.service-feature-component {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
}

.feature-component {
  height: 220px;
  width: 400px;
  background-color: #fbfbfb;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.feature-component .first-section {
  flex: 2;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.feature-component .first-section .header-line {
  border: 2px solid #3563a8;
  height: 44px;
}

.feature-component .first-section span {
  font-weight: 600;
  font-size: 20px;
  color: #182230;
}

.feature-component .second-section {
  flex: 3;
  display: flex;
  height: 84px;
  padding: 9px 24px 9px 24px;
}

.feature-component .second-section .section-detail {
  width: 100%;
  font-size: 18px;
  color: #344054;
  font-weight: 400;
  text-align: start;
}

@media (max-width: 375px) {
  .service-feature-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .feature-component {
    width: 300px;
  }
}

@media (min-width: 376px) and (max-width: 820px) {
  .service-feature-component {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: left;
  }

  .feature-component {
    width: auto;
  }
}
