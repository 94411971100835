.project-component-outer {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
}

.our-project-component {
  height: 425px;
  width: 361px;
  border-radius: 12px;
  border: 1px solid #e4e7ec;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.project-component-outer .our-project-component:nth-child(2) {
  height: 400px;
}

.project-component-outer .our-project-component:nth-child(2) .img-section img {
  height: 200px;
  object-fit: cover;
}

.our-project-component .img-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.our-project-component .img-section img {
  width: 330px;
  height: 231px;
  object-fit: cover;
  border-radius: 8px;
}

.our-project-component .first-section {
  display: flex;
  align-items: center;
  gap: 2rem;
  font-weight: 600;
  font-size: 20px;
  color: #182230;
  padding: 16px 20px;
}

.our-project-component .second-section {
  display: flex;
  padding: 0px 20px;
}

.our-project-component .second-section .section-detail {
  width: 100%;
  font-size: 18px;
  color: #667085;
  font-weight: 400;
  text-align: start;
}

@media (max-width: 375px) {
  .project-component-outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .our-project-component {
    width: 300px;
    height: 450px;
  }

  .our-project-component .img-section img {
    width: 280px;
  }

  .project-component-outer .our-project-component:nth-child(2) {
    height: 400px;
  }
}

@media (min-width: 376px) and (max-width: 820px) {
  .project-component-outer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}
