.hero-gradient {
  margin-top: 60px;
  /* background: linear-gradient(#f3faf8, #f1f5f900); */
}

.hero-label {
  border: 1px solid #e4e7ec;
  border-radius: 999px;
  margin-bottom: 28px;
}

.hero-label P > span {
  color: #234b4c;
}

.hero-font {
  font-size: 64px;
  line-height: 76.2px;
  margin-bottom: 28px;
}

.hero-svg {
  margin-top: 80px !important;
}

@media only screen and (max-width: 600px) {
  .hero-section-responsive {
    padding: 60px 24px !important;
    margin-top: 60px !important;
  }

  .hero-font span {
    font-size: 38px !important;
    line-height: 52px !important;
  }
}

#Platy .hero-label {
  padding: 9px 24px !important;
}
