.strategies-com-box {
  margin: auto;
  padding: 0;
  display: flex;
  gap: 40px;
  margin: auto;
  justify-content: center;
  flex-wrap: wrap;
}

.common-outer {
  padding: 80px 0px;
  display: flex;
  flex-direction: column;
}

.main-outer-container {
  margin-bottom: 60px;
  margin-top: 0px;
}

.main-outer-container .main-title {
  font-size: 48px;
  font-weight: 700;
}

.strategies-component {
  height: 220px;
  width: 400px;
  border-radius: 12px;
  border: 1px solid #e4e7ec;
  display: flex;
  flex-direction: column;
}

.strategies-component .first-section {
  flex: 2;
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 0px 24px 0px 24px;
  font-weight: 600;
  font-size: 20px;
  color: #182230;
}

.strategies-component .second-section {
  flex: 3;
  display: flex;
  padding: 0px 24px 0px 24px;
}

@media only screen and (max-width: 600px) {
  .main-outer-container {
    margin-bottom: 40px;
  }

  .strategies-component {
    height: auto;
    width: 100%;

    .first-section {
      padding: 24px;
    }

    .second-section {
      padding: 0px 24px 24px 24px;
    }
  }
}

.strategies-component .second-section .section-detail {
  width: 100%;
  font-size: 18px;
  color: #667085;
  font-weight: 400;
  text-align: start;
}

@media only screen and (max-width: 460px) {
  .common-outer {
    padding: 72px 16px 50px 16px;

    .main-outer-container {
      text-align: start;

      span {
        font-size: 36px !important;
      }
    }

    .component-box {
      padding: 0 0px 0 0px;
    }
  }
}

@media (max-width: 375px) {
  .strategies-com-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  strategies-component {
    width: 300px;
  }
}

@media (min-width: 376px) and (max-width: 820px) {
  .main-outer-container {
    text-align: start;

    span {
      font-size: 36px !important;
    }
  }

  .strategies-com-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: left;
  }

  .strategies-component {
    width: auto
  }
}