.last-section {
    padding: 60px 0 60px 0;
}

.section-opening-heading {
    font-size: 48px;
    font-weight: 600;
    color: #344054;
}

.section-opening-subheading {
    font-size: 22px;
    font-weight: 400;
    color: #344054;
    margin-top: 24px;
}

@media (max-width: 991px) {
    .section-opening-heading {
        font-size: 36px;
    }
}

@media (max-width: 768px) and (max-width: 484px) {
    .section-opening-heading {
        font-size: 28px;
    }
}