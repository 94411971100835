.about {
  .solution {
    h1 {
      margin-bottom: 60px;
    }

    .service-box {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

      .box {
        width: 90%;
        margin: auto;
      }
    }
  }

  .section1 {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 60px 0px;
    margin-top: 72px;

    .section-about-heading {
      text-align: start;
      font-size: 48px;
      font-weight: 700;
    }

    .section-detail {
      text-align: start;
      font-size: 22px;

      span {
        color: #667085;
      }
    }

    .section-image {
      img {
        width: 100%;
        border-radius: 16px;
      }
    }

    @media (max-width: 768px) {
      .section-about-heading {
        font-size: 38px;
      }
    }


  }

  .section-about-2 {
    padding: 72px 0px;
    width: 200;

    .section-2-header {
      span {
        font-size: 48px;
        font-weight: 700;
      }
    }
  }

  .section-solution-outer {
    padding: 72px 0px;
  }

  @media (max-width: 768px) {
    .section-solution-outer {
      padding: 72px 0px 0px 0px;
    }
  }

  .solution {
    h1 {
      margin-bottom: 60px;
    }

    .service-box {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

      .box {
        width: 100%;

        img {
          width: 90%;
          height: 100%;
        }
      }
    }

    .tools {
      display: none;
    }


    @media (max-width: 991px) {
      .service-box {
        display: none;
      }

      .tools {
        display: block;
      }
    }
  }

  .section-about-3-outer {
    background-color: #edf8ff99;
  }

  .section-about-3 {
    padding: 72px 0px;
    display: flex;
    flex-wrap: wrap;

    .left {
      text-align: start;

      .left-text {
        font-size: 48px;
        font-weight: 700;
      }
    }

    .right {
      flex: 0 0 70%;
      text-align: start;

      .right-text {
        font-size: 20px;
        color: #667085;
      }
    }


  }

  @media (max-width: 768px) {
    .section-about-3 {
      padding: 60px 16px !important;

      .left,
      .right {
        flex: 0 0 100%;
      }

      .left {
        .left-text {
          font-size: 38px;
        }
      }

      .right {
        .right-text {
          font-size: 18px;
          margin-top: 20px;
        }
      }
    }
  }

  .section-mission {
    padding: 72px 0px;
    display: flex;

    .left {
      flex: 1;
      display: flex;
      flex-direction: column;
      text-align: start;
      gap: 24px;

      .mission-title {
        font-size: 38px;
        font-weight: 500;
      }

      .mission-discription {
        p {
          font-size: 20px;
          color: #667085;
        }
      }

      .btn-section {
        button {
          height: 48px;
          padding: 16px 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          outline: none;
          border: none;
          background-color: #3563a8fb;
          color: #f7f8f9;
          font-weight: 500;
          border-radius: 4px;
        }
      }
    }

    .right {
      flex: 1;
    }
  }

  .section-connect {
    /* height: 440px; */
    padding: 72px 0px;

    .connect-info {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 292px;
      background-color: #3563a8;
      gap: 32px;
      flex-direction: column;
      border-radius: 16px;

      .connect-info-text-section {
        .connect-info-text1 {
          font-size: 20px;
          font-weight: 500;
          color: #fcfcfd;
        }

        .connect-info-text2 {
          font-size: 32px;
          font-weight: 500;
          color: #ffffff;
          margin-top: 24px;
        }
      }

    }

    @media (max-width: 768px) {
      .connect-info-text-section {
        padding: 0 10px;
      }

      .connect-info {
        height: 350px !important;
        margin: 0 20px;

        .connect-info-text-section {
          .connect-info-text1 {
            font-size: 16px;
            margin-top: 24px;
          }

          .connect-info-text2 {
            font-size: 18px;
            margin-top: 24px;
          }
        }
      }

    }

    .connect-info-btn {
      button {
        height: 48px;
        padding: 16px;
        background-color: transparent;
        color: #e4e7ec;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        border: 1px solid #e4e7ec;
        border-radius: 6px;
      }
    }
  }
}

.service-card {
  width: 200px;

  img {
    border-radius: 8px;
  }

  span {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .section1 {
    padding: 60px 16px !important;
  }
}