/* @keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
} */

/* @keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

.product-box-detail {
  transition: background-color 0.5s ease;
} */

/* .product-box-detail-img img {
  height: 450px;
  width: 840px;
} */

/* .fade-in {
  animation: fadeIn 0.5s ease forwards;
}

.fade-out {
  animation: fadeOut 0.3s ease forwards;
} */

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.product-box-detail-img img {
  opacity: 0;
  transition: opacity 0.5s ease; /* Smooth fade-in effect */
}

.product-box-detail-img img.fade-in {
  opacity: 1;
}
