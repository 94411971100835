#product-Platy {
  section {
    padding: 60px 0;

    &.bg-green {
      background: #f3faf8;
    }
  }

  .color-green {
    color: #318280;
  }

  .hero-gradient {
    margin-top: 60px;
    background: linear-gradient(#f3faf8, #f1f5f900);
  }

  .hero-label {
    border: 1px solid #e4e7ec;
    border-radius: 999px;
    margin-bottom: 28px;
    padding: 9px 25px;

    P > span {
      color: #234b4c;
    }
  }

  .hero-font {
    font-size: 64px;
    line-height: 76.2px;
    margin-bottom: 28px;
  }

  .hero-svg {
    margin-top: 80px !important;
  }

  .s2-box {
    padding: 24px;
    border: 1px solid #e4e7ec;
    border-radius: 32px;
    display: flex;
    gap: 80px;
    align-items: center;

    .s2-box-img {
      width: 40%;
    }

    .s2-box-text {
      width: 60%;
      display: flex;
      gap: 23px;
      justify-content: center;
      flex-direction: column;

      p {
        width: 90%;
      }
    }
  }

  /* Feature section  */

  .feature-heading {
    font-size: 48px;
    width: 70%;
    line-height: 120%;
    margin-bottom: 32px;
  }

  .feature-sub-heading {
    font-size: 20px;
    width: 50%;
    margin-bottom: 60px;
  }

  .feature-box {
    border: 1px solid #eee;
    background: #fafafa;
    border-radius: 24px;
    margin-bottom: 32px;

    .feature-box-content {
      padding: 40px;

      h4 {
        font-size: 24px;
        margin-bottom: 13px;
      }

      p {
        font-size: 20px;
      }
    }
  }

  /* service section  */
  .service {
    .service-heading {
      margin-bottom: 60px;
    }

    .swiper-wrapper {
      padding-bottom: 3rem;
    }

    .eachslide {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  /* Book now box section  */
  .platy-bookbox {
    margin-top: -3rem;

    .bookbox-1 {
      padding: 70px 0 90px 0;
      background-color: #ffffff;

      h1 {
        margin-bottom: 60px;
      }

      .BBSI-box {
        position: relative;
      }

      .divider {
        border-right: 1px solid #d0d5dd;
        height: 56px;
        position: absolute;
        right: 0;
      }
    }

    .platy-bookbox-2 {
      height: 250px;
      margin-top: -3rem;
      background: linear-gradient(#318281cc, #318280);
      border-radius: 16px;
      color: #ffffff;

      .booknow-btn-custom {
        display: inline;
        width: fit-content;
        border: 1px solid #fff;
        height: 3rem;
        color: #fff;
        padding: 1rem;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        margin: 2rem auto 0;
      }
    }
  }
}
