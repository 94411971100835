#home {
  section {
    padding: 60px 0;

    &.bg-blue {
      background: #f2f9ff;
    }
  }

  .hero-gradient {
    margin-top: 60px;
    background: linear-gradient(#fff2e3, #f1f5f900);
    position: relative;
    overflow: hidden;

    .hero-svg {
      max-width: 1280px;
      position: absolute;
      top: 120%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
  }

  .hero-label {
    border: 1px solid #e4e7ec;
    background: #fffcf8;
    /* background-color: #f5e3cf; */
    border-radius: 999px;
    margin: 28px 0;
    padding: 9px 25px;
  }

  .hero-font {
    font-size: 64px;
    line-height: 76.2px;
    margin-bottom: 28px;
  }

  .bookademo-btn {
    display: flex;
    justify-content: center;
    margin: 2rem 0;

    a {
      background: #3563a8;
      color: #fff;
    }
  }

  /* Solution block */
  .solution {
    h1 {
      margin-bottom: 60px;
    }

    .service-box {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

      .box {
        img {
          height: 100%;
          width: 90%;
        }
      }
    }

    .tools {
      display: none;
    }

    @media (max-width: 991px) {
      .service-box {
        display: none;
      }

      .tools {
        display: block;
      }
    }
  }

  /* Product detail box */
  .product {
    overflow: hidden;
    margin: auto;

    .product-box {
      display: flex;
      align-items: center;

      .product-box-nav {
        width: calc(100vw - 70%);
        height: 100%;
        text-align: left;
        display: flex;
        justify-content: end;
        padding-right: 10%;

        li {
          list-style: none;
          margin-bottom: 1rem;
          cursor: pointer;
          font-weight: 500;
          transition: transform 0.3s ease-out;
          /* Smooth transition for scaling */
          /* width: fit-content; */

          &.active {
            color: #3563a8;
            font-weight: 600;
            position: relative;
          }

          &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            height: 2px;
            width: 100%;
            background: #3563a8;
            transform: scaleX(0);
            /* Start from 0 */
            transform-origin: left;
            /* Start from left */
            transition: transform 0.3s ease-in-out;
            /* Smooth transition */
          }

          &.active:after {
            transform: scaleX(1);
            /* Expand to full width */
          }
        }

        li:hover {
          transform: scale(1.05);
        }
      }

      .product-box-detail {
        width: 70%;
        padding: 60px 0 60px 40px;
        border-radius: 1rem 0 0 1rem;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 4rem;
        margin-left: 1rem;

        .product-box-detail-content {
          width: 55%;
          flex-shrink: 2;
          text-align: left;

          h1 {
            font-size: 46px;
            line-height: 60px;
            margin-bottom: 1.2rem;
            font-weight: 700;
            color: #182230;
          }

          p {
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 1.2rem;
            word-spacing: 0.15rem;
            /* text-align: justify; */
            color: #344054;
          }

          a {
            border-bottom: 1px solid #3563a8;
            color: #3563a8;
            font-weight: bold;
          }
        }

        .product-box-detail-img {
          text-align: left;
          width: 45%;

          img {
            height: 100%;
          }
        }

        .product-box-detail-learnmore {
          width: 55%;
        }
      }
    }

    @media (max-width: 991px) {
      .product-box {
        flex-direction: column;
        align-items: flex-start;

        .product-box-nav {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          width: 100%;
          padding-right: 0;
          justify-content: flex-start;
          margin-bottom: 20px;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;

          ul {
            display: flex;
            flex-wrap: nowrap;
            gap: 12px;
          }

          li {
            font-size: 16px;
            text-align: center;
            margin-right: 10px;
            white-space: nowrap;

            &.active {
              color: #3563a8;
              font-weight: 600;
              /* border-bottom: 2px solid #3563a8; */
              padding-bottom: 10px;
            }
          }
        }

        .product-box-detail {
          width: 100%;
          padding: 20px;
          margin-left: 0;
          flex-direction: column;
          gap: 1.5rem;

          .product-box-detail-content {
            width: 100%;

            h1 {
              font-size: 32px;
              line-height: 40px;
            }

            p {
              font-size: 18px;
            }
          }

          .product-box-detail-img {
            width: 100%;

            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }

    @media (max-width: 480px) {
      .product-box-nav {
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        ul {
          display: flex;
          flex-wrap: nowrap;
        }

        li {
          margin-right: 10px;
          margin-bottom: 0;
          font-size: 14px;
          white-space: nowrap;
        }
      }
    }
  }

  .product-svg {
    background: linear-gradient(
      175.85deg,
      rgba(255, 139, 12, 0.025) -8.08%,
      rgba(64, 123, 255, 0.05) 110.88%
    );
  }

  /* .industry {
    .container {
      h1 {
        margin-bottom: calc(4rem - 4px);
      }
    }
 
    .swiper-wrapper {
      padding-bottom: 3rem;
    }
 
    .industryeachslide {
      padding: 20px;
      border: 2px solid #e4e7ec;
      border-radius: 14px;
      width: 100%;
 
      .industryeachslide-img {
        border-radius: 14px;
        height: 100%;
        width: 100%;
}
}
}

*/
  .clients {
    margin-top: -3rem;
    padding: 70px 0 90px 0;
    background-color: #ffffff;

    h1 {
      margin-bottom: calc(4rem - 4px);
    }

    .clients-box {
      margin-bottom: 2rem;

      .scroll-container {
        width: 100%;
        overflow: hidden;
        /* Hide overflow to create the scrolling effect */
        position: relative;
      }

      .scroll-track {
        display: flex;
        width: calc(13 * 160px * 2);
        animation: scroll 20s linear infinite;
      }

      .client-box {
        flex: 0 0 auto;
        margin-right: 5rem;
      }

      .client-box img {
        max-height: 100px;
        object-fit: contain;
      }
    }
  }

  .home-booknow-box {
    .home-bookbox-2 {
      padding: 60px 80px;
      margin-top: -3rem;
      background: #3563a8;
      border-radius: 16px;
      color: #ffffff;

      .booknow-btn-custom {
        display: inline;
        width: fit-content;
        border: 1px solid #fff;
        height: 3rem;
        color: #fff;
        padding: 1rem;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        margin: 2rem auto 0;
      }
    }
  }

  .partner {
    h1 {
      margin-bottom: 60px;
    }

    .service-box {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

      .box {
        width: auto;
        margin: auto;
      }
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}
