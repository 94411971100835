@media only screen and (max-width: 768px) {
  .main-image-container {
    display: flex !important;
    flex-direction: column !important;
  }

  .main-image-container .s2-box-img {
    width: 100% !important;
  }
  .main-image-container .s2-box-text {
    width: 100% !important;
  }
}
