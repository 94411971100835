.SapFeatureSection {
  padding: 72px 0px;
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.SapFeatureSection .container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.feature-text {
  text-align: start;
}

.feature-text p {
  font-size: 48px;
  font-weight: 700;
  width: 100%;
}

.feature-detail-card {
  display: flex;
  gap: 72px;
}

.feature-detail-card.reverse {
  flex-direction: row-reverse;
}

.left {
  flex: 1;
}

.left img {
  width: 100%;
  /* Make the image responsive */
  /* max-width: 677px; */
  /* Set a max-width for large screens */
  height: auto;
  /* Maintain aspect ratio */
}

.right {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  text-align: start;
  justify-content: center;
}

.feature-title {
  font-size: 24px;
  font-weight: 500;
}

.feature-text1 p,
.feature-text2 p {
  font-size: 18px;
  font-weight: 400;
}

.feature-text1 p {
  color: #667085;
}

.feature-text2 p {
  color: #475467;
}

@media (max-width: 1200px) {
  .SapFeatureSection {
    padding: 48px 40px;
  }

  .feature-text p {
    font-size: 38px;
  }

  .feature-detail-card {
    gap: 40px;
  }
  /* 
  .left img {
    max-width: 400px;
  } */

  .feature-title {
    width: 100%;
    font-size: 20px;
  }

  .feature-text1 p,
  .feature-text2 p {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  /* Tablets and large mobile devices */
  .SapFeatureSection {
    padding: 32px 16px;
    gap: 32px;
  }

  .feature-text p {
    font-size: 38px;
  }

  .feature-detail-card {
    flex-direction: column !important;
    /* Override any row or row-reverse */
    gap: 24px;
  }

  .left,
  .right {
    width: 100%;
  }

  .left img {
    /* max-width: 300px; */
    /* Further reduce image size */
  }

  .right {
    align-items: center;
    /* Center content */
    justify-content: center;
    text-align: center;
    text-align: start;
  }

  .feature-title {
    font-size: 18px;
  }

  .feature-text1,
  .feature-text2 {
    width: 100%;
  }

  .feature-text1 p,
  .feature-text2 p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  /* Mobile devices */
  .SapFeatureSection {
    padding: 24px 12px;
  }

  .feature-text p {
    font-size: 38px;
  }

  .feature-detail-card {
    flex-direction: column !important;
    /* Override any row or row-reverse */
    gap: 24px;
  }

  .left,
  .right {
    width: 100%;
  }

  .left img {
    max-width: 100%;
    /* Further reduce image size for small screens */
  }

  .right {
    text-align: start;
    gap: 16px;
  }

  .feature-title {
    width: 100%;
    font-size: 18px;
  }

  .feature-text1,
  .feature-text2 {
    width: 100%;
    text-align: start;
  }

  .feature-text1 p,
  .feature-text2 p {
    font-size: 14px;
  }
}
