#AnimatedButton div[class*="box"] {
  height: 54px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#AnimatedButton .btn {
  line-height: 50px;
  height: 50px;
  text-align: center;
  width: 250px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 2rem; */
  /* color: #000000 !important; */
}

#AnimatedButton .btn span {
  z-index: 99;
  font-weight: 600;
}

#AnimatedButton .btn-three {
  color: #fff;
  transition: all 0.5s;
  position: relative;
}

#AnimatedButton .btn-three::before {
  border: 1px solid rgb(24, 34, 48);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.3s;
  color: #fff !important;
}

#AnimatedButton .btn-three:hover::before {
  opacity: 0;
  transform: scale(0.5, 0.5);
  color: #fff !important;
}

#AnimatedButton .btn-three::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  transform: scale(1.2, 1.2);
  outline: none;
}

#AnimatedButton .btn-three:hover::after {
  opacity: 1;
  transform: scale(1, 1);
  background-color: rgba(218, 28, 92, 1);
  color: #fff;
}

#AnimatedButton .box-3:hover span {
  /* Add your styles here */
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}