/* .sap-hero {
  margin-top: 76px;
  padding: 80px;
  display: flex;
  gap: 110px;
  justify-content: center;

  .left {
    flex: 1;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 28px;
    max-width: 590px;

    .left-cap {
      height: 40px;
      border: 1px solid #d0d5dd;
      background-color: #e6f5ff;
      border-radius: 999px;
      width: fit-content;
      padding: 9px 18px;

      span {
        font-size: 14px;
        font-weight: 500;
        color: #182230;
      }
    }

    .hero-title {
      font-size: 48px;
      font-weight: 800;
      color: #182230;
    }

    .hero-detail {
      p {
        font-size: 20px;
        color: #667085;
      }
    }

    .hero-btn {
      button {
        height: 48px;
        padding: 16px;
        background-color: #3563a8fb;
        color: #e4e7ec;

        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        border: 1px solid #e4e7ec;
        border-radius: 6px;
      }
    }
  }

  .right {
    flex: 1;
    max-width: 556px;

    .hero-image {
      height: 446px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .sap-hero {
    padding: 24px !important;

    .right {
      max-width: none;
    }
    .left {
      max-width: none;
    }
    .right {
      max-width: none;
      width: 100%;
      .hero-image {
        width: 100% !important;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .sap-hero {
    padding: 24px !important;
    gap: 32px;
  }
  .left {
    max-width: none;
  }
  .irght {
    max-width: none;
  }
}

@media only screen and (max-width: 768px) {
  .sap-hero {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 0px;
    .right {
      max-width: none;
    }
    .hero-image {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 425px) {
}

@media only screen and (max-width: 375px) {
} */

.sap-hero {
  margin-top: 76px;
  padding: 80px;
  display: flex;
  gap: 110px;
  justify-content: center;
  flex-wrap: wrap;

  .left {
    flex: 1;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 28px;
    max-width: 590px;

    .left-cap {
      height: 40px;
      border: 1px solid #d0d5dd;
      background-color: #e6f5ff;
      border-radius: 999px;
      width: fit-content;
      padding: 9px 18px;

      span {
        font-size: 14px;
        font-weight: 500;
        color: #182230;
      }
    }

    .hero-title {
      font-size: 48px;
      font-weight: 800;
      color: #182230;
    }

    .hero-detail {
      p {
        font-size: 20px;
        color: #667085;
      }
    }

    .hero-btn {
      button {
        height: 48px;
        padding: 16px;
        background-color: #3563a8fb;
        color: #e4e7ec;

        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        border: 1px solid #e4e7ec;
        border-radius: 6px;
      }
    }
  }

  .right {
    flex: 1;
    max-width: 556px;

    .hero-image {
      width: 100%;
      img {
        width: 100%;
        height: auto; /* Maintain aspect ratio */
        object-fit: cover;
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .outer-conatiner {
    .container {
      min-width: none !important;
    }
  }
  .sap-hero {
    padding: 60px 16px !important;
    gap: 32px;
  }
  .left,
  .right {
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .sap-hero {
    flex-direction: column;
    padding: 16px;
    gap: 16px;
  }
  .right {
    .hero-image {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .sap-hero {
    padding: 16px;
    .hero-title {
      font-size: 32px; /* Adjust title size for smaller screens */
    }
    .hero-detail p {
      font-size: 16px; /* Adjust detail text size */
    }
  }
}
