.contact-hero {
    margin-top: 76px;
    padding: 80px 0;
    display: flex;
    gap: 110px;
    justify-content: center;
    background-color: #EDF8FF99;

    .contact-head-main {
        flex: 1;
        text-align: start;
        display: flex;
        flex-direction: column;
        gap: 28px;
        width: 100%;

        .hero-title,
        .hero-detail {
            animation-duration: 1s;
            animation-delay: 0.5s;
        }

        .hero-title {
            font-size: 48px;
            font-weight: 700;
            color: #344054;
        }

        .hero-detail {
            p {
                font-size: 20px;
                color: #667085;
            }
        }
    }
}

.contact-form-main {
    /* display: flex; */
    /* gap: 2rem; */
    /* gap: 133px; */
    padding: 72px 0;

    .contact-form-main-box {
        display: flex;
        align-items: center;
        gap: 5rem;
    }

    .container-left img {
        /* width: 458px;
        height: 383px; */
        width: 100%;
        height: auto;
    }

    .container-right {
        width: 100%;
    }

    .container-right form {
        display: flex;
        flex-direction: column;
    }

    .form-group {
        margin-bottom: 20px;
        text-align: left;

        &:last-child {
            margin-bottom: 0px;
        }
    }

    .form-group label {
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 5px;
        display: block;
        text-align: left;
    }

    .form-group input,
    .form-group textarea {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .form-group input:focus,
    .form-group textarea:focus {
        outline: none;
        border-color: #3563A8;
    }

    .form-group input::placeholder,
    .form-group textarea::placeholder {
        color: #4E5D6B52;
    }

    .submit-btn {
        padding: 10px 20px;
        background-color: #3563A8;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        text-align: left;
        display: inline-block;
    }
}

.find-us-main {
    padding: 72px 0;
    flex: 1;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    .hero-title {
        font-size: 48px;
        font-weight: 700;
        color: #344054;
    }

    .hero-detail {
        p {
            font-size: 20px;
            color: #667085;
        }
    }

    .map iframe {
        width: 100%;
        height: 400px;
        margin-top: 20px;
        position: relative;
        border-radius: 16px;
    }
}

.section-connect {
    padding: 72px 0;

    .connect-info {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 235px;
        background-color: #3563a8;
        gap: 32px;
        flex-direction: column;
        border-radius: 16px;

        .connect-info-text-section {
            .connect-info-text1 {
                font-size: 36px;
                font-weight: 600;
                color: #E4E7EC;
            }

            .connect-info-text2 {
                font-size: 32px;
                font-weight: 400;
                color: #E4E7EC;
            }
        }
    }
}

@media (max-width: 991px) {
    .contact-hero {
        flex-direction: column;
        align-items: left;
        /* gap: 50px; */

        .contact-head-main .hero-title {
            font-size: 36px;
        }

        .contact-head-main .hero-detail p {
            font-size: 18px;
        }
    }

    .contact-form-main-box {
        flex-direction: column;
        gap: 50px;

        .container-left img {
            width: 550px;
            height: auto;
        }
    }

    .find-us-main {
        .hero-title {
            font-size: 36px;
        }

        .hero-detail p {
            font-size: 18px;
        }
    }

    .section-connect .connect-info {
        height: 260px;

        .connect-info-text-section {
            .connect-info-text1 {
                font-size: 24px;
            }

            .connect-info-text2 {
                font-size: 20px;
                margin-top: 20px;
            }
        }
    }
}

@media (max-width: 768px) {
    .contact-hero {
        gap: 30px;

        .contact-head-main .hero-title {
            font-size: 28px;
        }

        .contact-head-main .hero-detail p {
            font-size: 16px;
        }
    }

    .contact-form-main-box {
        flex-direction: column;
        gap: 30px;

        .container-left img {
            width: 550px;
            height: auto;
        }

        .form-group label {
            font-size: 12px;
        }

        .form-group input,
        .form-group textarea {
            padding: 8px;
            font-size: 14px;
        }

        .submit-btn {
            padding: 8px 16px;
        }
    }

    .find-us-main {
        .hero-title {
            font-size: 28px;
        }

        .hero-detail p {
            font-size: 16px;
        }

        .map iframe {
            height: 300px;
        }
    }

    /* .section-connect .connect-info {
        height: 220px;

        .connect-info-text-section {
            .connect-info-text1 {
                font-size: 24px;
            }

            .connect-info-text2 {
                font-size: 20px;
            }
        }
    } */
}

@media (max-width: 484px) {
    .contact-hero {
        gap: 30px;
        padding: 30px 20px;

        .contact-head-main .hero-title {
            font-size: 28px;
        }

        .contact-head-main .hero-detail p {
            font-size: 16px;
        }
    }

    .contact-form-main-box {
        flex-direction: column;
        gap: 30px;

        .container-left img {
            width: 320px;
            height: auto;
        }

        .container-right {
            width: 320px;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
        }

        .form-group label {
            font-size: 12px;
        }

    }

    .find-us-main {
        padding: 0 20px;

        .hero-title {
            font-size: 28px;
        }

        .hero-detail p {
            font-size: 16px;
        }

        .map iframe {
            height: 300px;
        }
    }

    .section-connect .connect-info {
        margin: 0 20px;
    }
}