.footer-main {
    display: flex;
    background-color: #FBFBFB;
}

.footer-sub-main {
    padding: 5rem 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}


@media (max-width: 480px) {

    .footer-second-part,
    .footer-third-part {
        margin-top: 24px;
    }
}

.footer-first-part,
.footer-second-part,
.footer-third-part {
    /* width: 33%; */
    text-align: left;
}

.heading {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
}

.footer-first-part .footer-links {
    display: flex;
    margin-top: 32px;
    gap: 24px;

}

.footer-first-part .footer-links img {
    width: 24px;
    height: 24px;
}

.footer-first-part .get-the-directions {
    margin-top: 32px;
}

.footer-first-part .get-the-directions .direction-part {
    display: flex;
    margin-top: 20px;
    align-items: center;
}

.footer-first-part .get-the-directions .direction-part img {
    margin-right: 8px;
}

.footer-first-part .get-the-directions .direction-part p {
    text-decoration: underline;
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
    color: #182230;
    margin-left: 12px;
}

.footer-second-part .important-links {
    margin-top: 24px;
}

.footer-second-part .important-links p {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
    color: #182230;
}

.footer-third-part img {
    width: 185px;
    height: 65px;
    display: block;
}

.footer-third-part .address {
    margin-top: 32px;
}

.footer-third-part .address p {
    font-size: 16px;
    font-weight: 400;
    color: #344054;
}

.copy-right {
    padding: .4rem 0;
    font-size: 14px;
    font-weight: 400;
    color: #21272A;
}

/* @media (max-width: 767px) {
    .footer-sub-main {
        flex-direction: column;
        gap: 20px;
        margin: 0 24px;
    }

    .footer-first-part,
    .footer-second-part,
    .footer-third-part {
        width: 100%;
        text-align: left;
        margin-top: 12px;
    }

    .footer-sub-main {
        padding: 40px 20px;
    }

    .footer-first-part .footer-links {
        margin-top: 24px;
        gap: 24px;
    }

    .footer-first-part .get-the-directions {
        margin-top: 16px;
    }

    .footer-third-part .address {
        margin-top: 16px;
    }
}


@media (min-width: 768px) and (max-width: 1024px) {
    .footer-sub-main {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 0 24px;
        padding: 40px 20px;
    }

    .footer-first-part,
    .footer-second-part {
        width: 40%;
        text-align: left;
        margin-top: 12px;
    }

    .footer-first-second-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .footer-third-part {
        width: 100%;
        text-align: left;
        margin-top: 20px;
    }
} */
/* @media (max-width: 480px) {
    .footer-sub-main {
        .footer-first-part {
            margin-top: 24px;
        }

        .footer-second-part {
            margin-top: 16px;
        }
    }

} */