.typed {
  line-height: 35px;
  width: 80%;
  font-size: 150%;
  text-align: left;
  font-family: "Courier New", "Lucida Console", monospace;
  vertical-align: text-bottom;
}

.typed::after {
  content: "|";
  font-size: 150%;
  line-height: 30px;
  animation: blink 0.75s step-end infinite;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }

  50% {
    color: rgba(245, 245, 245);
  }
}

.learnMore-btn {
  background-color: transparent;
  color: #3563a8;
  border: none;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
  line-height: 60px;
}

.hero-gradient {
  .city-text {
    strong {
      color: #667085;
      text-transform: capitalize;
    }
  }
}

/* Fade-in on load animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
    /* Slide in from below */
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero-section-responsive {
  /* opacity: 0; */
  /* transform: translateY(20px);
  transition: opacity 0.7s ease-out, transform 0.7s ease-out; */
}
/* 
.hero-section-responsive.animate {
  opacity: 1;
  transform: translateY(0);
} */

/* Hover effects */
.bookademo-btn a {
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.bookademo-btn a:hover {
  transform: scale(1.05);
  background-color: #28518a;
}

/* .hero-svg img {
  transition: transform 0.5s ease;
} */

/* .hero-svg img:hover {
  transform: scale(1.02);
} */

/* Parallax effect on mouse move */
.hero-svg img {
  transform: translate(0px, 0px);
}
