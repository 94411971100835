.contact-btn-custom {
  display: inline;
  width: fit-content;
  border: 1px solid #000;
  height: 2.5rem;
  color: #000;
  padding: 0 15px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#navbarSupportedContent {
  .dropdown-item {
    font-size: 16px;
    font-weight: 500;
    padding: 12px 200px 12px 42px;
    color: #344054;

    &:hover {
      border-left: 2px solid #3563a8;
    }
  }
}

#navbar .nav-item {
  cursor: pointer;
}

.custom-nav-design {
  .accordion-item {
    .accordion-button {
      padding-left: 0;
      padding-right: 0;
    }

    .accordion-body {
      .dropdown-item {
        padding: 0.5rem 0;

        &:hover {
          color: #3563a8;
        }
      }
    }
  }

  .nav-link.menu-link {
    &.collapsed {

      .text-content,
      .mdi-chevron-right {
        color: #000;
      }

      .mdi-chevron-down {
        display: none;
      }
    }

    &:not(.collapsed) {

      .text-content,
      .mdi-chevron-down {
        color: #3563a8;
      }

      .mdi-chevron-right {
        display: none;
      }
    }
  }

  .accordion-button:not(.collapsed) {
    background: none;
  }
}

.navbar-small-box {
  .accordion-item {
    margin-bottom: 0.5rem;

    .accordion-button {
      font-size: 18px;

      i {
        font-size: 20px;
        margin-right: 0.7rem;
      }

      &.active {
        color: #3563a8;

        i {
          color: #3563a8;
        }
      }
    }

    .accordion-body {
      padding: 0rem 2rem;
    }
  }
}