.section-mission {
  padding: 72px 0px;
}
.section-mission .component-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 140px;
}
.section-mission .left {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 60px;
}
.section-mission .left .common-left {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 24px;
}
.section-mission .left .common-left .mission-title {
  font-size: 36px;
  font-weight: 500;
  color: #182230;
}
.section-mission .left .common-left .mission-discription p {
  font-size: 20px;
  color: #667085;
}
.section-mission .left .common-left .btn-section button {
  height: 48px;
  padding: 16px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background-color: #3563a8fb;
  color: #f7f8f9;
  font-weight: 500;
  border-radius: 4px;
}
.section-mission .left .selection-conatiner-down {
  display: flex;
  flex-direction: column;
  gap: 56px;
}
.section-mission .left .swiping-section {
  height: 96px;
  display: flex;
  gap: 16px;
  cursor: pointer;
}
.section-mission .left .swiping-section .border-active {
  border: 2px solid #d0d5dd;
  border-radius: 2px;
  position: absolute;
  height: 95px;
}
.section-mission .left .swiping-section .border-selected {
  position: relative;
  height: 28px;
  border: 2px solid #4e76b2;
  border-radius: 2px;
  z-index: 11;
  transition: none;
}
.section-mission .left .swiping-section .border-selected.animate-down {
  animation: moveDownAndFade 0.8s forwards;
}
.section-mission .left .swiping-section .border-selected.animate-up {
  animation: moveDownAndFade 0.8s forwards;
}
.section-mission .left .swiping-section .border-deselected {
  position: relative;
  height: 28px;
  border: 2px solid #d0d5dd;
  top: 0;
  border-radius: 2px;
  z-index: 11;
}
.section-mission .left .swiping-section .section-text {
  width: 60%;
}
.section-mission .left .swiping-section .section-text .text1-not-selected {
  font-size: 24px;
  font-weight: 500;
  color: #98a3b3;
}
.section-mission .left .swiping-section .section-text .text2-not-selected {
  font-size: 18px;
  color: #d0d5dd !important;
  transition: opacity 2s ease-in-out;
  opacity: 1;
}
.section-mission .left .swiping-section .section-text .text2-not-selected {
  transition: opacity 2s ease-in-out;
}
.section-mission .left .swiping-section .section-text .text2-not-selected {
  opacity: 0.5;
  color: #d0d5dd !important;
}
.section-mission .left .swiping-section .section-text .text1 {
  font-size: 24px;
  font-weight: 500;
  color: #182230;
}
.section-mission .left .swiping-section .section-text .text2 {
  font-size: 18px;
  font-weight: 400;
  color: #667085;
}
.section-mission .right img {
  margin-top: 24px;
  width: 401px;
  height: 440px;
}
@keyframes moveDownAndFade {
  0% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: 67px;
    opacity: 0;
  }
}
@keyframes moveUpAndFade {
  0% {
    bottom: 0;
    opacity: 1;
  }
  100% {
    bottom: 67px;
    opacity: 0;
  }
}
.flip {
  animation: flip 0.6s ease-in-out forwards;
}
@keyframes flip {
  0% {
    transform: rotateY(0deg);
    opacity: 1;
  }
  50% {
    transform: rotateY(90deg);
    opacity: 0.5;
  }
  100% {
    transform: rotateY(180deg);
    opacity: 1;
  }
}
.fade-in {
  animation: fadeIn 0.5s ease-in-out forwards;
}
@media only screen and (min-width: 1021px) {
  .mobile-component-container {
    display: none !important;
  }
}
@media only screen and (max-width: 1020px) and (min-width: 769px) {
  .mobile-component-container {
    display: block !important;
  }
  .component-container {
    display: none !important;
  }
  .mobile-component-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 140px;
  }
  .common-left {
    display: flex;
    flex-direction: column;
    text-align: start;
    gap: 24px;
  }
  .common-left .mission-title {
    font-size: 32px;
    font-weight: 500;
  }
  .common-left .mission-discription p {
    font-size: 20px;
    color: #667085;
  }
  .common-left .btn-section button {
    height: 48px;
    padding: 16px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    background-color: #3563a8fb;
    color: #f7f8f9;
    font-weight: 500;
    border-radius: 4px;
  }
  .selection-container-down .section-text {
    text-align: left;
    justify-content: left;
    margin-top: 34px;
  }
  .selection-container-down .text1 {
    font-size: 22px;
    font-weight: 500;
  }
  .selection-container-down .text2 {
    font-size: 18px;
    font-weight: 400;
    color: #667085;
  }
  .selection-container-down .section-text img {
    margin-top: 24px;
    width: 500px;
    height: 400px;
  }
}
@media only screen and (max-width: 768px) {
  .section-mission {
    padding: 0px 0 72px 0;
  }
  .mobile-component-container {
    display: block !important;
    padding: 60px 0px 0px 0px !important;
  }
  .component-container {
    display: none !important;
  }
  .mobile-component-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 140px;
    flex-wrap: wrap;
  }
  .common-left {
    display: flex;
    flex-direction: column;
    text-align: start;
    gap: 24px;
  }
  .common-left .mission-title {
    font-size: 32px;
    font-weight: 500;
  }
  .common-left .mission-discription p {
    font-size: 20px;
    color: #667085;
  }
  .common-left .btn-section button {
    height: 48px;
    padding: 16px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    background-color: #3563a8fb;
    color: #f7f8f9;
    font-weight: 500;
    border-radius: 4px;
  }
  .selection-container-down .section-text {
    text-align: start;
    align-items: start;
    margin-top: 60px;
  }
  .selection-container-down .text1 {
    font-size: 22px;
    font-weight: 500;
  }
  .selection-container-down .text2 {
    font-size: 18px;
    font-weight: 400;
    color: #667085;
  }
  .selection-container-down .section-text img {
    margin-top: 24px;
    height: 300px;
  }
}

@media only screen and (max-width: 530px) {
  .mobile-component-container {
    display: block !important;
    padding: 60px 16px 0px 16px !important;
  }
}
