.ProductFeatureCard {
  display: flex;
  justify-content: center;
  gap: 32px;
  padding: 60px 80px;
  flex-wrap: wrap;
}
.card-main {
  height: 405px;
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #fafafa;
  border-radius: 24px;
}

.card-main .card-image-section {
  height: 234;
  width: 100%;
}

.card-main .card-detail-section {
  width: 314px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.card-main .card-title-section {
  font-size: 24px;
  text-align: start;
}
.card-main .card-desc-section {
  font-size: 16px;
  text-align: start;
}

@media only screen and (max-width: 520px) {
.card-detail-section {
    padding: 0px 16px !important;
  }
}
