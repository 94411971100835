.hero-page-main {
    background-image: url('../../../assets/svg/ExternalPages/hero.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .hero-sub-details {
        position: relative;
        text-align: center;
    }

    .hero-sub-details img {
        width: 450px;
        height: 450px;
    }

    .heading {
        margin-top: 32px;
        font-size: 48px;
        font-weight: 600;
        color: #182230;
        text-align: center;
    }

    .sub-heading {
        margin-top: 32px;
        font-size: 24px;
        font-weight: 400;
        color: #344054;
        text-align: center;
    }

    @media (max-width: 767px) {
        .hero-sub-details img {
            width: 480px;
            height: 480px;
        }

        .heading {
            font-size: 36px;
        }

        .sub-heading {
            font-size: 18px;
        }
    }

    @media (max-width: 480px) {
        .hero-sub-details img {
            width: 350px;
            height: 380px;
        }

        .heading {
            font-size: 30px;
        }

        .sub-heading {
            font-size: 16px;
        }
    }
}