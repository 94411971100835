.cloud-infra-main {
    .section-connect {
        height: 440px;
        /* padding: 72px 30px; */

        .connect-info {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 300px;
            background-color: #3563a8;
            gap: 32px;
            flex-direction: column;
            border-radius: 16px;

            .connect-info-text-section {

                .connect-info-text1 {
                    font-size: 20px;
                    font-weight: 500;
                    color: #fcfcfd;
                    font-style: italic;
                }

                .connect-info-text2 {
                    font-size: 32px;
                    font-weight: 500;
                    color: #ffffff;
                    margin-top: 24px;
                }

                @media only screen and (max-width: 768px) {
                    .connect-info-text2 {
                        font-size: 16px;
                        padding: 0 10px;
                    }

                    .connect-info-text1 {
                        font-size: 14px;
                        padding: 0 10px;
                    }
                }
            }

            .connect-info-btn {
                button {
                    height: 48px;
                    padding: 16px;
                    background-color: transparent;
                    color: #e4e7ec;
                    font-size: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    outline: none;
                    border: 1px solid #e4e7ec;
                    border-radius: 6px;
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .section-connect {
            padding: 30px 0;
            height: 400px;
        }
    }

}