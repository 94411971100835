.industry {
    .container {

        h1 {
            margin-bottom: calc(4rem - 4px);
            font-size: 48px;

            @media (max-width: 1024px) {
                font-size: 36px;
            }

            @media (max-width: 767px) {
                font-size: 28px;
            }
        }
    }

    .swiper-wrapper {
        padding-bottom: 4rem;
    }

    .swiper-pagination {
        .swiper-pagination-bullet {
            height: 12px;
            width: 12px;

            &.swiper-pagination-bullet-active-main {
                height: 14px;
                width: 14px;
                border-radius: 8px;
            }
        }
    }

    .industryeachslide {
        padding: 20px;
        border: 2px solid #e4e7ec;
        border-radius: 14px;
        width: 370px;
        gap: 24px;

        .industryeachslide-img {
            border-radius: 14px;
            height: 232px;
            width: 331px;
        }

        @media (max-width: 4000px) {
            width: 300px !important;

            .industryeachslide-img {
                border-radius: 14px;
                height: 200px;
                width: 100%;
            }
        }

        @media (max-width: 1024px) {
            width: 300px !important;

            .industryeachslide-img {
                border-radius: 14px;
                height: 200px;
                width: 100%;
            }
        }

        @media (max-width: 767px) {
            width: 300px !important;

            .industryeachslide-img {
                border-radius: 14px;
                height: 180px;
                width: 100%;
            }
        }
    }
}