@media only screen and (max-width: 768px) {
  .booknow-btn {
    width: 120px !important;
  }

  .book-now-conatiner {
    padding: 0 24px !important;
  }

  .bookbox .content-heading {
    font-size: 36px !important;
  }

  .bookbox .divider {
    display: none !important;
  }

  .bookbox .conatiner-box-book {
    justify-content: center;
  }

  .offers-conatiner {
    gap: 1rem;
  }
}

@media only screen and (max-width: 780px) {
  .bookbox .divider {
    display: none !important;
  }
  .bookbox-2 {
    .booknow-btn {
      padding: 0px !important;
    }
  }

  .book-now-growtext {
    font-size: 38px !important;
  }
}

.book-now-conatiner {
  padding: 0px;
  .bookbox-2 {
    .booknow-btn {
      display: inline;
      width: fit-content;
      border: 1px solid #fff;
      height: 3rem;
      color: #fff;
      padding: 0px 15px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}
.book-now-growtext {
  font-size: 48px;
}
