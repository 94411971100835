#Platy section {
  padding: 60px 0;
}

#Platy section.bg-green {
  background: #f3faf8;
}

.color-green {
  color: #318280;
}

#Platy .hero-gradient {
  margin-top: 60px;
  background: linear-gradient(#dfefffcc, #f1f5f900);
}

#Platy .hero-label {
  border: 1px solid #e4e7ec;
  border-radius: 999px;
  margin-bottom: 28px;
  padding: 9px 25px;
}

#Platy .hero-label P > span {
  color: #234b4c;
}

#Platy .hero-font {
  font-size: 64px;
  line-height: 76.2px;
  margin-bottom: 28px;
}

#Platy .hero-svg {
  margin-top: 80px !important;
}

.s2-box {
  padding: 24px;
  border: 1px solid #e4e7ec;
  border-radius: 32px;
  display: flex;
  gap: 80px;
  align-items: center;
}

.s2-box .s2-box-img {
  width: 40%;
}

.s2-box .s2-box-text {
  width: 60%;
  display: flex;
  gap: 23px;
  justify-content: center;
  flex-direction: column;
}

.s2-box .s2-box-text p {
  width: 90%;
}

/* Feature section  */

.feature-heading {
  font-size: 48px;
  width: 70%;
  line-height: 120%;
  margin-bottom: 32px;
}

.feature-sub-heading {
  font-size: 20px;
  width: 50%;
  margin-bottom: 60px;
}

.feature-box {
  border: 1px solid #eee;
  background: #fafafa;
  border-radius: 24px;
  margin-bottom: 32px;
}

.feature-box .feature-box-content {
  padding: 40px;
}

.feature-box .feature-box-content h4 {
  font-size: 24px;
  margin-bottom: 13px;
}

.feature-box .feature-box-content p {
  font-size: 20px;
}

/* service section  */

.service .service-heading {
  margin-bottom: 60px;
}

.service .swiper-wrapper {
  padding-bottom: 3rem;
}

.service .eachslide {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* Book now box section  */

.bookbox {
  margin-top: -50px;
}

.bookbox-1 {
  padding: 70px 0 120px 0;
  background-color: #ffffff;
}

.bookbox-1 h1 {
  margin-bottom: 60px;
}

.BBSI-box {
  position: relative;
}

.divider {
  border-right: 1px solid #d0d5dd;
  height: 56px;
  position: absolute;
  right: 0;
}

.bookbox-2 {
  height: 250px;
  margin-top: -40px;
  background: linear-gradient(#318281cc, #318280);
  border-radius: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.bookbox-2 .booknow-btn {
  border: 1px solid #fff;
  text-align: center;
  color: #fff;
  padding: 0.5rem 0.3rem;
  border-radius: 8px;
}
