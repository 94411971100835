@font-face {
    font-family: "Material Design Icons";
    src: url(/src/assets/fonts/materialdesignicons-webfont1d2d.eot?v=6.5.95);
    src: url(/src/assets/fonts/materialdesignicons-webfontd41d.eot?#iefix&v=6.5.95) format("embedded-opentype"),
        url(/src/assets/fonts/materialdesignicons-webfont1d2d.woff?v=6.5.95) format("woff"),
        url(/src/assets/fonts/materialdesignicons-webfont1d2d.ttf?v=6.5.95) format("truetype");
    font-weight: 400;
    font-style: normal
}

.mdi-set,
.mdi:before {
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.mdi-ab-testing::before {
    content: "\f01c9"
}

.mdi-abacus::before {
    content: "\f16e0"
}

.mdi-abjad-arabic::before {
    content: "\f1328"
}

.mdi-abjad-hebrew::before {
    content: "\f1329"
}

.mdi-abugida-devanagari::before {
    content: "\f132a"
}

.mdi-abugida-thai::before {
    content: "\f132b"
}

.mdi-access-point::before {
    content: "\f0003"
}

.mdi-access-point-check::before {
    content: "\f1538"
}

.mdi-access-point-minus::before {
    content: "\f1539"
}

.mdi-access-point-network::before {
    content: "\f0002"
}

.mdi-access-point-network-off::before {
    content: "\f0be1"
}

.mdi-access-point-off::before {
    content: "\f1511"
}

.mdi-access-point-plus::before {
    content: "\f153a"
}

.mdi-access-point-remove::before {
    content: "\f153b"
}

.mdi-account::before {
    content: "\f0004"
}

.mdi-account-alert::before {
    content: "\f0005"
}

.mdi-account-alert-outline::before {
    content: "\f0b50"
}

.mdi-account-arrow-down::before {
    content: "\f1868"
}

.mdi-account-arrow-down-outline::before {
    content: "\f1869"
}

.mdi-account-arrow-left::before {
    content: "\f0b51"
}

.mdi-account-arrow-left-outline::before {
    content: "\f0b52"
}

.mdi-account-arrow-right::before {
    content: "\f0b53"
}

.mdi-account-arrow-right-outline::before {
    content: "\f0b54"
}

.mdi-account-arrow-up::before {
    content: "\f1867"
}

.mdi-account-arrow-up-outline::before {
    content: "\f186a"
}

.mdi-account-box::before {
    content: "\f0006"
}

.mdi-account-box-multiple::before {
    content: "\f0934"
}

.mdi-account-box-multiple-outline::before {
    content: "\f100a"
}

.mdi-account-box-outline::before {
    content: "\f0007"
}

.mdi-account-cancel::before {
    content: "\f12df"
}

.mdi-account-cancel-outline::before {
    content: "\f12e0"
}

.mdi-account-cash::before {
    content: "\f1097"
}

.mdi-account-cash-outline::before {
    content: "\f1098"
}

.mdi-account-check::before {
    content: "\f0008"
}

.mdi-account-check-outline::before {
    content: "\f0be2"
}

.mdi-account-child::before {
    content: "\f0a89"
}

.mdi-account-child-circle::before {
    content: "\f0a8a"
}

.mdi-account-child-outline::before {
    content: "\f10c8"
}

.mdi-account-circle::before {
    content: "\f0009"
}

.mdi-account-circle-outline::before {
    content: "\f0b55"
}

.mdi-account-clock::before {
    content: "\f0b56"
}

.mdi-account-clock-outline::before {
    content: "\f0b57"
}

.mdi-account-cog::before {
    content: "\f1370"
}

.mdi-account-cog-outline::before {
    content: "\f1371"
}

.mdi-account-convert::before {
    content: "\f000a"
}

.mdi-account-convert-outline::before {
    content: "\f1301"
}

.mdi-account-cowboy-hat::before {
    content: "\f0e9b"
}

.mdi-account-cowboy-hat-outline::before {
    content: "\f17f3"
}

.mdi-account-details::before {
    content: "\f0631"
}

.mdi-account-details-outline::before {
    content: "\f1372"
}

.mdi-account-edit::before {
    content: "\f06bc"
}

.mdi-account-edit-outline::before {
    content: "\f0ffb"
}

.mdi-account-eye::before {
    content: "\f0420"
}

.mdi-account-eye-outline::before {
    content: "\f127b"
}

.mdi-account-filter::before {
    content: "\f0936"
}

.mdi-account-filter-outline::before {
    content: "\f0f9d"
}

.mdi-account-group::before {
    content: "\f0849"
}

.mdi-account-group-outline::before {
    content: "\f0b58"
}

.mdi-account-hard-hat::before {
    content: "\f05b5"
}

.mdi-account-heart::before {
    content: "\f0899"
}

.mdi-account-heart-outline::before {
    content: "\f0be3"
}

.mdi-account-injury::before {
    content: "\f1815"
}

.mdi-account-injury-outline::before {
    content: "\f1816"
}

.mdi-account-key::before {
    content: "\f000b"
}

.mdi-account-key-outline::before {
    content: "\f0be4"
}

.mdi-account-lock::before {
    content: "\f115e"
}

.mdi-account-lock-open::before {
    content: "\f1960"
}

.mdi-account-lock-open-outline::before {
    content: "\f1961"
}

.mdi-account-lock-outline::before {
    content: "\f115f"
}

.mdi-account-minus::before {
    content: "\f000d"
}

.mdi-account-minus-outline::before {
    content: "\f0aec"
}

.mdi-account-multiple::before {
    content: "\f000e"
}

.mdi-account-multiple-check::before {
    content: "\f08c5"
}

.mdi-account-multiple-check-outline::before {
    content: "\f11fe"
}

.mdi-account-multiple-minus::before {
    content: "\f05d3"
}

.mdi-account-multiple-minus-outline::before {
    content: "\f0be5"
}

.mdi-account-multiple-outline::before {
    content: "\f000f"
}

.mdi-account-multiple-plus::before {
    content: "\f0010"
}

.mdi-account-multiple-plus-outline::before {
    content: "\f0800"
}

.mdi-account-multiple-remove::before {
    content: "\f120a"
}

.mdi-account-multiple-remove-outline::before {
    content: "\f120b"
}

.mdi-account-music::before {
    content: "\f0803"
}

.mdi-account-music-outline::before {
    content: "\f0ce9"
}

.mdi-account-network::before {
    content: "\f0011"
}

.mdi-account-network-outline::before {
    content: "\f0be6"
}

.mdi-account-off::before {
    content: "\f0012"
}

.mdi-account-off-outline::before {
    content: "\f0be7"
}

.mdi-account-outline::before {
    content: "\f0013"
}

.mdi-account-plus::before {
    content: "\f0014"
}

.mdi-account-plus-outline::before {
    content: "\f0801"
}

.mdi-account-question::before {
    content: "\f0b59"
}

.mdi-account-question-outline::before {
    content: "\f0b5a"
}

.mdi-account-reactivate::before {
    content: "\f152b"
}

.mdi-account-reactivate-outline::before {
    content: "\f152c"
}

.mdi-account-remove::before {
    content: "\f0015"
}

.mdi-account-remove-outline::before {
    content: "\f0aed"
}

.mdi-account-search::before {
    content: "\f0016"
}

.mdi-account-search-outline::before {
    content: "\f0935"
}

.mdi-account-settings::before {
    content: "\f0630"
}

.mdi-account-settings-outline::before {
    content: "\f10c9"
}

.mdi-account-star::before {
    content: "\f0017"
}

.mdi-account-star-outline::before {
    content: "\f0be8"
}

.mdi-account-supervisor::before {
    content: "\f0a8b"
}

.mdi-account-supervisor-circle::before {
    content: "\f0a8c"
}

.mdi-account-supervisor-circle-outline::before {
    content: "\f14ec"
}

.mdi-account-supervisor-outline::before {
    content: "\f112d"
}

.mdi-account-switch::before {
    content: "\f0019"
}

.mdi-account-switch-outline::before {
    content: "\f04cb"
}

.mdi-account-sync::before {
    content: "\f191b"
}

.mdi-account-sync-outline::before {
    content: "\f191c"
}

.mdi-account-tie::before {
    content: "\f0ce3"
}

.mdi-account-tie-hat::before {
    content: "\f1898"
}

.mdi-account-tie-hat-outline::before {
    content: "\f1899"
}

.mdi-account-tie-outline::before {
    content: "\f10ca"
}

.mdi-account-tie-voice::before {
    content: "\f1308"
}

.mdi-account-tie-voice-off::before {
    content: "\f130a"
}

.mdi-account-tie-voice-off-outline::before {
    content: "\f130b"
}

.mdi-account-tie-voice-outline::before {
    content: "\f1309"
}

.mdi-account-voice::before {
    content: "\f05cb"
}

.mdi-account-voice-off::before {
    content: "\f0ed4"
}

.mdi-account-wrench::before {
    content: "\f189a"
}

.mdi-account-wrench-outline::before {
    content: "\f189b"
}

.mdi-adjust::before {
    content: "\f001a"
}

.mdi-advertisements::before {
    content: "\f192a"
}

.mdi-advertisements-off::before {
    content: "\f192b"
}

.mdi-air-conditioner::before {
    content: "\f001b"
}

.mdi-air-filter::before {
    content: "\f0d43"
}

.mdi-air-horn::before {
    content: "\f0dac"
}

.mdi-air-humidifier::before {
    content: "\f1099"
}

.mdi-air-humidifier-off::before {
    content: "\f1466"
}

.mdi-air-purifier::before {
    content: "\f0d44"
}

.mdi-airbag::before {
    content: "\f0be9"
}

.mdi-airballoon::before {
    content: "\f001c"
}

.mdi-airballoon-outline::before {
    content: "\f100b"
}

.mdi-airplane::before {
    content: "\f001d"
}

.mdi-airplane-alert::before {
    content: "\f187a"
}

.mdi-airplane-check::before {
    content: "\f187b"
}

.mdi-airplane-clock::before {
    content: "\f187c"
}

.mdi-airplane-cog::before {
    content: "\f187d"
}

.mdi-airplane-edit::before {
    content: "\f187e"
}

.mdi-airplane-landing::before {
    content: "\f05d4"
}

.mdi-airplane-marker::before {
    content: "\f187f"
}

.mdi-airplane-minus::before {
    content: "\f1880"
}

.mdi-airplane-off::before {
    content: "\f001e"
}

.mdi-airplane-plus::before {
    content: "\f1881"
}

.mdi-airplane-remove::before {
    content: "\f1882"
}

.mdi-airplane-search::before {
    content: "\f1883"
}

.mdi-airplane-settings::before {
    content: "\f1884"
}

.mdi-airplane-takeoff::before {
    content: "\f05d5"
}

.mdi-airport::before {
    content: "\f084b"
}

.mdi-alarm::before {
    content: "\f0020"
}

.mdi-alarm-bell::before {
    content: "\f078e"
}

.mdi-alarm-check::before {
    content: "\f0021"
}

.mdi-alarm-light::before {
    content: "\f078f"
}

.mdi-alarm-light-off::before {
    content: "\f171e"
}

.mdi-alarm-light-off-outline::before {
    content: "\f171f"
}

.mdi-alarm-light-outline::before {
    content: "\f0bea"
}

.mdi-alarm-multiple::before {
    content: "\f0022"
}

.mdi-alarm-note::before {
    content: "\f0e71"
}

.mdi-alarm-note-off::before {
    content: "\f0e72"
}

.mdi-alarm-off::before {
    content: "\f0023"
}

.mdi-alarm-panel::before {
    content: "\f15c4"
}

.mdi-alarm-panel-outline::before {
    content: "\f15c5"
}

.mdi-alarm-plus::before {
    content: "\f0024"
}

.mdi-alarm-snooze::before {
    content: "\f068e"
}

.mdi-album::before {
    content: "\f0025"
}

.mdi-alert::before {
    content: "\f0026"
}

.mdi-alert-box::before {
    content: "\f0027"
}

.mdi-alert-box-outline::before {
    content: "\f0ce4"
}

.mdi-alert-circle::before {
    content: "\f0028"
}

.mdi-alert-circle-check::before {
    content: "\f11ed"
}

.mdi-alert-circle-check-outline::before {
    content: "\f11ee"
}

.mdi-alert-circle-outline::before {
    content: "\f05d6"
}

.mdi-alert-decagram::before {
    content: "\f06bd"
}

.mdi-alert-decagram-outline::before {
    content: "\f0ce5"
}

.mdi-alert-minus::before {
    content: "\f14bb"
}

.mdi-alert-minus-outline::before {
    content: "\f14be"
}

.mdi-alert-octagon::before {
    content: "\f0029"
}

.mdi-alert-octagon-outline::before {
    content: "\f0ce6"
}

.mdi-alert-octagram::before {
    content: "\f0767"
}

.mdi-alert-octagram-outline::before {
    content: "\f0ce7"
}

.mdi-alert-outline::before {
    content: "\f002a"
}

.mdi-alert-plus::before {
    content: "\f14ba"
}

.mdi-alert-plus-outline::before {
    content: "\f14bd"
}

.mdi-alert-remove::before {
    content: "\f14bc"
}

.mdi-alert-remove-outline::before {
    content: "\f14bf"
}

.mdi-alert-rhombus::before {
    content: "\f11ce"
}

.mdi-alert-rhombus-outline::before {
    content: "\f11cf"
}

.mdi-alien::before {
    content: "\f089a"
}

.mdi-alien-outline::before {
    content: "\f10cb"
}

.mdi-align-horizontal-center::before {
    content: "\f11c3"
}

.mdi-align-horizontal-distribute::before {
    content: "\f1962"
}

.mdi-align-horizontal-left::before {
    content: "\f11c2"
}

.mdi-align-horizontal-right::before {
    content: "\f11c4"
}

.mdi-align-vertical-bottom::before {
    content: "\f11c5"
}

.mdi-align-vertical-center::before {
    content: "\f11c6"
}

.mdi-align-vertical-distribute::before {
    content: "\f1963"
}

.mdi-align-vertical-top::before {
    content: "\f11c7"
}

.mdi-all-inclusive::before {
    content: "\f06be"
}

.mdi-all-inclusive-box::before {
    content: "\f188d"
}

.mdi-all-inclusive-box-outline::before {
    content: "\f188e"
}

.mdi-allergy::before {
    content: "\f1258"
}

.mdi-alpha::before {
    content: "\f002b"
}

.mdi-alpha-a::before {
    content: "\f0aee"
}

.mdi-alpha-a-box::before {
    content: "\f0b08"
}

.mdi-alpha-a-box-outline::before {
    content: "\f0beb"
}

.mdi-alpha-a-circle::before {
    content: "\f0bec"
}

.mdi-alpha-a-circle-outline::before {
    content: "\f0bed"
}

.mdi-alpha-b::before {
    content: "\f0aef"
}

.mdi-alpha-b-box::before {
    content: "\f0b09"
}

.mdi-alpha-b-box-outline::before {
    content: "\f0bee"
}

.mdi-alpha-b-circle::before {
    content: "\f0bef"
}

.mdi-alpha-b-circle-outline::before {
    content: "\f0bf0"
}

.mdi-alpha-c::before {
    content: "\f0af0"
}

.mdi-alpha-c-box::before {
    content: "\f0b0a"
}

.mdi-alpha-c-box-outline::before {
    content: "\f0bf1"
}

.mdi-alpha-c-circle::before {
    content: "\f0bf2"
}

.mdi-alpha-c-circle-outline::before {
    content: "\f0bf3"
}

.mdi-alpha-d::before {
    content: "\f0af1"
}

.mdi-alpha-d-box::before {
    content: "\f0b0b"
}

.mdi-alpha-d-box-outline::before {
    content: "\f0bf4"
}

.mdi-alpha-d-circle::before {
    content: "\f0bf5"
}

.mdi-alpha-d-circle-outline::before {
    content: "\f0bf6"
}

.mdi-alpha-e::before {
    content: "\f0af2"
}

.mdi-alpha-e-box::before {
    content: "\f0b0c"
}

.mdi-alpha-e-box-outline::before {
    content: "\f0bf7"
}

.mdi-alpha-e-circle::before {
    content: "\f0bf8"
}

.mdi-alpha-e-circle-outline::before {
    content: "\f0bf9"
}

.mdi-alpha-f::before {
    content: "\f0af3"
}

.mdi-alpha-f-box::before {
    content: "\f0b0d"
}

.mdi-alpha-f-box-outline::before {
    content: "\f0bfa"
}

.mdi-alpha-f-circle::before {
    content: "\f0bfb"
}

.mdi-alpha-f-circle-outline::before {
    content: "\f0bfc"
}

.mdi-alpha-g::before {
    content: "\f0af4"
}

.mdi-alpha-g-box::before {
    content: "\f0b0e"
}

.mdi-alpha-g-box-outline::before {
    content: "\f0bfd"
}

.mdi-alpha-g-circle::before {
    content: "\f0bfe"
}

.mdi-alpha-g-circle-outline::before {
    content: "\f0bff"
}

.mdi-alpha-h::before {
    content: "\f0af5"
}

.mdi-alpha-h-box::before {
    content: "\f0b0f"
}

.mdi-alpha-h-box-outline::before {
    content: "\f0c00"
}

.mdi-alpha-h-circle::before {
    content: "\f0c01"
}

.mdi-alpha-h-circle-outline::before {
    content: "\f0c02"
}

.mdi-alpha-i::before {
    content: "\f0af6"
}

.mdi-alpha-i-box::before {
    content: "\f0b10"
}

.mdi-alpha-i-box-outline::before {
    content: "\f0c03"
}

.mdi-alpha-i-circle::before {
    content: "\f0c04"
}

.mdi-alpha-i-circle-outline::before {
    content: "\f0c05"
}

.mdi-alpha-j::before {
    content: "\f0af7"
}

.mdi-alpha-j-box::before {
    content: "\f0b11"
}

.mdi-alpha-j-box-outline::before {
    content: "\f0c06"
}

.mdi-alpha-j-circle::before {
    content: "\f0c07"
}

.mdi-alpha-j-circle-outline::before {
    content: "\f0c08"
}

.mdi-alpha-k::before {
    content: "\f0af8"
}

.mdi-alpha-k-box::before {
    content: "\f0b12"
}

.mdi-alpha-k-box-outline::before {
    content: "\f0c09"
}

.mdi-alpha-k-circle::before {
    content: "\f0c0a"
}

.mdi-alpha-k-circle-outline::before {
    content: "\f0c0b"
}

.mdi-alpha-l::before {
    content: "\f0af9"
}

.mdi-alpha-l-box::before {
    content: "\f0b13"
}

.mdi-alpha-l-box-outline::before {
    content: "\f0c0c"
}

.mdi-alpha-l-circle::before {
    content: "\f0c0d"
}

.mdi-alpha-l-circle-outline::before {
    content: "\f0c0e"
}

.mdi-alpha-m::before {
    content: "\f0afa"
}

.mdi-alpha-m-box::before {
    content: "\f0b14"
}

.mdi-alpha-m-box-outline::before {
    content: "\f0c0f"
}

.mdi-alpha-m-circle::before {
    content: "\f0c10"
}

.mdi-alpha-m-circle-outline::before {
    content: "\f0c11"
}

.mdi-alpha-n::before {
    content: "\f0afb"
}

.mdi-alpha-n-box::before {
    content: "\f0b15"
}

.mdi-alpha-n-box-outline::before {
    content: "\f0c12"
}

.mdi-alpha-n-circle::before {
    content: "\f0c13"
}

.mdi-alpha-n-circle-outline::before {
    content: "\f0c14"
}

.mdi-alpha-o::before {
    content: "\f0afc"
}

.mdi-alpha-o-box::before {
    content: "\f0b16"
}

.mdi-alpha-o-box-outline::before {
    content: "\f0c15"
}

.mdi-alpha-o-circle::before {
    content: "\f0c16"
}

.mdi-alpha-o-circle-outline::before {
    content: "\f0c17"
}

.mdi-alpha-p::before {
    content: "\f0afd"
}

.mdi-alpha-p-box::before {
    content: "\f0b17"
}

.mdi-alpha-p-box-outline::before {
    content: "\f0c18"
}

.mdi-alpha-p-circle::before {
    content: "\f0c19"
}

.mdi-alpha-p-circle-outline::before {
    content: "\f0c1a"
}

.mdi-alpha-q::before {
    content: "\f0afe"
}

.mdi-alpha-q-box::before {
    content: "\f0b18"
}

.mdi-alpha-q-box-outline::before {
    content: "\f0c1b"
}

.mdi-alpha-q-circle::before {
    content: "\f0c1c"
}

.mdi-alpha-q-circle-outline::before {
    content: "\f0c1d"
}

.mdi-alpha-r::before {
    content: "\f0aff"
}

.mdi-alpha-r-box::before {
    content: "\f0b19"
}

.mdi-alpha-r-box-outline::before {
    content: "\f0c1e"
}

.mdi-alpha-r-circle::before {
    content: "\f0c1f"
}

.mdi-alpha-r-circle-outline::before {
    content: "\f0c20"
}

.mdi-alpha-s::before {
    content: "\f0b00"
}

.mdi-alpha-s-box::before {
    content: "\f0b1a"
}

.mdi-alpha-s-box-outline::before {
    content: "\f0c21"
}

.mdi-alpha-s-circle::before {
    content: "\f0c22"
}

.mdi-alpha-s-circle-outline::before {
    content: "\f0c23"
}

.mdi-alpha-t::before {
    content: "\f0b01"
}

.mdi-alpha-t-box::before {
    content: "\f0b1b"
}

.mdi-alpha-t-box-outline::before {
    content: "\f0c24"
}

.mdi-alpha-t-circle::before {
    content: "\f0c25"
}

.mdi-alpha-t-circle-outline::before {
    content: "\f0c26"
}

.mdi-alpha-u::before {
    content: "\f0b02"
}

.mdi-alpha-u-box::before {
    content: "\f0b1c"
}

.mdi-alpha-u-box-outline::before {
    content: "\f0c27"
}

.mdi-alpha-u-circle::before {
    content: "\f0c28"
}

.mdi-alpha-u-circle-outline::before {
    content: "\f0c29"
}

.mdi-alpha-v::before {
    content: "\f0b03"
}

.mdi-alpha-v-box::before {
    content: "\f0b1d"
}

.mdi-alpha-v-box-outline::before {
    content: "\f0c2a"
}

.mdi-alpha-v-circle::before {
    content: "\f0c2b"
}

.mdi-alpha-v-circle-outline::before {
    content: "\f0c2c"
}

.mdi-alpha-w::before {
    content: "\f0b04"
}

.mdi-alpha-w-box::before {
    content: "\f0b1e"
}

.mdi-alpha-w-box-outline::before {
    content: "\f0c2d"
}

.mdi-alpha-w-circle::before {
    content: "\f0c2e"
}

.mdi-alpha-w-circle-outline::before {
    content: "\f0c2f"
}

.mdi-alpha-x::before {
    content: "\f0b05"
}

.mdi-alpha-x-box::before {
    content: "\f0b1f"
}

.mdi-alpha-x-box-outline::before {
    content: "\f0c30"
}

.mdi-alpha-x-circle::before {
    content: "\f0c31"
}

.mdi-alpha-x-circle-outline::before {
    content: "\f0c32"
}

.mdi-alpha-y::before {
    content: "\f0b06"
}

.mdi-alpha-y-box::before {
    content: "\f0b20"
}

.mdi-alpha-y-box-outline::before {
    content: "\f0c33"
}

.mdi-alpha-y-circle::before {
    content: "\f0c34"
}

.mdi-alpha-y-circle-outline::before {
    content: "\f0c35"
}

.mdi-alpha-z::before {
    content: "\f0b07"
}

.mdi-alpha-z-box::before {
    content: "\f0b21"
}

.mdi-alpha-z-box-outline::before {
    content: "\f0c36"
}

.mdi-alpha-z-circle::before {
    content: "\f0c37"
}

.mdi-alpha-z-circle-outline::before {
    content: "\f0c38"
}

.mdi-alphabet-aurebesh::before {
    content: "\f132c"
}

.mdi-alphabet-cyrillic::before {
    content: "\f132d"
}

.mdi-alphabet-greek::before {
    content: "\f132e"
}

.mdi-alphabet-latin::before {
    content: "\f132f"
}

.mdi-alphabet-piqad::before {
    content: "\f1330"
}

.mdi-alphabet-tengwar::before {
    content: "\f1337"
}

.mdi-alphabetical::before {
    content: "\f002c"
}

.mdi-alphabetical-off::before {
    content: "\f100c"
}

.mdi-alphabetical-variant::before {
    content: "\f100d"
}

.mdi-alphabetical-variant-off::before {
    content: "\f100e"
}

.mdi-altimeter::before {
    content: "\f05d7"
}

.mdi-ambulance::before {
    content: "\f002f"
}

.mdi-ammunition::before {
    content: "\f0ce8"
}

.mdi-ampersand::before {
    content: "\f0a8d"
}

.mdi-amplifier::before {
    content: "\f0030"
}

.mdi-amplifier-off::before {
    content: "\f11b5"
}

.mdi-anchor::before {
    content: "\f0031"
}

.mdi-android::before {
    content: "\f0032"
}

.mdi-android-messages::before {
    content: "\f0d45"
}

.mdi-android-studio::before {
    content: "\f0034"
}

.mdi-angle-acute::before {
    content: "\f0937"
}

.mdi-angle-obtuse::before {
    content: "\f0938"
}

.mdi-angle-right::before {
    content: "\f0939"
}

.mdi-angular::before {
    content: "\f06b2"
}

.mdi-angularjs::before {
    content: "\f06bf"
}

.mdi-animation::before {
    content: "\f05d8"
}

.mdi-animation-outline::before {
    content: "\f0a8f"
}

.mdi-animation-play::before {
    content: "\f093a"
}

.mdi-animation-play-outline::before {
    content: "\f0a90"
}

.mdi-ansible::before {
    content: "\f109a"
}

.mdi-antenna::before {
    content: "\f1119"
}

.mdi-anvil::before {
    content: "\f089b"
}

.mdi-apache-kafka::before {
    content: "\f100f"
}

.mdi-api::before {
    content: "\f109b"
}

.mdi-api-off::before {
    content: "\f1257"
}

.mdi-apple::before {
    content: "\f0035"
}

.mdi-apple-finder::before {
    content: "\f0036"
}

.mdi-apple-icloud::before {
    content: "\f0038"
}

.mdi-apple-ios::before {
    content: "\f0037"
}

.mdi-apple-keyboard-caps::before {
    content: "\f0632"
}

.mdi-apple-keyboard-command::before {
    content: "\f0633"
}

.mdi-apple-keyboard-control::before {
    content: "\f0634"
}

.mdi-apple-keyboard-option::before {
    content: "\f0635"
}

.mdi-apple-keyboard-shift::before {
    content: "\f0636"
}

.mdi-apple-safari::before {
    content: "\f0039"
}

.mdi-application::before {
    content: "\f08c6"
}

.mdi-application-array::before {
    content: "\f10f5"
}

.mdi-application-array-outline::before {
    content: "\f10f6"
}

.mdi-application-braces::before {
    content: "\f10f7"
}

.mdi-application-braces-outline::before {
    content: "\f10f8"
}

.mdi-application-brackets::before {
    content: "\f0c8b"
}

.mdi-application-brackets-outline::before {
    content: "\f0c8c"
}

.mdi-application-cog::before {
    content: "\f0675"
}

.mdi-application-cog-outline::before {
    content: "\f1577"
}

.mdi-application-edit::before {
    content: "\f00ae"
}

.mdi-application-edit-outline::before {
    content: "\f0619"
}

.mdi-application-export::before {
    content: "\f0dad"
}

.mdi-application-import::before {
    content: "\f0dae"
}

.mdi-application-outline::before {
    content: "\f0614"
}

.mdi-application-parentheses::before {
    content: "\f10f9"
}

.mdi-application-parentheses-outline::before {
    content: "\f10fa"
}

.mdi-application-settings::before {
    content: "\f0b60"
}

.mdi-application-settings-outline::before {
    content: "\f1555"
}

.mdi-application-variable::before {
    content: "\f10fb"
}

.mdi-application-variable-outline::before {
    content: "\f10fc"
}

.mdi-approximately-equal::before {
    content: "\f0f9e"
}

.mdi-approximately-equal-box::before {
    content: "\f0f9f"
}

.mdi-apps::before {
    content: "\f003b"
}

.mdi-apps-box::before {
    content: "\f0d46"
}

.mdi-arch::before {
    content: "\f08c7"
}

.mdi-archive::before {
    content: "\f003c"
}

.mdi-archive-alert::before {
    content: "\f14fd"
}

.mdi-archive-alert-outline::before {
    content: "\f14fe"
}

.mdi-archive-arrow-down::before {
    content: "\f1259"
}

.mdi-archive-arrow-down-outline::before {
    content: "\f125a"
}

.mdi-archive-arrow-up::before {
    content: "\f125b"
}

.mdi-archive-arrow-up-outline::before {
    content: "\f125c"
}

.mdi-archive-cancel::before {
    content: "\f174b"
}

.mdi-archive-cancel-outline::before {
    content: "\f174c"
}

.mdi-archive-check::before {
    content: "\f174d"
}

.mdi-archive-check-outline::before {
    content: "\f174e"
}

.mdi-archive-clock::before {
    content: "\f174f"
}

.mdi-archive-clock-outline::before {
    content: "\f1750"
}

.mdi-archive-cog::before {
    content: "\f1751"
}

.mdi-archive-cog-outline::before {
    content: "\f1752"
}

.mdi-archive-edit::before {
    content: "\f1753"
}

.mdi-archive-edit-outline::before {
    content: "\f1754"
}

.mdi-archive-eye::before {
    content: "\f1755"
}

.mdi-archive-eye-outline::before {
    content: "\f1756"
}

.mdi-archive-lock::before {
    content: "\f1757"
}

.mdi-archive-lock-open::before {
    content: "\f1758"
}

.mdi-archive-lock-open-outline::before {
    content: "\f1759"
}

.mdi-archive-lock-outline::before {
    content: "\f175a"
}

.mdi-archive-marker::before {
    content: "\f175b"
}

.mdi-archive-marker-outline::before {
    content: "\f175c"
}

.mdi-archive-minus::before {
    content: "\f175d"
}

.mdi-archive-minus-outline::before {
    content: "\f175e"
}

.mdi-archive-music::before {
    content: "\f175f"
}

.mdi-archive-music-outline::before {
    content: "\f1760"
}

.mdi-archive-off::before {
    content: "\f1761"
}

.mdi-archive-off-outline::before {
    content: "\f1762"
}

.mdi-archive-outline::before {
    content: "\f120e"
}

.mdi-archive-plus::before {
    content: "\f1763"
}

.mdi-archive-plus-outline::before {
    content: "\f1764"
}

.mdi-archive-refresh::before {
    content: "\f1765"
}

.mdi-archive-refresh-outline::before {
    content: "\f1766"
}

.mdi-archive-remove::before {
    content: "\f1767"
}

.mdi-archive-remove-outline::before {
    content: "\f1768"
}

.mdi-archive-search::before {
    content: "\f1769"
}

.mdi-archive-search-outline::before {
    content: "\f176a"
}

.mdi-archive-settings::before {
    content: "\f176b"
}

.mdi-archive-settings-outline::before {
    content: "\f176c"
}

.mdi-archive-star::before {
    content: "\f176d"
}

.mdi-archive-star-outline::before {
    content: "\f176e"
}

.mdi-archive-sync::before {
    content: "\f176f"
}

.mdi-archive-sync-outline::before {
    content: "\f1770"
}

.mdi-arm-flex::before {
    content: "\f0fd7"
}

.mdi-arm-flex-outline::before {
    content: "\f0fd6"
}

.mdi-arrange-bring-forward::before {
    content: "\f003d"
}

.mdi-arrange-bring-to-front::before {
    content: "\f003e"
}

.mdi-arrange-send-backward::before {
    content: "\f003f"
}

.mdi-arrange-send-to-back::before {
    content: "\f0040"
}

.mdi-arrow-all::before {
    content: "\f0041"
}

.mdi-arrow-bottom-left::before {
    content: "\f0042"
}

.mdi-arrow-bottom-left-bold-box::before {
    content: "\f1964"
}

.mdi-arrow-bottom-left-bold-box-outline::before {
    content: "\f1965"
}

.mdi-arrow-bottom-left-bold-outline::before {
    content: "\f09b7"
}

.mdi-arrow-bottom-left-thick::before {
    content: "\f09b8"
}

.mdi-arrow-bottom-left-thin::before {
    content: "\f19b6"
}

.mdi-arrow-bottom-left-thin-circle-outline::before {
    content: "\f1596"
}

.mdi-arrow-bottom-right::before {
    content: "\f0043"
}

.mdi-arrow-bottom-right-bold-box::before {
    content: "\f1966"
}

.mdi-arrow-bottom-right-bold-box-outline::before {
    content: "\f1967"
}

.mdi-arrow-bottom-right-bold-outline::before {
    content: "\f09b9"
}

.mdi-arrow-bottom-right-thick::before {
    content: "\f09ba"
}

.mdi-arrow-bottom-right-thin::before {
    content: "\f19b7"
}

.mdi-arrow-bottom-right-thin-circle-outline::before {
    content: "\f1595"
}

.mdi-arrow-collapse::before {
    content: "\f0615"
}

.mdi-arrow-collapse-all::before {
    content: "\f0044"
}

.mdi-arrow-collapse-down::before {
    content: "\f0792"
}

.mdi-arrow-collapse-horizontal::before {
    content: "\f084c"
}

.mdi-arrow-collapse-left::before {
    content: "\f0793"
}

.mdi-arrow-collapse-right::before {
    content: "\f0794"
}

.mdi-arrow-collapse-up::before {
    content: "\f0795"
}

.mdi-arrow-collapse-vertical::before {
    content: "\f084d"
}

.mdi-arrow-decision::before {
    content: "\f09bb"
}

.mdi-arrow-decision-auto::before {
    content: "\f09bc"
}

.mdi-arrow-decision-auto-outline::before {
    content: "\f09bd"
}

.mdi-arrow-decision-outline::before {
    content: "\f09be"
}

.mdi-arrow-down::before {
    content: "\f0045"
}

.mdi-arrow-down-bold::before {
    content: "\f072e"
}

.mdi-arrow-down-bold-box::before {
    content: "\f072f"
}

.mdi-arrow-down-bold-box-outline::before {
    content: "\f0730"
}

.mdi-arrow-down-bold-circle::before {
    content: "\f0047"
}

.mdi-arrow-down-bold-circle-outline::before {
    content: "\f0048"
}

.mdi-arrow-down-bold-hexagon-outline::before {
    content: "\f0049"
}

.mdi-arrow-down-bold-outline::before {
    content: "\f09bf"
}

.mdi-arrow-down-box::before {
    content: "\f06c0"
}

.mdi-arrow-down-circle::before {
    content: "\f0cdb"
}

.mdi-arrow-down-circle-outline::before {
    content: "\f0cdc"
}

.mdi-arrow-down-drop-circle::before {
    content: "\f004a"
}

.mdi-arrow-down-drop-circle-outline::before {
    content: "\f004b"
}

.mdi-arrow-down-left::before {
    content: "\f17a1"
}

.mdi-arrow-down-left-bold::before {
    content: "\f17a2"
}

.mdi-arrow-down-right::before {
    content: "\f17a3"
}

.mdi-arrow-down-right-bold::before {
    content: "\f17a4"
}

.mdi-arrow-down-thick::before {
    content: "\f0046"
}

.mdi-arrow-down-thin::before {
    content: "\f19b3"
}

.mdi-arrow-down-thin-circle-outline::before {
    content: "\f1599"
}

.mdi-arrow-expand::before {
    content: "\f0616"
}

.mdi-arrow-expand-all::before {
    content: "\f004c"
}

.mdi-arrow-expand-down::before {
    content: "\f0796"
}

.mdi-arrow-expand-horizontal::before {
    content: "\f084e"
}

.mdi-arrow-expand-left::before {
    content: "\f0797"
}

.mdi-arrow-expand-right::before {
    content: "\f0798"
}

.mdi-arrow-expand-up::before {
    content: "\f0799"
}

.mdi-arrow-expand-vertical::before {
    content: "\f084f"
}

.mdi-arrow-horizontal-lock::before {
    content: "\f115b"
}

.mdi-arrow-left::before {
    content: "\f004d"
}

.mdi-arrow-left-bold::before {
    content: "\f0731"
}

.mdi-arrow-left-bold-box::before {
    content: "\f0732"
}

.mdi-arrow-left-bold-box-outline::before {
    content: "\f0733"
}

.mdi-arrow-left-bold-circle::before {
    content: "\f004f"
}

.mdi-arrow-left-bold-circle-outline::before {
    content: "\f0050"
}

.mdi-arrow-left-bold-hexagon-outline::before {
    content: "\f0051"
}

.mdi-arrow-left-bold-outline::before {
    content: "\f09c0"
}

.mdi-arrow-left-bottom::before {
    content: "\f17a5"
}

.mdi-arrow-left-bottom-bold::before {
    content: "\f17a6"
}

.mdi-arrow-left-box::before {
    content: "\f06c1"
}

.mdi-arrow-left-circle::before {
    content: "\f0cdd"
}

.mdi-arrow-left-circle-outline::before {
    content: "\f0cde"
}

.mdi-arrow-left-drop-circle::before {
    content: "\f0052"
}

.mdi-arrow-left-drop-circle-outline::before {
    content: "\f0053"
}

.mdi-arrow-left-right::before {
    content: "\f0e73"
}

.mdi-arrow-left-right-bold::before {
    content: "\f0e74"
}

.mdi-arrow-left-right-bold-outline::before {
    content: "\f09c1"
}

.mdi-arrow-left-thick::before {
    content: "\f004e"
}

.mdi-arrow-left-thin::before {
    content: "\f19b1"
}

.mdi-arrow-left-thin-circle-outline::before {
    content: "\f159a"
}

.mdi-arrow-left-top::before {
    content: "\f17a7"
}

.mdi-arrow-left-top-bold::before {
    content: "\f17a8"
}

.mdi-arrow-projectile::before {
    content: "\f1840"
}

.mdi-arrow-projectile-multiple::before {
    content: "\f183f"
}

.mdi-arrow-right::before {
    content: "\f0054"
}

.mdi-arrow-right-bold::before {
    content: "\f0734"
}

.mdi-arrow-right-bold-box::before {
    content: "\f0735"
}

.mdi-arrow-right-bold-box-outline::before {
    content: "\f0736"
}

.mdi-arrow-right-bold-circle::before {
    content: "\f0056"
}

.mdi-arrow-right-bold-circle-outline::before {
    content: "\f0057"
}

.mdi-arrow-right-bold-hexagon-outline::before {
    content: "\f0058"
}

.mdi-arrow-right-bold-outline::before {
    content: "\f09c2"
}

.mdi-arrow-right-bottom::before {
    content: "\f17a9"
}

.mdi-arrow-right-bottom-bold::before {
    content: "\f17aa"
}

.mdi-arrow-right-box::before {
    content: "\f06c2"
}

.mdi-arrow-right-circle::before {
    content: "\f0cdf"
}

.mdi-arrow-right-circle-outline::before {
    content: "\f0ce0"
}

.mdi-arrow-right-drop-circle::before {
    content: "\f0059"
}

.mdi-arrow-right-drop-circle-outline::before {
    content: "\f005a"
}

.mdi-arrow-right-thick::before {
    content: "\f0055"
}

.mdi-arrow-right-thin::before {
    content: "\f19b0"
}

.mdi-arrow-right-thin-circle-outline::before {
    content: "\f1598"
}

.mdi-arrow-right-top::before {
    content: "\f17ab"
}

.mdi-arrow-right-top-bold::before {
    content: "\f17ac"
}

.mdi-arrow-split-horizontal::before {
    content: "\f093b"
}

.mdi-arrow-split-vertical::before {
    content: "\f093c"
}

.mdi-arrow-top-left::before {
    content: "\f005b"
}

.mdi-arrow-top-left-bold-box::before {
    content: "\f1968"
}

.mdi-arrow-top-left-bold-box-outline::before {
    content: "\f1969"
}

.mdi-arrow-top-left-bold-outline::before {
    content: "\f09c3"
}

.mdi-arrow-top-left-bottom-right::before {
    content: "\f0e75"
}

.mdi-arrow-top-left-bottom-right-bold::before {
    content: "\f0e76"
}

.mdi-arrow-top-left-thick::before {
    content: "\f09c4"
}

.mdi-arrow-top-left-thin::before {
    content: "\f19b5"
}

.mdi-arrow-top-left-thin-circle-outline::before {
    content: "\f1593"
}

.mdi-arrow-top-right::before {
    content: "\f005c"
}

.mdi-arrow-top-right-bold-box::before {
    content: "\f196a"
}

.mdi-arrow-top-right-bold-box-outline::before {
    content: "\f196b"
}

.mdi-arrow-top-right-bold-outline::before {
    content: "\f09c5"
}

.mdi-arrow-top-right-bottom-left::before {
    content: "\f0e77"
}

.mdi-arrow-top-right-bottom-left-bold::before {
    content: "\f0e78"
}

.mdi-arrow-top-right-thick::before {
    content: "\f09c6"
}

.mdi-arrow-top-right-thin::before {
    content: "\f19b4"
}

.mdi-arrow-top-right-thin-circle-outline::before {
    content: "\f1594"
}

.mdi-arrow-u-down-left::before {
    content: "\f17ad"
}

.mdi-arrow-u-down-left-bold::before {
    content: "\f17ae"
}

.mdi-arrow-u-down-right::before {
    content: "\f17af"
}

.mdi-arrow-u-down-right-bold::before {
    content: "\f17b0"
}

.mdi-arrow-u-left-bottom::before {
    content: "\f17b1"
}

.mdi-arrow-u-left-bottom-bold::before {
    content: "\f17b2"
}

.mdi-arrow-u-left-top::before {
    content: "\f17b3"
}

.mdi-arrow-u-left-top-bold::before {
    content: "\f17b4"
}

.mdi-arrow-u-right-bottom::before {
    content: "\f17b5"
}

.mdi-arrow-u-right-bottom-bold::before {
    content: "\f17b6"
}

.mdi-arrow-u-right-top::before {
    content: "\f17b7"
}

.mdi-arrow-u-right-top-bold::before {
    content: "\f17b8"
}

.mdi-arrow-u-up-left::before {
    content: "\f17b9"
}

.mdi-arrow-u-up-left-bold::before {
    content: "\f17ba"
}

.mdi-arrow-u-up-right::before {
    content: "\f17bb"
}

.mdi-arrow-u-up-right-bold::before {
    content: "\f17bc"
}

.mdi-arrow-up::before {
    content: "\f005d"
}

.mdi-arrow-up-bold::before {
    content: "\f0737"
}

.mdi-arrow-up-bold-box::before {
    content: "\f0738"
}

.mdi-arrow-up-bold-box-outline::before {
    content: "\f0739"
}

.mdi-arrow-up-bold-circle::before {
    content: "\f005f"
}

.mdi-arrow-up-bold-circle-outline::before {
    content: "\f0060"
}

.mdi-arrow-up-bold-hexagon-outline::before {
    content: "\f0061"
}

.mdi-arrow-up-bold-outline::before {
    content: "\f09c7"
}

.mdi-arrow-up-box::before {
    content: "\f06c3"
}

.mdi-arrow-up-circle::before {
    content: "\f0ce1"
}

.mdi-arrow-up-circle-outline::before {
    content: "\f0ce2"
}

.mdi-arrow-up-down::before {
    content: "\f0e79"
}

.mdi-arrow-up-down-bold::before {
    content: "\f0e7a"
}

.mdi-arrow-up-down-bold-outline::before {
    content: "\f09c8"
}

.mdi-arrow-up-drop-circle::before {
    content: "\f0062"
}

.mdi-arrow-up-drop-circle-outline::before {
    content: "\f0063"
}

.mdi-arrow-up-left::before {
    content: "\f17bd"
}

.mdi-arrow-up-left-bold::before {
    content: "\f17be"
}

.mdi-arrow-up-right::before {
    content: "\f17bf"
}

.mdi-arrow-up-right-bold::before {
    content: "\f17c0"
}

.mdi-arrow-up-thick::before {
    content: "\f005e"
}

.mdi-arrow-up-thin::before {
    content: "\f19b2"
}

.mdi-arrow-up-thin-circle-outline::before {
    content: "\f1597"
}

.mdi-arrow-vertical-lock::before {
    content: "\f115c"
}

.mdi-artstation::before {
    content: "\f0b5b"
}

.mdi-aspect-ratio::before {
    content: "\f0a24"
}

.mdi-assistant::before {
    content: "\f0064"
}

.mdi-asterisk::before {
    content: "\f06c4"
}

.mdi-at::before {
    content: "\f0065"
}

.mdi-atlassian::before {
    content: "\f0804"
}

.mdi-atm::before {
    content: "\f0d47"
}

.mdi-atom::before {
    content: "\f0768"
}

.mdi-atom-variant::before {
    content: "\f0e7b"
}

.mdi-attachment::before {
    content: "\f0066"
}

.mdi-audio-input-rca::before {
    content: "\f186b"
}

.mdi-audio-input-stereo-minijack::before {
    content: "\f186c"
}

.mdi-audio-input-xlr::before {
    content: "\f186d"
}

.mdi-audio-video::before {
    content: "\f093d"
}

.mdi-audio-video-off::before {
    content: "\f11b6"
}

.mdi-augmented-reality::before {
    content: "\f0850"
}

.mdi-auto-download::before {
    content: "\f137e"
}

.mdi-auto-fix::before {
    content: "\f0068"
}

.mdi-auto-upload::before {
    content: "\f0069"
}

.mdi-autorenew::before {
    content: "\f006a"
}

.mdi-av-timer::before {
    content: "\f006b"
}

.mdi-aws::before {
    content: "\f0e0f"
}

.mdi-axe::before {
    content: "\f08c8"
}

.mdi-axe-battle::before {
    content: "\f1842"
}

.mdi-axis::before {
    content: "\f0d48"
}

.mdi-axis-arrow::before {
    content: "\f0d49"
}

.mdi-axis-arrow-info::before {
    content: "\f140e"
}

.mdi-axis-arrow-lock::before {
    content: "\f0d4a"
}

.mdi-axis-lock::before {
    content: "\f0d4b"
}

.mdi-axis-x-arrow::before {
    content: "\f0d4c"
}

.mdi-axis-x-arrow-lock::before {
    content: "\f0d4d"
}

.mdi-axis-x-rotate-clockwise::before {
    content: "\f0d4e"
}

.mdi-axis-x-rotate-counterclockwise::before {
    content: "\f0d4f"
}

.mdi-axis-x-y-arrow-lock::before {
    content: "\f0d50"
}

.mdi-axis-y-arrow::before {
    content: "\f0d51"
}

.mdi-axis-y-arrow-lock::before {
    content: "\f0d52"
}

.mdi-axis-y-rotate-clockwise::before {
    content: "\f0d53"
}

.mdi-axis-y-rotate-counterclockwise::before {
    content: "\f0d54"
}

.mdi-axis-z-arrow::before {
    content: "\f0d55"
}

.mdi-axis-z-arrow-lock::before {
    content: "\f0d56"
}

.mdi-axis-z-rotate-clockwise::before {
    content: "\f0d57"
}

.mdi-axis-z-rotate-counterclockwise::before {
    content: "\f0d58"
}

.mdi-babel::before {
    content: "\f0a25"
}

.mdi-baby::before {
    content: "\f006c"
}

.mdi-baby-bottle::before {
    content: "\f0f39"
}

.mdi-baby-bottle-outline::before {
    content: "\f0f3a"
}

.mdi-baby-buggy::before {
    content: "\f13e0"
}

.mdi-baby-carriage::before {
    content: "\f068f"
}

.mdi-baby-carriage-off::before {
    content: "\f0fa0"
}

.mdi-baby-face::before {
    content: "\f0e7c"
}

.mdi-baby-face-outline::before {
    content: "\f0e7d"
}

.mdi-backburger::before {
    content: "\f006d"
}

.mdi-backspace::before {
    content: "\f006e"
}

.mdi-backspace-outline::before {
    content: "\f0b5c"
}

.mdi-backspace-reverse::before {
    content: "\f0e7e"
}

.mdi-backspace-reverse-outline::before {
    content: "\f0e7f"
}

.mdi-backup-restore::before {
    content: "\f006f"
}

.mdi-bacteria::before {
    content: "\f0ed5"
}

.mdi-bacteria-outline::before {
    content: "\f0ed6"
}

.mdi-badge-account::before {
    content: "\f0da7"
}

.mdi-badge-account-alert::before {
    content: "\f0da8"
}

.mdi-badge-account-alert-outline::before {
    content: "\f0da9"
}

.mdi-badge-account-horizontal::before {
    content: "\f0e0d"
}

.mdi-badge-account-horizontal-outline::before {
    content: "\f0e0e"
}

.mdi-badge-account-outline::before {
    content: "\f0daa"
}

.mdi-badminton::before {
    content: "\f0851"
}

.mdi-bag-carry-on::before {
    content: "\f0f3b"
}

.mdi-bag-carry-on-check::before {
    content: "\f0d65"
}

.mdi-bag-carry-on-off::before {
    content: "\f0f3c"
}

.mdi-bag-checked::before {
    content: "\f0f3d"
}

.mdi-bag-personal::before {
    content: "\f0e10"
}

.mdi-bag-personal-off::before {
    content: "\f0e11"
}

.mdi-bag-personal-off-outline::before {
    content: "\f0e12"
}

.mdi-bag-personal-outline::before {
    content: "\f0e13"
}

.mdi-bag-suitcase::before {
    content: "\f158b"
}

.mdi-bag-suitcase-off::before {
    content: "\f158d"
}

.mdi-bag-suitcase-off-outline::before {
    content: "\f158e"
}

.mdi-bag-suitcase-outline::before {
    content: "\f158c"
}

.mdi-baguette::before {
    content: "\f0f3e"
}

.mdi-balcony::before {
    content: "\f1817"
}

.mdi-balloon::before {
    content: "\f0a26"
}

.mdi-ballot::before {
    content: "\f09c9"
}

.mdi-ballot-outline::before {
    content: "\f09ca"
}

.mdi-ballot-recount::before {
    content: "\f0c39"
}

.mdi-ballot-recount-outline::before {
    content: "\f0c3a"
}

.mdi-bandage::before {
    content: "\f0daf"
}

.mdi-bank::before {
    content: "\f0070"
}

.mdi-bank-check::before {
    content: "\f1655"
}

.mdi-bank-minus::before {
    content: "\f0db0"
}

.mdi-bank-off::before {
    content: "\f1656"
}

.mdi-bank-off-outline::before {
    content: "\f1657"
}

.mdi-bank-outline::before {
    content: "\f0e80"
}

.mdi-bank-plus::before {
    content: "\f0db1"
}

.mdi-bank-remove::before {
    content: "\f0db2"
}

.mdi-bank-transfer::before {
    content: "\f0a27"
}

.mdi-bank-transfer-in::before {
    content: "\f0a28"
}

.mdi-bank-transfer-out::before {
    content: "\f0a29"
}

.mdi-barcode::before {
    content: "\f0071"
}

.mdi-barcode-off::before {
    content: "\f1236"
}

.mdi-barcode-scan::before {
    content: "\f0072"
}

.mdi-barley::before {
    content: "\f0073"
}

.mdi-barley-off::before {
    content: "\f0b5d"
}

.mdi-barn::before {
    content: "\f0b5e"
}

.mdi-barrel::before {
    content: "\f0074"
}

.mdi-baseball::before {
    content: "\f0852"
}

.mdi-baseball-bat::before {
    content: "\f0853"
}

.mdi-baseball-diamond::before {
    content: "\f15ec"
}

.mdi-baseball-diamond-outline::before {
    content: "\f15ed"
}

.mdi-bash::before {
    content: "\f1183"
}

.mdi-basket::before {
    content: "\f0076"
}

.mdi-basket-check::before {
    content: "\f18e5"
}

.mdi-basket-check-outline::before {
    content: "\f18e6"
}

.mdi-basket-fill::before {
    content: "\f0077"
}

.mdi-basket-minus::before {
    content: "\f1523"
}

.mdi-basket-minus-outline::before {
    content: "\f1524"
}

.mdi-basket-off::before {
    content: "\f1525"
}

.mdi-basket-off-outline::before {
    content: "\f1526"
}

.mdi-basket-outline::before {
    content: "\f1181"
}

.mdi-basket-plus::before {
    content: "\f1527"
}

.mdi-basket-plus-outline::before {
    content: "\f1528"
}

.mdi-basket-remove::before {
    content: "\f1529"
}

.mdi-basket-remove-outline::before {
    content: "\f152a"
}

.mdi-basket-unfill::before {
    content: "\f0078"
}

.mdi-basketball::before {
    content: "\f0806"
}

.mdi-basketball-hoop::before {
    content: "\f0c3b"
}

.mdi-basketball-hoop-outline::before {
    content: "\f0c3c"
}

.mdi-bat::before {
    content: "\f0b5f"
}

.mdi-bathtub::before {
    content: "\f1818"
}

.mdi-bathtub-outline::before {
    content: "\f1819"
}

.mdi-battery::before {
    content: "\f0079"
}

.mdi-battery-10::before {
    content: "\f007a"
}

.mdi-battery-10-bluetooth::before {
    content: "\f093e"
}

.mdi-battery-20::before {
    content: "\f007b"
}

.mdi-battery-20-bluetooth::before {
    content: "\f093f"
}

.mdi-battery-30::before {
    content: "\f007c"
}

.mdi-battery-30-bluetooth::before {
    content: "\f0940"
}

.mdi-battery-40::before {
    content: "\f007d"
}

.mdi-battery-40-bluetooth::before {
    content: "\f0941"
}

.mdi-battery-50::before {
    content: "\f007e"
}

.mdi-battery-50-bluetooth::before {
    content: "\f0942"
}

.mdi-battery-60::before {
    content: "\f007f"
}

.mdi-battery-60-bluetooth::before {
    content: "\f0943"
}

.mdi-battery-70::before {
    content: "\f0080"
}

.mdi-battery-70-bluetooth::before {
    content: "\f0944"
}

.mdi-battery-80::before {
    content: "\f0081"
}

.mdi-battery-80-bluetooth::before {
    content: "\f0945"
}

.mdi-battery-90::before {
    content: "\f0082"
}

.mdi-battery-90-bluetooth::before {
    content: "\f0946"
}

.mdi-battery-alert::before {
    content: "\f0083"
}

.mdi-battery-alert-bluetooth::before {
    content: "\f0947"
}

.mdi-battery-alert-variant::before {
    content: "\f10cc"
}

.mdi-battery-alert-variant-outline::before {
    content: "\f10cd"
}

.mdi-battery-arrow-down::before {
    content: "\f17de"
}

.mdi-battery-arrow-down-outline::before {
    content: "\f17df"
}

.mdi-battery-arrow-up::before {
    content: "\f17e0"
}

.mdi-battery-arrow-up-outline::before {
    content: "\f17e1"
}

.mdi-battery-bluetooth::before {
    content: "\f0948"
}

.mdi-battery-bluetooth-variant::before {
    content: "\f0949"
}

.mdi-battery-charging::before {
    content: "\f0084"
}

.mdi-battery-charging-10::before {
    content: "\f089c"
}

.mdi-battery-charging-100::before {
    content: "\f0085"
}

.mdi-battery-charging-20::before {
    content: "\f0086"
}

.mdi-battery-charging-30::before {
    content: "\f0087"
}

.mdi-battery-charging-40::before {
    content: "\f0088"
}

.mdi-battery-charging-50::before {
    content: "\f089d"
}

.mdi-battery-charging-60::before {
    content: "\f0089"
}

.mdi-battery-charging-70::before {
    content: "\f089e"
}

.mdi-battery-charging-80::before {
    content: "\f008a"
}

.mdi-battery-charging-90::before {
    content: "\f008b"
}

.mdi-battery-charging-high::before {
    content: "\f12a6"
}

.mdi-battery-charging-low::before {
    content: "\f12a4"
}

.mdi-battery-charging-medium::before {
    content: "\f12a5"
}

.mdi-battery-charging-outline::before {
    content: "\f089f"
}

.mdi-battery-charging-wireless::before {
    content: "\f0807"
}

.mdi-battery-charging-wireless-10::before {
    content: "\f0808"
}

.mdi-battery-charging-wireless-20::before {
    content: "\f0809"
}

.mdi-battery-charging-wireless-30::before {
    content: "\f080a"
}

.mdi-battery-charging-wireless-40::before {
    content: "\f080b"
}

.mdi-battery-charging-wireless-50::before {
    content: "\f080c"
}

.mdi-battery-charging-wireless-60::before {
    content: "\f080d"
}

.mdi-battery-charging-wireless-70::before {
    content: "\f080e"
}

.mdi-battery-charging-wireless-80::before {
    content: "\f080f"
}

.mdi-battery-charging-wireless-90::before {
    content: "\f0810"
}

.mdi-battery-charging-wireless-alert::before {
    content: "\f0811"
}

.mdi-battery-charging-wireless-outline::before {
    content: "\f0812"
}

.mdi-battery-check::before {
    content: "\f17e2"
}

.mdi-battery-check-outline::before {
    content: "\f17e3"
}

.mdi-battery-heart::before {
    content: "\f120f"
}

.mdi-battery-heart-outline::before {
    content: "\f1210"
}

.mdi-battery-heart-variant::before {
    content: "\f1211"
}

.mdi-battery-high::before {
    content: "\f12a3"
}

.mdi-battery-lock::before {
    content: "\f179c"
}

.mdi-battery-lock-open::before {
    content: "\f179d"
}

.mdi-battery-low::before {
    content: "\f12a1"
}

.mdi-battery-medium::before {
    content: "\f12a2"
}

.mdi-battery-minus::before {
    content: "\f17e4"
}

.mdi-battery-minus-outline::before {
    content: "\f17e5"
}

.mdi-battery-minus-variant::before {
    content: "\f008c"
}

.mdi-battery-negative::before {
    content: "\f008d"
}

.mdi-battery-off::before {
    content: "\f125d"
}

.mdi-battery-off-outline::before {
    content: "\f125e"
}

.mdi-battery-outline::before {
    content: "\f008e"
}

.mdi-battery-plus::before {
    content: "\f17e6"
}

.mdi-battery-plus-outline::before {
    content: "\f17e7"
}

.mdi-battery-plus-variant::before {
    content: "\f008f"
}

.mdi-battery-positive::before {
    content: "\f0090"
}

.mdi-battery-remove::before {
    content: "\f17e8"
}

.mdi-battery-remove-outline::before {
    content: "\f17e9"
}

.mdi-battery-sync::before {
    content: "\f1834"
}

.mdi-battery-sync-outline::before {
    content: "\f1835"
}

.mdi-battery-unknown::before {
    content: "\f0091"
}

.mdi-battery-unknown-bluetooth::before {
    content: "\f094a"
}

.mdi-beach::before {
    content: "\f0092"
}

.mdi-beaker::before {
    content: "\f0cea"
}

.mdi-beaker-alert::before {
    content: "\f1229"
}

.mdi-beaker-alert-outline::before {
    content: "\f122a"
}

.mdi-beaker-check::before {
    content: "\f122b"
}

.mdi-beaker-check-outline::before {
    content: "\f122c"
}

.mdi-beaker-minus::before {
    content: "\f122d"
}

.mdi-beaker-minus-outline::before {
    content: "\f122e"
}

.mdi-beaker-outline::before {
    content: "\f0690"
}

.mdi-beaker-plus::before {
    content: "\f122f"
}

.mdi-beaker-plus-outline::before {
    content: "\f1230"
}

.mdi-beaker-question::before {
    content: "\f1231"
}

.mdi-beaker-question-outline::before {
    content: "\f1232"
}

.mdi-beaker-remove::before {
    content: "\f1233"
}

.mdi-beaker-remove-outline::before {
    content: "\f1234"
}

.mdi-bed::before {
    content: "\f02e3"
}

.mdi-bed-double::before {
    content: "\f0fd4"
}

.mdi-bed-double-outline::before {
    content: "\f0fd3"
}

.mdi-bed-empty::before {
    content: "\f08a0"
}

.mdi-bed-king::before {
    content: "\f0fd2"
}

.mdi-bed-king-outline::before {
    content: "\f0fd1"
}

.mdi-bed-outline::before {
    content: "\f0099"
}

.mdi-bed-queen::before {
    content: "\f0fd0"
}

.mdi-bed-queen-outline::before {
    content: "\f0fdb"
}

.mdi-bed-single::before {
    content: "\f106d"
}

.mdi-bed-single-outline::before {
    content: "\f106e"
}

.mdi-bee::before {
    content: "\f0fa1"
}

.mdi-bee-flower::before {
    content: "\f0fa2"
}

.mdi-beehive-off-outline::before {
    content: "\f13ed"
}

.mdi-beehive-outline::before {
    content: "\f10ce"
}

.mdi-beekeeper::before {
    content: "\f14e2"
}

.mdi-beer::before {
    content: "\f0098"
}

.mdi-beer-outline::before {
    content: "\f130c"
}

.mdi-bell::before {
    content: "\f009a"
}

.mdi-bell-alert::before {
    content: "\f0d59"
}

.mdi-bell-alert-outline::before {
    content: "\f0e81"
}

.mdi-bell-badge::before {
    content: "\f116b"
}

.mdi-bell-badge-outline::before {
    content: "\f0178"
}

.mdi-bell-cancel::before {
    content: "\f13e7"
}

.mdi-bell-cancel-outline::before {
    content: "\f13e8"
}

.mdi-bell-check::before {
    content: "\f11e5"
}

.mdi-bell-check-outline::before {
    content: "\f11e6"
}

.mdi-bell-circle::before {
    content: "\f0d5a"
}

.mdi-bell-circle-outline::before {
    content: "\f0d5b"
}

.mdi-bell-minus::before {
    content: "\f13e9"
}

.mdi-bell-minus-outline::before {
    content: "\f13ea"
}

.mdi-bell-off::before {
    content: "\f009b"
}

.mdi-bell-off-outline::before {
    content: "\f0a91"
}

.mdi-bell-outline::before {
    content: "\f009c"
}

.mdi-bell-plus::before {
    content: "\f009d"
}

.mdi-bell-plus-outline::before {
    content: "\f0a92"
}

.mdi-bell-remove::before {
    content: "\f13eb"
}

.mdi-bell-remove-outline::before {
    content: "\f13ec"
}

.mdi-bell-ring::before {
    content: "\f009e"
}

.mdi-bell-ring-outline::before {
    content: "\f009f"
}

.mdi-bell-sleep::before {
    content: "\f00a0"
}

.mdi-bell-sleep-outline::before {
    content: "\f0a93"
}

.mdi-beta::before {
    content: "\f00a1"
}

.mdi-betamax::before {
    content: "\f09cb"
}

.mdi-biathlon::before {
    content: "\f0e14"
}

.mdi-bicycle::before {
    content: "\f109c"
}

.mdi-bicycle-basket::before {
    content: "\f1235"
}

.mdi-bicycle-cargo::before {
    content: "\f189c"
}

.mdi-bicycle-electric::before {
    content: "\f15b4"
}

.mdi-bicycle-penny-farthing::before {
    content: "\f15e9"
}

.mdi-bike::before {
    content: "\f00a3"
}

.mdi-bike-fast::before {
    content: "\f111f"
}

.mdi-billboard::before {
    content: "\f1010"
}

.mdi-billiards::before {
    content: "\f0b61"
}

.mdi-billiards-rack::before {
    content: "\f0b62"
}

.mdi-binoculars::before {
    content: "\f00a5"
}

.mdi-bio::before {
    content: "\f00a6"
}

.mdi-biohazard::before {
    content: "\f00a7"
}

.mdi-bird::before {
    content: "\f15c6"
}

.mdi-bitbucket::before {
    content: "\f00a8"
}

.mdi-bitcoin::before {
    content: "\f0813"
}

.mdi-black-mesa::before {
    content: "\f00a9"
}

.mdi-blender::before {
    content: "\f0ceb"
}

.mdi-blender-outline::before {
    content: "\f181a"
}

.mdi-blender-software::before {
    content: "\f00ab"
}

.mdi-blinds::before {
    content: "\f00ac"
}

.mdi-blinds-open::before {
    content: "\f1011"
}

.mdi-block-helper::before {
    content: "\f00ad"
}

.mdi-blood-bag::before {
    content: "\f0cec"
}

.mdi-bluetooth::before {
    content: "\f00af"
}

.mdi-bluetooth-audio::before {
    content: "\f00b0"
}

.mdi-bluetooth-connect::before {
    content: "\f00b1"
}

.mdi-bluetooth-off::before {
    content: "\f00b2"
}

.mdi-bluetooth-settings::before {
    content: "\f00b3"
}

.mdi-bluetooth-transfer::before {
    content: "\f00b4"
}

.mdi-blur::before {
    content: "\f00b5"
}

.mdi-blur-linear::before {
    content: "\f00b6"
}

.mdi-blur-off::before {
    content: "\f00b7"
}

.mdi-blur-radial::before {
    content: "\f00b8"
}

.mdi-bolt::before {
    content: "\f0db3"
}

.mdi-bomb::before {
    content: "\f0691"
}

.mdi-bomb-off::before {
    content: "\f06c5"
}

.mdi-bone::before {
    content: "\f00b9"
}

.mdi-book::before {
    content: "\f00ba"
}

.mdi-book-account::before {
    content: "\f13ad"
}

.mdi-book-account-outline::before {
    content: "\f13ae"
}

.mdi-book-alert::before {
    content: "\f167c"
}

.mdi-book-alert-outline::before {
    content: "\f167d"
}

.mdi-book-alphabet::before {
    content: "\f061d"
}

.mdi-book-arrow-down::before {
    content: "\f167e"
}

.mdi-book-arrow-down-outline::before {
    content: "\f167f"
}

.mdi-book-arrow-left::before {
    content: "\f1680"
}

.mdi-book-arrow-left-outline::before {
    content: "\f1681"
}

.mdi-book-arrow-right::before {
    content: "\f1682"
}

.mdi-book-arrow-right-outline::before {
    content: "\f1683"
}

.mdi-book-arrow-up::before {
    content: "\f1684"
}

.mdi-book-arrow-up-outline::before {
    content: "\f1685"
}

.mdi-book-cancel::before {
    content: "\f1686"
}

.mdi-book-cancel-outline::before {
    content: "\f1687"
}

.mdi-book-check::before {
    content: "\f14f3"
}

.mdi-book-check-outline::before {
    content: "\f14f4"
}

.mdi-book-clock::before {
    content: "\f1688"
}

.mdi-book-clock-outline::before {
    content: "\f1689"
}

.mdi-book-cog::before {
    content: "\f168a"
}

.mdi-book-cog-outline::before {
    content: "\f168b"
}

.mdi-book-cross::before {
    content: "\f00a2"
}

.mdi-book-edit::before {
    content: "\f168c"
}

.mdi-book-edit-outline::before {
    content: "\f168d"
}

.mdi-book-education::before {
    content: "\f16c9"
}

.mdi-book-education-outline::before {
    content: "\f16ca"
}

.mdi-book-information-variant::before {
    content: "\f106f"
}

.mdi-book-lock::before {
    content: "\f079a"
}

.mdi-book-lock-open::before {
    content: "\f079b"
}

.mdi-book-lock-open-outline::before {
    content: "\f168e"
}

.mdi-book-lock-outline::before {
    content: "\f168f"
}

.mdi-book-marker::before {
    content: "\f1690"
}

.mdi-book-marker-outline::before {
    content: "\f1691"
}

.mdi-book-minus::before {
    content: "\f05d9"
}

.mdi-book-minus-multiple::before {
    content: "\f0a94"
}

.mdi-book-minus-multiple-outline::before {
    content: "\f090b"
}

.mdi-book-minus-outline::before {
    content: "\f1692"
}

.mdi-book-multiple::before {
    content: "\f00bb"
}

.mdi-book-multiple-outline::before {
    content: "\f0436"
}

.mdi-book-music::before {
    content: "\f0067"
}

.mdi-book-music-outline::before {
    content: "\f1693"
}

.mdi-book-off::before {
    content: "\f1694"
}

.mdi-book-off-outline::before {
    content: "\f1695"
}

.mdi-book-open::before {
    content: "\f00bd"
}

.mdi-book-open-blank-variant::before {
    content: "\f00be"
}

.mdi-book-open-outline::before {
    content: "\f0b63"
}

.mdi-book-open-page-variant::before {
    content: "\f05da"
}

.mdi-book-open-page-variant-outline::before {
    content: "\f15d6"
}

.mdi-book-open-variant::before {
    content: "\f14f7"
}

.mdi-book-outline::before {
    content: "\f0b64"
}

.mdi-book-play::before {
    content: "\f0e82"
}

.mdi-book-play-outline::before {
    content: "\f0e83"
}

.mdi-book-plus::before {
    content: "\f05db"
}

.mdi-book-plus-multiple::before {
    content: "\f0a95"
}

.mdi-book-plus-multiple-outline::before {
    content: "\f0ade"
}

.mdi-book-plus-outline::before {
    content: "\f1696"
}

.mdi-book-refresh::before {
    content: "\f1697"
}

.mdi-book-refresh-outline::before {
    content: "\f1698"
}

.mdi-book-remove::before {
    content: "\f0a97"
}

.mdi-book-remove-multiple::before {
    content: "\f0a96"
}

.mdi-book-remove-multiple-outline::before {
    content: "\f04ca"
}

.mdi-book-remove-outline::before {
    content: "\f1699"
}

.mdi-book-search::before {
    content: "\f0e84"
}

.mdi-book-search-outline::before {
    content: "\f0e85"
}

.mdi-book-settings::before {
    content: "\f169a"
}

.mdi-book-settings-outline::before {
    content: "\f169b"
}

.mdi-book-sync::before {
    content: "\f169c"
}

.mdi-book-sync-outline::before {
    content: "\f16c8"
}

.mdi-book-variant::before {
    content: "\f00bf"
}

.mdi-book-variant-multiple::before {
    content: "\f00bc"
}

.mdi-bookmark::before {
    content: "\f00c0"
}

.mdi-bookmark-box-multiple::before {
    content: "\f196c"
}

.mdi-bookmark-box-multiple-outline::before {
    content: "\f196d"
}

.mdi-bookmark-check::before {
    content: "\f00c1"
}

.mdi-bookmark-check-outline::before {
    content: "\f137b"
}

.mdi-bookmark-minus::before {
    content: "\f09cc"
}

.mdi-bookmark-minus-outline::before {
    content: "\f09cd"
}

.mdi-bookmark-multiple::before {
    content: "\f0e15"
}

.mdi-bookmark-multiple-outline::before {
    content: "\f0e16"
}

.mdi-bookmark-music::before {
    content: "\f00c2"
}

.mdi-bookmark-music-outline::before {
    content: "\f1379"
}

.mdi-bookmark-off::before {
    content: "\f09ce"
}

.mdi-bookmark-off-outline::before {
    content: "\f09cf"
}

.mdi-bookmark-outline::before {
    content: "\f00c3"
}

.mdi-bookmark-plus::before {
    content: "\f00c5"
}

.mdi-bookmark-plus-outline::before {
    content: "\f00c4"
}

.mdi-bookmark-remove::before {
    content: "\f00c6"
}

.mdi-bookmark-remove-outline::before {
    content: "\f137a"
}

.mdi-bookshelf::before {
    content: "\f125f"
}

.mdi-boom-gate::before {
    content: "\f0e86"
}

.mdi-boom-gate-alert::before {
    content: "\f0e87"
}

.mdi-boom-gate-alert-outline::before {
    content: "\f0e88"
}

.mdi-boom-gate-arrow-down::before {
    content: "\f0e89"
}

.mdi-boom-gate-arrow-down-outline::before {
    content: "\f0e8a"
}

.mdi-boom-gate-arrow-up::before {
    content: "\f0e8c"
}

.mdi-boom-gate-arrow-up-outline::before {
    content: "\f0e8d"
}

.mdi-boom-gate-outline::before {
    content: "\f0e8b"
}

.mdi-boom-gate-up::before {
    content: "\f17f9"
}

.mdi-boom-gate-up-outline::before {
    content: "\f17fa"
}

.mdi-boombox::before {
    content: "\f05dc"
}

.mdi-boomerang::before {
    content: "\f10cf"
}

.mdi-bootstrap::before {
    content: "\f06c6"
}

.mdi-border-all::before {
    content: "\f00c7"
}

.mdi-border-all-variant::before {
    content: "\f08a1"
}

.mdi-border-bottom::before {
    content: "\f00c8"
}

.mdi-border-bottom-variant::before {
    content: "\f08a2"
}

.mdi-border-color::before {
    content: "\f00c9"
}

.mdi-border-horizontal::before {
    content: "\f00ca"
}

.mdi-border-inside::before {
    content: "\f00cb"
}

.mdi-border-left::before {
    content: "\f00cc"
}

.mdi-border-left-variant::before {
    content: "\f08a3"
}

.mdi-border-none::before {
    content: "\f00cd"
}

.mdi-border-none-variant::before {
    content: "\f08a4"
}

.mdi-border-outside::before {
    content: "\f00ce"
}

.mdi-border-right::before {
    content: "\f00cf"
}

.mdi-border-right-variant::before {
    content: "\f08a5"
}

.mdi-border-style::before {
    content: "\f00d0"
}

.mdi-border-top::before {
    content: "\f00d1"
}

.mdi-border-top-variant::before {
    content: "\f08a6"
}

.mdi-border-vertical::before {
    content: "\f00d2"
}

.mdi-bottle-soda::before {
    content: "\f1070"
}

.mdi-bottle-soda-classic::before {
    content: "\f1071"
}

.mdi-bottle-soda-classic-outline::before {
    content: "\f1363"
}

.mdi-bottle-soda-outline::before {
    content: "\f1072"
}

.mdi-bottle-tonic::before {
    content: "\f112e"
}

.mdi-bottle-tonic-outline::before {
    content: "\f112f"
}

.mdi-bottle-tonic-plus::before {
    content: "\f1130"
}

.mdi-bottle-tonic-plus-outline::before {
    content: "\f1131"
}

.mdi-bottle-tonic-skull::before {
    content: "\f1132"
}

.mdi-bottle-tonic-skull-outline::before {
    content: "\f1133"
}

.mdi-bottle-wine::before {
    content: "\f0854"
}

.mdi-bottle-wine-outline::before {
    content: "\f1310"
}

.mdi-bow-arrow::before {
    content: "\f1841"
}

.mdi-bow-tie::before {
    content: "\f0678"
}

.mdi-bowl::before {
    content: "\f028e"
}

.mdi-bowl-mix::before {
    content: "\f0617"
}

.mdi-bowl-mix-outline::before {
    content: "\f02e4"
}

.mdi-bowl-outline::before {
    content: "\f02a9"
}

.mdi-bowling::before {
    content: "\f00d3"
}

.mdi-box::before {
    content: "\f00d4"
}

.mdi-box-cutter::before {
    content: "\f00d5"
}

.mdi-box-cutter-off::before {
    content: "\f0b4a"
}

.mdi-box-shadow::before {
    content: "\f0637"
}

.mdi-boxing-glove::before {
    content: "\f0b65"
}

.mdi-braille::before {
    content: "\f09d0"
}

.mdi-brain::before {
    content: "\f09d1"
}

.mdi-bread-slice::before {
    content: "\f0cee"
}

.mdi-bread-slice-outline::before {
    content: "\f0cef"
}

.mdi-bridge::before {
    content: "\f0618"
}

.mdi-briefcase::before {
    content: "\f00d6"
}

.mdi-briefcase-account::before {
    content: "\f0cf0"
}

.mdi-briefcase-account-outline::before {
    content: "\f0cf1"
}

.mdi-briefcase-check::before {
    content: "\f00d7"
}

.mdi-briefcase-check-outline::before {
    content: "\f131e"
}

.mdi-briefcase-clock::before {
    content: "\f10d0"
}

.mdi-briefcase-clock-outline::before {
    content: "\f10d1"
}

.mdi-briefcase-download::before {
    content: "\f00d8"
}

.mdi-briefcase-download-outline::before {
    content: "\f0c3d"
}

.mdi-briefcase-edit::before {
    content: "\f0a98"
}

.mdi-briefcase-edit-outline::before {
    content: "\f0c3e"
}

.mdi-briefcase-eye::before {
    content: "\f17d9"
}

.mdi-briefcase-eye-outline::before {
    content: "\f17da"
}

.mdi-briefcase-minus::before {
    content: "\f0a2a"
}

.mdi-briefcase-minus-outline::before {
    content: "\f0c3f"
}

.mdi-briefcase-off::before {
    content: "\f1658"
}

.mdi-briefcase-off-outline::before {
    content: "\f1659"
}

.mdi-briefcase-outline::before {
    content: "\f0814"
}

.mdi-briefcase-plus::before {
    content: "\f0a2b"
}

.mdi-briefcase-plus-outline::before {
    content: "\f0c40"
}

.mdi-briefcase-remove::before {
    content: "\f0a2c"
}

.mdi-briefcase-remove-outline::before {
    content: "\f0c41"
}

.mdi-briefcase-search::before {
    content: "\f0a2d"
}

.mdi-briefcase-search-outline::before {
    content: "\f0c42"
}

.mdi-briefcase-upload::before {
    content: "\f00d9"
}

.mdi-briefcase-upload-outline::before {
    content: "\f0c43"
}

.mdi-briefcase-variant::before {
    content: "\f1494"
}

.mdi-briefcase-variant-off::before {
    content: "\f165a"
}

.mdi-briefcase-variant-off-outline::before {
    content: "\f165b"
}

.mdi-briefcase-variant-outline::before {
    content: "\f1495"
}

.mdi-brightness-1::before {
    content: "\f00da"
}

.mdi-brightness-2::before {
    content: "\f00db"
}

.mdi-brightness-3::before {
    content: "\f00dc"
}

.mdi-brightness-4::before {
    content: "\f00dd"
}

.mdi-brightness-5::before {
    content: "\f00de"
}

.mdi-brightness-6::before {
    content: "\f00df"
}

.mdi-brightness-7::before {
    content: "\f00e0"
}

.mdi-brightness-auto::before {
    content: "\f00e1"
}

.mdi-brightness-percent::before {
    content: "\f0cf2"
}

.mdi-broadcast::before {
    content: "\f1720"
}

.mdi-broadcast-off::before {
    content: "\f1721"
}

.mdi-broom::before {
    content: "\f00e2"
}

.mdi-brush::before {
    content: "\f00e3"
}

.mdi-brush-off::before {
    content: "\f1771"
}

.mdi-brush-variant::before {
    content: "\f1813"
}

.mdi-bucket::before {
    content: "\f1415"
}

.mdi-bucket-outline::before {
    content: "\f1416"
}

.mdi-buffet::before {
    content: "\f0578"
}

.mdi-bug::before {
    content: "\f00e4"
}

.mdi-bug-check::before {
    content: "\f0a2e"
}

.mdi-bug-check-outline::before {
    content: "\f0a2f"
}

.mdi-bug-outline::before {
    content: "\f0a30"
}

.mdi-bugle::before {
    content: "\f0db4"
}

.mdi-bulldozer::before {
    content: "\f0b22"
}

.mdi-bullet::before {
    content: "\f0cf3"
}

.mdi-bulletin-board::before {
    content: "\f00e5"
}

.mdi-bullhorn::before {
    content: "\f00e6"
}

.mdi-bullhorn-outline::before {
    content: "\f0b23"
}

.mdi-bullhorn-variant::before {
    content: "\f196e"
}

.mdi-bullhorn-variant-outline::before {
    content: "\f196f"
}

.mdi-bullseye::before {
    content: "\f05dd"
}

.mdi-bullseye-arrow::before {
    content: "\f08c9"
}

.mdi-bulma::before {
    content: "\f12e7"
}

.mdi-bunk-bed::before {
    content: "\f1302"
}

.mdi-bunk-bed-outline::before {
    content: "\f0097"
}

.mdi-bus::before {
    content: "\f00e7"
}

.mdi-bus-alert::before {
    content: "\f0a99"
}

.mdi-bus-articulated-end::before {
    content: "\f079c"
}

.mdi-bus-articulated-front::before {
    content: "\f079d"
}

.mdi-bus-clock::before {
    content: "\f08ca"
}

.mdi-bus-double-decker::before {
    content: "\f079e"
}

.mdi-bus-electric::before {
    content: "\f191d"
}

.mdi-bus-marker::before {
    content: "\f1212"
}

.mdi-bus-multiple::before {
    content: "\f0f3f"
}

.mdi-bus-school::before {
    content: "\f079f"
}

.mdi-bus-side::before {
    content: "\f07a0"
}

.mdi-bus-stop::before {
    content: "\f1012"
}

.mdi-bus-stop-covered::before {
    content: "\f1013"
}

.mdi-bus-stop-uncovered::before {
    content: "\f1014"
}

.mdi-butterfly::before {
    content: "\f1589"
}

.mdi-butterfly-outline::before {
    content: "\f158a"
}

.mdi-cabin-a-frame::before {
    content: "\f188c"
}

.mdi-cable-data::before {
    content: "\f1394"
}

.mdi-cached::before {
    content: "\f00e8"
}

.mdi-cactus::before {
    content: "\f0db5"
}

.mdi-cake::before {
    content: "\f00e9"
}

.mdi-cake-layered::before {
    content: "\f00ea"
}

.mdi-cake-variant::before {
    content: "\f00eb"
}

.mdi-cake-variant-outline::before {
    content: "\f17f0"
}

.mdi-calculator::before {
    content: "\f00ec"
}

.mdi-calculator-variant::before {
    content: "\f0a9a"
}

.mdi-calculator-variant-outline::before {
    content: "\f15a6"
}

.mdi-calendar::before {
    content: "\f00ed"
}

.mdi-calendar-account::before {
    content: "\f0ed7"
}

.mdi-calendar-account-outline::before {
    content: "\f0ed8"
}

.mdi-calendar-alert::before {
    content: "\f0a31"
}

.mdi-calendar-arrow-left::before {
    content: "\f1134"
}

.mdi-calendar-arrow-right::before {
    content: "\f1135"
}

.mdi-calendar-blank::before {
    content: "\f00ee"
}

.mdi-calendar-blank-multiple::before {
    content: "\f1073"
}

.mdi-calendar-blank-outline::before {
    content: "\f0b66"
}

.mdi-calendar-check::before {
    content: "\f00ef"
}

.mdi-calendar-check-outline::before {
    content: "\f0c44"
}

.mdi-calendar-clock::before {
    content: "\f00f0"
}

.mdi-calendar-clock-outline::before {
    content: "\f16e1"
}

.mdi-calendar-collapse-horizontal::before {
    content: "\f189d"
}

.mdi-calendar-cursor::before {
    content: "\f157b"
}

.mdi-calendar-edit::before {
    content: "\f08a7"
}

.mdi-calendar-end::before {
    content: "\f166c"
}

.mdi-calendar-expand-horizontal::before {
    content: "\f189e"
}

.mdi-calendar-export::before {
    content: "\f0b24"
}

.mdi-calendar-heart::before {
    content: "\f09d2"
}

.mdi-calendar-import::before {
    content: "\f0b25"
}

.mdi-calendar-lock::before {
    content: "\f1641"
}

.mdi-calendar-lock-outline::before {
    content: "\f1642"
}

.mdi-calendar-minus::before {
    content: "\f0d5c"
}

.mdi-calendar-month::before {
    content: "\f0e17"
}

.mdi-calendar-month-outline::before {
    content: "\f0e18"
}

.mdi-calendar-multiple::before {
    content: "\f00f1"
}

.mdi-calendar-multiple-check::before {
    content: "\f00f2"
}

.mdi-calendar-multiselect::before {
    content: "\f0a32"
}

.mdi-calendar-outline::before {
    content: "\f0b67"
}

.mdi-calendar-plus::before {
    content: "\f00f3"
}

.mdi-calendar-question::before {
    content: "\f0692"
}

.mdi-calendar-range::before {
    content: "\f0679"
}

.mdi-calendar-range-outline::before {
    content: "\f0b68"
}

.mdi-calendar-refresh::before {
    content: "\f01e1"
}

.mdi-calendar-refresh-outline::before {
    content: "\f0203"
}

.mdi-calendar-remove::before {
    content: "\f00f4"
}

.mdi-calendar-remove-outline::before {
    content: "\f0c45"
}

.mdi-calendar-search::before {
    content: "\f094c"
}

.mdi-calendar-star::before {
    content: "\f09d3"
}

.mdi-calendar-start::before {
    content: "\f166d"
}

.mdi-calendar-sync::before {
    content: "\f0e8e"
}

.mdi-calendar-sync-outline::before {
    content: "\f0e8f"
}

.mdi-calendar-text::before {
    content: "\f00f5"
}

.mdi-calendar-text-outline::before {
    content: "\f0c46"
}

.mdi-calendar-today::before {
    content: "\f00f6"
}

.mdi-calendar-week::before {
    content: "\f0a33"
}

.mdi-calendar-week-begin::before {
    content: "\f0a34"
}

.mdi-calendar-weekend::before {
    content: "\f0ed9"
}

.mdi-calendar-weekend-outline::before {
    content: "\f0eda"
}

.mdi-call-made::before {
    content: "\f00f7"
}

.mdi-call-merge::before {
    content: "\f00f8"
}

.mdi-call-missed::before {
    content: "\f00f9"
}

.mdi-call-received::before {
    content: "\f00fa"
}

.mdi-call-split::before {
    content: "\f00fb"
}

.mdi-camcorder::before {
    content: "\f00fc"
}

.mdi-camcorder-off::before {
    content: "\f00ff"
}

.mdi-camera::before {
    content: "\f0100"
}

.mdi-camera-account::before {
    content: "\f08cb"
}

.mdi-camera-burst::before {
    content: "\f0693"
}

.mdi-camera-control::before {
    content: "\f0b69"
}

.mdi-camera-document::before {
    content: "\f1871"
}

.mdi-camera-document-off::before {
    content: "\f1872"
}

.mdi-camera-enhance::before {
    content: "\f0101"
}

.mdi-camera-enhance-outline::before {
    content: "\f0b6a"
}

.mdi-camera-flip::before {
    content: "\f15d9"
}

.mdi-camera-flip-outline::before {
    content: "\f15da"
}

.mdi-camera-front::before {
    content: "\f0102"
}

.mdi-camera-front-variant::before {
    content: "\f0103"
}

.mdi-camera-gopro::before {
    content: "\f07a1"
}

.mdi-camera-image::before {
    content: "\f08cc"
}

.mdi-camera-iris::before {
    content: "\f0104"
}

.mdi-camera-marker::before {
    content: "\f19a7"
}

.mdi-camera-marker-outline::before {
    content: "\f19a8"
}

.mdi-camera-metering-center::before {
    content: "\f07a2"
}

.mdi-camera-metering-matrix::before {
    content: "\f07a3"
}

.mdi-camera-metering-partial::before {
    content: "\f07a4"
}

.mdi-camera-metering-spot::before {
    content: "\f07a5"
}

.mdi-camera-off::before {
    content: "\f05df"
}

.mdi-camera-off-outline::before {
    content: "\f19bf"
}

.mdi-camera-outline::before {
    content: "\f0d5d"
}

.mdi-camera-party-mode::before {
    content: "\f0105"
}

.mdi-camera-plus::before {
    content: "\f0edb"
}

.mdi-camera-plus-outline::before {
    content: "\f0edc"
}

.mdi-camera-rear::before {
    content: "\f0106"
}

.mdi-camera-rear-variant::before {
    content: "\f0107"
}

.mdi-camera-retake::before {
    content: "\f0e19"
}

.mdi-camera-retake-outline::before {
    content: "\f0e1a"
}

.mdi-camera-switch::before {
    content: "\f0108"
}

.mdi-camera-switch-outline::before {
    content: "\f084a"
}

.mdi-camera-timer::before {
    content: "\f0109"
}

.mdi-camera-wireless::before {
    content: "\f0db6"
}

.mdi-camera-wireless-outline::before {
    content: "\f0db7"
}

.mdi-campfire::before {
    content: "\f0edd"
}

.mdi-cancel::before {
    content: "\f073a"
}

.mdi-candelabra::before {
    content: "\f17d2"
}

.mdi-candelabra-fire::before {
    content: "\f17d3"
}

.mdi-candle::before {
    content: "\f05e2"
}

.mdi-candy::before {
    content: "\f1970"
}

.mdi-candy-off::before {
    content: "\f1971"
}

.mdi-candy-off-outline::before {
    content: "\f1972"
}

.mdi-candy-outline::before {
    content: "\f1973"
}

.mdi-candycane::before {
    content: "\f010a"
}

.mdi-cannabis::before {
    content: "\f07a6"
}

.mdi-cannabis-off::before {
    content: "\f166e"
}

.mdi-caps-lock::before {
    content: "\f0a9b"
}

.mdi-car::before {
    content: "\f010b"
}

.mdi-car-2-plus::before {
    content: "\f1015"
}

.mdi-car-3-plus::before {
    content: "\f1016"
}

.mdi-car-arrow-left::before {
    content: "\f13b2"
}

.mdi-car-arrow-right::before {
    content: "\f13b3"
}

.mdi-car-back::before {
    content: "\f0e1b"
}

.mdi-car-battery::before {
    content: "\f010c"
}

.mdi-car-brake-abs::before {
    content: "\f0c47"
}

.mdi-car-brake-alert::before {
    content: "\f0c48"
}

.mdi-car-brake-fluid-level::before {
    content: "\f1909"
}

.mdi-car-brake-hold::before {
    content: "\f0d5e"
}

.mdi-car-brake-low-pressure::before {
    content: "\f190a"
}

.mdi-car-brake-parking::before {
    content: "\f0d5f"
}

.mdi-car-brake-retarder::before {
    content: "\f1017"
}

.mdi-car-brake-temperature::before {
    content: "\f190b"
}

.mdi-car-brake-worn-linings::before {
    content: "\f190c"
}

.mdi-car-child-seat::before {
    content: "\f0fa3"
}

.mdi-car-clock::before {
    content: "\f1974"
}

.mdi-car-clutch::before {
    content: "\f1018"
}

.mdi-car-cog::before {
    content: "\f13cc"
}

.mdi-car-connected::before {
    content: "\f010d"
}

.mdi-car-convertible::before {
    content: "\f07a7"
}

.mdi-car-coolant-level::before {
    content: "\f1019"
}

.mdi-car-cruise-control::before {
    content: "\f0d60"
}

.mdi-car-defrost-front::before {
    content: "\f0d61"
}

.mdi-car-defrost-rear::before {
    content: "\f0d62"
}

.mdi-car-door::before {
    content: "\f0b6b"
}

.mdi-car-door-lock::before {
    content: "\f109d"
}

.mdi-car-electric::before {
    content: "\f0b6c"
}

.mdi-car-electric-outline::before {
    content: "\f15b5"
}

.mdi-car-emergency::before {
    content: "\f160f"
}

.mdi-car-esp::before {
    content: "\f0c49"
}

.mdi-car-estate::before {
    content: "\f07a8"
}

.mdi-car-hatchback::before {
    content: "\f07a9"
}

.mdi-car-info::before {
    content: "\f11be"
}

.mdi-car-key::before {
    content: "\f0b6d"
}

.mdi-car-lifted-pickup::before {
    content: "\f152d"
}

.mdi-car-light-alert::before {
    content: "\f190d"
}

.mdi-car-light-dimmed::before {
    content: "\f0c4a"
}

.mdi-car-light-fog::before {
    content: "\f0c4b"
}

.mdi-car-light-high::before {
    content: "\f0c4c"
}

.mdi-car-limousine::before {
    content: "\f08cd"
}

.mdi-car-multiple::before {
    content: "\f0b6e"
}

.mdi-car-off::before {
    content: "\f0e1c"
}

.mdi-car-outline::before {
    content: "\f14ed"
}

.mdi-car-parking-lights::before {
    content: "\f0d63"
}

.mdi-car-pickup::before {
    content: "\f07aa"
}

.mdi-car-seat::before {
    content: "\f0fa4"
}

.mdi-car-seat-cooler::before {
    content: "\f0fa5"
}

.mdi-car-seat-heater::before {
    content: "\f0fa6"
}

.mdi-car-select::before {
    content: "\f1879"
}

.mdi-car-settings::before {
    content: "\f13cd"
}

.mdi-car-shift-pattern::before {
    content: "\f0f40"
}

.mdi-car-side::before {
    content: "\f07ab"
}

.mdi-car-speed-limiter::before {
    content: "\f190e"
}

.mdi-car-sports::before {
    content: "\f07ac"
}

.mdi-car-tire-alert::before {
    content: "\f0c4d"
}

.mdi-car-traction-control::before {
    content: "\f0d64"
}

.mdi-car-turbocharger::before {
    content: "\f101a"
}

.mdi-car-wash::before {
    content: "\f010e"
}

.mdi-car-windshield::before {
    content: "\f101b"
}

.mdi-car-windshield-outline::before {
    content: "\f101c"
}

.mdi-car-wireless::before {
    content: "\f1878"
}

.mdi-car-wrench::before {
    content: "\f1814"
}

.mdi-carabiner::before {
    content: "\f14c0"
}

.mdi-caravan::before {
    content: "\f07ad"
}

.mdi-card::before {
    content: "\f0b6f"
}

.mdi-card-account-details::before {
    content: "\f05d2"
}

.mdi-card-account-details-outline::before {
    content: "\f0dab"
}

.mdi-card-account-details-star::before {
    content: "\f02a3"
}

.mdi-card-account-details-star-outline::before {
    content: "\f06db"
}

.mdi-card-account-mail::before {
    content: "\f018e"
}

.mdi-card-account-mail-outline::before {
    content: "\f0e98"
}

.mdi-card-account-phone::before {
    content: "\f0e99"
}

.mdi-card-account-phone-outline::before {
    content: "\f0e9a"
}

.mdi-card-bulleted::before {
    content: "\f0b70"
}

.mdi-card-bulleted-off::before {
    content: "\f0b71"
}

.mdi-card-bulleted-off-outline::before {
    content: "\f0b72"
}

.mdi-card-bulleted-outline::before {
    content: "\f0b73"
}

.mdi-card-bulleted-settings::before {
    content: "\f0b74"
}

.mdi-card-bulleted-settings-outline::before {
    content: "\f0b75"
}

.mdi-card-minus::before {
    content: "\f1600"
}

.mdi-card-minus-outline::before {
    content: "\f1601"
}

.mdi-card-multiple::before {
    content: "\f17f1"
}

.mdi-card-multiple-outline::before {
    content: "\f17f2"
}

.mdi-card-off::before {
    content: "\f1602"
}

.mdi-card-off-outline::before {
    content: "\f1603"
}

.mdi-card-outline::before {
    content: "\f0b76"
}

.mdi-card-plus::before {
    content: "\f11ff"
}

.mdi-card-plus-outline::before {
    content: "\f1200"
}

.mdi-card-remove::before {
    content: "\f1604"
}

.mdi-card-remove-outline::before {
    content: "\f1605"
}

.mdi-card-search::before {
    content: "\f1074"
}

.mdi-card-search-outline::before {
    content: "\f1075"
}

.mdi-card-text::before {
    content: "\f0b77"
}

.mdi-card-text-outline::before {
    content: "\f0b78"
}

.mdi-cards::before {
    content: "\f0638"
}

.mdi-cards-club::before {
    content: "\f08ce"
}

.mdi-cards-club-outline::before {
    content: "\f189f"
}

.mdi-cards-diamond::before {
    content: "\f08cf"
}

.mdi-cards-diamond-outline::before {
    content: "\f101d"
}

.mdi-cards-heart::before {
    content: "\f08d0"
}

.mdi-cards-heart-outline::before {
    content: "\f18a0"
}

.mdi-cards-outline::before {
    content: "\f0639"
}

.mdi-cards-playing::before {
    content: "\f18a1"
}

.mdi-cards-playing-club::before {
    content: "\f18a2"
}

.mdi-cards-playing-club-multiple::before {
    content: "\f18a3"
}

.mdi-cards-playing-club-multiple-outline::before {
    content: "\f18a4"
}

.mdi-cards-playing-club-outline::before {
    content: "\f18a5"
}

.mdi-cards-playing-diamond::before {
    content: "\f18a6"
}

.mdi-cards-playing-diamond-multiple::before {
    content: "\f18a7"
}

.mdi-cards-playing-diamond-multiple-outline::before {
    content: "\f18a8"
}

.mdi-cards-playing-diamond-outline::before {
    content: "\f18a9"
}

.mdi-cards-playing-heart::before {
    content: "\f18aa"
}

.mdi-cards-playing-heart-multiple::before {
    content: "\f18ab"
}

.mdi-cards-playing-heart-multiple-outline::before {
    content: "\f18ac"
}

.mdi-cards-playing-heart-outline::before {
    content: "\f18ad"
}

.mdi-cards-playing-outline::before {
    content: "\f063a"
}

.mdi-cards-playing-spade::before {
    content: "\f18ae"
}

.mdi-cards-playing-spade-multiple::before {
    content: "\f18af"
}

.mdi-cards-playing-spade-multiple-outline::before {
    content: "\f18b0"
}

.mdi-cards-playing-spade-outline::before {
    content: "\f18b1"
}

.mdi-cards-spade::before {
    content: "\f08d1"
}

.mdi-cards-spade-outline::before {
    content: "\f18b2"
}

.mdi-cards-variant::before {
    content: "\f06c7"
}

.mdi-carrot::before {
    content: "\f010f"
}

.mdi-cart::before {
    content: "\f0110"
}

.mdi-cart-arrow-down::before {
    content: "\f0d66"
}

.mdi-cart-arrow-right::before {
    content: "\f0c4e"
}

.mdi-cart-arrow-up::before {
    content: "\f0d67"
}

.mdi-cart-check::before {
    content: "\f15ea"
}

.mdi-cart-heart::before {
    content: "\f18e0"
}

.mdi-cart-minus::before {
    content: "\f0d68"
}

.mdi-cart-off::before {
    content: "\f066b"
}

.mdi-cart-outline::before {
    content: "\f0111"
}

.mdi-cart-plus::before {
    content: "\f0112"
}

.mdi-cart-remove::before {
    content: "\f0d69"
}

.mdi-cart-variant::before {
    content: "\f15eb"
}

.mdi-case-sensitive-alt::before {
    content: "\f0113"
}

.mdi-cash::before {
    content: "\f0114"
}

.mdi-cash-100::before {
    content: "\f0115"
}

.mdi-cash-check::before {
    content: "\f14ee"
}

.mdi-cash-fast::before {
    content: "\f185c"
}

.mdi-cash-lock::before {
    content: "\f14ea"
}

.mdi-cash-lock-open::before {
    content: "\f14eb"
}

.mdi-cash-marker::before {
    content: "\f0db8"
}

.mdi-cash-minus::before {
    content: "\f1260"
}

.mdi-cash-multiple::before {
    content: "\f0116"
}

.mdi-cash-plus::before {
    content: "\f1261"
}

.mdi-cash-refund::before {
    content: "\f0a9c"
}

.mdi-cash-register::before {
    content: "\f0cf4"
}

.mdi-cash-remove::before {
    content: "\f1262"
}

.mdi-cassette::before {
    content: "\f09d4"
}

.mdi-cast::before {
    content: "\f0118"
}

.mdi-cast-audio::before {
    content: "\f101e"
}

.mdi-cast-audio-variant::before {
    content: "\f1749"
}

.mdi-cast-connected::before {
    content: "\f0119"
}

.mdi-cast-education::before {
    content: "\f0e1d"
}

.mdi-cast-off::before {
    content: "\f078a"
}

.mdi-cast-variant::before {
    content: "\f001f"
}

.mdi-castle::before {
    content: "\f011a"
}

.mdi-cat::before {
    content: "\f011b"
}

.mdi-cctv::before {
    content: "\f07ae"
}

.mdi-cctv-off::before {
    content: "\f185f"
}

.mdi-ceiling-fan::before {
    content: "\f1797"
}

.mdi-ceiling-fan-light::before {
    content: "\f1798"
}

.mdi-ceiling-light::before {
    content: "\f0769"
}

.mdi-ceiling-light-multiple::before {
    content: "\f18dd"
}

.mdi-ceiling-light-multiple-outline::before {
    content: "\f18de"
}

.mdi-ceiling-light-outline::before {
    content: "\f17c7"
}

.mdi-cellphone::before {
    content: "\f011c"
}

.mdi-cellphone-arrow-down::before {
    content: "\f09d5"
}

.mdi-cellphone-basic::before {
    content: "\f011e"
}

.mdi-cellphone-charging::before {
    content: "\f1397"
}

.mdi-cellphone-check::before {
    content: "\f17fd"
}

.mdi-cellphone-cog::before {
    content: "\f0951"
}

.mdi-cellphone-dock::before {
    content: "\f011f"
}

.mdi-cellphone-information::before {
    content: "\f0f41"
}

.mdi-cellphone-key::before {
    content: "\f094e"
}

.mdi-cellphone-link::before {
    content: "\f0121"
}

.mdi-cellphone-link-off::before {
    content: "\f0122"
}

.mdi-cellphone-lock::before {
    content: "\f094f"
}

.mdi-cellphone-marker::before {
    content: "\f183a"
}

.mdi-cellphone-message::before {
    content: "\f08d3"
}

.mdi-cellphone-message-off::before {
    content: "\f10d2"
}

.mdi-cellphone-nfc::before {
    content: "\f0e90"
}

.mdi-cellphone-nfc-off::before {
    content: "\f12d8"
}

.mdi-cellphone-off::before {
    content: "\f0950"
}

.mdi-cellphone-play::before {
    content: "\f101f"
}

.mdi-cellphone-remove::before {
    content: "\f094d"
}

.mdi-cellphone-screenshot::before {
    content: "\f0a35"
}

.mdi-cellphone-settings::before {
    content: "\f0123"
}

.mdi-cellphone-sound::before {
    content: "\f0952"
}

.mdi-cellphone-text::before {
    content: "\f08d2"
}

.mdi-cellphone-wireless::before {
    content: "\f0815"
}

.mdi-centos::before {
    content: "\f111a"
}

.mdi-certificate::before {
    content: "\f0124"
}

.mdi-certificate-outline::before {
    content: "\f1188"
}

.mdi-chair-rolling::before {
    content: "\f0f48"
}

.mdi-chair-school::before {
    content: "\f0125"
}

.mdi-chandelier::before {
    content: "\f1793"
}

.mdi-charity::before {
    content: "\f0c4f"
}

.mdi-chart-arc::before {
    content: "\f0126"
}

.mdi-chart-areaspline::before {
    content: "\f0127"
}

.mdi-chart-areaspline-variant::before {
    content: "\f0e91"
}

.mdi-chart-bar::before {
    content: "\f0128"
}

.mdi-chart-bar-stacked::before {
    content: "\f076a"
}

.mdi-chart-bell-curve::before {
    content: "\f0c50"
}

.mdi-chart-bell-curve-cumulative::before {
    content: "\f0fa7"
}

.mdi-chart-box::before {
    content: "\f154d"
}

.mdi-chart-box-outline::before {
    content: "\f154e"
}

.mdi-chart-box-plus-outline::before {
    content: "\f154f"
}

.mdi-chart-bubble::before {
    content: "\f05e3"
}

.mdi-chart-donut::before {
    content: "\f07af"
}

.mdi-chart-donut-variant::before {
    content: "\f07b0"
}

.mdi-chart-gantt::before {
    content: "\f066c"
}

.mdi-chart-histogram::before {
    content: "\f0129"
}

.mdi-chart-line::before {
    content: "\f012a"
}

.mdi-chart-line-stacked::before {
    content: "\f076b"
}

.mdi-chart-line-variant::before {
    content: "\f07b1"
}

.mdi-chart-multiline::before {
    content: "\f08d4"
}

.mdi-chart-multiple::before {
    content: "\f1213"
}

.mdi-chart-pie::before {
    content: "\f012b"
}

.mdi-chart-ppf::before {
    content: "\f1380"
}

.mdi-chart-sankey::before {
    content: "\f11df"
}

.mdi-chart-sankey-variant::before {
    content: "\f11e0"
}

.mdi-chart-scatter-plot::before {
    content: "\f0e92"
}

.mdi-chart-scatter-plot-hexbin::before {
    content: "\f066d"
}

.mdi-chart-timeline::before {
    content: "\f066e"
}

.mdi-chart-timeline-variant::before {
    content: "\f0e93"
}

.mdi-chart-timeline-variant-shimmer::before {
    content: "\f15b6"
}

.mdi-chart-tree::before {
    content: "\f0e94"
}

.mdi-chart-waterfall::before {
    content: "\f1918"
}

.mdi-chat::before {
    content: "\f0b79"
}

.mdi-chat-alert::before {
    content: "\f0b7a"
}

.mdi-chat-alert-outline::before {
    content: "\f12c9"
}

.mdi-chat-minus::before {
    content: "\f1410"
}

.mdi-chat-minus-outline::before {
    content: "\f1413"
}

.mdi-chat-outline::before {
    content: "\f0ede"
}

.mdi-chat-plus::before {
    content: "\f140f"
}

.mdi-chat-plus-outline::before {
    content: "\f1412"
}

.mdi-chat-processing::before {
    content: "\f0b7b"
}

.mdi-chat-processing-outline::before {
    content: "\f12ca"
}

.mdi-chat-question::before {
    content: "\f1738"
}

.mdi-chat-question-outline::before {
    content: "\f1739"
}

.mdi-chat-remove::before {
    content: "\f1411"
}

.mdi-chat-remove-outline::before {
    content: "\f1414"
}

.mdi-chat-sleep::before {
    content: "\f12d1"
}

.mdi-chat-sleep-outline::before {
    content: "\f12d2"
}

.mdi-check::before {
    content: "\f012c"
}

.mdi-check-all::before {
    content: "\f012d"
}

.mdi-check-bold::before {
    content: "\f0e1e"
}

.mdi-check-circle::before {
    content: "\f05e0"
}

.mdi-check-circle-outline::before {
    content: "\f05e1"
}

.mdi-check-decagram::before {
    content: "\f0791"
}

.mdi-check-decagram-outline::before {
    content: "\f1740"
}

.mdi-check-network::before {
    content: "\f0c53"
}

.mdi-check-network-outline::before {
    content: "\f0c54"
}

.mdi-check-outline::before {
    content: "\f0855"
}

.mdi-check-underline::before {
    content: "\f0e1f"
}

.mdi-check-underline-circle::before {
    content: "\f0e20"
}

.mdi-check-underline-circle-outline::before {
    content: "\f0e21"
}

.mdi-checkbook::before {
    content: "\f0a9d"
}

.mdi-checkbox-blank::before {
    content: "\f012e"
}

.mdi-checkbox-blank-badge::before {
    content: "\f1176"
}

.mdi-checkbox-blank-badge-outline::before {
    content: "\f0117"
}

.mdi-checkbox-blank-circle::before {
    content: "\f012f"
}

.mdi-checkbox-blank-circle-outline::before {
    content: "\f0130"
}

.mdi-checkbox-blank-off::before {
    content: "\f12ec"
}

.mdi-checkbox-blank-off-outline::before {
    content: "\f12ed"
}

.mdi-checkbox-blank-outline::before {
    content: "\f0131"
}

.mdi-checkbox-intermediate::before {
    content: "\f0856"
}

.mdi-checkbox-marked::before {
    content: "\f0132"
}

.mdi-checkbox-marked-circle::before {
    content: "\f0133"
}

.mdi-checkbox-marked-circle-outline::before {
    content: "\f0134"
}

.mdi-checkbox-marked-circle-plus-outline::before {
    content: "\f1927"
}

.mdi-checkbox-marked-outline::before {
    content: "\f0135"
}

.mdi-checkbox-multiple-blank::before {
    content: "\f0136"
}

.mdi-checkbox-multiple-blank-circle::before {
    content: "\f063b"
}

.mdi-checkbox-multiple-blank-circle-outline::before {
    content: "\f063c"
}

.mdi-checkbox-multiple-blank-outline::before {
    content: "\f0137"
}

.mdi-checkbox-multiple-marked::before {
    content: "\f0138"
}

.mdi-checkbox-multiple-marked-circle::before {
    content: "\f063d"
}

.mdi-checkbox-multiple-marked-circle-outline::before {
    content: "\f063e"
}

.mdi-checkbox-multiple-marked-outline::before {
    content: "\f0139"
}

.mdi-checkbox-multiple-outline::before {
    content: "\f0c51"
}

.mdi-checkbox-outline::before {
    content: "\f0c52"
}

.mdi-checkerboard::before {
    content: "\f013a"
}

.mdi-checkerboard-minus::before {
    content: "\f1202"
}

.mdi-checkerboard-plus::before {
    content: "\f1201"
}

.mdi-checkerboard-remove::before {
    content: "\f1203"
}

.mdi-cheese::before {
    content: "\f12b9"
}

.mdi-cheese-off::before {
    content: "\f13ee"
}

.mdi-chef-hat::before {
    content: "\f0b7c"
}

.mdi-chemical-weapon::before {
    content: "\f013b"
}

.mdi-chess-bishop::before {
    content: "\f085c"
}

.mdi-chess-king::before {
    content: "\f0857"
}

.mdi-chess-knight::before {
    content: "\f0858"
}

.mdi-chess-pawn::before {
    content: "\f0859"
}

.mdi-chess-queen::before {
    content: "\f085a"
}

.mdi-chess-rook::before {
    content: "\f085b"
}

.mdi-chevron-double-down::before {
    content: "\f013c"
}

.mdi-chevron-double-left::before {
    content: "\f013d"
}

.mdi-chevron-double-right::before {
    content: "\f013e"
}

.mdi-chevron-double-up::before {
    content: "\f013f"
}

.mdi-chevron-down::before {
    content: "\f0140"
}

.mdi-chevron-down-box::before {
    content: "\f09d6"
}

.mdi-chevron-down-box-outline::before {
    content: "\f09d7"
}

.mdi-chevron-down-circle::before {
    content: "\f0b26"
}

.mdi-chevron-down-circle-outline::before {
    content: "\f0b27"
}

.mdi-chevron-left::before {
    content: "\f0141"
}

.mdi-chevron-left-box::before {
    content: "\f09d8"
}

.mdi-chevron-left-box-outline::before {
    content: "\f09d9"
}

.mdi-chevron-left-circle::before {
    content: "\f0b28"
}

.mdi-chevron-left-circle-outline::before {
    content: "\f0b29"
}

.mdi-chevron-right::before {
    content: "\f0142"
}

.mdi-chevron-right-box::before {
    content: "\f09da"
}

.mdi-chevron-right-box-outline::before {
    content: "\f09db"
}

.mdi-chevron-right-circle::before {
    content: "\f0b2a"
}

.mdi-chevron-right-circle-outline::before {
    content: "\f0b2b"
}

.mdi-chevron-triple-down::before {
    content: "\f0db9"
}

.mdi-chevron-triple-left::before {
    content: "\f0dba"
}

.mdi-chevron-triple-right::before {
    content: "\f0dbb"
}

.mdi-chevron-triple-up::before {
    content: "\f0dbc"
}

.mdi-chevron-up::before {
    content: "\f0143"
}

.mdi-chevron-up-box::before {
    content: "\f09dc"
}

.mdi-chevron-up-box-outline::before {
    content: "\f09dd"
}

.mdi-chevron-up-circle::before {
    content: "\f0b2c"
}

.mdi-chevron-up-circle-outline::before {
    content: "\f0b2d"
}

.mdi-chili-alert::before {
    content: "\f17ea"
}

.mdi-chili-alert-outline::before {
    content: "\f17eb"
}

.mdi-chili-hot::before {
    content: "\f07b2"
}

.mdi-chili-hot-outline::before {
    content: "\f17ec"
}

.mdi-chili-medium::before {
    content: "\f07b3"
}

.mdi-chili-medium-outline::before {
    content: "\f17ed"
}

.mdi-chili-mild::before {
    content: "\f07b4"
}

.mdi-chili-mild-outline::before {
    content: "\f17ee"
}

.mdi-chili-off::before {
    content: "\f1467"
}

.mdi-chili-off-outline::before {
    content: "\f17ef"
}

.mdi-chip::before {
    content: "\f061a"
}

.mdi-church::before {
    content: "\f0144"
}

.mdi-cigar::before {
    content: "\f1189"
}

.mdi-cigar-off::before {
    content: "\f141b"
}

.mdi-circle::before {
    content: "\f0765"
}

.mdi-circle-box::before {
    content: "\f15dc"
}

.mdi-circle-box-outline::before {
    content: "\f15dd"
}

.mdi-circle-double::before {
    content: "\f0e95"
}

.mdi-circle-edit-outline::before {
    content: "\f08d5"
}

.mdi-circle-expand::before {
    content: "\f0e96"
}

.mdi-circle-half::before {
    content: "\f1395"
}

.mdi-circle-half-full::before {
    content: "\f1396"
}

.mdi-circle-medium::before {
    content: "\f09de"
}

.mdi-circle-multiple::before {
    content: "\f0b38"
}

.mdi-circle-multiple-outline::before {
    content: "\f0695"
}

.mdi-circle-off-outline::before {
    content: "\f10d3"
}

.mdi-circle-opacity::before {
    content: "\f1853"
}

.mdi-circle-outline::before {
    content: "\f0766"
}

.mdi-circle-slice-1::before {
    content: "\f0a9e"
}

.mdi-circle-slice-2::before {
    content: "\f0a9f"
}

.mdi-circle-slice-3::before {
    content: "\f0aa0"
}

.mdi-circle-slice-4::before {
    content: "\f0aa1"
}

.mdi-circle-slice-5::before {
    content: "\f0aa2"
}

.mdi-circle-slice-6::before {
    content: "\f0aa3"
}

.mdi-circle-slice-7::before {
    content: "\f0aa4"
}

.mdi-circle-slice-8::before {
    content: "\f0aa5"
}

.mdi-circle-small::before {
    content: "\f09df"
}

.mdi-circular-saw::before {
    content: "\f0e22"
}

.mdi-city::before {
    content: "\f0146"
}

.mdi-city-variant::before {
    content: "\f0a36"
}

.mdi-city-variant-outline::before {
    content: "\f0a37"
}

.mdi-clipboard::before {
    content: "\f0147"
}

.mdi-clipboard-account::before {
    content: "\f0148"
}

.mdi-clipboard-account-outline::before {
    content: "\f0c55"
}

.mdi-clipboard-alert::before {
    content: "\f0149"
}

.mdi-clipboard-alert-outline::before {
    content: "\f0cf7"
}

.mdi-clipboard-arrow-down::before {
    content: "\f014a"
}

.mdi-clipboard-arrow-down-outline::before {
    content: "\f0c56"
}

.mdi-clipboard-arrow-left::before {
    content: "\f014b"
}

.mdi-clipboard-arrow-left-outline::before {
    content: "\f0cf8"
}

.mdi-clipboard-arrow-right::before {
    content: "\f0cf9"
}

.mdi-clipboard-arrow-right-outline::before {
    content: "\f0cfa"
}

.mdi-clipboard-arrow-up::before {
    content: "\f0c57"
}

.mdi-clipboard-arrow-up-outline::before {
    content: "\f0c58"
}

.mdi-clipboard-check::before {
    content: "\f014e"
}

.mdi-clipboard-check-multiple::before {
    content: "\f1263"
}

.mdi-clipboard-check-multiple-outline::before {
    content: "\f1264"
}

.mdi-clipboard-check-outline::before {
    content: "\f08a8"
}

.mdi-clipboard-clock::before {
    content: "\f16e2"
}

.mdi-clipboard-clock-outline::before {
    content: "\f16e3"
}

.mdi-clipboard-edit::before {
    content: "\f14e5"
}

.mdi-clipboard-edit-outline::before {
    content: "\f14e6"
}

.mdi-clipboard-file::before {
    content: "\f1265"
}

.mdi-clipboard-file-outline::before {
    content: "\f1266"
}

.mdi-clipboard-flow::before {
    content: "\f06c8"
}

.mdi-clipboard-flow-outline::before {
    content: "\f1117"
}

.mdi-clipboard-list::before {
    content: "\f10d4"
}

.mdi-clipboard-list-outline::before {
    content: "\f10d5"
}

.mdi-clipboard-minus::before {
    content: "\f1618"
}

.mdi-clipboard-minus-outline::before {
    content: "\f1619"
}

.mdi-clipboard-multiple::before {
    content: "\f1267"
}

.mdi-clipboard-multiple-outline::before {
    content: "\f1268"
}

.mdi-clipboard-off::before {
    content: "\f161a"
}

.mdi-clipboard-off-outline::before {
    content: "\f161b"
}

.mdi-clipboard-outline::before {
    content: "\f014c"
}

.mdi-clipboard-play::before {
    content: "\f0c59"
}

.mdi-clipboard-play-multiple::before {
    content: "\f1269"
}

.mdi-clipboard-play-multiple-outline::before {
    content: "\f126a"
}

.mdi-clipboard-play-outline::before {
    content: "\f0c5a"
}

.mdi-clipboard-plus::before {
    content: "\f0751"
}

.mdi-clipboard-plus-outline::before {
    content: "\f131f"
}

.mdi-clipboard-pulse::before {
    content: "\f085d"
}

.mdi-clipboard-pulse-outline::before {
    content: "\f085e"
}

.mdi-clipboard-remove::before {
    content: "\f161c"
}

.mdi-clipboard-remove-outline::before {
    content: "\f161d"
}

.mdi-clipboard-search::before {
    content: "\f161e"
}

.mdi-clipboard-search-outline::before {
    content: "\f161f"
}

.mdi-clipboard-text::before {
    content: "\f014d"
}

.mdi-clipboard-text-clock::before {
    content: "\f18f9"
}

.mdi-clipboard-text-clock-outline::before {
    content: "\f18fa"
}

.mdi-clipboard-text-multiple::before {
    content: "\f126b"
}

.mdi-clipboard-text-multiple-outline::before {
    content: "\f126c"
}

.mdi-clipboard-text-off::before {
    content: "\f1620"
}

.mdi-clipboard-text-off-outline::before {
    content: "\f1621"
}

.mdi-clipboard-text-outline::before {
    content: "\f0a38"
}

.mdi-clipboard-text-play::before {
    content: "\f0c5b"
}

.mdi-clipboard-text-play-outline::before {
    content: "\f0c5c"
}

.mdi-clipboard-text-search::before {
    content: "\f1622"
}

.mdi-clipboard-text-search-outline::before {
    content: "\f1623"
}

.mdi-clippy::before {
    content: "\f014f"
}

.mdi-clock::before {
    content: "\f0954"
}

.mdi-clock-alert::before {
    content: "\f0955"
}

.mdi-clock-alert-outline::before {
    content: "\f05ce"
}

.mdi-clock-check::before {
    content: "\f0fa8"
}

.mdi-clock-check-outline::before {
    content: "\f0fa9"
}

.mdi-clock-digital::before {
    content: "\f0e97"
}

.mdi-clock-edit::before {
    content: "\f19ba"
}

.mdi-clock-edit-outline::before {
    content: "\f19bb"
}

.mdi-clock-end::before {
    content: "\f0151"
}

.mdi-clock-fast::before {
    content: "\f0152"
}

.mdi-clock-in::before {
    content: "\f0153"
}

.mdi-clock-minus::before {
    content: "\f1863"
}

.mdi-clock-minus-outline::before {
    content: "\f1864"
}

.mdi-clock-out::before {
    content: "\f0154"
}

.mdi-clock-outline::before {
    content: "\f0150"
}

.mdi-clock-plus::before {
    content: "\f1861"
}

.mdi-clock-plus-outline::before {
    content: "\f1862"
}

.mdi-clock-remove::before {
    content: "\f1865"
}

.mdi-clock-remove-outline::before {
    content: "\f1866"
}

.mdi-clock-start::before {
    content: "\f0155"
}

.mdi-clock-time-eight::before {
    content: "\f1446"
}

.mdi-clock-time-eight-outline::before {
    content: "\f1452"
}

.mdi-clock-time-eleven::before {
    content: "\f1449"
}

.mdi-clock-time-eleven-outline::before {
    content: "\f1455"
}

.mdi-clock-time-five::before {
    content: "\f1443"
}

.mdi-clock-time-five-outline::before {
    content: "\f144f"
}

.mdi-clock-time-four::before {
    content: "\f1442"
}

.mdi-clock-time-four-outline::before {
    content: "\f144e"
}

.mdi-clock-time-nine::before {
    content: "\f1447"
}

.mdi-clock-time-nine-outline::before {
    content: "\f1453"
}

.mdi-clock-time-one::before {
    content: "\f143f"
}

.mdi-clock-time-one-outline::before {
    content: "\f144b"
}

.mdi-clock-time-seven::before {
    content: "\f1445"
}

.mdi-clock-time-seven-outline::before {
    content: "\f1451"
}

.mdi-clock-time-six::before {
    content: "\f1444"
}

.mdi-clock-time-six-outline::before {
    content: "\f1450"
}

.mdi-clock-time-ten::before {
    content: "\f1448"
}

.mdi-clock-time-ten-outline::before {
    content: "\f1454"
}

.mdi-clock-time-three::before {
    content: "\f1441"
}

.mdi-clock-time-three-outline::before {
    content: "\f144d"
}

.mdi-clock-time-twelve::before {
    content: "\f144a"
}

.mdi-clock-time-twelve-outline::before {
    content: "\f1456"
}

.mdi-clock-time-two::before {
    content: "\f1440"
}

.mdi-clock-time-two-outline::before {
    content: "\f144c"
}

.mdi-close::before {
    content: "\f0156"
}

.mdi-close-box::before {
    content: "\f0157"
}

.mdi-close-box-multiple::before {
    content: "\f0c5d"
}

.mdi-close-box-multiple-outline::before {
    content: "\f0c5e"
}

.mdi-close-box-outline::before {
    content: "\f0158"
}

.mdi-close-circle::before {
    content: "\f0159"
}

.mdi-close-circle-multiple::before {
    content: "\f062a"
}

.mdi-close-circle-multiple-outline::before {
    content: "\f0883"
}

.mdi-close-circle-outline::before {
    content: "\f015a"
}

.mdi-close-network::before {
    content: "\f015b"
}

.mdi-close-network-outline::before {
    content: "\f0c5f"
}

.mdi-close-octagon::before {
    content: "\f015c"
}

.mdi-close-octagon-outline::before {
    content: "\f015d"
}

.mdi-close-outline::before {
    content: "\f06c9"
}

.mdi-close-thick::before {
    content: "\f1398"
}

.mdi-closed-caption::before {
    content: "\f015e"
}

.mdi-closed-caption-outline::before {
    content: "\f0dbd"
}

.mdi-cloud::before {
    content: "\f015f"
}

.mdi-cloud-alert::before {
    content: "\f09e0"
}

.mdi-cloud-braces::before {
    content: "\f07b5"
}

.mdi-cloud-check::before {
    content: "\f0160"
}

.mdi-cloud-check-outline::before {
    content: "\f12cc"
}

.mdi-cloud-circle::before {
    content: "\f0161"
}

.mdi-cloud-download::before {
    content: "\f0162"
}

.mdi-cloud-download-outline::before {
    content: "\f0b7d"
}

.mdi-cloud-lock::before {
    content: "\f11f1"
}

.mdi-cloud-lock-outline::before {
    content: "\f11f2"
}

.mdi-cloud-off-outline::before {
    content: "\f0164"
}

.mdi-cloud-outline::before {
    content: "\f0163"
}

.mdi-cloud-print::before {
    content: "\f0165"
}

.mdi-cloud-print-outline::before {
    content: "\f0166"
}

.mdi-cloud-question::before {
    content: "\f0a39"
}

.mdi-cloud-refresh::before {
    content: "\f052a"
}

.mdi-cloud-search::before {
    content: "\f0956"
}

.mdi-cloud-search-outline::before {
    content: "\f0957"
}

.mdi-cloud-sync::before {
    content: "\f063f"
}

.mdi-cloud-sync-outline::before {
    content: "\f12d6"
}

.mdi-cloud-tags::before {
    content: "\f07b6"
}

.mdi-cloud-upload::before {
    content: "\f0167"
}

.mdi-cloud-upload-outline::before {
    content: "\f0b7e"
}

.mdi-clover::before {
    content: "\f0816"
}

.mdi-coach-lamp::before {
    content: "\f1020"
}

.mdi-coat-rack::before {
    content: "\f109e"
}

.mdi-code-array::before {
    content: "\f0168"
}

.mdi-code-braces::before {
    content: "\f0169"
}

.mdi-code-braces-box::before {
    content: "\f10d6"
}

.mdi-code-brackets::before {
    content: "\f016a"
}

.mdi-code-equal::before {
    content: "\f016b"
}

.mdi-code-greater-than::before {
    content: "\f016c"
}

.mdi-code-greater-than-or-equal::before {
    content: "\f016d"
}

.mdi-code-json::before {
    content: "\f0626"
}

.mdi-code-less-than::before {
    content: "\f016e"
}

.mdi-code-less-than-or-equal::before {
    content: "\f016f"
}

.mdi-code-not-equal::before {
    content: "\f0170"
}

.mdi-code-not-equal-variant::before {
    content: "\f0171"
}

.mdi-code-parentheses::before {
    content: "\f0172"
}

.mdi-code-parentheses-box::before {
    content: "\f10d7"
}

.mdi-code-string::before {
    content: "\f0173"
}

.mdi-code-tags::before {
    content: "\f0174"
}

.mdi-code-tags-check::before {
    content: "\f0694"
}

.mdi-codepen::before {
    content: "\f0175"
}

.mdi-coffee::before {
    content: "\f0176"
}

.mdi-coffee-maker::before {
    content: "\f109f"
}

.mdi-coffee-maker-check::before {
    content: "\f1931"
}

.mdi-coffee-maker-check-outline::before {
    content: "\f1932"
}

.mdi-coffee-maker-outline::before {
    content: "\f181b"
}

.mdi-coffee-off::before {
    content: "\f0faa"
}

.mdi-coffee-off-outline::before {
    content: "\f0fab"
}

.mdi-coffee-outline::before {
    content: "\f06ca"
}

.mdi-coffee-to-go::before {
    content: "\f0177"
}

.mdi-coffee-to-go-outline::before {
    content: "\f130e"
}

.mdi-coffin::before {
    content: "\f0b7f"
}

.mdi-cog::before {
    content: "\f0493"
}

.mdi-cog-box::before {
    content: "\f0494"
}

.mdi-cog-clockwise::before {
    content: "\f11dd"
}

.mdi-cog-counterclockwise::before {
    content: "\f11de"
}

.mdi-cog-off::before {
    content: "\f13ce"
}

.mdi-cog-off-outline::before {
    content: "\f13cf"
}

.mdi-cog-outline::before {
    content: "\f08bb"
}

.mdi-cog-pause::before {
    content: "\f1933"
}

.mdi-cog-pause-outline::before {
    content: "\f1934"
}

.mdi-cog-play::before {
    content: "\f1935"
}

.mdi-cog-play-outline::before {
    content: "\f1936"
}

.mdi-cog-refresh::before {
    content: "\f145e"
}

.mdi-cog-refresh-outline::before {
    content: "\f145f"
}

.mdi-cog-stop::before {
    content: "\f1937"
}

.mdi-cog-stop-outline::before {
    content: "\f1938"
}

.mdi-cog-sync::before {
    content: "\f1460"
}

.mdi-cog-sync-outline::before {
    content: "\f1461"
}

.mdi-cog-transfer::before {
    content: "\f105b"
}

.mdi-cog-transfer-outline::before {
    content: "\f105c"
}

.mdi-cogs::before {
    content: "\f08d6"
}

.mdi-collage::before {
    content: "\f0640"
}

.mdi-collapse-all::before {
    content: "\f0aa6"
}

.mdi-collapse-all-outline::before {
    content: "\f0aa7"
}

.mdi-color-helper::before {
    content: "\f0179"
}

.mdi-comma::before {
    content: "\f0e23"
}

.mdi-comma-box::before {
    content: "\f0e2b"
}

.mdi-comma-box-outline::before {
    content: "\f0e24"
}

.mdi-comma-circle::before {
    content: "\f0e25"
}

.mdi-comma-circle-outline::before {
    content: "\f0e26"
}

.mdi-comment::before {
    content: "\f017a"
}

.mdi-comment-account::before {
    content: "\f017b"
}

.mdi-comment-account-outline::before {
    content: "\f017c"
}

.mdi-comment-alert::before {
    content: "\f017d"
}

.mdi-comment-alert-outline::before {
    content: "\f017e"
}

.mdi-comment-arrow-left::before {
    content: "\f09e1"
}

.mdi-comment-arrow-left-outline::before {
    content: "\f09e2"
}

.mdi-comment-arrow-right::before {
    content: "\f09e3"
}

.mdi-comment-arrow-right-outline::before {
    content: "\f09e4"
}

.mdi-comment-bookmark::before {
    content: "\f15ae"
}

.mdi-comment-bookmark-outline::before {
    content: "\f15af"
}

.mdi-comment-check::before {
    content: "\f017f"
}

.mdi-comment-check-outline::before {
    content: "\f0180"
}

.mdi-comment-edit::before {
    content: "\f11bf"
}

.mdi-comment-edit-outline::before {
    content: "\f12c4"
}

.mdi-comment-eye::before {
    content: "\f0a3a"
}

.mdi-comment-eye-outline::before {
    content: "\f0a3b"
}

.mdi-comment-flash::before {
    content: "\f15b0"
}

.mdi-comment-flash-outline::before {
    content: "\f15b1"
}

.mdi-comment-minus::before {
    content: "\f15df"
}

.mdi-comment-minus-outline::before {
    content: "\f15e0"
}

.mdi-comment-multiple::before {
    content: "\f085f"
}

.mdi-comment-multiple-outline::before {
    content: "\f0181"
}

.mdi-comment-off::before {
    content: "\f15e1"
}

.mdi-comment-off-outline::before {
    content: "\f15e2"
}

.mdi-comment-outline::before {
    content: "\f0182"
}

.mdi-comment-plus::before {
    content: "\f09e5"
}

.mdi-comment-plus-outline::before {
    content: "\f0183"
}

.mdi-comment-processing::before {
    content: "\f0184"
}

.mdi-comment-processing-outline::before {
    content: "\f0185"
}

.mdi-comment-question::before {
    content: "\f0817"
}

.mdi-comment-question-outline::before {
    content: "\f0186"
}

.mdi-comment-quote::before {
    content: "\f1021"
}

.mdi-comment-quote-outline::before {
    content: "\f1022"
}

.mdi-comment-remove::before {
    content: "\f05de"
}

.mdi-comment-remove-outline::before {
    content: "\f0187"
}

.mdi-comment-search::before {
    content: "\f0a3c"
}

.mdi-comment-search-outline::before {
    content: "\f0a3d"
}

.mdi-comment-text::before {
    content: "\f0188"
}

.mdi-comment-text-multiple::before {
    content: "\f0860"
}

.mdi-comment-text-multiple-outline::before {
    content: "\f0861"
}

.mdi-comment-text-outline::before {
    content: "\f0189"
}

.mdi-compare::before {
    content: "\f018a"
}

.mdi-compare-horizontal::before {
    content: "\f1492"
}

.mdi-compare-remove::before {
    content: "\f18b3"
}

.mdi-compare-vertical::before {
    content: "\f1493"
}

.mdi-compass::before {
    content: "\f018b"
}

.mdi-compass-off::before {
    content: "\f0b80"
}

.mdi-compass-off-outline::before {
    content: "\f0b81"
}

.mdi-compass-outline::before {
    content: "\f018c"
}

.mdi-compass-rose::before {
    content: "\f1382"
}

.mdi-cone::before {
    content: "\f194c"
}

.mdi-cone-off::before {
    content: "\f194d"
}

.mdi-connection::before {
    content: "\f1616"
}

.mdi-console::before {
    content: "\f018d"
}

.mdi-console-line::before {
    content: "\f07b7"
}

.mdi-console-network::before {
    content: "\f08a9"
}

.mdi-console-network-outline::before {
    content: "\f0c60"
}

.mdi-consolidate::before {
    content: "\f10d8"
}

.mdi-contactless-payment::before {
    content: "\f0d6a"
}

.mdi-contactless-payment-circle::before {
    content: "\f0321"
}

.mdi-contactless-payment-circle-outline::before {
    content: "\f0408"
}

.mdi-contacts::before {
    content: "\f06cb"
}

.mdi-contacts-outline::before {
    content: "\f05b8"
}

.mdi-contain::before {
    content: "\f0a3e"
}

.mdi-contain-end::before {
    content: "\f0a3f"
}

.mdi-contain-start::before {
    content: "\f0a40"
}

.mdi-content-copy::before {
    content: "\f018f"
}

.mdi-content-cut::before {
    content: "\f0190"
}

.mdi-content-duplicate::before {
    content: "\f0191"
}

.mdi-content-paste::before {
    content: "\f0192"
}

.mdi-content-save::before {
    content: "\f0193"
}

.mdi-content-save-alert::before {
    content: "\f0f42"
}

.mdi-content-save-alert-outline::before {
    content: "\f0f43"
}

.mdi-content-save-all::before {
    content: "\f0194"
}

.mdi-content-save-all-outline::before {
    content: "\f0f44"
}

.mdi-content-save-check::before {
    content: "\f18ea"
}

.mdi-content-save-check-outline::before {
    content: "\f18eb"
}

.mdi-content-save-cog::before {
    content: "\f145b"
}

.mdi-content-save-cog-outline::before {
    content: "\f145c"
}

.mdi-content-save-edit::before {
    content: "\f0cfb"
}

.mdi-content-save-edit-outline::before {
    content: "\f0cfc"
}

.mdi-content-save-move::before {
    content: "\f0e27"
}

.mdi-content-save-move-outline::before {
    content: "\f0e28"
}

.mdi-content-save-off::before {
    content: "\f1643"
}

.mdi-content-save-off-outline::before {
    content: "\f1644"
}

.mdi-content-save-outline::before {
    content: "\f0818"
}

.mdi-content-save-settings::before {
    content: "\f061b"
}

.mdi-content-save-settings-outline::before {
    content: "\f0b2e"
}

.mdi-contrast::before {
    content: "\f0195"
}

.mdi-contrast-box::before {
    content: "\f0196"
}

.mdi-contrast-circle::before {
    content: "\f0197"
}

.mdi-controller-classic::before {
    content: "\f0b82"
}

.mdi-controller-classic-outline::before {
    content: "\f0b83"
}

.mdi-cookie::before {
    content: "\f0198"
}

.mdi-cookie-alert::before {
    content: "\f16d0"
}

.mdi-cookie-alert-outline::before {
    content: "\f16d1"
}

.mdi-cookie-check::before {
    content: "\f16d2"
}

.mdi-cookie-check-outline::before {
    content: "\f16d3"
}

.mdi-cookie-clock::before {
    content: "\f16e4"
}

.mdi-cookie-clock-outline::before {
    content: "\f16e5"
}

.mdi-cookie-cog::before {
    content: "\f16d4"
}

.mdi-cookie-cog-outline::before {
    content: "\f16d5"
}

.mdi-cookie-edit::before {
    content: "\f16e6"
}

.mdi-cookie-edit-outline::before {
    content: "\f16e7"
}

.mdi-cookie-lock::before {
    content: "\f16e8"
}

.mdi-cookie-lock-outline::before {
    content: "\f16e9"
}

.mdi-cookie-minus::before {
    content: "\f16da"
}

.mdi-cookie-minus-outline::before {
    content: "\f16db"
}

.mdi-cookie-off::before {
    content: "\f16ea"
}

.mdi-cookie-off-outline::before {
    content: "\f16eb"
}

.mdi-cookie-outline::before {
    content: "\f16de"
}

.mdi-cookie-plus::before {
    content: "\f16d6"
}

.mdi-cookie-plus-outline::before {
    content: "\f16d7"
}

.mdi-cookie-refresh::before {
    content: "\f16ec"
}

.mdi-cookie-refresh-outline::before {
    content: "\f16ed"
}

.mdi-cookie-remove::before {
    content: "\f16d8"
}

.mdi-cookie-remove-outline::before {
    content: "\f16d9"
}

.mdi-cookie-settings::before {
    content: "\f16dc"
}

.mdi-cookie-settings-outline::before {
    content: "\f16dd"
}

.mdi-coolant-temperature::before {
    content: "\f03c8"
}

.mdi-copyleft::before {
    content: "\f1939"
}

.mdi-copyright::before {
    content: "\f05e6"
}

.mdi-cordova::before {
    content: "\f0958"
}

.mdi-corn::before {
    content: "\f07b8"
}

.mdi-corn-off::before {
    content: "\f13ef"
}

.mdi-cosine-wave::before {
    content: "\f1479"
}

.mdi-counter::before {
    content: "\f0199"
}

.mdi-countertop::before {
    content: "\f181c"
}

.mdi-countertop-outline::before {
    content: "\f181d"
}

.mdi-cow::before {
    content: "\f019a"
}

.mdi-cow-off::before {
    content: "\f18fc"
}

.mdi-cpu-32-bit::before {
    content: "\f0edf"
}

.mdi-cpu-64-bit::before {
    content: "\f0ee0"
}

.mdi-cradle::before {
    content: "\f198b"
}

.mdi-cradle-outline::before {
    content: "\f1991"
}

.mdi-crane::before {
    content: "\f0862"
}

.mdi-creation::before {
    content: "\f0674"
}

.mdi-creative-commons::before {
    content: "\f0d6b"
}

.mdi-credit-card::before {
    content: "\f0fef"
}

.mdi-credit-card-check::before {
    content: "\f13d0"
}

.mdi-credit-card-check-outline::before {
    content: "\f13d1"
}

.mdi-credit-card-chip::before {
    content: "\f190f"
}

.mdi-credit-card-chip-outline::before {
    content: "\f1910"
}

.mdi-credit-card-clock::before {
    content: "\f0ee1"
}

.mdi-credit-card-clock-outline::before {
    content: "\f0ee2"
}

.mdi-credit-card-edit::before {
    content: "\f17d7"
}

.mdi-credit-card-edit-outline::before {
    content: "\f17d8"
}

.mdi-credit-card-fast::before {
    content: "\f1911"
}

.mdi-credit-card-fast-outline::before {
    content: "\f1912"
}

.mdi-credit-card-lock::before {
    content: "\f18e7"
}

.mdi-credit-card-lock-outline::before {
    content: "\f18e8"
}

.mdi-credit-card-marker::before {
    content: "\f06a8"
}

.mdi-credit-card-marker-outline::before {
    content: "\f0dbe"
}

.mdi-credit-card-minus::before {
    content: "\f0fac"
}

.mdi-credit-card-minus-outline::before {
    content: "\f0fad"
}

.mdi-credit-card-multiple::before {
    content: "\f0ff0"
}

.mdi-credit-card-multiple-outline::before {
    content: "\f019c"
}

.mdi-credit-card-off::before {
    content: "\f0ff1"
}

.mdi-credit-card-off-outline::before {
    content: "\f05e4"
}

.mdi-credit-card-outline::before {
    content: "\f019b"
}

.mdi-credit-card-plus::before {
    content: "\f0ff2"
}

.mdi-credit-card-plus-outline::before {
    content: "\f0676"
}

.mdi-credit-card-refresh::before {
    content: "\f1645"
}

.mdi-credit-card-refresh-outline::before {
    content: "\f1646"
}

.mdi-credit-card-refund::before {
    content: "\f0ff3"
}

.mdi-credit-card-refund-outline::before {
    content: "\f0aa8"
}

.mdi-credit-card-remove::before {
    content: "\f0fae"
}

.mdi-credit-card-remove-outline::before {
    content: "\f0faf"
}

.mdi-credit-card-scan::before {
    content: "\f0ff4"
}

.mdi-credit-card-scan-outline::before {
    content: "\f019d"
}

.mdi-credit-card-search::before {
    content: "\f1647"
}

.mdi-credit-card-search-outline::before {
    content: "\f1648"
}

.mdi-credit-card-settings::before {
    content: "\f0ff5"
}

.mdi-credit-card-settings-outline::before {
    content: "\f08d7"
}

.mdi-credit-card-sync::before {
    content: "\f1649"
}

.mdi-credit-card-sync-outline::before {
    content: "\f164a"
}

.mdi-credit-card-wireless::before {
    content: "\f0802"
}

.mdi-credit-card-wireless-off::before {
    content: "\f057a"
}

.mdi-credit-card-wireless-off-outline::before {
    content: "\f057b"
}

.mdi-credit-card-wireless-outline::before {
    content: "\f0d6c"
}

.mdi-cricket::before {
    content: "\f0d6d"
}

.mdi-crop::before {
    content: "\f019e"
}

.mdi-crop-free::before {
    content: "\f019f"
}

.mdi-crop-landscape::before {
    content: "\f01a0"
}

.mdi-crop-portrait::before {
    content: "\f01a1"
}

.mdi-crop-rotate::before {
    content: "\f0696"
}

.mdi-crop-square::before {
    content: "\f01a2"
}

.mdi-cross::before {
    content: "\f0953"
}

.mdi-cross-bolnisi::before {
    content: "\f0ced"
}

.mdi-cross-celtic::before {
    content: "\f0cf5"
}

.mdi-cross-outline::before {
    content: "\f0cf6"
}

.mdi-crosshairs::before {
    content: "\f01a3"
}

.mdi-crosshairs-gps::before {
    content: "\f01a4"
}

.mdi-crosshairs-off::before {
    content: "\f0f45"
}

.mdi-crosshairs-question::before {
    content: "\f1136"
}

.mdi-crowd::before {
    content: "\f1975"
}

.mdi-crown::before {
    content: "\f01a5"
}

.mdi-crown-circle::before {
    content: "\f17dc"
}

.mdi-crown-circle-outline::before {
    content: "\f17dd"
}

.mdi-crown-outline::before {
    content: "\f11d0"
}

.mdi-cryengine::before {
    content: "\f0959"
}

.mdi-crystal-ball::before {
    content: "\f0b2f"
}

.mdi-cube::before {
    content: "\f01a6"
}

.mdi-cube-off::before {
    content: "\f141c"
}

.mdi-cube-off-outline::before {
    content: "\f141d"
}

.mdi-cube-outline::before {
    content: "\f01a7"
}

.mdi-cube-scan::before {
    content: "\f0b84"
}

.mdi-cube-send::before {
    content: "\f01a8"
}

.mdi-cube-unfolded::before {
    content: "\f01a9"
}

.mdi-cup::before {
    content: "\f01aa"
}

.mdi-cup-off::before {
    content: "\f05e5"
}

.mdi-cup-off-outline::before {
    content: "\f137d"
}

.mdi-cup-outline::before {
    content: "\f130f"
}

.mdi-cup-water::before {
    content: "\f01ab"
}

.mdi-cupboard::before {
    content: "\f0f46"
}

.mdi-cupboard-outline::before {
    content: "\f0f47"
}

.mdi-cupcake::before {
    content: "\f095a"
}

.mdi-curling::before {
    content: "\f0863"
}

.mdi-currency-bdt::before {
    content: "\f0864"
}

.mdi-currency-brl::before {
    content: "\f0b85"
}

.mdi-currency-btc::before {
    content: "\f01ac"
}

.mdi-currency-cny::before {
    content: "\f07ba"
}

.mdi-currency-eth::before {
    content: "\f07bb"
}

.mdi-currency-eur::before {
    content: "\f01ad"
}

.mdi-currency-eur-off::before {
    content: "\f1315"
}

.mdi-currency-gbp::before {
    content: "\f01ae"
}

.mdi-currency-ils::before {
    content: "\f0c61"
}

.mdi-currency-inr::before {
    content: "\f01af"
}

.mdi-currency-jpy::before {
    content: "\f07bc"
}

.mdi-currency-krw::before {
    content: "\f07bd"
}

.mdi-currency-kzt::before {
    content: "\f0865"
}

.mdi-currency-mnt::before {
    content: "\f1512"
}

.mdi-currency-ngn::before {
    content: "\f01b0"
}

.mdi-currency-php::before {
    content: "\f09e6"
}

.mdi-currency-rial::before {
    content: "\f0e9c"
}

.mdi-currency-rub::before {
    content: "\f01b1"
}

.mdi-currency-rupee::before {
    content: "\f1976"
}

.mdi-currency-sign::before {
    content: "\f07be"
}

.mdi-currency-try::before {
    content: "\f01b2"
}

.mdi-currency-twd::before {
    content: "\f07bf"
}

.mdi-currency-usd::before {
    content: "\f01c1"
}

.mdi-currency-usd-off::before {
    content: "\f067a"
}

.mdi-current-ac::before {
    content: "\f1480"
}

.mdi-current-dc::before {
    content: "\f095c"
}

.mdi-cursor-default::before {
    content: "\f01c0"
}

.mdi-cursor-default-click::before {
    content: "\f0cfd"
}

.mdi-cursor-default-click-outline::before {
    content: "\f0cfe"
}

.mdi-cursor-default-gesture::before {
    content: "\f1127"
}

.mdi-cursor-default-gesture-outline::before {
    content: "\f1128"
}

.mdi-cursor-default-outline::before {
    content: "\f01bf"
}

.mdi-cursor-move::before {
    content: "\f01be"
}

.mdi-cursor-pointer::before {
    content: "\f01bd"
}

.mdi-cursor-text::before {
    content: "\f05e7"
}

.mdi-curtains::before {
    content: "\f1846"
}

.mdi-curtains-closed::before {
    content: "\f1847"
}

.mdi-cylinder::before {
    content: "\f194e"
}

.mdi-cylinder-off::before {
    content: "\f194f"
}

.mdi-dance-ballroom::before {
    content: "\f15fb"
}

.mdi-dance-pole::before {
    content: "\f1578"
}

.mdi-data-matrix::before {
    content: "\f153c"
}

.mdi-data-matrix-edit::before {
    content: "\f153d"
}

.mdi-data-matrix-minus::before {
    content: "\f153e"
}

.mdi-data-matrix-plus::before {
    content: "\f153f"
}

.mdi-data-matrix-remove::before {
    content: "\f1540"
}

.mdi-data-matrix-scan::before {
    content: "\f1541"
}

.mdi-database::before {
    content: "\f01bc"
}

.mdi-database-alert::before {
    content: "\f163a"
}

.mdi-database-alert-outline::before {
    content: "\f1624"
}

.mdi-database-arrow-down::before {
    content: "\f163b"
}

.mdi-database-arrow-down-outline::before {
    content: "\f1625"
}

.mdi-database-arrow-left::before {
    content: "\f163c"
}

.mdi-database-arrow-left-outline::before {
    content: "\f1626"
}

.mdi-database-arrow-right::before {
    content: "\f163d"
}

.mdi-database-arrow-right-outline::before {
    content: "\f1627"
}

.mdi-database-arrow-up::before {
    content: "\f163e"
}

.mdi-database-arrow-up-outline::before {
    content: "\f1628"
}

.mdi-database-check::before {
    content: "\f0aa9"
}

.mdi-database-check-outline::before {
    content: "\f1629"
}

.mdi-database-clock::before {
    content: "\f163f"
}

.mdi-database-clock-outline::before {
    content: "\f162a"
}

.mdi-database-cog::before {
    content: "\f164b"
}

.mdi-database-cog-outline::before {
    content: "\f164c"
}

.mdi-database-edit::before {
    content: "\f0b86"
}

.mdi-database-edit-outline::before {
    content: "\f162b"
}

.mdi-database-export::before {
    content: "\f095e"
}

.mdi-database-export-outline::before {
    content: "\f162c"
}

.mdi-database-eye::before {
    content: "\f191f"
}

.mdi-database-eye-off::before {
    content: "\f1920"
}

.mdi-database-eye-off-outline::before {
    content: "\f1921"
}

.mdi-database-eye-outline::before {
    content: "\f1922"
}

.mdi-database-import::before {
    content: "\f095d"
}

.mdi-database-import-outline::before {
    content: "\f162d"
}

.mdi-database-lock::before {
    content: "\f0aaa"
}

.mdi-database-lock-outline::before {
    content: "\f162e"
}

.mdi-database-marker::before {
    content: "\f12f6"
}

.mdi-database-marker-outline::before {
    content: "\f162f"
}

.mdi-database-minus::before {
    content: "\f01bb"
}

.mdi-database-minus-outline::before {
    content: "\f1630"
}

.mdi-database-off::before {
    content: "\f1640"
}

.mdi-database-off-outline::before {
    content: "\f1631"
}

.mdi-database-outline::before {
    content: "\f1632"
}

.mdi-database-plus::before {
    content: "\f01ba"
}

.mdi-database-plus-outline::before {
    content: "\f1633"
}

.mdi-database-refresh::before {
    content: "\f05c2"
}

.mdi-database-refresh-outline::before {
    content: "\f1634"
}

.mdi-database-remove::before {
    content: "\f0d00"
}

.mdi-database-remove-outline::before {
    content: "\f1635"
}

.mdi-database-search::before {
    content: "\f0866"
}

.mdi-database-search-outline::before {
    content: "\f1636"
}

.mdi-database-settings::before {
    content: "\f0d01"
}

.mdi-database-settings-outline::before {
    content: "\f1637"
}

.mdi-database-sync::before {
    content: "\f0cff"
}

.mdi-database-sync-outline::before {
    content: "\f1638"
}

.mdi-death-star::before {
    content: "\f08d8"
}

.mdi-death-star-variant::before {
    content: "\f08d9"
}

.mdi-deathly-hallows::before {
    content: "\f0b87"
}

.mdi-debian::before {
    content: "\f08da"
}

.mdi-debug-step-into::before {
    content: "\f01b9"
}

.mdi-debug-step-out::before {
    content: "\f01b8"
}

.mdi-debug-step-over::before {
    content: "\f01b7"
}

.mdi-decagram::before {
    content: "\f076c"
}

.mdi-decagram-outline::before {
    content: "\f076d"
}

.mdi-decimal::before {
    content: "\f10a1"
}

.mdi-decimal-comma::before {
    content: "\f10a2"
}

.mdi-decimal-comma-decrease::before {
    content: "\f10a3"
}

.mdi-decimal-comma-increase::before {
    content: "\f10a4"
}

.mdi-decimal-decrease::before {
    content: "\f01b6"
}

.mdi-decimal-increase::before {
    content: "\f01b5"
}

.mdi-delete::before {
    content: "\f01b4"
}

.mdi-delete-alert::before {
    content: "\f10a5"
}

.mdi-delete-alert-outline::before {
    content: "\f10a6"
}

.mdi-delete-circle::before {
    content: "\f0683"
}

.mdi-delete-circle-outline::before {
    content: "\f0b88"
}

.mdi-delete-clock::before {
    content: "\f1556"
}

.mdi-delete-clock-outline::before {
    content: "\f1557"
}

.mdi-delete-empty::before {
    content: "\f06cc"
}

.mdi-delete-empty-outline::before {
    content: "\f0e9d"
}

.mdi-delete-forever::before {
    content: "\f05e8"
}

.mdi-delete-forever-outline::before {
    content: "\f0b89"
}

.mdi-delete-off::before {
    content: "\f10a7"
}

.mdi-delete-off-outline::before {
    content: "\f10a8"
}

.mdi-delete-outline::before {
    content: "\f09e7"
}

.mdi-delete-restore::before {
    content: "\f0819"
}

.mdi-delete-sweep::before {
    content: "\f05e9"
}

.mdi-delete-sweep-outline::before {
    content: "\f0c62"
}

.mdi-delete-variant::before {
    content: "\f01b3"
}

.mdi-delta::before {
    content: "\f01c2"
}

.mdi-desk::before {
    content: "\f1239"
}

.mdi-desk-lamp::before {
    content: "\f095f"
}

.mdi-deskphone::before {
    content: "\f01c3"
}

.mdi-desktop-classic::before {
    content: "\f07c0"
}

.mdi-desktop-mac::before {
    content: "\f01c4"
}

.mdi-desktop-mac-dashboard::before {
    content: "\f09e8"
}

.mdi-desktop-tower::before {
    content: "\f01c5"
}

.mdi-desktop-tower-monitor::before {
    content: "\f0aab"
}

.mdi-details::before {
    content: "\f01c6"
}

.mdi-dev-to::before {
    content: "\f0d6e"
}

.mdi-developer-board::before {
    content: "\f0697"
}

.mdi-deviantart::before {
    content: "\f01c7"
}

.mdi-devices::before {
    content: "\f0fb0"
}

.mdi-dharmachakra::before {
    content: "\f094b"
}

.mdi-diabetes::before {
    content: "\f1126"
}

.mdi-dialpad::before {
    content: "\f061c"
}

.mdi-diameter::before {
    content: "\f0c63"
}

.mdi-diameter-outline::before {
    content: "\f0c64"
}

.mdi-diameter-variant::before {
    content: "\f0c65"
}

.mdi-diamond::before {
    content: "\f0b8a"
}

.mdi-diamond-outline::before {
    content: "\f0b8b"
}

.mdi-diamond-stone::before {
    content: "\f01c8"
}

.mdi-dice-1::before {
    content: "\f01ca"
}

.mdi-dice-1-outline::before {
    content: "\f114a"
}

.mdi-dice-2::before {
    content: "\f01cb"
}

.mdi-dice-2-outline::before {
    content: "\f114b"
}

.mdi-dice-3::before {
    content: "\f01cc"
}

.mdi-dice-3-outline::before {
    content: "\f114c"
}

.mdi-dice-4::before {
    content: "\f01cd"
}

.mdi-dice-4-outline::before {
    content: "\f114d"
}

.mdi-dice-5::before {
    content: "\f01ce"
}

.mdi-dice-5-outline::before {
    content: "\f114e"
}

.mdi-dice-6::before {
    content: "\f01cf"
}

.mdi-dice-6-outline::before {
    content: "\f114f"
}

.mdi-dice-d10::before {
    content: "\f1153"
}

.mdi-dice-d10-outline::before {
    content: "\f076f"
}

.mdi-dice-d12::before {
    content: "\f1154"
}

.mdi-dice-d12-outline::before {
    content: "\f0867"
}

.mdi-dice-d20::before {
    content: "\f1155"
}

.mdi-dice-d20-outline::before {
    content: "\f05ea"
}

.mdi-dice-d4::before {
    content: "\f1150"
}

.mdi-dice-d4-outline::before {
    content: "\f05eb"
}

.mdi-dice-d6::before {
    content: "\f1151"
}

.mdi-dice-d6-outline::before {
    content: "\f05ed"
}

.mdi-dice-d8::before {
    content: "\f1152"
}

.mdi-dice-d8-outline::before {
    content: "\f05ec"
}

.mdi-dice-multiple::before {
    content: "\f076e"
}

.mdi-dice-multiple-outline::before {
    content: "\f1156"
}

.mdi-digital-ocean::before {
    content: "\f1237"
}

.mdi-dip-switch::before {
    content: "\f07c1"
}

.mdi-directions::before {
    content: "\f01d0"
}

.mdi-directions-fork::before {
    content: "\f0641"
}

.mdi-disc::before {
    content: "\f05ee"
}

.mdi-disc-alert::before {
    content: "\f01d1"
}

.mdi-disc-player::before {
    content: "\f0960"
}

.mdi-discord::before {
    content: "\f066f"
}

.mdi-dishwasher::before {
    content: "\f0aac"
}

.mdi-dishwasher-alert::before {
    content: "\f11b8"
}

.mdi-dishwasher-off::before {
    content: "\f11b9"
}

.mdi-disqus::before {
    content: "\f01d2"
}

.mdi-distribute-horizontal-center::before {
    content: "\f11c9"
}

.mdi-distribute-horizontal-left::before {
    content: "\f11c8"
}

.mdi-distribute-horizontal-right::before {
    content: "\f11ca"
}

.mdi-distribute-vertical-bottom::before {
    content: "\f11cb"
}

.mdi-distribute-vertical-center::before {
    content: "\f11cc"
}

.mdi-distribute-vertical-top::before {
    content: "\f11cd"
}

.mdi-diversify::before {
    content: "\f1877"
}

.mdi-diving::before {
    content: "\f1977"
}

.mdi-diving-flippers::before {
    content: "\f0dbf"
}

.mdi-diving-helmet::before {
    content: "\f0dc0"
}

.mdi-diving-scuba::before {
    content: "\f0dc1"
}

.mdi-diving-scuba-flag::before {
    content: "\f0dc2"
}

.mdi-diving-scuba-tank::before {
    content: "\f0dc3"
}

.mdi-diving-scuba-tank-multiple::before {
    content: "\f0dc4"
}

.mdi-diving-snorkel::before {
    content: "\f0dc5"
}

.mdi-division::before {
    content: "\f01d4"
}

.mdi-division-box::before {
    content: "\f01d5"
}

.mdi-dlna::before {
    content: "\f0a41"
}

.mdi-dna::before {
    content: "\f0684"
}

.mdi-dns::before {
    content: "\f01d6"
}

.mdi-dns-outline::before {
    content: "\f0b8c"
}

.mdi-dock-bottom::before {
    content: "\f10a9"
}

.mdi-dock-left::before {
    content: "\f10aa"
}

.mdi-dock-right::before {
    content: "\f10ab"
}

.mdi-dock-top::before {
    content: "\f1513"
}

.mdi-dock-window::before {
    content: "\f10ac"
}

.mdi-docker::before {
    content: "\f0868"
}

.mdi-doctor::before {
    content: "\f0a42"
}

.mdi-dog::before {
    content: "\f0a43"
}

.mdi-dog-service::before {
    content: "\f0aad"
}

.mdi-dog-side::before {
    content: "\f0a44"
}

.mdi-dog-side-off::before {
    content: "\f16ee"
}

.mdi-dolby::before {
    content: "\f06b3"
}

.mdi-dolly::before {
    content: "\f0e9e"
}

.mdi-dolphin::before {
    content: "\f18b4"
}

.mdi-domain::before {
    content: "\f01d7"
}

.mdi-domain-off::before {
    content: "\f0d6f"
}

.mdi-domain-plus::before {
    content: "\f10ad"
}

.mdi-domain-remove::before {
    content: "\f10ae"
}

.mdi-dome-light::before {
    content: "\f141e"
}

.mdi-domino-mask::before {
    content: "\f1023"
}

.mdi-donkey::before {
    content: "\f07c2"
}

.mdi-door::before {
    content: "\f081a"
}

.mdi-door-closed::before {
    content: "\f081b"
}

.mdi-door-closed-lock::before {
    content: "\f10af"
}

.mdi-door-open::before {
    content: "\f081c"
}

.mdi-door-sliding::before {
    content: "\f181e"
}

.mdi-door-sliding-lock::before {
    content: "\f181f"
}

.mdi-door-sliding-open::before {
    content: "\f1820"
}

.mdi-doorbell::before {
    content: "\f12e6"
}

.mdi-doorbell-video::before {
    content: "\f0869"
}

.mdi-dot-net::before {
    content: "\f0aae"
}

.mdi-dots-circle::before {
    content: "\f1978"
}

.mdi-dots-grid::before {
    content: "\f15fc"
}

.mdi-dots-hexagon::before {
    content: "\f15ff"
}

.mdi-dots-horizontal::before {
    content: "\f01d8"
}

.mdi-dots-horizontal-circle::before {
    content: "\f07c3"
}

.mdi-dots-horizontal-circle-outline::before {
    content: "\f0b8d"
}

.mdi-dots-square::before {
    content: "\f15fd"
}

.mdi-dots-triangle::before {
    content: "\f15fe"
}

.mdi-dots-vertical::before {
    content: "\f01d9"
}

.mdi-dots-vertical-circle::before {
    content: "\f07c4"
}

.mdi-dots-vertical-circle-outline::before {
    content: "\f0b8e"
}

.mdi-download::before {
    content: "\f01da"
}

.mdi-download-box::before {
    content: "\f1462"
}

.mdi-download-box-outline::before {
    content: "\f1463"
}

.mdi-download-circle::before {
    content: "\f1464"
}

.mdi-download-circle-outline::before {
    content: "\f1465"
}

.mdi-download-lock::before {
    content: "\f1320"
}

.mdi-download-lock-outline::before {
    content: "\f1321"
}

.mdi-download-multiple::before {
    content: "\f09e9"
}

.mdi-download-network::before {
    content: "\f06f4"
}

.mdi-download-network-outline::before {
    content: "\f0c66"
}

.mdi-download-off::before {
    content: "\f10b0"
}

.mdi-download-off-outline::before {
    content: "\f10b1"
}

.mdi-download-outline::before {
    content: "\f0b8f"
}

.mdi-drag::before {
    content: "\f01db"
}

.mdi-drag-horizontal::before {
    content: "\f01dc"
}

.mdi-drag-horizontal-variant::before {
    content: "\f12f0"
}

.mdi-drag-variant::before {
    content: "\f0b90"
}

.mdi-drag-vertical::before {
    content: "\f01dd"
}

.mdi-drag-vertical-variant::before {
    content: "\f12f1"
}

.mdi-drama-masks::before {
    content: "\f0d02"
}

.mdi-draw::before {
    content: "\f0f49"
}

.mdi-draw-pen::before {
    content: "\f19b9"
}

.mdi-drawing::before {
    content: "\f01de"
}

.mdi-drawing-box::before {
    content: "\f01df"
}

.mdi-dresser::before {
    content: "\f0f4a"
}

.mdi-dresser-outline::before {
    content: "\f0f4b"
}

.mdi-drone::before {
    content: "\f01e2"
}

.mdi-dropbox::before {
    content: "\f01e3"
}

.mdi-drupal::before {
    content: "\f01e4"
}

.mdi-duck::before {
    content: "\f01e5"
}

.mdi-dumbbell::before {
    content: "\f01e6"
}

.mdi-dump-truck::before {
    content: "\f0c67"
}

.mdi-ear-hearing::before {
    content: "\f07c5"
}

.mdi-ear-hearing-off::before {
    content: "\f0a45"
}

.mdi-earbuds::before {
    content: "\f184f"
}

.mdi-earbuds-off::before {
    content: "\f1850"
}

.mdi-earbuds-off-outline::before {
    content: "\f1851"
}

.mdi-earbuds-outline::before {
    content: "\f1852"
}

.mdi-earth::before {
    content: "\f01e7"
}

.mdi-earth-arrow-right::before {
    content: "\f1311"
}

.mdi-earth-box::before {
    content: "\f06cd"
}

.mdi-earth-box-minus::before {
    content: "\f1407"
}

.mdi-earth-box-off::before {
    content: "\f06ce"
}

.mdi-earth-box-plus::before {
    content: "\f1406"
}

.mdi-earth-box-remove::before {
    content: "\f1408"
}

.mdi-earth-minus::before {
    content: "\f1404"
}

.mdi-earth-off::before {
    content: "\f01e8"
}

.mdi-earth-plus::before {
    content: "\f1403"
}

.mdi-earth-remove::before {
    content: "\f1405"
}

.mdi-egg::before {
    content: "\f0aaf"
}

.mdi-egg-easter::before {
    content: "\f0ab0"
}

.mdi-egg-fried::before {
    content: "\f184a"
}

.mdi-egg-off::before {
    content: "\f13f0"
}

.mdi-egg-off-outline::before {
    content: "\f13f1"
}

.mdi-egg-outline::before {
    content: "\f13f2"
}

.mdi-eiffel-tower::before {
    content: "\f156b"
}

.mdi-eight-track::before {
    content: "\f09ea"
}

.mdi-eject::before {
    content: "\f01ea"
}

.mdi-eject-outline::before {
    content: "\f0b91"
}

.mdi-electric-switch::before {
    content: "\f0e9f"
}

.mdi-electric-switch-closed::before {
    content: "\f10d9"
}

.mdi-electron-framework::before {
    content: "\f1024"
}

.mdi-elephant::before {
    content: "\f07c6"
}

.mdi-elevation-decline::before {
    content: "\f01eb"
}

.mdi-elevation-rise::before {
    content: "\f01ec"
}

.mdi-elevator::before {
    content: "\f01ed"
}

.mdi-elevator-down::before {
    content: "\f12c2"
}

.mdi-elevator-passenger::before {
    content: "\f1381"
}

.mdi-elevator-passenger-off::before {
    content: "\f1979"
}

.mdi-elevator-passenger-off-outline::before {
    content: "\f197a"
}

.mdi-elevator-passenger-outline::before {
    content: "\f197b"
}

.mdi-elevator-up::before {
    content: "\f12c1"
}

.mdi-ellipse::before {
    content: "\f0ea0"
}

.mdi-ellipse-outline::before {
    content: "\f0ea1"
}

.mdi-email::before {
    content: "\f01ee"
}

.mdi-email-alert::before {
    content: "\f06cf"
}

.mdi-email-alert-outline::before {
    content: "\f0d42"
}

.mdi-email-box::before {
    content: "\f0d03"
}

.mdi-email-check::before {
    content: "\f0ab1"
}

.mdi-email-check-outline::before {
    content: "\f0ab2"
}

.mdi-email-edit::before {
    content: "\f0ee3"
}

.mdi-email-edit-outline::before {
    content: "\f0ee4"
}

.mdi-email-fast::before {
    content: "\f186f"
}

.mdi-email-fast-outline::before {
    content: "\f1870"
}

.mdi-email-lock::before {
    content: "\f01f1"
}

.mdi-email-mark-as-unread::before {
    content: "\f0b92"
}

.mdi-email-minus::before {
    content: "\f0ee5"
}

.mdi-email-minus-outline::before {
    content: "\f0ee6"
}

.mdi-email-multiple::before {
    content: "\f0ee7"
}

.mdi-email-multiple-outline::before {
    content: "\f0ee8"
}

.mdi-email-newsletter::before {
    content: "\f0fb1"
}

.mdi-email-off::before {
    content: "\f13e3"
}

.mdi-email-off-outline::before {
    content: "\f13e4"
}

.mdi-email-open::before {
    content: "\f01ef"
}

.mdi-email-open-multiple::before {
    content: "\f0ee9"
}

.mdi-email-open-multiple-outline::before {
    content: "\f0eea"
}

.mdi-email-open-outline::before {
    content: "\f05ef"
}

.mdi-email-outline::before {
    content: "\f01f0"
}

.mdi-email-plus::before {
    content: "\f09eb"
}

.mdi-email-plus-outline::before {
    content: "\f09ec"
}

.mdi-email-receive::before {
    content: "\f10da"
}

.mdi-email-receive-outline::before {
    content: "\f10db"
}

.mdi-email-remove::before {
    content: "\f1661"
}

.mdi-email-remove-outline::before {
    content: "\f1662"
}

.mdi-email-seal::before {
    content: "\f195b"
}

.mdi-email-seal-outline::before {
    content: "\f195c"
}

.mdi-email-search::before {
    content: "\f0961"
}

.mdi-email-search-outline::before {
    content: "\f0962"
}

.mdi-email-send::before {
    content: "\f10dc"
}

.mdi-email-send-outline::before {
    content: "\f10dd"
}

.mdi-email-sync::before {
    content: "\f12c7"
}

.mdi-email-sync-outline::before {
    content: "\f12c8"
}

.mdi-email-variant::before {
    content: "\f05f0"
}

.mdi-ember::before {
    content: "\f0b30"
}

.mdi-emby::before {
    content: "\f06b4"
}

.mdi-emoticon::before {
    content: "\f0c68"
}

.mdi-emoticon-angry::before {
    content: "\f0c69"
}

.mdi-emoticon-angry-outline::before {
    content: "\f0c6a"
}

.mdi-emoticon-confused::before {
    content: "\f10de"
}

.mdi-emoticon-confused-outline::before {
    content: "\f10df"
}

.mdi-emoticon-cool::before {
    content: "\f0c6b"
}

.mdi-emoticon-cool-outline::before {
    content: "\f01f3"
}

.mdi-emoticon-cry::before {
    content: "\f0c6c"
}

.mdi-emoticon-cry-outline::before {
    content: "\f0c6d"
}

.mdi-emoticon-dead::before {
    content: "\f0c6e"
}

.mdi-emoticon-dead-outline::before {
    content: "\f069b"
}

.mdi-emoticon-devil::before {
    content: "\f0c6f"
}

.mdi-emoticon-devil-outline::before {
    content: "\f01f4"
}

.mdi-emoticon-excited::before {
    content: "\f0c70"
}

.mdi-emoticon-excited-outline::before {
    content: "\f069c"
}

.mdi-emoticon-frown::before {
    content: "\f0f4c"
}

.mdi-emoticon-frown-outline::before {
    content: "\f0f4d"
}

.mdi-emoticon-happy::before {
    content: "\f0c71"
}

.mdi-emoticon-happy-outline::before {
    content: "\f01f5"
}

.mdi-emoticon-kiss::before {
    content: "\f0c72"
}

.mdi-emoticon-kiss-outline::before {
    content: "\f0c73"
}

.mdi-emoticon-lol::before {
    content: "\f1214"
}

.mdi-emoticon-lol-outline::before {
    content: "\f1215"
}

.mdi-emoticon-neutral::before {
    content: "\f0c74"
}

.mdi-emoticon-neutral-outline::before {
    content: "\f01f6"
}

.mdi-emoticon-outline::before {
    content: "\f01f2"
}

.mdi-emoticon-poop::before {
    content: "\f01f7"
}

.mdi-emoticon-poop-outline::before {
    content: "\f0c75"
}

.mdi-emoticon-sad::before {
    content: "\f0c76"
}

.mdi-emoticon-sad-outline::before {
    content: "\f01f8"
}

.mdi-emoticon-sick::before {
    content: "\f157c"
}

.mdi-emoticon-sick-outline::before {
    content: "\f157d"
}

.mdi-emoticon-tongue::before {
    content: "\f01f9"
}

.mdi-emoticon-tongue-outline::before {
    content: "\f0c77"
}

.mdi-emoticon-wink::before {
    content: "\f0c78"
}

.mdi-emoticon-wink-outline::before {
    content: "\f0c79"
}

.mdi-engine::before {
    content: "\f01fa"
}

.mdi-engine-off::before {
    content: "\f0a46"
}

.mdi-engine-off-outline::before {
    content: "\f0a47"
}

.mdi-engine-outline::before {
    content: "\f01fb"
}

.mdi-epsilon::before {
    content: "\f10e0"
}

.mdi-equal::before {
    content: "\f01fc"
}

.mdi-equal-box::before {
    content: "\f01fd"
}

.mdi-equalizer::before {
    content: "\f0ea2"
}

.mdi-equalizer-outline::before {
    content: "\f0ea3"
}

.mdi-eraser::before {
    content: "\f01fe"
}

.mdi-eraser-variant::before {
    content: "\f0642"
}

.mdi-escalator::before {
    content: "\f01ff"
}

.mdi-escalator-box::before {
    content: "\f1399"
}

.mdi-escalator-down::before {
    content: "\f12c0"
}

.mdi-escalator-up::before {
    content: "\f12bf"
}

.mdi-eslint::before {
    content: "\f0c7a"
}

.mdi-et::before {
    content: "\f0ab3"
}

.mdi-ethereum::before {
    content: "\f086a"
}

.mdi-ethernet::before {
    content: "\f0200"
}

.mdi-ethernet-cable::before {
    content: "\f0201"
}

.mdi-ethernet-cable-off::before {
    content: "\f0202"
}

.mdi-ev-plug-ccs1::before {
    content: "\f1519"
}

.mdi-ev-plug-ccs2::before {
    content: "\f151a"
}

.mdi-ev-plug-chademo::before {
    content: "\f151b"
}

.mdi-ev-plug-tesla::before {
    content: "\f151c"
}

.mdi-ev-plug-type1::before {
    content: "\f151d"
}

.mdi-ev-plug-type2::before {
    content: "\f151e"
}

.mdi-ev-station::before {
    content: "\f05f1"
}

.mdi-evernote::before {
    content: "\f0204"
}

.mdi-excavator::before {
    content: "\f1025"
}

.mdi-exclamation::before {
    content: "\f0205"
}

.mdi-exclamation-thick::before {
    content: "\f1238"
}

.mdi-exit-run::before {
    content: "\f0a48"
}

.mdi-exit-to-app::before {
    content: "\f0206"
}

.mdi-expand-all::before {
    content: "\f0ab4"
}

.mdi-expand-all-outline::before {
    content: "\f0ab5"
}

.mdi-expansion-card::before {
    content: "\f08ae"
}

.mdi-expansion-card-variant::before {
    content: "\f0fb2"
}

.mdi-exponent::before {
    content: "\f0963"
}

.mdi-exponent-box::before {
    content: "\f0964"
}

.mdi-export::before {
    content: "\f0207"
}

.mdi-export-variant::before {
    content: "\f0b93"
}

.mdi-eye::before {
    content: "\f0208"
}

.mdi-eye-arrow-left::before {
    content: "\f18fd"
}

.mdi-eye-arrow-left-outline::before {
    content: "\f18fe"
}

.mdi-eye-arrow-right::before {
    content: "\f18ff"
}

.mdi-eye-arrow-right-outline::before {
    content: "\f1900"
}

.mdi-eye-check::before {
    content: "\f0d04"
}

.mdi-eye-check-outline::before {
    content: "\f0d05"
}

.mdi-eye-circle::before {
    content: "\f0b94"
}

.mdi-eye-circle-outline::before {
    content: "\f0b95"
}

.mdi-eye-minus::before {
    content: "\f1026"
}

.mdi-eye-minus-outline::before {
    content: "\f1027"
}

.mdi-eye-off::before {
    content: "\f0209"
}

.mdi-eye-off-outline::before {
    content: "\f06d1"
}

.mdi-eye-outline::before {
    content: "\f06d0"
}

.mdi-eye-plus::before {
    content: "\f086b"
}

.mdi-eye-plus-outline::before {
    content: "\f086c"
}

.mdi-eye-refresh::before {
    content: "\f197c"
}

.mdi-eye-refresh-outline::before {
    content: "\f197d"
}

.mdi-eye-remove::before {
    content: "\f15e3"
}

.mdi-eye-remove-outline::before {
    content: "\f15e4"
}

.mdi-eye-settings::before {
    content: "\f086d"
}

.mdi-eye-settings-outline::before {
    content: "\f086e"
}

.mdi-eyedropper::before {
    content: "\f020a"
}

.mdi-eyedropper-minus::before {
    content: "\f13dd"
}

.mdi-eyedropper-off::before {
    content: "\f13df"
}

.mdi-eyedropper-plus::before {
    content: "\f13dc"
}

.mdi-eyedropper-remove::before {
    content: "\f13de"
}

.mdi-eyedropper-variant::before {
    content: "\f020b"
}

.mdi-face-agent::before {
    content: "\f0d70"
}

.mdi-face-man::before {
    content: "\f0643"
}

.mdi-face-man-outline::before {
    content: "\f0b96"
}

.mdi-face-man-profile::before {
    content: "\f0644"
}

.mdi-face-man-shimmer::before {
    content: "\f15cc"
}

.mdi-face-man-shimmer-outline::before {
    content: "\f15cd"
}

.mdi-face-mask::before {
    content: "\f1586"
}

.mdi-face-mask-outline::before {
    content: "\f1587"
}

.mdi-face-recognition::before {
    content: "\f0c7b"
}

.mdi-face-woman::before {
    content: "\f1077"
}

.mdi-face-woman-outline::before {
    content: "\f1078"
}

.mdi-face-woman-profile::before {
    content: "\f1076"
}

.mdi-face-woman-shimmer::before {
    content: "\f15ce"
}

.mdi-face-woman-shimmer-outline::before {
    content: "\f15cf"
}

.mdi-facebook::before {
    content: "\f020c"
}

.mdi-facebook-gaming::before {
    content: "\f07dd"
}

.mdi-facebook-messenger::before {
    content: "\f020e"
}

.mdi-facebook-workplace::before {
    content: "\f0b31"
}

.mdi-factory::before {
    content: "\f020f"
}

.mdi-family-tree::before {
    content: "\f160e"
}

.mdi-fan::before {
    content: "\f0210"
}

.mdi-fan-alert::before {
    content: "\f146c"
}

.mdi-fan-auto::before {
    content: "\f171d"
}

.mdi-fan-chevron-down::before {
    content: "\f146d"
}

.mdi-fan-chevron-up::before {
    content: "\f146e"
}

.mdi-fan-minus::before {
    content: "\f1470"
}

.mdi-fan-off::before {
    content: "\f081d"
}

.mdi-fan-plus::before {
    content: "\f146f"
}

.mdi-fan-remove::before {
    content: "\f1471"
}

.mdi-fan-speed-1::before {
    content: "\f1472"
}

.mdi-fan-speed-2::before {
    content: "\f1473"
}

.mdi-fan-speed-3::before {
    content: "\f1474"
}

.mdi-fast-forward::before {
    content: "\f0211"
}

.mdi-fast-forward-10::before {
    content: "\f0d71"
}

.mdi-fast-forward-15::before {
    content: "\f193a"
}

.mdi-fast-forward-30::before {
    content: "\f0d06"
}

.mdi-fast-forward-5::before {
    content: "\f11f8"
}

.mdi-fast-forward-60::before {
    content: "\f160b"
}

.mdi-fast-forward-outline::before {
    content: "\f06d2"
}

.mdi-fax::before {
    content: "\f0212"
}

.mdi-feather::before {
    content: "\f06d3"
}

.mdi-feature-search::before {
    content: "\f0a49"
}

.mdi-feature-search-outline::before {
    content: "\f0a4a"
}

.mdi-fedora::before {
    content: "\f08db"
}

.mdi-fence::before {
    content: "\f179a"
}

.mdi-fence-electric::before {
    content: "\f17f6"
}

.mdi-fencing::before {
    content: "\f14c1"
}

.mdi-ferris-wheel::before {
    content: "\f0ea4"
}

.mdi-ferry::before {
    content: "\f0213"
}

.mdi-file::before {
    content: "\f0214"
}

.mdi-file-account::before {
    content: "\f073b"
}

.mdi-file-account-outline::before {
    content: "\f1028"
}

.mdi-file-alert::before {
    content: "\f0a4b"
}

.mdi-file-alert-outline::before {
    content: "\f0a4c"
}

.mdi-file-cabinet::before {
    content: "\f0ab6"
}

.mdi-file-cad::before {
    content: "\f0eeb"
}

.mdi-file-cad-box::before {
    content: "\f0eec"
}

.mdi-file-cancel::before {
    content: "\f0dc6"
}

.mdi-file-cancel-outline::before {
    content: "\f0dc7"
}

.mdi-file-certificate::before {
    content: "\f1186"
}

.mdi-file-certificate-outline::before {
    content: "\f1187"
}

.mdi-file-chart::before {
    content: "\f0215"
}

.mdi-file-chart-outline::before {
    content: "\f1029"
}

.mdi-file-check::before {
    content: "\f0216"
}

.mdi-file-check-outline::before {
    content: "\f0e29"
}

.mdi-file-clock::before {
    content: "\f12e1"
}

.mdi-file-clock-outline::before {
    content: "\f12e2"
}

.mdi-file-cloud::before {
    content: "\f0217"
}

.mdi-file-cloud-outline::before {
    content: "\f102a"
}

.mdi-file-code::before {
    content: "\f022e"
}

.mdi-file-code-outline::before {
    content: "\f102b"
}

.mdi-file-cog::before {
    content: "\f107b"
}

.mdi-file-cog-outline::before {
    content: "\f107c"
}

.mdi-file-compare::before {
    content: "\f08aa"
}

.mdi-file-delimited::before {
    content: "\f0218"
}

.mdi-file-delimited-outline::before {
    content: "\f0ea5"
}

.mdi-file-document::before {
    content: "\f0219"
}

.mdi-file-document-edit::before {
    content: "\f0dc8"
}

.mdi-file-document-edit-outline::before {
    content: "\f0dc9"
}

.mdi-file-document-multiple::before {
    content: "\f1517"
}

.mdi-file-document-multiple-outline::before {
    content: "\f1518"
}

.mdi-file-document-outline::before {
    content: "\f09ee"
}

.mdi-file-download::before {
    content: "\f0965"
}

.mdi-file-download-outline::before {
    content: "\f0966"
}

.mdi-file-edit::before {
    content: "\f11e7"
}

.mdi-file-edit-outline::before {
    content: "\f11e8"
}

.mdi-file-excel::before {
    content: "\f021b"
}

.mdi-file-excel-box::before {
    content: "\f021c"
}

.mdi-file-excel-box-outline::before {
    content: "\f102c"
}

.mdi-file-excel-outline::before {
    content: "\f102d"
}

.mdi-file-export::before {
    content: "\f021d"
}

.mdi-file-export-outline::before {
    content: "\f102e"
}

.mdi-file-eye::before {
    content: "\f0dca"
}

.mdi-file-eye-outline::before {
    content: "\f0dcb"
}

.mdi-file-find::before {
    content: "\f021e"
}

.mdi-file-find-outline::before {
    content: "\f0b97"
}

.mdi-file-gif-box::before {
    content: "\f0d78"
}

.mdi-file-hidden::before {
    content: "\f0613"
}

.mdi-file-image::before {
    content: "\f021f"
}

.mdi-file-image-marker::before {
    content: "\f1772"
}

.mdi-file-image-marker-outline::before {
    content: "\f1773"
}

.mdi-file-image-minus::before {
    content: "\f193b"
}

.mdi-file-image-minus-outline::before {
    content: "\f193c"
}

.mdi-file-image-outline::before {
    content: "\f0eb0"
}

.mdi-file-image-plus::before {
    content: "\f193d"
}

.mdi-file-image-plus-outline::before {
    content: "\f193e"
}

.mdi-file-image-remove::before {
    content: "\f193f"
}

.mdi-file-image-remove-outline::before {
    content: "\f1940"
}

.mdi-file-import::before {
    content: "\f0220"
}

.mdi-file-import-outline::before {
    content: "\f102f"
}

.mdi-file-jpg-box::before {
    content: "\f0225"
}

.mdi-file-key::before {
    content: "\f1184"
}

.mdi-file-key-outline::before {
    content: "\f1185"
}

.mdi-file-link::before {
    content: "\f1177"
}

.mdi-file-link-outline::before {
    content: "\f1178"
}

.mdi-file-lock::before {
    content: "\f0221"
}

.mdi-file-lock-outline::before {
    content: "\f1030"
}

.mdi-file-marker::before {
    content: "\f1774"
}

.mdi-file-marker-outline::before {
    content: "\f1775"
}

.mdi-file-move::before {
    content: "\f0ab9"
}

.mdi-file-move-outline::before {
    content: "\f1031"
}

.mdi-file-multiple::before {
    content: "\f0222"
}

.mdi-file-multiple-outline::before {
    content: "\f1032"
}

.mdi-file-music::before {
    content: "\f0223"
}

.mdi-file-music-outline::before {
    content: "\f0e2a"
}

.mdi-file-outline::before {
    content: "\f0224"
}

.mdi-file-pdf-box::before {
    content: "\f0226"
}

.mdi-file-percent::before {
    content: "\f081e"
}

.mdi-file-percent-outline::before {
    content: "\f1033"
}

.mdi-file-phone::before {
    content: "\f1179"
}

.mdi-file-phone-outline::before {
    content: "\f117a"
}

.mdi-file-plus::before {
    content: "\f0752"
}

.mdi-file-plus-outline::before {
    content: "\f0eed"
}

.mdi-file-png-box::before {
    content: "\f0e2d"
}

.mdi-file-powerpoint::before {
    content: "\f0227"
}

.mdi-file-powerpoint-box::before {
    content: "\f0228"
}

.mdi-file-powerpoint-box-outline::before {
    content: "\f1034"
}

.mdi-file-powerpoint-outline::before {
    content: "\f1035"
}

.mdi-file-presentation-box::before {
    content: "\f0229"
}

.mdi-file-question::before {
    content: "\f086f"
}

.mdi-file-question-outline::before {
    content: "\f1036"
}

.mdi-file-refresh::before {
    content: "\f0918"
}

.mdi-file-refresh-outline::before {
    content: "\f0541"
}

.mdi-file-remove::before {
    content: "\f0b98"
}

.mdi-file-remove-outline::before {
    content: "\f1037"
}

.mdi-file-replace::before {
    content: "\f0b32"
}

.mdi-file-replace-outline::before {
    content: "\f0b33"
}

.mdi-file-restore::before {
    content: "\f0670"
}

.mdi-file-restore-outline::before {
    content: "\f1038"
}

.mdi-file-search::before {
    content: "\f0c7c"
}

.mdi-file-search-outline::before {
    content: "\f0c7d"
}

.mdi-file-send::before {
    content: "\f022a"
}

.mdi-file-send-outline::before {
    content: "\f1039"
}

.mdi-file-settings::before {
    content: "\f1079"
}

.mdi-file-settings-outline::before {
    content: "\f107a"
}

.mdi-file-sign::before {
    content: "\f19c3"
}

.mdi-file-star::before {
    content: "\f103a"
}

.mdi-file-star-outline::before {
    content: "\f103b"
}

.mdi-file-swap::before {
    content: "\f0fb4"
}

.mdi-file-swap-outline::before {
    content: "\f0fb5"
}

.mdi-file-sync::before {
    content: "\f1216"
}

.mdi-file-sync-outline::before {
    content: "\f1217"
}

.mdi-file-table::before {
    content: "\f0c7e"
}

.mdi-file-table-box::before {
    content: "\f10e1"
}

.mdi-file-table-box-multiple::before {
    content: "\f10e2"
}

.mdi-file-table-box-multiple-outline::before {
    content: "\f10e3"
}

.mdi-file-table-box-outline::before {
    content: "\f10e4"
}

.mdi-file-table-outline::before {
    content: "\f0c7f"
}

.mdi-file-tree::before {
    content: "\f0645"
}

.mdi-file-tree-outline::before {
    content: "\f13d2"
}

.mdi-file-undo::before {
    content: "\f08dc"
}

.mdi-file-undo-outline::before {
    content: "\f103c"
}

.mdi-file-upload::before {
    content: "\f0a4d"
}

.mdi-file-upload-outline::before {
    content: "\f0a4e"
}

.mdi-file-video::before {
    content: "\f022b"
}

.mdi-file-video-outline::before {
    content: "\f0e2c"
}

.mdi-file-word::before {
    content: "\f022c"
}

.mdi-file-word-box::before {
    content: "\f022d"
}

.mdi-file-word-box-outline::before {
    content: "\f103d"
}

.mdi-file-word-outline::before {
    content: "\f103e"
}

.mdi-film::before {
    content: "\f022f"
}

.mdi-filmstrip::before {
    content: "\f0230"
}

.mdi-filmstrip-box::before {
    content: "\f0332"
}

.mdi-filmstrip-box-multiple::before {
    content: "\f0d18"
}

.mdi-filmstrip-off::before {
    content: "\f0231"
}

.mdi-filter::before {
    content: "\f0232"
}

.mdi-filter-check::before {
    content: "\f18ec"
}

.mdi-filter-check-outline::before {
    content: "\f18ed"
}

.mdi-filter-menu::before {
    content: "\f10e5"
}

.mdi-filter-menu-outline::before {
    content: "\f10e6"
}

.mdi-filter-minus::before {
    content: "\f0eee"
}

.mdi-filter-minus-outline::before {
    content: "\f0eef"
}

.mdi-filter-off::before {
    content: "\f14ef"
}

.mdi-filter-off-outline::before {
    content: "\f14f0"
}

.mdi-filter-outline::before {
    content: "\f0233"
}

.mdi-filter-plus::before {
    content: "\f0ef0"
}

.mdi-filter-plus-outline::before {
    content: "\f0ef1"
}

.mdi-filter-remove::before {
    content: "\f0234"
}

.mdi-filter-remove-outline::before {
    content: "\f0235"
}

.mdi-filter-variant::before {
    content: "\f0236"
}

.mdi-filter-variant-minus::before {
    content: "\f1112"
}

.mdi-filter-variant-plus::before {
    content: "\f1113"
}

.mdi-filter-variant-remove::before {
    content: "\f103f"
}

.mdi-finance::before {
    content: "\f081f"
}

.mdi-find-replace::before {
    content: "\f06d4"
}

.mdi-fingerprint::before {
    content: "\f0237"
}

.mdi-fingerprint-off::before {
    content: "\f0eb1"
}

.mdi-fire::before {
    content: "\f0238"
}

.mdi-fire-alert::before {
    content: "\f15d7"
}

.mdi-fire-circle::before {
    content: "\f1807"
}

.mdi-fire-extinguisher::before {
    content: "\f0ef2"
}

.mdi-fire-hydrant::before {
    content: "\f1137"
}

.mdi-fire-hydrant-alert::before {
    content: "\f1138"
}

.mdi-fire-hydrant-off::before {
    content: "\f1139"
}

.mdi-fire-off::before {
    content: "\f1722"
}

.mdi-fire-truck::before {
    content: "\f08ab"
}

.mdi-firebase::before {
    content: "\f0967"
}

.mdi-firefox::before {
    content: "\f0239"
}

.mdi-fireplace::before {
    content: "\f0e2e"
}

.mdi-fireplace-off::before {
    content: "\f0e2f"
}

.mdi-firewire::before {
    content: "\f05be"
}

.mdi-firework::before {
    content: "\f0e30"
}

.mdi-firework-off::before {
    content: "\f1723"
}

.mdi-fish::before {
    content: "\f023a"
}

.mdi-fish-off::before {
    content: "\f13f3"
}

.mdi-fishbowl::before {
    content: "\f0ef3"
}

.mdi-fishbowl-outline::before {
    content: "\f0ef4"
}

.mdi-fit-to-page::before {
    content: "\f0ef5"
}

.mdi-fit-to-page-outline::before {
    content: "\f0ef6"
}

.mdi-fit-to-screen::before {
    content: "\f18f4"
}

.mdi-fit-to-screen-outline::before {
    content: "\f18f5"
}

.mdi-flag::before {
    content: "\f023b"
}

.mdi-flag-checkered::before {
    content: "\f023c"
}

.mdi-flag-minus::before {
    content: "\f0b99"
}

.mdi-flag-minus-outline::before {
    content: "\f10b2"
}

.mdi-flag-off::before {
    content: "\f18ee"
}

.mdi-flag-off-outline::before {
    content: "\f18ef"
}

.mdi-flag-outline::before {
    content: "\f023d"
}

.mdi-flag-plus::before {
    content: "\f0b9a"
}

.mdi-flag-plus-outline::before {
    content: "\f10b3"
}

.mdi-flag-remove::before {
    content: "\f0b9b"
}

.mdi-flag-remove-outline::before {
    content: "\f10b4"
}

.mdi-flag-triangle::before {
    content: "\f023f"
}

.mdi-flag-variant::before {
    content: "\f0240"
}

.mdi-flag-variant-outline::before {
    content: "\f023e"
}

.mdi-flare::before {
    content: "\f0d72"
}

.mdi-flash::before {
    content: "\f0241"
}

.mdi-flash-alert::before {
    content: "\f0ef7"
}

.mdi-flash-alert-outline::before {
    content: "\f0ef8"
}

.mdi-flash-auto::before {
    content: "\f0242"
}

.mdi-flash-off::before {
    content: "\f0243"
}

.mdi-flash-outline::before {
    content: "\f06d5"
}

.mdi-flash-red-eye::before {
    content: "\f067b"
}

.mdi-flashlight::before {
    content: "\f0244"
}

.mdi-flashlight-off::before {
    content: "\f0245"
}

.mdi-flask::before {
    content: "\f0093"
}

.mdi-flask-empty::before {
    content: "\f0094"
}

.mdi-flask-empty-minus::before {
    content: "\f123a"
}

.mdi-flask-empty-minus-outline::before {
    content: "\f123b"
}

.mdi-flask-empty-off::before {
    content: "\f13f4"
}

.mdi-flask-empty-off-outline::before {
    content: "\f13f5"
}

.mdi-flask-empty-outline::before {
    content: "\f0095"
}

.mdi-flask-empty-plus::before {
    content: "\f123c"
}

.mdi-flask-empty-plus-outline::before {
    content: "\f123d"
}

.mdi-flask-empty-remove::before {
    content: "\f123e"
}

.mdi-flask-empty-remove-outline::before {
    content: "\f123f"
}

.mdi-flask-minus::before {
    content: "\f1240"
}

.mdi-flask-minus-outline::before {
    content: "\f1241"
}

.mdi-flask-off::before {
    content: "\f13f6"
}

.mdi-flask-off-outline::before {
    content: "\f13f7"
}

.mdi-flask-outline::before {
    content: "\f0096"
}

.mdi-flask-plus::before {
    content: "\f1242"
}

.mdi-flask-plus-outline::before {
    content: "\f1243"
}

.mdi-flask-remove::before {
    content: "\f1244"
}

.mdi-flask-remove-outline::before {
    content: "\f1245"
}

.mdi-flask-round-bottom::before {
    content: "\f124b"
}

.mdi-flask-round-bottom-empty::before {
    content: "\f124c"
}

.mdi-flask-round-bottom-empty-outline::before {
    content: "\f124d"
}

.mdi-flask-round-bottom-outline::before {
    content: "\f124e"
}

.mdi-fleur-de-lis::before {
    content: "\f1303"
}

.mdi-flip-horizontal::before {
    content: "\f10e7"
}

.mdi-flip-to-back::before {
    content: "\f0247"
}

.mdi-flip-to-front::before {
    content: "\f0248"
}

.mdi-flip-vertical::before {
    content: "\f10e8"
}

.mdi-floor-lamp::before {
    content: "\f08dd"
}

.mdi-floor-lamp-dual::before {
    content: "\f1040"
}

.mdi-floor-lamp-dual-outline::before {
    content: "\f17ce"
}

.mdi-floor-lamp-outline::before {
    content: "\f17c8"
}

.mdi-floor-lamp-torchiere::before {
    content: "\f1747"
}

.mdi-floor-lamp-torchiere-outline::before {
    content: "\f17d6"
}

.mdi-floor-lamp-torchiere-variant::before {
    content: "\f1041"
}

.mdi-floor-lamp-torchiere-variant-outline::before {
    content: "\f17cf"
}

.mdi-floor-plan::before {
    content: "\f0821"
}

.mdi-floppy::before {
    content: "\f0249"
}

.mdi-floppy-variant::before {
    content: "\f09ef"
}

.mdi-flower::before {
    content: "\f024a"
}

.mdi-flower-outline::before {
    content: "\f09f0"
}

.mdi-flower-pollen::before {
    content: "\f1885"
}

.mdi-flower-pollen-outline::before {
    content: "\f1886"
}

.mdi-flower-poppy::before {
    content: "\f0d08"
}

.mdi-flower-tulip::before {
    content: "\f09f1"
}

.mdi-flower-tulip-outline::before {
    content: "\f09f2"
}

.mdi-focus-auto::before {
    content: "\f0f4e"
}

.mdi-focus-field::before {
    content: "\f0f4f"
}

.mdi-focus-field-horizontal::before {
    content: "\f0f50"
}

.mdi-focus-field-vertical::before {
    content: "\f0f51"
}

.mdi-folder::before {
    content: "\f024b"
}

.mdi-folder-account::before {
    content: "\f024c"
}

.mdi-folder-account-outline::before {
    content: "\f0b9c"
}

.mdi-folder-alert::before {
    content: "\f0dcc"
}

.mdi-folder-alert-outline::before {
    content: "\f0dcd"
}

.mdi-folder-check::before {
    content: "\f197e"
}

.mdi-folder-check-outline::before {
    content: "\f197f"
}

.mdi-folder-clock::before {
    content: "\f0aba"
}

.mdi-folder-clock-outline::before {
    content: "\f0abb"
}

.mdi-folder-cog::before {
    content: "\f107f"
}

.mdi-folder-cog-outline::before {
    content: "\f1080"
}

.mdi-folder-download::before {
    content: "\f024d"
}

.mdi-folder-download-outline::before {
    content: "\f10e9"
}

.mdi-folder-edit::before {
    content: "\f08de"
}

.mdi-folder-edit-outline::before {
    content: "\f0dce"
}

.mdi-folder-eye::before {
    content: "\f178a"
}

.mdi-folder-eye-outline::before {
    content: "\f178b"
}

.mdi-folder-google-drive::before {
    content: "\f024e"
}

.mdi-folder-heart::before {
    content: "\f10ea"
}

.mdi-folder-heart-outline::before {
    content: "\f10eb"
}

.mdi-folder-hidden::before {
    content: "\f179e"
}

.mdi-folder-home::before {
    content: "\f10b5"
}

.mdi-folder-home-outline::before {
    content: "\f10b6"
}

.mdi-folder-image::before {
    content: "\f024f"
}

.mdi-folder-information::before {
    content: "\f10b7"
}

.mdi-folder-information-outline::before {
    content: "\f10b8"
}

.mdi-folder-key::before {
    content: "\f08ac"
}

.mdi-folder-key-network::before {
    content: "\f08ad"
}

.mdi-folder-key-network-outline::before {
    content: "\f0c80"
}

.mdi-folder-key-outline::before {
    content: "\f10ec"
}

.mdi-folder-lock::before {
    content: "\f0250"
}

.mdi-folder-lock-open::before {
    content: "\f0251"
}

.mdi-folder-marker::before {
    content: "\f126d"
}

.mdi-folder-marker-outline::before {
    content: "\f126e"
}

.mdi-folder-move::before {
    content: "\f0252"
}

.mdi-folder-move-outline::before {
    content: "\f1246"
}

.mdi-folder-multiple::before {
    content: "\f0253"
}

.mdi-folder-multiple-image::before {
    content: "\f0254"
}

.mdi-folder-multiple-outline::before {
    content: "\f0255"
}

.mdi-folder-multiple-plus::before {
    content: "\f147e"
}

.mdi-folder-multiple-plus-outline::before {
    content: "\f147f"
}

.mdi-folder-music::before {
    content: "\f1359"
}

.mdi-folder-music-outline::before {
    content: "\f135a"
}

.mdi-folder-network::before {
    content: "\f0870"
}

.mdi-folder-network-outline::before {
    content: "\f0c81"
}

.mdi-folder-open::before {
    content: "\f0770"
}

.mdi-folder-open-outline::before {
    content: "\f0dcf"
}

.mdi-folder-outline::before {
    content: "\f0256"
}

.mdi-folder-plus::before {
    content: "\f0257"
}

.mdi-folder-plus-outline::before {
    content: "\f0b9d"
}

.mdi-folder-pound::before {
    content: "\f0d09"
}

.mdi-folder-pound-outline::before {
    content: "\f0d0a"
}

.mdi-folder-refresh::before {
    content: "\f0749"
}

.mdi-folder-refresh-outline::before {
    content: "\f0542"
}

.mdi-folder-remove::before {
    content: "\f0258"
}

.mdi-folder-remove-outline::before {
    content: "\f0b9e"
}

.mdi-folder-search::before {
    content: "\f0968"
}

.mdi-folder-search-outline::before {
    content: "\f0969"
}

.mdi-folder-settings::before {
    content: "\f107d"
}

.mdi-folder-settings-outline::before {
    content: "\f107e"
}

.mdi-folder-star::before {
    content: "\f069d"
}

.mdi-folder-star-multiple::before {
    content: "\f13d3"
}

.mdi-folder-star-multiple-outline::before {
    content: "\f13d4"
}

.mdi-folder-star-outline::before {
    content: "\f0b9f"
}

.mdi-folder-swap::before {
    content: "\f0fb6"
}

.mdi-folder-swap-outline::before {
    content: "\f0fb7"
}

.mdi-folder-sync::before {
    content: "\f0d0b"
}

.mdi-folder-sync-outline::before {
    content: "\f0d0c"
}

.mdi-folder-table::before {
    content: "\f12e3"
}

.mdi-folder-table-outline::before {
    content: "\f12e4"
}

.mdi-folder-text::before {
    content: "\f0c82"
}

.mdi-folder-text-outline::before {
    content: "\f0c83"
}

.mdi-folder-upload::before {
    content: "\f0259"
}

.mdi-folder-upload-outline::before {
    content: "\f10ed"
}

.mdi-folder-zip::before {
    content: "\f06eb"
}

.mdi-folder-zip-outline::before {
    content: "\f07b9"
}

.mdi-font-awesome::before {
    content: "\f003a"
}

.mdi-food::before {
    content: "\f025a"
}

.mdi-food-apple::before {
    content: "\f025b"
}

.mdi-food-apple-outline::before {
    content: "\f0c84"
}

.mdi-food-croissant::before {
    content: "\f07c8"
}

.mdi-food-drumstick::before {
    content: "\f141f"
}

.mdi-food-drumstick-off::before {
    content: "\f1468"
}

.mdi-food-drumstick-off-outline::before {
    content: "\f1469"
}

.mdi-food-drumstick-outline::before {
    content: "\f1420"
}

.mdi-food-fork-drink::before {
    content: "\f05f2"
}

.mdi-food-halal::before {
    content: "\f1572"
}

.mdi-food-hot-dog::before {
    content: "\f184b"
}

.mdi-food-kosher::before {
    content: "\f1573"
}

.mdi-food-off::before {
    content: "\f05f3"
}

.mdi-food-off-outline::before {
    content: "\f1915"
}

.mdi-food-outline::before {
    content: "\f1916"
}

.mdi-food-steak::before {
    content: "\f146a"
}

.mdi-food-steak-off::before {
    content: "\f146b"
}

.mdi-food-takeout-box::before {
    content: "\f1836"
}

.mdi-food-takeout-box-outline::before {
    content: "\f1837"
}

.mdi-food-turkey::before {
    content: "\f171c"
}

.mdi-food-variant::before {
    content: "\f025c"
}

.mdi-food-variant-off::before {
    content: "\f13e5"
}

.mdi-foot-print::before {
    content: "\f0f52"
}

.mdi-football::before {
    content: "\f025d"
}

.mdi-football-australian::before {
    content: "\f025e"
}

.mdi-football-helmet::before {
    content: "\f025f"
}

.mdi-forest::before {
    content: "\f1897"
}

.mdi-forklift::before {
    content: "\f07c9"
}

.mdi-form-dropdown::before {
    content: "\f1400"
}

.mdi-form-select::before {
    content: "\f1401"
}

.mdi-form-textarea::before {
    content: "\f1095"
}

.mdi-form-textbox::before {
    content: "\f060e"
}

.mdi-form-textbox-lock::before {
    content: "\f135d"
}

.mdi-form-textbox-password::before {
    content: "\f07f5"
}

.mdi-format-align-bottom::before {
    content: "\f0753"
}

.mdi-format-align-center::before {
    content: "\f0260"
}

.mdi-format-align-justify::before {
    content: "\f0261"
}

.mdi-format-align-left::before {
    content: "\f0262"
}

.mdi-format-align-middle::before {
    content: "\f0754"
}

.mdi-format-align-right::before {
    content: "\f0263"
}

.mdi-format-align-top::before {
    content: "\f0755"
}

.mdi-format-annotation-minus::before {
    content: "\f0abc"
}

.mdi-format-annotation-plus::before {
    content: "\f0646"
}

.mdi-format-bold::before {
    content: "\f0264"
}

.mdi-format-clear::before {
    content: "\f0265"
}

.mdi-format-color-fill::before {
    content: "\f0266"
}

.mdi-format-color-highlight::before {
    content: "\f0e31"
}

.mdi-format-color-marker-cancel::before {
    content: "\f1313"
}

.mdi-format-color-text::before {
    content: "\f069e"
}

.mdi-format-columns::before {
    content: "\f08df"
}

.mdi-format-float-center::before {
    content: "\f0267"
}

.mdi-format-float-left::before {
    content: "\f0268"
}

.mdi-format-float-none::before {
    content: "\f0269"
}

.mdi-format-float-right::before {
    content: "\f026a"
}

.mdi-format-font::before {
    content: "\f06d6"
}

.mdi-format-font-size-decrease::before {
    content: "\f09f3"
}

.mdi-format-font-size-increase::before {
    content: "\f09f4"
}

.mdi-format-header-1::before {
    content: "\f026b"
}

.mdi-format-header-2::before {
    content: "\f026c"
}

.mdi-format-header-3::before {
    content: "\f026d"
}

.mdi-format-header-4::before {
    content: "\f026e"
}

.mdi-format-header-5::before {
    content: "\f026f"
}

.mdi-format-header-6::before {
    content: "\f0270"
}

.mdi-format-header-decrease::before {
    content: "\f0271"
}

.mdi-format-header-equal::before {
    content: "\f0272"
}

.mdi-format-header-increase::before {
    content: "\f0273"
}

.mdi-format-header-pound::before {
    content: "\f0274"
}

.mdi-format-horizontal-align-center::before {
    content: "\f061e"
}

.mdi-format-horizontal-align-left::before {
    content: "\f061f"
}

.mdi-format-horizontal-align-right::before {
    content: "\f0620"
}

.mdi-format-indent-decrease::before {
    content: "\f0275"
}

.mdi-format-indent-increase::before {
    content: "\f0276"
}

.mdi-format-italic::before {
    content: "\f0277"
}

.mdi-format-letter-case::before {
    content: "\f0b34"
}

.mdi-format-letter-case-lower::before {
    content: "\f0b35"
}

.mdi-format-letter-case-upper::before {
    content: "\f0b36"
}

.mdi-format-letter-ends-with::before {
    content: "\f0fb8"
}

.mdi-format-letter-matches::before {
    content: "\f0fb9"
}

.mdi-format-letter-spacing::before {
    content: "\f1956"
}

.mdi-format-letter-starts-with::before {
    content: "\f0fba"
}

.mdi-format-line-spacing::before {
    content: "\f0278"
}

.mdi-format-line-style::before {
    content: "\f05c8"
}

.mdi-format-line-weight::before {
    content: "\f05c9"
}

.mdi-format-list-bulleted::before {
    content: "\f0279"
}

.mdi-format-list-bulleted-square::before {
    content: "\f0dd0"
}

.mdi-format-list-bulleted-triangle::before {
    content: "\f0eb2"
}

.mdi-format-list-bulleted-type::before {
    content: "\f027a"
}

.mdi-format-list-checkbox::before {
    content: "\f096a"
}

.mdi-format-list-checks::before {
    content: "\f0756"
}

.mdi-format-list-group::before {
    content: "\f1860"
}

.mdi-format-list-numbered::before {
    content: "\f027b"
}

.mdi-format-list-numbered-rtl::before {
    content: "\f0d0d"
}

.mdi-format-list-text::before {
    content: "\f126f"
}

.mdi-format-overline::before {
    content: "\f0eb3"
}

.mdi-format-page-break::before {
    content: "\f06d7"
}

.mdi-format-page-split::before {
    content: "\f1917"
}

.mdi-format-paint::before {
    content: "\f027c"
}

.mdi-format-paragraph::before {
    content: "\f027d"
}

.mdi-format-pilcrow::before {
    content: "\f06d8"
}

.mdi-format-quote-close::before {
    content: "\f027e"
}

.mdi-format-quote-close-outline::before {
    content: "\f11a8"
}

.mdi-format-quote-open::before {
    content: "\f0757"
}

.mdi-format-quote-open-outline::before {
    content: "\f11a7"
}

.mdi-format-rotate-90::before {
    content: "\f06aa"
}

.mdi-format-section::before {
    content: "\f069f"
}

.mdi-format-size::before {
    content: "\f027f"
}

.mdi-format-strikethrough::before {
    content: "\f0280"
}

.mdi-format-strikethrough-variant::before {
    content: "\f0281"
}

.mdi-format-subscript::before {
    content: "\f0282"
}

.mdi-format-superscript::before {
    content: "\f0283"
}

.mdi-format-text::before {
    content: "\f0284"
}

.mdi-format-text-rotation-angle-down::before {
    content: "\f0fbb"
}

.mdi-format-text-rotation-angle-up::before {
    content: "\f0fbc"
}

.mdi-format-text-rotation-down::before {
    content: "\f0d73"
}

.mdi-format-text-rotation-down-vertical::before {
    content: "\f0fbd"
}

.mdi-format-text-rotation-none::before {
    content: "\f0d74"
}

.mdi-format-text-rotation-up::before {
    content: "\f0fbe"
}

.mdi-format-text-rotation-vertical::before {
    content: "\f0fbf"
}

.mdi-format-text-variant::before {
    content: "\f0e32"
}

.mdi-format-text-variant-outline::before {
    content: "\f150f"
}

.mdi-format-text-wrapping-clip::before {
    content: "\f0d0e"
}

.mdi-format-text-wrapping-overflow::before {
    content: "\f0d0f"
}

.mdi-format-text-wrapping-wrap::before {
    content: "\f0d10"
}

.mdi-format-textbox::before {
    content: "\f0d11"
}

.mdi-format-textdirection-l-to-r::before {
    content: "\f0285"
}

.mdi-format-textdirection-r-to-l::before {
    content: "\f0286"
}

.mdi-format-title::before {
    content: "\f05f4"
}

.mdi-format-underline::before {
    content: "\f0287"
}

.mdi-format-underline-wavy::before {
    content: "\f18e9"
}

.mdi-format-vertical-align-bottom::before {
    content: "\f0621"
}

.mdi-format-vertical-align-center::before {
    content: "\f0622"
}

.mdi-format-vertical-align-top::before {
    content: "\f0623"
}

.mdi-format-wrap-inline::before {
    content: "\f0288"
}

.mdi-format-wrap-square::before {
    content: "\f0289"
}

.mdi-format-wrap-tight::before {
    content: "\f028a"
}

.mdi-format-wrap-top-bottom::before {
    content: "\f028b"
}

.mdi-forum::before {
    content: "\f028c"
}

.mdi-forum-outline::before {
    content: "\f0822"
}

.mdi-forward::before {
    content: "\f028d"
}

.mdi-forwardburger::before {
    content: "\f0d75"
}

.mdi-fountain::before {
    content: "\f096b"
}

.mdi-fountain-pen::before {
    content: "\f0d12"
}

.mdi-fountain-pen-tip::before {
    content: "\f0d13"
}

.mdi-fraction-one-half::before {
    content: "\f1992"
}

.mdi-freebsd::before {
    content: "\f08e0"
}

.mdi-french-fries::before {
    content: "\f1957"
}

.mdi-frequently-asked-questions::before {
    content: "\f0eb4"
}

.mdi-fridge::before {
    content: "\f0290"
}

.mdi-fridge-alert::before {
    content: "\f11b1"
}

.mdi-fridge-alert-outline::before {
    content: "\f11b2"
}

.mdi-fridge-bottom::before {
    content: "\f0292"
}

.mdi-fridge-industrial::before {
    content: "\f15ee"
}

.mdi-fridge-industrial-alert::before {
    content: "\f15ef"
}

.mdi-fridge-industrial-alert-outline::before {
    content: "\f15f0"
}

.mdi-fridge-industrial-off::before {
    content: "\f15f1"
}

.mdi-fridge-industrial-off-outline::before {
    content: "\f15f2"
}

.mdi-fridge-industrial-outline::before {
    content: "\f15f3"
}

.mdi-fridge-off::before {
    content: "\f11af"
}

.mdi-fridge-off-outline::before {
    content: "\f11b0"
}

.mdi-fridge-outline::before {
    content: "\f028f"
}

.mdi-fridge-top::before {
    content: "\f0291"
}

.mdi-fridge-variant::before {
    content: "\f15f4"
}

.mdi-fridge-variant-alert::before {
    content: "\f15f5"
}

.mdi-fridge-variant-alert-outline::before {
    content: "\f15f6"
}

.mdi-fridge-variant-off::before {
    content: "\f15f7"
}

.mdi-fridge-variant-off-outline::before {
    content: "\f15f8"
}

.mdi-fridge-variant-outline::before {
    content: "\f15f9"
}

.mdi-fruit-cherries::before {
    content: "\f1042"
}

.mdi-fruit-cherries-off::before {
    content: "\f13f8"
}

.mdi-fruit-citrus::before {
    content: "\f1043"
}

.mdi-fruit-citrus-off::before {
    content: "\f13f9"
}

.mdi-fruit-grapes::before {
    content: "\f1044"
}

.mdi-fruit-grapes-outline::before {
    content: "\f1045"
}

.mdi-fruit-pineapple::before {
    content: "\f1046"
}

.mdi-fruit-watermelon::before {
    content: "\f1047"
}

.mdi-fuel::before {
    content: "\f07ca"
}

.mdi-fuel-cell::before {
    content: "\f18b5"
}

.mdi-fullscreen::before {
    content: "\f0293"
}

.mdi-fullscreen-exit::before {
    content: "\f0294"
}

.mdi-function::before {
    content: "\f0295"
}

.mdi-function-variant::before {
    content: "\f0871"
}

.mdi-furigana-horizontal::before {
    content: "\f1081"
}

.mdi-furigana-vertical::before {
    content: "\f1082"
}

.mdi-fuse::before {
    content: "\f0c85"
}

.mdi-fuse-alert::before {
    content: "\f142d"
}

.mdi-fuse-blade::before {
    content: "\f0c86"
}

.mdi-fuse-off::before {
    content: "\f142c"
}

.mdi-gamepad::before {
    content: "\f0296"
}

.mdi-gamepad-circle::before {
    content: "\f0e33"
}

.mdi-gamepad-circle-down::before {
    content: "\f0e34"
}

.mdi-gamepad-circle-left::before {
    content: "\f0e35"
}

.mdi-gamepad-circle-outline::before {
    content: "\f0e36"
}

.mdi-gamepad-circle-right::before {
    content: "\f0e37"
}

.mdi-gamepad-circle-up::before {
    content: "\f0e38"
}

.mdi-gamepad-down::before {
    content: "\f0e39"
}

.mdi-gamepad-left::before {
    content: "\f0e3a"
}

.mdi-gamepad-outline::before {
    content: "\f1919"
}

.mdi-gamepad-right::before {
    content: "\f0e3b"
}

.mdi-gamepad-round::before {
    content: "\f0e3c"
}

.mdi-gamepad-round-down::before {
    content: "\f0e3d"
}

.mdi-gamepad-round-left::before {
    content: "\f0e3e"
}

.mdi-gamepad-round-outline::before {
    content: "\f0e3f"
}

.mdi-gamepad-round-right::before {
    content: "\f0e40"
}

.mdi-gamepad-round-up::before {
    content: "\f0e41"
}

.mdi-gamepad-square::before {
    content: "\f0eb5"
}

.mdi-gamepad-square-outline::before {
    content: "\f0eb6"
}

.mdi-gamepad-up::before {
    content: "\f0e42"
}

.mdi-gamepad-variant::before {
    content: "\f0297"
}

.mdi-gamepad-variant-outline::before {
    content: "\f0eb7"
}

.mdi-gamma::before {
    content: "\f10ee"
}

.mdi-gantry-crane::before {
    content: "\f0dd1"
}

.mdi-garage::before {
    content: "\f06d9"
}

.mdi-garage-alert::before {
    content: "\f0872"
}

.mdi-garage-alert-variant::before {
    content: "\f12d5"
}

.mdi-garage-lock::before {
    content: "\f17fb"
}

.mdi-garage-open::before {
    content: "\f06da"
}

.mdi-garage-open-variant::before {
    content: "\f12d4"
}

.mdi-garage-variant::before {
    content: "\f12d3"
}

.mdi-garage-variant-lock::before {
    content: "\f17fc"
}

.mdi-gas-cylinder::before {
    content: "\f0647"
}

.mdi-gas-station::before {
    content: "\f0298"
}

.mdi-gas-station-off::before {
    content: "\f1409"
}

.mdi-gas-station-off-outline::before {
    content: "\f140a"
}

.mdi-gas-station-outline::before {
    content: "\f0eb8"
}

.mdi-gate::before {
    content: "\f0299"
}

.mdi-gate-alert::before {
    content: "\f17f8"
}

.mdi-gate-and::before {
    content: "\f08e1"
}

.mdi-gate-arrow-left::before {
    content: "\f17f7"
}

.mdi-gate-arrow-right::before {
    content: "\f1169"
}

.mdi-gate-nand::before {
    content: "\f08e2"
}

.mdi-gate-nor::before {
    content: "\f08e3"
}

.mdi-gate-not::before {
    content: "\f08e4"
}

.mdi-gate-open::before {
    content: "\f116a"
}

.mdi-gate-or::before {
    content: "\f08e5"
}

.mdi-gate-xnor::before {
    content: "\f08e6"
}

.mdi-gate-xor::before {
    content: "\f08e7"
}

.mdi-gatsby::before {
    content: "\f0e43"
}

.mdi-gauge::before {
    content: "\f029a"
}

.mdi-gauge-empty::before {
    content: "\f0873"
}

.mdi-gauge-full::before {
    content: "\f0874"
}

.mdi-gauge-low::before {
    content: "\f0875"
}

.mdi-gavel::before {
    content: "\f029b"
}

.mdi-gender-female::before {
    content: "\f029c"
}

.mdi-gender-male::before {
    content: "\f029d"
}

.mdi-gender-male-female::before {
    content: "\f029e"
}

.mdi-gender-male-female-variant::before {
    content: "\f113f"
}

.mdi-gender-non-binary::before {
    content: "\f1140"
}

.mdi-gender-transgender::before {
    content: "\f029f"
}

.mdi-gentoo::before {
    content: "\f08e8"
}

.mdi-gesture::before {
    content: "\f07cb"
}

.mdi-gesture-double-tap::before {
    content: "\f073c"
}

.mdi-gesture-pinch::before {
    content: "\f0abd"
}

.mdi-gesture-spread::before {
    content: "\f0abe"
}

.mdi-gesture-swipe::before {
    content: "\f0d76"
}

.mdi-gesture-swipe-down::before {
    content: "\f073d"
}

.mdi-gesture-swipe-horizontal::before {
    content: "\f0abf"
}

.mdi-gesture-swipe-left::before {
    content: "\f073e"
}

.mdi-gesture-swipe-right::before {
    content: "\f073f"
}

.mdi-gesture-swipe-up::before {
    content: "\f0740"
}

.mdi-gesture-swipe-vertical::before {
    content: "\f0ac0"
}

.mdi-gesture-tap::before {
    content: "\f0741"
}

.mdi-gesture-tap-box::before {
    content: "\f12a9"
}

.mdi-gesture-tap-button::before {
    content: "\f12a8"
}

.mdi-gesture-tap-hold::before {
    content: "\f0d77"
}

.mdi-gesture-two-double-tap::before {
    content: "\f0742"
}

.mdi-gesture-two-tap::before {
    content: "\f0743"
}

.mdi-ghost::before {
    content: "\f02a0"
}

.mdi-ghost-off::before {
    content: "\f09f5"
}

.mdi-ghost-off-outline::before {
    content: "\f165c"
}

.mdi-ghost-outline::before {
    content: "\f165d"
}

.mdi-gift::before {
    content: "\f0e44"
}

.mdi-gift-off::before {
    content: "\f16ef"
}

.mdi-gift-off-outline::before {
    content: "\f16f0"
}

.mdi-gift-open::before {
    content: "\f16f1"
}

.mdi-gift-open-outline::before {
    content: "\f16f2"
}

.mdi-gift-outline::before {
    content: "\f02a1"
}

.mdi-git::before {
    content: "\f02a2"
}

.mdi-github::before {
    content: "\f02a4"
}

.mdi-gitlab::before {
    content: "\f0ba0"
}

.mdi-glass-cocktail::before {
    content: "\f0356"
}

.mdi-glass-cocktail-off::before {
    content: "\f15e6"
}

.mdi-glass-flute::before {
    content: "\f02a5"
}

.mdi-glass-fragile::before {
    content: "\f1873"
}

.mdi-glass-mug::before {
    content: "\f02a6"
}

.mdi-glass-mug-off::before {
    content: "\f15e7"
}

.mdi-glass-mug-variant::before {
    content: "\f1116"
}

.mdi-glass-mug-variant-off::before {
    content: "\f15e8"
}

.mdi-glass-pint-outline::before {
    content: "\f130d"
}

.mdi-glass-stange::before {
    content: "\f02a7"
}

.mdi-glass-tulip::before {
    content: "\f02a8"
}

.mdi-glass-wine::before {
    content: "\f0876"
}

.mdi-glasses::before {
    content: "\f02aa"
}

.mdi-globe-light::before {
    content: "\f12d7"
}

.mdi-globe-model::before {
    content: "\f08e9"
}

.mdi-gmail::before {
    content: "\f02ab"
}

.mdi-gnome::before {
    content: "\f02ac"
}

.mdi-go-kart::before {
    content: "\f0d79"
}

.mdi-go-kart-track::before {
    content: "\f0d7a"
}

.mdi-gog::before {
    content: "\f0ba1"
}

.mdi-gold::before {
    content: "\f124f"
}

.mdi-golf::before {
    content: "\f0823"
}

.mdi-golf-cart::before {
    content: "\f11a4"
}

.mdi-golf-tee::before {
    content: "\f1083"
}

.mdi-gondola::before {
    content: "\f0686"
}

.mdi-goodreads::before {
    content: "\f0d7b"
}

.mdi-google::before {
    content: "\f02ad"
}

.mdi-google-ads::before {
    content: "\f0c87"
}

.mdi-google-analytics::before {
    content: "\f07cc"
}

.mdi-google-assistant::before {
    content: "\f07cd"
}

.mdi-google-cardboard::before {
    content: "\f02ae"
}

.mdi-google-chrome::before {
    content: "\f02af"
}

.mdi-google-circles::before {
    content: "\f02b0"
}

.mdi-google-circles-communities::before {
    content: "\f02b1"
}

.mdi-google-circles-extended::before {
    content: "\f02b2"
}

.mdi-google-circles-group::before {
    content: "\f02b3"
}

.mdi-google-classroom::before {
    content: "\f02c0"
}

.mdi-google-cloud::before {
    content: "\f11f6"
}

.mdi-google-controller::before {
    content: "\f02b4"
}

.mdi-google-controller-off::before {
    content: "\f02b5"
}

.mdi-google-downasaur::before {
    content: "\f1362"
}

.mdi-google-drive::before {
    content: "\f02b6"
}

.mdi-google-earth::before {
    content: "\f02b7"
}

.mdi-google-fit::before {
    content: "\f096c"
}

.mdi-google-glass::before {
    content: "\f02b8"
}

.mdi-google-hangouts::before {
    content: "\f02c9"
}

.mdi-google-home::before {
    content: "\f0824"
}

.mdi-google-keep::before {
    content: "\f06dc"
}

.mdi-google-lens::before {
    content: "\f09f6"
}

.mdi-google-maps::before {
    content: "\f05f5"
}

.mdi-google-my-business::before {
    content: "\f1048"
}

.mdi-google-nearby::before {
    content: "\f02b9"
}

.mdi-google-play::before {
    content: "\f02bc"
}

.mdi-google-plus::before {
    content: "\f02bd"
}

.mdi-google-podcast::before {
    content: "\f0eb9"
}

.mdi-google-spreadsheet::before {
    content: "\f09f7"
}

.mdi-google-street-view::before {
    content: "\f0c88"
}

.mdi-google-translate::before {
    content: "\f02bf"
}

.mdi-gradient-horizontal::before {
    content: "\f174a"
}

.mdi-gradient-vertical::before {
    content: "\f06a0"
}

.mdi-grain::before {
    content: "\f0d7c"
}

.mdi-graph::before {
    content: "\f1049"
}

.mdi-graph-outline::before {
    content: "\f104a"
}

.mdi-graphql::before {
    content: "\f0877"
}

.mdi-grass::before {
    content: "\f1510"
}

.mdi-grave-stone::before {
    content: "\f0ba2"
}

.mdi-grease-pencil::before {
    content: "\f0648"
}

.mdi-greater-than::before {
    content: "\f096d"
}

.mdi-greater-than-or-equal::before {
    content: "\f096e"
}

.mdi-greenhouse::before {
    content: "\f002d"
}

.mdi-grid::before {
    content: "\f02c1"
}

.mdi-grid-large::before {
    content: "\f0758"
}

.mdi-grid-off::before {
    content: "\f02c2"
}

.mdi-grill::before {
    content: "\f0e45"
}

.mdi-grill-outline::before {
    content: "\f118a"
}

.mdi-group::before {
    content: "\f02c3"
}

.mdi-guitar-acoustic::before {
    content: "\f0771"
}

.mdi-guitar-electric::before {
    content: "\f02c4"
}

.mdi-guitar-pick::before {
    content: "\f02c5"
}

.mdi-guitar-pick-outline::before {
    content: "\f02c6"
}

.mdi-guy-fawkes-mask::before {
    content: "\f0825"
}

.mdi-hail::before {
    content: "\f0ac1"
}

.mdi-hair-dryer::before {
    content: "\f10ef"
}

.mdi-hair-dryer-outline::before {
    content: "\f10f0"
}

.mdi-halloween::before {
    content: "\f0ba3"
}

.mdi-hamburger::before {
    content: "\f0685"
}

.mdi-hamburger-check::before {
    content: "\f1776"
}

.mdi-hamburger-minus::before {
    content: "\f1777"
}

.mdi-hamburger-off::before {
    content: "\f1778"
}

.mdi-hamburger-plus::before {
    content: "\f1779"
}

.mdi-hamburger-remove::before {
    content: "\f177a"
}

.mdi-hammer::before {
    content: "\f08ea"
}

.mdi-hammer-screwdriver::before {
    content: "\f1322"
}

.mdi-hammer-sickle::before {
    content: "\f1887"
}

.mdi-hammer-wrench::before {
    content: "\f1323"
}

.mdi-hand-back-left::before {
    content: "\f0e46"
}

.mdi-hand-back-left-off::before {
    content: "\f1830"
}

.mdi-hand-back-left-off-outline::before {
    content: "\f1832"
}

.mdi-hand-back-left-outline::before {
    content: "\f182c"
}

.mdi-hand-back-right::before {
    content: "\f0e47"
}

.mdi-hand-back-right-off::before {
    content: "\f1831"
}

.mdi-hand-back-right-off-outline::before {
    content: "\f1833"
}

.mdi-hand-back-right-outline::before {
    content: "\f182d"
}

.mdi-hand-clap::before {
    content: "\f194b"
}

.mdi-hand-coin::before {
    content: "\f188f"
}

.mdi-hand-coin-outline::before {
    content: "\f1890"
}

.mdi-hand-extended::before {
    content: "\f18b6"
}

.mdi-hand-extended-outline::before {
    content: "\f18b7"
}

.mdi-hand-front-left::before {
    content: "\f182b"
}

.mdi-hand-front-left-outline::before {
    content: "\f182e"
}

.mdi-hand-front-right::before {
    content: "\f0a4f"
}

.mdi-hand-front-right-outline::before {
    content: "\f182f"
}

.mdi-hand-heart::before {
    content: "\f10f1"
}

.mdi-hand-heart-outline::before {
    content: "\f157e"
}

.mdi-hand-okay::before {
    content: "\f0a50"
}

.mdi-hand-peace::before {
    content: "\f0a51"
}

.mdi-hand-peace-variant::before {
    content: "\f0a52"
}

.mdi-hand-pointing-down::before {
    content: "\f0a53"
}

.mdi-hand-pointing-left::before {
    content: "\f0a54"
}

.mdi-hand-pointing-right::before {
    content: "\f02c7"
}

.mdi-hand-pointing-up::before {
    content: "\f0a55"
}

.mdi-hand-saw::before {
    content: "\f0e48"
}

.mdi-hand-wash::before {
    content: "\f157f"
}

.mdi-hand-wash-outline::before {
    content: "\f1580"
}

.mdi-hand-water::before {
    content: "\f139f"
}

.mdi-hand-wave::before {
    content: "\f1821"
}

.mdi-hand-wave-outline::before {
    content: "\f1822"
}

.mdi-handball::before {
    content: "\f0f53"
}

.mdi-handcuffs::before {
    content: "\f113e"
}

.mdi-hands-pray::before {
    content: "\f0579"
}

.mdi-handshake::before {
    content: "\f1218"
}

.mdi-handshake-outline::before {
    content: "\f15a1"
}

.mdi-hanger::before {
    content: "\f02c8"
}

.mdi-hard-hat::before {
    content: "\f096f"
}

.mdi-harddisk::before {
    content: "\f02ca"
}

.mdi-harddisk-plus::before {
    content: "\f104b"
}

.mdi-harddisk-remove::before {
    content: "\f104c"
}

.mdi-hat-fedora::before {
    content: "\f0ba4"
}

.mdi-hazard-lights::before {
    content: "\f0c89"
}

.mdi-hdr::before {
    content: "\f0d7d"
}

.mdi-hdr-off::before {
    content: "\f0d7e"
}

.mdi-head::before {
    content: "\f135e"
}

.mdi-head-alert::before {
    content: "\f1338"
}

.mdi-head-alert-outline::before {
    content: "\f1339"
}

.mdi-head-check::before {
    content: "\f133a"
}

.mdi-head-check-outline::before {
    content: "\f133b"
}

.mdi-head-cog::before {
    content: "\f133c"
}

.mdi-head-cog-outline::before {
    content: "\f133d"
}

.mdi-head-dots-horizontal::before {
    content: "\f133e"
}

.mdi-head-dots-horizontal-outline::before {
    content: "\f133f"
}

.mdi-head-flash::before {
    content: "\f1340"
}

.mdi-head-flash-outline::before {
    content: "\f1341"
}

.mdi-head-heart::before {
    content: "\f1342"
}

.mdi-head-heart-outline::before {
    content: "\f1343"
}

.mdi-head-lightbulb::before {
    content: "\f1344"
}

.mdi-head-lightbulb-outline::before {
    content: "\f1345"
}

.mdi-head-minus::before {
    content: "\f1346"
}

.mdi-head-minus-outline::before {
    content: "\f1347"
}

.mdi-head-outline::before {
    content: "\f135f"
}

.mdi-head-plus::before {
    content: "\f1348"
}

.mdi-head-plus-outline::before {
    content: "\f1349"
}

.mdi-head-question::before {
    content: "\f134a"
}

.mdi-head-question-outline::before {
    content: "\f134b"
}

.mdi-head-remove::before {
    content: "\f134c"
}

.mdi-head-remove-outline::before {
    content: "\f134d"
}

.mdi-head-snowflake::before {
    content: "\f134e"
}

.mdi-head-snowflake-outline::before {
    content: "\f134f"
}

.mdi-head-sync::before {
    content: "\f1350"
}

.mdi-head-sync-outline::before {
    content: "\f1351"
}

.mdi-headphones::before {
    content: "\f02cb"
}

.mdi-headphones-bluetooth::before {
    content: "\f0970"
}

.mdi-headphones-box::before {
    content: "\f02cc"
}

.mdi-headphones-off::before {
    content: "\f07ce"
}

.mdi-headphones-settings::before {
    content: "\f02cd"
}

.mdi-headset::before {
    content: "\f02ce"
}

.mdi-headset-dock::before {
    content: "\f02cf"
}

.mdi-headset-off::before {
    content: "\f02d0"
}

.mdi-heart::before {
    content: "\f02d1"
}

.mdi-heart-box::before {
    content: "\f02d2"
}

.mdi-heart-box-outline::before {
    content: "\f02d3"
}

.mdi-heart-broken::before {
    content: "\f02d4"
}

.mdi-heart-broken-outline::before {
    content: "\f0d14"
}

.mdi-heart-circle::before {
    content: "\f0971"
}

.mdi-heart-circle-outline::before {
    content: "\f0972"
}

.mdi-heart-cog::before {
    content: "\f1663"
}

.mdi-heart-cog-outline::before {
    content: "\f1664"
}

.mdi-heart-flash::before {
    content: "\f0ef9"
}

.mdi-heart-half::before {
    content: "\f06df"
}

.mdi-heart-half-full::before {
    content: "\f06de"
}

.mdi-heart-half-outline::before {
    content: "\f06e0"
}

.mdi-heart-minus::before {
    content: "\f142f"
}

.mdi-heart-minus-outline::before {
    content: "\f1432"
}

.mdi-heart-multiple::before {
    content: "\f0a56"
}

.mdi-heart-multiple-outline::before {
    content: "\f0a57"
}

.mdi-heart-off::before {
    content: "\f0759"
}

.mdi-heart-off-outline::before {
    content: "\f1434"
}

.mdi-heart-outline::before {
    content: "\f02d5"
}

.mdi-heart-plus::before {
    content: "\f142e"
}

.mdi-heart-plus-outline::before {
    content: "\f1431"
}

.mdi-heart-pulse::before {
    content: "\f05f6"
}

.mdi-heart-remove::before {
    content: "\f1430"
}

.mdi-heart-remove-outline::before {
    content: "\f1433"
}

.mdi-heart-settings::before {
    content: "\f1665"
}

.mdi-heart-settings-outline::before {
    content: "\f1666"
}

.mdi-helicopter::before {
    content: "\f0ac2"
}

.mdi-help::before {
    content: "\f02d6"
}

.mdi-help-box::before {
    content: "\f078b"
}

.mdi-help-circle::before {
    content: "\f02d7"
}

.mdi-help-circle-outline::before {
    content: "\f0625"
}

.mdi-help-network::before {
    content: "\f06f5"
}

.mdi-help-network-outline::before {
    content: "\f0c8a"
}

.mdi-help-rhombus::before {
    content: "\f0ba5"
}

.mdi-help-rhombus-outline::before {
    content: "\f0ba6"
}

.mdi-hexadecimal::before {
    content: "\f12a7"
}

.mdi-hexagon::before {
    content: "\f02d8"
}

.mdi-hexagon-multiple::before {
    content: "\f06e1"
}

.mdi-hexagon-multiple-outline::before {
    content: "\f10f2"
}

.mdi-hexagon-outline::before {
    content: "\f02d9"
}

.mdi-hexagon-slice-1::before {
    content: "\f0ac3"
}

.mdi-hexagon-slice-2::before {
    content: "\f0ac4"
}

.mdi-hexagon-slice-3::before {
    content: "\f0ac5"
}

.mdi-hexagon-slice-4::before {
    content: "\f0ac6"
}

.mdi-hexagon-slice-5::before {
    content: "\f0ac7"
}

.mdi-hexagon-slice-6::before {
    content: "\f0ac8"
}

.mdi-hexagram::before {
    content: "\f0ac9"
}

.mdi-hexagram-outline::before {
    content: "\f0aca"
}

.mdi-high-definition::before {
    content: "\f07cf"
}

.mdi-high-definition-box::before {
    content: "\f0878"
}

.mdi-highway::before {
    content: "\f05f7"
}

.mdi-hiking::before {
    content: "\f0d7f"
}

.mdi-history::before {
    content: "\f02da"
}

.mdi-hockey-puck::before {
    content: "\f0879"
}

.mdi-hockey-sticks::before {
    content: "\f087a"
}

.mdi-hololens::before {
    content: "\f02db"
}

.mdi-home::before {
    content: "\f02dc"
}

.mdi-home-account::before {
    content: "\f0826"
}

.mdi-home-alert::before {
    content: "\f087b"
}

.mdi-home-alert-outline::before {
    content: "\f15d0"
}

.mdi-home-analytics::before {
    content: "\f0eba"
}

.mdi-home-assistant::before {
    content: "\f07d0"
}

.mdi-home-automation::before {
    content: "\f07d1"
}

.mdi-home-battery::before {
    content: "\f1901"
}

.mdi-home-battery-outline::before {
    content: "\f1902"
}

.mdi-home-circle::before {
    content: "\f07d2"
}

.mdi-home-circle-outline::before {
    content: "\f104d"
}

.mdi-home-city::before {
    content: "\f0d15"
}

.mdi-home-city-outline::before {
    content: "\f0d16"
}

.mdi-home-edit::before {
    content: "\f1159"
}

.mdi-home-edit-outline::before {
    content: "\f115a"
}

.mdi-home-export-outline::before {
    content: "\f0f9b"
}

.mdi-home-flood::before {
    content: "\f0efa"
}

.mdi-home-floor-0::before {
    content: "\f0dd2"
}

.mdi-home-floor-1::before {
    content: "\f0d80"
}

.mdi-home-floor-2::before {
    content: "\f0d81"
}

.mdi-home-floor-3::before {
    content: "\f0d82"
}

.mdi-home-floor-a::before {
    content: "\f0d83"
}

.mdi-home-floor-b::before {
    content: "\f0d84"
}

.mdi-home-floor-g::before {
    content: "\f0d85"
}

.mdi-home-floor-l::before {
    content: "\f0d86"
}

.mdi-home-floor-negative-1::before {
    content: "\f0dd3"
}

.mdi-home-group::before {
    content: "\f0dd4"
}

.mdi-home-group-minus::before {
    content: "\f19c1"
}

.mdi-home-group-plus::before {
    content: "\f19c0"
}

.mdi-home-group-remove::before {
    content: "\f19c2"
}

.mdi-home-heart::before {
    content: "\f0827"
}

.mdi-home-import-outline::before {
    content: "\f0f9c"
}

.mdi-home-lightbulb::before {
    content: "\f1251"
}

.mdi-home-lightbulb-outline::before {
    content: "\f1252"
}

.mdi-home-lightning-bolt::before {
    content: "\f1903"
}

.mdi-home-lightning-bolt-outline::before {
    content: "\f1904"
}

.mdi-home-lock::before {
    content: "\f08eb"
}

.mdi-home-lock-open::before {
    content: "\f08ec"
}

.mdi-home-map-marker::before {
    content: "\f05f8"
}

.mdi-home-minus::before {
    content: "\f0974"
}

.mdi-home-minus-outline::before {
    content: "\f13d5"
}

.mdi-home-modern::before {
    content: "\f02dd"
}

.mdi-home-outline::before {
    content: "\f06a1"
}

.mdi-home-plus::before {
    content: "\f0975"
}

.mdi-home-plus-outline::before {
    content: "\f13d6"
}

.mdi-home-remove::before {
    content: "\f1247"
}

.mdi-home-remove-outline::before {
    content: "\f13d7"
}

.mdi-home-roof::before {
    content: "\f112b"
}

.mdi-home-search::before {
    content: "\f13b0"
}

.mdi-home-search-outline::before {
    content: "\f13b1"
}

.mdi-home-switch::before {
    content: "\f1794"
}

.mdi-home-switch-outline::before {
    content: "\f1795"
}

.mdi-home-thermometer::before {
    content: "\f0f54"
}

.mdi-home-thermometer-outline::before {
    content: "\f0f55"
}

.mdi-home-variant::before {
    content: "\f02de"
}

.mdi-home-variant-outline::before {
    content: "\f0ba7"
}

.mdi-hook::before {
    content: "\f06e2"
}

.mdi-hook-off::before {
    content: "\f06e3"
}

.mdi-hoop-house::before {
    content: "\f0e56"
}

.mdi-hops::before {
    content: "\f02df"
}

.mdi-horizontal-rotate-clockwise::before {
    content: "\f10f3"
}

.mdi-horizontal-rotate-counterclockwise::before {
    content: "\f10f4"
}

.mdi-horse::before {
    content: "\f15bf"
}

.mdi-horse-human::before {
    content: "\f15c0"
}

.mdi-horse-variant::before {
    content: "\f15c1"
}

.mdi-horse-variant-fast::before {
    content: "\f186e"
}

.mdi-horseshoe::before {
    content: "\f0a58"
}

.mdi-hospital::before {
    content: "\f0ff6"
}

.mdi-hospital-box::before {
    content: "\f02e0"
}

.mdi-hospital-box-outline::before {
    content: "\f0ff7"
}

.mdi-hospital-building::before {
    content: "\f02e1"
}

.mdi-hospital-marker::before {
    content: "\f02e2"
}

.mdi-hot-tub::before {
    content: "\f0828"
}

.mdi-hours-24::before {
    content: "\f1478"
}

.mdi-hubspot::before {
    content: "\f0d17"
}

.mdi-hulu::before {
    content: "\f0829"
}

.mdi-human::before {
    content: "\f02e6"
}

.mdi-human-baby-changing-table::before {
    content: "\f138b"
}

.mdi-human-cane::before {
    content: "\f1581"
}

.mdi-human-capacity-decrease::before {
    content: "\f159b"
}

.mdi-human-capacity-increase::before {
    content: "\f159c"
}

.mdi-human-child::before {
    content: "\f02e7"
}

.mdi-human-dolly::before {
    content: "\f1980"
}

.mdi-human-edit::before {
    content: "\f14e8"
}

.mdi-human-female::before {
    content: "\f0649"
}

.mdi-human-female-boy::before {
    content: "\f0a59"
}

.mdi-human-female-dance::before {
    content: "\f15c9"
}

.mdi-human-female-female::before {
    content: "\f0a5a"
}

.mdi-human-female-girl::before {
    content: "\f0a5b"
}

.mdi-human-greeting::before {
    content: "\f17c4"
}

.mdi-human-greeting-proximity::before {
    content: "\f159d"
}

.mdi-human-greeting-variant::before {
    content: "\f064a"
}

.mdi-human-handsdown::before {
    content: "\f064b"
}

.mdi-human-handsup::before {
    content: "\f064c"
}

.mdi-human-male::before {
    content: "\f064d"
}

.mdi-human-male-board::before {
    content: "\f0890"
}

.mdi-human-male-board-poll::before {
    content: "\f0846"
}

.mdi-human-male-boy::before {
    content: "\f0a5c"
}

.mdi-human-male-child::before {
    content: "\f138c"
}

.mdi-human-male-female::before {
    content: "\f02e8"
}

.mdi-human-male-female-child::before {
    content: "\f1823"
}

.mdi-human-male-girl::before {
    content: "\f0a5d"
}

.mdi-human-male-height::before {
    content: "\f0efb"
}

.mdi-human-male-height-variant::before {
    content: "\f0efc"
}

.mdi-human-male-male::before {
    content: "\f0a5e"
}

.mdi-human-non-binary::before {
    content: "\f1848"
}

.mdi-human-pregnant::before {
    content: "\f05cf"
}

.mdi-human-queue::before {
    content: "\f1571"
}

.mdi-human-scooter::before {
    content: "\f11e9"
}

.mdi-human-wheelchair::before {
    content: "\f138d"
}

.mdi-human-white-cane::before {
    content: "\f1981"
}

.mdi-humble-bundle::before {
    content: "\f0744"
}

.mdi-hvac::before {
    content: "\f1352"
}

.mdi-hvac-off::before {
    content: "\f159e"
}

.mdi-hydraulic-oil-level::before {
    content: "\f1324"
}

.mdi-hydraulic-oil-temperature::before {
    content: "\f1325"
}

.mdi-hydro-power::before {
    content: "\f12e5"
}

.mdi-hydrogen-station::before {
    content: "\f1894"
}

.mdi-ice-cream::before {
    content: "\f082a"
}

.mdi-ice-cream-off::before {
    content: "\f0e52"
}

.mdi-ice-pop::before {
    content: "\f0efd"
}

.mdi-id-card::before {
    content: "\f0fc0"
}

.mdi-identifier::before {
    content: "\f0efe"
}

.mdi-ideogram-cjk::before {
    content: "\f1331"
}

.mdi-ideogram-cjk-variant::before {
    content: "\f1332"
}

.mdi-image::before {
    content: "\f02e9"
}

.mdi-image-album::before {
    content: "\f02ea"
}

.mdi-image-area::before {
    content: "\f02eb"
}

.mdi-image-area-close::before {
    content: "\f02ec"
}

.mdi-image-auto-adjust::before {
    content: "\f0fc1"
}

.mdi-image-broken::before {
    content: "\f02ed"
}

.mdi-image-broken-variant::before {
    content: "\f02ee"
}

.mdi-image-edit::before {
    content: "\f11e3"
}

.mdi-image-edit-outline::before {
    content: "\f11e4"
}

.mdi-image-filter-black-white::before {
    content: "\f02f0"
}

.mdi-image-filter-center-focus::before {
    content: "\f02f1"
}

.mdi-image-filter-center-focus-strong::before {
    content: "\f0eff"
}

.mdi-image-filter-center-focus-strong-outline::before {
    content: "\f0f00"
}

.mdi-image-filter-center-focus-weak::before {
    content: "\f02f2"
}

.mdi-image-filter-drama::before {
    content: "\f02f3"
}

.mdi-image-filter-frames::before {
    content: "\f02f4"
}

.mdi-image-filter-hdr::before {
    content: "\f02f5"
}

.mdi-image-filter-none::before {
    content: "\f02f6"
}

.mdi-image-filter-tilt-shift::before {
    content: "\f02f7"
}

.mdi-image-filter-vintage::before {
    content: "\f02f8"
}

.mdi-image-frame::before {
    content: "\f0e49"
}

.mdi-image-marker::before {
    content: "\f177b"
}

.mdi-image-marker-outline::before {
    content: "\f177c"
}

.mdi-image-minus::before {
    content: "\f1419"
}

.mdi-image-move::before {
    content: "\f09f8"
}

.mdi-image-multiple::before {
    content: "\f02f9"
}

.mdi-image-multiple-outline::before {
    content: "\f02ef"
}

.mdi-image-off::before {
    content: "\f082b"
}

.mdi-image-off-outline::before {
    content: "\f11d1"
}

.mdi-image-outline::before {
    content: "\f0976"
}

.mdi-image-plus::before {
    content: "\f087c"
}

.mdi-image-remove::before {
    content: "\f1418"
}

.mdi-image-search::before {
    content: "\f0977"
}

.mdi-image-search-outline::before {
    content: "\f0978"
}

.mdi-image-size-select-actual::before {
    content: "\f0c8d"
}

.mdi-image-size-select-large::before {
    content: "\f0c8e"
}

.mdi-image-size-select-small::before {
    content: "\f0c8f"
}

.mdi-image-text::before {
    content: "\f160d"
}

.mdi-import::before {
    content: "\f02fa"
}

.mdi-inbox::before {
    content: "\f0687"
}

.mdi-inbox-arrow-down::before {
    content: "\f02fb"
}

.mdi-inbox-arrow-down-outline::before {
    content: "\f1270"
}

.mdi-inbox-arrow-up::before {
    content: "\f03d1"
}

.mdi-inbox-arrow-up-outline::before {
    content: "\f1271"
}

.mdi-inbox-full::before {
    content: "\f1272"
}

.mdi-inbox-full-outline::before {
    content: "\f1273"
}

.mdi-inbox-multiple::before {
    content: "\f08b0"
}

.mdi-inbox-multiple-outline::before {
    content: "\f0ba8"
}

.mdi-inbox-outline::before {
    content: "\f1274"
}

.mdi-inbox-remove::before {
    content: "\f159f"
}

.mdi-inbox-remove-outline::before {
    content: "\f15a0"
}

.mdi-incognito::before {
    content: "\f05f9"
}

.mdi-incognito-circle::before {
    content: "\f1421"
}

.mdi-incognito-circle-off::before {
    content: "\f1422"
}

.mdi-incognito-off::before {
    content: "\f0075"
}

.mdi-induction::before {
    content: "\f184c"
}

.mdi-infinity::before {
    content: "\f06e4"
}

.mdi-information::before {
    content: "\f02fc"
}

.mdi-information-off::before {
    content: "\f178c"
}

.mdi-information-off-outline::before {
    content: "\f178d"
}

.mdi-information-outline::before {
    content: "\f02fd"
}

.mdi-information-variant::before {
    content: "\f064e"
}

.mdi-instagram::before {
    content: "\f02fe"
}

.mdi-instrument-triangle::before {
    content: "\f104e"
}

.mdi-integrated-circuit-chip::before {
    content: "\f1913"
}

.mdi-invert-colors::before {
    content: "\f0301"
}

.mdi-invert-colors-off::before {
    content: "\f0e4a"
}

.mdi-iobroker::before {
    content: "\f12e8"
}

.mdi-ip::before {
    content: "\f0a5f"
}

.mdi-ip-network::before {
    content: "\f0a60"
}

.mdi-ip-network-outline::before {
    content: "\f0c90"
}

.mdi-ip-outline::before {
    content: "\f1982"
}

.mdi-ipod::before {
    content: "\f0c91"
}

.mdi-iron::before {
    content: "\f1824"
}

.mdi-iron-board::before {
    content: "\f1838"
}

.mdi-iron-outline::before {
    content: "\f1825"
}

.mdi-island::before {
    content: "\f104f"
}

.mdi-iv-bag::before {
    content: "\f10b9"
}

.mdi-jabber::before {
    content: "\f0dd5"
}

.mdi-jeepney::before {
    content: "\f0302"
}

.mdi-jellyfish::before {
    content: "\f0f01"
}

.mdi-jellyfish-outline::before {
    content: "\f0f02"
}

.mdi-jira::before {
    content: "\f0303"
}

.mdi-jquery::before {
    content: "\f087d"
}

.mdi-jsfiddle::before {
    content: "\f0304"
}

.mdi-jump-rope::before {
    content: "\f12ff"
}

.mdi-kabaddi::before {
    content: "\f0d87"
}

.mdi-kangaroo::before {
    content: "\f1558"
}

.mdi-karate::before {
    content: "\f082c"
}

.mdi-kayaking::before {
    content: "\f08af"
}

.mdi-keg::before {
    content: "\f0305"
}

.mdi-kettle::before {
    content: "\f05fa"
}

.mdi-kettle-alert::before {
    content: "\f1317"
}

.mdi-kettle-alert-outline::before {
    content: "\f1318"
}

.mdi-kettle-off::before {
    content: "\f131b"
}

.mdi-kettle-off-outline::before {
    content: "\f131c"
}

.mdi-kettle-outline::before {
    content: "\f0f56"
}

.mdi-kettle-pour-over::before {
    content: "\f173c"
}

.mdi-kettle-steam::before {
    content: "\f1319"
}

.mdi-kettle-steam-outline::before {
    content: "\f131a"
}

.mdi-kettlebell::before {
    content: "\f1300"
}

.mdi-key::before {
    content: "\f0306"
}

.mdi-key-alert::before {
    content: "\f1983"
}

.mdi-key-alert-outline::before {
    content: "\f1984"
}

.mdi-key-arrow-right::before {
    content: "\f1312"
}

.mdi-key-chain::before {
    content: "\f1574"
}

.mdi-key-chain-variant::before {
    content: "\f1575"
}

.mdi-key-change::before {
    content: "\f0307"
}

.mdi-key-link::before {
    content: "\f119f"
}

.mdi-key-minus::before {
    content: "\f0308"
}

.mdi-key-outline::before {
    content: "\f0dd6"
}

.mdi-key-plus::before {
    content: "\f0309"
}

.mdi-key-remove::before {
    content: "\f030a"
}

.mdi-key-star::before {
    content: "\f119e"
}

.mdi-key-variant::before {
    content: "\f030b"
}

.mdi-key-wireless::before {
    content: "\f0fc2"
}

.mdi-keyboard::before {
    content: "\f030c"
}

.mdi-keyboard-backspace::before {
    content: "\f030d"
}

.mdi-keyboard-caps::before {
    content: "\f030e"
}

.mdi-keyboard-close::before {
    content: "\f030f"
}

.mdi-keyboard-esc::before {
    content: "\f12b7"
}

.mdi-keyboard-f1::before {
    content: "\f12ab"
}

.mdi-keyboard-f10::before {
    content: "\f12b4"
}

.mdi-keyboard-f11::before {
    content: "\f12b5"
}

.mdi-keyboard-f12::before {
    content: "\f12b6"
}

.mdi-keyboard-f2::before {
    content: "\f12ac"
}

.mdi-keyboard-f3::before {
    content: "\f12ad"
}

.mdi-keyboard-f4::before {
    content: "\f12ae"
}

.mdi-keyboard-f5::before {
    content: "\f12af"
}

.mdi-keyboard-f6::before {
    content: "\f12b0"
}

.mdi-keyboard-f7::before {
    content: "\f12b1"
}

.mdi-keyboard-f8::before {
    content: "\f12b2"
}

.mdi-keyboard-f9::before {
    content: "\f12b3"
}

.mdi-keyboard-off::before {
    content: "\f0310"
}

.mdi-keyboard-off-outline::before {
    content: "\f0e4b"
}

.mdi-keyboard-outline::before {
    content: "\f097b"
}

.mdi-keyboard-return::before {
    content: "\f0311"
}

.mdi-keyboard-settings::before {
    content: "\f09f9"
}

.mdi-keyboard-settings-outline::before {
    content: "\f09fa"
}

.mdi-keyboard-space::before {
    content: "\f1050"
}

.mdi-keyboard-tab::before {
    content: "\f0312"
}

.mdi-keyboard-tab-reverse::before {
    content: "\f0325"
}

.mdi-keyboard-variant::before {
    content: "\f0313"
}

.mdi-khanda::before {
    content: "\f10fd"
}

.mdi-kickstarter::before {
    content: "\f0745"
}

.mdi-kite::before {
    content: "\f1985"
}

.mdi-kite-outline::before {
    content: "\f1986"
}

.mdi-kitesurfing::before {
    content: "\f1744"
}

.mdi-klingon::before {
    content: "\f135b"
}

.mdi-knife::before {
    content: "\f09fb"
}

.mdi-knife-military::before {
    content: "\f09fc"
}

.mdi-koala::before {
    content: "\f173f"
}

.mdi-kodi::before {
    content: "\f0314"
}

.mdi-kubernetes::before {
    content: "\f10fe"
}

.mdi-label::before {
    content: "\f0315"
}

.mdi-label-multiple::before {
    content: "\f1375"
}

.mdi-label-multiple-outline::before {
    content: "\f1376"
}

.mdi-label-off::before {
    content: "\f0acb"
}

.mdi-label-off-outline::before {
    content: "\f0acc"
}

.mdi-label-outline::before {
    content: "\f0316"
}

.mdi-label-percent::before {
    content: "\f12ea"
}

.mdi-label-percent-outline::before {
    content: "\f12eb"
}

.mdi-label-variant::before {
    content: "\f0acd"
}

.mdi-label-variant-outline::before {
    content: "\f0ace"
}

.mdi-ladder::before {
    content: "\f15a2"
}

.mdi-ladybug::before {
    content: "\f082d"
}

.mdi-lambda::before {
    content: "\f0627"
}

.mdi-lamp::before {
    content: "\f06b5"
}

.mdi-lamp-outline::before {
    content: "\f17d0"
}

.mdi-lamps::before {
    content: "\f1576"
}

.mdi-lamps-outline::before {
    content: "\f17d1"
}

.mdi-lan::before {
    content: "\f0317"
}

.mdi-lan-check::before {
    content: "\f12aa"
}

.mdi-lan-connect::before {
    content: "\f0318"
}

.mdi-lan-disconnect::before {
    content: "\f0319"
}

.mdi-lan-pending::before {
    content: "\f031a"
}

.mdi-language-c::before {
    content: "\f0671"
}

.mdi-language-cpp::before {
    content: "\f0672"
}

.mdi-language-csharp::before {
    content: "\f031b"
}

.mdi-language-css3::before {
    content: "\f031c"
}

.mdi-language-fortran::before {
    content: "\f121a"
}

.mdi-language-go::before {
    content: "\f07d3"
}

.mdi-language-haskell::before {
    content: "\f0c92"
}

.mdi-language-html5::before {
    content: "\f031d"
}

.mdi-language-java::before {
    content: "\f0b37"
}

.mdi-language-javascript::before {
    content: "\f031e"
}

.mdi-language-kotlin::before {
    content: "\f1219"
}

.mdi-language-lua::before {
    content: "\f08b1"
}

.mdi-language-markdown::before {
    content: "\f0354"
}

.mdi-language-markdown-outline::before {
    content: "\f0f5b"
}

.mdi-language-php::before {
    content: "\f031f"
}

.mdi-language-python::before {
    content: "\f0320"
}

.mdi-language-r::before {
    content: "\f07d4"
}

.mdi-language-ruby::before {
    content: "\f0d2d"
}

.mdi-language-ruby-on-rails::before {
    content: "\f0acf"
}

.mdi-language-rust::before {
    content: "\f1617"
}

.mdi-language-swift::before {
    content: "\f06e5"
}

.mdi-language-typescript::before {
    content: "\f06e6"
}

.mdi-language-xaml::before {
    content: "\f0673"
}

.mdi-laptop::before {
    content: "\f0322"
}

.mdi-laptop-off::before {
    content: "\f06e7"
}

.mdi-laravel::before {
    content: "\f0ad0"
}

.mdi-laser-pointer::before {
    content: "\f1484"
}

.mdi-lasso::before {
    content: "\f0f03"
}

.mdi-lastpass::before {
    content: "\f0446"
}

.mdi-latitude::before {
    content: "\f0f57"
}

.mdi-launch::before {
    content: "\f0327"
}

.mdi-lava-lamp::before {
    content: "\f07d5"
}

.mdi-layers::before {
    content: "\f0328"
}

.mdi-layers-edit::before {
    content: "\f1892"
}

.mdi-layers-minus::before {
    content: "\f0e4c"
}

.mdi-layers-off::before {
    content: "\f0329"
}

.mdi-layers-off-outline::before {
    content: "\f09fd"
}

.mdi-layers-outline::before {
    content: "\f09fe"
}

.mdi-layers-plus::before {
    content: "\f0e4d"
}

.mdi-layers-remove::before {
    content: "\f0e4e"
}

.mdi-layers-search::before {
    content: "\f1206"
}

.mdi-layers-search-outline::before {
    content: "\f1207"
}

.mdi-layers-triple::before {
    content: "\f0f58"
}

.mdi-layers-triple-outline::before {
    content: "\f0f59"
}

.mdi-lead-pencil::before {
    content: "\f064f"
}

.mdi-leaf::before {
    content: "\f032a"
}

.mdi-leaf-circle::before {
    content: "\f1905"
}

.mdi-leaf-circle-outline::before {
    content: "\f1906"
}

.mdi-leaf-maple::before {
    content: "\f0c93"
}

.mdi-leaf-maple-off::before {
    content: "\f12da"
}

.mdi-leaf-off::before {
    content: "\f12d9"
}

.mdi-leak::before {
    content: "\f0dd7"
}

.mdi-leak-off::before {
    content: "\f0dd8"
}

.mdi-led-off::before {
    content: "\f032b"
}

.mdi-led-on::before {
    content: "\f032c"
}

.mdi-led-outline::before {
    content: "\f032d"
}

.mdi-led-strip::before {
    content: "\f07d6"
}

.mdi-led-strip-variant::before {
    content: "\f1051"
}

.mdi-led-variant-off::before {
    content: "\f032e"
}

.mdi-led-variant-on::before {
    content: "\f032f"
}

.mdi-led-variant-outline::before {
    content: "\f0330"
}

.mdi-leek::before {
    content: "\f117d"
}

.mdi-less-than::before {
    content: "\f097c"
}

.mdi-less-than-or-equal::before {
    content: "\f097d"
}

.mdi-library::before {
    content: "\f0331"
}

.mdi-library-shelves::before {
    content: "\f0ba9"
}

.mdi-license::before {
    content: "\f0fc3"
}

.mdi-lifebuoy::before {
    content: "\f087e"
}

.mdi-light-flood-down::before {
    content: "\f1987"
}

.mdi-light-flood-up::before {
    content: "\f1988"
}

.mdi-light-recessed::before {
    content: "\f179b"
}

.mdi-light-switch::before {
    content: "\f097e"
}

.mdi-lightbulb::before {
    content: "\f0335"
}

.mdi-lightbulb-auto::before {
    content: "\f1800"
}

.mdi-lightbulb-auto-outline::before {
    content: "\f1801"
}

.mdi-lightbulb-cfl::before {
    content: "\f1208"
}

.mdi-lightbulb-cfl-off::before {
    content: "\f1209"
}

.mdi-lightbulb-cfl-spiral::before {
    content: "\f1275"
}

.mdi-lightbulb-cfl-spiral-off::before {
    content: "\f12c3"
}

.mdi-lightbulb-fluorescent-tube::before {
    content: "\f1804"
}

.mdi-lightbulb-fluorescent-tube-outline::before {
    content: "\f1805"
}

.mdi-lightbulb-group::before {
    content: "\f1253"
}

.mdi-lightbulb-group-off::before {
    content: "\f12cd"
}

.mdi-lightbulb-group-off-outline::before {
    content: "\f12ce"
}

.mdi-lightbulb-group-outline::before {
    content: "\f1254"
}

.mdi-lightbulb-multiple::before {
    content: "\f1255"
}

.mdi-lightbulb-multiple-off::before {
    content: "\f12cf"
}

.mdi-lightbulb-multiple-off-outline::before {
    content: "\f12d0"
}

.mdi-lightbulb-multiple-outline::before {
    content: "\f1256"
}

.mdi-lightbulb-off::before {
    content: "\f0e4f"
}

.mdi-lightbulb-off-outline::before {
    content: "\f0e50"
}

.mdi-lightbulb-on::before {
    content: "\f06e8"
}

.mdi-lightbulb-on-outline::before {
    content: "\f06e9"
}

.mdi-lightbulb-outline::before {
    content: "\f0336"
}

.mdi-lightbulb-spot::before {
    content: "\f17f4"
}

.mdi-lightbulb-spot-off::before {
    content: "\f17f5"
}

.mdi-lightbulb-variant::before {
    content: "\f1802"
}

.mdi-lightbulb-variant-outline::before {
    content: "\f1803"
}

.mdi-lighthouse::before {
    content: "\f09ff"
}

.mdi-lighthouse-on::before {
    content: "\f0a00"
}

.mdi-lightning-bolt::before {
    content: "\f140b"
}

.mdi-lightning-bolt-circle::before {
    content: "\f0820"
}

.mdi-lightning-bolt-outline::before {
    content: "\f140c"
}

.mdi-line-scan::before {
    content: "\f0624"
}

.mdi-lingerie::before {
    content: "\f1476"
}

.mdi-link::before {
    content: "\f0337"
}

.mdi-link-box::before {
    content: "\f0d1a"
}

.mdi-link-box-outline::before {
    content: "\f0d1b"
}

.mdi-link-box-variant::before {
    content: "\f0d1c"
}

.mdi-link-box-variant-outline::before {
    content: "\f0d1d"
}

.mdi-link-lock::before {
    content: "\f10ba"
}

.mdi-link-off::before {
    content: "\f0338"
}

.mdi-link-plus::before {
    content: "\f0c94"
}

.mdi-link-variant::before {
    content: "\f0339"
}

.mdi-link-variant-minus::before {
    content: "\f10ff"
}

.mdi-link-variant-off::before {
    content: "\f033a"
}

.mdi-link-variant-plus::before {
    content: "\f1100"
}

.mdi-link-variant-remove::before {
    content: "\f1101"
}

.mdi-linkedin::before {
    content: "\f033b"
}

.mdi-linux::before {
    content: "\f033d"
}

.mdi-linux-mint::before {
    content: "\f08ed"
}

.mdi-lipstick::before {
    content: "\f13b5"
}

.mdi-liquid-spot::before {
    content: "\f1826"
}

.mdi-liquor::before {
    content: "\f191e"
}

.mdi-list-status::before {
    content: "\f15ab"
}

.mdi-litecoin::before {
    content: "\f0a61"
}

.mdi-loading::before {
    content: "\f0772"
}

.mdi-location-enter::before {
    content: "\f0fc4"
}

.mdi-location-exit::before {
    content: "\f0fc5"
}

.mdi-lock::before {
    content: "\f033e"
}

.mdi-lock-alert::before {
    content: "\f08ee"
}

.mdi-lock-alert-outline::before {
    content: "\f15d1"
}

.mdi-lock-check::before {
    content: "\f139a"
}

.mdi-lock-check-outline::before {
    content: "\f16a8"
}

.mdi-lock-clock::before {
    content: "\f097f"
}

.mdi-lock-minus::before {
    content: "\f16a9"
}

.mdi-lock-minus-outline::before {
    content: "\f16aa"
}

.mdi-lock-off::before {
    content: "\f1671"
}

.mdi-lock-off-outline::before {
    content: "\f1672"
}

.mdi-lock-open::before {
    content: "\f033f"
}

.mdi-lock-open-alert::before {
    content: "\f139b"
}

.mdi-lock-open-alert-outline::before {
    content: "\f15d2"
}

.mdi-lock-open-check::before {
    content: "\f139c"
}

.mdi-lock-open-check-outline::before {
    content: "\f16ab"
}

.mdi-lock-open-minus::before {
    content: "\f16ac"
}

.mdi-lock-open-minus-outline::before {
    content: "\f16ad"
}

.mdi-lock-open-outline::before {
    content: "\f0340"
}

.mdi-lock-open-plus::before {
    content: "\f16ae"
}

.mdi-lock-open-plus-outline::before {
    content: "\f16af"
}

.mdi-lock-open-remove::before {
    content: "\f16b0"
}

.mdi-lock-open-remove-outline::before {
    content: "\f16b1"
}

.mdi-lock-open-variant::before {
    content: "\f0fc6"
}

.mdi-lock-open-variant-outline::before {
    content: "\f0fc7"
}

.mdi-lock-outline::before {
    content: "\f0341"
}

.mdi-lock-pattern::before {
    content: "\f06ea"
}

.mdi-lock-plus::before {
    content: "\f05fb"
}

.mdi-lock-plus-outline::before {
    content: "\f16b2"
}

.mdi-lock-question::before {
    content: "\f08ef"
}

.mdi-lock-remove::before {
    content: "\f16b3"
}

.mdi-lock-remove-outline::before {
    content: "\f16b4"
}

.mdi-lock-reset::before {
    content: "\f0773"
}

.mdi-lock-smart::before {
    content: "\f08b2"
}

.mdi-locker::before {
    content: "\f07d7"
}

.mdi-locker-multiple::before {
    content: "\f07d8"
}

.mdi-login::before {
    content: "\f0342"
}

.mdi-login-variant::before {
    content: "\f05fc"
}

.mdi-logout::before {
    content: "\f0343"
}

.mdi-logout-variant::before {
    content: "\f05fd"
}

.mdi-longitude::before {
    content: "\f0f5a"
}

.mdi-looks::before {
    content: "\f0344"
}

.mdi-lotion::before {
    content: "\f1582"
}

.mdi-lotion-outline::before {
    content: "\f1583"
}

.mdi-lotion-plus::before {
    content: "\f1584"
}

.mdi-lotion-plus-outline::before {
    content: "\f1585"
}

.mdi-loupe::before {
    content: "\f0345"
}

.mdi-lumx::before {
    content: "\f0346"
}

.mdi-lungs::before {
    content: "\f1084"
}

.mdi-mace::before {
    content: "\f1843"
}

.mdi-magazine-pistol::before {
    content: "\f0324"
}

.mdi-magazine-rifle::before {
    content: "\f0323"
}

.mdi-magic-staff::before {
    content: "\f1844"
}

.mdi-magnet::before {
    content: "\f0347"
}

.mdi-magnet-on::before {
    content: "\f0348"
}

.mdi-magnify::before {
    content: "\f0349"
}

.mdi-magnify-close::before {
    content: "\f0980"
}

.mdi-magnify-expand::before {
    content: "\f1874"
}

.mdi-magnify-minus::before {
    content: "\f034a"
}

.mdi-magnify-minus-cursor::before {
    content: "\f0a62"
}

.mdi-magnify-minus-outline::before {
    content: "\f06ec"
}

.mdi-magnify-plus::before {
    content: "\f034b"
}

.mdi-magnify-plus-cursor::before {
    content: "\f0a63"
}

.mdi-magnify-plus-outline::before {
    content: "\f06ed"
}

.mdi-magnify-remove-cursor::before {
    content: "\f120c"
}

.mdi-magnify-remove-outline::before {
    content: "\f120d"
}

.mdi-magnify-scan::before {
    content: "\f1276"
}

.mdi-mail::before {
    content: "\f0ebb"
}

.mdi-mailbox::before {
    content: "\f06ee"
}

.mdi-mailbox-open::before {
    content: "\f0d88"
}

.mdi-mailbox-open-outline::before {
    content: "\f0d89"
}

.mdi-mailbox-open-up::before {
    content: "\f0d8a"
}

.mdi-mailbox-open-up-outline::before {
    content: "\f0d8b"
}

.mdi-mailbox-outline::before {
    content: "\f0d8c"
}

.mdi-mailbox-up::before {
    content: "\f0d8d"
}

.mdi-mailbox-up-outline::before {
    content: "\f0d8e"
}

.mdi-manjaro::before {
    content: "\f160a"
}

.mdi-map::before {
    content: "\f034d"
}

.mdi-map-check::before {
    content: "\f0ebc"
}

.mdi-map-check-outline::before {
    content: "\f0ebd"
}

.mdi-map-clock::before {
    content: "\f0d1e"
}

.mdi-map-clock-outline::before {
    content: "\f0d1f"
}

.mdi-map-legend::before {
    content: "\f0a01"
}

.mdi-map-marker::before {
    content: "\f034e"
}

.mdi-map-marker-account::before {
    content: "\f18e3"
}

.mdi-map-marker-account-outline::before {
    content: "\f18e4"
}

.mdi-map-marker-alert::before {
    content: "\f0f05"
}

.mdi-map-marker-alert-outline::before {
    content: "\f0f06"
}

.mdi-map-marker-check::before {
    content: "\f0c95"
}

.mdi-map-marker-check-outline::before {
    content: "\f12fb"
}

.mdi-map-marker-circle::before {
    content: "\f034f"
}

.mdi-map-marker-distance::before {
    content: "\f08f0"
}

.mdi-map-marker-down::before {
    content: "\f1102"
}

.mdi-map-marker-left::before {
    content: "\f12db"
}

.mdi-map-marker-left-outline::before {
    content: "\f12dd"
}

.mdi-map-marker-minus::before {
    content: "\f0650"
}

.mdi-map-marker-minus-outline::before {
    content: "\f12f9"
}

.mdi-map-marker-multiple::before {
    content: "\f0350"
}

.mdi-map-marker-multiple-outline::before {
    content: "\f1277"
}

.mdi-map-marker-off::before {
    content: "\f0351"
}

.mdi-map-marker-off-outline::before {
    content: "\f12fd"
}

.mdi-map-marker-outline::before {
    content: "\f07d9"
}

.mdi-map-marker-path::before {
    content: "\f0d20"
}

.mdi-map-marker-plus::before {
    content: "\f0651"
}

.mdi-map-marker-plus-outline::before {
    content: "\f12f8"
}

.mdi-map-marker-question::before {
    content: "\f0f07"
}

.mdi-map-marker-question-outline::before {
    content: "\f0f08"
}

.mdi-map-marker-radius::before {
    content: "\f0352"
}

.mdi-map-marker-radius-outline::before {
    content: "\f12fc"
}

.mdi-map-marker-remove::before {
    content: "\f0f09"
}

.mdi-map-marker-remove-outline::before {
    content: "\f12fa"
}

.mdi-map-marker-remove-variant::before {
    content: "\f0f0a"
}

.mdi-map-marker-right::before {
    content: "\f12dc"
}

.mdi-map-marker-right-outline::before {
    content: "\f12de"
}

.mdi-map-marker-star::before {
    content: "\f1608"
}

.mdi-map-marker-star-outline::before {
    content: "\f1609"
}

.mdi-map-marker-up::before {
    content: "\f1103"
}

.mdi-map-minus::before {
    content: "\f0981"
}

.mdi-map-outline::before {
    content: "\f0982"
}

.mdi-map-plus::before {
    content: "\f0983"
}

.mdi-map-search::before {
    content: "\f0984"
}

.mdi-map-search-outline::before {
    content: "\f0985"
}

.mdi-mapbox::before {
    content: "\f0baa"
}

.mdi-margin::before {
    content: "\f0353"
}

.mdi-marker::before {
    content: "\f0652"
}

.mdi-marker-cancel::before {
    content: "\f0dd9"
}

.mdi-marker-check::before {
    content: "\f0355"
}

.mdi-mastodon::before {
    content: "\f0ad1"
}

.mdi-material-design::before {
    content: "\f0986"
}

.mdi-material-ui::before {
    content: "\f0357"
}

.mdi-math-compass::before {
    content: "\f0358"
}

.mdi-math-cos::before {
    content: "\f0c96"
}

.mdi-math-integral::before {
    content: "\f0fc8"
}

.mdi-math-integral-box::before {
    content: "\f0fc9"
}

.mdi-math-log::before {
    content: "\f1085"
}

.mdi-math-norm::before {
    content: "\f0fca"
}

.mdi-math-norm-box::before {
    content: "\f0fcb"
}

.mdi-math-sin::before {
    content: "\f0c97"
}

.mdi-math-tan::before {
    content: "\f0c98"
}

.mdi-matrix::before {
    content: "\f0628"
}

.mdi-medal::before {
    content: "\f0987"
}

.mdi-medal-outline::before {
    content: "\f1326"
}

.mdi-medical-bag::before {
    content: "\f06ef"
}

.mdi-meditation::before {
    content: "\f117b"
}

.mdi-memory::before {
    content: "\f035b"
}

.mdi-menorah::before {
    content: "\f17d4"
}

.mdi-menorah-fire::before {
    content: "\f17d5"
}

.mdi-menu::before {
    content: "\f035c"
}

.mdi-menu-down::before {
    content: "\f035d"
}

.mdi-menu-down-outline::before {
    content: "\f06b6"
}

.mdi-menu-left::before {
    content: "\f035e"
}

.mdi-menu-left-outline::before {
    content: "\f0a02"
}

.mdi-menu-open::before {
    content: "\f0bab"
}

.mdi-menu-right::before {
    content: "\f035f"
}

.mdi-menu-right-outline::before {
    content: "\f0a03"
}

.mdi-menu-swap::before {
    content: "\f0a64"
}

.mdi-menu-swap-outline::before {
    content: "\f0a65"
}

.mdi-menu-up::before {
    content: "\f0360"
}

.mdi-menu-up-outline::before {
    content: "\f06b7"
}

.mdi-merge::before {
    content: "\f0f5c"
}

.mdi-message::before {
    content: "\f0361"
}

.mdi-message-alert::before {
    content: "\f0362"
}

.mdi-message-alert-outline::before {
    content: "\f0a04"
}

.mdi-message-arrow-left::before {
    content: "\f12f2"
}

.mdi-message-arrow-left-outline::before {
    content: "\f12f3"
}

.mdi-message-arrow-right::before {
    content: "\f12f4"
}

.mdi-message-arrow-right-outline::before {
    content: "\f12f5"
}

.mdi-message-badge::before {
    content: "\f1941"
}

.mdi-message-badge-outline::before {
    content: "\f1942"
}

.mdi-message-bookmark::before {
    content: "\f15ac"
}

.mdi-message-bookmark-outline::before {
    content: "\f15ad"
}

.mdi-message-bulleted::before {
    content: "\f06a2"
}

.mdi-message-bulleted-off::before {
    content: "\f06a3"
}

.mdi-message-cog::before {
    content: "\f06f1"
}

.mdi-message-cog-outline::before {
    content: "\f1172"
}

.mdi-message-draw::before {
    content: "\f0363"
}

.mdi-message-flash::before {
    content: "\f15a9"
}

.mdi-message-flash-outline::before {
    content: "\f15aa"
}

.mdi-message-image::before {
    content: "\f0364"
}

.mdi-message-image-outline::before {
    content: "\f116c"
}

.mdi-message-lock::before {
    content: "\f0fcc"
}

.mdi-message-lock-outline::before {
    content: "\f116d"
}

.mdi-message-minus::before {
    content: "\f116e"
}

.mdi-message-minus-outline::before {
    content: "\f116f"
}

.mdi-message-off::before {
    content: "\f164d"
}

.mdi-message-off-outline::before {
    content: "\f164e"
}

.mdi-message-outline::before {
    content: "\f0365"
}

.mdi-message-plus::before {
    content: "\f0653"
}

.mdi-message-plus-outline::before {
    content: "\f10bb"
}

.mdi-message-processing::before {
    content: "\f0366"
}

.mdi-message-processing-outline::before {
    content: "\f1170"
}

.mdi-message-question::before {
    content: "\f173a"
}

.mdi-message-question-outline::before {
    content: "\f173b"
}

.mdi-message-reply::before {
    content: "\f0367"
}

.mdi-message-reply-outline::before {
    content: "\f173d"
}

.mdi-message-reply-text::before {
    content: "\f0368"
}

.mdi-message-reply-text-outline::before {
    content: "\f173e"
}

.mdi-message-settings::before {
    content: "\f06f0"
}

.mdi-message-settings-outline::before {
    content: "\f1171"
}

.mdi-message-star::before {
    content: "\f069a"
}

.mdi-message-star-outline::before {
    content: "\f1250"
}

.mdi-message-text::before {
    content: "\f0369"
}

.mdi-message-text-clock::before {
    content: "\f1173"
}

.mdi-message-text-clock-outline::before {
    content: "\f1174"
}

.mdi-message-text-lock::before {
    content: "\f0fcd"
}

.mdi-message-text-lock-outline::before {
    content: "\f1175"
}

.mdi-message-text-outline::before {
    content: "\f036a"
}

.mdi-message-video::before {
    content: "\f036b"
}

.mdi-meteor::before {
    content: "\f0629"
}

.mdi-metronome::before {
    content: "\f07da"
}

.mdi-metronome-tick::before {
    content: "\f07db"
}

.mdi-micro-sd::before {
    content: "\f07dc"
}

.mdi-microphone::before {
    content: "\f036c"
}

.mdi-microphone-minus::before {
    content: "\f08b3"
}

.mdi-microphone-off::before {
    content: "\f036d"
}

.mdi-microphone-outline::before {
    content: "\f036e"
}

.mdi-microphone-plus::before {
    content: "\f08b4"
}

.mdi-microphone-question::before {
    content: "\f1989"
}

.mdi-microphone-question-outline::before {
    content: "\f198a"
}

.mdi-microphone-settings::before {
    content: "\f036f"
}

.mdi-microphone-variant::before {
    content: "\f0370"
}

.mdi-microphone-variant-off::before {
    content: "\f0371"
}

.mdi-microscope::before {
    content: "\f0654"
}

.mdi-microsoft::before {
    content: "\f0372"
}

.mdi-microsoft-access::before {
    content: "\f138e"
}

.mdi-microsoft-azure::before {
    content: "\f0805"
}

.mdi-microsoft-azure-devops::before {
    content: "\f0fd5"
}

.mdi-microsoft-bing::before {
    content: "\f00a4"
}

.mdi-microsoft-dynamics-365::before {
    content: "\f0988"
}

.mdi-microsoft-edge::before {
    content: "\f01e9"
}

.mdi-microsoft-excel::before {
    content: "\f138f"
}

.mdi-microsoft-internet-explorer::before {
    content: "\f0300"
}

.mdi-microsoft-office::before {
    content: "\f03c6"
}

.mdi-microsoft-onedrive::before {
    content: "\f03ca"
}

.mdi-microsoft-onenote::before {
    content: "\f0747"
}

.mdi-microsoft-outlook::before {
    content: "\f0d22"
}

.mdi-microsoft-powerpoint::before {
    content: "\f1390"
}

.mdi-microsoft-sharepoint::before {
    content: "\f1391"
}

.mdi-microsoft-teams::before {
    content: "\f02bb"
}

.mdi-microsoft-visual-studio::before {
    content: "\f0610"
}

.mdi-microsoft-visual-studio-code::before {
    content: "\f0a1e"
}

.mdi-microsoft-windows::before {
    content: "\f05b3"
}

.mdi-microsoft-windows-classic::before {
    content: "\f0a21"
}

.mdi-microsoft-word::before {
    content: "\f1392"
}

.mdi-microsoft-xbox::before {
    content: "\f05b9"
}

.mdi-microsoft-xbox-controller::before {
    content: "\f05ba"
}

.mdi-microsoft-xbox-controller-battery-alert::before {
    content: "\f074b"
}

.mdi-microsoft-xbox-controller-battery-charging::before {
    content: "\f0a22"
}

.mdi-microsoft-xbox-controller-battery-empty::before {
    content: "\f074c"
}

.mdi-microsoft-xbox-controller-battery-full::before {
    content: "\f074d"
}

.mdi-microsoft-xbox-controller-battery-low::before {
    content: "\f074e"
}

.mdi-microsoft-xbox-controller-battery-medium::before {
    content: "\f074f"
}

.mdi-microsoft-xbox-controller-battery-unknown::before {
    content: "\f0750"
}

.mdi-microsoft-xbox-controller-menu::before {
    content: "\f0e6f"
}

.mdi-microsoft-xbox-controller-off::before {
    content: "\f05bb"
}

.mdi-microsoft-xbox-controller-view::before {
    content: "\f0e70"
}

.mdi-microwave::before {
    content: "\f0c99"
}

.mdi-microwave-off::before {
    content: "\f1423"
}

.mdi-middleware::before {
    content: "\f0f5d"
}

.mdi-middleware-outline::before {
    content: "\f0f5e"
}

.mdi-midi::before {
    content: "\f08f1"
}

.mdi-midi-port::before {
    content: "\f08f2"
}

.mdi-mine::before {
    content: "\f0dda"
}

.mdi-minecraft::before {
    content: "\f0373"
}

.mdi-mini-sd::before {
    content: "\f0a05"
}

.mdi-minidisc::before {
    content: "\f0a06"
}

.mdi-minus::before {
    content: "\f0374"
}

.mdi-minus-box::before {
    content: "\f0375"
}

.mdi-minus-box-multiple::before {
    content: "\f1141"
}

.mdi-minus-box-multiple-outline::before {
    content: "\f1142"
}

.mdi-minus-box-outline::before {
    content: "\f06f2"
}

.mdi-minus-circle::before {
    content: "\f0376"
}

.mdi-minus-circle-multiple::before {
    content: "\f035a"
}

.mdi-minus-circle-multiple-outline::before {
    content: "\f0ad3"
}

.mdi-minus-circle-off::before {
    content: "\f1459"
}

.mdi-minus-circle-off-outline::before {
    content: "\f145a"
}

.mdi-minus-circle-outline::before {
    content: "\f0377"
}

.mdi-minus-network::before {
    content: "\f0378"
}

.mdi-minus-network-outline::before {
    content: "\f0c9a"
}

.mdi-minus-thick::before {
    content: "\f1639"
}

.mdi-mirror::before {
    content: "\f11fd"
}

.mdi-mirror-rectangle::before {
    content: "\f179f"
}

.mdi-mirror-variant::before {
    content: "\f17a0"
}

.mdi-mixed-martial-arts::before {
    content: "\f0d8f"
}

.mdi-mixed-reality::before {
    content: "\f087f"
}

.mdi-molecule::before {
    content: "\f0bac"
}

.mdi-molecule-co::before {
    content: "\f12fe"
}

.mdi-molecule-co2::before {
    content: "\f07e4"
}

.mdi-monitor::before {
    content: "\f0379"
}

.mdi-monitor-cellphone::before {
    content: "\f0989"
}

.mdi-monitor-cellphone-star::before {
    content: "\f098a"
}

.mdi-monitor-dashboard::before {
    content: "\f0a07"
}

.mdi-monitor-edit::before {
    content: "\f12c6"
}

.mdi-monitor-eye::before {
    content: "\f13b4"
}

.mdi-monitor-lock::before {
    content: "\f0ddb"
}

.mdi-monitor-multiple::before {
    content: "\f037a"
}

.mdi-monitor-off::before {
    content: "\f0d90"
}

.mdi-monitor-screenshot::before {
    content: "\f0e51"
}

.mdi-monitor-share::before {
    content: "\f1483"
}

.mdi-monitor-shimmer::before {
    content: "\f1104"
}

.mdi-monitor-small::before {
    content: "\f1876"
}

.mdi-monitor-speaker::before {
    content: "\f0f5f"
}

.mdi-monitor-speaker-off::before {
    content: "\f0f60"
}

.mdi-monitor-star::before {
    content: "\f0ddc"
}

.mdi-moon-first-quarter::before {
    content: "\f0f61"
}

.mdi-moon-full::before {
    content: "\f0f62"
}

.mdi-moon-last-quarter::before {
    content: "\f0f63"
}

.mdi-moon-new::before {
    content: "\f0f64"
}

.mdi-moon-waning-crescent::before {
    content: "\f0f65"
}

.mdi-moon-waning-gibbous::before {
    content: "\f0f66"
}

.mdi-moon-waxing-crescent::before {
    content: "\f0f67"
}

.mdi-moon-waxing-gibbous::before {
    content: "\f0f68"
}

.mdi-moped::before {
    content: "\f1086"
}

.mdi-moped-electric::before {
    content: "\f15b7"
}

.mdi-moped-electric-outline::before {
    content: "\f15b8"
}

.mdi-moped-outline::before {
    content: "\f15b9"
}

.mdi-more::before {
    content: "\f037b"
}

.mdi-mortar-pestle::before {
    content: "\f1748"
}

.mdi-mortar-pestle-plus::before {
    content: "\f03f1"
}

.mdi-mosque::before {
    content: "\f1827"
}

.mdi-mother-heart::before {
    content: "\f1314"
}

.mdi-mother-nurse::before {
    content: "\f0d21"
}

.mdi-motion::before {
    content: "\f15b2"
}

.mdi-motion-outline::before {
    content: "\f15b3"
}

.mdi-motion-pause::before {
    content: "\f1590"
}

.mdi-motion-pause-outline::before {
    content: "\f1592"
}

.mdi-motion-play::before {
    content: "\f158f"
}

.mdi-motion-play-outline::before {
    content: "\f1591"
}

.mdi-motion-sensor::before {
    content: "\f0d91"
}

.mdi-motion-sensor-off::before {
    content: "\f1435"
}

.mdi-motorbike::before {
    content: "\f037c"
}

.mdi-motorbike-electric::before {
    content: "\f15ba"
}

.mdi-mouse::before {
    content: "\f037d"
}

.mdi-mouse-bluetooth::before {
    content: "\f098b"
}

.mdi-mouse-move-down::before {
    content: "\f1550"
}

.mdi-mouse-move-up::before {
    content: "\f1551"
}

.mdi-mouse-move-vertical::before {
    content: "\f1552"
}

.mdi-mouse-off::before {
    content: "\f037e"
}

.mdi-mouse-variant::before {
    content: "\f037f"
}

.mdi-mouse-variant-off::before {
    content: "\f0380"
}

.mdi-move-resize::before {
    content: "\f0655"
}

.mdi-move-resize-variant::before {
    content: "\f0656"
}

.mdi-movie::before {
    content: "\f0381"
}

.mdi-movie-check::before {
    content: "\f16f3"
}

.mdi-movie-check-outline::before {
    content: "\f16f4"
}

.mdi-movie-cog::before {
    content: "\f16f5"
}

.mdi-movie-cog-outline::before {
    content: "\f16f6"
}

.mdi-movie-edit::before {
    content: "\f1122"
}

.mdi-movie-edit-outline::before {
    content: "\f1123"
}

.mdi-movie-filter::before {
    content: "\f1124"
}

.mdi-movie-filter-outline::before {
    content: "\f1125"
}

.mdi-movie-minus::before {
    content: "\f16f7"
}

.mdi-movie-minus-outline::before {
    content: "\f16f8"
}

.mdi-movie-off::before {
    content: "\f16f9"
}

.mdi-movie-off-outline::before {
    content: "\f16fa"
}

.mdi-movie-open::before {
    content: "\f0fce"
}

.mdi-movie-open-check::before {
    content: "\f16fb"
}

.mdi-movie-open-check-outline::before {
    content: "\f16fc"
}

.mdi-movie-open-cog::before {
    content: "\f16fd"
}

.mdi-movie-open-cog-outline::before {
    content: "\f16fe"
}

.mdi-movie-open-edit::before {
    content: "\f16ff"
}

.mdi-movie-open-edit-outline::before {
    content: "\f1700"
}

.mdi-movie-open-minus::before {
    content: "\f1701"
}

.mdi-movie-open-minus-outline::before {
    content: "\f1702"
}

.mdi-movie-open-off::before {
    content: "\f1703"
}

.mdi-movie-open-off-outline::before {
    content: "\f1704"
}

.mdi-movie-open-outline::before {
    content: "\f0fcf"
}

.mdi-movie-open-play::before {
    content: "\f1705"
}

.mdi-movie-open-play-outline::before {
    content: "\f1706"
}

.mdi-movie-open-plus::before {
    content: "\f1707"
}

.mdi-movie-open-plus-outline::before {
    content: "\f1708"
}

.mdi-movie-open-remove::before {
    content: "\f1709"
}

.mdi-movie-open-remove-outline::before {
    content: "\f170a"
}

.mdi-movie-open-settings::before {
    content: "\f170b"
}

.mdi-movie-open-settings-outline::before {
    content: "\f170c"
}

.mdi-movie-open-star::before {
    content: "\f170d"
}

.mdi-movie-open-star-outline::before {
    content: "\f170e"
}

.mdi-movie-outline::before {
    content: "\f0ddd"
}

.mdi-movie-play::before {
    content: "\f170f"
}

.mdi-movie-play-outline::before {
    content: "\f1710"
}

.mdi-movie-plus::before {
    content: "\f1711"
}

.mdi-movie-plus-outline::before {
    content: "\f1712"
}

.mdi-movie-remove::before {
    content: "\f1713"
}

.mdi-movie-remove-outline::before {
    content: "\f1714"
}

.mdi-movie-roll::before {
    content: "\f07de"
}

.mdi-movie-search::before {
    content: "\f11d2"
}

.mdi-movie-search-outline::before {
    content: "\f11d3"
}

.mdi-movie-settings::before {
    content: "\f1715"
}

.mdi-movie-settings-outline::before {
    content: "\f1716"
}

.mdi-movie-star::before {
    content: "\f1717"
}

.mdi-movie-star-outline::before {
    content: "\f1718"
}

.mdi-mower::before {
    content: "\f166f"
}

.mdi-mower-bag::before {
    content: "\f1670"
}

.mdi-muffin::before {
    content: "\f098c"
}

.mdi-multicast::before {
    content: "\f1893"
}

.mdi-multiplication::before {
    content: "\f0382"
}

.mdi-multiplication-box::before {
    content: "\f0383"
}

.mdi-mushroom::before {
    content: "\f07df"
}

.mdi-mushroom-off::before {
    content: "\f13fa"
}

.mdi-mushroom-off-outline::before {
    content: "\f13fb"
}

.mdi-mushroom-outline::before {
    content: "\f07e0"
}

.mdi-music::before {
    content: "\f075a"
}

.mdi-music-accidental-double-flat::before {
    content: "\f0f69"
}

.mdi-music-accidental-double-sharp::before {
    content: "\f0f6a"
}

.mdi-music-accidental-flat::before {
    content: "\f0f6b"
}

.mdi-music-accidental-natural::before {
    content: "\f0f6c"
}

.mdi-music-accidental-sharp::before {
    content: "\f0f6d"
}

.mdi-music-box::before {
    content: "\f0384"
}

.mdi-music-box-multiple::before {
    content: "\f0333"
}

.mdi-music-box-multiple-outline::before {
    content: "\f0f04"
}

.mdi-music-box-outline::before {
    content: "\f0385"
}

.mdi-music-circle::before {
    content: "\f0386"
}

.mdi-music-circle-outline::before {
    content: "\f0ad4"
}

.mdi-music-clef-alto::before {
    content: "\f0f6e"
}

.mdi-music-clef-bass::before {
    content: "\f0f6f"
}

.mdi-music-clef-treble::before {
    content: "\f0f70"
}

.mdi-music-note::before {
    content: "\f0387"
}

.mdi-music-note-bluetooth::before {
    content: "\f05fe"
}

.mdi-music-note-bluetooth-off::before {
    content: "\f05ff"
}

.mdi-music-note-eighth::before {
    content: "\f0388"
}

.mdi-music-note-eighth-dotted::before {
    content: "\f0f71"
}

.mdi-music-note-half::before {
    content: "\f0389"
}

.mdi-music-note-half-dotted::before {
    content: "\f0f72"
}

.mdi-music-note-off::before {
    content: "\f038a"
}

.mdi-music-note-off-outline::before {
    content: "\f0f73"
}

.mdi-music-note-outline::before {
    content: "\f0f74"
}

.mdi-music-note-plus::before {
    content: "\f0dde"
}

.mdi-music-note-quarter::before {
    content: "\f038b"
}

.mdi-music-note-quarter-dotted::before {
    content: "\f0f75"
}

.mdi-music-note-sixteenth::before {
    content: "\f038c"
}

.mdi-music-note-sixteenth-dotted::before {
    content: "\f0f76"
}

.mdi-music-note-whole::before {
    content: "\f038d"
}

.mdi-music-note-whole-dotted::before {
    content: "\f0f77"
}

.mdi-music-off::before {
    content: "\f075b"
}

.mdi-music-rest-eighth::before {
    content: "\f0f78"
}

.mdi-music-rest-half::before {
    content: "\f0f79"
}

.mdi-music-rest-quarter::before {
    content: "\f0f7a"
}

.mdi-music-rest-sixteenth::before {
    content: "\f0f7b"
}

.mdi-music-rest-whole::before {
    content: "\f0f7c"
}

.mdi-mustache::before {
    content: "\f15de"
}

.mdi-nail::before {
    content: "\f0ddf"
}

.mdi-nas::before {
    content: "\f08f3"
}

.mdi-nativescript::before {
    content: "\f0880"
}

.mdi-nature::before {
    content: "\f038e"
}

.mdi-nature-people::before {
    content: "\f038f"
}

.mdi-navigation::before {
    content: "\f0390"
}

.mdi-navigation-outline::before {
    content: "\f1607"
}

.mdi-navigation-variant::before {
    content: "\f18f0"
}

.mdi-navigation-variant-outline::before {
    content: "\f18f1"
}

.mdi-near-me::before {
    content: "\f05cd"
}

.mdi-necklace::before {
    content: "\f0f0b"
}

.mdi-needle::before {
    content: "\f0391"
}

.mdi-netflix::before {
    content: "\f0746"
}

.mdi-network::before {
    content: "\f06f3"
}

.mdi-network-off::before {
    content: "\f0c9b"
}

.mdi-network-off-outline::before {
    content: "\f0c9c"
}

.mdi-network-outline::before {
    content: "\f0c9d"
}

.mdi-network-strength-1::before {
    content: "\f08f4"
}

.mdi-network-strength-1-alert::before {
    content: "\f08f5"
}

.mdi-network-strength-2::before {
    content: "\f08f6"
}

.mdi-network-strength-2-alert::before {
    content: "\f08f7"
}

.mdi-network-strength-3::before {
    content: "\f08f8"
}

.mdi-network-strength-3-alert::before {
    content: "\f08f9"
}

.mdi-network-strength-4::before {
    content: "\f08fa"
}

.mdi-network-strength-4-alert::before {
    content: "\f08fb"
}

.mdi-network-strength-4-cog::before {
    content: "\f191a"
}

.mdi-network-strength-off::before {
    content: "\f08fc"
}

.mdi-network-strength-off-outline::before {
    content: "\f08fd"
}

.mdi-network-strength-outline::before {
    content: "\f08fe"
}

.mdi-new-box::before {
    content: "\f0394"
}

.mdi-newspaper::before {
    content: "\f0395"
}

.mdi-newspaper-check::before {
    content: "\f1943"
}

.mdi-newspaper-minus::before {
    content: "\f0f0c"
}

.mdi-newspaper-plus::before {
    content: "\f0f0d"
}

.mdi-newspaper-remove::before {
    content: "\f1944"
}

.mdi-newspaper-variant::before {
    content: "\f1001"
}

.mdi-newspaper-variant-multiple::before {
    content: "\f1002"
}

.mdi-newspaper-variant-multiple-outline::before {
    content: "\f1003"
}

.mdi-newspaper-variant-outline::before {
    content: "\f1004"
}

.mdi-nfc::before {
    content: "\f0396"
}

.mdi-nfc-search-variant::before {
    content: "\f0e53"
}

.mdi-nfc-tap::before {
    content: "\f0397"
}

.mdi-nfc-variant::before {
    content: "\f0398"
}

.mdi-nfc-variant-off::before {
    content: "\f0e54"
}

.mdi-ninja::before {
    content: "\f0774"
}

.mdi-nintendo-game-boy::before {
    content: "\f1393"
}

.mdi-nintendo-switch::before {
    content: "\f07e1"
}

.mdi-nintendo-wii::before {
    content: "\f05ab"
}

.mdi-nintendo-wiiu::before {
    content: "\f072d"
}

.mdi-nix::before {
    content: "\f1105"
}

.mdi-nodejs::before {
    content: "\f0399"
}

.mdi-noodles::before {
    content: "\f117e"
}

.mdi-not-equal::before {
    content: "\f098d"
}

.mdi-not-equal-variant::before {
    content: "\f098e"
}

.mdi-note::before {
    content: "\f039a"
}

.mdi-note-alert::before {
    content: "\f177d"
}

.mdi-note-alert-outline::before {
    content: "\f177e"
}

.mdi-note-check::before {
    content: "\f177f"
}

.mdi-note-check-outline::before {
    content: "\f1780"
}

.mdi-note-edit::before {
    content: "\f1781"
}

.mdi-note-edit-outline::before {
    content: "\f1782"
}

.mdi-note-minus::before {
    content: "\f164f"
}

.mdi-note-minus-outline::before {
    content: "\f1650"
}

.mdi-note-multiple::before {
    content: "\f06b8"
}

.mdi-note-multiple-outline::before {
    content: "\f06b9"
}

.mdi-note-off::before {
    content: "\f1783"
}

.mdi-note-off-outline::before {
    content: "\f1784"
}

.mdi-note-outline::before {
    content: "\f039b"
}

.mdi-note-plus::before {
    content: "\f039c"
}

.mdi-note-plus-outline::before {
    content: "\f039d"
}

.mdi-note-remove::before {
    content: "\f1651"
}

.mdi-note-remove-outline::before {
    content: "\f1652"
}

.mdi-note-search::before {
    content: "\f1653"
}

.mdi-note-search-outline::before {
    content: "\f1654"
}

.mdi-note-text::before {
    content: "\f039e"
}

.mdi-note-text-outline::before {
    content: "\f11d7"
}

.mdi-notebook::before {
    content: "\f082e"
}

.mdi-notebook-check::before {
    content: "\f14f5"
}

.mdi-notebook-check-outline::before {
    content: "\f14f6"
}

.mdi-notebook-edit::before {
    content: "\f14e7"
}

.mdi-notebook-edit-outline::before {
    content: "\f14e9"
}

.mdi-notebook-minus::before {
    content: "\f1610"
}

.mdi-notebook-minus-outline::before {
    content: "\f1611"
}

.mdi-notebook-multiple::before {
    content: "\f0e55"
}

.mdi-notebook-outline::before {
    content: "\f0ebf"
}

.mdi-notebook-plus::before {
    content: "\f1612"
}

.mdi-notebook-plus-outline::before {
    content: "\f1613"
}

.mdi-notebook-remove::before {
    content: "\f1614"
}

.mdi-notebook-remove-outline::before {
    content: "\f1615"
}

.mdi-notification-clear-all::before {
    content: "\f039f"
}

.mdi-npm::before {
    content: "\f06f7"
}

.mdi-nuke::before {
    content: "\f06a4"
}

.mdi-null::before {
    content: "\f07e2"
}

.mdi-numeric::before {
    content: "\f03a0"
}

.mdi-numeric-0::before {
    content: "\f0b39"
}

.mdi-numeric-0-box::before {
    content: "\f03a1"
}

.mdi-numeric-0-box-multiple::before {
    content: "\f0f0e"
}

.mdi-numeric-0-box-multiple-outline::before {
    content: "\f03a2"
}

.mdi-numeric-0-box-outline::before {
    content: "\f03a3"
}

.mdi-numeric-0-circle::before {
    content: "\f0c9e"
}

.mdi-numeric-0-circle-outline::before {
    content: "\f0c9f"
}

.mdi-numeric-1::before {
    content: "\f0b3a"
}

.mdi-numeric-1-box::before {
    content: "\f03a4"
}

.mdi-numeric-1-box-multiple::before {
    content: "\f0f0f"
}

.mdi-numeric-1-box-multiple-outline::before {
    content: "\f03a5"
}

.mdi-numeric-1-box-outline::before {
    content: "\f03a6"
}

.mdi-numeric-1-circle::before {
    content: "\f0ca0"
}

.mdi-numeric-1-circle-outline::before {
    content: "\f0ca1"
}

.mdi-numeric-10::before {
    content: "\f0fe9"
}

.mdi-numeric-10-box::before {
    content: "\f0f7d"
}

.mdi-numeric-10-box-multiple::before {
    content: "\f0fea"
}

.mdi-numeric-10-box-multiple-outline::before {
    content: "\f0feb"
}

.mdi-numeric-10-box-outline::before {
    content: "\f0f7e"
}

.mdi-numeric-10-circle::before {
    content: "\f0fec"
}

.mdi-numeric-10-circle-outline::before {
    content: "\f0fed"
}

.mdi-numeric-2::before {
    content: "\f0b3b"
}

.mdi-numeric-2-box::before {
    content: "\f03a7"
}

.mdi-numeric-2-box-multiple::before {
    content: "\f0f10"
}

.mdi-numeric-2-box-multiple-outline::before {
    content: "\f03a8"
}

.mdi-numeric-2-box-outline::before {
    content: "\f03a9"
}

.mdi-numeric-2-circle::before {
    content: "\f0ca2"
}

.mdi-numeric-2-circle-outline::before {
    content: "\f0ca3"
}

.mdi-numeric-3::before {
    content: "\f0b3c"
}

.mdi-numeric-3-box::before {
    content: "\f03aa"
}

.mdi-numeric-3-box-multiple::before {
    content: "\f0f11"
}

.mdi-numeric-3-box-multiple-outline::before {
    content: "\f03ab"
}

.mdi-numeric-3-box-outline::before {
    content: "\f03ac"
}

.mdi-numeric-3-circle::before {
    content: "\f0ca4"
}

.mdi-numeric-3-circle-outline::before {
    content: "\f0ca5"
}

.mdi-numeric-4::before {
    content: "\f0b3d"
}

.mdi-numeric-4-box::before {
    content: "\f03ad"
}

.mdi-numeric-4-box-multiple::before {
    content: "\f0f12"
}

.mdi-numeric-4-box-multiple-outline::before {
    content: "\f03b2"
}

.mdi-numeric-4-box-outline::before {
    content: "\f03ae"
}

.mdi-numeric-4-circle::before {
    content: "\f0ca6"
}

.mdi-numeric-4-circle-outline::before {
    content: "\f0ca7"
}

.mdi-numeric-5::before {
    content: "\f0b3e"
}

.mdi-numeric-5-box::before {
    content: "\f03b1"
}

.mdi-numeric-5-box-multiple::before {
    content: "\f0f13"
}

.mdi-numeric-5-box-multiple-outline::before {
    content: "\f03af"
}

.mdi-numeric-5-box-outline::before {
    content: "\f03b0"
}

.mdi-numeric-5-circle::before {
    content: "\f0ca8"
}

.mdi-numeric-5-circle-outline::before {
    content: "\f0ca9"
}

.mdi-numeric-6::before {
    content: "\f0b3f"
}

.mdi-numeric-6-box::before {
    content: "\f03b3"
}

.mdi-numeric-6-box-multiple::before {
    content: "\f0f14"
}

.mdi-numeric-6-box-multiple-outline::before {
    content: "\f03b4"
}

.mdi-numeric-6-box-outline::before {
    content: "\f03b5"
}

.mdi-numeric-6-circle::before {
    content: "\f0caa"
}

.mdi-numeric-6-circle-outline::before {
    content: "\f0cab"
}

.mdi-numeric-7::before {
    content: "\f0b40"
}

.mdi-numeric-7-box::before {
    content: "\f03b6"
}

.mdi-numeric-7-box-multiple::before {
    content: "\f0f15"
}

.mdi-numeric-7-box-multiple-outline::before {
    content: "\f03b7"
}

.mdi-numeric-7-box-outline::before {
    content: "\f03b8"
}

.mdi-numeric-7-circle::before {
    content: "\f0cac"
}

.mdi-numeric-7-circle-outline::before {
    content: "\f0cad"
}

.mdi-numeric-8::before {
    content: "\f0b41"
}

.mdi-numeric-8-box::before {
    content: "\f03b9"
}

.mdi-numeric-8-box-multiple::before {
    content: "\f0f16"
}

.mdi-numeric-8-box-multiple-outline::before {
    content: "\f03ba"
}

.mdi-numeric-8-box-outline::before {
    content: "\f03bb"
}

.mdi-numeric-8-circle::before {
    content: "\f0cae"
}

.mdi-numeric-8-circle-outline::before {
    content: "\f0caf"
}

.mdi-numeric-9::before {
    content: "\f0b42"
}

.mdi-numeric-9-box::before {
    content: "\f03bc"
}

.mdi-numeric-9-box-multiple::before {
    content: "\f0f17"
}

.mdi-numeric-9-box-multiple-outline::before {
    content: "\f03bd"
}

.mdi-numeric-9-box-outline::before {
    content: "\f03be"
}

.mdi-numeric-9-circle::before {
    content: "\f0cb0"
}

.mdi-numeric-9-circle-outline::before {
    content: "\f0cb1"
}

.mdi-numeric-9-plus::before {
    content: "\f0fee"
}

.mdi-numeric-9-plus-box::before {
    content: "\f03bf"
}

.mdi-numeric-9-plus-box-multiple::before {
    content: "\f0f18"
}

.mdi-numeric-9-plus-box-multiple-outline::before {
    content: "\f03c0"
}

.mdi-numeric-9-plus-box-outline::before {
    content: "\f03c1"
}

.mdi-numeric-9-plus-circle::before {
    content: "\f0cb2"
}

.mdi-numeric-9-plus-circle-outline::before {
    content: "\f0cb3"
}

.mdi-numeric-negative-1::before {
    content: "\f1052"
}

.mdi-numeric-positive-1::before {
    content: "\f15cb"
}

.mdi-nut::before {
    content: "\f06f8"
}

.mdi-nutrition::before {
    content: "\f03c2"
}

.mdi-nuxt::before {
    content: "\f1106"
}

.mdi-oar::before {
    content: "\f067c"
}

.mdi-ocarina::before {
    content: "\f0de0"
}

.mdi-oci::before {
    content: "\f12e9"
}

.mdi-ocr::before {
    content: "\f113a"
}

.mdi-octagon::before {
    content: "\f03c3"
}

.mdi-octagon-outline::before {
    content: "\f03c4"
}

.mdi-octagram::before {
    content: "\f06f9"
}

.mdi-octagram-outline::before {
    content: "\f0775"
}

.mdi-octahedron::before {
    content: "\f1950"
}

.mdi-octahedron-off::before {
    content: "\f1951"
}

.mdi-odnoklassniki::before {
    content: "\f03c5"
}

.mdi-offer::before {
    content: "\f121b"
}

.mdi-office-building::before {
    content: "\f0991"
}

.mdi-office-building-cog::before {
    content: "\f1949"
}

.mdi-office-building-cog-outline::before {
    content: "\f194a"
}

.mdi-office-building-marker::before {
    content: "\f1520"
}

.mdi-office-building-marker-outline::before {
    content: "\f1521"
}

.mdi-office-building-outline::before {
    content: "\f151f"
}

.mdi-oil::before {
    content: "\f03c7"
}

.mdi-oil-lamp::before {
    content: "\f0f19"
}

.mdi-oil-level::before {
    content: "\f1053"
}

.mdi-oil-temperature::before {
    content: "\f0ff8"
}

.mdi-om::before {
    content: "\f0973"
}

.mdi-omega::before {
    content: "\f03c9"
}

.mdi-one-up::before {
    content: "\f0bad"
}

.mdi-onepassword::before {
    content: "\f0881"
}

.mdi-opacity::before {
    content: "\f05cc"
}

.mdi-open-in-app::before {
    content: "\f03cb"
}

.mdi-open-in-new::before {
    content: "\f03cc"
}

.mdi-open-source-initiative::before {
    content: "\f0bae"
}

.mdi-openid::before {
    content: "\f03cd"
}

.mdi-opera::before {
    content: "\f03ce"
}

.mdi-orbit::before {
    content: "\f0018"
}

.mdi-orbit-variant::before {
    content: "\f15db"
}

.mdi-order-alphabetical-ascending::before {
    content: "\f020d"
}

.mdi-order-alphabetical-descending::before {
    content: "\f0d07"
}

.mdi-order-bool-ascending::before {
    content: "\f02be"
}

.mdi-order-bool-ascending-variant::before {
    content: "\f098f"
}

.mdi-order-bool-descending::before {
    content: "\f1384"
}

.mdi-order-bool-descending-variant::before {
    content: "\f0990"
}

.mdi-order-numeric-ascending::before {
    content: "\f0545"
}

.mdi-order-numeric-descending::before {
    content: "\f0546"
}

.mdi-origin::before {
    content: "\f0b43"
}

.mdi-ornament::before {
    content: "\f03cf"
}

.mdi-ornament-variant::before {
    content: "\f03d0"
}

.mdi-outdoor-lamp::before {
    content: "\f1054"
}

.mdi-overscan::before {
    content: "\f1005"
}

.mdi-owl::before {
    content: "\f03d2"
}

.mdi-pac-man::before {
    content: "\f0baf"
}

.mdi-package::before {
    content: "\f03d3"
}

.mdi-package-down::before {
    content: "\f03d4"
}

.mdi-package-up::before {
    content: "\f03d5"
}

.mdi-package-variant::before {
    content: "\f03d6"
}

.mdi-package-variant-closed::before {
    content: "\f03d7"
}

.mdi-page-first::before {
    content: "\f0600"
}

.mdi-page-last::before {
    content: "\f0601"
}

.mdi-page-layout-body::before {
    content: "\f06fa"
}

.mdi-page-layout-footer::before {
    content: "\f06fb"
}

.mdi-page-layout-header::before {
    content: "\f06fc"
}

.mdi-page-layout-header-footer::before {
    content: "\f0f7f"
}

.mdi-page-layout-sidebar-left::before {
    content: "\f06fd"
}

.mdi-page-layout-sidebar-right::before {
    content: "\f06fe"
}

.mdi-page-next::before {
    content: "\f0bb0"
}

.mdi-page-next-outline::before {
    content: "\f0bb1"
}

.mdi-page-previous::before {
    content: "\f0bb2"
}

.mdi-page-previous-outline::before {
    content: "\f0bb3"
}

.mdi-pail::before {
    content: "\f1417"
}

.mdi-pail-minus::before {
    content: "\f1437"
}

.mdi-pail-minus-outline::before {
    content: "\f143c"
}

.mdi-pail-off::before {
    content: "\f1439"
}

.mdi-pail-off-outline::before {
    content: "\f143e"
}

.mdi-pail-outline::before {
    content: "\f143a"
}

.mdi-pail-plus::before {
    content: "\f1436"
}

.mdi-pail-plus-outline::before {
    content: "\f143b"
}

.mdi-pail-remove::before {
    content: "\f1438"
}

.mdi-pail-remove-outline::before {
    content: "\f143d"
}

.mdi-palette::before {
    content: "\f03d8"
}

.mdi-palette-advanced::before {
    content: "\f03d9"
}

.mdi-palette-outline::before {
    content: "\f0e0c"
}

.mdi-palette-swatch::before {
    content: "\f08b5"
}

.mdi-palette-swatch-outline::before {
    content: "\f135c"
}

.mdi-palette-swatch-variant::before {
    content: "\f195a"
}

.mdi-palm-tree::before {
    content: "\f1055"
}

.mdi-pan::before {
    content: "\f0bb4"
}

.mdi-pan-bottom-left::before {
    content: "\f0bb5"
}

.mdi-pan-bottom-right::before {
    content: "\f0bb6"
}

.mdi-pan-down::before {
    content: "\f0bb7"
}

.mdi-pan-horizontal::before {
    content: "\f0bb8"
}

.mdi-pan-left::before {
    content: "\f0bb9"
}

.mdi-pan-right::before {
    content: "\f0bba"
}

.mdi-pan-top-left::before {
    content: "\f0bbb"
}

.mdi-pan-top-right::before {
    content: "\f0bbc"
}

.mdi-pan-up::before {
    content: "\f0bbd"
}

.mdi-pan-vertical::before {
    content: "\f0bbe"
}

.mdi-panda::before {
    content: "\f03da"
}

.mdi-pandora::before {
    content: "\f03db"
}

.mdi-panorama::before {
    content: "\f03dc"
}

.mdi-panorama-fisheye::before {
    content: "\f03dd"
}

.mdi-panorama-horizontal::before {
    content: "\f1928"
}

.mdi-panorama-horizontal-outline::before {
    content: "\f03de"
}

.mdi-panorama-outline::before {
    content: "\f198c"
}

.mdi-panorama-sphere::before {
    content: "\f198d"
}

.mdi-panorama-sphere-outline::before {
    content: "\f198e"
}

.mdi-panorama-variant::before {
    content: "\f198f"
}

.mdi-panorama-variant-outline::before {
    content: "\f1990"
}

.mdi-panorama-vertical::before {
    content: "\f1929"
}

.mdi-panorama-vertical-outline::before {
    content: "\f03df"
}

.mdi-panorama-wide-angle::before {
    content: "\f195f"
}

.mdi-panorama-wide-angle-outline::before {
    content: "\f03e0"
}

.mdi-paper-cut-vertical::before {
    content: "\f03e1"
}

.mdi-paper-roll::before {
    content: "\f1157"
}

.mdi-paper-roll-outline::before {
    content: "\f1158"
}

.mdi-paperclip::before {
    content: "\f03e2"
}

.mdi-parachute::before {
    content: "\f0cb4"
}

.mdi-parachute-outline::before {
    content: "\f0cb5"
}

.mdi-paragliding::before {
    content: "\f1745"
}

.mdi-parking::before {
    content: "\f03e3"
}

.mdi-party-popper::before {
    content: "\f1056"
}

.mdi-passport::before {
    content: "\f07e3"
}

.mdi-passport-biometric::before {
    content: "\f0de1"
}

.mdi-pasta::before {
    content: "\f1160"
}

.mdi-patio-heater::before {
    content: "\f0f80"
}

.mdi-patreon::before {
    content: "\f0882"
}

.mdi-pause::before {
    content: "\f03e4"
}

.mdi-pause-circle::before {
    content: "\f03e5"
}

.mdi-pause-circle-outline::before {
    content: "\f03e6"
}

.mdi-pause-octagon::before {
    content: "\f03e7"
}

.mdi-pause-octagon-outline::before {
    content: "\f03e8"
}

.mdi-paw::before {
    content: "\f03e9"
}

.mdi-paw-off::before {
    content: "\f0657"
}

.mdi-paw-off-outline::before {
    content: "\f1676"
}

.mdi-paw-outline::before {
    content: "\f1675"
}

.mdi-peace::before {
    content: "\f0884"
}

.mdi-peanut::before {
    content: "\f0ffc"
}

.mdi-peanut-off::before {
    content: "\f0ffd"
}

.mdi-peanut-off-outline::before {
    content: "\f0fff"
}

.mdi-peanut-outline::before {
    content: "\f0ffe"
}

.mdi-pen::before {
    content: "\f03ea"
}

.mdi-pen-lock::before {
    content: "\f0de2"
}

.mdi-pen-minus::before {
    content: "\f0de3"
}

.mdi-pen-off::before {
    content: "\f0de4"
}

.mdi-pen-plus::before {
    content: "\f0de5"
}

.mdi-pen-remove::before {
    content: "\f0de6"
}

.mdi-pencil::before {
    content: "\f03eb"
}

.mdi-pencil-box::before {
    content: "\f03ec"
}

.mdi-pencil-box-multiple::before {
    content: "\f1144"
}

.mdi-pencil-box-multiple-outline::before {
    content: "\f1145"
}

.mdi-pencil-box-outline::before {
    content: "\f03ed"
}

.mdi-pencil-circle::before {
    content: "\f06ff"
}

.mdi-pencil-circle-outline::before {
    content: "\f0776"
}

.mdi-pencil-lock::before {
    content: "\f03ee"
}

.mdi-pencil-lock-outline::before {
    content: "\f0de7"
}

.mdi-pencil-minus::before {
    content: "\f0de8"
}

.mdi-pencil-minus-outline::before {
    content: "\f0de9"
}

.mdi-pencil-off::before {
    content: "\f03ef"
}

.mdi-pencil-off-outline::before {
    content: "\f0dea"
}

.mdi-pencil-outline::before {
    content: "\f0cb6"
}

.mdi-pencil-plus::before {
    content: "\f0deb"
}

.mdi-pencil-plus-outline::before {
    content: "\f0dec"
}

.mdi-pencil-remove::before {
    content: "\f0ded"
}

.mdi-pencil-remove-outline::before {
    content: "\f0dee"
}

.mdi-pencil-ruler::before {
    content: "\f1353"
}

.mdi-penguin::before {
    content: "\f0ec0"
}

.mdi-pentagon::before {
    content: "\f0701"
}

.mdi-pentagon-outline::before {
    content: "\f0700"
}

.mdi-pentagram::before {
    content: "\f1667"
}

.mdi-percent::before {
    content: "\f03f0"
}

.mdi-percent-outline::before {
    content: "\f1278"
}

.mdi-periodic-table::before {
    content: "\f08b6"
}

.mdi-perspective-less::before {
    content: "\f0d23"
}

.mdi-perspective-more::before {
    content: "\f0d24"
}

.mdi-ph::before {
    content: "\f17c5"
}

.mdi-phone::before {
    content: "\f03f2"
}

.mdi-phone-alert::before {
    content: "\f0f1a"
}

.mdi-phone-alert-outline::before {
    content: "\f118e"
}

.mdi-phone-bluetooth::before {
    content: "\f03f3"
}

.mdi-phone-bluetooth-outline::before {
    content: "\f118f"
}

.mdi-phone-cancel::before {
    content: "\f10bc"
}

.mdi-phone-cancel-outline::before {
    content: "\f1190"
}

.mdi-phone-check::before {
    content: "\f11a9"
}

.mdi-phone-check-outline::before {
    content: "\f11aa"
}

.mdi-phone-classic::before {
    content: "\f0602"
}

.mdi-phone-classic-off::before {
    content: "\f1279"
}

.mdi-phone-dial::before {
    content: "\f1559"
}

.mdi-phone-dial-outline::before {
    content: "\f155a"
}

.mdi-phone-forward::before {
    content: "\f03f4"
}

.mdi-phone-forward-outline::before {
    content: "\f1191"
}

.mdi-phone-hangup::before {
    content: "\f03f5"
}

.mdi-phone-hangup-outline::before {
    content: "\f1192"
}

.mdi-phone-in-talk::before {
    content: "\f03f6"
}

.mdi-phone-in-talk-outline::before {
    content: "\f1182"
}

.mdi-phone-incoming::before {
    content: "\f03f7"
}

.mdi-phone-incoming-outline::before {
    content: "\f1193"
}

.mdi-phone-lock::before {
    content: "\f03f8"
}

.mdi-phone-lock-outline::before {
    content: "\f1194"
}

.mdi-phone-log::before {
    content: "\f03f9"
}

.mdi-phone-log-outline::before {
    content: "\f1195"
}

.mdi-phone-message::before {
    content: "\f1196"
}

.mdi-phone-message-outline::before {
    content: "\f1197"
}

.mdi-phone-minus::before {
    content: "\f0658"
}

.mdi-phone-minus-outline::before {
    content: "\f1198"
}

.mdi-phone-missed::before {
    content: "\f03fa"
}

.mdi-phone-missed-outline::before {
    content: "\f11a5"
}

.mdi-phone-off::before {
    content: "\f0def"
}

.mdi-phone-off-outline::before {
    content: "\f11a6"
}

.mdi-phone-outgoing::before {
    content: "\f03fb"
}

.mdi-phone-outgoing-outline::before {
    content: "\f1199"
}

.mdi-phone-outline::before {
    content: "\f0df0"
}

.mdi-phone-paused::before {
    content: "\f03fc"
}

.mdi-phone-paused-outline::before {
    content: "\f119a"
}

.mdi-phone-plus::before {
    content: "\f0659"
}

.mdi-phone-plus-outline::before {
    content: "\f119b"
}

.mdi-phone-refresh::before {
    content: "\f1993"
}

.mdi-phone-refresh-outline::before {
    content: "\f1994"
}

.mdi-phone-remove::before {
    content: "\f152f"
}

.mdi-phone-remove-outline::before {
    content: "\f1530"
}

.mdi-phone-return::before {
    content: "\f082f"
}

.mdi-phone-return-outline::before {
    content: "\f119c"
}

.mdi-phone-ring::before {
    content: "\f11ab"
}

.mdi-phone-ring-outline::before {
    content: "\f11ac"
}

.mdi-phone-rotate-landscape::before {
    content: "\f0885"
}

.mdi-phone-rotate-portrait::before {
    content: "\f0886"
}

.mdi-phone-settings::before {
    content: "\f03fd"
}

.mdi-phone-settings-outline::before {
    content: "\f119d"
}

.mdi-phone-sync::before {
    content: "\f1995"
}

.mdi-phone-sync-outline::before {
    content: "\f1996"
}

.mdi-phone-voip::before {
    content: "\f03fe"
}

.mdi-pi::before {
    content: "\f03ff"
}

.mdi-pi-box::before {
    content: "\f0400"
}

.mdi-pi-hole::before {
    content: "\f0df1"
}

.mdi-piano::before {
    content: "\f067d"
}

.mdi-piano-off::before {
    content: "\f0698"
}

.mdi-pickaxe::before {
    content: "\f08b7"
}

.mdi-picture-in-picture-bottom-right::before {
    content: "\f0e57"
}

.mdi-picture-in-picture-bottom-right-outline::before {
    content: "\f0e58"
}

.mdi-picture-in-picture-top-right::before {
    content: "\f0e59"
}

.mdi-picture-in-picture-top-right-outline::before {
    content: "\f0e5a"
}

.mdi-pier::before {
    content: "\f0887"
}

.mdi-pier-crane::before {
    content: "\f0888"
}

.mdi-pig::before {
    content: "\f0401"
}

.mdi-pig-variant::before {
    content: "\f1006"
}

.mdi-pig-variant-outline::before {
    content: "\f1678"
}

.mdi-piggy-bank::before {
    content: "\f1007"
}

.mdi-piggy-bank-outline::before {
    content: "\f1679"
}

.mdi-pill::before {
    content: "\f0402"
}

.mdi-pillar::before {
    content: "\f0702"
}

.mdi-pin::before {
    content: "\f0403"
}

.mdi-pin-off::before {
    content: "\f0404"
}

.mdi-pin-off-outline::before {
    content: "\f0930"
}

.mdi-pin-outline::before {
    content: "\f0931"
}

.mdi-pine-tree::before {
    content: "\f0405"
}

.mdi-pine-tree-box::before {
    content: "\f0406"
}

.mdi-pine-tree-fire::before {
    content: "\f141a"
}

.mdi-pinterest::before {
    content: "\f0407"
}

.mdi-pinwheel::before {
    content: "\f0ad5"
}

.mdi-pinwheel-outline::before {
    content: "\f0ad6"
}

.mdi-pipe::before {
    content: "\f07e5"
}

.mdi-pipe-disconnected::before {
    content: "\f07e6"
}

.mdi-pipe-leak::before {
    content: "\f0889"
}

.mdi-pipe-valve::before {
    content: "\f184d"
}

.mdi-pipe-wrench::before {
    content: "\f1354"
}

.mdi-pirate::before {
    content: "\f0a08"
}

.mdi-pistol::before {
    content: "\f0703"
}

.mdi-piston::before {
    content: "\f088a"
}

.mdi-pitchfork::before {
    content: "\f1553"
}

.mdi-pizza::before {
    content: "\f0409"
}

.mdi-play::before {
    content: "\f040a"
}

.mdi-play-box::before {
    content: "\f127a"
}

.mdi-play-box-multiple::before {
    content: "\f0d19"
}

.mdi-play-box-multiple-outline::before {
    content: "\f13e6"
}

.mdi-play-box-outline::before {
    content: "\f040b"
}

.mdi-play-circle::before {
    content: "\f040c"
}

.mdi-play-circle-outline::before {
    content: "\f040d"
}

.mdi-play-network::before {
    content: "\f088b"
}

.mdi-play-network-outline::before {
    content: "\f0cb7"
}

.mdi-play-outline::before {
    content: "\f0f1b"
}

.mdi-play-pause::before {
    content: "\f040e"
}

.mdi-play-protected-content::before {
    content: "\f040f"
}

.mdi-play-speed::before {
    content: "\f08ff"
}

.mdi-playlist-check::before {
    content: "\f05c7"
}

.mdi-playlist-edit::before {
    content: "\f0900"
}

.mdi-playlist-minus::before {
    content: "\f0410"
}

.mdi-playlist-music::before {
    content: "\f0cb8"
}

.mdi-playlist-music-outline::before {
    content: "\f0cb9"
}

.mdi-playlist-play::before {
    content: "\f0411"
}

.mdi-playlist-plus::before {
    content: "\f0412"
}

.mdi-playlist-remove::before {
    content: "\f0413"
}

.mdi-playlist-star::before {
    content: "\f0df2"
}

.mdi-plex::before {
    content: "\f06ba"
}

.mdi-pliers::before {
    content: "\f19a4"
}

.mdi-plus::before {
    content: "\f0415"
}

.mdi-plus-box::before {
    content: "\f0416"
}

.mdi-plus-box-multiple::before {
    content: "\f0334"
}

.mdi-plus-box-multiple-outline::before {
    content: "\f1143"
}

.mdi-plus-box-outline::before {
    content: "\f0704"
}

.mdi-plus-circle::before {
    content: "\f0417"
}

.mdi-plus-circle-multiple::before {
    content: "\f034c"
}

.mdi-plus-circle-multiple-outline::before {
    content: "\f0418"
}

.mdi-plus-circle-outline::before {
    content: "\f0419"
}

.mdi-plus-minus::before {
    content: "\f0992"
}

.mdi-plus-minus-box::before {
    content: "\f0993"
}

.mdi-plus-minus-variant::before {
    content: "\f14c9"
}

.mdi-plus-network::before {
    content: "\f041a"
}

.mdi-plus-network-outline::before {
    content: "\f0cba"
}

.mdi-plus-outline::before {
    content: "\f0705"
}

.mdi-plus-thick::before {
    content: "\f11ec"
}

.mdi-podcast::before {
    content: "\f0994"
}

.mdi-podium::before {
    content: "\f0d25"
}

.mdi-podium-bronze::before {
    content: "\f0d26"
}

.mdi-podium-gold::before {
    content: "\f0d27"
}

.mdi-podium-silver::before {
    content: "\f0d28"
}

.mdi-point-of-sale::before {
    content: "\f0d92"
}

.mdi-pokeball::before {
    content: "\f041d"
}

.mdi-pokemon-go::before {
    content: "\f0a09"
}

.mdi-poker-chip::before {
    content: "\f0830"
}

.mdi-polaroid::before {
    content: "\f041e"
}

.mdi-police-badge::before {
    content: "\f1167"
}

.mdi-police-badge-outline::before {
    content: "\f1168"
}

.mdi-police-station::before {
    content: "\f1839"
}

.mdi-poll::before {
    content: "\f041f"
}

.mdi-polo::before {
    content: "\f14c3"
}

.mdi-polymer::before {
    content: "\f0421"
}

.mdi-pool::before {
    content: "\f0606"
}

.mdi-popcorn::before {
    content: "\f0422"
}

.mdi-post::before {
    content: "\f1008"
}

.mdi-post-outline::before {
    content: "\f1009"
}

.mdi-postage-stamp::before {
    content: "\f0cbb"
}

.mdi-pot::before {
    content: "\f02e5"
}

.mdi-pot-mix::before {
    content: "\f065b"
}

.mdi-pot-mix-outline::before {
    content: "\f0677"
}

.mdi-pot-outline::before {
    content: "\f02ff"
}

.mdi-pot-steam::before {
    content: "\f065a"
}

.mdi-pot-steam-outline::before {
    content: "\f0326"
}

.mdi-pound::before {
    content: "\f0423"
}

.mdi-pound-box::before {
    content: "\f0424"
}

.mdi-pound-box-outline::before {
    content: "\f117f"
}

.mdi-power::before {
    content: "\f0425"
}

.mdi-power-cycle::before {
    content: "\f0901"
}

.mdi-power-off::before {
    content: "\f0902"
}

.mdi-power-on::before {
    content: "\f0903"
}

.mdi-power-plug::before {
    content: "\f06a5"
}

.mdi-power-plug-off::before {
    content: "\f06a6"
}

.mdi-power-plug-off-outline::before {
    content: "\f1424"
}

.mdi-power-plug-outline::before {
    content: "\f1425"
}

.mdi-power-settings::before {
    content: "\f0426"
}

.mdi-power-sleep::before {
    content: "\f0904"
}

.mdi-power-socket::before {
    content: "\f0427"
}

.mdi-power-socket-au::before {
    content: "\f0905"
}

.mdi-power-socket-ch::before {
    content: "\f0fb3"
}

.mdi-power-socket-de::before {
    content: "\f1107"
}

.mdi-power-socket-eu::before {
    content: "\f07e7"
}

.mdi-power-socket-fr::before {
    content: "\f1108"
}

.mdi-power-socket-it::before {
    content: "\f14ff"
}

.mdi-power-socket-jp::before {
    content: "\f1109"
}

.mdi-power-socket-uk::before {
    content: "\f07e8"
}

.mdi-power-socket-us::before {
    content: "\f07e9"
}

.mdi-power-standby::before {
    content: "\f0906"
}

.mdi-powershell::before {
    content: "\f0a0a"
}

.mdi-prescription::before {
    content: "\f0706"
}

.mdi-presentation::before {
    content: "\f0428"
}

.mdi-presentation-play::before {
    content: "\f0429"
}

.mdi-pretzel::before {
    content: "\f1562"
}

.mdi-printer::before {
    content: "\f042a"
}

.mdi-printer-3d::before {
    content: "\f042b"
}

.mdi-printer-3d-nozzle::before {
    content: "\f0e5b"
}

.mdi-printer-3d-nozzle-alert::before {
    content: "\f11c0"
}

.mdi-printer-3d-nozzle-alert-outline::before {
    content: "\f11c1"
}

.mdi-printer-3d-nozzle-heat::before {
    content: "\f18b8"
}

.mdi-printer-3d-nozzle-heat-outline::before {
    content: "\f18b9"
}

.mdi-printer-3d-nozzle-outline::before {
    content: "\f0e5c"
}

.mdi-printer-alert::before {
    content: "\f042c"
}

.mdi-printer-check::before {
    content: "\f1146"
}

.mdi-printer-eye::before {
    content: "\f1458"
}

.mdi-printer-off::before {
    content: "\f0e5d"
}

.mdi-printer-off-outline::before {
    content: "\f1785"
}

.mdi-printer-outline::before {
    content: "\f1786"
}

.mdi-printer-pos::before {
    content: "\f1057"
}

.mdi-printer-search::before {
    content: "\f1457"
}

.mdi-printer-settings::before {
    content: "\f0707"
}

.mdi-printer-wireless::before {
    content: "\f0a0b"
}

.mdi-priority-high::before {
    content: "\f0603"
}

.mdi-priority-low::before {
    content: "\f0604"
}

.mdi-professional-hexagon::before {
    content: "\f042d"
}

.mdi-progress-alert::before {
    content: "\f0cbc"
}

.mdi-progress-check::before {
    content: "\f0995"
}

.mdi-progress-clock::before {
    content: "\f0996"
}

.mdi-progress-close::before {
    content: "\f110a"
}

.mdi-progress-download::before {
    content: "\f0997"
}

.mdi-progress-pencil::before {
    content: "\f1787"
}

.mdi-progress-question::before {
    content: "\f1522"
}

.mdi-progress-star::before {
    content: "\f1788"
}

.mdi-progress-upload::before {
    content: "\f0998"
}

.mdi-progress-wrench::before {
    content: "\f0cbd"
}

.mdi-projector::before {
    content: "\f042e"
}

.mdi-projector-screen::before {
    content: "\f042f"
}

.mdi-projector-screen-off::before {
    content: "\f180d"
}

.mdi-projector-screen-off-outline::before {
    content: "\f180e"
}

.mdi-projector-screen-outline::before {
    content: "\f1724"
}

.mdi-projector-screen-variant::before {
    content: "\f180f"
}

.mdi-projector-screen-variant-off::before {
    content: "\f1810"
}

.mdi-projector-screen-variant-off-outline::before {
    content: "\f1811"
}

.mdi-projector-screen-variant-outline::before {
    content: "\f1812"
}

.mdi-propane-tank::before {
    content: "\f1357"
}

.mdi-propane-tank-outline::before {
    content: "\f1358"
}

.mdi-protocol::before {
    content: "\f0fd8"
}

.mdi-publish::before {
    content: "\f06a7"
}

.mdi-publish-off::before {
    content: "\f1945"
}

.mdi-pulse::before {
    content: "\f0430"
}

.mdi-pump::before {
    content: "\f1402"
}

.mdi-pumpkin::before {
    content: "\f0bbf"
}

.mdi-purse::before {
    content: "\f0f1c"
}

.mdi-purse-outline::before {
    content: "\f0f1d"
}

.mdi-puzzle::before {
    content: "\f0431"
}

.mdi-puzzle-check::before {
    content: "\f1426"
}

.mdi-puzzle-check-outline::before {
    content: "\f1427"
}

.mdi-puzzle-edit::before {
    content: "\f14d3"
}

.mdi-puzzle-edit-outline::before {
    content: "\f14d9"
}

.mdi-puzzle-heart::before {
    content: "\f14d4"
}

.mdi-puzzle-heart-outline::before {
    content: "\f14da"
}

.mdi-puzzle-minus::before {
    content: "\f14d1"
}

.mdi-puzzle-minus-outline::before {
    content: "\f14d7"
}

.mdi-puzzle-outline::before {
    content: "\f0a66"
}

.mdi-puzzle-plus::before {
    content: "\f14d0"
}

.mdi-puzzle-plus-outline::before {
    content: "\f14d6"
}

.mdi-puzzle-remove::before {
    content: "\f14d2"
}

.mdi-puzzle-remove-outline::before {
    content: "\f14d8"
}

.mdi-puzzle-star::before {
    content: "\f14d5"
}

.mdi-puzzle-star-outline::before {
    content: "\f14db"
}

.mdi-pyramid::before {
    content: "\f1952"
}

.mdi-pyramid-off::before {
    content: "\f1953"
}

.mdi-qi::before {
    content: "\f0999"
}

.mdi-qqchat::before {
    content: "\f0605"
}

.mdi-qrcode::before {
    content: "\f0432"
}

.mdi-qrcode-edit::before {
    content: "\f08b8"
}

.mdi-qrcode-minus::before {
    content: "\f118c"
}

.mdi-qrcode-plus::before {
    content: "\f118b"
}

.mdi-qrcode-remove::before {
    content: "\f118d"
}

.mdi-qrcode-scan::before {
    content: "\f0433"
}

.mdi-quadcopter::before {
    content: "\f0434"
}

.mdi-quality-high::before {
    content: "\f0435"
}

.mdi-quality-low::before {
    content: "\f0a0c"
}

.mdi-quality-medium::before {
    content: "\f0a0d"
}

.mdi-quora::before {
    content: "\f0d29"
}

.mdi-rabbit::before {
    content: "\f0907"
}

.mdi-racing-helmet::before {
    content: "\f0d93"
}

.mdi-racquetball::before {
    content: "\f0d94"
}

.mdi-radar::before {
    content: "\f0437"
}

.mdi-radiator::before {
    content: "\f0438"
}

.mdi-radiator-disabled::before {
    content: "\f0ad7"
}

.mdi-radiator-off::before {
    content: "\f0ad8"
}

.mdi-radio::before {
    content: "\f0439"
}

.mdi-radio-am::before {
    content: "\f0cbe"
}

.mdi-radio-fm::before {
    content: "\f0cbf"
}

.mdi-radio-handheld::before {
    content: "\f043a"
}

.mdi-radio-off::before {
    content: "\f121c"
}

.mdi-radio-tower::before {
    content: "\f043b"
}

.mdi-radioactive::before {
    content: "\f043c"
}

.mdi-radioactive-circle::before {
    content: "\f185d"
}

.mdi-radioactive-circle-outline::before {
    content: "\f185e"
}

.mdi-radioactive-off::before {
    content: "\f0ec1"
}

.mdi-radiobox-blank::before {
    content: "\f043d"
}

.mdi-radiobox-marked::before {
    content: "\f043e"
}

.mdi-radiology-box::before {
    content: "\f14c5"
}

.mdi-radiology-box-outline::before {
    content: "\f14c6"
}

.mdi-radius::before {
    content: "\f0cc0"
}

.mdi-radius-outline::before {
    content: "\f0cc1"
}

.mdi-railroad-light::before {
    content: "\f0f1e"
}

.mdi-rake::before {
    content: "\f1544"
}

.mdi-raspberry-pi::before {
    content: "\f043f"
}

.mdi-ray-end::before {
    content: "\f0440"
}

.mdi-ray-end-arrow::before {
    content: "\f0441"
}

.mdi-ray-start::before {
    content: "\f0442"
}

.mdi-ray-start-arrow::before {
    content: "\f0443"
}

.mdi-ray-start-end::before {
    content: "\f0444"
}

.mdi-ray-start-vertex-end::before {
    content: "\f15d8"
}

.mdi-ray-vertex::before {
    content: "\f0445"
}

.mdi-razor-double-edge::before {
    content: "\f1997"
}

.mdi-razor-single-edge::before {
    content: "\f1998"
}

.mdi-react::before {
    content: "\f0708"
}

.mdi-read::before {
    content: "\f0447"
}

.mdi-receipt::before {
    content: "\f0449"
}

.mdi-record::before {
    content: "\f044a"
}

.mdi-record-circle::before {
    content: "\f0ec2"
}

.mdi-record-circle-outline::before {
    content: "\f0ec3"
}

.mdi-record-player::before {
    content: "\f099a"
}

.mdi-record-rec::before {
    content: "\f044b"
}

.mdi-rectangle::before {
    content: "\f0e5e"
}

.mdi-rectangle-outline::before {
    content: "\f0e5f"
}

.mdi-recycle::before {
    content: "\f044c"
}

.mdi-recycle-variant::before {
    content: "\f139d"
}

.mdi-reddit::before {
    content: "\f044d"
}

.mdi-redhat::before {
    content: "\f111b"
}

.mdi-redo::before {
    content: "\f044e"
}

.mdi-redo-variant::before {
    content: "\f044f"
}

.mdi-reflect-horizontal::before {
    content: "\f0a0e"
}

.mdi-reflect-vertical::before {
    content: "\f0a0f"
}

.mdi-refresh::before {
    content: "\f0450"
}

.mdi-refresh-auto::before {
    content: "\f18f2"
}

.mdi-refresh-circle::before {
    content: "\f1377"
}

.mdi-regex::before {
    content: "\f0451"
}

.mdi-registered-trademark::before {
    content: "\f0a67"
}

.mdi-reiterate::before {
    content: "\f1588"
}

.mdi-relation-many-to-many::before {
    content: "\f1496"
}

.mdi-relation-many-to-one::before {
    content: "\f1497"
}

.mdi-relation-many-to-one-or-many::before {
    content: "\f1498"
}

.mdi-relation-many-to-only-one::before {
    content: "\f1499"
}

.mdi-relation-many-to-zero-or-many::before {
    content: "\f149a"
}

.mdi-relation-many-to-zero-or-one::before {
    content: "\f149b"
}

.mdi-relation-one-or-many-to-many::before {
    content: "\f149c"
}

.mdi-relation-one-or-many-to-one::before {
    content: "\f149d"
}

.mdi-relation-one-or-many-to-one-or-many::before {
    content: "\f149e"
}

.mdi-relation-one-or-many-to-only-one::before {
    content: "\f149f"
}

.mdi-relation-one-or-many-to-zero-or-many::before {
    content: "\f14a0"
}

.mdi-relation-one-or-many-to-zero-or-one::before {
    content: "\f14a1"
}

.mdi-relation-one-to-many::before {
    content: "\f14a2"
}

.mdi-relation-one-to-one::before {
    content: "\f14a3"
}

.mdi-relation-one-to-one-or-many::before {
    content: "\f14a4"
}

.mdi-relation-one-to-only-one::before {
    content: "\f14a5"
}

.mdi-relation-one-to-zero-or-many::before {
    content: "\f14a6"
}

.mdi-relation-one-to-zero-or-one::before {
    content: "\f14a7"
}

.mdi-relation-only-one-to-many::before {
    content: "\f14a8"
}

.mdi-relation-only-one-to-one::before {
    content: "\f14a9"
}

.mdi-relation-only-one-to-one-or-many::before {
    content: "\f14aa"
}

.mdi-relation-only-one-to-only-one::before {
    content: "\f14ab"
}

.mdi-relation-only-one-to-zero-or-many::before {
    content: "\f14ac"
}

.mdi-relation-only-one-to-zero-or-one::before {
    content: "\f14ad"
}

.mdi-relation-zero-or-many-to-many::before {
    content: "\f14ae"
}

.mdi-relation-zero-or-many-to-one::before {
    content: "\f14af"
}

.mdi-relation-zero-or-many-to-one-or-many::before {
    content: "\f14b0"
}

.mdi-relation-zero-or-many-to-only-one::before {
    content: "\f14b1"
}

.mdi-relation-zero-or-many-to-zero-or-many::before {
    content: "\f14b2"
}

.mdi-relation-zero-or-many-to-zero-or-one::before {
    content: "\f14b3"
}

.mdi-relation-zero-or-one-to-many::before {
    content: "\f14b4"
}

.mdi-relation-zero-or-one-to-one::before {
    content: "\f14b5"
}

.mdi-relation-zero-or-one-to-one-or-many::before {
    content: "\f14b6"
}

.mdi-relation-zero-or-one-to-only-one::before {
    content: "\f14b7"
}

.mdi-relation-zero-or-one-to-zero-or-many::before {
    content: "\f14b8"
}

.mdi-relation-zero-or-one-to-zero-or-one::before {
    content: "\f14b9"
}

.mdi-relative-scale::before {
    content: "\f0452"
}

.mdi-reload::before {
    content: "\f0453"
}

.mdi-reload-alert::before {
    content: "\f110b"
}

.mdi-reminder::before {
    content: "\f088c"
}

.mdi-remote::before {
    content: "\f0454"
}

.mdi-remote-desktop::before {
    content: "\f08b9"
}

.mdi-remote-off::before {
    content: "\f0ec4"
}

.mdi-remote-tv::before {
    content: "\f0ec5"
}

.mdi-remote-tv-off::before {
    content: "\f0ec6"
}

.mdi-rename-box::before {
    content: "\f0455"
}

.mdi-reorder-horizontal::before {
    content: "\f0688"
}

.mdi-reorder-vertical::before {
    content: "\f0689"
}

.mdi-repeat::before {
    content: "\f0456"
}

.mdi-repeat-off::before {
    content: "\f0457"
}

.mdi-repeat-once::before {
    content: "\f0458"
}

.mdi-repeat-variant::before {
    content: "\f0547"
}

.mdi-replay::before {
    content: "\f0459"
}

.mdi-reply::before {
    content: "\f045a"
}

.mdi-reply-all::before {
    content: "\f045b"
}

.mdi-reply-all-outline::before {
    content: "\f0f1f"
}

.mdi-reply-circle::before {
    content: "\f11ae"
}

.mdi-reply-outline::before {
    content: "\f0f20"
}

.mdi-reproduction::before {
    content: "\f045c"
}

.mdi-resistor::before {
    content: "\f0b44"
}

.mdi-resistor-nodes::before {
    content: "\f0b45"
}

.mdi-resize::before {
    content: "\f0a68"
}

.mdi-resize-bottom-right::before {
    content: "\f045d"
}

.mdi-responsive::before {
    content: "\f045e"
}

.mdi-restart::before {
    content: "\f0709"
}

.mdi-restart-alert::before {
    content: "\f110c"
}

.mdi-restart-off::before {
    content: "\f0d95"
}

.mdi-restore::before {
    content: "\f099b"
}

.mdi-restore-alert::before {
    content: "\f110d"
}

.mdi-rewind::before {
    content: "\f045f"
}

.mdi-rewind-10::before {
    content: "\f0d2a"
}

.mdi-rewind-15::before {
    content: "\f1946"
}

.mdi-rewind-30::before {
    content: "\f0d96"
}

.mdi-rewind-5::before {
    content: "\f11f9"
}

.mdi-rewind-60::before {
    content: "\f160c"
}

.mdi-rewind-outline::before {
    content: "\f070a"
}

.mdi-rhombus::before {
    content: "\f070b"
}

.mdi-rhombus-medium::before {
    content: "\f0a10"
}

.mdi-rhombus-medium-outline::before {
    content: "\f14dc"
}

.mdi-rhombus-outline::before {
    content: "\f070c"
}

.mdi-rhombus-split::before {
    content: "\f0a11"
}

.mdi-rhombus-split-outline::before {
    content: "\f14dd"
}

.mdi-ribbon::before {
    content: "\f0460"
}

.mdi-rice::before {
    content: "\f07ea"
}

.mdi-rickshaw::before {
    content: "\f15bb"
}

.mdi-rickshaw-electric::before {
    content: "\f15bc"
}

.mdi-ring::before {
    content: "\f07eb"
}

.mdi-rivet::before {
    content: "\f0e60"
}

.mdi-road::before {
    content: "\f0461"
}

.mdi-road-variant::before {
    content: "\f0462"
}

.mdi-robber::before {
    content: "\f1058"
}

.mdi-robot::before {
    content: "\f06a9"
}

.mdi-robot-angry::before {
    content: "\f169d"
}

.mdi-robot-angry-outline::before {
    content: "\f169e"
}

.mdi-robot-confused::before {
    content: "\f169f"
}

.mdi-robot-confused-outline::before {
    content: "\f16a0"
}

.mdi-robot-dead::before {
    content: "\f16a1"
}

.mdi-robot-dead-outline::before {
    content: "\f16a2"
}

.mdi-robot-excited::before {
    content: "\f16a3"
}

.mdi-robot-excited-outline::before {
    content: "\f16a4"
}

.mdi-robot-happy::before {
    content: "\f1719"
}

.mdi-robot-happy-outline::before {
    content: "\f171a"
}

.mdi-robot-industrial::before {
    content: "\f0b46"
}

.mdi-robot-love::before {
    content: "\f16a5"
}

.mdi-robot-love-outline::before {
    content: "\f16a6"
}

.mdi-robot-mower::before {
    content: "\f11f7"
}

.mdi-robot-mower-outline::before {
    content: "\f11f3"
}

.mdi-robot-off::before {
    content: "\f16a7"
}

.mdi-robot-off-outline::before {
    content: "\f167b"
}

.mdi-robot-outline::before {
    content: "\f167a"
}

.mdi-robot-vacuum::before {
    content: "\f070d"
}

.mdi-robot-vacuum-variant::before {
    content: "\f0908"
}

.mdi-rocket::before {
    content: "\f0463"
}

.mdi-rocket-launch::before {
    content: "\f14de"
}

.mdi-rocket-launch-outline::before {
    content: "\f14df"
}

.mdi-rocket-outline::before {
    content: "\f13af"
}

.mdi-rodent::before {
    content: "\f1327"
}

.mdi-roller-skate::before {
    content: "\f0d2b"
}

.mdi-roller-skate-off::before {
    content: "\f0145"
}

.mdi-rollerblade::before {
    content: "\f0d2c"
}

.mdi-rollerblade-off::before {
    content: "\f002e"
}

.mdi-rollupjs::before {
    content: "\f0bc0"
}

.mdi-roman-numeral-1::before {
    content: "\f1088"
}

.mdi-roman-numeral-10::before {
    content: "\f1091"
}

.mdi-roman-numeral-2::before {
    content: "\f1089"
}

.mdi-roman-numeral-3::before {
    content: "\f108a"
}

.mdi-roman-numeral-4::before {
    content: "\f108b"
}

.mdi-roman-numeral-5::before {
    content: "\f108c"
}

.mdi-roman-numeral-6::before {
    content: "\f108d"
}

.mdi-roman-numeral-7::before {
    content: "\f108e"
}

.mdi-roman-numeral-8::before {
    content: "\f108f"
}

.mdi-roman-numeral-9::before {
    content: "\f1090"
}

.mdi-room-service::before {
    content: "\f088d"
}

.mdi-room-service-outline::before {
    content: "\f0d97"
}

.mdi-rotate-360::before {
    content: "\f1999"
}

.mdi-rotate-3d::before {
    content: "\f0ec7"
}

.mdi-rotate-3d-variant::before {
    content: "\f0464"
}

.mdi-rotate-left::before {
    content: "\f0465"
}

.mdi-rotate-left-variant::before {
    content: "\f0466"
}

.mdi-rotate-orbit::before {
    content: "\f0d98"
}

.mdi-rotate-right::before {
    content: "\f0467"
}

.mdi-rotate-right-variant::before {
    content: "\f0468"
}

.mdi-rounded-corner::before {
    content: "\f0607"
}

.mdi-router::before {
    content: "\f11e2"
}

.mdi-router-network::before {
    content: "\f1087"
}

.mdi-router-wireless::before {
    content: "\f0469"
}

.mdi-router-wireless-off::before {
    content: "\f15a3"
}

.mdi-router-wireless-settings::before {
    content: "\f0a69"
}

.mdi-routes::before {
    content: "\f046a"
}

.mdi-routes-clock::before {
    content: "\f1059"
}

.mdi-rowing::before {
    content: "\f0608"
}

.mdi-rss::before {
    content: "\f046b"
}

.mdi-rss-box::before {
    content: "\f046c"
}

.mdi-rss-off::before {
    content: "\f0f21"
}

.mdi-rug::before {
    content: "\f1475"
}

.mdi-rugby::before {
    content: "\f0d99"
}

.mdi-ruler::before {
    content: "\f046d"
}

.mdi-ruler-square::before {
    content: "\f0cc2"
}

.mdi-ruler-square-compass::before {
    content: "\f0ebe"
}

.mdi-run::before {
    content: "\f070e"
}

.mdi-run-fast::before {
    content: "\f046e"
}

.mdi-rv-truck::before {
    content: "\f11d4"
}

.mdi-sack::before {
    content: "\f0d2e"
}

.mdi-sack-percent::before {
    content: "\f0d2f"
}

.mdi-safe::before {
    content: "\f0a6a"
}

.mdi-safe-square::before {
    content: "\f127c"
}

.mdi-safe-square-outline::before {
    content: "\f127d"
}

.mdi-safety-goggles::before {
    content: "\f0d30"
}

.mdi-sail-boat::before {
    content: "\f0ec8"
}

.mdi-sale::before {
    content: "\f046f"
}

.mdi-salesforce::before {
    content: "\f088e"
}

.mdi-sass::before {
    content: "\f07ec"
}

.mdi-satellite::before {
    content: "\f0470"
}

.mdi-satellite-uplink::before {
    content: "\f0909"
}

.mdi-satellite-variant::before {
    content: "\f0471"
}

.mdi-sausage::before {
    content: "\f08ba"
}

.mdi-sausage-off::before {
    content: "\f1789"
}

.mdi-saw-blade::before {
    content: "\f0e61"
}

.mdi-sawtooth-wave::before {
    content: "\f147a"
}

.mdi-saxophone::before {
    content: "\f0609"
}

.mdi-scale::before {
    content: "\f0472"
}

.mdi-scale-balance::before {
    content: "\f05d1"
}

.mdi-scale-bathroom::before {
    content: "\f0473"
}

.mdi-scale-off::before {
    content: "\f105a"
}

.mdi-scale-unbalanced::before {
    content: "\f19b8"
}

.mdi-scan-helper::before {
    content: "\f13d8"
}

.mdi-scanner::before {
    content: "\f06ab"
}

.mdi-scanner-off::before {
    content: "\f090a"
}

.mdi-scatter-plot::before {
    content: "\f0ec9"
}

.mdi-scatter-plot-outline::before {
    content: "\f0eca"
}

.mdi-scent::before {
    content: "\f1958"
}

.mdi-scent-off::before {
    content: "\f1959"
}

.mdi-school::before {
    content: "\f0474"
}

.mdi-school-outline::before {
    content: "\f1180"
}

.mdi-scissors-cutting::before {
    content: "\f0a6b"
}

.mdi-scooter::before {
    content: "\f15bd"
}

.mdi-scooter-electric::before {
    content: "\f15be"
}

.mdi-scoreboard::before {
    content: "\f127e"
}

.mdi-scoreboard-outline::before {
    content: "\f127f"
}

.mdi-screen-rotation::before {
    content: "\f0475"
}

.mdi-screen-rotation-lock::before {
    content: "\f0478"
}

.mdi-screw-flat-top::before {
    content: "\f0df3"
}

.mdi-screw-lag::before {
    content: "\f0df4"
}

.mdi-screw-machine-flat-top::before {
    content: "\f0df5"
}

.mdi-screw-machine-round-top::before {
    content: "\f0df6"
}

.mdi-screw-round-top::before {
    content: "\f0df7"
}

.mdi-screwdriver::before {
    content: "\f0476"
}

.mdi-script::before {
    content: "\f0bc1"
}

.mdi-script-outline::before {
    content: "\f0477"
}

.mdi-script-text::before {
    content: "\f0bc2"
}

.mdi-script-text-key::before {
    content: "\f1725"
}

.mdi-script-text-key-outline::before {
    content: "\f1726"
}

.mdi-script-text-outline::before {
    content: "\f0bc3"
}

.mdi-script-text-play::before {
    content: "\f1727"
}

.mdi-script-text-play-outline::before {
    content: "\f1728"
}

.mdi-sd::before {
    content: "\f0479"
}

.mdi-seal::before {
    content: "\f047a"
}

.mdi-seal-variant::before {
    content: "\f0fd9"
}

.mdi-search-web::before {
    content: "\f070f"
}

.mdi-seat::before {
    content: "\f0cc3"
}

.mdi-seat-flat::before {
    content: "\f047b"
}

.mdi-seat-flat-angled::before {
    content: "\f047c"
}

.mdi-seat-individual-suite::before {
    content: "\f047d"
}

.mdi-seat-legroom-extra::before {
    content: "\f047e"
}

.mdi-seat-legroom-normal::before {
    content: "\f047f"
}

.mdi-seat-legroom-reduced::before {
    content: "\f0480"
}

.mdi-seat-outline::before {
    content: "\f0cc4"
}

.mdi-seat-passenger::before {
    content: "\f1249"
}

.mdi-seat-recline-extra::before {
    content: "\f0481"
}

.mdi-seat-recline-normal::before {
    content: "\f0482"
}

.mdi-seatbelt::before {
    content: "\f0cc5"
}

.mdi-security::before {
    content: "\f0483"
}

.mdi-security-network::before {
    content: "\f0484"
}

.mdi-seed::before {
    content: "\f0e62"
}

.mdi-seed-off::before {
    content: "\f13fd"
}

.mdi-seed-off-outline::before {
    content: "\f13fe"
}

.mdi-seed-outline::before {
    content: "\f0e63"
}

.mdi-seesaw::before {
    content: "\f15a4"
}

.mdi-segment::before {
    content: "\f0ecb"
}

.mdi-select::before {
    content: "\f0485"
}

.mdi-select-all::before {
    content: "\f0486"
}

.mdi-select-color::before {
    content: "\f0d31"
}

.mdi-select-compare::before {
    content: "\f0ad9"
}

.mdi-select-drag::before {
    content: "\f0a6c"
}

.mdi-select-group::before {
    content: "\f0f82"
}

.mdi-select-inverse::before {
    content: "\f0487"
}

.mdi-select-marker::before {
    content: "\f1280"
}

.mdi-select-multiple::before {
    content: "\f1281"
}

.mdi-select-multiple-marker::before {
    content: "\f1282"
}

.mdi-select-off::before {
    content: "\f0488"
}

.mdi-select-place::before {
    content: "\f0fda"
}

.mdi-select-remove::before {
    content: "\f17c1"
}

.mdi-select-search::before {
    content: "\f1204"
}

.mdi-selection::before {
    content: "\f0489"
}

.mdi-selection-drag::before {
    content: "\f0a6d"
}

.mdi-selection-ellipse::before {
    content: "\f0d32"
}

.mdi-selection-ellipse-arrow-inside::before {
    content: "\f0f22"
}

.mdi-selection-ellipse-remove::before {
    content: "\f17c2"
}

.mdi-selection-marker::before {
    content: "\f1283"
}

.mdi-selection-multiple::before {
    content: "\f1285"
}

.mdi-selection-multiple-marker::before {
    content: "\f1284"
}

.mdi-selection-off::before {
    content: "\f0777"
}

.mdi-selection-remove::before {
    content: "\f17c3"
}

.mdi-selection-search::before {
    content: "\f1205"
}

.mdi-semantic-web::before {
    content: "\f1316"
}

.mdi-send::before {
    content: "\f048a"
}

.mdi-send-check::before {
    content: "\f1161"
}

.mdi-send-check-outline::before {
    content: "\f1162"
}

.mdi-send-circle::before {
    content: "\f0df8"
}

.mdi-send-circle-outline::before {
    content: "\f0df9"
}

.mdi-send-clock::before {
    content: "\f1163"
}

.mdi-send-clock-outline::before {
    content: "\f1164"
}

.mdi-send-lock::before {
    content: "\f07ed"
}

.mdi-send-lock-outline::before {
    content: "\f1166"
}

.mdi-send-outline::before {
    content: "\f1165"
}

.mdi-serial-port::before {
    content: "\f065c"
}

.mdi-server::before {
    content: "\f048b"
}

.mdi-server-minus::before {
    content: "\f048c"
}

.mdi-server-network::before {
    content: "\f048d"
}

.mdi-server-network-off::before {
    content: "\f048e"
}

.mdi-server-off::before {
    content: "\f048f"
}

.mdi-server-plus::before {
    content: "\f0490"
}

.mdi-server-remove::before {
    content: "\f0491"
}

.mdi-server-security::before {
    content: "\f0492"
}

.mdi-set-all::before {
    content: "\f0778"
}

.mdi-set-center::before {
    content: "\f0779"
}

.mdi-set-center-right::before {
    content: "\f077a"
}

.mdi-set-left::before {
    content: "\f077b"
}

.mdi-set-left-center::before {
    content: "\f077c"
}

.mdi-set-left-right::before {
    content: "\f077d"
}

.mdi-set-merge::before {
    content: "\f14e0"
}

.mdi-set-none::before {
    content: "\f077e"
}

.mdi-set-right::before {
    content: "\f077f"
}

.mdi-set-split::before {
    content: "\f14e1"
}

.mdi-set-square::before {
    content: "\f145d"
}

.mdi-set-top-box::before {
    content: "\f099f"
}

.mdi-settings-helper::before {
    content: "\f0a6e"
}

.mdi-shaker::before {
    content: "\f110e"
}

.mdi-shaker-outline::before {
    content: "\f110f"
}

.mdi-shape::before {
    content: "\f0831"
}

.mdi-shape-circle-plus::before {
    content: "\f065d"
}

.mdi-shape-outline::before {
    content: "\f0832"
}

.mdi-shape-oval-plus::before {
    content: "\f11fa"
}

.mdi-shape-plus::before {
    content: "\f0495"
}

.mdi-shape-polygon-plus::before {
    content: "\f065e"
}

.mdi-shape-rectangle-plus::before {
    content: "\f065f"
}

.mdi-shape-square-plus::before {
    content: "\f0660"
}

.mdi-shape-square-rounded-plus::before {
    content: "\f14fa"
}

.mdi-share::before {
    content: "\f0496"
}

.mdi-share-all::before {
    content: "\f11f4"
}

.mdi-share-all-outline::before {
    content: "\f11f5"
}

.mdi-share-circle::before {
    content: "\f11ad"
}

.mdi-share-off::before {
    content: "\f0f23"
}

.mdi-share-off-outline::before {
    content: "\f0f24"
}

.mdi-share-outline::before {
    content: "\f0932"
}

.mdi-share-variant::before {
    content: "\f0497"
}

.mdi-share-variant-outline::before {
    content: "\f1514"
}

.mdi-shark::before {
    content: "\f18ba"
}

.mdi-shark-fin::before {
    content: "\f1673"
}

.mdi-shark-fin-outline::before {
    content: "\f1674"
}

.mdi-shark-off::before {
    content: "\f18bb"
}

.mdi-sheep::before {
    content: "\f0cc6"
}

.mdi-shield::before {
    content: "\f0498"
}

.mdi-shield-account::before {
    content: "\f088f"
}

.mdi-shield-account-outline::before {
    content: "\f0a12"
}

.mdi-shield-account-variant::before {
    content: "\f15a7"
}

.mdi-shield-account-variant-outline::before {
    content: "\f15a8"
}

.mdi-shield-airplane::before {
    content: "\f06bb"
}

.mdi-shield-airplane-outline::before {
    content: "\f0cc7"
}

.mdi-shield-alert::before {
    content: "\f0ecc"
}

.mdi-shield-alert-outline::before {
    content: "\f0ecd"
}

.mdi-shield-bug::before {
    content: "\f13da"
}

.mdi-shield-bug-outline::before {
    content: "\f13db"
}

.mdi-shield-car::before {
    content: "\f0f83"
}

.mdi-shield-check::before {
    content: "\f0565"
}

.mdi-shield-check-outline::before {
    content: "\f0cc8"
}

.mdi-shield-cross::before {
    content: "\f0cc9"
}

.mdi-shield-cross-outline::before {
    content: "\f0cca"
}

.mdi-shield-crown::before {
    content: "\f18bc"
}

.mdi-shield-crown-outline::before {
    content: "\f18bd"
}

.mdi-shield-edit::before {
    content: "\f11a0"
}

.mdi-shield-edit-outline::before {
    content: "\f11a1"
}

.mdi-shield-half::before {
    content: "\f1360"
}

.mdi-shield-half-full::before {
    content: "\f0780"
}

.mdi-shield-home::before {
    content: "\f068a"
}

.mdi-shield-home-outline::before {
    content: "\f0ccb"
}

.mdi-shield-key::before {
    content: "\f0bc4"
}

.mdi-shield-key-outline::before {
    content: "\f0bc5"
}

.mdi-shield-link-variant::before {
    content: "\f0d33"
}

.mdi-shield-link-variant-outline::before {
    content: "\f0d34"
}

.mdi-shield-lock::before {
    content: "\f099d"
}

.mdi-shield-lock-open::before {
    content: "\f199a"
}

.mdi-shield-lock-open-outline::before {
    content: "\f199b"
}

.mdi-shield-lock-outline::before {
    content: "\f0ccc"
}

.mdi-shield-moon::before {
    content: "\f1828"
}

.mdi-shield-moon-outline::before {
    content: "\f1829"
}

.mdi-shield-off::before {
    content: "\f099e"
}

.mdi-shield-off-outline::before {
    content: "\f099c"
}

.mdi-shield-outline::before {
    content: "\f0499"
}

.mdi-shield-plus::before {
    content: "\f0ada"
}

.mdi-shield-plus-outline::before {
    content: "\f0adb"
}

.mdi-shield-refresh::before {
    content: "\f00aa"
}

.mdi-shield-refresh-outline::before {
    content: "\f01e0"
}

.mdi-shield-remove::before {
    content: "\f0adc"
}

.mdi-shield-remove-outline::before {
    content: "\f0add"
}

.mdi-shield-search::before {
    content: "\f0d9a"
}

.mdi-shield-star::before {
    content: "\f113b"
}

.mdi-shield-star-outline::before {
    content: "\f113c"
}

.mdi-shield-sun::before {
    content: "\f105d"
}

.mdi-shield-sun-outline::before {
    content: "\f105e"
}

.mdi-shield-sword::before {
    content: "\f18be"
}

.mdi-shield-sword-outline::before {
    content: "\f18bf"
}

.mdi-shield-sync::before {
    content: "\f11a2"
}

.mdi-shield-sync-outline::before {
    content: "\f11a3"
}

.mdi-shimmer::before {
    content: "\f1545"
}

.mdi-ship-wheel::before {
    content: "\f0833"
}

.mdi-shipping-pallet::before {
    content: "\f184e"
}

.mdi-shoe-ballet::before {
    content: "\f15ca"
}

.mdi-shoe-cleat::before {
    content: "\f15c7"
}

.mdi-shoe-formal::before {
    content: "\f0b47"
}

.mdi-shoe-heel::before {
    content: "\f0b48"
}

.mdi-shoe-print::before {
    content: "\f0dfa"
}

.mdi-shoe-sneaker::before {
    content: "\f15c8"
}

.mdi-shopping::before {
    content: "\f049a"
}

.mdi-shopping-music::before {
    content: "\f049b"
}

.mdi-shopping-outline::before {
    content: "\f11d5"
}

.mdi-shopping-search::before {
    content: "\f0f84"
}

.mdi-shore::before {
    content: "\f14f9"
}

.mdi-shovel::before {
    content: "\f0710"
}

.mdi-shovel-off::before {
    content: "\f0711"
}

.mdi-shower::before {
    content: "\f09a0"
}

.mdi-shower-head::before {
    content: "\f09a1"
}

.mdi-shredder::before {
    content: "\f049c"
}

.mdi-shuffle::before {
    content: "\f049d"
}

.mdi-shuffle-disabled::before {
    content: "\f049e"
}

.mdi-shuffle-variant::before {
    content: "\f049f"
}

.mdi-shuriken::before {
    content: "\f137f"
}

.mdi-sickle::before {
    content: "\f18c0"
}

.mdi-sigma::before {
    content: "\f04a0"
}

.mdi-sigma-lower::before {
    content: "\f062b"
}

.mdi-sign-caution::before {
    content: "\f04a1"
}

.mdi-sign-direction::before {
    content: "\f0781"
}

.mdi-sign-direction-minus::before {
    content: "\f1000"
}

.mdi-sign-direction-plus::before {
    content: "\f0fdc"
}

.mdi-sign-direction-remove::before {
    content: "\f0fdd"
}

.mdi-sign-pole::before {
    content: "\f14f8"
}

.mdi-sign-real-estate::before {
    content: "\f1118"
}

.mdi-sign-text::before {
    content: "\f0782"
}

.mdi-signal::before {
    content: "\f04a2"
}

.mdi-signal-2g::before {
    content: "\f0712"
}

.mdi-signal-3g::before {
    content: "\f0713"
}

.mdi-signal-4g::before {
    content: "\f0714"
}

.mdi-signal-5g::before {
    content: "\f0a6f"
}

.mdi-signal-cellular-1::before {
    content: "\f08bc"
}

.mdi-signal-cellular-2::before {
    content: "\f08bd"
}

.mdi-signal-cellular-3::before {
    content: "\f08be"
}

.mdi-signal-cellular-outline::before {
    content: "\f08bf"
}

.mdi-signal-distance-variant::before {
    content: "\f0e64"
}

.mdi-signal-hspa::before {
    content: "\f0715"
}

.mdi-signal-hspa-plus::before {
    content: "\f0716"
}

.mdi-signal-off::before {
    content: "\f0783"
}

.mdi-signal-variant::before {
    content: "\f060a"
}

.mdi-signature::before {
    content: "\f0dfb"
}

.mdi-signature-freehand::before {
    content: "\f0dfc"
}

.mdi-signature-image::before {
    content: "\f0dfd"
}

.mdi-signature-text::before {
    content: "\f0dfe"
}

.mdi-silo::before {
    content: "\f0b49"
}

.mdi-silverware::before {
    content: "\f04a3"
}

.mdi-silverware-clean::before {
    content: "\f0fde"
}

.mdi-silverware-fork::before {
    content: "\f04a4"
}

.mdi-silverware-fork-knife::before {
    content: "\f0a70"
}

.mdi-silverware-spoon::before {
    content: "\f04a5"
}

.mdi-silverware-variant::before {
    content: "\f04a6"
}

.mdi-sim::before {
    content: "\f04a7"
}

.mdi-sim-alert::before {
    content: "\f04a8"
}

.mdi-sim-alert-outline::before {
    content: "\f15d3"
}

.mdi-sim-off::before {
    content: "\f04a9"
}

.mdi-sim-off-outline::before {
    content: "\f15d4"
}

.mdi-sim-outline::before {
    content: "\f15d5"
}

.mdi-simple-icons::before {
    content: "\f131d"
}

.mdi-sina-weibo::before {
    content: "\f0adf"
}

.mdi-sine-wave::before {
    content: "\f095b"
}

.mdi-sitemap::before {
    content: "\f04aa"
}

.mdi-sitemap-outline::before {
    content: "\f199c"
}

.mdi-size-l::before {
    content: "\f13a6"
}

.mdi-size-m::before {
    content: "\f13a5"
}

.mdi-size-s::before {
    content: "\f13a4"
}

.mdi-size-xl::before {
    content: "\f13a7"
}

.mdi-size-xs::before {
    content: "\f13a3"
}

.mdi-size-xxl::before {
    content: "\f13a8"
}

.mdi-size-xxs::before {
    content: "\f13a2"
}

.mdi-size-xxxl::before {
    content: "\f13a9"
}

.mdi-skate::before {
    content: "\f0d35"
}

.mdi-skate-off::before {
    content: "\f0699"
}

.mdi-skateboard::before {
    content: "\f14c2"
}

.mdi-skateboarding::before {
    content: "\f0501"
}

.mdi-skew-less::before {
    content: "\f0d36"
}

.mdi-skew-more::before {
    content: "\f0d37"
}

.mdi-ski::before {
    content: "\f1304"
}

.mdi-ski-cross-country::before {
    content: "\f1305"
}

.mdi-ski-water::before {
    content: "\f1306"
}

.mdi-skip-backward::before {
    content: "\f04ab"
}

.mdi-skip-backward-outline::before {
    content: "\f0f25"
}

.mdi-skip-forward::before {
    content: "\f04ac"
}

.mdi-skip-forward-outline::before {
    content: "\f0f26"
}

.mdi-skip-next::before {
    content: "\f04ad"
}

.mdi-skip-next-circle::before {
    content: "\f0661"
}

.mdi-skip-next-circle-outline::before {
    content: "\f0662"
}

.mdi-skip-next-outline::before {
    content: "\f0f27"
}

.mdi-skip-previous::before {
    content: "\f04ae"
}

.mdi-skip-previous-circle::before {
    content: "\f0663"
}

.mdi-skip-previous-circle-outline::before {
    content: "\f0664"
}

.mdi-skip-previous-outline::before {
    content: "\f0f28"
}

.mdi-skull::before {
    content: "\f068c"
}

.mdi-skull-crossbones::before {
    content: "\f0bc6"
}

.mdi-skull-crossbones-outline::before {
    content: "\f0bc7"
}

.mdi-skull-outline::before {
    content: "\f0bc8"
}

.mdi-skull-scan::before {
    content: "\f14c7"
}

.mdi-skull-scan-outline::before {
    content: "\f14c8"
}

.mdi-skype::before {
    content: "\f04af"
}

.mdi-skype-business::before {
    content: "\f04b0"
}

.mdi-slack::before {
    content: "\f04b1"
}

.mdi-slash-forward::before {
    content: "\f0fdf"
}

.mdi-slash-forward-box::before {
    content: "\f0fe0"
}

.mdi-sledding::before {
    content: "\f041b"
}

.mdi-sleep::before {
    content: "\f04b2"
}

.mdi-sleep-off::before {
    content: "\f04b3"
}

.mdi-slide::before {
    content: "\f15a5"
}

.mdi-slope-downhill::before {
    content: "\f0dff"
}

.mdi-slope-uphill::before {
    content: "\f0e00"
}

.mdi-slot-machine::before {
    content: "\f1114"
}

.mdi-slot-machine-outline::before {
    content: "\f1115"
}

.mdi-smart-card::before {
    content: "\f10bd"
}

.mdi-smart-card-off::before {
    content: "\f18f7"
}

.mdi-smart-card-off-outline::before {
    content: "\f18f8"
}

.mdi-smart-card-outline::before {
    content: "\f10be"
}

.mdi-smart-card-reader::before {
    content: "\f10bf"
}

.mdi-smart-card-reader-outline::before {
    content: "\f10c0"
}

.mdi-smog::before {
    content: "\f0a71"
}

.mdi-smoke::before {
    content: "\f1799"
}

.mdi-smoke-detector::before {
    content: "\f0392"
}

.mdi-smoke-detector-alert::before {
    content: "\f192e"
}

.mdi-smoke-detector-alert-outline::before {
    content: "\f192f"
}

.mdi-smoke-detector-off::before {
    content: "\f1809"
}

.mdi-smoke-detector-off-outline::before {
    content: "\f180a"
}

.mdi-smoke-detector-outline::before {
    content: "\f1808"
}

.mdi-smoke-detector-variant::before {
    content: "\f180b"
}

.mdi-smoke-detector-variant-alert::before {
    content: "\f1930"
}

.mdi-smoke-detector-variant-off::before {
    content: "\f180c"
}

.mdi-smoking::before {
    content: "\f04b4"
}

.mdi-smoking-off::before {
    content: "\f04b5"
}

.mdi-smoking-pipe::before {
    content: "\f140d"
}

.mdi-smoking-pipe-off::before {
    content: "\f1428"
}

.mdi-snail::before {
    content: "\f1677"
}

.mdi-snake::before {
    content: "\f150e"
}

.mdi-snapchat::before {
    content: "\f04b6"
}

.mdi-snowboard::before {
    content: "\f1307"
}

.mdi-snowflake::before {
    content: "\f0717"
}

.mdi-snowflake-alert::before {
    content: "\f0f29"
}

.mdi-snowflake-melt::before {
    content: "\f12cb"
}

.mdi-snowflake-off::before {
    content: "\f14e3"
}

.mdi-snowflake-variant::before {
    content: "\f0f2a"
}

.mdi-snowman::before {
    content: "\f04b7"
}

.mdi-snowmobile::before {
    content: "\f06dd"
}

.mdi-soccer::before {
    content: "\f04b8"
}

.mdi-soccer-field::before {
    content: "\f0834"
}

.mdi-social-distance-2-meters::before {
    content: "\f1579"
}

.mdi-social-distance-6-feet::before {
    content: "\f157a"
}

.mdi-sofa::before {
    content: "\f04b9"
}

.mdi-sofa-outline::before {
    content: "\f156d"
}

.mdi-sofa-single::before {
    content: "\f156e"
}

.mdi-sofa-single-outline::before {
    content: "\f156f"
}

.mdi-solar-panel::before {
    content: "\f0d9b"
}

.mdi-solar-panel-large::before {
    content: "\f0d9c"
}

.mdi-solar-power::before {
    content: "\f0a72"
}

.mdi-soldering-iron::before {
    content: "\f1092"
}

.mdi-solid::before {
    content: "\f068d"
}

.mdi-sony-playstation::before {
    content: "\f0414"
}

.mdi-sort::before {
    content: "\f04ba"
}

.mdi-sort-alphabetical-ascending::before {
    content: "\f05bd"
}

.mdi-sort-alphabetical-ascending-variant::before {
    content: "\f1148"
}

.mdi-sort-alphabetical-descending::before {
    content: "\f05bf"
}

.mdi-sort-alphabetical-descending-variant::before {
    content: "\f1149"
}

.mdi-sort-alphabetical-variant::before {
    content: "\f04bb"
}

.mdi-sort-ascending::before {
    content: "\f04bc"
}

.mdi-sort-bool-ascending::before {
    content: "\f1385"
}

.mdi-sort-bool-ascending-variant::before {
    content: "\f1386"
}

.mdi-sort-bool-descending::before {
    content: "\f1387"
}

.mdi-sort-bool-descending-variant::before {
    content: "\f1388"
}

.mdi-sort-calendar-ascending::before {
    content: "\f1547"
}

.mdi-sort-calendar-descending::before {
    content: "\f1548"
}

.mdi-sort-clock-ascending::before {
    content: "\f1549"
}

.mdi-sort-clock-ascending-outline::before {
    content: "\f154a"
}

.mdi-sort-clock-descending::before {
    content: "\f154b"
}

.mdi-sort-clock-descending-outline::before {
    content: "\f154c"
}

.mdi-sort-descending::before {
    content: "\f04bd"
}

.mdi-sort-numeric-ascending::before {
    content: "\f1389"
}

.mdi-sort-numeric-ascending-variant::before {
    content: "\f090d"
}

.mdi-sort-numeric-descending::before {
    content: "\f138a"
}

.mdi-sort-numeric-descending-variant::before {
    content: "\f0ad2"
}

.mdi-sort-numeric-variant::before {
    content: "\f04be"
}

.mdi-sort-reverse-variant::before {
    content: "\f033c"
}

.mdi-sort-variant::before {
    content: "\f04bf"
}

.mdi-sort-variant-lock::before {
    content: "\f0ccd"
}

.mdi-sort-variant-lock-open::before {
    content: "\f0cce"
}

.mdi-sort-variant-remove::before {
    content: "\f1147"
}

.mdi-soundbar::before {
    content: "\f17db"
}

.mdi-soundcloud::before {
    content: "\f04c0"
}

.mdi-source-branch::before {
    content: "\f062c"
}

.mdi-source-branch-check::before {
    content: "\f14cf"
}

.mdi-source-branch-minus::before {
    content: "\f14cb"
}

.mdi-source-branch-plus::before {
    content: "\f14ca"
}

.mdi-source-branch-refresh::before {
    content: "\f14cd"
}

.mdi-source-branch-remove::before {
    content: "\f14cc"
}

.mdi-source-branch-sync::before {
    content: "\f14ce"
}

.mdi-source-commit::before {
    content: "\f0718"
}

.mdi-source-commit-end::before {
    content: "\f0719"
}

.mdi-source-commit-end-local::before {
    content: "\f071a"
}

.mdi-source-commit-local::before {
    content: "\f071b"
}

.mdi-source-commit-next-local::before {
    content: "\f071c"
}

.mdi-source-commit-start::before {
    content: "\f071d"
}

.mdi-source-commit-start-next-local::before {
    content: "\f071e"
}

.mdi-source-fork::before {
    content: "\f04c1"
}

.mdi-source-merge::before {
    content: "\f062d"
}

.mdi-source-pull::before {
    content: "\f04c2"
}

.mdi-source-repository::before {
    content: "\f0ccf"
}

.mdi-source-repository-multiple::before {
    content: "\f0cd0"
}

.mdi-soy-sauce::before {
    content: "\f07ee"
}

.mdi-soy-sauce-off::before {
    content: "\f13fc"
}

.mdi-spa::before {
    content: "\f0cd1"
}

.mdi-spa-outline::before {
    content: "\f0cd2"
}

.mdi-space-invaders::before {
    content: "\f0bc9"
}

.mdi-space-station::before {
    content: "\f1383"
}

.mdi-spade::before {
    content: "\f0e65"
}

.mdi-speaker::before {
    content: "\f04c3"
}

.mdi-speaker-bluetooth::before {
    content: "\f09a2"
}

.mdi-speaker-multiple::before {
    content: "\f0d38"
}

.mdi-speaker-off::before {
    content: "\f04c4"
}

.mdi-speaker-wireless::before {
    content: "\f071f"
}

.mdi-spear::before {
    content: "\f1845"
}

.mdi-speedometer::before {
    content: "\f04c5"
}

.mdi-speedometer-medium::before {
    content: "\f0f85"
}

.mdi-speedometer-slow::before {
    content: "\f0f86"
}

.mdi-spellcheck::before {
    content: "\f04c6"
}

.mdi-sphere::before {
    content: "\f1954"
}

.mdi-sphere-off::before {
    content: "\f1955"
}

.mdi-spider::before {
    content: "\f11ea"
}

.mdi-spider-thread::before {
    content: "\f11eb"
}

.mdi-spider-web::before {
    content: "\f0bca"
}

.mdi-spirit-level::before {
    content: "\f14f1"
}

.mdi-spoon-sugar::before {
    content: "\f1429"
}

.mdi-spotify::before {
    content: "\f04c7"
}

.mdi-spotlight::before {
    content: "\f04c8"
}

.mdi-spotlight-beam::before {
    content: "\f04c9"
}

.mdi-spray::before {
    content: "\f0665"
}

.mdi-spray-bottle::before {
    content: "\f0ae0"
}

.mdi-sprinkler::before {
    content: "\f105f"
}

.mdi-sprinkler-fire::before {
    content: "\f199d"
}

.mdi-sprinkler-variant::before {
    content: "\f1060"
}

.mdi-sprout::before {
    content: "\f0e66"
}

.mdi-sprout-outline::before {
    content: "\f0e67"
}

.mdi-square::before {
    content: "\f0764"
}

.mdi-square-circle::before {
    content: "\f1500"
}

.mdi-square-edit-outline::before {
    content: "\f090c"
}

.mdi-square-medium::before {
    content: "\f0a13"
}

.mdi-square-medium-outline::before {
    content: "\f0a14"
}

.mdi-square-off::before {
    content: "\f12ee"
}

.mdi-square-off-outline::before {
    content: "\f12ef"
}

.mdi-square-opacity::before {
    content: "\f1854"
}

.mdi-square-outline::before {
    content: "\f0763"
}

.mdi-square-root::before {
    content: "\f0784"
}

.mdi-square-root-box::before {
    content: "\f09a3"
}

.mdi-square-rounded::before {
    content: "\f14fb"
}

.mdi-square-rounded-outline::before {
    content: "\f14fc"
}

.mdi-square-small::before {
    content: "\f0a15"
}

.mdi-square-wave::before {
    content: "\f147b"
}

.mdi-squeegee::before {
    content: "\f0ae1"
}

.mdi-ssh::before {
    content: "\f08c0"
}

.mdi-stack-exchange::before {
    content: "\f060b"
}

.mdi-stack-overflow::before {
    content: "\f04cc"
}

.mdi-stackpath::before {
    content: "\f0359"
}

.mdi-stadium::before {
    content: "\f0ff9"
}

.mdi-stadium-variant::before {
    content: "\f0720"
}

.mdi-stairs::before {
    content: "\f04cd"
}

.mdi-stairs-box::before {
    content: "\f139e"
}

.mdi-stairs-down::before {
    content: "\f12be"
}

.mdi-stairs-up::before {
    content: "\f12bd"
}

.mdi-stamper::before {
    content: "\f0d39"
}

.mdi-standard-definition::before {
    content: "\f07ef"
}

.mdi-star::before {
    content: "\f04ce"
}

.mdi-star-box::before {
    content: "\f0a73"
}

.mdi-star-box-multiple::before {
    content: "\f1286"
}

.mdi-star-box-multiple-outline::before {
    content: "\f1287"
}

.mdi-star-box-outline::before {
    content: "\f0a74"
}

.mdi-star-check::before {
    content: "\f1566"
}

.mdi-star-check-outline::before {
    content: "\f156a"
}

.mdi-star-circle::before {
    content: "\f04cf"
}

.mdi-star-circle-outline::before {
    content: "\f09a4"
}

.mdi-star-cog::before {
    content: "\f1668"
}

.mdi-star-cog-outline::before {
    content: "\f1669"
}

.mdi-star-crescent::before {
    content: "\f0979"
}

.mdi-star-david::before {
    content: "\f097a"
}

.mdi-star-face::before {
    content: "\f09a5"
}

.mdi-star-four-points::before {
    content: "\f0ae2"
}

.mdi-star-four-points-outline::before {
    content: "\f0ae3"
}

.mdi-star-half::before {
    content: "\f0246"
}

.mdi-star-half-full::before {
    content: "\f04d0"
}

.mdi-star-minus::before {
    content: "\f1564"
}

.mdi-star-minus-outline::before {
    content: "\f1568"
}

.mdi-star-off::before {
    content: "\f04d1"
}

.mdi-star-off-outline::before {
    content: "\f155b"
}

.mdi-star-outline::before {
    content: "\f04d2"
}

.mdi-star-plus::before {
    content: "\f1563"
}

.mdi-star-plus-outline::before {
    content: "\f1567"
}

.mdi-star-remove::before {
    content: "\f1565"
}

.mdi-star-remove-outline::before {
    content: "\f1569"
}

.mdi-star-settings::before {
    content: "\f166a"
}

.mdi-star-settings-outline::before {
    content: "\f166b"
}

.mdi-star-shooting::before {
    content: "\f1741"
}

.mdi-star-shooting-outline::before {
    content: "\f1742"
}

.mdi-star-three-points::before {
    content: "\f0ae4"
}

.mdi-star-three-points-outline::before {
    content: "\f0ae5"
}

.mdi-state-machine::before {
    content: "\f11ef"
}

.mdi-steam::before {
    content: "\f04d3"
}

.mdi-steering::before {
    content: "\f04d4"
}

.mdi-steering-off::before {
    content: "\f090e"
}

.mdi-step-backward::before {
    content: "\f04d5"
}

.mdi-step-backward-2::before {
    content: "\f04d6"
}

.mdi-step-forward::before {
    content: "\f04d7"
}

.mdi-step-forward-2::before {
    content: "\f04d8"
}

.mdi-stethoscope::before {
    content: "\f04d9"
}

.mdi-sticker::before {
    content: "\f1364"
}

.mdi-sticker-alert::before {
    content: "\f1365"
}

.mdi-sticker-alert-outline::before {
    content: "\f1366"
}

.mdi-sticker-check::before {
    content: "\f1367"
}

.mdi-sticker-check-outline::before {
    content: "\f1368"
}

.mdi-sticker-circle-outline::before {
    content: "\f05d0"
}

.mdi-sticker-emoji::before {
    content: "\f0785"
}

.mdi-sticker-minus::before {
    content: "\f1369"
}

.mdi-sticker-minus-outline::before {
    content: "\f136a"
}

.mdi-sticker-outline::before {
    content: "\f136b"
}

.mdi-sticker-plus::before {
    content: "\f136c"
}

.mdi-sticker-plus-outline::before {
    content: "\f136d"
}

.mdi-sticker-remove::before {
    content: "\f136e"
}

.mdi-sticker-remove-outline::before {
    content: "\f136f"
}

.mdi-sticker-text::before {
    content: "\f178e"
}

.mdi-sticker-text-outline::before {
    content: "\f178f"
}

.mdi-stocking::before {
    content: "\f04da"
}

.mdi-stomach::before {
    content: "\f1093"
}

.mdi-stool::before {
    content: "\f195d"
}

.mdi-stool-outline::before {
    content: "\f195e"
}

.mdi-stop::before {
    content: "\f04db"
}

.mdi-stop-circle::before {
    content: "\f0666"
}

.mdi-stop-circle-outline::before {
    content: "\f0667"
}

.mdi-store::before {
    content: "\f04dc"
}

.mdi-store-24-hour::before {
    content: "\f04dd"
}

.mdi-store-alert::before {
    content: "\f18c1"
}

.mdi-store-alert-outline::before {
    content: "\f18c2"
}

.mdi-store-check::before {
    content: "\f18c3"
}

.mdi-store-check-outline::before {
    content: "\f18c4"
}

.mdi-store-clock::before {
    content: "\f18c5"
}

.mdi-store-clock-outline::before {
    content: "\f18c6"
}

.mdi-store-cog::before {
    content: "\f18c7"
}

.mdi-store-cog-outline::before {
    content: "\f18c8"
}

.mdi-store-edit::before {
    content: "\f18c9"
}

.mdi-store-edit-outline::before {
    content: "\f18ca"
}

.mdi-store-marker::before {
    content: "\f18cb"
}

.mdi-store-marker-outline::before {
    content: "\f18cc"
}

.mdi-store-minus::before {
    content: "\f165e"
}

.mdi-store-minus-outline::before {
    content: "\f18cd"
}

.mdi-store-off::before {
    content: "\f18ce"
}

.mdi-store-off-outline::before {
    content: "\f18cf"
}

.mdi-store-outline::before {
    content: "\f1361"
}

.mdi-store-plus::before {
    content: "\f165f"
}

.mdi-store-plus-outline::before {
    content: "\f18d0"
}

.mdi-store-remove::before {
    content: "\f1660"
}

.mdi-store-remove-outline::before {
    content: "\f18d1"
}

.mdi-store-search::before {
    content: "\f18d2"
}

.mdi-store-search-outline::before {
    content: "\f18d3"
}

.mdi-store-settings::before {
    content: "\f18d4"
}

.mdi-store-settings-outline::before {
    content: "\f18d5"
}

.mdi-storefront::before {
    content: "\f07c7"
}

.mdi-storefront-outline::before {
    content: "\f10c1"
}

.mdi-stove::before {
    content: "\f04de"
}

.mdi-strategy::before {
    content: "\f11d6"
}

.mdi-stretch-to-page::before {
    content: "\f0f2b"
}

.mdi-stretch-to-page-outline::before {
    content: "\f0f2c"
}

.mdi-string-lights::before {
    content: "\f12ba"
}

.mdi-string-lights-off::before {
    content: "\f12bb"
}

.mdi-subdirectory-arrow-left::before {
    content: "\f060c"
}

.mdi-subdirectory-arrow-right::before {
    content: "\f060d"
}

.mdi-submarine::before {
    content: "\f156c"
}

.mdi-subtitles::before {
    content: "\f0a16"
}

.mdi-subtitles-outline::before {
    content: "\f0a17"
}

.mdi-subway::before {
    content: "\f06ac"
}

.mdi-subway-alert-variant::before {
    content: "\f0d9d"
}

.mdi-subway-variant::before {
    content: "\f04df"
}

.mdi-summit::before {
    content: "\f0786"
}

.mdi-sun-compass::before {
    content: "\f19a5"
}

.mdi-sun-snowflake::before {
    content: "\f1796"
}

.mdi-sun-thermometer::before {
    content: "\f18d6"
}

.mdi-sun-thermometer-outline::before {
    content: "\f18d7"
}

.mdi-sun-wireless::before {
    content: "\f17fe"
}

.mdi-sun-wireless-outline::before {
    content: "\f17ff"
}

.mdi-sunglasses::before {
    content: "\f04e0"
}

.mdi-surfing::before {
    content: "\f1746"
}

.mdi-surround-sound::before {
    content: "\f05c5"
}

.mdi-surround-sound-2-0::before {
    content: "\f07f0"
}

.mdi-surround-sound-2-1::before {
    content: "\f1729"
}

.mdi-surround-sound-3-1::before {
    content: "\f07f1"
}

.mdi-surround-sound-5-1::before {
    content: "\f07f2"
}

.mdi-surround-sound-5-1-2::before {
    content: "\f172a"
}

.mdi-surround-sound-7-1::before {
    content: "\f07f3"
}

.mdi-svg::before {
    content: "\f0721"
}

.mdi-swap-horizontal::before {
    content: "\f04e1"
}

.mdi-swap-horizontal-bold::before {
    content: "\f0bcd"
}

.mdi-swap-horizontal-circle::before {
    content: "\f0fe1"
}

.mdi-swap-horizontal-circle-outline::before {
    content: "\f0fe2"
}

.mdi-swap-horizontal-variant::before {
    content: "\f08c1"
}

.mdi-swap-vertical::before {
    content: "\f04e2"
}

.mdi-swap-vertical-bold::before {
    content: "\f0bce"
}

.mdi-swap-vertical-circle::before {
    content: "\f0fe3"
}

.mdi-swap-vertical-circle-outline::before {
    content: "\f0fe4"
}

.mdi-swap-vertical-variant::before {
    content: "\f08c2"
}

.mdi-swim::before {
    content: "\f04e3"
}

.mdi-switch::before {
    content: "\f04e4"
}

.mdi-sword::before {
    content: "\f04e5"
}

.mdi-sword-cross::before {
    content: "\f0787"
}

.mdi-syllabary-hangul::before {
    content: "\f1333"
}

.mdi-syllabary-hiragana::before {
    content: "\f1334"
}

.mdi-syllabary-katakana::before {
    content: "\f1335"
}

.mdi-syllabary-katakana-halfwidth::before {
    content: "\f1336"
}

.mdi-symbol::before {
    content: "\f1501"
}

.mdi-symfony::before {
    content: "\f0ae6"
}

.mdi-sync::before {
    content: "\f04e6"
}

.mdi-sync-alert::before {
    content: "\f04e7"
}

.mdi-sync-circle::before {
    content: "\f1378"
}

.mdi-sync-off::before {
    content: "\f04e8"
}

.mdi-tab::before {
    content: "\f04e9"
}

.mdi-tab-minus::before {
    content: "\f0b4b"
}

.mdi-tab-plus::before {
    content: "\f075c"
}

.mdi-tab-remove::before {
    content: "\f0b4c"
}

.mdi-tab-search::before {
    content: "\f199e"
}

.mdi-tab-unselected::before {
    content: "\f04ea"
}

.mdi-table::before {
    content: "\f04eb"
}

.mdi-table-account::before {
    content: "\f13b9"
}

.mdi-table-alert::before {
    content: "\f13ba"
}

.mdi-table-arrow-down::before {
    content: "\f13bb"
}

.mdi-table-arrow-left::before {
    content: "\f13bc"
}

.mdi-table-arrow-right::before {
    content: "\f13bd"
}

.mdi-table-arrow-up::before {
    content: "\f13be"
}

.mdi-table-border::before {
    content: "\f0a18"
}

.mdi-table-cancel::before {
    content: "\f13bf"
}

.mdi-table-chair::before {
    content: "\f1061"
}

.mdi-table-check::before {
    content: "\f13c0"
}

.mdi-table-clock::before {
    content: "\f13c1"
}

.mdi-table-cog::before {
    content: "\f13c2"
}

.mdi-table-column::before {
    content: "\f0835"
}

.mdi-table-column-plus-after::before {
    content: "\f04ec"
}

.mdi-table-column-plus-before::before {
    content: "\f04ed"
}

.mdi-table-column-remove::before {
    content: "\f04ee"
}

.mdi-table-column-width::before {
    content: "\f04ef"
}

.mdi-table-edit::before {
    content: "\f04f0"
}

.mdi-table-eye::before {
    content: "\f1094"
}

.mdi-table-eye-off::before {
    content: "\f13c3"
}

.mdi-table-furniture::before {
    content: "\f05bc"
}

.mdi-table-headers-eye::before {
    content: "\f121d"
}

.mdi-table-headers-eye-off::before {
    content: "\f121e"
}

.mdi-table-heart::before {
    content: "\f13c4"
}

.mdi-table-key::before {
    content: "\f13c5"
}

.mdi-table-large::before {
    content: "\f04f1"
}

.mdi-table-large-plus::before {
    content: "\f0f87"
}

.mdi-table-large-remove::before {
    content: "\f0f88"
}

.mdi-table-lock::before {
    content: "\f13c6"
}

.mdi-table-merge-cells::before {
    content: "\f09a6"
}

.mdi-table-minus::before {
    content: "\f13c7"
}

.mdi-table-multiple::before {
    content: "\f13c8"
}

.mdi-table-network::before {
    content: "\f13c9"
}

.mdi-table-of-contents::before {
    content: "\f0836"
}

.mdi-table-off::before {
    content: "\f13ca"
}

.mdi-table-picnic::before {
    content: "\f1743"
}

.mdi-table-pivot::before {
    content: "\f183c"
}

.mdi-table-plus::before {
    content: "\f0a75"
}

.mdi-table-refresh::before {
    content: "\f13a0"
}

.mdi-table-remove::before {
    content: "\f0a76"
}

.mdi-table-row::before {
    content: "\f0837"
}

.mdi-table-row-height::before {
    content: "\f04f2"
}

.mdi-table-row-plus-after::before {
    content: "\f04f3"
}

.mdi-table-row-plus-before::before {
    content: "\f04f4"
}

.mdi-table-row-remove::before {
    content: "\f04f5"
}

.mdi-table-search::before {
    content: "\f090f"
}

.mdi-table-settings::before {
    content: "\f0838"
}

.mdi-table-split-cell::before {
    content: "\f142a"
}

.mdi-table-star::before {
    content: "\f13cb"
}

.mdi-table-sync::before {
    content: "\f13a1"
}

.mdi-table-tennis::before {
    content: "\f0e68"
}

.mdi-tablet::before {
    content: "\f04f6"
}

.mdi-tablet-android::before {
    content: "\f04f7"
}

.mdi-tablet-cellphone::before {
    content: "\f09a7"
}

.mdi-tablet-dashboard::before {
    content: "\f0ece"
}

.mdi-taco::before {
    content: "\f0762"
}

.mdi-tag::before {
    content: "\f04f9"
}

.mdi-tag-arrow-down::before {
    content: "\f172b"
}

.mdi-tag-arrow-down-outline::before {
    content: "\f172c"
}

.mdi-tag-arrow-left::before {
    content: "\f172d"
}

.mdi-tag-arrow-left-outline::before {
    content: "\f172e"
}

.mdi-tag-arrow-right::before {
    content: "\f172f"
}

.mdi-tag-arrow-right-outline::before {
    content: "\f1730"
}

.mdi-tag-arrow-up::before {
    content: "\f1731"
}

.mdi-tag-arrow-up-outline::before {
    content: "\f1732"
}

.mdi-tag-faces::before {
    content: "\f04fa"
}

.mdi-tag-heart::before {
    content: "\f068b"
}

.mdi-tag-heart-outline::before {
    content: "\f0bcf"
}

.mdi-tag-minus::before {
    content: "\f0910"
}

.mdi-tag-minus-outline::before {
    content: "\f121f"
}

.mdi-tag-multiple::before {
    content: "\f04fb"
}

.mdi-tag-multiple-outline::before {
    content: "\f12f7"
}

.mdi-tag-off::before {
    content: "\f1220"
}

.mdi-tag-off-outline::before {
    content: "\f1221"
}

.mdi-tag-outline::before {
    content: "\f04fc"
}

.mdi-tag-plus::before {
    content: "\f0722"
}

.mdi-tag-plus-outline::before {
    content: "\f1222"
}

.mdi-tag-remove::before {
    content: "\f0723"
}

.mdi-tag-remove-outline::before {
    content: "\f1223"
}

.mdi-tag-search::before {
    content: "\f1907"
}

.mdi-tag-search-outline::before {
    content: "\f1908"
}

.mdi-tag-text::before {
    content: "\f1224"
}

.mdi-tag-text-outline::before {
    content: "\f04fd"
}

.mdi-tailwind::before {
    content: "\f13ff"
}

.mdi-tangram::before {
    content: "\f04f8"
}

.mdi-tank::before {
    content: "\f0d3a"
}

.mdi-tanker-truck::before {
    content: "\f0fe5"
}

.mdi-tape-drive::before {
    content: "\f16df"
}

.mdi-tape-measure::before {
    content: "\f0b4d"
}

.mdi-target::before {
    content: "\f04fe"
}

.mdi-target-account::before {
    content: "\f0bd0"
}

.mdi-target-variant::before {
    content: "\f0a77"
}

.mdi-taxi::before {
    content: "\f04ff"
}

.mdi-tea::before {
    content: "\f0d9e"
}

.mdi-tea-outline::before {
    content: "\f0d9f"
}

.mdi-teamviewer::before {
    content: "\f0500"
}

.mdi-teddy-bear::before {
    content: "\f18fb"
}

.mdi-telescope::before {
    content: "\f0b4e"
}

.mdi-television::before {
    content: "\f0502"
}

.mdi-television-ambient-light::before {
    content: "\f1356"
}

.mdi-television-box::before {
    content: "\f0839"
}

.mdi-television-classic::before {
    content: "\f07f4"
}

.mdi-television-classic-off::before {
    content: "\f083a"
}

.mdi-television-guide::before {
    content: "\f0503"
}

.mdi-television-off::before {
    content: "\f083b"
}

.mdi-television-pause::before {
    content: "\f0f89"
}

.mdi-television-play::before {
    content: "\f0ecf"
}

.mdi-television-shimmer::before {
    content: "\f1110"
}

.mdi-television-stop::before {
    content: "\f0f8a"
}

.mdi-temperature-celsius::before {
    content: "\f0504"
}

.mdi-temperature-fahrenheit::before {
    content: "\f0505"
}

.mdi-temperature-kelvin::before {
    content: "\f0506"
}

.mdi-tennis::before {
    content: "\f0da0"
}

.mdi-tennis-ball::before {
    content: "\f0507"
}

.mdi-tent::before {
    content: "\f0508"
}

.mdi-terraform::before {
    content: "\f1062"
}

.mdi-terrain::before {
    content: "\f0509"
}

.mdi-test-tube::before {
    content: "\f0668"
}

.mdi-test-tube-empty::before {
    content: "\f0911"
}

.mdi-test-tube-off::before {
    content: "\f0912"
}

.mdi-text::before {
    content: "\f09a8"
}

.mdi-text-account::before {
    content: "\f1570"
}

.mdi-text-box::before {
    content: "\f021a"
}

.mdi-text-box-check::before {
    content: "\f0ea6"
}

.mdi-text-box-check-outline::before {
    content: "\f0ea7"
}

.mdi-text-box-minus::before {
    content: "\f0ea8"
}

.mdi-text-box-minus-outline::before {
    content: "\f0ea9"
}

.mdi-text-box-multiple::before {
    content: "\f0ab7"
}

.mdi-text-box-multiple-outline::before {
    content: "\f0ab8"
}

.mdi-text-box-outline::before {
    content: "\f09ed"
}

.mdi-text-box-plus::before {
    content: "\f0eaa"
}

.mdi-text-box-plus-outline::before {
    content: "\f0eab"
}

.mdi-text-box-remove::before {
    content: "\f0eac"
}

.mdi-text-box-remove-outline::before {
    content: "\f0ead"
}

.mdi-text-box-search::before {
    content: "\f0eae"
}

.mdi-text-box-search-outline::before {
    content: "\f0eaf"
}

.mdi-text-long::before {
    content: "\f09aa"
}

.mdi-text-recognition::before {
    content: "\f113d"
}

.mdi-text-search::before {
    content: "\f13b8"
}

.mdi-text-shadow::before {
    content: "\f0669"
}

.mdi-text-short::before {
    content: "\f09a9"
}

.mdi-text-to-speech::before {
    content: "\f050a"
}

.mdi-text-to-speech-off::before {
    content: "\f050b"
}

.mdi-texture::before {
    content: "\f050c"
}

.mdi-texture-box::before {
    content: "\f0fe6"
}

.mdi-theater::before {
    content: "\f050d"
}

.mdi-theme-light-dark::before {
    content: "\f050e"
}

.mdi-thermometer::before {
    content: "\f050f"
}

.mdi-thermometer-alert::before {
    content: "\f0e01"
}

.mdi-thermometer-bluetooth::before {
    content: "\f1895"
}

.mdi-thermometer-chevron-down::before {
    content: "\f0e02"
}

.mdi-thermometer-chevron-up::before {
    content: "\f0e03"
}

.mdi-thermometer-high::before {
    content: "\f10c2"
}

.mdi-thermometer-lines::before {
    content: "\f0510"
}

.mdi-thermometer-low::before {
    content: "\f10c3"
}

.mdi-thermometer-minus::before {
    content: "\f0e04"
}

.mdi-thermometer-off::before {
    content: "\f1531"
}

.mdi-thermometer-plus::before {
    content: "\f0e05"
}

.mdi-thermostat::before {
    content: "\f0393"
}

.mdi-thermostat-box::before {
    content: "\f0891"
}

.mdi-thought-bubble::before {
    content: "\f07f6"
}

.mdi-thought-bubble-outline::before {
    content: "\f07f7"
}

.mdi-thumb-down::before {
    content: "\f0511"
}

.mdi-thumb-down-outline::before {
    content: "\f0512"
}

.mdi-thumb-up::before {
    content: "\f0513"
}

.mdi-thumb-up-outline::before {
    content: "\f0514"
}

.mdi-thumbs-up-down::before {
    content: "\f0515"
}

.mdi-thumbs-up-down-outline::before {
    content: "\f1914"
}

.mdi-ticket::before {
    content: "\f0516"
}

.mdi-ticket-account::before {
    content: "\f0517"
}

.mdi-ticket-confirmation::before {
    content: "\f0518"
}

.mdi-ticket-confirmation-outline::before {
    content: "\f13aa"
}

.mdi-ticket-outline::before {
    content: "\f0913"
}

.mdi-ticket-percent::before {
    content: "\f0724"
}

.mdi-ticket-percent-outline::before {
    content: "\f142b"
}

.mdi-tie::before {
    content: "\f0519"
}

.mdi-tilde::before {
    content: "\f0725"
}

.mdi-tilde-off::before {
    content: "\f18f3"
}

.mdi-timelapse::before {
    content: "\f051a"
}

.mdi-timeline::before {
    content: "\f0bd1"
}

.mdi-timeline-alert::before {
    content: "\f0f95"
}

.mdi-timeline-alert-outline::before {
    content: "\f0f98"
}

.mdi-timeline-check::before {
    content: "\f1532"
}

.mdi-timeline-check-outline::before {
    content: "\f1533"
}

.mdi-timeline-clock::before {
    content: "\f11fb"
}

.mdi-timeline-clock-outline::before {
    content: "\f11fc"
}

.mdi-timeline-help::before {
    content: "\f0f99"
}

.mdi-timeline-help-outline::before {
    content: "\f0f9a"
}

.mdi-timeline-minus::before {
    content: "\f1534"
}

.mdi-timeline-minus-outline::before {
    content: "\f1535"
}

.mdi-timeline-outline::before {
    content: "\f0bd2"
}

.mdi-timeline-plus::before {
    content: "\f0f96"
}

.mdi-timeline-plus-outline::before {
    content: "\f0f97"
}

.mdi-timeline-remove::before {
    content: "\f1536"
}

.mdi-timeline-remove-outline::before {
    content: "\f1537"
}

.mdi-timeline-text::before {
    content: "\f0bd3"
}

.mdi-timeline-text-outline::before {
    content: "\f0bd4"
}

.mdi-timer::before {
    content: "\f13ab"
}

.mdi-timer-10::before {
    content: "\f051c"
}

.mdi-timer-3::before {
    content: "\f051d"
}

.mdi-timer-cog::before {
    content: "\f1925"
}

.mdi-timer-cog-outline::before {
    content: "\f1926"
}

.mdi-timer-off::before {
    content: "\f13ac"
}

.mdi-timer-off-outline::before {
    content: "\f051e"
}

.mdi-timer-outline::before {
    content: "\f051b"
}

.mdi-timer-sand::before {
    content: "\f051f"
}

.mdi-timer-sand-complete::before {
    content: "\f199f"
}

.mdi-timer-sand-empty::before {
    content: "\f06ad"
}

.mdi-timer-sand-full::before {
    content: "\f078c"
}

.mdi-timer-sand-paused::before {
    content: "\f19a0"
}

.mdi-timer-settings::before {
    content: "\f1923"
}

.mdi-timer-settings-outline::before {
    content: "\f1924"
}

.mdi-timetable::before {
    content: "\f0520"
}

.mdi-tire::before {
    content: "\f1896"
}

.mdi-toaster::before {
    content: "\f1063"
}

.mdi-toaster-off::before {
    content: "\f11b7"
}

.mdi-toaster-oven::before {
    content: "\f0cd3"
}

.mdi-toggle-switch::before {
    content: "\f0521"
}

.mdi-toggle-switch-off::before {
    content: "\f0522"
}

.mdi-toggle-switch-off-outline::before {
    content: "\f0a19"
}

.mdi-toggle-switch-outline::before {
    content: "\f0a1a"
}

.mdi-toilet::before {
    content: "\f09ab"
}

.mdi-toolbox::before {
    content: "\f09ac"
}

.mdi-toolbox-outline::before {
    content: "\f09ad"
}

.mdi-tools::before {
    content: "\f1064"
}

.mdi-tooltip::before {
    content: "\f0523"
}

.mdi-tooltip-account::before {
    content: "\f000c"
}

.mdi-tooltip-cellphone::before {
    content: "\f183b"
}

.mdi-tooltip-check::before {
    content: "\f155c"
}

.mdi-tooltip-check-outline::before {
    content: "\f155d"
}

.mdi-tooltip-edit::before {
    content: "\f0524"
}

.mdi-tooltip-edit-outline::before {
    content: "\f12c5"
}

.mdi-tooltip-image::before {
    content: "\f0525"
}

.mdi-tooltip-image-outline::before {
    content: "\f0bd5"
}

.mdi-tooltip-minus::before {
    content: "\f155e"
}

.mdi-tooltip-minus-outline::before {
    content: "\f155f"
}

.mdi-tooltip-outline::before {
    content: "\f0526"
}

.mdi-tooltip-plus::before {
    content: "\f0bd6"
}

.mdi-tooltip-plus-outline::before {
    content: "\f0527"
}

.mdi-tooltip-remove::before {
    content: "\f1560"
}

.mdi-tooltip-remove-outline::before {
    content: "\f1561"
}

.mdi-tooltip-text::before {
    content: "\f0528"
}

.mdi-tooltip-text-outline::before {
    content: "\f0bd7"
}

.mdi-tooth::before {
    content: "\f08c3"
}

.mdi-tooth-outline::before {
    content: "\f0529"
}

.mdi-toothbrush::before {
    content: "\f1129"
}

.mdi-toothbrush-electric::before {
    content: "\f112c"
}

.mdi-toothbrush-paste::before {
    content: "\f112a"
}

.mdi-torch::before {
    content: "\f1606"
}

.mdi-tortoise::before {
    content: "\f0d3b"
}

.mdi-toslink::before {
    content: "\f12b8"
}

.mdi-tournament::before {
    content: "\f09ae"
}

.mdi-tow-truck::before {
    content: "\f083c"
}

.mdi-tower-beach::before {
    content: "\f0681"
}

.mdi-tower-fire::before {
    content: "\f0682"
}

.mdi-town-hall::before {
    content: "\f1875"
}

.mdi-toy-brick::before {
    content: "\f1288"
}

.mdi-toy-brick-marker::before {
    content: "\f1289"
}

.mdi-toy-brick-marker-outline::before {
    content: "\f128a"
}

.mdi-toy-brick-minus::before {
    content: "\f128b"
}

.mdi-toy-brick-minus-outline::before {
    content: "\f128c"
}

.mdi-toy-brick-outline::before {
    content: "\f128d"
}

.mdi-toy-brick-plus::before {
    content: "\f128e"
}

.mdi-toy-brick-plus-outline::before {
    content: "\f128f"
}

.mdi-toy-brick-remove::before {
    content: "\f1290"
}

.mdi-toy-brick-remove-outline::before {
    content: "\f1291"
}

.mdi-toy-brick-search::before {
    content: "\f1292"
}

.mdi-toy-brick-search-outline::before {
    content: "\f1293"
}

.mdi-track-light::before {
    content: "\f0914"
}

.mdi-trackpad::before {
    content: "\f07f8"
}

.mdi-trackpad-lock::before {
    content: "\f0933"
}

.mdi-tractor::before {
    content: "\f0892"
}

.mdi-tractor-variant::before {
    content: "\f14c4"
}

.mdi-trademark::before {
    content: "\f0a78"
}

.mdi-traffic-cone::before {
    content: "\f137c"
}

.mdi-traffic-light::before {
    content: "\f052b"
}

.mdi-traffic-light-outline::before {
    content: "\f182a"
}

.mdi-train::before {
    content: "\f052c"
}

.mdi-train-car::before {
    content: "\f0bd8"
}

.mdi-train-car-passenger::before {
    content: "\f1733"
}

.mdi-train-car-passenger-door::before {
    content: "\f1734"
}

.mdi-train-car-passenger-door-open::before {
    content: "\f1735"
}

.mdi-train-car-passenger-variant::before {
    content: "\f1736"
}

.mdi-train-variant::before {
    content: "\f08c4"
}

.mdi-tram::before {
    content: "\f052d"
}

.mdi-tram-side::before {
    content: "\f0fe7"
}

.mdi-transcribe::before {
    content: "\f052e"
}

.mdi-transcribe-close::before {
    content: "\f052f"
}

.mdi-transfer::before {
    content: "\f1065"
}

.mdi-transfer-down::before {
    content: "\f0da1"
}

.mdi-transfer-left::before {
    content: "\f0da2"
}

.mdi-transfer-right::before {
    content: "\f0530"
}

.mdi-transfer-up::before {
    content: "\f0da3"
}

.mdi-transit-connection::before {
    content: "\f0d3c"
}

.mdi-transit-connection-horizontal::before {
    content: "\f1546"
}

.mdi-transit-connection-variant::before {
    content: "\f0d3d"
}

.mdi-transit-detour::before {
    content: "\f0f8b"
}

.mdi-transit-skip::before {
    content: "\f1515"
}

.mdi-transit-transfer::before {
    content: "\f06ae"
}

.mdi-transition::before {
    content: "\f0915"
}

.mdi-transition-masked::before {
    content: "\f0916"
}

.mdi-translate::before {
    content: "\f05ca"
}

.mdi-translate-off::before {
    content: "\f0e06"
}

.mdi-transmission-tower::before {
    content: "\f0d3e"
}

.mdi-transmission-tower-export::before {
    content: "\f192c"
}

.mdi-transmission-tower-import::before {
    content: "\f192d"
}

.mdi-trash-can::before {
    content: "\f0a79"
}

.mdi-trash-can-outline::before {
    content: "\f0a7a"
}

.mdi-tray::before {
    content: "\f1294"
}

.mdi-tray-alert::before {
    content: "\f1295"
}

.mdi-tray-arrow-down::before {
    content: "\f0120"
}

.mdi-tray-arrow-up::before {
    content: "\f011d"
}

.mdi-tray-full::before {
    content: "\f1296"
}

.mdi-tray-minus::before {
    content: "\f1297"
}

.mdi-tray-plus::before {
    content: "\f1298"
}

.mdi-tray-remove::before {
    content: "\f1299"
}

.mdi-treasure-chest::before {
    content: "\f0726"
}

.mdi-tree::before {
    content: "\f0531"
}

.mdi-tree-outline::before {
    content: "\f0e69"
}

.mdi-trello::before {
    content: "\f0532"
}

.mdi-trending-down::before {
    content: "\f0533"
}

.mdi-trending-neutral::before {
    content: "\f0534"
}

.mdi-trending-up::before {
    content: "\f0535"
}

.mdi-triangle::before {
    content: "\f0536"
}

.mdi-triangle-outline::before {
    content: "\f0537"
}

.mdi-triangle-wave::before {
    content: "\f147c"
}

.mdi-triforce::before {
    content: "\f0bd9"
}

.mdi-trophy::before {
    content: "\f0538"
}

.mdi-trophy-award::before {
    content: "\f0539"
}

.mdi-trophy-broken::before {
    content: "\f0da4"
}

.mdi-trophy-outline::before {
    content: "\f053a"
}

.mdi-trophy-variant::before {
    content: "\f053b"
}

.mdi-trophy-variant-outline::before {
    content: "\f053c"
}

.mdi-truck::before {
    content: "\f053d"
}

.mdi-truck-cargo-container::before {
    content: "\f18d8"
}

.mdi-truck-check::before {
    content: "\f0cd4"
}

.mdi-truck-check-outline::before {
    content: "\f129a"
}

.mdi-truck-delivery::before {
    content: "\f053e"
}

.mdi-truck-delivery-outline::before {
    content: "\f129b"
}

.mdi-truck-fast::before {
    content: "\f0788"
}

.mdi-truck-fast-outline::before {
    content: "\f129c"
}

.mdi-truck-flatbed::before {
    content: "\f1891"
}

.mdi-truck-minus::before {
    content: "\f19ae"
}

.mdi-truck-minus-outline::before {
    content: "\f19bd"
}

.mdi-truck-outline::before {
    content: "\f129d"
}

.mdi-truck-plus::before {
    content: "\f19ad"
}

.mdi-truck-plus-outline::before {
    content: "\f19bc"
}

.mdi-truck-remove::before {
    content: "\f19af"
}

.mdi-truck-remove-outline::before {
    content: "\f19be"
}

.mdi-truck-snowflake::before {
    content: "\f19a6"
}

.mdi-truck-trailer::before {
    content: "\f0727"
}

.mdi-trumpet::before {
    content: "\f1096"
}

.mdi-tshirt-crew::before {
    content: "\f0a7b"
}

.mdi-tshirt-crew-outline::before {
    content: "\f053f"
}

.mdi-tshirt-v::before {
    content: "\f0a7c"
}

.mdi-tshirt-v-outline::before {
    content: "\f0540"
}

.mdi-tumble-dryer::before {
    content: "\f0917"
}

.mdi-tumble-dryer-alert::before {
    content: "\f11ba"
}

.mdi-tumble-dryer-off::before {
    content: "\f11bb"
}

.mdi-tune::before {
    content: "\f062e"
}

.mdi-tune-variant::before {
    content: "\f1542"
}

.mdi-tune-vertical::before {
    content: "\f066a"
}

.mdi-tune-vertical-variant::before {
    content: "\f1543"
}

.mdi-tunnel::before {
    content: "\f183d"
}

.mdi-tunnel-outline::before {
    content: "\f183e"
}

.mdi-turkey::before {
    content: "\f171b"
}

.mdi-turnstile::before {
    content: "\f0cd5"
}

.mdi-turnstile-outline::before {
    content: "\f0cd6"
}

.mdi-turtle::before {
    content: "\f0cd7"
}

.mdi-twitch::before {
    content: "\f0543"
}

.mdi-twitter::before {
    content: "\f0544"
}

.mdi-two-factor-authentication::before {
    content: "\f09af"
}

.mdi-typewriter::before {
    content: "\f0f2d"
}

.mdi-ubisoft::before {
    content: "\f0bda"
}

.mdi-ubuntu::before {
    content: "\f0548"
}

.mdi-ufo::before {
    content: "\f10c4"
}

.mdi-ufo-outline::before {
    content: "\f10c5"
}

.mdi-ultra-high-definition::before {
    content: "\f07f9"
}

.mdi-umbraco::before {
    content: "\f0549"
}

.mdi-umbrella::before {
    content: "\f054a"
}

.mdi-umbrella-beach::before {
    content: "\f188a"
}

.mdi-umbrella-beach-outline::before {
    content: "\f188b"
}

.mdi-umbrella-closed::before {
    content: "\f09b0"
}

.mdi-umbrella-closed-outline::before {
    content: "\f13e2"
}

.mdi-umbrella-closed-variant::before {
    content: "\f13e1"
}

.mdi-umbrella-outline::before {
    content: "\f054b"
}

.mdi-undo::before {
    content: "\f054c"
}

.mdi-undo-variant::before {
    content: "\f054d"
}

.mdi-unfold-less-horizontal::before {
    content: "\f054e"
}

.mdi-unfold-less-vertical::before {
    content: "\f0760"
}

.mdi-unfold-more-horizontal::before {
    content: "\f054f"
}

.mdi-unfold-more-vertical::before {
    content: "\f0761"
}

.mdi-ungroup::before {
    content: "\f0550"
}

.mdi-unicode::before {
    content: "\f0ed0"
}

.mdi-unicorn::before {
    content: "\f15c2"
}

.mdi-unicorn-variant::before {
    content: "\f15c3"
}

.mdi-unicycle::before {
    content: "\f15e5"
}

.mdi-unity::before {
    content: "\f06af"
}

.mdi-unreal::before {
    content: "\f09b1"
}

.mdi-update::before {
    content: "\f06b0"
}

.mdi-upload::before {
    content: "\f0552"
}

.mdi-upload-lock::before {
    content: "\f1373"
}

.mdi-upload-lock-outline::before {
    content: "\f1374"
}

.mdi-upload-multiple::before {
    content: "\f083d"
}

.mdi-upload-network::before {
    content: "\f06f6"
}

.mdi-upload-network-outline::before {
    content: "\f0cd8"
}

.mdi-upload-off::before {
    content: "\f10c6"
}

.mdi-upload-off-outline::before {
    content: "\f10c7"
}

.mdi-upload-outline::before {
    content: "\f0e07"
}

.mdi-usb::before {
    content: "\f0553"
}

.mdi-usb-flash-drive::before {
    content: "\f129e"
}

.mdi-usb-flash-drive-outline::before {
    content: "\f129f"
}

.mdi-usb-port::before {
    content: "\f11f0"
}

.mdi-vacuum::before {
    content: "\f19a1"
}

.mdi-vacuum-outline::before {
    content: "\f19a2"
}

.mdi-valve::before {
    content: "\f1066"
}

.mdi-valve-closed::before {
    content: "\f1067"
}

.mdi-valve-open::before {
    content: "\f1068"
}

.mdi-van-passenger::before {
    content: "\f07fa"
}

.mdi-van-utility::before {
    content: "\f07fb"
}

.mdi-vanish::before {
    content: "\f07fc"
}

.mdi-vanish-quarter::before {
    content: "\f1554"
}

.mdi-vanity-light::before {
    content: "\f11e1"
}

.mdi-variable::before {
    content: "\f0ae7"
}

.mdi-variable-box::before {
    content: "\f1111"
}

.mdi-vector-arrange-above::before {
    content: "\f0554"
}

.mdi-vector-arrange-below::before {
    content: "\f0555"
}

.mdi-vector-bezier::before {
    content: "\f0ae8"
}

.mdi-vector-circle::before {
    content: "\f0556"
}

.mdi-vector-circle-variant::before {
    content: "\f0557"
}

.mdi-vector-combine::before {
    content: "\f0558"
}

.mdi-vector-curve::before {
    content: "\f0559"
}

.mdi-vector-difference::before {
    content: "\f055a"
}

.mdi-vector-difference-ab::before {
    content: "\f055b"
}

.mdi-vector-difference-ba::before {
    content: "\f055c"
}

.mdi-vector-ellipse::before {
    content: "\f0893"
}

.mdi-vector-intersection::before {
    content: "\f055d"
}

.mdi-vector-line::before {
    content: "\f055e"
}

.mdi-vector-link::before {
    content: "\f0fe8"
}

.mdi-vector-point::before {
    content: "\f055f"
}

.mdi-vector-polygon::before {
    content: "\f0560"
}

.mdi-vector-polygon-variant::before {
    content: "\f1856"
}

.mdi-vector-polyline::before {
    content: "\f0561"
}

.mdi-vector-polyline-edit::before {
    content: "\f1225"
}

.mdi-vector-polyline-minus::before {
    content: "\f1226"
}

.mdi-vector-polyline-plus::before {
    content: "\f1227"
}

.mdi-vector-polyline-remove::before {
    content: "\f1228"
}

.mdi-vector-radius::before {
    content: "\f074a"
}

.mdi-vector-rectangle::before {
    content: "\f05c6"
}

.mdi-vector-selection::before {
    content: "\f0562"
}

.mdi-vector-square::before {
    content: "\f0001"
}

.mdi-vector-square-close::before {
    content: "\f1857"
}

.mdi-vector-square-edit::before {
    content: "\f18d9"
}

.mdi-vector-square-minus::before {
    content: "\f18da"
}

.mdi-vector-square-open::before {
    content: "\f1858"
}

.mdi-vector-square-plus::before {
    content: "\f18db"
}

.mdi-vector-square-remove::before {
    content: "\f18dc"
}

.mdi-vector-triangle::before {
    content: "\f0563"
}

.mdi-vector-union::before {
    content: "\f0564"
}

.mdi-vhs::before {
    content: "\f0a1b"
}

.mdi-vibrate::before {
    content: "\f0566"
}

.mdi-vibrate-off::before {
    content: "\f0cd9"
}

.mdi-video::before {
    content: "\f0567"
}

.mdi-video-3d::before {
    content: "\f07fd"
}

.mdi-video-3d-off::before {
    content: "\f13d9"
}

.mdi-video-3d-variant::before {
    content: "\f0ed1"
}

.mdi-video-4k-box::before {
    content: "\f083e"
}

.mdi-video-account::before {
    content: "\f0919"
}

.mdi-video-box::before {
    content: "\f00fd"
}

.mdi-video-box-off::before {
    content: "\f00fe"
}

.mdi-video-check::before {
    content: "\f1069"
}

.mdi-video-check-outline::before {
    content: "\f106a"
}

.mdi-video-high-definition::before {
    content: "\f152e"
}

.mdi-video-image::before {
    content: "\f091a"
}

.mdi-video-input-antenna::before {
    content: "\f083f"
}

.mdi-video-input-component::before {
    content: "\f0840"
}

.mdi-video-input-hdmi::before {
    content: "\f0841"
}

.mdi-video-input-scart::before {
    content: "\f0f8c"
}

.mdi-video-input-svideo::before {
    content: "\f0842"
}

.mdi-video-marker::before {
    content: "\f19a9"
}

.mdi-video-marker-outline::before {
    content: "\f19aa"
}

.mdi-video-minus::before {
    content: "\f09b2"
}

.mdi-video-minus-outline::before {
    content: "\f02ba"
}

.mdi-video-off::before {
    content: "\f0568"
}

.mdi-video-off-outline::before {
    content: "\f0bdb"
}

.mdi-video-outline::before {
    content: "\f0bdc"
}

.mdi-video-plus::before {
    content: "\f09b3"
}

.mdi-video-plus-outline::before {
    content: "\f01d3"
}

.mdi-video-stabilization::before {
    content: "\f091b"
}

.mdi-video-switch::before {
    content: "\f0569"
}

.mdi-video-switch-outline::before {
    content: "\f0790"
}

.mdi-video-vintage::before {
    content: "\f0a1c"
}

.mdi-video-wireless::before {
    content: "\f0ed2"
}

.mdi-video-wireless-outline::before {
    content: "\f0ed3"
}

.mdi-view-agenda::before {
    content: "\f056a"
}

.mdi-view-agenda-outline::before {
    content: "\f11d8"
}

.mdi-view-array::before {
    content: "\f056b"
}

.mdi-view-array-outline::before {
    content: "\f1485"
}

.mdi-view-carousel::before {
    content: "\f056c"
}

.mdi-view-carousel-outline::before {
    content: "\f1486"
}

.mdi-view-column::before {
    content: "\f056d"
}

.mdi-view-column-outline::before {
    content: "\f1487"
}

.mdi-view-comfy::before {
    content: "\f0e6a"
}

.mdi-view-comfy-outline::before {
    content: "\f1488"
}

.mdi-view-compact::before {
    content: "\f0e6b"
}

.mdi-view-compact-outline::before {
    content: "\f0e6c"
}

.mdi-view-dashboard::before {
    content: "\f056e"
}

.mdi-view-dashboard-edit::before {
    content: "\f1947"
}

.mdi-view-dashboard-edit-outline::before {
    content: "\f1948"
}

.mdi-view-dashboard-outline::before {
    content: "\f0a1d"
}

.mdi-view-dashboard-variant::before {
    content: "\f0843"
}

.mdi-view-dashboard-variant-outline::before {
    content: "\f1489"
}

.mdi-view-day::before {
    content: "\f056f"
}

.mdi-view-day-outline::before {
    content: "\f148a"
}

.mdi-view-gallery::before {
    content: "\f1888"
}

.mdi-view-gallery-outline::before {
    content: "\f1889"
}

.mdi-view-grid::before {
    content: "\f0570"
}

.mdi-view-grid-outline::before {
    content: "\f11d9"
}

.mdi-view-grid-plus::before {
    content: "\f0f8d"
}

.mdi-view-grid-plus-outline::before {
    content: "\f11da"
}

.mdi-view-headline::before {
    content: "\f0571"
}

.mdi-view-list::before {
    content: "\f0572"
}

.mdi-view-list-outline::before {
    content: "\f148b"
}

.mdi-view-module::before {
    content: "\f0573"
}

.mdi-view-module-outline::before {
    content: "\f148c"
}

.mdi-view-parallel::before {
    content: "\f0728"
}

.mdi-view-parallel-outline::before {
    content: "\f148d"
}

.mdi-view-quilt::before {
    content: "\f0574"
}

.mdi-view-quilt-outline::before {
    content: "\f148e"
}

.mdi-view-sequential::before {
    content: "\f0729"
}

.mdi-view-sequential-outline::before {
    content: "\f148f"
}

.mdi-view-split-horizontal::before {
    content: "\f0bcb"
}

.mdi-view-split-vertical::before {
    content: "\f0bcc"
}

.mdi-view-stream::before {
    content: "\f0575"
}

.mdi-view-stream-outline::before {
    content: "\f1490"
}

.mdi-view-week::before {
    content: "\f0576"
}

.mdi-view-week-outline::before {
    content: "\f1491"
}

.mdi-vimeo::before {
    content: "\f0577"
}

.mdi-violin::before {
    content: "\f060f"
}

.mdi-virtual-reality::before {
    content: "\f0894"
}

.mdi-virus::before {
    content: "\f13b6"
}

.mdi-virus-off::before {
    content: "\f18e1"
}

.mdi-virus-off-outline::before {
    content: "\f18e2"
}

.mdi-virus-outline::before {
    content: "\f13b7"
}

.mdi-vlc::before {
    content: "\f057c"
}

.mdi-voicemail::before {
    content: "\f057d"
}

.mdi-volleyball::before {
    content: "\f09b4"
}

.mdi-volume-high::before {
    content: "\f057e"
}

.mdi-volume-low::before {
    content: "\f057f"
}

.mdi-volume-medium::before {
    content: "\f0580"
}

.mdi-volume-minus::before {
    content: "\f075e"
}

.mdi-volume-mute::before {
    content: "\f075f"
}

.mdi-volume-off::before {
    content: "\f0581"
}

.mdi-volume-plus::before {
    content: "\f075d"
}

.mdi-volume-source::before {
    content: "\f1120"
}

.mdi-volume-variant-off::before {
    content: "\f0e08"
}

.mdi-volume-vibrate::before {
    content: "\f1121"
}

.mdi-vote::before {
    content: "\f0a1f"
}

.mdi-vote-outline::before {
    content: "\f0a20"
}

.mdi-vpn::before {
    content: "\f0582"
}

.mdi-vuejs::before {
    content: "\f0844"
}

.mdi-vuetify::before {
    content: "\f0e6d"
}

.mdi-walk::before {
    content: "\f0583"
}

.mdi-wall::before {
    content: "\f07fe"
}

.mdi-wall-sconce::before {
    content: "\f091c"
}

.mdi-wall-sconce-flat::before {
    content: "\f091d"
}

.mdi-wall-sconce-flat-outline::before {
    content: "\f17c9"
}

.mdi-wall-sconce-flat-variant::before {
    content: "\f041c"
}

.mdi-wall-sconce-flat-variant-outline::before {
    content: "\f17ca"
}

.mdi-wall-sconce-outline::before {
    content: "\f17cb"
}

.mdi-wall-sconce-round::before {
    content: "\f0748"
}

.mdi-wall-sconce-round-outline::before {
    content: "\f17cc"
}

.mdi-wall-sconce-round-variant::before {
    content: "\f091e"
}

.mdi-wall-sconce-round-variant-outline::before {
    content: "\f17cd"
}

.mdi-wallet::before {
    content: "\f0584"
}

.mdi-wallet-giftcard::before {
    content: "\f0585"
}

.mdi-wallet-membership::before {
    content: "\f0586"
}

.mdi-wallet-outline::before {
    content: "\f0bdd"
}

.mdi-wallet-plus::before {
    content: "\f0f8e"
}

.mdi-wallet-plus-outline::before {
    content: "\f0f8f"
}

.mdi-wallet-travel::before {
    content: "\f0587"
}

.mdi-wallpaper::before {
    content: "\f0e09"
}

.mdi-wan::before {
    content: "\f0588"
}

.mdi-wardrobe::before {
    content: "\f0f90"
}

.mdi-wardrobe-outline::before {
    content: "\f0f91"
}

.mdi-warehouse::before {
    content: "\f0f81"
}

.mdi-washing-machine::before {
    content: "\f072a"
}

.mdi-washing-machine-alert::before {
    content: "\f11bc"
}

.mdi-washing-machine-off::before {
    content: "\f11bd"
}

.mdi-watch::before {
    content: "\f0589"
}

.mdi-watch-export::before {
    content: "\f058a"
}

.mdi-watch-export-variant::before {
    content: "\f0895"
}

.mdi-watch-import::before {
    content: "\f058b"
}

.mdi-watch-import-variant::before {
    content: "\f0896"
}

.mdi-watch-variant::before {
    content: "\f0897"
}

.mdi-watch-vibrate::before {
    content: "\f06b1"
}

.mdi-watch-vibrate-off::before {
    content: "\f0cda"
}

.mdi-water::before {
    content: "\f058c"
}

.mdi-water-alert::before {
    content: "\f1502"
}

.mdi-water-alert-outline::before {
    content: "\f1503"
}

.mdi-water-boiler::before {
    content: "\f0f92"
}

.mdi-water-boiler-alert::before {
    content: "\f11b3"
}

.mdi-water-boiler-off::before {
    content: "\f11b4"
}

.mdi-water-check::before {
    content: "\f1504"
}

.mdi-water-check-outline::before {
    content: "\f1505"
}

.mdi-water-circle::before {
    content: "\f1806"
}

.mdi-water-minus::before {
    content: "\f1506"
}

.mdi-water-minus-outline::before {
    content: "\f1507"
}

.mdi-water-off::before {
    content: "\f058d"
}

.mdi-water-off-outline::before {
    content: "\f1508"
}

.mdi-water-opacity::before {
    content: "\f1855"
}

.mdi-water-outline::before {
    content: "\f0e0a"
}

.mdi-water-percent::before {
    content: "\f058e"
}

.mdi-water-percent-alert::before {
    content: "\f1509"
}

.mdi-water-plus::before {
    content: "\f150a"
}

.mdi-water-plus-outline::before {
    content: "\f150b"
}

.mdi-water-polo::before {
    content: "\f12a0"
}

.mdi-water-pump::before {
    content: "\f058f"
}

.mdi-water-pump-off::before {
    content: "\f0f93"
}

.mdi-water-remove::before {
    content: "\f150c"
}

.mdi-water-remove-outline::before {
    content: "\f150d"
}

.mdi-water-sync::before {
    content: "\f17c6"
}

.mdi-water-well::before {
    content: "\f106b"
}

.mdi-water-well-outline::before {
    content: "\f106c"
}

.mdi-waterfall::before {
    content: "\f1849"
}

.mdi-watering-can::before {
    content: "\f1481"
}

.mdi-watering-can-outline::before {
    content: "\f1482"
}

.mdi-watermark::before {
    content: "\f0612"
}

.mdi-wave::before {
    content: "\f0f2e"
}

.mdi-waveform::before {
    content: "\f147d"
}

.mdi-waves::before {
    content: "\f078d"
}

.mdi-waves-arrow-left::before {
    content: "\f1859"
}

.mdi-waves-arrow-right::before {
    content: "\f185a"
}

.mdi-waves-arrow-up::before {
    content: "\f185b"
}

.mdi-waze::before {
    content: "\f0bde"
}

.mdi-weather-cloudy::before {
    content: "\f0590"
}

.mdi-weather-cloudy-alert::before {
    content: "\f0f2f"
}

.mdi-weather-cloudy-arrow-right::before {
    content: "\f0e6e"
}

.mdi-weather-cloudy-clock::before {
    content: "\f18f6"
}

.mdi-weather-fog::before {
    content: "\f0591"
}

.mdi-weather-hail::before {
    content: "\f0592"
}

.mdi-weather-hazy::before {
    content: "\f0f30"
}

.mdi-weather-hurricane::before {
    content: "\f0898"
}

.mdi-weather-lightning::before {
    content: "\f0593"
}

.mdi-weather-lightning-rainy::before {
    content: "\f067e"
}

.mdi-weather-night::before {
    content: "\f0594"
}

.mdi-weather-night-partly-cloudy::before {
    content: "\f0f31"
}

.mdi-weather-partly-cloudy::before {
    content: "\f0595"
}

.mdi-weather-partly-lightning::before {
    content: "\f0f32"
}

.mdi-weather-partly-rainy::before {
    content: "\f0f33"
}

.mdi-weather-partly-snowy::before {
    content: "\f0f34"
}

.mdi-weather-partly-snowy-rainy::before {
    content: "\f0f35"
}

.mdi-weather-pouring::before {
    content: "\f0596"
}

.mdi-weather-rainy::before {
    content: "\f0597"
}

.mdi-weather-snowy::before {
    content: "\f0598"
}

.mdi-weather-snowy-heavy::before {
    content: "\f0f36"
}

.mdi-weather-snowy-rainy::before {
    content: "\f067f"
}

.mdi-weather-sunny::before {
    content: "\f0599"
}

.mdi-weather-sunny-alert::before {
    content: "\f0f37"
}

.mdi-weather-sunny-off::before {
    content: "\f14e4"
}

.mdi-weather-sunset::before {
    content: "\f059a"
}

.mdi-weather-sunset-down::before {
    content: "\f059b"
}

.mdi-weather-sunset-up::before {
    content: "\f059c"
}

.mdi-weather-tornado::before {
    content: "\f0f38"
}

.mdi-weather-windy::before {
    content: "\f059d"
}

.mdi-weather-windy-variant::before {
    content: "\f059e"
}

.mdi-web::before {
    content: "\f059f"
}

.mdi-web-box::before {
    content: "\f0f94"
}

.mdi-web-cancel::before {
    content: "\f1790"
}

.mdi-web-check::before {
    content: "\f0789"
}

.mdi-web-clock::before {
    content: "\f124a"
}

.mdi-web-minus::before {
    content: "\f10a0"
}

.mdi-web-off::before {
    content: "\f0a8e"
}

.mdi-web-plus::before {
    content: "\f0033"
}

.mdi-web-refresh::before {
    content: "\f1791"
}

.mdi-web-remove::before {
    content: "\f0551"
}

.mdi-web-sync::before {
    content: "\f1792"
}

.mdi-webcam::before {
    content: "\f05a0"
}

.mdi-webcam-off::before {
    content: "\f1737"
}

.mdi-webhook::before {
    content: "\f062f"
}

.mdi-webpack::before {
    content: "\f072b"
}

.mdi-webrtc::before {
    content: "\f1248"
}

.mdi-wechat::before {
    content: "\f0611"
}

.mdi-weight::before {
    content: "\f05a1"
}

.mdi-weight-gram::before {
    content: "\f0d3f"
}

.mdi-weight-kilogram::before {
    content: "\f05a2"
}

.mdi-weight-lifter::before {
    content: "\f115d"
}

.mdi-weight-pound::before {
    content: "\f09b5"
}

.mdi-whatsapp::before {
    content: "\f05a3"
}

.mdi-wheel-barrow::before {
    content: "\f14f2"
}

.mdi-wheelchair-accessibility::before {
    content: "\f05a4"
}

.mdi-whistle::before {
    content: "\f09b6"
}

.mdi-whistle-outline::before {
    content: "\f12bc"
}

.mdi-white-balance-auto::before {
    content: "\f05a5"
}

.mdi-white-balance-incandescent::before {
    content: "\f05a6"
}

.mdi-white-balance-iridescent::before {
    content: "\f05a7"
}

.mdi-white-balance-sunny::before {
    content: "\f05a8"
}

.mdi-widgets::before {
    content: "\f072c"
}

.mdi-widgets-outline::before {
    content: "\f1355"
}

.mdi-wifi::before {
    content: "\f05a9"
}

.mdi-wifi-alert::before {
    content: "\f16b5"
}

.mdi-wifi-arrow-down::before {
    content: "\f16b6"
}

.mdi-wifi-arrow-left::before {
    content: "\f16b7"
}

.mdi-wifi-arrow-left-right::before {
    content: "\f16b8"
}

.mdi-wifi-arrow-right::before {
    content: "\f16b9"
}

.mdi-wifi-arrow-up::before {
    content: "\f16ba"
}

.mdi-wifi-arrow-up-down::before {
    content: "\f16bb"
}

.mdi-wifi-cancel::before {
    content: "\f16bc"
}

.mdi-wifi-check::before {
    content: "\f16bd"
}

.mdi-wifi-cog::before {
    content: "\f16be"
}

.mdi-wifi-lock::before {
    content: "\f16bf"
}

.mdi-wifi-lock-open::before {
    content: "\f16c0"
}

.mdi-wifi-marker::before {
    content: "\f16c1"
}

.mdi-wifi-minus::before {
    content: "\f16c2"
}

.mdi-wifi-off::before {
    content: "\f05aa"
}

.mdi-wifi-plus::before {
    content: "\f16c3"
}

.mdi-wifi-refresh::before {
    content: "\f16c4"
}

.mdi-wifi-remove::before {
    content: "\f16c5"
}

.mdi-wifi-settings::before {
    content: "\f16c6"
}

.mdi-wifi-star::before {
    content: "\f0e0b"
}

.mdi-wifi-strength-1::before {
    content: "\f091f"
}

.mdi-wifi-strength-1-alert::before {
    content: "\f0920"
}

.mdi-wifi-strength-1-lock::before {
    content: "\f0921"
}

.mdi-wifi-strength-1-lock-open::before {
    content: "\f16cb"
}

.mdi-wifi-strength-2::before {
    content: "\f0922"
}

.mdi-wifi-strength-2-alert::before {
    content: "\f0923"
}

.mdi-wifi-strength-2-lock::before {
    content: "\f0924"
}

.mdi-wifi-strength-2-lock-open::before {
    content: "\f16cc"
}

.mdi-wifi-strength-3::before {
    content: "\f0925"
}

.mdi-wifi-strength-3-alert::before {
    content: "\f0926"
}

.mdi-wifi-strength-3-lock::before {
    content: "\f0927"
}

.mdi-wifi-strength-3-lock-open::before {
    content: "\f16cd"
}

.mdi-wifi-strength-4::before {
    content: "\f0928"
}

.mdi-wifi-strength-4-alert::before {
    content: "\f0929"
}

.mdi-wifi-strength-4-lock::before {
    content: "\f092a"
}

.mdi-wifi-strength-4-lock-open::before {
    content: "\f16ce"
}

.mdi-wifi-strength-alert-outline::before {
    content: "\f092b"
}

.mdi-wifi-strength-lock-open-outline::before {
    content: "\f16cf"
}

.mdi-wifi-strength-lock-outline::before {
    content: "\f092c"
}

.mdi-wifi-strength-off::before {
    content: "\f092d"
}

.mdi-wifi-strength-off-outline::before {
    content: "\f092e"
}

.mdi-wifi-strength-outline::before {
    content: "\f092f"
}

.mdi-wifi-sync::before {
    content: "\f16c7"
}

.mdi-wikipedia::before {
    content: "\f05ac"
}

.mdi-wind-turbine::before {
    content: "\f0da5"
}

.mdi-wind-turbine-alert::before {
    content: "\f19ab"
}

.mdi-wind-turbine-check::before {
    content: "\f19ac"
}

.mdi-window-close::before {
    content: "\f05ad"
}

.mdi-window-closed::before {
    content: "\f05ae"
}

.mdi-window-closed-variant::before {
    content: "\f11db"
}

.mdi-window-maximize::before {
    content: "\f05af"
}

.mdi-window-minimize::before {
    content: "\f05b0"
}

.mdi-window-open::before {
    content: "\f05b1"
}

.mdi-window-open-variant::before {
    content: "\f11dc"
}

.mdi-window-restore::before {
    content: "\f05b2"
}

.mdi-window-shutter::before {
    content: "\f111c"
}

.mdi-window-shutter-alert::before {
    content: "\f111d"
}

.mdi-window-shutter-open::before {
    content: "\f111e"
}

.mdi-windsock::before {
    content: "\f15fa"
}

.mdi-wiper::before {
    content: "\f0ae9"
}

.mdi-wiper-wash::before {
    content: "\f0da6"
}

.mdi-wiper-wash-alert::before {
    content: "\f18df"
}

.mdi-wizard-hat::before {
    content: "\f1477"
}

.mdi-wordpress::before {
    content: "\f05b4"
}

.mdi-wrap::before {
    content: "\f05b6"
}

.mdi-wrap-disabled::before {
    content: "\f0bdf"
}

.mdi-wrench::before {
    content: "\f05b7"
}

.mdi-wrench-clock::before {
    content: "\f19a3"
}

.mdi-wrench-outline::before {
    content: "\f0be0"
}

.mdi-xamarin::before {
    content: "\f0845"
}

.mdi-xml::before {
    content: "\f05c0"
}

.mdi-xmpp::before {
    content: "\f07ff"
}

.mdi-yahoo::before {
    content: "\f0b4f"
}

.mdi-yeast::before {
    content: "\f05c1"
}

.mdi-yin-yang::before {
    content: "\f0680"
}

.mdi-yoga::before {
    content: "\f117c"
}

.mdi-youtube::before {
    content: "\f05c3"
}

.mdi-youtube-gaming::before {
    content: "\f0848"
}

.mdi-youtube-studio::before {
    content: "\f0847"
}

.mdi-youtube-subscription::before {
    content: "\f0d40"
}

.mdi-youtube-tv::before {
    content: "\f0448"
}

.mdi-yurt::before {
    content: "\f1516"
}

.mdi-z-wave::before {
    content: "\f0aea"
}

.mdi-zend::before {
    content: "\f0aeb"
}

.mdi-zigbee::before {
    content: "\f0d41"
}

.mdi-zip-box::before {
    content: "\f05c4"
}

.mdi-zip-box-outline::before {
    content: "\f0ffa"
}

.mdi-zip-disk::before {
    content: "\f0a23"
}

.mdi-zodiac-aquarius::before {
    content: "\f0a7d"
}

.mdi-zodiac-aries::before {
    content: "\f0a7e"
}

.mdi-zodiac-cancer::before {
    content: "\f0a7f"
}

.mdi-zodiac-capricorn::before {
    content: "\f0a80"
}

.mdi-zodiac-gemini::before {
    content: "\f0a81"
}

.mdi-zodiac-leo::before {
    content: "\f0a82"
}

.mdi-zodiac-libra::before {
    content: "\f0a83"
}

.mdi-zodiac-pisces::before {
    content: "\f0a84"
}

.mdi-zodiac-sagittarius::before {
    content: "\f0a85"
}

.mdi-zodiac-scorpio::before {
    content: "\f0a86"
}

.mdi-zodiac-taurus::before {
    content: "\f0a87"
}

.mdi-zodiac-virgo::before {
    content: "\f0a88"
}

.mdi-blank::before {
    content: "\f68c";
    visibility: hidden
}

.mdi-18px.mdi-set,
.mdi-18px.mdi:before {
    font-size: 18px
}

.mdi-24px.mdi-set,
.mdi-24px.mdi:before {
    font-size: 24px
}

.mdi-36px.mdi-set,
.mdi-36px.mdi:before {
    font-size: 36px
}

.mdi-48px.mdi-set,
.mdi-48px.mdi:before {
    font-size: 48px
}

.mdi-dark:before {
    color: rgba(0, 0, 0, .54)
}

.mdi-dark.mdi-inactive:before {
    color: rgba(0, 0, 0, .26)
}

.mdi-light:before {
    color: #fff
}

.mdi-light.mdi-inactive:before {
    color: rgba(255, 255, 255, .3)
}

.mdi-rotate-45:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.mdi-rotate-90:before {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.mdi-rotate-135:before {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg)
}

.mdi-rotate-180:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.mdi-rotate-225:before {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg)
}

.mdi-rotate-270:before {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg)
}

.mdi-rotate-315:before {
    -webkit-transform: rotate(315deg);
    transform: rotate(315deg)
}

.mdi-flip-h:before {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    -webkit-filter: FlipH;
    filter: FlipH;
    -ms-filter: FlipH
}

.mdi-flip-v:before {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    -webkit-filter: FlipV;
    filter: FlipV;
    -ms-filter: FlipV
}

.mdi-spin:before {
    -webkit-animation: mdi-spin 2s infinite linear;
    animation: mdi-spin 2s infinite linear
}

@-webkit-keyframes mdi-spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@keyframes mdi-spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@font-face {
    font-family: boxicons;
    font-weight: 400;
    font-style: normal;
    src: url(../fonts/boxicons.eot);
    src: url(../fonts/boxicons.eot) format("embedded-opentype"), url(../fonts/boxicons.html) format("woff2"), url(../fonts/boxicons.woff) format("woff"), url(../fonts/boxicons.ttf) format("truetype"), url(../fonts/boxiconsd41d.svg?#boxicons) format("svg")
}

.bx {
    font-family: boxicons !important;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
    display: inline-block;
    text-transform: none;
    /* speak: none; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.bx-ul {
    margin-left: 2em;
    padding-left: 0;
    list-style: none
}

.bx-ul>li {
    position: relative
}

.bx-ul .bx {
    font-size: inherit;
    line-height: inherit;
    position: absolute;
    left: -2em;
    width: 2em;
    text-align: center
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@-webkit-keyframes burst {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }

    90% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0
    }
}

@keyframes burst {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }

    90% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0
    }
}

@-webkit-keyframes flashing {
    0% {
        opacity: 1
    }

    45% {
        opacity: 0
    }

    90% {
        opacity: 1
    }
}

@keyframes flashing {
    0% {
        opacity: 1
    }

    45% {
        opacity: 0
    }

    90% {
        opacity: 1
    }
}

@-webkit-keyframes fade-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }

    75% {
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
        opacity: 0
    }
}

@keyframes fade-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }

    75% {
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
        opacity: 0
    }
}

@-webkit-keyframes fade-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }

    75% {
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
        opacity: 0
    }
}

@keyframes fade-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }

    75% {
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
        opacity: 0
    }
}

@-webkit-keyframes fade-up {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }

    75% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0
    }
}

@keyframes fade-up {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }

    75% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0
    }
}

@-webkit-keyframes fade-down {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }

    75% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0
    }
}

@keyframes fade-down {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }

    75% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0
    }
}

@-webkit-keyframes tada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    10%,
    20% {
        -webkit-transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg);
        transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg)
    }

    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
        transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg)
    }

    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
        transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg)
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

@keyframes tada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    10%,
    20% {
        -webkit-transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg);
        transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg)
    }

    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
        transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg)
    }

    40%,
    60%,
    80% {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
        transform: rotate3d(0, 0, 1, -10deg)
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

.bx-spin {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite
}

.bx-spin-hover:hover {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite
}

.bx-tada {
    -webkit-animation: tada 1.5s ease infinite;
    animation: tada 1.5s ease infinite
}

.bx-tada-hover:hover {
    -webkit-animation: tada 1.5s ease infinite;
    animation: tada 1.5s ease infinite
}

.bx-flashing {
    -webkit-animation: flashing 1.5s infinite linear;
    animation: flashing 1.5s infinite linear
}

.bx-flashing-hover:hover {
    -webkit-animation: flashing 1.5s infinite linear;
    animation: flashing 1.5s infinite linear
}

.bx-burst {
    -webkit-animation: burst 1.5s infinite linear;
    animation: burst 1.5s infinite linear
}

.bx-burst-hover:hover {
    -webkit-animation: burst 1.5s infinite linear;
    animation: burst 1.5s infinite linear
}

.bx-fade-up {
    -webkit-animation: fade-up 1.5s infinite linear;
    animation: fade-up 1.5s infinite linear
}

.bx-fade-up-hover:hover {
    -webkit-animation: fade-up 1.5s infinite linear;
    animation: fade-up 1.5s infinite linear
}

.bx-fade-down {
    -webkit-animation: fade-down 1.5s infinite linear;
    animation: fade-down 1.5s infinite linear
}

.bx-fade-down-hover:hover {
    -webkit-animation: fade-down 1.5s infinite linear;
    animation: fade-down 1.5s infinite linear
}

.bx-fade-left {
    -webkit-animation: fade-left 1.5s infinite linear;
    animation: fade-left 1.5s infinite linear
}

.bx-fade-left-hover:hover {
    -webkit-animation: fade-left 1.5s infinite linear;
    animation: fade-left 1.5s infinite linear
}

.bx-fade-right {
    -webkit-animation: fade-right 1.5s infinite linear;
    animation: fade-right 1.5s infinite linear
}

.bx-fade-right-hover:hover {
    -webkit-animation: fade-right 1.5s infinite linear;
    animation: fade-right 1.5s infinite linear
}

.bx-xs {
    font-size: 1rem !important
}

.bx-sm {
    font-size: 1.55rem !important
}

.bx-md {
    font-size: 2.25rem !important
}

.bx-lg {
    font-size: 3rem !important
}

.bx-fw {
    font-size: 1.2857142857em;
    line-height: .8em;
    width: 1.2857142857em;
    height: .8em;
    margin-top: -.2em !important;
    vertical-align: middle
}

.bx-pull-left {
    float: left;
    margin-right: .3em !important
}

.bx-pull-right {
    float: right;
    margin-left: .3em !important
}

.bx-rotate-90 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.bx-rotate-180 {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.bx-rotate-270 {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg)
}

.bx-flip-horizontal {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1)
}

.bx-flip-vertical {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1)
}

.bx-border {
    padding: .25em;
    border: .07em solid rgba(0, 0, 0, .1);
    border-radius: .25em
}

.bx-border-circle {
    padding: .25em;
    border: .07em solid rgba(0, 0, 0, .1);
    border-radius: 50%
}

.bxl-meta:before {
    content: "\ef27"
}

.bx-lemon:before {
    content: "\ef28"
}

.bxs-lemon:before {
    content: "\ef29"
}

.bx-cricket-ball:before {
    content: "\ef0c"
}

.bx-baguette:before {
    content: "\ef0d"
}

.bx-bowl-hot:before {
    content: "\ef0e"
}

.bx-bowl-rice:before {
    content: "\ef0f"
}

.bx-cable-car:before {
    content: "\ef10"
}

.bx-candles:before {
    content: "\ef11"
}

.bx-circle-half:before {
    content: "\ef12"
}

.bx-circle-quarter:before {
    content: "\ef13"
}

.bx-circle-three-quarter:before {
    content: "\ef14"
}

.bx-cross:before {
    content: "\ef15"
}

.bx-fork:before {
    content: "\ef16"
}

.bx-knife:before {
    content: "\ef17"
}

.bx-money-withdraw:before {
    content: "\ef18"
}

.bx-popsicle:before {
    content: "\ef19"
}

.bx-scatter-chart:before {
    content: "\ef1a"
}

.bxs-baguette:before {
    content: "\ef1b"
}

.bxs-bowl-hot:before {
    content: "\ef1c"
}

.bxs-bowl-rice:before {
    content: "\ef1d"
}

.bxs-cable-car:before {
    content: "\ef1e"
}

.bxs-circle-half:before {
    content: "\ef1f"
}

.bxs-circle-quarter:before {
    content: "\ef20"
}

.bxs-circle-three-quarter:before {
    content: "\ef21"
}

.bxs-cricket-ball:before {
    content: "\ef22"
}

.bxs-invader:before {
    content: "\ef23"
}

.bx-male-female:before {
    content: "\ef24"
}

.bxs-popsicle:before {
    content: "\ef25"
}

.bxs-tree-alt:before {
    content: "\ef26"
}

.bxl-venmo:before {
    content: "\e900"
}

.bxl-upwork:before {
    content: "\e901"
}

.bxl-netlify:before {
    content: "\e902"
}

.bxl-java:before {
    content: "\e903"
}

.bxl-heroku:before {
    content: "\e904"
}

.bxl-go-lang:before {
    content: "\e905"
}

.bxl-gmail:before {
    content: "\e906"
}

.bxl-flask:before {
    content: "\e907"
}

.bxl-99designs:before {
    content: "\e908"
}

.bxl-500px:before {
    content: "\e909"
}

.bxl-adobe:before {
    content: "\e90a"
}

.bxl-airbnb:before {
    content: "\e90b"
}

.bxl-algolia:before {
    content: "\e90c"
}

.bxl-amazon:before {
    content: "\e90d"
}

.bxl-android:before {
    content: "\e90e"
}

.bxl-angular:before {
    content: "\e90f"
}

.bxl-apple:before {
    content: "\e910"
}

.bxl-audible:before {
    content: "\e911"
}

.bxl-aws:before {
    content: "\e912"
}

.bxl-baidu:before {
    content: "\e913"
}

.bxl-behance:before {
    content: "\e914"
}

.bxl-bing:before {
    content: "\e915"
}

.bxl-bitcoin:before {
    content: "\e916"
}

.bxl-blender:before {
    content: "\e917"
}

.bxl-blogger:before {
    content: "\e918"
}

.bxl-bootstrap:before {
    content: "\e919"
}

.bxl-chrome:before {
    content: "\e91a"
}

.bxl-codepen:before {
    content: "\e91b"
}

.bxl-c-plus-plus:before {
    content: "\e91c"
}

.bxl-creative-commons:before {
    content: "\e91d"
}

.bxl-css3:before {
    content: "\e91e"
}

.bxl-dailymotion:before {
    content: "\e91f"
}

.bxl-deviantart:before {
    content: "\e920"
}

.bxl-dev-to:before {
    content: "\e921"
}

.bxl-digg:before {
    content: "\e922"
}

.bxl-digitalocean:before {
    content: "\e923"
}

.bxl-discord:before {
    content: "\e924"
}

.bxl-discord-alt:before {
    content: "\e925"
}

.bxl-discourse:before {
    content: "\e926"
}

.bxl-django:before {
    content: "\e927"
}

.bxl-docker:before {
    content: "\e928"
}

.bxl-dribbble:before {
    content: "\e929"
}

.bxl-dropbox:before {
    content: "\e92a"
}

.bxl-drupal:before {
    content: "\e92b"
}

.bxl-ebay:before {
    content: "\e92c"
}

.bxl-edge:before {
    content: "\e92d"
}

.bxl-etsy:before {
    content: "\e92e"
}

.bxl-facebook:before {
    content: "\e92f"
}

.bxl-facebook-circle:before {
    content: "\e930"
}

.bxl-facebook-square:before {
    content: "\e931"
}

.bxl-figma:before {
    content: "\e932"
}

.bxl-firebase:before {
    content: "\e933"
}

.bxl-firefox:before {
    content: "\e934"
}

.bxl-flickr:before {
    content: "\e935"
}

.bxl-flickr-square:before {
    content: "\e936"
}

.bxl-flutter:before {
    content: "\e937"
}

.bxl-foursquare:before {
    content: "\e938"
}

.bxl-git:before {
    content: "\e939"
}

.bxl-github:before {
    content: "\e93a"
}

.bxl-gitlab:before {
    content: "\e93b"
}

.bxl-google:before {
    content: "\e93c"
}

.bxl-google-cloud:before {
    content: "\e93d"
}

.bxl-google-plus:before {
    content: "\e93e"
}

.bxl-google-plus-circle:before {
    content: "\e93f"
}

.bxl-html5:before {
    content: "\e940"
}

.bxl-imdb:before {
    content: "\e941"
}

.bxl-instagram:before {
    content: "\e942"
}

.bxl-instagram-alt:before {
    content: "\e943"
}

.bxl-internet-explorer:before {
    content: "\e944"
}

.bxl-invision:before {
    content: "\e945"
}

.bxl-javascript:before {
    content: "\e946"
}

.bxl-joomla:before {
    content: "\e947"
}

.bxl-jquery:before {
    content: "\e948"
}

.bxl-jsfiddle:before {
    content: "\e949"
}

.bxl-kickstarter:before {
    content: "\e94a"
}

.bxl-kubernetes:before {
    content: "\e94b"
}

.bxl-less:before {
    content: "\e94c"
}

.bxl-linkedin:before {
    content: "\e94d"
}

.bxl-linkedin-square:before {
    content: "\e94e"
}

.bxl-magento:before {
    content: "\e94f"
}

.bxl-mailchimp:before {
    content: "\e950"
}

.bxl-markdown:before {
    content: "\e951"
}

.bxl-mastercard:before {
    content: "\e952"
}

.bxl-mastodon:before {
    content: "\e953"
}

.bxl-medium:before {
    content: "\e954"
}

.bxl-medium-old:before {
    content: "\e955"
}

.bxl-medium-square:before {
    content: "\e956"
}

.bxl-messenger:before {
    content: "\e957"
}

.bxl-microsoft:before {
    content: "\e958"
}

.bxl-microsoft-teams:before {
    content: "\e959"
}

.bxl-nodejs:before {
    content: "\e95a"
}

.bxl-ok-ru:before {
    content: "\e95b"
}

.bxl-opera:before {
    content: "\e95c"
}

.bxl-patreon:before {
    content: "\e95d"
}

.bxl-paypal:before {
    content: "\e95e"
}

.bxl-periscope:before {
    content: "\e95f"
}

.bxl-php:before {
    content: "\e960"
}

.bxl-pinterest:before {
    content: "\e961"
}

.bxl-pinterest-alt:before {
    content: "\e962"
}

.bxl-play-store:before {
    content: "\e963"
}

.bxl-pocket:before {
    content: "\e964"
}

.bxl-product-hunt:before {
    content: "\e965"
}

.bxl-python:before {
    content: "\e966"
}

.bxl-quora:before {
    content: "\e967"
}

.bxl-react:before {
    content: "\e968"
}

.bxl-redbubble:before {
    content: "\e969"
}

.bxl-reddit:before {
    content: "\e96a"
}

.bxl-redux:before {
    content: "\e96b"
}

.bxl-sass:before {
    content: "\e96c"
}

.bxl-shopify:before {
    content: "\e96d"
}

.bxl-sketch:before {
    content: "\e96e"
}

.bxl-skype:before {
    content: "\e96f"
}

.bxl-slack:before {
    content: "\e970"
}

.bxl-slack-old:before {
    content: "\e971"
}

.bxl-snapchat:before {
    content: "\e972"
}

.bxl-soundcloud:before {
    content: "\e973"
}

.bxl-spotify:before {
    content: "\e974"
}

.bxl-spring-boot:before {
    content: "\e975"
}

.bxl-squarespace:before {
    content: "\e976"
}

.bxl-stack-overflow:before {
    content: "\e977"
}

.bxl-steam:before {
    content: "\e978"
}

.bxl-stripe:before {
    content: "\e979"
}

.bxl-tailwind-css:before {
    content: "\e97a"
}

.bxl-telegram:before {
    content: "\e97b"
}

.bxl-tiktok:before {
    content: "\e97c"
}

.bxl-trello:before {
    content: "\e97d"
}

.bxl-trip-advisor:before {
    content: "\e97e"
}

.bxl-tumblr:before {
    content: "\e97f"
}

.bxl-tux:before {
    content: "\e980"
}

.bxl-twitch:before {
    content: "\e981"
}

.bxl-twitter:before {
    content: "\e982"
}

.bxl-unity:before {
    content: "\e983"
}

.bxl-unsplash:before {
    content: "\e984"
}

.bxl-vimeo:before {
    content: "\e985"
}

.bxl-visa:before {
    content: "\e986"
}

.bxl-visual-studio:before {
    content: "\e987"
}

.bxl-vk:before {
    content: "\e988"
}

.bxl-vuejs:before {
    content: "\e989"
}

.bxl-whatsapp:before {
    content: "\e98a"
}

.bxl-whatsapp-square:before {
    content: "\e98b"
}

.bxl-wikipedia:before {
    content: "\e98c"
}

.bxl-windows:before {
    content: "\e98d"
}

.bxl-wix:before {
    content: "\e98e"
}

.bxl-wordpress:before {
    content: "\e98f"
}

.bxl-yahoo:before {
    content: "\e990"
}

.bxl-yelp:before {
    content: "\e991"
}

.bxl-youtube:before {
    content: "\e992"
}

.bxl-zoom:before {
    content: "\e993"
}

.bx-collapse-alt:before {
    content: "\e994"
}

.bx-collapse-horizontal:before {
    content: "\e995"
}

.bx-collapse-vertical:before {
    content: "\e996"
}

.bx-expand-horizontal:before {
    content: "\e997"
}

.bx-expand-vertical:before {
    content: "\e998"
}

.bx-injection:before {
    content: "\e999"
}

.bx-leaf:before {
    content: "\e99a"
}

.bx-math:before {
    content: "\e99b"
}

.bx-party:before {
    content: "\e99c"
}

.bx-abacus:before {
    content: "\e99d"
}

.bx-accessibility:before {
    content: "\e99e"
}

.bx-add-to-queue:before {
    content: "\e99f"
}

.bx-adjust:before {
    content: "\e9a0"
}

.bx-alarm:before {
    content: "\e9a1"
}

.bx-alarm-add:before {
    content: "\e9a2"
}

.bx-alarm-exclamation:before {
    content: "\e9a3"
}

.bx-alarm-off:before {
    content: "\e9a4"
}

.bx-alarm-snooze:before {
    content: "\e9a5"
}

.bx-album:before {
    content: "\e9a6"
}

.bx-align-justify:before {
    content: "\e9a7"
}

.bx-align-left:before {
    content: "\e9a8"
}

.bx-align-middle:before {
    content: "\e9a9"
}

.bx-align-right:before {
    content: "\e9aa"
}

.bx-analyse:before {
    content: "\e9ab"
}

.bx-anchor:before {
    content: "\e9ac"
}

.bx-angry:before {
    content: "\e9ad"
}

.bx-aperture:before {
    content: "\e9ae"
}

.bx-arch:before {
    content: "\e9af"
}

.bx-archive:before {
    content: "\e9b0"
}

.bx-archive-in:before {
    content: "\e9b1"
}

.bx-archive-out:before {
    content: "\e9b2"
}

.bx-area:before {
    content: "\e9b3"
}

.bx-arrow-back:before {
    content: "\e9b4"
}

.bx-arrow-from-bottom:before {
    content: "\e9b5"
}

.bx-arrow-from-left:before {
    content: "\e9b6"
}

.bx-arrow-from-right:before {
    content: "\e9b7"
}

.bx-arrow-from-top:before {
    content: "\e9b8"
}

.bx-arrow-to-bottom:before {
    content: "\e9b9"
}

.bx-arrow-to-left:before {
    content: "\e9ba"
}

.bx-arrow-to-right:before {
    content: "\e9bb"
}

.bx-arrow-to-top:before {
    content: "\e9bc"
}

.bx-at:before {
    content: "\e9bd"
}

.bx-atom:before {
    content: "\e9be"
}

.bx-award:before {
    content: "\e9bf"
}

.bx-badge:before {
    content: "\e9c0"
}

.bx-badge-check:before {
    content: "\e9c1"
}

.bx-ball:before {
    content: "\e9c2"
}

.bx-band-aid:before {
    content: "\e9c3"
}

.bx-bar-chart:before {
    content: "\e9c4"
}

.bx-bar-chart-alt:before {
    content: "\e9c5"
}

.bx-bar-chart-alt-2:before {
    content: "\e9c6"
}

.bx-bar-chart-square:before {
    content: "\e9c7"
}

.bx-barcode:before {
    content: "\e9c8"
}

.bx-barcode-reader:before {
    content: "\e9c9"
}

.bx-baseball:before {
    content: "\e9ca"
}

.bx-basket:before {
    content: "\e9cb"
}

.bx-basketball:before {
    content: "\e9cc"
}

.bx-bath:before {
    content: "\e9cd"
}

.bx-battery:before {
    content: "\e9ce"
}

.bx-bed:before {
    content: "\e9cf"
}

.bx-been-here:before {
    content: "\e9d0"
}

.bx-beer:before {
    content: "\e9d1"
}

.bx-bell:before {
    content: "\e9d2"
}

.bx-bell-minus:before {
    content: "\e9d3"
}

.bx-bell-off:before {
    content: "\e9d4"
}

.bx-bell-plus:before {
    content: "\e9d5"
}

.bx-bible:before {
    content: "\e9d6"
}

.bx-bitcoin:before {
    content: "\e9d7"
}

.bx-blanket:before {
    content: "\e9d8"
}

.bx-block:before {
    content: "\e9d9"
}

.bx-bluetooth:before {
    content: "\e9da"
}

.bx-body:before {
    content: "\e9db"
}

.bx-bold:before {
    content: "\e9dc"
}

.bx-bolt-circle:before {
    content: "\e9dd"
}

.bx-bomb:before {
    content: "\e9de"
}

.bx-bone:before {
    content: "\e9df"
}

.bx-bong:before {
    content: "\e9e0"
}

.bx-book:before {
    content: "\e9e1"
}

.bx-book-add:before {
    content: "\e9e2"
}

.bx-book-alt:before {
    content: "\e9e3"
}

.bx-book-bookmark:before {
    content: "\e9e4"
}

.bx-book-content:before {
    content: "\e9e5"
}

.bx-book-heart:before {
    content: "\e9e6"
}

.bx-bookmark:before {
    content: "\e9e7"
}

.bx-bookmark-alt:before {
    content: "\e9e8"
}

.bx-bookmark-alt-minus:before {
    content: "\e9e9"
}

.bx-bookmark-alt-plus:before {
    content: "\e9ea"
}

.bx-bookmark-heart:before {
    content: "\e9eb"
}

.bx-bookmark-minus:before {
    content: "\e9ec"
}

.bx-bookmark-plus:before {
    content: "\e9ed"
}

.bx-bookmarks:before {
    content: "\e9ee"
}

.bx-book-open:before {
    content: "\e9ef"
}

.bx-book-reader:before {
    content: "\e9f0"
}

.bx-border-all:before {
    content: "\e9f1"
}

.bx-border-bottom:before {
    content: "\e9f2"
}

.bx-border-inner:before {
    content: "\e9f3"
}

.bx-border-left:before {
    content: "\e9f4"
}

.bx-border-none:before {
    content: "\e9f5"
}

.bx-border-outer:before {
    content: "\e9f6"
}

.bx-border-radius:before {
    content: "\e9f7"
}

.bx-border-right:before {
    content: "\e9f8"
}

.bx-border-top:before {
    content: "\e9f9"
}

.bx-bot:before {
    content: "\e9fa"
}

.bx-bowling-ball:before {
    content: "\e9fb"
}

.bx-box:before {
    content: "\e9fc"
}

.bx-bracket:before {
    content: "\e9fd"
}

.bx-braille:before {
    content: "\e9fe"
}

.bx-brain:before {
    content: "\e9ff"
}

.bx-briefcase:before {
    content: "\ea00"
}

.bx-briefcase-alt:before {
    content: "\ea01"
}

.bx-briefcase-alt-2:before {
    content: "\ea02"
}

.bx-brightness:before {
    content: "\ea03"
}

.bx-brightness-half:before {
    content: "\ea04"
}

.bx-broadcast:before {
    content: "\ea05"
}

.bx-brush:before {
    content: "\ea06"
}

.bx-brush-alt:before {
    content: "\ea07"
}

.bx-bug:before {
    content: "\ea08"
}

.bx-bug-alt:before {
    content: "\ea09"
}

.bx-building:before {
    content: "\ea0a"
}

.bx-building-house:before {
    content: "\ea0b"
}

.bx-buildings:before {
    content: "\ea0c"
}

.bx-bulb:before {
    content: "\ea0d"
}

.bx-bullseye:before {
    content: "\ea0e"
}

.bx-buoy:before {
    content: "\ea0f"
}

.bx-bus:before {
    content: "\ea10"
}

.bx-bus-school:before {
    content: "\ea11"
}

.bx-cabinet:before {
    content: "\ea12"
}

.bx-cake:before {
    content: "\ea13"
}

.bx-calculator:before {
    content: "\ea14"
}

.bx-calendar:before {
    content: "\ea15"
}

.bx-calendar-alt:before {
    content: "\ea16"
}

.bx-calendar-check:before {
    content: "\ea17"
}

.bx-calendar-edit:before {
    content: "\ea18"
}

.bx-calendar-event:before {
    content: "\ea19"
}

.bx-calendar-exclamation:before {
    content: "\ea1a"
}

.bx-calendar-heart:before {
    content: "\ea1b"
}

.bx-calendar-minus:before {
    content: "\ea1c"
}

.bx-calendar-plus:before {
    content: "\ea1d"
}

.bx-calendar-star:before {
    content: "\ea1e"
}

.bx-calendar-week:before {
    content: "\ea1f"
}

.bx-calendar-x:before {
    content: "\ea20"
}

.bx-camera:before {
    content: "\ea21"
}

.bx-camera-home:before {
    content: "\ea22"
}

.bx-camera-movie:before {
    content: "\ea23"
}

.bx-camera-off:before {
    content: "\ea24"
}

.bx-capsule:before {
    content: "\ea25"
}

.bx-captions:before {
    content: "\ea26"
}

.bx-car:before {
    content: "\ea27"
}

.bx-card:before {
    content: "\ea28"
}

.bx-caret-down:before {
    content: "\ea29"
}

.bx-caret-down-circle:before {
    content: "\ea2a"
}

.bx-caret-down-square:before {
    content: "\ea2b"
}

.bx-caret-left:before {
    content: "\ea2c"
}

.bx-caret-left-circle:before {
    content: "\ea2d"
}

.bx-caret-left-square:before {
    content: "\ea2e"
}

.bx-caret-right:before {
    content: "\ea2f"
}

.bx-caret-right-circle:before {
    content: "\ea30"
}

.bx-caret-right-square:before {
    content: "\ea31"
}

.bx-caret-up:before {
    content: "\ea32"
}

.bx-caret-up-circle:before {
    content: "\ea33"
}

.bx-caret-up-square:before {
    content: "\ea34"
}

.bx-carousel:before {
    content: "\ea35"
}

.bx-cart:before {
    content: "\ea36"
}

.bx-cart-alt:before {
    content: "\ea37"
}

.bx-cast:before {
    content: "\ea38"
}

.bx-category:before {
    content: "\ea39"
}

.bx-category-alt:before {
    content: "\ea3a"
}

.bx-cctv:before {
    content: "\ea3b"
}

.bx-certification:before {
    content: "\ea3c"
}

.bx-chair:before {
    content: "\ea3d"
}

.bx-chalkboard:before {
    content: "\ea3e"
}

.bx-chart:before {
    content: "\ea3f"
}

.bx-chat:before {
    content: "\ea40"
}

.bx-check:before {
    content: "\ea41"
}

.bx-checkbox:before {
    content: "\ea42"
}

.bx-checkbox-checked:before {
    content: "\ea43"
}

.bx-checkbox-minus:before {
    content: "\ea44"
}

.bx-checkbox-square:before {
    content: "\ea45"
}

.bx-check-circle:before {
    content: "\ea46"
}

.bx-check-double:before {
    content: "\ea47"
}

.bx-check-shield:before {
    content: "\ea48"
}

.bx-check-square:before {
    content: "\ea49"
}

.bx-chevron-down:before {
    content: "\ea4a"
}

.bx-chevron-down-circle:before {
    content: "\ea4b"
}

.bx-chevron-down-square:before {
    content: "\ea4c"
}

.bx-chevron-left:before {
    content: "\ea4d"
}

.bx-chevron-left-circle:before {
    content: "\ea4e"
}

.bx-chevron-left-square:before {
    content: "\ea4f"
}

.bx-chevron-right:before {
    content: "\ea50"
}

.bx-chevron-right-circle:before {
    content: "\ea51"
}

.bx-chevron-right-square:before {
    content: "\ea52"
}

.bx-chevrons-down:before {
    content: "\ea53"
}

.bx-chevrons-left:before {
    content: "\ea54"
}

.bx-chevrons-right:before {
    content: "\ea55"
}

.bx-chevrons-up:before {
    content: "\ea56"
}

.bx-chevron-up:before {
    content: "\ea57"
}

.bx-chevron-up-circle:before {
    content: "\ea58"
}

.bx-chevron-up-square:before {
    content: "\ea59"
}

.bx-chip:before {
    content: "\ea5a"
}

.bx-church:before {
    content: "\ea5b"
}

.bx-circle:before {
    content: "\ea5c"
}

.bx-clinic:before {
    content: "\ea5d"
}

.bx-clipboard:before {
    content: "\ea5e"
}

.bx-closet:before {
    content: "\ea5f"
}

.bx-cloud:before {
    content: "\ea60"
}

.bx-cloud-download:before {
    content: "\ea61"
}

.bx-cloud-drizzle:before {
    content: "\ea62"
}

.bx-cloud-lightning:before {
    content: "\ea63"
}

.bx-cloud-light-rain:before {
    content: "\ea64"
}

.bx-cloud-rain:before {
    content: "\ea65"
}

.bx-cloud-snow:before {
    content: "\ea66"
}

.bx-cloud-upload:before {
    content: "\ea67"
}

.bx-code:before {
    content: "\ea68"
}

.bx-code-alt:before {
    content: "\ea69"
}

.bx-code-block:before {
    content: "\ea6a"
}

.bx-code-curly:before {
    content: "\ea6b"
}

.bx-coffee:before {
    content: "\ea6c"
}

.bx-coffee-togo:before {
    content: "\ea6d"
}

.bx-cog:before {
    content: "\ea6e"
}

.bx-coin:before {
    content: "\ea6f"
}

.bx-coin-stack:before {
    content: "\ea70"
}

.bx-collapse:before {
    content: "\ea71"
}

.bx-collection:before {
    content: "\ea72"
}

.bx-color-fill:before {
    content: "\ea73"
}

.bx-columns:before {
    content: "\ea74"
}

.bx-command:before {
    content: "\ea75"
}

.bx-comment:before {
    content: "\ea76"
}

.bx-comment-add:before {
    content: "\ea77"
}

.bx-comment-check:before {
    content: "\ea78"
}

.bx-comment-detail:before {
    content: "\ea79"
}

.bx-comment-dots:before {
    content: "\ea7a"
}

.bx-comment-edit:before {
    content: "\ea7b"
}

.bx-comment-error:before {
    content: "\ea7c"
}

.bx-comment-minus:before {
    content: "\ea7d"
}

.bx-comment-x:before {
    content: "\ea7e"
}

.bx-compass:before {
    content: "\ea7f"
}

.bx-confused:before {
    content: "\ea80"
}

.bx-conversation:before {
    content: "\ea81"
}

.bx-cookie:before {
    content: "\ea82"
}

.bx-cool:before {
    content: "\ea83"
}

.bx-copy:before {
    content: "\ea84"
}

.bx-copy-alt:before {
    content: "\ea85"
}

.bx-copyright:before {
    content: "\ea86"
}

.bx-credit-card:before {
    content: "\ea87"
}

.bx-credit-card-alt:before {
    content: "\ea88"
}

.bx-credit-card-front:before {
    content: "\ea89"
}

.bx-crop:before {
    content: "\ea8a"
}

.bx-crosshair:before {
    content: "\ea8b"
}

.bx-crown:before {
    content: "\ea8c"
}

.bx-cube:before {
    content: "\ea8d"
}

.bx-cube-alt:before {
    content: "\ea8e"
}

.bx-cuboid:before {
    content: "\ea8f"
}

.bx-current-location:before {
    content: "\ea90"
}

.bx-customize:before {
    content: "\ea91"
}

.bx-cut:before {
    content: "\ea92"
}

.bx-cycling:before {
    content: "\ea93"
}

.bx-cylinder:before {
    content: "\ea94"
}

.bx-data:before {
    content: "\ea95"
}

.bx-desktop:before {
    content: "\ea96"
}

.bx-detail:before {
    content: "\ea97"
}

.bx-devices:before {
    content: "\ea98"
}

.bx-dialpad:before {
    content: "\ea99"
}

.bx-dialpad-alt:before {
    content: "\ea9a"
}

.bx-diamond:before {
    content: "\ea9b"
}

.bx-dice-1:before {
    content: "\ea9c"
}

.bx-dice-2:before {
    content: "\ea9d"
}

.bx-dice-3:before {
    content: "\ea9e"
}

.bx-dice-4:before {
    content: "\ea9f"
}

.bx-dice-5:before {
    content: "\eaa0"
}

.bx-dice-6:before {
    content: "\eaa1"
}

.bx-directions:before {
    content: "\eaa2"
}

.bx-disc:before {
    content: "\eaa3"
}

.bx-dish:before {
    content: "\eaa4"
}

.bx-dislike:before {
    content: "\eaa5"
}

.bx-dizzy:before {
    content: "\eaa6"
}

.bx-dna:before {
    content: "\eaa7"
}

.bx-dock-bottom:before {
    content: "\eaa8"
}

.bx-dock-left:before {
    content: "\eaa9"
}

.bx-dock-right:before {
    content: "\eaaa"
}

.bx-dock-top:before {
    content: "\eaab"
}

.bx-dollar:before {
    content: "\eaac"
}

.bx-dollar-circle:before {
    content: "\eaad"
}

.bx-donate-blood:before {
    content: "\eaae"
}

.bx-donate-heart:before {
    content: "\eaaf"
}

.bx-door-open:before {
    content: "\eab0"
}

.bx-dots-horizontal:before {
    content: "\eab1"
}

.bx-dots-horizontal-rounded:before {
    content: "\eab2"
}

.bx-dots-vertical:before {
    content: "\eab3"
}

.bx-dots-vertical-rounded:before {
    content: "\eab4"
}

.bx-doughnut-chart:before {
    content: "\eab5"
}

.bx-down-arrow:before {
    content: "\eab6"
}

.bx-down-arrow-alt:before {
    content: "\eab7"
}

.bx-down-arrow-circle:before {
    content: "\eab8"
}

.bx-download:before {
    content: "\eab9"
}

.bx-downvote:before {
    content: "\eaba"
}

.bx-drink:before {
    content: "\eabb"
}

.bx-droplet:before {
    content: "\eabc"
}

.bx-dumbbell:before {
    content: "\eabd"
}

.bx-duplicate:before {
    content: "\eabe"
}

.bx-edit:before {
    content: "\eabf"
}

.bx-edit-alt:before {
    content: "\eac0"
}

.bx-envelope:before {
    content: "\eac1"
}

.bx-envelope-open:before {
    content: "\eac2"
}

.bx-equalizer:before {
    content: "\eac3"
}

.bx-eraser:before {
    content: "\eac4"
}

.bx-error:before {
    content: "\eac5"
}

.bx-error-alt:before {
    content: "\eac6"
}

.bx-error-circle:before {
    content: "\eac7"
}

.bx-euro:before {
    content: "\eac8"
}

.bx-exclude:before {
    content: "\eac9"
}

.bx-exit:before {
    content: "\eaca"
}

.bx-exit-fullscreen:before {
    content: "\eacb"
}

.bx-expand:before {
    content: "\eacc"
}

.bx-expand-alt:before {
    content: "\eacd"
}

.bx-export:before {
    content: "\eace"
}

.bx-extension:before {
    content: "\eacf"
}

.bx-face:before {
    content: "\ead0"
}

.bx-fast-forward:before {
    content: "\ead1"
}

.bx-fast-forward-circle:before {
    content: "\ead2"
}

.bx-female:before {
    content: "\ead3"
}

.bx-female-sign:before {
    content: "\ead4"
}

.bx-file:before {
    content: "\ead5"
}

.bx-file-blank:before {
    content: "\ead6"
}

.bx-file-find:before {
    content: "\ead7"
}

.bx-film:before {
    content: "\ead8"
}

.bx-filter:before {
    content: "\ead9"
}

.bx-filter-alt:before {
    content: "\eada"
}

.bx-fingerprint:before {
    content: "\eadb"
}

.bx-first-aid:before {
    content: "\eadc"
}

.bx-first-page:before {
    content: "\eadd"
}

.bx-flag:before {
    content: "\eade"
}

.bx-folder:before {
    content: "\eadf"
}

.bx-folder-minus:before {
    content: "\eae0"
}

.bx-folder-open:before {
    content: "\eae1"
}

.bx-folder-plus:before {
    content: "\eae2"
}

.bx-font:before {
    content: "\eae3"
}

.bx-font-color:before {
    content: "\eae4"
}

.bx-font-family:before {
    content: "\eae5"
}

.bx-font-size:before {
    content: "\eae6"
}

.bx-food-menu:before {
    content: "\eae7"
}

.bx-food-tag:before {
    content: "\eae8"
}

.bx-football:before {
    content: "\eae9"
}

.bx-fridge:before {
    content: "\eaea"
}

.bx-fullscreen:before {
    content: "\eaeb"
}

.bx-game:before {
    content: "\eaec"
}

.bx-gas-pump:before {
    content: "\eaed"
}

.bx-ghost:before {
    content: "\eaee"
}

.bx-gift:before {
    content: "\eaef"
}

.bx-git-branch:before {
    content: "\eaf0"
}

.bx-git-commit:before {
    content: "\eaf1"
}

.bx-git-compare:before {
    content: "\eaf2"
}

.bx-git-merge:before {
    content: "\eaf3"
}

.bx-git-pull-request:before {
    content: "\eaf4"
}

.bx-git-repo-forked:before {
    content: "\eaf5"
}

.bx-glasses:before {
    content: "\eaf6"
}

.bx-glasses-alt:before {
    content: "\eaf7"
}

.bx-globe:before {
    content: "\eaf8"
}

.bx-globe-alt:before {
    content: "\eaf9"
}

.bx-grid:before {
    content: "\eafa"
}

.bx-grid-alt:before {
    content: "\eafb"
}

.bx-grid-horizontal:before {
    content: "\eafc"
}

.bx-grid-small:before {
    content: "\eafd"
}

.bx-grid-vertical:before {
    content: "\eafe"
}

.bx-group:before {
    content: "\eaff"
}

.bx-handicap:before {
    content: "\eb00"
}

.bx-happy:before {
    content: "\eb01"
}

.bx-happy-alt:before {
    content: "\eb02"
}

.bx-happy-beaming:before {
    content: "\eb03"
}

.bx-happy-heart-eyes:before {
    content: "\eb04"
}

.bx-hash:before {
    content: "\eb05"
}

.bx-hdd:before {
    content: "\eb06"
}

.bx-heading:before {
    content: "\eb07"
}

.bx-headphone:before {
    content: "\eb08"
}

.bx-health:before {
    content: "\eb09"
}

.bx-heart:before {
    content: "\eb0a"
}

.bx-heart-circle:before {
    content: "\eb0b"
}

.bx-heart-square:before {
    content: "\eb0c"
}

.bx-help-circle:before {
    content: "\eb0d"
}

.bx-hide:before {
    content: "\eb0e"
}

.bx-highlight:before {
    content: "\eb0f"
}

.bx-history:before {
    content: "\eb10"
}

.bx-hive:before {
    content: "\eb11"
}

.bx-home:before {
    content: "\eb12"
}

.bx-home-alt:before {
    content: "\eb13"
}

.bx-home-circle:before {
    content: "\eb14"
}

.bx-home-heart:before {
    content: "\eb15"
}

.bx-home-smile:before {
    content: "\eb16"
}

.bx-horizontal-center:before {
    content: "\eb17"
}

.bx-hotel:before {
    content: "\eb18"
}

.bx-hourglass:before {
    content: "\eb19"
}

.bx-id-card:before {
    content: "\eb1a"
}

.bx-image:before {
    content: "\eb1b"
}

.bx-image-add:before {
    content: "\eb1c"
}

.bx-image-alt:before {
    content: "\eb1d"
}

.bx-images:before {
    content: "\eb1e"
}

.bx-import:before {
    content: "\eb1f"
}

.bx-infinite:before {
    content: "\eb20"
}

.bx-info-circle:before {
    content: "\eb21"
}

.bx-info-square:before {
    content: "\eb22"
}

.bx-intersect:before {
    content: "\eb23"
}

.bx-italic:before {
    content: "\eb24"
}

.bx-joystick:before {
    content: "\eb25"
}

.bx-joystick-alt:before {
    content: "\eb26"
}

.bx-joystick-button:before {
    content: "\eb27"
}

.bx-key:before {
    content: "\eb28"
}

.bx-label:before {
    content: "\eb29"
}

.bx-landscape:before {
    content: "\eb2a"
}

.bx-laptop:before {
    content: "\eb2b"
}

.bx-last-page:before {
    content: "\eb2c"
}

.bx-laugh:before {
    content: "\eb2d"
}

.bx-layer:before {
    content: "\eb2e"
}

.bx-layer-minus:before {
    content: "\eb2f"
}

.bx-layer-plus:before {
    content: "\eb30"
}

.bx-layout:before {
    content: "\eb31"
}

.bx-left-arrow:before {
    content: "\eb32"
}

.bx-left-arrow-alt:before {
    content: "\eb33"
}

.bx-left-arrow-circle:before {
    content: "\eb34"
}

.bx-left-down-arrow-circle:before {
    content: "\eb35"
}

.bx-left-indent:before {
    content: "\eb36"
}

.bx-left-top-arrow-circle:before {
    content: "\eb37"
}

.bx-library:before {
    content: "\eb38"
}

.bx-like:before {
    content: "\eb39"
}

.bx-line-chart:before {
    content: "\eb3a"
}

.bx-line-chart-down:before {
    content: "\eb3b"
}

.bx-link:before {
    content: "\eb3c"
}

.bx-link-alt:before {
    content: "\eb3d"
}

.bx-link-external:before {
    content: "\eb3e"
}

.bx-lira:before {
    content: "\eb3f"
}

.bx-list-check:before {
    content: "\eb40"
}

.bx-list-minus:before {
    content: "\eb41"
}

.bx-list-ol:before {
    content: "\eb42"
}

.bx-list-plus:before {
    content: "\eb43"
}

.bx-list-ul:before {
    content: "\eb44"
}

.bx-loader:before {
    content: "\eb45"
}

.bx-loader-alt:before {
    content: "\eb46"
}

.bx-loader-circle:before {
    content: "\eb47"
}

.bx-location-plus:before {
    content: "\eb48"
}

.bx-lock:before {
    content: "\eb49"
}

.bx-lock-alt:before {
    content: "\eb4a"
}

.bx-lock-open:before {
    content: "\eb4b"
}

.bx-lock-open-alt:before {
    content: "\eb4c"
}

.bx-log-in:before {
    content: "\eb4d"
}

.bx-log-in-circle:before {
    content: "\eb4e"
}

.bx-log-out:before {
    content: "\eb4f"
}

.bx-log-out-circle:before {
    content: "\eb50"
}

.bx-low-vision:before {
    content: "\eb51"
}

.bx-magnet:before {
    content: "\eb52"
}

.bx-mail-send:before {
    content: "\eb53"
}

.bx-male:before {
    content: "\eb54"
}

.bx-male-sign:before {
    content: "\eb55"
}

.bx-map:before {
    content: "\eb56"
}

.bx-map-alt:before {
    content: "\eb57"
}

.bx-map-pin:before {
    content: "\eb58"
}

.bx-mask:before {
    content: "\eb59"
}

.bx-medal:before {
    content: "\eb5a"
}

.bx-meh:before {
    content: "\eb5b"
}

.bx-meh-alt:before {
    content: "\eb5c"
}

.bx-meh-blank:before {
    content: "\eb5d"
}

.bx-memory-card:before {
    content: "\eb5e"
}

.bx-menu:before {
    content: "\eb5f"
}

.bx-menu-alt-left:before {
    content: "\eb60"
}

.bx-menu-alt-right:before {
    content: "\eb61"
}

.bx-merge:before {
    content: "\eb62"
}

.bx-message:before {
    content: "\eb63"
}

.bx-message-add:before {
    content: "\eb64"
}

.bx-message-alt:before {
    content: "\eb65"
}

.bx-message-alt-add:before {
    content: "\eb66"
}

.bx-message-alt-check:before {
    content: "\eb67"
}

.bx-message-alt-detail:before {
    content: "\eb68"
}

.bx-message-alt-dots:before {
    content: "\eb69"
}

.bx-message-alt-edit:before {
    content: "\eb6a"
}

.bx-message-alt-error:before {
    content: "\eb6b"
}

.bx-message-alt-minus:before {
    content: "\eb6c"
}

.bx-message-alt-x:before {
    content: "\eb6d"
}

.bx-message-check:before {
    content: "\eb6e"
}

.bx-message-detail:before {
    content: "\eb6f"
}

.bx-message-dots:before {
    content: "\eb70"
}

.bx-message-edit:before {
    content: "\eb71"
}

.bx-message-error:before {
    content: "\eb72"
}

.bx-message-minus:before {
    content: "\eb73"
}

.bx-message-rounded:before {
    content: "\eb74"
}

.bx-message-rounded-add:before {
    content: "\eb75"
}

.bx-message-rounded-check:before {
    content: "\eb76"
}

.bx-message-rounded-detail:before {
    content: "\eb77"
}

.bx-message-rounded-dots:before {
    content: "\eb78"
}

.bx-message-rounded-edit:before {
    content: "\eb79"
}

.bx-message-rounded-error:before {
    content: "\eb7a"
}

.bx-message-rounded-minus:before {
    content: "\eb7b"
}

.bx-message-rounded-x:before {
    content: "\eb7c"
}

.bx-message-square:before {
    content: "\eb7d"
}

.bx-message-square-add:before {
    content: "\eb7e"
}

.bx-message-square-check:before {
    content: "\eb7f"
}

.bx-message-square-detail:before {
    content: "\eb80"
}

.bx-message-square-dots:before {
    content: "\eb81"
}

.bx-message-square-edit:before {
    content: "\eb82"
}

.bx-message-square-error:before {
    content: "\eb83"
}

.bx-message-square-minus:before {
    content: "\eb84"
}

.bx-message-square-x:before {
    content: "\eb85"
}

.bx-message-x:before {
    content: "\eb86"
}

.bx-meteor:before {
    content: "\eb87"
}

.bx-microchip:before {
    content: "\eb88"
}

.bx-microphone:before {
    content: "\eb89"
}

.bx-microphone-off:before {
    content: "\eb8a"
}

.bx-minus:before {
    content: "\eb8b"
}

.bx-minus-back:before {
    content: "\eb8c"
}

.bx-minus-circle:before {
    content: "\eb8d"
}

.bx-minus-front:before {
    content: "\eb8e"
}

.bx-mobile:before {
    content: "\eb8f"
}

.bx-mobile-alt:before {
    content: "\eb90"
}

.bx-mobile-landscape:before {
    content: "\eb91"
}

.bx-mobile-vibration:before {
    content: "\eb92"
}

.bx-money:before {
    content: "\eb93"
}

.bx-moon:before {
    content: "\eb94"
}

.bx-mouse:before {
    content: "\eb95"
}

.bx-mouse-alt:before {
    content: "\eb96"
}

.bx-move:before {
    content: "\eb97"
}

.bx-move-horizontal:before {
    content: "\eb98"
}

.bx-move-vertical:before {
    content: "\eb99"
}

.bx-movie:before {
    content: "\eb9a"
}

.bx-movie-play:before {
    content: "\eb9b"
}

.bx-music:before {
    content: "\eb9c"
}

.bx-navigation:before {
    content: "\eb9d"
}

.bx-network-chart:before {
    content: "\eb9e"
}

.bx-news:before {
    content: "\eb9f"
}

.bx-no-entry:before {
    content: "\eba0"
}

.bx-note:before {
    content: "\eba1"
}

.bx-notepad:before {
    content: "\eba2"
}

.bx-notification:before {
    content: "\eba3"
}

.bx-notification-off:before {
    content: "\eba4"
}

.bx-outline:before {
    content: "\eba5"
}

.bx-package:before {
    content: "\eba6"
}

.bx-paint:before {
    content: "\eba7"
}

.bx-paint-roll:before {
    content: "\eba8"
}

.bx-palette:before {
    content: "\eba9"
}

.bx-paperclip:before {
    content: "\ebaa"
}

.bx-paper-plane:before {
    content: "\ebab"
}

.bx-paragraph:before {
    content: "\ebac"
}

.bx-paste:before {
    content: "\ebad"
}

.bx-pause:before {
    content: "\ebae"
}

.bx-pause-circle:before {
    content: "\ebaf"
}

.bx-pen:before {
    content: "\ebb0"
}

.bx-pencil:before {
    content: "\ebb1"
}

.bx-phone:before {
    content: "\ebb2"
}

.bx-phone-call:before {
    content: "\ebb3"
}

.bx-phone-incoming:before {
    content: "\ebb4"
}

.bx-phone-off:before {
    content: "\ebb5"
}

.bx-phone-outgoing:before {
    content: "\ebb6"
}

.bx-photo-album:before {
    content: "\ebb7"
}

.bx-pie-chart:before {
    content: "\ebb8"
}

.bx-pie-chart-alt:before {
    content: "\ebb9"
}

.bx-pie-chart-alt-2:before {
    content: "\ebba"
}

.bx-pin:before {
    content: "\ebbb"
}

.bx-planet:before {
    content: "\ebbc"
}

.bx-play:before {
    content: "\ebbd"
}

.bx-play-circle:before {
    content: "\ebbe"
}

.bx-plug:before {
    content: "\ebbf"
}

.bx-plus:before {
    content: "\ebc0"
}

.bx-plus-circle:before {
    content: "\ebc1"
}

.bx-plus-medical:before {
    content: "\ebc2"
}

.bx-podcast:before {
    content: "\ebc3"
}

.bx-pointer:before {
    content: "\ebc4"
}

.bx-poll:before {
    content: "\ebc5"
}

.bx-polygon:before {
    content: "\ebc6"
}

.bx-pound:before {
    content: "\ebc7"
}

.bx-power-off:before {
    content: "\ebc8"
}

.bx-printer:before {
    content: "\ebc9"
}

.bx-pulse:before {
    content: "\ebca"
}

.bx-purchase-tag:before {
    content: "\ebcb"
}

.bx-purchase-tag-alt:before {
    content: "\ebcc"
}

.bx-pyramid:before {
    content: "\ebcd"
}

.bx-qr:before {
    content: "\ebce"
}

.bx-qr-scan:before {
    content: "\ebcf"
}

.bx-question-mark:before {
    content: "\ebd0"
}

.bx-radar:before {
    content: "\ebd1"
}

.bx-radio:before {
    content: "\ebd2"
}

.bx-radio-circle:before {
    content: "\ebd3"
}

.bx-radio-circle-marked:before {
    content: "\ebd4"
}

.bx-receipt:before {
    content: "\ebd5"
}

.bx-rectangle:before {
    content: "\ebd6"
}

.bx-recycle:before {
    content: "\ebd7"
}

.bx-redo:before {
    content: "\ebd8"
}

.bx-refresh:before {
    content: "\ebd9"
}

.bx-registered:before {
    content: "\ebda"
}

.bx-rename:before {
    content: "\ebdb"
}

.bx-repeat:before {
    content: "\ebdc"
}

.bx-reply:before {
    content: "\ebdd"
}

.bx-reply-all:before {
    content: "\ebde"
}

.bx-repost:before {
    content: "\ebdf"
}

.bx-reset:before {
    content: "\ebe0"
}

.bx-restaurant:before {
    content: "\ebe1"
}

.bx-revision:before {
    content: "\ebe2"
}

.bx-rewind:before {
    content: "\ebe3"
}

.bx-rewind-circle:before {
    content: "\ebe4"
}

.bx-right-arrow:before {
    content: "\ebe5"
}

.bx-right-arrow-alt:before {
    content: "\ebe6"
}

.bx-right-arrow-circle:before {
    content: "\ebe7"
}

.bx-right-down-arrow-circle:before {
    content: "\ebe8"
}

.bx-right-indent:before {
    content: "\ebe9"
}

.bx-right-top-arrow-circle:before {
    content: "\ebea"
}

.bx-rocket:before {
    content: "\ebeb"
}

.bx-rotate-left:before {
    content: "\ebec"
}

.bx-rotate-right:before {
    content: "\ebed"
}

.bx-rss:before {
    content: "\ebee"
}

.bx-ruble:before {
    content: "\ebef"
}

.bx-ruler:before {
    content: "\ebf0"
}

.bx-run:before {
    content: "\ebf1"
}

.bx-rupee:before {
    content: "\ebf2"
}

.bx-sad:before {
    content: "\ebf3"
}

.bx-save:before {
    content: "\ebf4"
}

.bx-scan:before {
    content: "\ebf5"
}

.bx-screenshot:before {
    content: "\ebf6"
}

.bx-search:before {
    content: "\ebf7"
}

.bx-search-alt:before {
    content: "\ebf8"
}

.bx-search-alt-2:before {
    content: "\ebf9"
}

.bx-selection:before {
    content: "\ebfa"
}

.bx-select-multiple:before {
    content: "\ebfb"
}

.bx-send:before {
    content: "\ebfc"
}

.bx-server:before {
    content: "\ebfd"
}

.bx-shape-circle:before {
    content: "\ebfe"
}

.bx-shape-polygon:before {
    content: "\ebff"
}

.bx-shape-square:before {
    content: "\ec00"
}

.bx-shape-triangle:before {
    content: "\ec01"
}

.bx-share:before {
    content: "\ec02"
}

.bx-share-alt:before {
    content: "\ec03"
}

.bx-shekel:before {
    content: "\ec04"
}

.bx-shield:before {
    content: "\ec05"
}

.bx-shield-alt:before {
    content: "\ec06"
}

.bx-shield-alt-2:before {
    content: "\ec07"
}

.bx-shield-quarter:before {
    content: "\ec08"
}

.bx-shield-x:before {
    content: "\ec09"
}

.bx-shocked:before {
    content: "\ec0a"
}

.bx-shopping-bag:before {
    content: "\ec0b"
}

.bx-show:before {
    content: "\ec0c"
}

.bx-show-alt:before {
    content: "\ec0d"
}

.bx-shuffle:before {
    content: "\ec0e"
}

.bx-sidebar:before {
    content: "\ec0f"
}

.bx-sitemap:before {
    content: "\ec10"
}

.bx-skip-next:before {
    content: "\ec11"
}

.bx-skip-next-circle:before {
    content: "\ec12"
}

.bx-skip-previous:before {
    content: "\ec13"
}

.bx-skip-previous-circle:before {
    content: "\ec14"
}

.bx-sleepy:before {
    content: "\ec15"
}

.bx-slider:before {
    content: "\ec16"
}

.bx-slider-alt:before {
    content: "\ec17"
}

.bx-slideshow:before {
    content: "\ec18"
}

.bx-smile:before {
    content: "\ec19"
}

.bx-sort:before {
    content: "\ec1a"
}

.bx-sort-alt-2:before {
    content: "\ec1b"
}

.bx-sort-a-z:before {
    content: "\ec1c"
}

.bx-sort-down:before {
    content: "\ec1d"
}

.bx-sort-up:before {
    content: "\ec1e"
}

.bx-sort-z-a:before {
    content: "\ec1f"
}

.bx-spa:before {
    content: "\ec20"
}

.bx-space-bar:before {
    content: "\ec21"
}

.bx-speaker:before {
    content: "\ec22"
}

.bx-spray-can:before {
    content: "\ec23"
}

.bx-spreadsheet:before {
    content: "\ec24"
}

.bx-square:before {
    content: "\ec25"
}

.bx-square-rounded:before {
    content: "\ec26"
}

.bx-star:before {
    content: "\ec27"
}

.bx-station:before {
    content: "\ec28"
}

.bx-stats:before {
    content: "\ec29"
}

.bx-sticker:before {
    content: "\ec2a"
}

.bx-stop:before {
    content: "\ec2b"
}

.bx-stop-circle:before {
    content: "\ec2c"
}

.bx-stopwatch:before {
    content: "\ec2d"
}

.bx-store:before {
    content: "\ec2e"
}

.bx-store-alt:before {
    content: "\ec2f"
}

.bx-street-view:before {
    content: "\ec30"
}

.bx-strikethrough:before {
    content: "\ec31"
}

.bx-subdirectory-left:before {
    content: "\ec32"
}

.bx-subdirectory-right:before {
    content: "\ec33"
}

.bx-sun:before {
    content: "\ec34"
}

.bx-support:before {
    content: "\ec35"
}

.bx-swim:before {
    content: "\ec36"
}

.bx-sync:before {
    content: "\ec37"
}

.bx-tab:before {
    content: "\ec38"
}

.bx-table:before {
    content: "\ec39"
}

.bx-tachometer:before {
    content: "\ec3a"
}

.bx-tag:before {
    content: "\ec3b"
}

.bx-tag-alt:before {
    content: "\ec3c"
}

.bx-target-lock:before {
    content: "\ec3d"
}

.bx-task:before {
    content: "\ec3e"
}

.bx-task-x:before {
    content: "\ec3f"
}

.bx-taxi:before {
    content: "\ec40"
}

.bx-tennis-ball:before {
    content: "\ec41"
}

.bx-terminal:before {
    content: "\ec42"
}

.bx-test-tube:before {
    content: "\ec43"
}

.bx-text:before {
    content: "\ec44"
}

.bx-time:before {
    content: "\ec45"
}

.bx-time-five:before {
    content: "\ec46"
}

.bx-timer:before {
    content: "\ec47"
}

.bx-tired:before {
    content: "\ec48"
}

.bx-toggle-left:before {
    content: "\ec49"
}

.bx-toggle-right:before {
    content: "\ec4a"
}

.bx-tone:before {
    content: "\ec4b"
}

.bx-traffic-cone:before {
    content: "\ec4c"
}

.bx-train:before {
    content: "\ec4d"
}

.bx-transfer:before {
    content: "\ec4e"
}

.bx-transfer-alt:before {
    content: "\ec4f"
}

.bx-trash:before {
    content: "\ec50"
}

.bx-trash-alt:before {
    content: "\ec51"
}

.bx-trending-down:before {
    content: "\ec52"
}

.bx-trending-up:before {
    content: "\ec53"
}

.bx-trim:before {
    content: "\ec54"
}

.bx-trip:before {
    content: "\ec55"
}

.bx-trophy:before {
    content: "\ec56"
}

.bx-tv:before {
    content: "\ec57"
}

.bx-underline:before {
    content: "\ec58"
}

.bx-undo:before {
    content: "\ec59"
}

.bx-unite:before {
    content: "\ec5a"
}

.bx-unlink:before {
    content: "\ec5b"
}

.bx-up-arrow:before {
    content: "\ec5c"
}

.bx-up-arrow-alt:before {
    content: "\ec5d"
}

.bx-up-arrow-circle:before {
    content: "\ec5e"
}

.bx-upload:before {
    content: "\ec5f"
}

.bx-upside-down:before {
    content: "\ec60"
}

.bx-upvote:before {
    content: "\ec61"
}

.bx-usb:before {
    content: "\ec62"
}

.bx-user:before {
    content: "\ec63"
}

.bx-user-check:before {
    content: "\ec64"
}

.bx-user-circle:before {
    content: "\ec65"
}

.bx-user-minus:before {
    content: "\ec66"
}

.bx-user-pin:before {
    content: "\ec67"
}

.bx-user-plus:before {
    content: "\ec68"
}

.bx-user-voice:before {
    content: "\ec69"
}

.bx-user-x:before {
    content: "\ec6a"
}

.bx-vector:before {
    content: "\ec6b"
}

.bx-vertical-center:before {
    content: "\ec6c"
}

.bx-vial:before {
    content: "\ec6d"
}

.bx-video:before {
    content: "\ec6e"
}

.bx-video-off:before {
    content: "\ec6f"
}

.bx-video-plus:before {
    content: "\ec70"
}

.bx-video-recording:before {
    content: "\ec71"
}

.bx-voicemail:before {
    content: "\ec72"
}

.bx-volume:before {
    content: "\ec73"
}

.bx-volume-full:before {
    content: "\ec74"
}

.bx-volume-low:before {
    content: "\ec75"
}

.bx-volume-mute:before {
    content: "\ec76"
}

.bx-walk:before {
    content: "\ec77"
}

.bx-wallet:before {
    content: "\ec78"
}

.bx-wallet-alt:before {
    content: "\ec79"
}

.bx-water:before {
    content: "\ec7a"
}

.bx-webcam:before {
    content: "\ec7b"
}

.bx-wifi:before {
    content: "\ec7c"
}

.bx-wifi-0:before {
    content: "\ec7d"
}

.bx-wifi-1:before {
    content: "\ec7e"
}

.bx-wifi-2:before {
    content: "\ec7f"
}

.bx-wifi-off:before {
    content: "\ec80"
}

.bx-wind:before {
    content: "\ec81"
}

.bx-window:before {
    content: "\ec82"
}

.bx-window-alt:before {
    content: "\ec83"
}

.bx-window-close:before {
    content: "\ec84"
}

.bx-window-open:before {
    content: "\ec85"
}

.bx-windows:before {
    content: "\ec86"
}

.bx-wine:before {
    content: "\ec87"
}

.bx-wink-smile:before {
    content: "\ec88"
}

.bx-wink-tongue:before {
    content: "\ec89"
}

.bx-won:before {
    content: "\ec8a"
}

.bx-world:before {
    content: "\ec8b"
}

.bx-wrench:before {
    content: "\ec8c"
}

.bx-x:before {
    content: "\ec8d"
}

.bx-x-circle:before {
    content: "\ec8e"
}

.bx-yen:before {
    content: "\ec8f"
}

.bx-zoom-in:before {
    content: "\ec90"
}

.bx-zoom-out:before {
    content: "\ec91"
}

.bxs-party:before {
    content: "\ec92"
}

.bxs-hot:before {
    content: "\ec93"
}

.bxs-droplet:before {
    content: "\ec94"
}

.bxs-cat:before {
    content: "\ec95"
}

.bxs-dog:before {
    content: "\ec96"
}

.bxs-injection:before {
    content: "\ec97"
}

.bxs-leaf:before {
    content: "\ec98"
}

.bxs-add-to-queue:before {
    content: "\ec99"
}

.bxs-adjust:before {
    content: "\ec9a"
}

.bxs-adjust-alt:before {
    content: "\ec9b"
}

.bxs-alarm:before {
    content: "\ec9c"
}

.bxs-alarm-add:before {
    content: "\ec9d"
}

.bxs-alarm-exclamation:before {
    content: "\ec9e"
}

.bxs-alarm-off:before {
    content: "\ec9f"
}

.bxs-alarm-snooze:before {
    content: "\eca0"
}

.bxs-album:before {
    content: "\eca1"
}

.bxs-ambulance:before {
    content: "\eca2"
}

.bxs-analyse:before {
    content: "\eca3"
}

.bxs-angry:before {
    content: "\eca4"
}

.bxs-arch:before {
    content: "\eca5"
}

.bxs-archive:before {
    content: "\eca6"
}

.bxs-archive-in:before {
    content: "\eca7"
}

.bxs-archive-out:before {
    content: "\eca8"
}

.bxs-area:before {
    content: "\eca9"
}

.bxs-arrow-from-bottom:before {
    content: "\ecaa"
}

.bxs-arrow-from-left:before {
    content: "\ecab"
}

.bxs-arrow-from-right:before {
    content: "\ecac"
}

.bxs-arrow-from-top:before {
    content: "\ecad"
}

.bxs-arrow-to-bottom:before {
    content: "\ecae"
}

.bxs-arrow-to-left:before {
    content: "\ecaf"
}

.bxs-arrow-to-right:before {
    content: "\ecb0"
}

.bxs-arrow-to-top:before {
    content: "\ecb1"
}

.bxs-award:before {
    content: "\ecb2"
}

.bxs-baby-carriage:before {
    content: "\ecb3"
}

.bxs-backpack:before {
    content: "\ecb4"
}

.bxs-badge:before {
    content: "\ecb5"
}

.bxs-badge-check:before {
    content: "\ecb6"
}

.bxs-badge-dollar:before {
    content: "\ecb7"
}

.bxs-ball:before {
    content: "\ecb8"
}

.bxs-band-aid:before {
    content: "\ecb9"
}

.bxs-bank:before {
    content: "\ecba"
}

.bxs-bar-chart-alt-2:before {
    content: "\ecbb"
}

.bxs-bar-chart-square:before {
    content: "\ecbc"
}

.bxs-barcode:before {
    content: "\ecbd"
}

.bxs-baseball:before {
    content: "\ecbe"
}

.bxs-basket:before {
    content: "\ecbf"
}

.bxs-basketball:before {
    content: "\ecc0"
}

.bxs-bath:before {
    content: "\ecc1"
}

.bxs-battery:before {
    content: "\ecc2"
}

.bxs-battery-charging:before {
    content: "\ecc3"
}

.bxs-battery-full:before {
    content: "\ecc4"
}

.bxs-battery-low:before {
    content: "\ecc5"
}

.bxs-bed:before {
    content: "\ecc6"
}

.bxs-been-here:before {
    content: "\ecc7"
}

.bxs-beer:before {
    content: "\ecc8"
}

.bxs-bell:before {
    content: "\ecc9"
}

.bxs-bell-minus:before {
    content: "\ecca"
}

.bxs-bell-off:before {
    content: "\eccb"
}

.bxs-bell-plus:before {
    content: "\eccc"
}

.bxs-bell-ring:before {
    content: "\eccd"
}

.bxs-bible:before {
    content: "\ecce"
}

.bxs-binoculars:before {
    content: "\eccf"
}

.bxs-blanket:before {
    content: "\ecd0"
}

.bxs-bolt:before {
    content: "\ecd1"
}

.bxs-bolt-circle:before {
    content: "\ecd2"
}

.bxs-bomb:before {
    content: "\ecd3"
}

.bxs-bone:before {
    content: "\ecd4"
}

.bxs-bong:before {
    content: "\ecd5"
}

.bxs-book:before {
    content: "\ecd6"
}

.bxs-book-add:before {
    content: "\ecd7"
}

.bxs-book-alt:before {
    content: "\ecd8"
}

.bxs-book-bookmark:before {
    content: "\ecd9"
}

.bxs-book-content:before {
    content: "\ecda"
}

.bxs-book-heart:before {
    content: "\ecdb"
}

.bxs-bookmark:before {
    content: "\ecdc"
}

.bxs-bookmark-alt:before {
    content: "\ecdd"
}

.bxs-bookmark-alt-minus:before {
    content: "\ecde"
}

.bxs-bookmark-alt-plus:before {
    content: "\ecdf"
}

.bxs-bookmark-heart:before {
    content: "\ece0"
}

.bxs-bookmark-minus:before {
    content: "\ece1"
}

.bxs-bookmark-plus:before {
    content: "\ece2"
}

.bxs-bookmarks:before {
    content: "\ece3"
}

.bxs-bookmark-star:before {
    content: "\ece4"
}

.bxs-book-open:before {
    content: "\ece5"
}

.bxs-book-reader:before {
    content: "\ece6"
}

.bxs-bot:before {
    content: "\ece7"
}

.bxs-bowling-ball:before {
    content: "\ece8"
}

.bxs-box:before {
    content: "\ece9"
}

.bxs-brain:before {
    content: "\ecea"
}

.bxs-briefcase:before {
    content: "\eceb"
}

.bxs-briefcase-alt:before {
    content: "\ecec"
}

.bxs-briefcase-alt-2:before {
    content: "\eced"
}

.bxs-brightness:before {
    content: "\ecee"
}

.bxs-brightness-half:before {
    content: "\ecef"
}

.bxs-brush:before {
    content: "\ecf0"
}

.bxs-brush-alt:before {
    content: "\ecf1"
}

.bxs-bug:before {
    content: "\ecf2"
}

.bxs-bug-alt:before {
    content: "\ecf3"
}

.bxs-building:before {
    content: "\ecf4"
}

.bxs-building-house:before {
    content: "\ecf5"
}

.bxs-buildings:before {
    content: "\ecf6"
}

.bxs-bulb:before {
    content: "\ecf7"
}

.bxs-bullseye:before {
    content: "\ecf8"
}

.bxs-buoy:before {
    content: "\ecf9"
}

.bxs-bus:before {
    content: "\ecfa"
}

.bxs-business:before {
    content: "\ecfb"
}

.bxs-bus-school:before {
    content: "\ecfc"
}

.bxs-cabinet:before {
    content: "\ecfd"
}

.bxs-cake:before {
    content: "\ecfe"
}

.bxs-calculator:before {
    content: "\ecff"
}

.bxs-calendar:before {
    content: "\ed00"
}

.bxs-calendar-alt:before {
    content: "\ed01"
}

.bxs-calendar-check:before {
    content: "\ed02"
}

.bxs-calendar-edit:before {
    content: "\ed03"
}

.bxs-calendar-event:before {
    content: "\ed04"
}

.bxs-calendar-exclamation:before {
    content: "\ed05"
}

.bxs-calendar-heart:before {
    content: "\ed06"
}

.bxs-calendar-minus:before {
    content: "\ed07"
}

.bxs-calendar-plus:before {
    content: "\ed08"
}

.bxs-calendar-star:before {
    content: "\ed09"
}

.bxs-calendar-week:before {
    content: "\ed0a"
}

.bxs-calendar-x:before {
    content: "\ed0b"
}

.bxs-camera:before {
    content: "\ed0c"
}

.bxs-camera-home:before {
    content: "\ed0d"
}

.bxs-camera-movie:before {
    content: "\ed0e"
}

.bxs-camera-off:before {
    content: "\ed0f"
}

.bxs-camera-plus:before {
    content: "\ed10"
}

.bxs-capsule:before {
    content: "\ed11"
}

.bxs-captions:before {
    content: "\ed12"
}

.bxs-car:before {
    content: "\ed13"
}

.bxs-car-battery:before {
    content: "\ed14"
}

.bxs-car-crash:before {
    content: "\ed15"
}

.bxs-card:before {
    content: "\ed16"
}

.bxs-caret-down-circle:before {
    content: "\ed17"
}

.bxs-caret-down-square:before {
    content: "\ed18"
}

.bxs-caret-left-circle:before {
    content: "\ed19"
}

.bxs-caret-left-square:before {
    content: "\ed1a"
}

.bxs-caret-right-circle:before {
    content: "\ed1b"
}

.bxs-caret-right-square:before {
    content: "\ed1c"
}

.bxs-caret-up-circle:before {
    content: "\ed1d"
}

.bxs-caret-up-square:before {
    content: "\ed1e"
}

.bxs-car-garage:before {
    content: "\ed1f"
}

.bxs-car-mechanic:before {
    content: "\ed20"
}

.bxs-carousel:before {
    content: "\ed21"
}

.bxs-cart:before {
    content: "\ed22"
}

.bxs-cart-add:before {
    content: "\ed23"
}

.bxs-cart-alt:before {
    content: "\ed24"
}

.bxs-cart-download:before {
    content: "\ed25"
}

.bxs-car-wash:before {
    content: "\ed26"
}

.bxs-category:before {
    content: "\ed27"
}

.bxs-category-alt:before {
    content: "\ed28"
}

.bxs-cctv:before {
    content: "\ed29"
}

.bxs-certification:before {
    content: "\ed2a"
}

.bxs-chalkboard:before {
    content: "\ed2b"
}

.bxs-chart:before {
    content: "\ed2c"
}

.bxs-chat:before {
    content: "\ed2d"
}

.bxs-checkbox:before {
    content: "\ed2e"
}

.bxs-checkbox-checked:before {
    content: "\ed2f"
}

.bxs-checkbox-minus:before {
    content: "\ed30"
}

.bxs-check-circle:before {
    content: "\ed31"
}

.bxs-check-shield:before {
    content: "\ed32"
}

.bxs-check-square:before {
    content: "\ed33"
}

.bxs-chess:before {
    content: "\ed34"
}

.bxs-chevron-down:before {
    content: "\ed35"
}

.bxs-chevron-down-circle:before {
    content: "\ed36"
}

.bxs-chevron-down-square:before {
    content: "\ed37"
}

.bxs-chevron-left:before {
    content: "\ed38"
}

.bxs-chevron-left-circle:before {
    content: "\ed39"
}

.bxs-chevron-left-square:before {
    content: "\ed3a"
}

.bxs-chevron-right:before {
    content: "\ed3b"
}

.bxs-chevron-right-circle:before {
    content: "\ed3c"
}

.bxs-chevron-right-square:before {
    content: "\ed3d"
}

.bxs-chevrons-down:before {
    content: "\ed3e"
}

.bxs-chevrons-left:before {
    content: "\ed3f"
}

.bxs-chevrons-right:before {
    content: "\ed40"
}

.bxs-chevrons-up:before {
    content: "\ed41"
}

.bxs-chevron-up:before {
    content: "\ed42"
}

.bxs-chevron-up-circle:before {
    content: "\ed43"
}

.bxs-chevron-up-square:before {
    content: "\ed44"
}

.bxs-chip:before {
    content: "\ed45"
}

.bxs-church:before {
    content: "\ed46"
}

.bxs-circle:before {
    content: "\ed47"
}

.bxs-city:before {
    content: "\ed48"
}

.bxs-clinic:before {
    content: "\ed49"
}

.bxs-cloud:before {
    content: "\ed4a"
}

.bxs-cloud-download:before {
    content: "\ed4b"
}

.bxs-cloud-lightning:before {
    content: "\ed4c"
}

.bxs-cloud-rain:before {
    content: "\ed4d"
}

.bxs-cloud-upload:before {
    content: "\ed4e"
}

.bxs-coffee:before {
    content: "\ed4f"
}

.bxs-coffee-alt:before {
    content: "\ed50"
}

.bxs-coffee-togo:before {
    content: "\ed51"
}

.bxs-cog:before {
    content: "\ed52"
}

.bxs-coin:before {
    content: "\ed53"
}

.bxs-coin-stack:before {
    content: "\ed54"
}

.bxs-collection:before {
    content: "\ed55"
}

.bxs-color-fill:before {
    content: "\ed56"
}

.bxs-comment:before {
    content: "\ed57"
}

.bxs-comment-add:before {
    content: "\ed58"
}

.bxs-comment-check:before {
    content: "\ed59"
}

.bxs-comment-detail:before {
    content: "\ed5a"
}

.bxs-comment-dots:before {
    content: "\ed5b"
}

.bxs-comment-edit:before {
    content: "\ed5c"
}

.bxs-comment-error:before {
    content: "\ed5d"
}

.bxs-comment-minus:before {
    content: "\ed5e"
}

.bxs-comment-x:before {
    content: "\ed5f"
}

.bxs-compass:before {
    content: "\ed60"
}

.bxs-component:before {
    content: "\ed61"
}

.bxs-confused:before {
    content: "\ed62"
}

.bxs-contact:before {
    content: "\ed63"
}

.bxs-conversation:before {
    content: "\ed64"
}

.bxs-cookie:before {
    content: "\ed65"
}

.bxs-cool:before {
    content: "\ed66"
}

.bxs-copy:before {
    content: "\ed67"
}

.bxs-copy-alt:before {
    content: "\ed68"
}

.bxs-copyright:before {
    content: "\ed69"
}

.bxs-coupon:before {
    content: "\ed6a"
}

.bxs-credit-card:before {
    content: "\ed6b"
}

.bxs-credit-card-alt:before {
    content: "\ed6c"
}

.bxs-credit-card-front:before {
    content: "\ed6d"
}

.bxs-crop:before {
    content: "\ed6e"
}

.bxs-crown:before {
    content: "\ed6f"
}

.bxs-cube:before {
    content: "\ed70"
}

.bxs-cube-alt:before {
    content: "\ed71"
}

.bxs-cuboid:before {
    content: "\ed72"
}

.bxs-customize:before {
    content: "\ed73"
}

.bxs-cylinder:before {
    content: "\ed74"
}

.bxs-dashboard:before {
    content: "\ed75"
}

.bxs-data:before {
    content: "\ed76"
}

.bxs-detail:before {
    content: "\ed77"
}

.bxs-devices:before {
    content: "\ed78"
}

.bxs-diamond:before {
    content: "\ed79"
}

.bxs-dice-1:before {
    content: "\ed7a"
}

.bxs-dice-2:before {
    content: "\ed7b"
}

.bxs-dice-3:before {
    content: "\ed7c"
}

.bxs-dice-4:before {
    content: "\ed7d"
}

.bxs-dice-5:before {
    content: "\ed7e"
}

.bxs-dice-6:before {
    content: "\ed7f"
}

.bxs-direction-left:before {
    content: "\ed80"
}

.bxs-direction-right:before {
    content: "\ed81"
}

.bxs-directions:before {
    content: "\ed82"
}

.bxs-disc:before {
    content: "\ed83"
}

.bxs-discount:before {
    content: "\ed84"
}

.bxs-dish:before {
    content: "\ed85"
}

.bxs-dislike:before {
    content: "\ed86"
}

.bxs-dizzy:before {
    content: "\ed87"
}

.bxs-dock-bottom:before {
    content: "\ed88"
}

.bxs-dock-left:before {
    content: "\ed89"
}

.bxs-dock-right:before {
    content: "\ed8a"
}

.bxs-dock-top:before {
    content: "\ed8b"
}

.bxs-dollar-circle:before {
    content: "\ed8c"
}

.bxs-donate-blood:before {
    content: "\ed8d"
}

.bxs-donate-heart:before {
    content: "\ed8e"
}

.bxs-door-open:before {
    content: "\ed8f"
}

.bxs-doughnut-chart:before {
    content: "\ed90"
}

.bxs-down-arrow:before {
    content: "\ed91"
}

.bxs-down-arrow-alt:before {
    content: "\ed92"
}

.bxs-down-arrow-circle:before {
    content: "\ed93"
}

.bxs-down-arrow-square:before {
    content: "\ed94"
}

.bxs-download:before {
    content: "\ed95"
}

.bxs-downvote:before {
    content: "\ed96"
}

.bxs-drink:before {
    content: "\ed97"
}

.bxs-droplet-half:before {
    content: "\ed98"
}

.bxs-dryer:before {
    content: "\ed99"
}

.bxs-duplicate:before {
    content: "\ed9a"
}

.bxs-edit:before {
    content: "\ed9b"
}

.bxs-edit-alt:before {
    content: "\ed9c"
}

.bxs-edit-location:before {
    content: "\ed9d"
}

.bxs-eject:before {
    content: "\ed9e"
}

.bxs-envelope:before {
    content: "\ed9f"
}

.bxs-envelope-open:before {
    content: "\eda0"
}

.bxs-eraser:before {
    content: "\eda1"
}

.bxs-error:before {
    content: "\eda2"
}

.bxs-error-alt:before {
    content: "\eda3"
}

.bxs-error-circle:before {
    content: "\eda4"
}

.bxs-ev-station:before {
    content: "\eda5"
}

.bxs-exit:before {
    content: "\eda6"
}

.bxs-extension:before {
    content: "\eda7"
}

.bxs-eyedropper:before {
    content: "\eda8"
}

.bxs-face:before {
    content: "\eda9"
}

.bxs-face-mask:before {
    content: "\edaa"
}

.bxs-factory:before {
    content: "\edab"
}

.bxs-fast-forward-circle:before {
    content: "\edac"
}

.bxs-file:before {
    content: "\edad"
}

.bxs-file-archive:before {
    content: "\edae"
}

.bxs-file-blank:before {
    content: "\edaf"
}

.bxs-file-css:before {
    content: "\edb0"
}

.bxs-file-doc:before {
    content: "\edb1"
}

.bxs-file-export:before {
    content: "\edb2"
}

.bxs-file-find:before {
    content: "\edb3"
}

.bxs-file-gif:before {
    content: "\edb4"
}

.bxs-file-html:before {
    content: "\edb5"
}

.bxs-file-image:before {
    content: "\edb6"
}

.bxs-file-import:before {
    content: "\edb7"
}

.bxs-file-jpg:before {
    content: "\edb8"
}

.bxs-file-js:before {
    content: "\edb9"
}

.bxs-file-json:before {
    content: "\edba"
}

.bxs-file-md:before {
    content: "\edbb"
}

.bxs-file-pdf:before {
    content: "\edbc"
}

.bxs-file-plus:before {
    content: "\edbd"
}

.bxs-file-png:before {
    content: "\edbe"
}

.bxs-file-txt:before {
    content: "\edbf"
}

.bxs-film:before {
    content: "\edc0"
}

.bxs-filter-alt:before {
    content: "\edc1"
}

.bxs-first-aid:before {
    content: "\edc2"
}

.bxs-flag:before {
    content: "\edc3"
}

.bxs-flag-alt:before {
    content: "\edc4"
}

.bxs-flag-checkered:before {
    content: "\edc5"
}

.bxs-flame:before {
    content: "\edc6"
}

.bxs-flask:before {
    content: "\edc7"
}

.bxs-florist:before {
    content: "\edc8"
}

.bxs-folder:before {
    content: "\edc9"
}

.bxs-folder-minus:before {
    content: "\edca"
}

.bxs-folder-open:before {
    content: "\edcb"
}

.bxs-folder-plus:before {
    content: "\edcc"
}

.bxs-food-menu:before {
    content: "\edcd"
}

.bxs-fridge:before {
    content: "\edce"
}

.bxs-game:before {
    content: "\edcf"
}

.bxs-gas-pump:before {
    content: "\edd0"
}

.bxs-ghost:before {
    content: "\edd1"
}

.bxs-gift:before {
    content: "\edd2"
}

.bxs-graduation:before {
    content: "\edd3"
}

.bxs-grid:before {
    content: "\edd4"
}

.bxs-grid-alt:before {
    content: "\edd5"
}

.bxs-group:before {
    content: "\edd6"
}

.bxs-guitar-amp:before {
    content: "\edd7"
}

.bxs-hand:before {
    content: "\edd8"
}

.bxs-hand-down:before {
    content: "\edd9"
}

.bxs-hand-left:before {
    content: "\edda"
}

.bxs-hand-right:before {
    content: "\eddb"
}

.bxs-hand-up:before {
    content: "\eddc"
}

.bxs-happy:before {
    content: "\eddd"
}

.bxs-happy-alt:before {
    content: "\edde"
}

.bxs-happy-beaming:before {
    content: "\eddf"
}

.bxs-happy-heart-eyes:before {
    content: "\ede0"
}

.bxs-hdd:before {
    content: "\ede1"
}

.bxs-heart:before {
    content: "\ede2"
}

.bxs-heart-circle:before {
    content: "\ede3"
}

.bxs-heart-square:before {
    content: "\ede4"
}

.bxs-help-circle:before {
    content: "\ede5"
}

.bxs-hide:before {
    content: "\ede6"
}

.bxs-home:before {
    content: "\ede7"
}

.bxs-home-circle:before {
    content: "\ede8"
}

.bxs-home-heart:before {
    content: "\ede9"
}

.bxs-home-smile:before {
    content: "\edea"
}

.bxs-hotel:before {
    content: "\edeb"
}

.bxs-hourglass:before {
    content: "\edec"
}

.bxs-hourglass-bottom:before {
    content: "\eded"
}

.bxs-hourglass-top:before {
    content: "\edee"
}

.bxs-id-card:before {
    content: "\edef"
}

.bxs-image:before {
    content: "\edf0"
}

.bxs-image-add:before {
    content: "\edf1"
}

.bxs-image-alt:before {
    content: "\edf2"
}

.bxs-inbox:before {
    content: "\edf3"
}

.bxs-info-circle:before {
    content: "\edf4"
}

.bxs-info-square:before {
    content: "\edf5"
}

.bxs-institution:before {
    content: "\edf6"
}

.bxs-joystick:before {
    content: "\edf7"
}

.bxs-joystick-alt:before {
    content: "\edf8"
}

.bxs-joystick-button:before {
    content: "\edf9"
}

.bxs-key:before {
    content: "\edfa"
}

.bxs-keyboard:before {
    content: "\edfb"
}

.bxs-label:before {
    content: "\edfc"
}

.bxs-landmark:before {
    content: "\edfd"
}

.bxs-landscape:before {
    content: "\edfe"
}

.bxs-laugh:before {
    content: "\edff"
}

.bxs-layer:before {
    content: "\ee00"
}

.bxs-layer-minus:before {
    content: "\ee01"
}

.bxs-layer-plus:before {
    content: "\ee02"
}

.bxs-layout:before {
    content: "\ee03"
}

.bxs-left-arrow:before {
    content: "\ee04"
}

.bxs-left-arrow-alt:before {
    content: "\ee05"
}

.bxs-left-arrow-circle:before {
    content: "\ee06"
}

.bxs-left-arrow-square:before {
    content: "\ee07"
}

.bxs-left-down-arrow-circle:before {
    content: "\ee08"
}

.bxs-left-top-arrow-circle:before {
    content: "\ee09"
}

.bxs-like:before {
    content: "\ee0a"
}

.bxs-location-plus:before {
    content: "\ee0b"
}

.bxs-lock:before {
    content: "\ee0c"
}

.bxs-lock-alt:before {
    content: "\ee0d"
}

.bxs-lock-open:before {
    content: "\ee0e"
}

.bxs-lock-open-alt:before {
    content: "\ee0f"
}

.bxs-log-in:before {
    content: "\ee10"
}

.bxs-log-in-circle:before {
    content: "\ee11"
}

.bxs-log-out:before {
    content: "\ee12"
}

.bxs-log-out-circle:before {
    content: "\ee13"
}

.bxs-low-vision:before {
    content: "\ee14"
}

.bxs-magic-wand:before {
    content: "\ee15"
}

.bxs-magnet:before {
    content: "\ee16"
}

.bxs-map:before {
    content: "\ee17"
}

.bxs-map-alt:before {
    content: "\ee18"
}

.bxs-map-pin:before {
    content: "\ee19"
}

.bxs-mask:before {
    content: "\ee1a"
}

.bxs-medal:before {
    content: "\ee1b"
}

.bxs-megaphone:before {
    content: "\ee1c"
}

.bxs-meh:before {
    content: "\ee1d"
}

.bxs-meh-alt:before {
    content: "\ee1e"
}

.bxs-meh-blank:before {
    content: "\ee1f"
}

.bxs-memory-card:before {
    content: "\ee20"
}

.bxs-message:before {
    content: "\ee21"
}

.bxs-message-add:before {
    content: "\ee22"
}

.bxs-message-alt:before {
    content: "\ee23"
}

.bxs-message-alt-add:before {
    content: "\ee24"
}

.bxs-message-alt-check:before {
    content: "\ee25"
}

.bxs-message-alt-detail:before {
    content: "\ee26"
}

.bxs-message-alt-dots:before {
    content: "\ee27"
}

.bxs-message-alt-edit:before {
    content: "\ee28"
}

.bxs-message-alt-error:before {
    content: "\ee29"
}

.bxs-message-alt-minus:before {
    content: "\ee2a"
}

.bxs-message-alt-x:before {
    content: "\ee2b"
}

.bxs-message-check:before {
    content: "\ee2c"
}

.bxs-message-detail:before {
    content: "\ee2d"
}

.bxs-message-dots:before {
    content: "\ee2e"
}

.bxs-message-edit:before {
    content: "\ee2f"
}

.bxs-message-error:before {
    content: "\ee30"
}

.bxs-message-minus:before {
    content: "\ee31"
}

.bxs-message-rounded:before {
    content: "\ee32"
}

.bxs-message-rounded-add:before {
    content: "\ee33"
}

.bxs-message-rounded-check:before {
    content: "\ee34"
}

.bxs-message-rounded-detail:before {
    content: "\ee35"
}

.bxs-message-rounded-dots:before {
    content: "\ee36"
}

.bxs-message-rounded-edit:before {
    content: "\ee37"
}

.bxs-message-rounded-error:before {
    content: "\ee38"
}

.bxs-message-rounded-minus:before {
    content: "\ee39"
}

.bxs-message-rounded-x:before {
    content: "\ee3a"
}

.bxs-message-square:before {
    content: "\ee3b"
}

.bxs-message-square-add:before {
    content: "\ee3c"
}

.bxs-message-square-check:before {
    content: "\ee3d"
}

.bxs-message-square-detail:before {
    content: "\ee3e"
}

.bxs-message-square-dots:before {
    content: "\ee3f"
}

.bxs-message-square-edit:before {
    content: "\ee40"
}

.bxs-message-square-error:before {
    content: "\ee41"
}

.bxs-message-square-minus:before {
    content: "\ee42"
}

.bxs-message-square-x:before {
    content: "\ee43"
}

.bxs-message-x:before {
    content: "\ee44"
}

.bxs-meteor:before {
    content: "\ee45"
}

.bxs-microchip:before {
    content: "\ee46"
}

.bxs-microphone:before {
    content: "\ee47"
}

.bxs-microphone-alt:before {
    content: "\ee48"
}

.bxs-microphone-off:before {
    content: "\ee49"
}

.bxs-minus-circle:before {
    content: "\ee4a"
}

.bxs-minus-square:before {
    content: "\ee4b"
}

.bxs-mobile:before {
    content: "\ee4c"
}

.bxs-mobile-vibration:before {
    content: "\ee4d"
}

.bxs-moon:before {
    content: "\ee4e"
}

.bxs-mouse:before {
    content: "\ee4f"
}

.bxs-mouse-alt:before {
    content: "\ee50"
}

.bxs-movie:before {
    content: "\ee51"
}

.bxs-movie-play:before {
    content: "\ee52"
}

.bxs-music:before {
    content: "\ee53"
}

.bxs-navigation:before {
    content: "\ee54"
}

.bxs-network-chart:before {
    content: "\ee55"
}

.bxs-news:before {
    content: "\ee56"
}

.bxs-no-entry:before {
    content: "\ee57"
}

.bxs-note:before {
    content: "\ee58"
}

.bxs-notepad:before {
    content: "\ee59"
}

.bxs-notification:before {
    content: "\ee5a"
}

.bxs-notification-off:before {
    content: "\ee5b"
}

.bxs-offer:before {
    content: "\ee5c"
}

.bxs-package:before {
    content: "\ee5d"
}

.bxs-paint:before {
    content: "\ee5e"
}

.bxs-paint-roll:before {
    content: "\ee5f"
}

.bxs-palette:before {
    content: "\ee60"
}

.bxs-paper-plane:before {
    content: "\ee61"
}

.bxs-parking:before {
    content: "\ee62"
}

.bxs-paste:before {
    content: "\ee63"
}

.bxs-pen:before {
    content: "\ee64"
}

.bxs-pencil:before {
    content: "\ee65"
}

.bxs-phone:before {
    content: "\ee66"
}

.bxs-phone-call:before {
    content: "\ee67"
}

.bxs-phone-incoming:before {
    content: "\ee68"
}

.bxs-phone-off:before {
    content: "\ee69"
}

.bxs-phone-outgoing:before {
    content: "\ee6a"
}

.bxs-photo-album:before {
    content: "\ee6b"
}

.bxs-piano:before {
    content: "\ee6c"
}

.bxs-pie-chart:before {
    content: "\ee6d"
}

.bxs-pie-chart-alt:before {
    content: "\ee6e"
}

.bxs-pie-chart-alt-2:before {
    content: "\ee6f"
}

.bxs-pin:before {
    content: "\ee70"
}

.bxs-pizza:before {
    content: "\ee71"
}

.bxs-plane:before {
    content: "\ee72"
}

.bxs-plane-alt:before {
    content: "\ee73"
}

.bxs-plane-land:before {
    content: "\ee74"
}

.bxs-planet:before {
    content: "\ee75"
}

.bxs-plane-take-off:before {
    content: "\ee76"
}

.bxs-playlist:before {
    content: "\ee77"
}

.bxs-plug:before {
    content: "\ee78"
}

.bxs-plus-circle:before {
    content: "\ee79"
}

.bxs-plus-square:before {
    content: "\ee7a"
}

.bxs-pointer:before {
    content: "\ee7b"
}

.bxs-polygon:before {
    content: "\ee7c"
}

.bxs-printer:before {
    content: "\ee7d"
}

.bxs-purchase-tag:before {
    content: "\ee7e"
}

.bxs-purchase-tag-alt:before {
    content: "\ee7f"
}

.bxs-pyramid:before {
    content: "\ee80"
}

.bxs-quote-alt-left:before {
    content: "\ee81"
}

.bxs-quote-alt-right:before {
    content: "\ee82"
}

.bxs-quote-left:before {
    content: "\ee83"
}

.bxs-quote-right:before {
    content: "\ee84"
}

.bxs-quote-single-left:before {
    content: "\ee85"
}

.bxs-quote-single-right:before {
    content: "\ee86"
}

.bxs-radiation:before {
    content: "\ee87"
}

.bxs-radio:before {
    content: "\ee88"
}

.bxs-receipt:before {
    content: "\ee89"
}

.bxs-rectangle:before {
    content: "\ee8a"
}

.bxs-registered:before {
    content: "\ee8b"
}

.bxs-rename:before {
    content: "\ee8c"
}

.bxs-report:before {
    content: "\ee8d"
}

.bxs-rewind-circle:before {
    content: "\ee8e"
}

.bxs-right-arrow:before {
    content: "\ee8f"
}

.bxs-right-arrow-alt:before {
    content: "\ee90"
}

.bxs-right-arrow-circle:before {
    content: "\ee91"
}

.bxs-right-arrow-square:before {
    content: "\ee92"
}

.bxs-right-down-arrow-circle:before {
    content: "\ee93"
}

.bxs-right-top-arrow-circle:before {
    content: "\ee94"
}

.bxs-rocket:before {
    content: "\ee95"
}

.bxs-ruler:before {
    content: "\ee96"
}

.bxs-sad:before {
    content: "\ee97"
}

.bxs-save:before {
    content: "\ee98"
}

.bxs-school:before {
    content: "\ee99"
}

.bxs-search:before {
    content: "\ee9a"
}

.bxs-search-alt-2:before {
    content: "\ee9b"
}

.bxs-select-multiple:before {
    content: "\ee9c"
}

.bxs-send:before {
    content: "\ee9d"
}

.bxs-server:before {
    content: "\ee9e"
}

.bxs-shapes:before {
    content: "\ee9f"
}

.bxs-share:before {
    content: "\eea0"
}

.bxs-share-alt:before {
    content: "\eea1"
}

.bxs-shield:before {
    content: "\eea2"
}

.bxs-shield-alt-2:before {
    content: "\eea3"
}

.bxs-shield-x:before {
    content: "\eea4"
}

.bxs-ship:before {
    content: "\eea5"
}

.bxs-shocked:before {
    content: "\eea6"
}

.bxs-shopping-bag:before {
    content: "\eea7"
}

.bxs-shopping-bag-alt:before {
    content: "\eea8"
}

.bxs-shopping-bags:before {
    content: "\eea9"
}

.bxs-show:before {
    content: "\eeaa"
}

.bxs-skip-next-circle:before {
    content: "\eeab"
}

.bxs-skip-previous-circle:before {
    content: "\eeac"
}

.bxs-skull:before {
    content: "\eead"
}

.bxs-sleepy:before {
    content: "\eeae"
}

.bxs-slideshow:before {
    content: "\eeaf"
}

.bxs-smile:before {
    content: "\eeb0"
}

.bxs-sort-alt:before {
    content: "\eeb1"
}

.bxs-spa:before {
    content: "\eeb2"
}

.bxs-speaker:before {
    content: "\eeb3"
}

.bxs-spray-can:before {
    content: "\eeb4"
}

.bxs-spreadsheet:before {
    content: "\eeb5"
}

.bxs-square:before {
    content: "\eeb6"
}

.bxs-square-rounded:before {
    content: "\eeb7"
}

.bxs-star:before {
    content: "\eeb8"
}

.bxs-star-half:before {
    content: "\eeb9"
}

.bxs-sticker:before {
    content: "\eeba"
}

.bxs-stopwatch:before {
    content: "\eebb"
}

.bxs-store:before {
    content: "\eebc"
}

.bxs-store-alt:before {
    content: "\eebd"
}

.bxs-sun:before {
    content: "\eebe"
}

.bxs-tachometer:before {
    content: "\eebf"
}

.bxs-tag:before {
    content: "\eec0"
}

.bxs-tag-alt:before {
    content: "\eec1"
}

.bxs-tag-x:before {
    content: "\eec2"
}

.bxs-taxi:before {
    content: "\eec3"
}

.bxs-tennis-ball:before {
    content: "\eec4"
}

.bxs-terminal:before {
    content: "\eec5"
}

.bxs-thermometer:before {
    content: "\eec6"
}

.bxs-time:before {
    content: "\eec7"
}

.bxs-time-five:before {
    content: "\eec8"
}

.bxs-timer:before {
    content: "\eec9"
}

.bxs-tired:before {
    content: "\eeca"
}

.bxs-toggle-left:before {
    content: "\eecb"
}

.bxs-toggle-right:before {
    content: "\eecc"
}

.bxs-tone:before {
    content: "\eecd"
}

.bxs-torch:before {
    content: "\eece"
}

.bxs-to-top:before {
    content: "\eecf"
}

.bxs-traffic:before {
    content: "\eed0"
}

.bxs-traffic-barrier:before {
    content: "\eed1"
}

.bxs-traffic-cone:before {
    content: "\eed2"
}

.bxs-train:before {
    content: "\eed3"
}

.bxs-trash:before {
    content: "\eed4"
}

.bxs-trash-alt:before {
    content: "\eed5"
}

.bxs-tree:before {
    content: "\eed6"
}

.bxs-trophy:before {
    content: "\eed7"
}

.bxs-truck:before {
    content: "\eed8"
}

.bxs-t-shirt:before {
    content: "\eed9"
}

.bxs-tv:before {
    content: "\eeda"
}

.bxs-up-arrow:before {
    content: "\eedb"
}

.bxs-up-arrow-alt:before {
    content: "\eedc"
}

.bxs-up-arrow-circle:before {
    content: "\eedd"
}

.bxs-up-arrow-square:before {
    content: "\eede"
}

.bxs-upside-down:before {
    content: "\eedf"
}

.bxs-upvote:before {
    content: "\eee0"
}

.bxs-user:before {
    content: "\eee1"
}

.bxs-user-account:before {
    content: "\eee2"
}

.bxs-user-badge:before {
    content: "\eee3"
}

.bxs-user-check:before {
    content: "\eee4"
}

.bxs-user-circle:before {
    content: "\eee5"
}

.bxs-user-detail:before {
    content: "\eee6"
}

.bxs-user-minus:before {
    content: "\eee7"
}

.bxs-user-pin:before {
    content: "\eee8"
}

.bxs-user-plus:before {
    content: "\eee9"
}

.bxs-user-rectangle:before {
    content: "\eeea"
}

.bxs-user-voice:before {
    content: "\eeeb"
}

.bxs-user-x:before {
    content: "\eeec"
}

.bxs-vector:before {
    content: "\eeed"
}

.bxs-vial:before {
    content: "\eeee"
}

.bxs-video:before {
    content: "\eeef"
}

.bxs-video-off:before {
    content: "\eef0"
}

.bxs-video-plus:before {
    content: "\eef1"
}

.bxs-video-recording:before {
    content: "\eef2"
}

.bxs-videos:before {
    content: "\eef3"
}

.bxs-virus:before {
    content: "\eef4"
}

.bxs-virus-block:before {
    content: "\eef5"
}

.bxs-volume:before {
    content: "\eef6"
}

.bxs-volume-full:before {
    content: "\eef7"
}

.bxs-volume-low:before {
    content: "\eef8"
}

.bxs-volume-mute:before {
    content: "\eef9"
}

.bxs-wallet:before {
    content: "\eefa"
}

.bxs-wallet-alt:before {
    content: "\eefb"
}

.bxs-washer:before {
    content: "\eefc"
}

.bxs-watch:before {
    content: "\eefd"
}

.bxs-watch-alt:before {
    content: "\eefe"
}

.bxs-webcam:before {
    content: "\eeff"
}

.bxs-widget:before {
    content: "\ef00"
}

.bxs-window-alt:before {
    content: "\ef01"
}

.bxs-wine:before {
    content: "\ef02"
}

.bxs-wink-smile:before {
    content: "\ef03"
}

.bxs-wink-tongue:before {
    content: "\ef04"
}

.bxs-wrench:before {
    content: "\ef05"
}

.bxs-x-circle:before {
    content: "\ef06"
}

.bxs-x-square:before {
    content: "\ef07"
}

.bxs-yin-yang:before {
    content: "\ef08"
}

.bxs-zap:before {
    content: "\ef09"
}

.bxs-zoom-in:before {
    content: "\ef0a"
}

.bxs-zoom-out:before {
    content: "\ef0b"
}

.la,
.lab,
.lad,
.lal,
.lar,
.las {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1
}

.la-lg {
    font-size: 1.33333em;
    line-height: .75em;
    vertical-align: -.0667em
}

.la-xs {
    font-size: .75em
}

.la-sm {
    font-size: .875em
}

.la-1x {
    font-size: 1em
}

.la-2x {
    font-size: 2em
}

.la-3x {
    font-size: 3em
}

.la-4x {
    font-size: 4em
}

.la-5x {
    font-size: 5em
}

.la-6x {
    font-size: 6em
}

.la-7x {
    font-size: 7em
}

.la-8x {
    font-size: 8em
}

.la-9x {
    font-size: 9em
}

.la-10x {
    font-size: 10em
}

.la-fw {
    text-align: center;
    width: 1.25em
}

.la-ul {
    list-style-type: none;
    margin-left: 2.5em;
    padding-left: 0
}

.la-ul>li {
    position: relative
}

.la-li {
    left: -2em;
    position: absolute;
    text-align: center;
    width: 2em;
    line-height: inherit
}

.la-border {
    border: solid .08em #eee;
    border-radius: .1em;
    padding: .2em .25em .15em
}

.la-pull-left {
    float: left
}

.la-pull-right {
    float: right
}

.la.la-pull-left,
.lab.la-pull-left,
.lal.la-pull-left,
.lar.la-pull-left,
.las.la-pull-left {
    margin-right: .3em
}

.la.la-pull-right,
.lab.la-pull-right,
.lal.la-pull-right,
.lar.la-pull-right,
.las.la-pull-right {
    margin-left: .3em
}

.la-spin {
    -webkit-animation: la-spin 2s infinite linear;
    animation: la-spin 2s infinite linear
}

.la-pulse {
    -webkit-animation: la-spin 1s infinite steps(8);
    animation: la-spin 1s infinite steps(8)
}

@-webkit-keyframes la-spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes la-spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.la-rotate-90 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.la-rotate-180 {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.la-rotate-270 {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg)
}

.la-flip-horizontal {
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1)
}

.la-flip-vertical {
    -webkit-transform: scale(1, -1);
    transform: scale(1, -1)
}

.la-flip-both,
.la-flip-horizontal.la-flip-vertical {
    -webkit-transform: scale(-1, -1);
    transform: scale(-1, -1)
}

:root .la-flip-both,
:root .la-flip-horizontal,
:root .la-flip-vertical,
:root .la-rotate-180,
:root .la-rotate-270,
:root .la-rotate-90 {
    -webkit-filter: none;
    filter: none
}

.la-stack {
    display: inline-block;
    height: 2em;
    line-height: 2em;
    position: relative;
    vertical-align: middle;
    width: 2.5em
}

.la-stack-1x,
.la-stack-2x {
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%
}

.la-stack-1x {
    line-height: inherit
}

.la-stack-2x {
    font-size: 2em
}

.la-inverse {
    color: #fff
}

.la-500px:before {
    content: "\f26e"
}

.la-accessible-icon:before {
    content: "\f368"
}

.la-accusoft:before {
    content: "\f369"
}

.la-acquisitions-incorporated:before {
    content: "\f6af"
}

.la-ad:before {
    content: "\f641"
}

.la-address-book:before {
    content: "\f2b9"
}

.la-address-card:before {
    content: "\f2bb"
}

.la-adjust:before {
    content: "\f042"
}

.la-adn:before {
    content: "\f170"
}

.la-adobe:before {
    content: "\f778"
}

.la-adversal:before {
    content: "\f36a"
}

.la-affiliatetheme:before {
    content: "\f36b"
}

.la-air-freshener:before {
    content: "\f5d0"
}

.la-airbnb:before {
    content: "\f834"
}

.la-algolia:before {
    content: "\f36c"
}

.la-align-center:before {
    content: "\f037"
}

.la-align-justify:before {
    content: "\f039"
}

.la-align-left:before {
    content: "\f036"
}

.la-align-right:before {
    content: "\f038"
}

.la-alipay:before {
    content: "\f642"
}

.la-allergies:before {
    content: "\f461"
}

.la-amazon:before {
    content: "\f270"
}

.la-amazon-pay:before {
    content: "\f42c"
}

.la-ambulance:before {
    content: "\f0f9"
}

.la-american-sign-language-interpreting:before {
    content: "\f2a3"
}

.la-amilia:before {
    content: "\f36d"
}

.la-anchor:before {
    content: "\f13d"
}

.la-android:before {
    content: "\f17b"
}

.la-angellist:before {
    content: "\f209"
}

.la-angle-double-down:before {
    content: "\f103"
}

.la-angle-double-left:before {
    content: "\f100"
}

.la-angle-double-right:before {
    content: "\f101"
}

.la-angle-double-up:before {
    content: "\f102"
}

.la-angle-down:before {
    content: "\f107"
}

.la-angle-left:before {
    content: "\f104"
}

.la-angle-right:before {
    content: "\f105"
}

.la-angle-up:before {
    content: "\f106"
}

.la-angry:before {
    content: "\f556"
}

.la-angrycreative:before {
    content: "\f36e"
}

.la-angular:before {
    content: "\f420"
}

.la-ankh:before {
    content: "\f644"
}

.la-app-store:before {
    content: "\f36f"
}

.la-app-store-ios:before {
    content: "\f370"
}

.la-apper:before {
    content: "\f371"
}

.la-apple:before {
    content: "\f179"
}

.la-apple-alt:before {
    content: "\f5d1"
}

.la-apple-pay:before {
    content: "\f415"
}

.la-archive:before {
    content: "\f187"
}

.la-archway:before {
    content: "\f557"
}

.la-arrow-alt-circle-down:before {
    content: "\f358"
}

.la-arrow-alt-circle-left:before {
    content: "\f359"
}

.la-arrow-alt-circle-right:before {
    content: "\f35a"
}

.la-arrow-alt-circle-up:before {
    content: "\f35b"
}

.la-arrow-circle-down:before {
    content: "\f0ab"
}

.la-arrow-circle-left:before {
    content: "\f0a8"
}

.la-arrow-circle-right:before {
    content: "\f0a9"
}

.la-arrow-circle-up:before {
    content: "\f0aa"
}

.la-arrow-down:before {
    content: "\f063"
}

.la-arrow-left:before {
    content: "\f060"
}

.la-arrow-right:before {
    content: "\f061"
}

.la-arrow-up:before {
    content: "\f062"
}

.la-arrows-alt:before {
    content: "\f0b2"
}

.la-arrows-alt-h:before {
    content: "\f337"
}

.la-arrows-alt-v:before {
    content: "\f338"
}

.la-artstation:before {
    content: "\f77a"
}

.la-assistive-listening-systems:before {
    content: "\f2a2"
}

.la-asterisk:before {
    content: "\f069"
}

.la-asymmetrik:before {
    content: "\f372"
}

.la-at:before {
    content: "\f1fa"
}

.la-atlas:before {
    content: "\f558"
}

.la-atlassian:before {
    content: "\f77b"
}

.la-atom:before {
    content: "\f5d2"
}

.la-audible:before {
    content: "\f373"
}

.la-audio-description:before {
    content: "\f29e"
}

.la-autoprefixer:before {
    content: "\f41c"
}

.la-avianex:before {
    content: "\f374"
}

.la-aviato:before {
    content: "\f421"
}

.la-award:before {
    content: "\f559"
}

.la-aws:before {
    content: "\f375"
}

.la-baby:before {
    content: "\f77c"
}

.la-baby-carriage:before {
    content: "\f77d"
}

.la-backspace:before {
    content: "\f55a"
}

.la-backward:before {
    content: "\f04a"
}

.la-bacon:before {
    content: "\f7e5"
}

.la-balance-scale:before {
    content: "\f24e"
}

.la-balance-scale-left:before {
    content: "\f515"
}

.la-balance-scale-right:before {
    content: "\f516"
}

.la-ban:before {
    content: "\f05e"
}

.la-band-aid:before {
    content: "\f462"
}

.la-bandcamp:before {
    content: "\f2d5"
}

.la-barcode:before {
    content: "\f02a"
}

.la-bars:before {
    content: "\f0c9"
}

.la-baseball-ball:before {
    content: "\f433"
}

.la-basketball-ball:before {
    content: "\f434"
}

.la-bath:before {
    content: "\f2cd"
}

.la-battery-empty:before {
    content: "\f244"
}

.la-battery-full:before {
    content: "\f240"
}

.la-battery-half:before {
    content: "\f242"
}

.la-battery-quarter:before {
    content: "\f243"
}

.la-battery-three-quarters:before {
    content: "\f241"
}

.la-battle-net:before {
    content: "\f835"
}

.la-bed:before {
    content: "\f236"
}

.la-beer:before {
    content: "\f0fc"
}

.la-behance:before {
    content: "\f1b4"
}

.la-behance-square:before {
    content: "\f1b5"
}

.la-bell:before {
    content: "\f0f3"
}

.la-bell-slash:before {
    content: "\f1f6"
}

.la-bezier-curve:before {
    content: "\f55b"
}

.la-bible:before {
    content: "\f647"
}

.la-bicycle:before {
    content: "\f206"
}

.la-biking:before {
    content: "\f84a"
}

.la-bimobject:before {
    content: "\f378"
}

.la-binoculars:before {
    content: "\f1e5"
}

.la-biohazard:before {
    content: "\f780"
}

.la-birthday-cake:before {
    content: "\f1fd"
}

.la-bitbucket:before {
    content: "\f171"
}

.la-bitcoin:before {
    content: "\f379"
}

.la-bity:before {
    content: "\f37a"
}

.la-black-tie:before {
    content: "\f27e"
}

.la-blackberry:before {
    content: "\f37b"
}

.la-blender:before {
    content: "\f517"
}

.la-blender-phone:before {
    content: "\f6b6"
}

.la-blind:before {
    content: "\f29d"
}

.la-blog:before {
    content: "\f781"
}

.la-blogger:before {
    content: "\f37c"
}

.la-blogger-b:before {
    content: "\f37d"
}

.la-bluetooth:before {
    content: "\f293"
}

.la-bluetooth-b:before {
    content: "\f294"
}

.la-bold:before {
    content: "\f032"
}

.la-bolt:before {
    content: "\f0e7"
}

.la-bomb:before {
    content: "\f1e2"
}

.la-bone:before {
    content: "\f5d7"
}

.la-bong:before {
    content: "\f55c"
}

.la-book:before {
    content: "\f02d"
}

.la-book-dead:before {
    content: "\f6b7"
}

.la-book-medical:before {
    content: "\f7e6"
}

.la-book-open:before {
    content: "\f518"
}

.la-book-reader:before {
    content: "\f5da"
}

.la-bookmark:before {
    content: "\f02e"
}

.la-bootstrap:before {
    content: "\f836"
}

.la-border-all:before {
    content: "\f84c"
}

.la-border-none:before {
    content: "\f850"
}

.la-border-style:before {
    content: "\f853"
}

.la-bowling-ball:before {
    content: "\f436"
}

.la-box:before {
    content: "\f466"
}

.la-box-open:before {
    content: "\f49e"
}

.la-boxes:before {
    content: "\f468"
}

.la-braille:before {
    content: "\f2a1"
}

.la-brain:before {
    content: "\f5dc"
}

.la-bread-slice:before {
    content: "\f7ec"
}

.la-briefcase:before {
    content: "\f0b1"
}

.la-briefcase-medical:before {
    content: "\f469"
}

.la-broadcast-tower:before {
    content: "\f519"
}

.la-broom:before {
    content: "\f51a"
}

.la-brush:before {
    content: "\f55d"
}

.la-btc:before {
    content: "\f15a"
}

.la-buffer:before {
    content: "\f837"
}

.la-bug:before {
    content: "\f188"
}

.la-building:before {
    content: "\f1ad"
}

.la-bullhorn:before {
    content: "\f0a1"
}

.la-bullseye:before {
    content: "\f140"
}

.la-burn:before {
    content: "\f46a"
}

.la-buromobelexperte:before {
    content: "\f37f"
}

.la-bus:before {
    content: "\f207"
}

.la-bus-alt:before {
    content: "\f55e"
}

.la-business-time:before {
    content: "\f64a"
}

.la-buy-n-large:before {
    content: "\f8a6"
}

.la-buysellads:before {
    content: "\f20d"
}

.la-calculator:before {
    content: "\f1ec"
}

.la-calendar:before {
    content: "\f133"
}

.la-calendar-alt:before {
    content: "\f073"
}

.la-calendar-check:before {
    content: "\f274"
}

.la-calendar-day:before {
    content: "\f783"
}

.la-calendar-minus:before {
    content: "\f272"
}

.la-calendar-plus:before {
    content: "\f271"
}

.la-calendar-times:before {
    content: "\f273"
}

.la-calendar-week:before {
    content: "\f784"
}

.la-camera:before {
    content: "\f030"
}

.la-camera-retro:before {
    content: "\f083"
}

.la-campground:before {
    content: "\f6bb"
}

.la-canadian-maple-leaf:before {
    content: "\f785"
}

.la-candy-cane:before {
    content: "\f786"
}

.la-cannabis:before {
    content: "\f55f"
}

.la-capsules:before {
    content: "\f46b"
}

.la-car:before {
    content: "\f1b9"
}

.la-car-alt:before {
    content: "\f5de"
}

.la-car-battery:before {
    content: "\f5df"
}

.la-car-crash:before {
    content: "\f5e1"
}

.la-car-side:before {
    content: "\f5e4"
}

.la-caret-down:before {
    content: "\f0d7"
}

.la-caret-left:before {
    content: "\f0d9"
}

.la-caret-right:before {
    content: "\f0da"
}

.la-caret-square-down:before {
    content: "\f150"
}

.la-caret-square-left:before {
    content: "\f191"
}

.la-caret-square-right:before {
    content: "\f152"
}

.la-caret-square-up:before {
    content: "\f151"
}

.la-caret-up:before {
    content: "\f0d8"
}

.la-carrot:before {
    content: "\f787"
}

.la-cart-arrow-down:before {
    content: "\f218"
}

.la-cart-plus:before {
    content: "\f217"
}

.la-cash-register:before {
    content: "\f788"
}

.la-cat:before {
    content: "\f6be"
}

.la-cc-amazon-pay:before {
    content: "\f42d"
}

.la-cc-amex:before {
    content: "\f1f3"
}

.la-cc-apple-pay:before {
    content: "\f416"
}

.la-cc-diners-club:before {
    content: "\f24c"
}

.la-cc-discover:before {
    content: "\f1f2"
}

.la-cc-jcb:before {
    content: "\f24b"
}

.la-cc-mastercard:before {
    content: "\f1f1"
}

.la-cc-paypal:before {
    content: "\f1f4"
}

.la-cc-stripe:before {
    content: "\f1f5"
}

.la-cc-visa:before {
    content: "\f1f0"
}

.la-centercode:before {
    content: "\f380"
}

.la-centos:before {
    content: "\f789"
}

.la-certificate:before {
    content: "\f0a3"
}

.la-chair:before {
    content: "\f6c0"
}

.la-chalkboard:before {
    content: "\f51b"
}

.la-chalkboard-teacher:before {
    content: "\f51c"
}

.la-charging-station:before {
    content: "\f5e7"
}

.la-chart-area:before {
    content: "\f1fe"
}

.la-chart-bar:before {
    content: "\f080"
}

.la-chart-line:before {
    content: "\f201"
}

.la-chart-pie:before {
    content: "\f200"
}

.la-check:before {
    content: "\f00c"
}

.la-check-circle:before {
    content: "\f058"
}

.la-check-double:before {
    content: "\f560"
}

.la-check-square:before {
    content: "\f14a"
}

.la-cheese:before {
    content: "\f7ef"
}

.la-chess:before {
    content: "\f439"
}

.la-chess-bishop:before {
    content: "\f43a"
}

.la-chess-board:before {
    content: "\f43c"
}

.la-chess-king:before {
    content: "\f43f"
}

.la-chess-knight:before {
    content: "\f441"
}

.la-chess-pawn:before {
    content: "\f443"
}

.la-chess-queen:before {
    content: "\f445"
}

.la-chess-rook:before {
    content: "\f447"
}

.la-chevron-circle-down:before {
    content: "\f13a"
}

.la-chevron-circle-left:before {
    content: "\f137"
}

.la-chevron-circle-right:before {
    content: "\f138"
}

.la-chevron-circle-up:before {
    content: "\f139"
}

.la-chevron-down:before {
    content: "\f078"
}

.la-chevron-left:before {
    content: "\f053"
}

.la-chevron-right:before {
    content: "\f054"
}

.la-chevron-up:before {
    content: "\f077"
}

.la-child:before {
    content: "\f1ae"
}

.la-chrome:before {
    content: "\f268"
}

.la-chromecast:before {
    content: "\f838"
}

.la-church:before {
    content: "\f51d"
}

.la-circle:before {
    content: "\f111"
}

.la-circle-notch:before {
    content: "\f1ce"
}

.la-city:before {
    content: "\f64f"
}

.la-clinic-medical:before {
    content: "\f7f2"
}

.la-clipboard:before {
    content: "\f328"
}

.la-clipboard-check:before {
    content: "\f46c"
}

.la-clipboard-list:before {
    content: "\f46d"
}

.la-clock:before {
    content: "\f017"
}

.la-clone:before {
    content: "\f24d"
}

.la-closed-captioning:before {
    content: "\f20a"
}

.la-cloud:before {
    content: "\f0c2"
}

.la-cloud-download-alt:before {
    content: "\f381"
}

.la-cloud-meatball:before {
    content: "\f73b"
}

.la-cloud-moon:before {
    content: "\f6c3"
}

.la-cloud-moon-rain:before {
    content: "\f73c"
}

.la-cloud-rain:before {
    content: "\f73d"
}

.la-cloud-showers-heavy:before {
    content: "\f740"
}

.la-cloud-sun:before {
    content: "\f6c4"
}

.la-cloud-sun-rain:before {
    content: "\f743"
}

.la-cloud-upload-alt:before {
    content: "\f382"
}

.la-cloudscale:before {
    content: "\f383"
}

.la-cloudsmith:before {
    content: "\f384"
}

.la-cloudversify:before {
    content: "\f385"
}

.la-cocktail:before {
    content: "\f561"
}

.la-code:before {
    content: "\f121"
}

.la-code-branch:before {
    content: "\f126"
}

.la-codepen:before {
    content: "\f1cb"
}

.la-codiepie:before {
    content: "\f284"
}

.la-coffee:before {
    content: "\f0f4"
}

.la-cog:before {
    content: "\f013"
}

.la-cogs:before {
    content: "\f085"
}

.la-coins:before {
    content: "\f51e"
}

.la-columns:before {
    content: "\f0db"
}

.la-comment:before {
    content: "\f075"
}

.la-comment-alt:before {
    content: "\f27a"
}

.la-comment-dollar:before {
    content: "\f651"
}

.la-comment-dots:before {
    content: "\f4ad"
}

.la-comment-medical:before {
    content: "\f7f5"
}

.la-comment-slash:before {
    content: "\f4b3"
}

.la-comments:before {
    content: "\f086"
}

.la-comments-dollar:before {
    content: "\f653"
}

.la-compact-disc:before {
    content: "\f51f"
}

.la-compass:before {
    content: "\f14e"
}

.la-compress:before {
    content: "\f066"
}

.la-compress-arrows-alt:before {
    content: "\f78c"
}

.la-concierge-bell:before {
    content: "\f562"
}

.la-confluence:before {
    content: "\f78d"
}

.la-connectdevelop:before {
    content: "\f20e"
}

.la-contao:before {
    content: "\f26d"
}

.la-cookie:before {
    content: "\f563"
}

.la-cookie-bite:before {
    content: "\f564"
}

.la-copy:before {
    content: "\f0c5"
}

.la-copyright:before {
    content: "\f1f9"
}

.la-cotton-bureau:before {
    content: "\f89e"
}

.la-couch:before {
    content: "\f4b8"
}

.la-cpanel:before {
    content: "\f388"
}

.la-creative-commons:before {
    content: "\f25e"
}

.la-creative-commons-by:before {
    content: "\f4e7"
}

.la-creative-commons-nc:before {
    content: "\f4e8"
}

.la-creative-commons-nc-eu:before {
    content: "\f4e9"
}

.la-creative-commons-nc-jp:before {
    content: "\f4ea"
}

.la-creative-commons-nd:before {
    content: "\f4eb"
}

.la-creative-commons-pd:before {
    content: "\f4ec"
}

.la-creative-commons-pd-alt:before {
    content: "\f4ed"
}

.la-creative-commons-remix:before {
    content: "\f4ee"
}

.la-creative-commons-sa:before {
    content: "\f4ef"
}

.la-creative-commons-sampling:before {
    content: "\f4f0"
}

.la-creative-commons-sampling-plus:before {
    content: "\f4f1"
}

.la-creative-commons-share:before {
    content: "\f4f2"
}

.la-creative-commons-zero:before {
    content: "\f4f3"
}

.la-credit-card:before {
    content: "\f09d"
}

.la-critical-role:before {
    content: "\f6c9"
}

.la-crop:before {
    content: "\f125"
}

.la-crop-alt:before {
    content: "\f565"
}

.la-cross:before {
    content: "\f654"
}

.la-crosshairs:before {
    content: "\f05b"
}

.la-crow:before {
    content: "\f520"
}

.la-crown:before {
    content: "\f521"
}

.la-crutch:before {
    content: "\f7f7"
}

.la-css3:before {
    content: "\f13c"
}

.la-css3-alt:before {
    content: "\f38b"
}

.la-cube:before {
    content: "\f1b2"
}

.la-cubes:before {
    content: "\f1b3"
}

.la-cut:before {
    content: "\f0c4"
}

.la-cuttlefish:before {
    content: "\f38c"
}

.la-d-and-d:before {
    content: "\f38d"
}

.la-d-and-d-beyond:before {
    content: "\f6ca"
}

.la-dashcube:before {
    content: "\f210"
}

.la-database:before {
    content: "\f1c0"
}

.la-deaf:before {
    content: "\f2a4"
}

.la-delicious:before {
    content: "\f1a5"
}

.la-democrat:before {
    content: "\f747"
}

.la-deploydog:before {
    content: "\f38e"
}

.la-deskpro:before {
    content: "\f38f"
}

.la-desktop:before {
    content: "\f108"
}

.la-dev:before {
    content: "\f6cc"
}

.la-deviantart:before {
    content: "\f1bd"
}

.la-dharmachakra:before {
    content: "\f655"
}

.la-dhl:before {
    content: "\f790"
}

.la-diagnoses:before {
    content: "\f470"
}

.la-diaspora:before {
    content: "\f791"
}

.la-dice:before {
    content: "\f522"
}

.la-dice-d20:before {
    content: "\f6cf"
}

.la-dice-d6:before {
    content: "\f6d1"
}

.la-dice-five:before {
    content: "\f523"
}

.la-dice-four:before {
    content: "\f524"
}

.la-dice-one:before {
    content: "\f525"
}

.la-dice-six:before {
    content: "\f526"
}

.la-dice-three:before {
    content: "\f527"
}

.la-dice-two:before {
    content: "\f528"
}

.la-digg:before {
    content: "\f1a6"
}

.la-digital-ocean:before {
    content: "\f391"
}

.la-digital-tachograph:before {
    content: "\f566"
}

.la-directions:before {
    content: "\f5eb"
}

.la-discord:before {
    content: "\f392"
}

.la-discourse:before {
    content: "\f393"
}

.la-divide:before {
    content: "\f529"
}

.la-dizzy:before {
    content: "\f567"
}

.la-dna:before {
    content: "\f471"
}

.la-dochub:before {
    content: "\f394"
}

.la-docker:before {
    content: "\f395"
}

.la-dog:before {
    content: "\f6d3"
}

.la-dollar-sign:before {
    content: "\f155"
}

.la-dolly:before {
    content: "\f472"
}

.la-dolly-flatbed:before {
    content: "\f474"
}

.la-donate:before {
    content: "\f4b9"
}

.la-door-closed:before {
    content: "\f52a"
}

.la-door-open:before {
    content: "\f52b"
}

.la-dot-circle:before {
    content: "\f192"
}

.la-dove:before {
    content: "\f4ba"
}

.la-download:before {
    content: "\f019"
}

.la-draft2digital:before {
    content: "\f396"
}

.la-drafting-compass:before {
    content: "\f568"
}

.la-dragon:before {
    content: "\f6d5"
}

.la-draw-polygon:before {
    content: "\f5ee"
}

.la-dribbble:before {
    content: "\f17d"
}

.la-dribbble-square:before {
    content: "\f397"
}

.la-dropbox:before {
    content: "\f16b"
}

.la-drum:before {
    content: "\f569"
}

.la-drum-steelpan:before {
    content: "\f56a"
}

.la-drumstick-bite:before {
    content: "\f6d7"
}

.la-drupal:before {
    content: "\f1a9"
}

.la-dumbbell:before {
    content: "\f44b"
}

.la-dumpster:before {
    content: "\f793"
}

.la-dumpster-fire:before {
    content: "\f794"
}

.la-dungeon:before {
    content: "\f6d9"
}

.la-dyalog:before {
    content: "\f399"
}

.la-earlybirds:before {
    content: "\f39a"
}

.la-ebay:before {
    content: "\f4f4"
}

.la-edge:before {
    content: "\f282"
}

.la-edit:before {
    content: "\f044"
}

.la-egg:before {
    content: "\f7fb"
}

.la-eject:before {
    content: "\f052"
}

.la-elementor:before {
    content: "\f430"
}

.la-ellipsis-h:before {
    content: "\f141"
}

.la-ellipsis-v:before {
    content: "\f142"
}

.la-ello:before {
    content: "\f5f1"
}

.la-ember:before {
    content: "\f423"
}

.la-empire:before {
    content: "\f1d1"
}

.la-envelope:before {
    content: "\f0e0"
}

.la-envelope-open:before {
    content: "\f2b6"
}

.la-envelope-open-text:before {
    content: "\f658"
}

.la-envelope-square:before {
    content: "\f199"
}

.la-envira:before {
    content: "\f299"
}

.la-equals:before {
    content: "\f52c"
}

.la-eraser:before {
    content: "\f12d"
}

.la-erlang:before {
    content: "\f39d"
}

.la-ethereum:before {
    content: "\f42e"
}

.la-ethernet:before {
    content: "\f796"
}

.la-etsy:before {
    content: "\f2d7"
}

.la-euro-sign:before {
    content: "\f153"
}

.la-evernote:before {
    content: "\f839"
}

.la-exchange-alt:before {
    content: "\f362"
}

.la-exclamation:before {
    content: "\f12a"
}

.la-exclamation-circle:before {
    content: "\f06a"
}

.la-exclamation-triangle:before {
    content: "\f071"
}

.la-expand:before {
    content: "\f065"
}

.la-expand-arrows-alt:before {
    content: "\f31e"
}

.la-expeditedssl:before {
    content: "\f23e"
}

.la-external-link-alt:before {
    content: "\f35d"
}

.la-external-link-square-alt:before {
    content: "\f360"
}

.la-eye:before {
    content: "\f06e"
}

.la-eye-dropper:before {
    content: "\f1fb"
}

.la-eye-slash:before {
    content: "\f070"
}

.la-facebook:before {
    content: "\f09a"
}

.la-facebook-f:before {
    content: "\f39e"
}

.la-facebook-messenger:before {
    content: "\f39f"
}

.la-facebook-square:before {
    content: "\f082"
}

.la-fan:before {
    content: "\f863"
}

.la-fantasy-flight-games:before {
    content: "\f6dc"
}

.la-fast-backward:before {
    content: "\f049"
}

.la-fast-forward:before {
    content: "\f050"
}

.la-fax:before {
    content: "\f1ac"
}

.la-feather:before {
    content: "\f52d"
}

.la-feather-alt:before {
    content: "\f56b"
}

.la-fedex:before {
    content: "\f797"
}

.la-fedora:before {
    content: "\f798"
}

.la-female:before {
    content: "\f182"
}

.la-fighter-jet:before {
    content: "\f0fb"
}

.la-figma:before {
    content: "\f799"
}

.la-file:before {
    content: "\f15b"
}

.la-file-alt:before {
    content: "\f15c"
}

.la-file-archive:before {
    content: "\f1c6"
}

.la-file-audio:before {
    content: "\f1c7"
}

.la-file-code:before {
    content: "\f1c9"
}

.la-file-contract:before {
    content: "\f56c"
}

.la-file-csv:before {
    content: "\f6dd"
}

.la-file-download:before {
    content: "\f56d"
}

.la-file-excel:before {
    content: "\f1c3"
}

.la-file-export:before {
    content: "\f56e"
}

.la-file-image:before {
    content: "\f1c5"
}

.la-file-import:before {
    content: "\f56f"
}

.la-file-invoice:before {
    content: "\f570"
}

.la-file-invoice-dollar:before {
    content: "\f571"
}

.la-file-medical:before {
    content: "\f477"
}

.la-file-medical-alt:before {
    content: "\f478"
}

.la-file-pdf:before {
    content: "\f1c1"
}

.la-file-powerpoint:before {
    content: "\f1c4"
}

.la-file-prescription:before {
    content: "\f572"
}

.la-file-signature:before {
    content: "\f573"
}

.la-file-upload:before {
    content: "\f574"
}

.la-file-video:before {
    content: "\f1c8"
}

.la-file-word:before {
    content: "\f1c2"
}

.la-fill:before {
    content: "\f575"
}

.la-fill-drip:before {
    content: "\f576"
}

.la-film:before {
    content: "\f008"
}

.la-filter:before {
    content: "\f0b0"
}

.la-fingerprint:before {
    content: "\f577"
}

.la-fire:before {
    content: "\f06d"
}

.la-fire-alt:before {
    content: "\f7e4"
}

.la-fire-extinguisher:before {
    content: "\f134"
}

.la-firefox:before {
    content: "\f269"
}

.la-first-aid:before {
    content: "\f479"
}

.la-first-order:before {
    content: "\f2b0"
}

.la-first-order-alt:before {
    content: "\f50a"
}

.la-firstdraft:before {
    content: "\f3a1"
}

.la-fish:before {
    content: "\f578"
}

.la-fist-raised:before {
    content: "\f6de"
}

.la-flag:before {
    content: "\f024"
}

.la-flag-checkered:before {
    content: "\f11e"
}

.la-flag-usa:before {
    content: "\f74d"
}

.la-flask:before {
    content: "\f0c3"
}

.la-flickr:before {
    content: "\f16e"
}

.la-flipboard:before {
    content: "\f44d"
}

.la-flushed:before {
    content: "\f579"
}

.la-fly:before {
    content: "\f417"
}

.la-folder:before {
    content: "\f07b"
}

.la-folder-minus:before {
    content: "\f65d"
}

.la-folder-open:before {
    content: "\f07c"
}

.la-folder-plus:before {
    content: "\f65e"
}

.la-font:before {
    content: "\f031"
}

.la-font-awesome:before {
    content: "\f2b4"
}

.la-font-awesome-alt:before {
    content: "\f35c"
}

.la-font-awesome-flag:before {
    content: "\f425"
}

.la-font-awesome-logo-full:before {
    content: "\f4e6"
}

.la-fonticons:before {
    content: "\f280"
}

.la-fonticons-fi:before {
    content: "\f3a2"
}

.la-football-ball:before {
    content: "\f44e"
}

.la-fort-awesome:before {
    content: "\f286"
}

.la-fort-awesome-alt:before {
    content: "\f3a3"
}

.la-forumbee:before {
    content: "\f211"
}

.la-forward:before {
    content: "\f04e"
}

.la-foursquare:before {
    content: "\f180"
}

.la-free-code-camp:before {
    content: "\f2c5"
}

.la-freebsd:before {
    content: "\f3a4"
}

.la-frog:before {
    content: "\f52e"
}

.la-frown:before {
    content: "\f119"
}

.la-frown-open:before {
    content: "\f57a"
}

.la-fulcrum:before {
    content: "\f50b"
}

.la-funnel-dollar:before {
    content: "\f662"
}

.la-futbol:before {
    content: "\f1e3"
}

.la-galactic-republic:before {
    content: "\f50c"
}

.la-galactic-senate:before {
    content: "\f50d"
}

.la-gamepad:before {
    content: "\f11b"
}

.la-gas-pump:before {
    content: "\f52f"
}

.la-gavel:before {
    content: "\f0e3"
}

.la-gem:before {
    content: "\f3a5"
}

.la-genderless:before {
    content: "\f22d"
}

.la-get-pocket:before {
    content: "\f265"
}

.la-gg:before {
    content: "\f260"
}

.la-gg-circle:before {
    content: "\f261"
}

.la-ghost:before {
    content: "\f6e2"
}

.la-gift:before {
    content: "\f06b"
}

.la-gifts:before {
    content: "\f79c"
}

.la-git:before {
    content: "\f1d3"
}

.la-git-alt:before {
    content: "\f841"
}

.la-git-square:before {
    content: "\f1d2"
}

.la-github:before {
    content: "\f09b"
}

.la-github-alt:before {
    content: "\f113"
}

.la-github-square:before {
    content: "\f092"
}

.la-gitkraken:before {
    content: "\f3a6"
}

.la-gitlab:before {
    content: "\f296"
}

.la-gitter:before {
    content: "\f426"
}

.la-glass-cheers:before {
    content: "\f79f"
}

.la-glass-martini:before {
    content: "\f000"
}

.la-glass-martini-alt:before {
    content: "\f57b"
}

.la-glass-whiskey:before {
    content: "\f7a0"
}

.la-glasses:before {
    content: "\f530"
}

.la-glide:before {
    content: "\f2a5"
}

.la-glide-g:before {
    content: "\f2a6"
}

.la-globe:before {
    content: "\f0ac"
}

.la-globe-africa:before {
    content: "\f57c"
}

.la-globe-americas:before {
    content: "\f57d"
}

.la-globe-asia:before {
    content: "\f57e"
}

.la-globe-europe:before {
    content: "\f7a2"
}

.la-gofore:before {
    content: "\f3a7"
}

.la-golf-ball:before {
    content: "\f450"
}

.la-goodreads:before {
    content: "\f3a8"
}

.la-goodreads-g:before {
    content: "\f3a9"
}

.la-google:before {
    content: "\f1a0"
}

.la-google-drive:before {
    content: "\f3aa"
}

.la-google-play:before {
    content: "\f3ab"
}

.la-google-plus:before {
    content: "\f2b3"
}

.la-google-plus-g:before {
    content: "\f0d5"
}

.la-google-plus-square:before {
    content: "\f0d4"
}

.la-google-wallet:before {
    content: "\f1ee"
}

.la-gopuram:before {
    content: "\f664"
}

.la-graduation-cap:before {
    content: "\f19d"
}

.la-gratipay:before {
    content: "\f184"
}

.la-grav:before {
    content: "\f2d6"
}

.la-greater-than:before {
    content: "\f531"
}

.la-greater-than-equal:before {
    content: "\f532"
}

.la-grimace:before {
    content: "\f57f"
}

.la-grin:before {
    content: "\f580"
}

.la-grin-alt:before {
    content: "\f581"
}

.la-grin-beam:before {
    content: "\f582"
}

.la-grin-beam-sweat:before {
    content: "\f583"
}

.la-grin-hearts:before {
    content: "\f584"
}

.la-grin-squint:before {
    content: "\f585"
}

.la-grin-squint-tears:before {
    content: "\f586"
}

.la-grin-stars:before {
    content: "\f587"
}

.la-grin-tears:before {
    content: "\f588"
}

.la-grin-tongue:before {
    content: "\f589"
}

.la-grin-tongue-squint:before {
    content: "\f58a"
}

.la-grin-tongue-wink:before {
    content: "\f58b"
}

.la-grin-wink:before {
    content: "\f58c"
}

.la-grip-horizontal:before {
    content: "\f58d"
}

.la-grip-lines:before {
    content: "\f7a4"
}

.la-grip-lines-vertical:before {
    content: "\f7a5"
}

.la-grip-vertical:before {
    content: "\f58e"
}

.la-gripfire:before {
    content: "\f3ac"
}

.la-grunt:before {
    content: "\f3ad"
}

.la-guitar:before {
    content: "\f7a6"
}

.la-gulp:before {
    content: "\f3ae"
}

.la-h-square:before {
    content: "\f0fd"
}

.la-hacker-news:before {
    content: "\f1d4"
}

.la-hacker-news-square:before {
    content: "\f3af"
}

.la-hackerrank:before {
    content: "\f5f7"
}

.la-hamburger:before {
    content: "\f805"
}

.la-hammer:before {
    content: "\f6e3"
}

.la-hamsa:before {
    content: "\f665"
}

.la-hand-holding:before {
    content: "\f4bd"
}

.la-hand-holding-heart:before {
    content: "\f4be"
}

.la-hand-holding-usd:before {
    content: "\f4c0"
}

.la-hand-lizard:before {
    content: "\f258"
}

.la-hand-middle-finger:before {
    content: "\f806"
}

.la-hand-paper:before {
    content: "\f256"
}

.la-hand-peace:before {
    content: "\f25b"
}

.la-hand-point-down:before {
    content: "\f0a7"
}

.la-hand-point-left:before {
    content: "\f0a5"
}

.la-hand-point-right:before {
    content: "\f0a4"
}

.la-hand-point-up:before {
    content: "\f0a6"
}

.la-hand-pointer:before {
    content: "\f25a"
}

.la-hand-rock:before {
    content: "\f255"
}

.la-hand-scissors:before {
    content: "\f257"
}

.la-hand-spock:before {
    content: "\f259"
}

.la-hands:before {
    content: "\f4c2"
}

.la-hands-helping:before {
    content: "\f4c4"
}

.la-handshake:before {
    content: "\f2b5"
}

.la-hanukiah:before {
    content: "\f6e6"
}

.la-hard-hat:before {
    content: "\f807"
}

.la-hashtag:before {
    content: "\f292"
}

.la-hat-cowboy:before {
    content: "\f8c0"
}

.la-hat-cowboy-side:before {
    content: "\f8c1"
}

.la-hat-wizard:before {
    content: "\f6e8"
}

.la-haykal:before {
    content: "\f666"
}

.la-hdd:before {
    content: "\f0a0"
}

.la-heading:before {
    content: "\f1dc"
}

.la-headphones:before {
    content: "\f025"
}

.la-headphones-alt:before {
    content: "\f58f"
}

.la-headset:before {
    content: "\f590"
}

.la-heart:before {
    content: "\f004"
}

.la-heart-broken:before {
    content: "\f7a9"
}

.la-heartbeat:before {
    content: "\f21e"
}

.la-helicopter:before {
    content: "\f533"
}

.la-highlighter:before {
    content: "\f591"
}

.la-hiking:before {
    content: "\f6ec"
}

.la-hippo:before {
    content: "\f6ed"
}

.la-hips:before {
    content: "\f452"
}

.la-hire-a-helper:before {
    content: "\f3b0"
}

.la-history:before {
    content: "\f1da"
}

.la-hockey-puck:before {
    content: "\f453"
}

.la-holly-berry:before {
    content: "\f7aa"
}

.la-home:before {
    content: "\f015"
}

.la-hooli:before {
    content: "\f427"
}

.la-hornbill:before {
    content: "\f592"
}

.la-horse:before {
    content: "\f6f0"
}

.la-horse-head:before {
    content: "\f7ab"
}

.la-hospital:before {
    content: "\f0f8"
}

.la-hospital-alt:before {
    content: "\f47d"
}

.la-hospital-symbol:before {
    content: "\f47e"
}

.la-hot-tub:before {
    content: "\f593"
}

.la-hotdog:before {
    content: "\f80f"
}

.la-hotel:before {
    content: "\f594"
}

.la-hotjar:before {
    content: "\f3b1"
}

.la-hourglass:before {
    content: "\f254"
}

.la-hourglass-end:before {
    content: "\f253"
}

.la-hourglass-half:before {
    content: "\f252"
}

.la-hourglass-start:before {
    content: "\f251"
}

.la-house-damage:before {
    content: "\f6f1"
}

.la-houzz:before {
    content: "\f27c"
}

.la-hryvnia:before {
    content: "\f6f2"
}

.la-html5:before {
    content: "\f13b"
}

.la-hubspot:before {
    content: "\f3b2"
}

.la-i-cursor:before {
    content: "\f246"
}

.la-ice-cream:before {
    content: "\f810"
}

.la-icicles:before {
    content: "\f7ad"
}

.la-icons:before {
    content: "\f86d"
}

.la-id-badge:before {
    content: "\f2c1"
}

.la-id-card:before {
    content: "\f2c2"
}

.la-id-card-alt:before {
    content: "\f47f"
}

.la-igloo:before {
    content: "\f7ae"
}

.la-image:before {
    content: "\f03e"
}

.la-images:before {
    content: "\f302"
}

.la-imdb:before {
    content: "\f2d8"
}

.la-inbox:before {
    content: "\f01c"
}

.la-indent:before {
    content: "\f03c"
}

.la-industry:before {
    content: "\f275"
}

.la-infinity:before {
    content: "\f534"
}

.la-info:before {
    content: "\f129"
}

.la-info-circle:before {
    content: "\f05a"
}

.la-instagram:before {
    content: "\f16d"
}

.la-intercom:before {
    content: "\f7af"
}

.la-internet-explorer:before {
    content: "\f26b"
}

.la-invision:before {
    content: "\f7b0"
}

.la-ioxhost:before {
    content: "\f208"
}

.la-italic:before {
    content: "\f033"
}

.la-itch-io:before {
    content: "\f83a"
}

.la-itunes:before {
    content: "\f3b4"
}

.la-itunes-note:before {
    content: "\f3b5"
}

.la-java:before {
    content: "\f4e4"
}

.la-jedi:before {
    content: "\f669"
}

.la-jedi-order:before {
    content: "\f50e"
}

.la-jenkins:before {
    content: "\f3b6"
}

.la-jira:before {
    content: "\f7b1"
}

.la-joget:before {
    content: "\f3b7"
}

.la-joint:before {
    content: "\f595"
}

.la-joomla:before {
    content: "\f1aa"
}

.la-journal-whills:before {
    content: "\f66a"
}

.la-js:before {
    content: "\f3b8"
}

.la-js-square:before {
    content: "\f3b9"
}

.la-jsfiddle:before {
    content: "\f1cc"
}

.la-kaaba:before {
    content: "\f66b"
}

.la-kaggle:before {
    content: "\f5fa"
}

.la-key:before {
    content: "\f084"
}

.la-keybase:before {
    content: "\f4f5"
}

.la-keyboard:before {
    content: "\f11c"
}

.la-keycdn:before {
    content: "\f3ba"
}

.la-khanda:before {
    content: "\f66d"
}

.la-kickstarter:before {
    content: "\f3bb"
}

.la-kickstarter-k:before {
    content: "\f3bc"
}

.la-kiss:before {
    content: "\f596"
}

.la-kiss-beam:before {
    content: "\f597"
}

.la-kiss-wink-heart:before {
    content: "\f598"
}

.la-kiwi-bird:before {
    content: "\f535"
}

.la-korvue:before {
    content: "\f42f"
}

.la-landmark:before {
    content: "\f66f"
}

.la-language:before {
    content: "\f1ab"
}

.la-laptop:before {
    content: "\f109"
}

.la-laptop-code:before {
    content: "\f5fc"
}

.la-laptop-medical:before {
    content: "\f812"
}

.la-laravel:before {
    content: "\f3bd"
}

.la-lastfm:before {
    content: "\f202"
}

.la-lastfm-square:before {
    content: "\f203"
}

.la-laugh:before {
    content: "\f599"
}

.la-laugh-beam:before {
    content: "\f59a"
}

.la-laugh-squint:before {
    content: "\f59b"
}

.la-laugh-wink:before {
    content: "\f59c"
}

.la-layer-group:before {
    content: "\f5fd"
}

.la-leaf:before {
    content: "\f06c"
}

.la-leanpub:before {
    content: "\f212"
}

.la-lemon:before {
    content: "\f094"
}

.la-less:before {
    content: "\f41d"
}

.la-less-than:before {
    content: "\f536"
}

.la-less-than-equal:before {
    content: "\f537"
}

.la-level-down-alt:before {
    content: "\f3be"
}

.la-level-up-alt:before {
    content: "\f3bf"
}

.la-life-ring:before {
    content: "\f1cd"
}

.la-lightbulb:before {
    content: "\f0eb"
}

.la-line:before {
    content: "\f3c0"
}

.la-link:before {
    content: "\f0c1"
}

.la-linkedin:before {
    content: "\f08c"
}

.la-linkedin-in:before {
    content: "\f0e1"
}

.la-linode:before {
    content: "\f2b8"
}

.la-linux:before {
    content: "\f17c"
}

.la-lira-sign:before {
    content: "\f195"
}

.la-list:before {
    content: "\f03a"
}

.la-list-alt:before {
    content: "\f022"
}

.la-list-ol:before {
    content: "\f0cb"
}

.la-list-ul:before {
    content: "\f0ca"
}

.la-location-arrow:before {
    content: "\f124"
}

.la-lock:before {
    content: "\f023"
}

.la-lock-open:before {
    content: "\f3c1"
}

.la-long-arrow-alt-down:before {
    content: "\f309"
}

.la-long-arrow-alt-left:before {
    content: "\f30a"
}

.la-long-arrow-alt-right:before {
    content: "\f30b"
}

.la-long-arrow-alt-up:before {
    content: "\f30c"
}

.la-low-vision:before {
    content: "\f2a8"
}

.la-luggage-cart:before {
    content: "\f59d"
}

.la-lyft:before {
    content: "\f3c3"
}

.la-magento:before {
    content: "\f3c4"
}

.la-magic:before {
    content: "\f0d0"
}

.la-magnet:before {
    content: "\f076"
}

.la-mail-bulk:before {
    content: "\f674"
}

.la-mailchimp:before {
    content: "\f59e"
}

.la-male:before {
    content: "\f183"
}

.la-mandalorian:before {
    content: "\f50f"
}

.la-map:before {
    content: "\f279"
}

.la-map-marked:before {
    content: "\f59f"
}

.la-map-marked-alt:before {
    content: "\f5a0"
}

.la-map-marker:before {
    content: "\f041"
}

.la-map-marker-alt:before {
    content: "\f3c5"
}

.la-map-pin:before {
    content: "\f276"
}

.la-map-signs:before {
    content: "\f277"
}

.la-markdown:before {
    content: "\f60f"
}

.la-marker:before {
    content: "\f5a1"
}

.la-mars:before {
    content: "\f222"
}

.la-mars-double:before {
    content: "\f227"
}

.la-mars-stroke:before {
    content: "\f229"
}

.la-mars-stroke-h:before {
    content: "\f22b"
}

.la-mars-stroke-v:before {
    content: "\f22a"
}

.la-mask:before {
    content: "\f6fa"
}

.la-mastodon:before {
    content: "\f4f6"
}

.la-maxcdn:before {
    content: "\f136"
}

.la-mdb:before {
    content: "\f8ca"
}

.la-medal:before {
    content: "\f5a2"
}

.la-medapps:before {
    content: "\f3c6"
}

.la-medium:before {
    content: "\f23a"
}

.la-medium-m:before {
    content: "\f3c7"
}

.la-medkit:before {
    content: "\f0fa"
}

.la-medrt:before {
    content: "\f3c8"
}

.la-meetup:before {
    content: "\f2e0"
}

.la-megaport:before {
    content: "\f5a3"
}

.la-meh:before {
    content: "\f11a"
}

.la-meh-blank:before {
    content: "\f5a4"
}

.la-meh-rolling-eyes:before {
    content: "\f5a5"
}

.la-memory:before {
    content: "\f538"
}

.la-mendeley:before {
    content: "\f7b3"
}

.la-menorah:before {
    content: "\f676"
}

.la-mercury:before {
    content: "\f223"
}

.la-meteor:before {
    content: "\f753"
}

.la-microchip:before {
    content: "\f2db"
}

.la-microphone:before {
    content: "\f130"
}

.la-microphone-alt:before {
    content: "\f3c9"
}

.la-microphone-alt-slash:before {
    content: "\f539"
}

.la-microphone-slash:before {
    content: "\f131"
}

.la-microscope:before {
    content: "\f610"
}

.la-microsoft:before {
    content: "\f3ca"
}

.la-minus:before {
    content: "\f068"
}

.la-minus-circle:before {
    content: "\f056"
}

.la-minus-square:before {
    content: "\f146"
}

.la-mitten:before {
    content: "\f7b5"
}

.la-mix:before {
    content: "\f3cb"
}

.la-mixcloud:before {
    content: "\f289"
}

.la-mizuni:before {
    content: "\f3cc"
}

.la-mobile:before {
    content: "\f10b"
}

.la-mobile-alt:before {
    content: "\f3cd"
}

.la-modx:before {
    content: "\f285"
}

.la-monero:before {
    content: "\f3d0"
}

.la-money-bill:before {
    content: "\f0d6"
}

.la-money-bill-alt:before {
    content: "\f3d1"
}

.la-money-bill-wave:before {
    content: "\f53a"
}

.la-money-bill-wave-alt:before {
    content: "\f53b"
}

.la-money-check:before {
    content: "\f53c"
}

.la-money-check-alt:before {
    content: "\f53d"
}

.la-monument:before {
    content: "\f5a6"
}

.la-moon:before {
    content: "\f186"
}

.la-mortar-pestle:before {
    content: "\f5a7"
}

.la-mosque:before {
    content: "\f678"
}

.la-motorcycle:before {
    content: "\f21c"
}

.la-mountain:before {
    content: "\f6fc"
}

.la-mouse:before {
    content: "\f8cc"
}

.la-mouse-pointer:before {
    content: "\f245"
}

.la-mug-hot:before {
    content: "\f7b6"
}

.la-music:before {
    content: "\f001"
}

.la-napster:before {
    content: "\f3d2"
}

.la-neos:before {
    content: "\f612"
}

.la-network-wired:before {
    content: "\f6ff"
}

.la-neuter:before {
    content: "\f22c"
}

.la-newspaper:before {
    content: "\f1ea"
}

.la-nimblr:before {
    content: "\f5a8"
}

.la-node:before {
    content: "\f419"
}

.la-node-js:before {
    content: "\f3d3"
}

.la-not-equal:before {
    content: "\f53e"
}

.la-notes-medical:before {
    content: "\f481"
}

.la-npm:before {
    content: "\f3d4"
}

.la-ns8:before {
    content: "\f3d5"
}

.la-nutritionix:before {
    content: "\f3d6"
}

.la-object-group:before {
    content: "\f247"
}

.la-object-ungroup:before {
    content: "\f248"
}

.la-odnoklassniki:before {
    content: "\f263"
}

.la-odnoklassniki-square:before {
    content: "\f264"
}

.la-oil-can:before {
    content: "\f613"
}

.la-old-republic:before {
    content: "\f510"
}

.la-om:before {
    content: "\f679"
}

.la-opencart:before {
    content: "\f23d"
}

.la-openid:before {
    content: "\f19b"
}

.la-opera:before {
    content: "\f26a"
}

.la-optin-monster:before {
    content: "\f23c"
}

.la-orcid:before {
    content: "\f8d2"
}

.la-osi:before {
    content: "\f41a"
}

.la-otter:before {
    content: "\f700"
}

.la-outdent:before {
    content: "\f03b"
}

.la-page4:before {
    content: "\f3d7"
}

.la-pagelines:before {
    content: "\f18c"
}

.la-pager:before {
    content: "\f815"
}

.la-paint-brush:before {
    content: "\f1fc"
}

.la-paint-roller:before {
    content: "\f5aa"
}

.la-palette:before {
    content: "\f53f"
}

.la-palfed:before {
    content: "\f3d8"
}

.la-pallet:before {
    content: "\f482"
}

.la-paper-plane:before {
    content: "\f1d8"
}

.la-paperclip:before {
    content: "\f0c6"
}

.la-parachute-box:before {
    content: "\f4cd"
}

.la-paragraph:before {
    content: "\f1dd"
}

.la-parking:before {
    content: "\f540"
}

.la-passport:before {
    content: "\f5ab"
}

.la-pastafarianism:before {
    content: "\f67b"
}

.la-paste:before {
    content: "\f0ea"
}

.la-patreon:before {
    content: "\f3d9"
}

.la-pause:before {
    content: "\f04c"
}

.la-pause-circle:before {
    content: "\f28b"
}

.la-paw:before {
    content: "\f1b0"
}

.la-paypal:before {
    content: "\f1ed"
}

.la-peace:before {
    content: "\f67c"
}

.la-pen:before {
    content: "\f304"
}

.la-pen-alt:before {
    content: "\f305"
}

.la-pen-fancy:before {
    content: "\f5ac"
}

.la-pen-nib:before {
    content: "\f5ad"
}

.la-pen-square:before {
    content: "\f14b"
}

.la-pencil-alt:before {
    content: "\f303"
}

.la-pencil-ruler:before {
    content: "\f5ae"
}

.la-penny-arcade:before {
    content: "\f704"
}

.la-people-carry:before {
    content: "\f4ce"
}

.la-pepper-hot:before {
    content: "\f816"
}

.la-percent:before {
    content: "\f295"
}

.la-percentage:before {
    content: "\f541"
}

.la-periscope:before {
    content: "\f3da"
}

.la-person-booth:before {
    content: "\f756"
}

.la-phabricator:before {
    content: "\f3db"
}

.la-phoenix-framework:before {
    content: "\f3dc"
}

.la-phoenix-squadron:before {
    content: "\f511"
}

.la-phone:before {
    content: "\f095"
}

.la-phone-alt:before {
    content: "\f879"
}

.la-phone-slash:before {
    content: "\f3dd"
}

.la-phone-square:before {
    content: "\f098"
}

.la-phone-square-alt:before {
    content: "\f87b"
}

.la-phone-volume:before {
    content: "\f2a0"
}

.la-photo-video:before {
    content: "\f87c"
}

.la-php:before {
    content: "\f457"
}

.la-pied-piper:before {
    content: "\f2ae"
}

.la-pied-piper-alt:before {
    content: "\f1a8"
}

.la-pied-piper-hat:before {
    content: "\f4e5"
}

.la-pied-piper-pp:before {
    content: "\f1a7"
}

.la-piggy-bank:before {
    content: "\f4d3"
}

.la-pills:before {
    content: "\f484"
}

.la-pinterest:before {
    content: "\f0d2"
}

.la-pinterest-p:before {
    content: "\f231"
}

.la-pinterest-square:before {
    content: "\f0d3"
}

.la-pizza-slice:before {
    content: "\f818"
}

.la-place-of-worship:before {
    content: "\f67f"
}

.la-plane:before {
    content: "\f072"
}

.la-plane-arrival:before {
    content: "\f5af"
}

.la-plane-departure:before {
    content: "\f5b0"
}

.la-play:before {
    content: "\f04b"
}

.la-play-circle:before {
    content: "\f144"
}

.la-playstation:before {
    content: "\f3df"
}

.la-plug:before {
    content: "\f1e6"
}

.la-plus:before {
    content: "\f067"
}

.la-plus-circle:before {
    content: "\f055"
}

.la-plus-square:before {
    content: "\f0fe"
}

.la-podcast:before {
    content: "\f2ce"
}

.la-poll:before {
    content: "\f681"
}

.la-poll-h:before {
    content: "\f682"
}

.la-poo:before {
    content: "\f2fe"
}

.la-poo-storm:before {
    content: "\f75a"
}

.la-poop:before {
    content: "\f619"
}

.la-portrait:before {
    content: "\f3e0"
}

.la-pound-sign:before {
    content: "\f154"
}

.la-power-off:before {
    content: "\f011"
}

.la-pray:before {
    content: "\f683"
}

.la-praying-hands:before {
    content: "\f684"
}

.la-prescription:before {
    content: "\f5b1"
}

.la-prescription-bottle:before {
    content: "\f485"
}

.la-prescription-bottle-alt:before {
    content: "\f486"
}

.la-print:before {
    content: "\f02f"
}

.la-procedures:before {
    content: "\f487"
}

.la-product-hunt:before {
    content: "\f288"
}

.la-project-diagram:before {
    content: "\f542"
}

.la-pushed:before {
    content: "\f3e1"
}

.la-puzzle-piece:before {
    content: "\f12e"
}

.la-python:before {
    content: "\f3e2"
}

.la-qq:before {
    content: "\f1d6"
}

.la-qrcode:before {
    content: "\f029"
}

.la-question:before {
    content: "\f128"
}

.la-question-circle:before {
    content: "\f059"
}

.la-quidditch:before {
    content: "\f458"
}

.la-quinscape:before {
    content: "\f459"
}

.la-quora:before {
    content: "\f2c4"
}

.la-quote-left:before {
    content: "\f10d"
}

.la-quote-right:before {
    content: "\f10e"
}

.la-quran:before {
    content: "\f687"
}

.la-r-project:before {
    content: "\f4f7"
}

.la-radiation:before {
    content: "\f7b9"
}

.la-radiation-alt:before {
    content: "\f7ba"
}

.la-rainbow:before {
    content: "\f75b"
}

.la-random:before {
    content: "\f074"
}

.la-raspberry-pi:before {
    content: "\f7bb"
}

.la-ravelry:before {
    content: "\f2d9"
}

.la-react:before {
    content: "\f41b"
}

.la-reacteurope:before {
    content: "\f75d"
}

.la-readme:before {
    content: "\f4d5"
}

.la-rebel:before {
    content: "\f1d0"
}

.la-receipt:before {
    content: "\f543"
}

.la-record-vinyl:before {
    content: "\f8d9"
}

.la-recycle:before {
    content: "\f1b8"
}

.la-red-river:before {
    content: "\f3e3"
}

.la-reddit:before {
    content: "\f1a1"
}

.la-reddit-alien:before {
    content: "\f281"
}

.la-reddit-square:before {
    content: "\f1a2"
}

.la-redhat:before {
    content: "\f7bc"
}

.la-redo:before {
    content: "\f01e"
}

.la-redo-alt:before {
    content: "\f2f9"
}

.la-registered:before {
    content: "\f25d"
}

.la-remove-format:before {
    content: "\f87d"
}

.la-renren:before {
    content: "\f18b"
}

.la-reply:before {
    content: "\f3e5"
}

.la-reply-all:before {
    content: "\f122"
}

.la-replyd:before {
    content: "\f3e6"
}

.la-republican:before {
    content: "\f75e"
}

.la-researchgate:before {
    content: "\f4f8"
}

.la-resolving:before {
    content: "\f3e7"
}

.la-restroom:before {
    content: "\f7bd"
}

.la-retweet:before {
    content: "\f079"
}

.la-rev:before {
    content: "\f5b2"
}

.la-ribbon:before {
    content: "\f4d6"
}

.la-ring:before {
    content: "\f70b"
}

.la-road:before {
    content: "\f018"
}

.la-robot:before {
    content: "\f544"
}

.la-rocket:before {
    content: "\f135"
}

.la-rocketchat:before {
    content: "\f3e8"
}

.la-rockrms:before {
    content: "\f3e9"
}

.la-route:before {
    content: "\f4d7"
}

.la-rss:before {
    content: "\f09e"
}

.la-rss-square:before {
    content: "\f143"
}

.la-ruble-sign:before {
    content: "\f158"
}

.la-ruler:before {
    content: "\f545"
}

.la-ruler-combined:before {
    content: "\f546"
}

.la-ruler-horizontal:before {
    content: "\f547"
}

.la-ruler-vertical:before {
    content: "\f548"
}

.la-running:before {
    content: "\f70c"
}

.la-rupee-sign:before {
    content: "\f156"
}

.la-sad-cry:before {
    content: "\f5b3"
}

.la-sad-tear:before {
    content: "\f5b4"
}

.la-safari:before {
    content: "\f267"
}

.la-salesforce:before {
    content: "\f83b"
}

.la-sass:before {
    content: "\f41e"
}

.la-satellite:before {
    content: "\f7bf"
}

.la-satellite-dish:before {
    content: "\f7c0"
}

.la-save:before {
    content: "\f0c7"
}

.la-schlix:before {
    content: "\f3ea"
}

.la-school:before {
    content: "\f549"
}

.la-screwdriver:before {
    content: "\f54a"
}

.la-scribd:before {
    content: "\f28a"
}

.la-scroll:before {
    content: "\f70e"
}

.la-sd-card:before {
    content: "\f7c2"
}

.la-search:before {
    content: "\f002"
}

.la-search-dollar:before {
    content: "\f688"
}

.la-search-location:before {
    content: "\f689"
}

.la-search-minus:before {
    content: "\f010"
}

.la-search-plus:before {
    content: "\f00e"
}

.la-searchengin:before {
    content: "\f3eb"
}

.la-seedling:before {
    content: "\f4d8"
}

.la-sellcast:before {
    content: "\f2da"
}

.la-sellsy:before {
    content: "\f213"
}

.la-server:before {
    content: "\f233"
}

.la-servicestack:before {
    content: "\f3ec"
}

.la-shapes:before {
    content: "\f61f"
}

.la-share:before {
    content: "\f064"
}

.la-share-alt:before {
    content: "\f1e0"
}

.la-share-alt-square:before {
    content: "\f1e1"
}

.la-share-square:before {
    content: "\f14d"
}

.la-shekel-sign:before {
    content: "\f20b"
}

.la-shield-alt:before {
    content: "\f3ed"
}

.la-ship:before {
    content: "\f21a"
}

.la-shipping-fast:before {
    content: "\f48b"
}

.la-shirtsinbulk:before {
    content: "\f214"
}

.la-shoe-prints:before {
    content: "\f54b"
}

.la-shopping-bag:before {
    content: "\f290"
}

.la-shopping-basket:before {
    content: "\f291"
}

.la-shopping-cart:before {
    content: "\f07a"
}

.la-shopware:before {
    content: "\f5b5"
}

.la-shower:before {
    content: "\f2cc"
}

.la-shuttle-van:before {
    content: "\f5b6"
}

.la-sign:before {
    content: "\f4d9"
}

.la-sign-in-alt:before {
    content: "\f2f6"
}

.la-sign-language:before {
    content: "\f2a7"
}

.la-sign-out-alt:before {
    content: "\f2f5"
}

.la-signal:before {
    content: "\f012"
}

.la-signature:before {
    content: "\f5b7"
}

.la-sim-card:before {
    content: "\f7c4"
}

.la-simplybuilt:before {
    content: "\f215"
}

.la-sistrix:before {
    content: "\f3ee"
}

.la-sitemap:before {
    content: "\f0e8"
}

.la-sith:before {
    content: "\f512"
}

.la-skating:before {
    content: "\f7c5"
}

.la-sketch:before {
    content: "\f7c6"
}

.la-skiing:before {
    content: "\f7c9"
}

.la-skiing-nordic:before {
    content: "\f7ca"
}

.la-skull:before {
    content: "\f54c"
}

.la-skull-crossbones:before {
    content: "\f714"
}

.la-skyatlas:before {
    content: "\f216"
}

.la-skype:before {
    content: "\f17e"
}

.la-slack:before {
    content: "\f198"
}

.la-slack-hash:before {
    content: "\f3ef"
}

.la-slash:before {
    content: "\f715"
}

.la-sleigh:before {
    content: "\f7cc"
}

.la-sliders-h:before {
    content: "\f1de"
}

.la-slideshare:before {
    content: "\f1e7"
}

.la-smile:before {
    content: "\f118"
}

.la-smile-beam:before {
    content: "\f5b8"
}

.la-smile-wink:before {
    content: "\f4da"
}

.la-smog:before {
    content: "\f75f"
}

.la-smoking:before {
    content: "\f48d"
}

.la-smoking-ban:before {
    content: "\f54d"
}

.la-sms:before {
    content: "\f7cd"
}

.la-snapchat:before {
    content: "\f2ab"
}

.la-snapchat-ghost:before {
    content: "\f2ac"
}

.la-snapchat-square:before {
    content: "\f2ad"
}

.la-snowboarding:before {
    content: "\f7ce"
}

.la-snowflake:before {
    content: "\f2dc"
}

.la-snowman:before {
    content: "\f7d0"
}

.la-snowplow:before {
    content: "\f7d2"
}

.la-socks:before {
    content: "\f696"
}

.la-solar-panel:before {
    content: "\f5ba"
}

.la-sort:before {
    content: "\f0dc"
}

.la-sort-alpha-down:before {
    content: "\f15d"
}

.la-sort-alpha-down-alt:before {
    content: "\f881"
}

.la-sort-alpha-up:before {
    content: "\f15e"
}

.la-sort-alpha-up-alt:before {
    content: "\f882"
}

.la-sort-amount-down:before {
    content: "\f160"
}

.la-sort-amount-down-alt:before {
    content: "\f884"
}

.la-sort-amount-up:before {
    content: "\f161"
}

.la-sort-amount-up-alt:before {
    content: "\f885"
}

.la-sort-down:before {
    content: "\f0dd"
}

.la-sort-numeric-down:before {
    content: "\f162"
}

.la-sort-numeric-down-alt:before {
    content: "\f886"
}

.la-sort-numeric-up:before {
    content: "\f163"
}

.la-sort-numeric-up-alt:before {
    content: "\f887"
}

.la-sort-up:before {
    content: "\f0de"
}

.la-soundcloud:before {
    content: "\f1be"
}

.la-sourcetree:before {
    content: "\f7d3"
}

.la-spa:before {
    content: "\f5bb"
}

.la-space-shuttle:before {
    content: "\f197"
}

.la-speakap:before {
    content: "\f3f3"
}

.la-speaker-deck:before {
    content: "\f83c"
}

.la-spell-check:before {
    content: "\f891"
}

.la-spider:before {
    content: "\f717"
}

.la-spinner:before {
    content: "\f110"
}

.la-splotch:before {
    content: "\f5bc"
}

.la-spotify:before {
    content: "\f1bc"
}

.la-spray-can:before {
    content: "\f5bd"
}

.la-square:before {
    content: "\f0c8"
}

.la-square-full:before {
    content: "\f45c"
}

.la-square-root-alt:before {
    content: "\f698"
}

.la-squarespace:before {
    content: "\f5be"
}

.la-stack-exchange:before {
    content: "\f18d"
}

.la-stack-overflow:before {
    content: "\f16c"
}

.la-stackpath:before {
    content: "\f842"
}

.la-stamp:before {
    content: "\f5bf"
}

.la-star:before {
    content: "\f005"
}

.la-star-and-crescent:before {
    content: "\f699"
}

.la-star-half:before {
    content: "\f089"
}

.la-star-half-alt:before {
    content: "\f5c0"
}

.la-star-of-david:before {
    content: "\f69a"
}

.la-star-of-life:before {
    content: "\f621"
}

.la-staylinked:before {
    content: "\f3f5"
}

.la-steam:before {
    content: "\f1b6"
}

.la-steam-square:before {
    content: "\f1b7"
}

.la-steam-symbol:before {
    content: "\f3f6"
}

.la-step-backward:before {
    content: "\f048"
}

.la-step-forward:before {
    content: "\f051"
}

.la-stethoscope:before {
    content: "\f0f1"
}

.la-sticker-mule:before {
    content: "\f3f7"
}

.la-sticky-note:before {
    content: "\f249"
}

.la-stop:before {
    content: "\f04d"
}

.la-stop-circle:before {
    content: "\f28d"
}

.la-stopwatch:before {
    content: "\f2f2"
}

.la-store:before {
    content: "\f54e"
}

.la-store-alt:before {
    content: "\f54f"
}

.la-strava:before {
    content: "\f428"
}

.la-stream:before {
    content: "\f550"
}

.la-street-view:before {
    content: "\f21d"
}

.la-strikethrough:before {
    content: "\f0cc"
}

.la-stripe:before {
    content: "\f429"
}

.la-stripe-s:before {
    content: "\f42a"
}

.la-stroopwafel:before {
    content: "\f551"
}

.la-studiovinari:before {
    content: "\f3f8"
}

.la-stumbleupon:before {
    content: "\f1a4"
}

.la-stumbleupon-circle:before {
    content: "\f1a3"
}

.la-subscript:before {
    content: "\f12c"
}

.la-subway:before {
    content: "\f239"
}

.la-suitcase:before {
    content: "\f0f2"
}

.la-suitcase-rolling:before {
    content: "\f5c1"
}

.la-sun:before {
    content: "\f185"
}

.la-superpowers:before {
    content: "\f2dd"
}

.la-superscript:before {
    content: "\f12b"
}

.la-supple:before {
    content: "\f3f9"
}

.la-surprise:before {
    content: "\f5c2"
}

.la-suse:before {
    content: "\f7d6"
}

.la-swatchbook:before {
    content: "\f5c3"
}

.la-swift:before {
    content: "\f8e1"
}

.la-swimmer:before {
    content: "\f5c4"
}

.la-swimming-pool:before {
    content: "\f5c5"
}

.la-symfony:before {
    content: "\f83d"
}

.la-synagogue:before {
    content: "\f69b"
}

.la-sync:before {
    content: "\f021"
}

.la-sync-alt:before {
    content: "\f2f1"
}

.la-syringe:before {
    content: "\f48e"
}

.la-table:before {
    content: "\f0ce"
}

.la-table-tennis:before {
    content: "\f45d"
}

.la-tablet:before {
    content: "\f10a"
}

.la-tablet-alt:before {
    content: "\f3fa"
}

.la-tablets:before {
    content: "\f490"
}

.la-tachometer-alt:before {
    content: "\f3fd"
}

.la-tag:before {
    content: "\f02b"
}

.la-tags:before {
    content: "\f02c"
}

.la-tape:before {
    content: "\f4db"
}

.la-tasks:before {
    content: "\f0ae"
}

.la-taxi:before {
    content: "\f1ba"
}

.la-teamspeak:before {
    content: "\f4f9"
}

.la-teeth:before {
    content: "\f62e"
}

.la-teeth-open:before {
    content: "\f62f"
}

.la-telegram:before {
    content: "\f2c6"
}

.la-telegram-plane:before {
    content: "\f3fe"
}

.la-temperature-high:before {
    content: "\f769"
}

.la-temperature-low:before {
    content: "\f76b"
}

.la-tencent-weibo:before {
    content: "\f1d5"
}

.la-tenge:before {
    content: "\f7d7"
}

.la-terminal:before {
    content: "\f120"
}

.la-text-height:before {
    content: "\f034"
}

.la-text-width:before {
    content: "\f035"
}

.la-th:before {
    content: "\f00a"
}

.la-th-large:before {
    content: "\f009"
}

.la-th-list:before {
    content: "\f00b"
}

.la-the-red-yeti:before {
    content: "\f69d"
}

.la-theater-masks:before {
    content: "\f630"
}

.la-themeco:before {
    content: "\f5c6"
}

.la-themeisle:before {
    content: "\f2b2"
}

.la-thermometer:before {
    content: "\f491"
}

.la-thermometer-empty:before {
    content: "\f2cb"
}

.la-thermometer-full:before {
    content: "\f2c7"
}

.la-thermometer-half:before {
    content: "\f2c9"
}

.la-thermometer-quarter:before {
    content: "\f2ca"
}

.la-thermometer-three-quarters:before {
    content: "\f2c8"
}

.la-think-peaks:before {
    content: "\f731"
}

.la-thumbs-down:before {
    content: "\f165"
}

.la-thumbs-up:before {
    content: "\f164"
}

.la-thumbtack:before {
    content: "\f08d"
}

.la-ticket-alt:before {
    content: "\f3ff"
}

.la-times:before {
    content: "\f00d"
}

.la-times-circle:before {
    content: "\f057"
}

.la-tint:before {
    content: "\f043"
}

.la-tint-slash:before {
    content: "\f5c7"
}

.la-tired:before {
    content: "\f5c8"
}

.la-toggle-off:before {
    content: "\f204"
}

.la-toggle-on:before {
    content: "\f205"
}

.la-toilet:before {
    content: "\f7d8"
}

.la-toilet-paper:before {
    content: "\f71e"
}

.la-toolbox:before {
    content: "\f552"
}

.la-tools:before {
    content: "\f7d9"
}

.la-tooth:before {
    content: "\f5c9"
}

.la-torah:before {
    content: "\f6a0"
}

.la-torii-gate:before {
    content: "\f6a1"
}

.la-tractor:before {
    content: "\f722"
}

.la-trade-federation:before {
    content: "\f513"
}

.la-trademark:before {
    content: "\f25c"
}

.la-traffic-light:before {
    content: "\f637"
}

.la-train:before {
    content: "\f238"
}

.la-tram:before {
    content: "\f7da"
}

.la-transgender:before {
    content: "\f224"
}

.la-transgender-alt:before {
    content: "\f225"
}

.la-trash:before {
    content: "\f1f8"
}

.la-trash-alt:before {
    content: "\f2ed"
}

.la-trash-restore:before {
    content: "\f829"
}

.la-trash-restore-alt:before {
    content: "\f82a"
}

.la-tree:before {
    content: "\f1bb"
}

.la-trello:before {
    content: "\f181"
}

.la-tripadvisor:before {
    content: "\f262"
}

.la-trophy:before {
    content: "\f091"
}

.la-truck:before {
    content: "\f0d1"
}

.la-truck-loading:before {
    content: "\f4de"
}

.la-truck-monster:before {
    content: "\f63b"
}

.la-truck-moving:before {
    content: "\f4df"
}

.la-truck-pickup:before {
    content: "\f63c"
}

.la-tshirt:before {
    content: "\f553"
}

.la-tty:before {
    content: "\f1e4"
}

.la-tumblr:before {
    content: "\f173"
}

.la-tumblr-square:before {
    content: "\f174"
}

.la-tv:before {
    content: "\f26c"
}

.la-twitch:before {
    content: "\f1e8"
}

.la-twitter:before {
    content: "\f099"
}

.la-twitter-square:before {
    content: "\f081"
}

.la-typo3:before {
    content: "\f42b"
}

.la-uber:before {
    content: "\f402"
}

.la-ubuntu:before {
    content: "\f7df"
}

.la-uikit:before {
    content: "\f403"
}

.la-umbraco:before {
    content: "\f8e8"
}

.la-umbrella:before {
    content: "\f0e9"
}

.la-umbrella-beach:before {
    content: "\f5ca"
}

.la-underline:before {
    content: "\f0cd"
}

.la-undo:before {
    content: "\f0e2"
}

.la-undo-alt:before {
    content: "\f2ea"
}

.la-uniregistry:before {
    content: "\f404"
}

.la-universal-access:before {
    content: "\f29a"
}

.la-university:before {
    content: "\f19c"
}

.la-unlink:before {
    content: "\f127"
}

.la-unlock:before {
    content: "\f09c"
}

.la-unlock-alt:before {
    content: "\f13e"
}

.la-untappd:before {
    content: "\f405"
}

.la-upload:before {
    content: "\f093"
}

.la-ups:before {
    content: "\f7e0"
}

.la-usb:before {
    content: "\f287"
}

.la-user:before {
    content: "\f007"
}

.la-user-alt:before {
    content: "\f406"
}

.la-user-alt-slash:before {
    content: "\f4fa"
}

.la-user-astronaut:before {
    content: "\f4fb"
}

.la-user-check:before {
    content: "\f4fc"
}

.la-user-circle:before {
    content: "\f2bd"
}

.la-user-clock:before {
    content: "\f4fd"
}

.la-user-cog:before {
    content: "\f4fe"
}

.la-user-edit:before {
    content: "\f4ff"
}

.la-user-friends:before {
    content: "\f500"
}

.la-user-graduate:before {
    content: "\f501"
}

.la-user-injured:before {
    content: "\f728"
}

.la-user-lock:before {
    content: "\f502"
}

.la-user-md:before {
    content: "\f0f0"
}

.la-user-minus:before {
    content: "\f503"
}

.la-user-ninja:before {
    content: "\f504"
}

.la-user-nurse:before {
    content: "\f82f"
}

.la-user-plus:before {
    content: "\f234"
}

.la-user-secret:before {
    content: "\f21b"
}

.la-user-shield:before {
    content: "\f505"
}

.la-user-slash:before {
    content: "\f506"
}

.la-user-tag:before {
    content: "\f507"
}

.la-user-tie:before {
    content: "\f508"
}

.la-user-times:before {
    content: "\f235"
}

.la-users:before {
    content: "\f0c0"
}

.la-users-cog:before {
    content: "\f509"
}

.la-usps:before {
    content: "\f7e1"
}

.la-ussunnah:before {
    content: "\f407"
}

.la-utensil-spoon:before {
    content: "\f2e5"
}

.la-utensils:before {
    content: "\f2e7"
}

.la-vaadin:before {
    content: "\f408"
}

.la-vector-square:before {
    content: "\f5cb"
}

.la-venus:before {
    content: "\f221"
}

.la-venus-double:before {
    content: "\f226"
}

.la-venus-mars:before {
    content: "\f228"
}

.la-viacoin:before {
    content: "\f237"
}

.la-viadeo:before {
    content: "\f2a9"
}

.la-viadeo-square:before {
    content: "\f2aa"
}

.la-vial:before {
    content: "\f492"
}

.la-vials:before {
    content: "\f493"
}

.la-viber:before {
    content: "\f409"
}

.la-video:before {
    content: "\f03d"
}

.la-video-slash:before {
    content: "\f4e2"
}

.la-vihara:before {
    content: "\f6a7"
}

.la-vimeo:before {
    content: "\f40a"
}

.la-vimeo-square:before {
    content: "\f194"
}

.la-vimeo-v:before {
    content: "\f27d"
}

.la-vine:before {
    content: "\f1ca"
}

.la-vk:before {
    content: "\f189"
}

.la-vnv:before {
    content: "\f40b"
}

.la-voicemail:before {
    content: "\f897"
}

.la-volleyball-ball:before {
    content: "\f45f"
}

.la-volume-down:before {
    content: "\f027"
}

.la-volume-mute:before {
    content: "\f6a9"
}

.la-volume-off:before {
    content: "\f026"
}

.la-volume-up:before {
    content: "\f028"
}

.la-vote-yea:before {
    content: "\f772"
}

.la-vr-cardboard:before {
    content: "\f729"
}

.la-vuejs:before {
    content: "\f41f"
}

.la-walking:before {
    content: "\f554"
}

.la-wallet:before {
    content: "\f555"
}

.la-warehouse:before {
    content: "\f494"
}

.la-water:before {
    content: "\f773"
}

.la-wave-square:before {
    content: "\f83e"
}

.la-waze:before {
    content: "\f83f"
}

.la-weebly:before {
    content: "\f5cc"
}

.la-weibo:before {
    content: "\f18a"
}

.la-weight:before {
    content: "\f496"
}

.la-weight-hanging:before {
    content: "\f5cd"
}

.la-weixin:before {
    content: "\f1d7"
}

.la-whatsapp:before {
    content: "\f232"
}

.la-whatsapp-square:before {
    content: "\f40c"
}

.la-wheelchair:before {
    content: "\f193"
}

.la-whmcs:before {
    content: "\f40d"
}

.la-wifi:before {
    content: "\f1eb"
}

.la-wikipedia-w:before {
    content: "\f266"
}

.la-wind:before {
    content: "\f72e"
}

.la-window-close:before {
    content: "\f410"
}

.la-window-maximize:before {
    content: "\f2d0"
}

.la-window-minimize:before {
    content: "\f2d1"
}

.la-window-restore:before {
    content: "\f2d2"
}

.la-windows:before {
    content: "\f17a"
}

.la-wine-bottle:before {
    content: "\f72f"
}

.la-wine-glass:before {
    content: "\f4e3"
}

.la-wine-glass-alt:before {
    content: "\f5ce"
}

.la-wix:before {
    content: "\f5cf"
}

.la-wizards-of-the-coast:before {
    content: "\f730"
}

.la-wolf-pack-battalion:before {
    content: "\f514"
}

.la-won-sign:before {
    content: "\f159"
}

.la-wordpress:before {
    content: "\f19a"
}

.la-wordpress-simple:before {
    content: "\f411"
}

.la-wpbeginner:before {
    content: "\f297"
}

.la-wpexplorer:before {
    content: "\f2de"
}

.la-wpforms:before {
    content: "\f298"
}

.la-wpressr:before {
    content: "\f3e4"
}

.la-wrench:before {
    content: "\f0ad"
}

.la-x-ray:before {
    content: "\f497"
}

.la-xbox:before {
    content: "\f412"
}

.la-xing:before {
    content: "\f168"
}

.la-xing-square:before {
    content: "\f169"
}

.la-y-combinator:before {
    content: "\f23b"
}

.la-yahoo:before {
    content: "\f19e"
}

.la-yammer:before {
    content: "\f840"
}

.la-yandex:before {
    content: "\f413"
}

.la-yandex-international:before {
    content: "\f414"
}

.la-yarn:before {
    content: "\f7e3"
}

.la-yelp:before {
    content: "\f1e9"
}

.la-yen-sign:before {
    content: "\f157"
}

.la-yin-yang:before {
    content: "\f6ad"
}

.la-yoast:before {
    content: "\f2b1"
}

.la-youtube:before {
    content: "\f167"
}

.la-youtube-square:before {
    content: "\f431"
}

.la-zhihu:before {
    content: "\f63f"
}

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto
}

@font-face {
    font-family: "Line Awesome Brands";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url(../fonts/la-brands-400.eot);
    src: url(../fonts/la-brands-400d41d.eot?#iefix) format("embedded-opentype"), url(../fonts/la-brands-400.html) format("woff2"), url(../fonts/la-brands-400.woff) format("woff"), url(../fonts/la-brands-400.ttf) format("truetype"), url(../fonts/la-brands-400.svg#lineawesome) format("svg")
}

.lab {
    font-family: "Line Awesome Brands"
}

@font-face {
    font-family: "Line Awesome Free";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url(../fonts/la-regular-400.eot);
    src: url(../fonts/la-regular-400d41d.eot?#iefix) format("embedded-opentype"), url(../fonts/la-regular-400.html) format("woff2"), url(../fonts/la-regular-400.woff) format("woff"), url(../fonts/la-regular-400.ttf) format("truetype"), url(../fonts/la-regular-400.svg#lineawesome) format("svg")
}

.lar {
    font-family: "Line Awesome Free";
    font-weight: 400
}

@font-face {
    font-family: "Line Awesome Free";
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url(../fonts/la-solid-900.eot);
    src: url(../fonts/la-solid-900d41d.eot?#iefix) format("embedded-opentype"), url(../fonts/la-solid-900.html) format("woff2"), url(../fonts/la-solid-900.woff) format("woff"), url(../fonts/la-solid-900.ttf) format("truetype"), url(../fonts/la-solid-900.svg#lineawesome) format("svg")
}

.la,
.las {
    font-family: "Line Awesome Free";
    font-weight: 900
}

.la.la-glass:before {
    content: "\f000"
}

.la.la-meetup {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-star-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-star-o:before {
    content: "\f005"
}

.la.la-remove:before {
    content: "\f00d"
}

.la.la-close:before {
    content: "\f00d"
}

.la.la-gear:before {
    content: "\f013"
}

.la.la-trash-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-trash-o:before {
    content: "\f2ed"
}

.la.la-file-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-file-o:before {
    content: "\f15b"
}

.la.la-clock-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-clock-o:before {
    content: "\f017"
}

.la.la-arrow-circle-o-down {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-arrow-circle-o-down:before {
    content: "\f358"
}

.la.la-arrow-circle-o-up {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-arrow-circle-o-up:before {
    content: "\f35b"
}

.la.la-play-circle-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-play-circle-o:before {
    content: "\f144"
}

.la.la-repeat:before {
    content: "\f01e"
}

.la.la-rotate-right:before {
    content: "\f01e"
}

.la.la-refresh:before {
    content: "\f021"
}

.la.la-list-alt {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-dedent:before {
    content: "\f03b"
}

.la.la-video-camera:before {
    content: "\f03d"
}

.la.la-picture-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-picture-o:before {
    content: "\f03e"
}

.la.la-photo {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-photo:before {
    content: "\f03e"
}

.la.la-image {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-image:before {
    content: "\f03e"
}

.la.la-pencil:before {
    content: "\f303"
}

.la.la-map-marker:before {
    content: "\f3c5"
}

.la.la-pencil-square-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-pencil-square-o:before {
    content: "\f044"
}

.la.la-share-square-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-share-square-o:before {
    content: "\f14d"
}

.la.la-check-square-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-check-square-o:before {
    content: "\f14a"
}

.la.la-arrows:before {
    content: "\f0b2"
}

.la.la-times-circle-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-times-circle-o:before {
    content: "\f057"
}

.la.la-check-circle-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-check-circle-o:before {
    content: "\f058"
}

.la.la-mail-forward:before {
    content: "\f064"
}

.la.la-eye {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-eye-slash {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-warning:before {
    content: "\f071"
}

.la.la-calendar:before {
    content: "\f073"
}

.la.la-arrows-v:before {
    content: "\f338"
}

.la.la-arrows-h:before {
    content: "\f337"
}

.la.la-bar-chart {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-bar-chart:before {
    content: "\f080"
}

.la.la-bar-chart-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-bar-chart-o:before {
    content: "\f080"
}

.la.la-twitter-square {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-facebook-square {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-gears:before {
    content: "\f085"
}

.la.la-thumbs-o-up {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-thumbs-o-up:before {
    content: "\f164"
}

.la.la-thumbs-o-down {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-thumbs-o-down:before {
    content: "\f165"
}

.la.la-heart-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-heart-o:before {
    content: "\f004"
}

.la.la-sign-out:before {
    content: "\f2f5"
}

.la.la-linkedin-square {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-linkedin-square:before {
    content: "\f08c"
}

.la.la-thumb-tack:before {
    content: "\f08d"
}

.la.la-external-link:before {
    content: "\f35d"
}

.la.la-sign-in:before {
    content: "\f2f6"
}

.la.la-github-square {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-lemon-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-lemon-o:before {
    content: "\f094"
}

.la.la-square-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-square-o:before {
    content: "\f0c8"
}

.la.la-bookmark-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-bookmark-o:before {
    content: "\f02e"
}

.la.la-twitter {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-facebook {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-facebook:before {
    content: "\f39e"
}

.la.la-facebook-f {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-facebook-f:before {
    content: "\f39e"
}

.la.la-github {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-credit-card {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-feed:before {
    content: "\f09e"
}

.la.la-hdd-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-hdd-o:before {
    content: "\f0a0"
}

.la.la-hand-o-right {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-hand-o-right:before {
    content: "\f0a4"
}

.la.la-hand-o-left {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-hand-o-left:before {
    content: "\f0a5"
}

.la.la-hand-o-up {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-hand-o-up:before {
    content: "\f0a6"
}

.la.la-hand-o-down {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-hand-o-down:before {
    content: "\f0a7"
}

.la.la-arrows-alt:before {
    content: "\f31e"
}

.la.la-group:before {
    content: "\f0c0"
}

.la.la-chain:before {
    content: "\f0c1"
}

.la.la-scissors:before {
    content: "\f0c4"
}

.la.la-files-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-files-o:before {
    content: "\f0c5"
}

.la.la-floppy-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-floppy-o:before {
    content: "\f0c7"
}

.la.la-navicon:before {
    content: "\f0c9"
}

.la.la-reorder:before {
    content: "\f0c9"
}

.la.la-pinterest {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-pinterest-square {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-google-plus-square {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-google-plus {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-google-plus:before {
    content: "\f0d5"
}

.la.la-money {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-money:before {
    content: "\f3d1"
}

.la.la-unsorted:before {
    content: "\f0dc"
}

.la.la-sort-desc:before {
    content: "\f0dd"
}

.la.la-sort-asc:before {
    content: "\f0de"
}

.la.la-linkedin {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-linkedin:before {
    content: "\f0e1"
}

.la.la-rotate-left:before {
    content: "\f0e2"
}

.la.la-legal:before {
    content: "\f0e3"
}

.la.la-tachometer:before {
    content: "\f3fd"
}

.la.la-dashboard:before {
    content: "\f3fd"
}

.la.la-comment-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-comment-o:before {
    content: "\f075"
}

.la.la-comments-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-comments-o:before {
    content: "\f086"
}

.la.la-flash:before {
    content: "\f0e7"
}

.la.la-clipboard {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-paste {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-paste:before {
    content: "\f328"
}

.la.la-lightbulb-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-lightbulb-o:before {
    content: "\f0eb"
}

.la.la-exchange:before {
    content: "\f362"
}

.la.la-cloud-download:before {
    content: "\f381"
}

.la.la-cloud-upload:before {
    content: "\f382"
}

.la.la-bell-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-bell-o:before {
    content: "\f0f3"
}

.la.la-cutlery:before {
    content: "\f2e7"
}

.la.la-file-text-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-file-text-o:before {
    content: "\f15c"
}

.la.la-building-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-building-o:before {
    content: "\f1ad"
}

.la.la-hospital-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-hospital-o:before {
    content: "\f0f8"
}

.la.la-tablet:before {
    content: "\f3fa"
}

.la.la-mobile:before {
    content: "\f3cd"
}

.la.la-mobile-phone:before {
    content: "\f3cd"
}

.la.la-circle-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-circle-o:before {
    content: "\f111"
}

.la.la-mail-reply:before {
    content: "\f3e5"
}

.la.la-github-alt {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-folder-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-folder-o:before {
    content: "\f07b"
}

.la.la-folder-open-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-folder-open-o:before {
    content: "\f07c"
}

.la.la-smile-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-smile-o:before {
    content: "\f118"
}

.la.la-frown-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-frown-o:before {
    content: "\f119"
}

.la.la-meh-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-meh-o:before {
    content: "\f11a"
}

.la.la-keyboard-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-keyboard-o:before {
    content: "\f11c"
}

.la.la-flag-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-flag-o:before {
    content: "\f024"
}

.la.la-mail-reply-all:before {
    content: "\f122"
}

.la.la-star-half-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-star-half-o:before {
    content: "\f089"
}

.la.la-star-half-empty {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-star-half-empty:before {
    content: "\f089"
}

.la.la-star-half-full {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-star-half-full:before {
    content: "\f089"
}

.la.la-code-fork:before {
    content: "\f126"
}

.la.la-chain-broken:before {
    content: "\f127"
}

.la.la-shield:before {
    content: "\f3ed"
}

.la.la-calendar-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-calendar-o:before {
    content: "\f133"
}

.la.la-maxcdn {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-html5 {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-css3 {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-ticket:before {
    content: "\f3ff"
}

.la.la-minus-square-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-minus-square-o:before {
    content: "\f146"
}

.la.la-level-up:before {
    content: "\f3bf"
}

.la.la-level-down:before {
    content: "\f3be"
}

.la.la-pencil-square:before {
    content: "\f14b"
}

.la.la-external-link-square:before {
    content: "\f360"
}

.la.la-compass {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-caret-square-o-down {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-caret-square-o-down:before {
    content: "\f150"
}

.la.la-toggle-down {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-toggle-down:before {
    content: "\f150"
}

.la.la-caret-square-o-up {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-caret-square-o-up:before {
    content: "\f151"
}

.la.la-toggle-up {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-toggle-up:before {
    content: "\f151"
}

.la.la-caret-square-o-right {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-caret-square-o-right:before {
    content: "\f152"
}

.la.la-toggle-right {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-toggle-right:before {
    content: "\f152"
}

.la.la-eur:before {
    content: "\f153"
}

.la.la-euro:before {
    content: "\f153"
}

.la.la-gbp:before {
    content: "\f154"
}

.la.la-usd:before {
    content: "\f155"
}

.la.la-dollar:before {
    content: "\f155"
}

.la.la-inr:before {
    content: "\f156"
}

.la.la-rupee:before {
    content: "\f156"
}

.la.la-jpy:before {
    content: "\f157"
}

.la.la-cny:before {
    content: "\f157"
}

.la.la-rmb:before {
    content: "\f157"
}

.la.la-yen:before {
    content: "\f157"
}

.la.la-rub:before {
    content: "\f158"
}

.la.la-ruble:before {
    content: "\f158"
}

.la.la-rouble:before {
    content: "\f158"
}

.la.la-krw:before {
    content: "\f159"
}

.la.la-won:before {
    content: "\f159"
}

.la.la-btc {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-bitcoin {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-bitcoin:before {
    content: "\f15a"
}

.la.la-file-text:before {
    content: "\f15c"
}

.la.la-sort-alpha-asc:before {
    content: "\f15d"
}

.la.la-sort-alpha-desc:before {
    content: "\f881"
}

.la.la-sort-amount-asc:before {
    content: "\f160"
}

.la.la-sort-amount-desc:before {
    content: "\f884"
}

.la.la-sort-numeric-asc:before {
    content: "\f162"
}

.la.la-sort-numeric-desc:before {
    content: "\f886"
}

.la.la-youtube-square {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-youtube {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-xing {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-xing-square {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-youtube-play {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-youtube-play:before {
    content: "\f167"
}

.la.la-dropbox {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-stack-overflow {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-instagram {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-flickr {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-adn {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-bitbucket {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-bitbucket-square {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-bitbucket-square:before {
    content: "\f171"
}

.la.la-tumblr {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-tumblr-square {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-long-arrow-down:before {
    content: "\f309"
}

.la.la-long-arrow-up:before {
    content: "\f30c"
}

.la.la-long-arrow-left:before {
    content: "\f30a"
}

.la.la-long-arrow-right:before {
    content: "\f30b"
}

.la.la-apple {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-windows {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-android {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-linux {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-dribbble {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-skype {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-foursquare {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-trello {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-gratipay {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-gittip {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-gittip:before {
    content: "\f184"
}

.la.la-sun-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-sun-o:before {
    content: "\f185"
}

.la.la-moon-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-moon-o:before {
    content: "\f186"
}

.la.la-vk {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-weibo {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-renren {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-pagelines {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-stack-exchange {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-arrow-circle-o-right {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-arrow-circle-o-right:before {
    content: "\f35a"
}

.la.la-arrow-circle-o-left {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-arrow-circle-o-left:before {
    content: "\f359"
}

.la.la-caret-square-o-left {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-caret-square-o-left:before {
    content: "\f191"
}

.la.la-toggle-left {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-toggle-left:before {
    content: "\f191"
}

.la.la-dot-circle-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-dot-circle-o:before {
    content: "\f192"
}

.la.la-vimeo-square {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-try:before {
    content: "\f195"
}

.la.la-turkish-lira:before {
    content: "\f195"
}

.la.la-plus-square-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-plus-square-o:before {
    content: "\f0fe"
}

.la.la-slack {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-wordpress {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-openid {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-institution:before {
    content: "\f19c"
}

.la.la-bank:before {
    content: "\f19c"
}

.la.la-mortar-board:before {
    content: "\f19d"
}

.la.la-yahoo {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-google {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-reddit {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-reddit-square {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-stumbleupon-circle {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-stumbleupon {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-delicious {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-digg {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-pied-piper-pp {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-pied-piper-alt {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-drupal {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-joomla {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-spoon:before {
    content: "\f2e5"
}

.la.la-behance {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-behance-square {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-steam {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-steam-square {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-automobile:before {
    content: "\f1b9"
}

.la.la-cab:before {
    content: "\f1ba"
}

.la.la-envelope-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-envelope-o:before {
    content: "\f0e0"
}

.la.la-deviantart {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-soundcloud {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-file-pdf-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-file-pdf-o:before {
    content: "\f1c1"
}

.la.la-file-word-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-file-word-o:before {
    content: "\f1c2"
}

.la.la-file-excel-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-file-excel-o:before {
    content: "\f1c3"
}

.la.la-file-powerpoint-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-file-powerpoint-o:before {
    content: "\f1c4"
}

.la.la-file-image-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-file-image-o:before {
    content: "\f1c5"
}

.la.la-file-photo-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-file-photo-o:before {
    content: "\f1c5"
}

.la.la-file-picture-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-file-picture-o:before {
    content: "\f1c5"
}

.la.la-file-archive-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-file-archive-o:before {
    content: "\f1c6"
}

.la.la-file-zip-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-file-zip-o:before {
    content: "\f1c6"
}

.la.la-file-audio-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-file-audio-o:before {
    content: "\f1c7"
}

.la.la-file-sound-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-file-sound-o:before {
    content: "\f1c7"
}

.la.la-file-video-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-file-video-o:before {
    content: "\f1c8"
}

.la.la-file-movie-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-file-movie-o:before {
    content: "\f1c8"
}

.la.la-file-code-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-file-code-o:before {
    content: "\f1c9"
}

.la.la-vine {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-codepen {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-jsfiddle {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-life-ring {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-life-bouy {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-life-bouy:before {
    content: "\f1cd"
}

.la.la-life-buoy {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-life-buoy:before {
    content: "\f1cd"
}

.la.la-life-saver {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-life-saver:before {
    content: "\f1cd"
}

.la.la-support {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-support:before {
    content: "\f1cd"
}

.la.la-circle-o-notch:before {
    content: "\f1ce"
}

.la.la-rebel {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-ra {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-ra:before {
    content: "\f1d0"
}

.la.la-resistance {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-resistance:before {
    content: "\f1d0"
}

.la.la-empire {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-ge {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-ge:before {
    content: "\f1d1"
}

.la.la-git-square {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-git {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-hacker-news {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-y-combinator-square {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-y-combinator-square:before {
    content: "\f1d4"
}

.la.la-yc-square {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-yc-square:before {
    content: "\f1d4"
}

.la.la-tencent-weibo {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-qq {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-weixin {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-wechat {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-wechat:before {
    content: "\f1d7"
}

.la.la-send:before {
    content: "\f1d8"
}

.la.la-paper-plane-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-paper-plane-o:before {
    content: "\f1d8"
}

.la.la-send-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-send-o:before {
    content: "\f1d8"
}

.la.la-circle-thin {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-circle-thin:before {
    content: "\f111"
}

.la.la-header:before {
    content: "\f1dc"
}

.la.la-sliders:before {
    content: "\f1de"
}

.la.la-futbol-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-futbol-o:before {
    content: "\f1e3"
}

.la.la-soccer-ball-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-soccer-ball-o:before {
    content: "\f1e3"
}

.la.la-slideshare {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-twitch {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-yelp {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-newspaper-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-newspaper-o:before {
    content: "\f1ea"
}

.la.la-paypal {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-google-wallet {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-cc-visa {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-cc-mastercard {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-cc-discover {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-cc-amex {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-cc-paypal {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-cc-stripe {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-bell-slash-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-bell-slash-o:before {
    content: "\f1f6"
}

.la.la-trash:before {
    content: "\f2ed"
}

.la.la-copyright {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-eyedropper:before {
    content: "\f1fb"
}

.la.la-area-chart:before {
    content: "\f1fe"
}

.la.la-pie-chart:before {
    content: "\f200"
}

.la.la-line-chart:before {
    content: "\f201"
}

.la.la-lastfm {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-lastfm-square {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-ioxhost {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-angellist {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-cc {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-cc:before {
    content: "\f20a"
}

.la.la-ils:before {
    content: "\f20b"
}

.la.la-shekel:before {
    content: "\f20b"
}

.la.la-sheqel:before {
    content: "\f20b"
}

.la.la-meanpath {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-meanpath:before {
    content: "\f2b4"
}

.la.la-buysellads {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-connectdevelop {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-dashcube {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-forumbee {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-leanpub {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-sellsy {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-shirtsinbulk {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-simplybuilt {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-skyatlas {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-diamond {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-diamond:before {
    content: "\f3a5"
}

.la.la-intersex:before {
    content: "\f224"
}

.la.la-facebook-official {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-facebook-official:before {
    content: "\f09a"
}

.la.la-pinterest-p {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-whatsapp {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-hotel:before {
    content: "\f236"
}

.la.la-viacoin {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-medium {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-y-combinator {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-yc {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-yc:before {
    content: "\f23b"
}

.la.la-optin-monster {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-opencart {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-expeditedssl {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-battery-4:before {
    content: "\f240"
}

.la.la-battery:before {
    content: "\f240"
}

.la.la-battery-3:before {
    content: "\f241"
}

.la.la-battery-2:before {
    content: "\f242"
}

.la.la-battery-1:before {
    content: "\f243"
}

.la.la-battery-0:before {
    content: "\f244"
}

.la.la-object-group {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-object-ungroup {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-sticky-note-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-sticky-note-o:before {
    content: "\f249"
}

.la.la-cc-jcb {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-cc-diners-club {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-clone {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-hourglass-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-hourglass-o:before {
    content: "\f254"
}

.la.la-hourglass-1:before {
    content: "\f251"
}

.la.la-hourglass-2:before {
    content: "\f252"
}

.la.la-hourglass-3:before {
    content: "\f253"
}

.la.la-hand-rock-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-hand-rock-o:before {
    content: "\f255"
}

.la.la-hand-grab-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-hand-grab-o:before {
    content: "\f255"
}

.la.la-hand-paper-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-hand-paper-o:before {
    content: "\f256"
}

.la.la-hand-stop-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-hand-stop-o:before {
    content: "\f256"
}

.la.la-hand-scissors-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-hand-scissors-o:before {
    content: "\f257"
}

.la.la-hand-lizard-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-hand-lizard-o:before {
    content: "\f258"
}

.la.la-hand-spock-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-hand-spock-o:before {
    content: "\f259"
}

.la.la-hand-pointer-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-hand-pointer-o:before {
    content: "\f25a"
}

.la.la-hand-peace-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-hand-peace-o:before {
    content: "\f25b"
}

.la.la-registered {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-creative-commons {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-gg {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-gg-circle {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-tripadvisor {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-odnoklassniki {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-odnoklassniki-square {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-get-pocket {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-wikipedia-w {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-safari {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-chrome {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-firefox {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-opera {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-internet-explorer {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-television:before {
    content: "\f26c"
}

.la.la-contao {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-500px {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-amazon {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-calendar-plus-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-calendar-plus-o:before {
    content: "\f271"
}

.la.la-calendar-minus-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-calendar-minus-o:before {
    content: "\f272"
}

.la.la-calendar-times-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-calendar-times-o:before {
    content: "\f273"
}

.la.la-calendar-check-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-calendar-check-o:before {
    content: "\f274"
}

.la.la-map-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-map-o:before {
    content: "\f279"
}

.la.la-commenting:before {
    content: "\f4ad"
}

.la.la-commenting-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-commenting-o:before {
    content: "\f4ad"
}

.la.la-houzz {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-vimeo {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-vimeo:before {
    content: "\f27d"
}

.la.la-black-tie {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-fonticons {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-reddit-alien {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-edge {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-credit-card-alt:before {
    content: "\f09d"
}

.la.la-codiepie {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-modx {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-fort-awesome {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-usb {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-product-hunt {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-mixcloud {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-scribd {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-pause-circle-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-pause-circle-o:before {
    content: "\f28b"
}

.la.la-stop-circle-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-stop-circle-o:before {
    content: "\f28d"
}

.la.la-bluetooth {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-bluetooth-b {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-gitlab {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-wpbeginner {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-wpforms {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-envira {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-wheelchair-alt {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-wheelchair-alt:before {
    content: "\f368"
}

.la.la-question-circle-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-question-circle-o:before {
    content: "\f059"
}

.la.la-volume-control-phone:before {
    content: "\f2a0"
}

.la.la-asl-interpreting:before {
    content: "\f2a3"
}

.la.la-deafness:before {
    content: "\f2a4"
}

.la.la-hard-of-hearing:before {
    content: "\f2a4"
}

.la.la-glide {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-glide-g {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-signing:before {
    content: "\f2a7"
}

.la.la-viadeo {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-viadeo-square {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-snapchat {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-snapchat-ghost {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-snapchat-square {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-pied-piper {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-first-order {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-yoast {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-themeisle {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-google-plus-official {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-google-plus-official:before {
    content: "\f2b3"
}

.la.la-google-plus-circle {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-google-plus-circle:before {
    content: "\f2b3"
}

.la.la-font-awesome {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-fa {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-fa:before {
    content: "\f2b4"
}

.la.la-handshake-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-handshake-o:before {
    content: "\f2b5"
}

.la.la-envelope-open-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-envelope-open-o:before {
    content: "\f2b6"
}

.la.la-linode {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-address-book-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-address-book-o:before {
    content: "\f2b9"
}

.la.la-vcard:before {
    content: "\f2bb"
}

.la.la-address-card-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-address-card-o:before {
    content: "\f2bb"
}

.la.la-vcard-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-vcard-o:before {
    content: "\f2bb"
}

.la.la-user-circle-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-user-circle-o:before {
    content: "\f2bd"
}

.la.la-user-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-user-o:before {
    content: "\f007"
}

.la.la-id-badge {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-drivers-license:before {
    content: "\f2c2"
}

.la.la-id-card-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-id-card-o:before {
    content: "\f2c2"
}

.la.la-drivers-license-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-drivers-license-o:before {
    content: "\f2c2"
}

.la.la-quora {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-free-code-camp {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-telegram {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-thermometer-4:before {
    content: "\f2c7"
}

.la.la-thermometer:before {
    content: "\f2c7"
}

.la.la-thermometer-3:before {
    content: "\f2c8"
}

.la.la-thermometer-2:before {
    content: "\f2c9"
}

.la.la-thermometer-1:before {
    content: "\f2ca"
}

.la.la-thermometer-0:before {
    content: "\f2cb"
}

.la.la-bathtub:before {
    content: "\f2cd"
}

.la.la-s15:before {
    content: "\f2cd"
}

.la.la-window-maximize {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-window-restore {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-times-rectangle:before {
    content: "\f410"
}

.la.la-window-close-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-window-close-o:before {
    content: "\f410"
}

.la.la-times-rectangle-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-times-rectangle-o:before {
    content: "\f410"
}

.la.la-bandcamp {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-grav {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-etsy {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-imdb {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-ravelry {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-eercast {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-eercast:before {
    content: "\f2da"
}

.la.la-snowflake-o {
    font-family: "Line Awesome Free";
    font-weight: 400
}

.la.la-snowflake-o:before {
    content: "\f2dc"
}

.la.la-superpowers {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-wpexplorer {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

.la.la-spotify {
    font-family: "Line Awesome Brands";
    font-weight: 400
}

@font-face {
    font-family: remixicon;
    src: url(../fonts/remixicondf6d.eot?t=1590207869815);
    src: url(../fonts/remixicondf6d.eot?t=1590207869815#iefix) format("embedded-opentype"), url(../fonts/remixicondf6d.html?t=1590207869815) format("woff2"), url(../fonts/remixicondf6d.woff?t=1590207869815) format("woff"), url(../fonts/remixicondf6d.ttf?t=1590207869815) format("truetype"), url(../fonts/remixicondf6d.svg?t=1590207869815#remixicon) format("svg");
    font-display: swap
}

[class*=" ri-"],
[class^=ri-] {
    font-family: remixicon !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.ri-lg {
    font-size: 1.3333em;
    line-height: .75em;
    vertical-align: -.0667em
}

.ri-xl {
    font-size: 1.5em;
    line-height: .6666em;
    vertical-align: -.075em
}

.ri-xxs {
    font-size: .5em
}

.ri-xs {
    font-size: .75em
}

.ri-sm {
    font-size: .875em
}

.ri-1x {
    font-size: 1em
}

.ri-2x {
    font-size: 2em
}

.ri-3x {
    font-size: 3em
}

.ri-4x {
    font-size: 4em
}

.ri-5x {
    font-size: 5em
}

.ri-6x {
    font-size: 6em
}

.ri-7x {
    font-size: 7em
}

.ri-8x {
    font-size: 8em
}

.ri-9x {
    font-size: 9em
}

.ri-10x {
    font-size: 10em
}

.ri-fw {
    text-align: center;
    width: 1.25em
}

.ri-24-hours-fill:before {
    content: "\ea01"
}

.ri-24-hours-line:before {
    content: "\ea02"
}

.ri-4k-fill:before {
    content: "\ea03"
}

.ri-4k-line:before {
    content: "\ea04"
}

.ri-a-b:before {
    content: "\ea05"
}

.ri-account-box-fill:before {
    content: "\ea06"
}

.ri-account-box-line:before {
    content: "\ea07"
}

.ri-account-circle-fill:before {
    content: "\ea08"
}

.ri-account-circle-line:before {
    content: "\ea09"
}

.ri-account-pin-box-fill:before {
    content: "\ea0a"
}

.ri-account-pin-box-line:before {
    content: "\ea0b"
}

.ri-account-pin-circle-fill:before {
    content: "\ea0c"
}

.ri-account-pin-circle-line:before {
    content: "\ea0d"
}

.ri-add-box-fill:before {
    content: "\ea0e"
}

.ri-add-box-line:before {
    content: "\ea0f"
}

.ri-add-circle-fill:before {
    content: "\ea10"
}

.ri-add-circle-line:before {
    content: "\ea11"
}

.ri-add-fill:before {
    content: "\ea12"
}

.ri-add-line:before {
    content: "\ea13"
}

.ri-admin-fill:before {
    content: "\ea14"
}

.ri-admin-line:before {
    content: "\ea15"
}

.ri-advertisement-fill:before {
    content: "\ea16"
}

.ri-advertisement-line:before {
    content: "\ea17"
}

.ri-airplay-fill:before {
    content: "\ea18"
}

.ri-airplay-line:before {
    content: "\ea19"
}

.ri-alarm-fill:before {
    content: "\ea1a"
}

.ri-alarm-line:before {
    content: "\ea1b"
}

.ri-alarm-warning-fill:before {
    content: "\ea1c"
}

.ri-alarm-warning-line:before {
    content: "\ea1d"
}

.ri-album-fill:before {
    content: "\ea1e"
}

.ri-album-line:before {
    content: "\ea1f"
}

.ri-alert-fill:before {
    content: "\ea20"
}

.ri-alert-line:before {
    content: "\ea21"
}

.ri-aliens-fill:before {
    content: "\ea22"
}

.ri-aliens-line:before {
    content: "\ea23"
}

.ri-align-bottom:before {
    content: "\ea24"
}

.ri-align-center:before {
    content: "\ea25"
}

.ri-align-justify:before {
    content: "\ea26"
}

.ri-align-left:before {
    content: "\ea27"
}

.ri-align-right:before {
    content: "\ea28"
}

.ri-align-top:before {
    content: "\ea29"
}

.ri-align-vertically:before {
    content: "\ea2a"
}

.ri-alipay-fill:before {
    content: "\ea2b"
}

.ri-alipay-line:before {
    content: "\ea2c"
}

.ri-amazon-fill:before {
    content: "\ea2d"
}

.ri-amazon-line:before {
    content: "\ea2e"
}

.ri-anchor-fill:before {
    content: "\ea2f"
}

.ri-anchor-line:before {
    content: "\ea30"
}

.ri-ancient-gate-fill:before {
    content: "\ea31"
}

.ri-ancient-gate-line:before {
    content: "\ea32"
}

.ri-ancient-pavilion-fill:before {
    content: "\ea33"
}

.ri-ancient-pavilion-line:before {
    content: "\ea34"
}

.ri-android-fill:before {
    content: "\ea35"
}

.ri-android-line:before {
    content: "\ea36"
}

.ri-angularjs-fill:before {
    content: "\ea37"
}

.ri-angularjs-line:before {
    content: "\ea38"
}

.ri-anticlockwise-2-fill:before {
    content: "\ea39"
}

.ri-anticlockwise-2-line:before {
    content: "\ea3a"
}

.ri-anticlockwise-fill:before {
    content: "\ea3b"
}

.ri-anticlockwise-line:before {
    content: "\ea3c"
}

.ri-app-store-fill:before {
    content: "\ea3d"
}

.ri-app-store-line:before {
    content: "\ea3e"
}

.ri-apple-fill:before {
    content: "\ea3f"
}

.ri-apple-line:before {
    content: "\ea40"
}

.ri-apps-2-fill:before {
    content: "\ea41"
}

.ri-apps-2-line:before {
    content: "\ea42"
}

.ri-apps-fill:before {
    content: "\ea43"
}

.ri-apps-line:before {
    content: "\ea44"
}

.ri-archive-drawer-fill:before {
    content: "\ea45"
}

.ri-archive-drawer-line:before {
    content: "\ea46"
}

.ri-archive-fill:before {
    content: "\ea47"
}

.ri-archive-line:before {
    content: "\ea48"
}

.ri-arrow-down-circle-fill:before {
    content: "\ea49"
}

.ri-arrow-down-circle-line:before {
    content: "\ea4a"
}

.ri-arrow-down-fill:before {
    content: "\ea4b"
}

.ri-arrow-down-line:before {
    content: "\ea4c"
}

.ri-arrow-down-s-fill:before {
    content: "\ea4d"
}

.ri-arrow-down-s-line:before {
    content: "\ea4e"
}

.ri-arrow-drop-down-fill:before {
    content: "\ea4f"
}

.ri-arrow-drop-down-line:before {
    content: "\ea50"
}

.ri-arrow-drop-left-fill:before {
    content: "\ea51"
}

.ri-arrow-drop-left-line:before {
    content: "\ea52"
}

.ri-arrow-drop-right-fill:before {
    content: "\ea53"
}

.ri-arrow-drop-right-line:before {
    content: "\ea54"
}

.ri-arrow-drop-up-fill:before {
    content: "\ea55"
}

.ri-arrow-drop-up-line:before {
    content: "\ea56"
}

.ri-arrow-go-back-fill:before {
    content: "\ea57"
}

.ri-arrow-go-back-line:before {
    content: "\ea58"
}

.ri-arrow-go-forward-fill:before {
    content: "\ea59"
}

.ri-arrow-go-forward-line:before {
    content: "\ea5a"
}

.ri-arrow-left-circle-fill:before {
    content: "\ea5b"
}

.ri-arrow-left-circle-line:before {
    content: "\ea5c"
}

.ri-arrow-left-down-fill:before {
    content: "\ea5d"
}

.ri-arrow-left-down-line:before {
    content: "\ea5e"
}

.ri-arrow-left-fill:before {
    content: "\ea5f"
}

.ri-arrow-left-line:before {
    content: "\ea60"
}

.ri-arrow-left-right-fill:before {
    content: "\ea61"
}

.ri-arrow-left-right-line:before {
    content: "\ea62"
}

.ri-arrow-left-s-fill:before {
    content: "\ea63"
}

.ri-arrow-left-s-line:before {
    content: "\ea64"
}

.ri-arrow-left-up-fill:before {
    content: "\ea65"
}

.ri-arrow-left-up-line:before {
    content: "\ea66"
}

.ri-arrow-right-circle-fill:before {
    content: "\ea67"
}

.ri-arrow-right-circle-line:before {
    content: "\ea68"
}

.ri-arrow-right-down-fill:before {
    content: "\ea69"
}

.ri-arrow-right-down-line:before {
    content: "\ea6a"
}

.ri-arrow-right-fill:before {
    content: "\ea6b"
}

.ri-arrow-right-line:before {
    content: "\ea6c"
}

.ri-arrow-right-s-fill:before {
    content: "\ea6d"
}

.ri-arrow-right-s-line:before {
    content: "\ea6e"
}

.ri-arrow-right-up-fill:before {
    content: "\ea6f"
}

.ri-arrow-right-up-line:before {
    content: "\ea70"
}

.ri-arrow-up-circle-fill:before {
    content: "\ea71"
}

.ri-arrow-up-circle-line:before {
    content: "\ea72"
}

.ri-arrow-up-down-fill:before {
    content: "\ea73"
}

.ri-arrow-up-down-line:before {
    content: "\ea74"
}

.ri-arrow-up-fill:before {
    content: "\ea75"
}

.ri-arrow-up-line:before {
    content: "\ea76"
}

.ri-arrow-up-s-fill:before {
    content: "\ea77"
}

.ri-arrow-up-s-line:before {
    content: "\ea78"
}

.ri-artboard-2-fill:before {
    content: "\ea79"
}

.ri-artboard-2-line:before {
    content: "\ea7a"
}

.ri-artboard-fill:before {
    content: "\ea7b"
}

.ri-artboard-line:before {
    content: "\ea7c"
}

.ri-article-fill:before {
    content: "\ea7d"
}

.ri-article-line:before {
    content: "\ea7e"
}

.ri-aspect-ratio-fill:before {
    content: "\ea7f"
}

.ri-aspect-ratio-line:before {
    content: "\ea80"
}

.ri-asterisk:before {
    content: "\ea81"
}

.ri-at-fill:before {
    content: "\ea82"
}

.ri-at-line:before {
    content: "\ea83"
}

.ri-attachment-2:before {
    content: "\ea84"
}

.ri-attachment-fill:before {
    content: "\ea85"
}

.ri-attachment-line:before {
    content: "\ea86"
}

.ri-auction-fill:before {
    content: "\ea87"
}

.ri-auction-line:before {
    content: "\ea88"
}

.ri-award-fill:before {
    content: "\ea89"
}

.ri-award-line:before {
    content: "\ea8a"
}

.ri-baidu-fill:before {
    content: "\ea8b"
}

.ri-baidu-line:before {
    content: "\ea8c"
}

.ri-ball-pen-fill:before {
    content: "\ea8d"
}

.ri-ball-pen-line:before {
    content: "\ea8e"
}

.ri-bank-card-2-fill:before {
    content: "\ea8f"
}

.ri-bank-card-2-line:before {
    content: "\ea90"
}

.ri-bank-card-fill:before {
    content: "\ea91"
}

.ri-bank-card-line:before {
    content: "\ea92"
}

.ri-bank-fill:before {
    content: "\ea93"
}

.ri-bank-line:before {
    content: "\ea94"
}

.ri-bar-chart-2-fill:before {
    content: "\ea95"
}

.ri-bar-chart-2-line:before {
    content: "\ea96"
}

.ri-bar-chart-box-fill:before {
    content: "\ea97"
}

.ri-bar-chart-box-line:before {
    content: "\ea98"
}

.ri-bar-chart-fill:before {
    content: "\ea99"
}

.ri-bar-chart-grouped-fill:before {
    content: "\ea9a"
}

.ri-bar-chart-grouped-line:before {
    content: "\ea9b"
}

.ri-bar-chart-horizontal-fill:before {
    content: "\ea9c"
}

.ri-bar-chart-horizontal-line:before {
    content: "\ea9d"
}

.ri-bar-chart-line:before {
    content: "\ea9e"
}

.ri-barcode-box-fill:before {
    content: "\ea9f"
}

.ri-barcode-box-line:before {
    content: "\eaa0"
}

.ri-barcode-fill:before {
    content: "\eaa1"
}

.ri-barcode-line:before {
    content: "\eaa2"
}

.ri-barricade-fill:before {
    content: "\eaa3"
}

.ri-barricade-line:before {
    content: "\eaa4"
}

.ri-base-station-fill:before {
    content: "\eaa5"
}

.ri-base-station-line:before {
    content: "\eaa6"
}

.ri-basketball-fill:before {
    content: "\eaa7"
}

.ri-basketball-line:before {
    content: "\eaa8"
}

.ri-battery-2-charge-fill:before {
    content: "\eaa9"
}

.ri-battery-2-charge-line:before {
    content: "\eaaa"
}

.ri-battery-2-fill:before {
    content: "\eaab"
}

.ri-battery-2-line:before {
    content: "\eaac"
}

.ri-battery-charge-fill:before {
    content: "\eaad"
}

.ri-battery-charge-line:before {
    content: "\eaae"
}

.ri-battery-fill:before {
    content: "\eaaf"
}

.ri-battery-line:before {
    content: "\eab0"
}

.ri-battery-low-fill:before {
    content: "\eab1"
}

.ri-battery-low-line:before {
    content: "\eab2"
}

.ri-battery-saver-fill:before {
    content: "\eab3"
}

.ri-battery-saver-line:before {
    content: "\eab4"
}

.ri-battery-share-fill:before {
    content: "\eab5"
}

.ri-battery-share-line:before {
    content: "\eab6"
}

.ri-bear-smile-fill:before {
    content: "\eab7"
}

.ri-bear-smile-line:before {
    content: "\eab8"
}

.ri-behance-fill:before {
    content: "\eab9"
}

.ri-behance-line:before {
    content: "\eaba"
}

.ri-bell-fill:before {
    content: "\eabb"
}

.ri-bell-line:before {
    content: "\eabc"
}

.ri-bike-fill:before {
    content: "\eabd"
}

.ri-bike-line:before {
    content: "\eabe"
}

.ri-bilibili-fill:before {
    content: "\eabf"
}

.ri-bilibili-line:before {
    content: "\eac0"
}

.ri-bill-fill:before {
    content: "\eac1"
}

.ri-bill-line:before {
    content: "\eac2"
}

.ri-billiards-fill:before {
    content: "\eac3"
}

.ri-billiards-line:before {
    content: "\eac4"
}

.ri-bit-coin-fill:before {
    content: "\eac5"
}

.ri-bit-coin-line:before {
    content: "\eac6"
}

.ri-blaze-fill:before {
    content: "\eac7"
}

.ri-blaze-line:before {
    content: "\eac8"
}

.ri-bluetooth-connect-fill:before {
    content: "\eac9"
}

.ri-bluetooth-connect-line:before {
    content: "\eaca"
}

.ri-bluetooth-fill:before {
    content: "\eacb"
}

.ri-bluetooth-line:before {
    content: "\eacc"
}

.ri-blur-off-fill:before {
    content: "\eacd"
}

.ri-blur-off-line:before {
    content: "\eace"
}

.ri-body-scan-fill:before {
    content: "\eacf"
}

.ri-body-scan-line:before {
    content: "\ead0"
}

.ri-bold:before {
    content: "\ead1"
}

.ri-book-2-fill:before {
    content: "\ead2"
}

.ri-book-2-line:before {
    content: "\ead3"
}

.ri-book-3-fill:before {
    content: "\ead4"
}

.ri-book-3-line:before {
    content: "\ead5"
}

.ri-book-fill:before {
    content: "\ead6"
}

.ri-book-line:before {
    content: "\ead7"
}

.ri-book-mark-fill:before {
    content: "\ead8"
}

.ri-book-mark-line:before {
    content: "\ead9"
}

.ri-book-open-fill:before {
    content: "\eada"
}

.ri-book-open-line:before {
    content: "\eadb"
}

.ri-book-read-fill:before {
    content: "\eadc"
}

.ri-book-read-line:before {
    content: "\eadd"
}

.ri-booklet-fill:before {
    content: "\eade"
}

.ri-booklet-line:before {
    content: "\eadf"
}

.ri-bookmark-2-fill:before {
    content: "\eae0"
}

.ri-bookmark-2-line:before {
    content: "\eae1"
}

.ri-bookmark-3-fill:before {
    content: "\eae2"
}

.ri-bookmark-3-line:before {
    content: "\eae3"
}

.ri-bookmark-fill:before {
    content: "\eae4"
}

.ri-bookmark-line:before {
    content: "\eae5"
}

.ri-boxing-fill:before {
    content: "\eae6"
}

.ri-boxing-line:before {
    content: "\eae7"
}

.ri-braces-fill:before {
    content: "\eae8"
}

.ri-braces-line:before {
    content: "\eae9"
}

.ri-brackets-fill:before {
    content: "\eaea"
}

.ri-brackets-line:before {
    content: "\eaeb"
}

.ri-briefcase-2-fill:before {
    content: "\eaec"
}

.ri-briefcase-2-line:before {
    content: "\eaed"
}

.ri-briefcase-3-fill:before {
    content: "\eaee"
}

.ri-briefcase-3-line:before {
    content: "\eaef"
}

.ri-briefcase-4-fill:before {
    content: "\eaf0"
}

.ri-briefcase-4-line:before {
    content: "\eaf1"
}

.ri-briefcase-5-fill:before {
    content: "\eaf2"
}

.ri-briefcase-5-line:before {
    content: "\eaf3"
}

.ri-briefcase-fill:before {
    content: "\eaf4"
}

.ri-briefcase-line:before {
    content: "\eaf5"
}

.ri-bring-forward:before {
    content: "\eaf6"
}

.ri-bring-to-front:before {
    content: "\eaf7"
}

.ri-broadcast-fill:before {
    content: "\eaf8"
}

.ri-broadcast-line:before {
    content: "\eaf9"
}

.ri-brush-2-fill:before {
    content: "\eafa"
}

.ri-brush-2-line:before {
    content: "\eafb"
}

.ri-brush-3-fill:before {
    content: "\eafc"
}

.ri-brush-3-line:before {
    content: "\eafd"
}

.ri-brush-4-fill:before {
    content: "\eafe"
}

.ri-brush-4-line:before {
    content: "\eaff"
}

.ri-brush-fill:before {
    content: "\eb00"
}

.ri-brush-line:before {
    content: "\eb01"
}

.ri-bubble-chart-fill:before {
    content: "\eb02"
}

.ri-bubble-chart-line:before {
    content: "\eb03"
}

.ri-bug-2-fill:before {
    content: "\eb04"
}

.ri-bug-2-line:before {
    content: "\eb05"
}

.ri-bug-fill:before {
    content: "\eb06"
}

.ri-bug-line:before {
    content: "\eb07"
}

.ri-building-2-fill:before {
    content: "\eb08"
}

.ri-building-2-line:before {
    content: "\eb09"
}

.ri-building-3-fill:before {
    content: "\eb0a"
}

.ri-building-3-line:before {
    content: "\eb0b"
}

.ri-building-4-fill:before {
    content: "\eb0c"
}

.ri-building-4-line:before {
    content: "\eb0d"
}

.ri-building-fill:before {
    content: "\eb0e"
}

.ri-building-line:before {
    content: "\eb0f"
}

.ri-bus-2-fill:before {
    content: "\eb10"
}

.ri-bus-2-line:before {
    content: "\eb11"
}

.ri-bus-fill:before {
    content: "\eb12"
}

.ri-bus-line:before {
    content: "\eb13"
}

.ri-bus-wifi-fill:before {
    content: "\eb14"
}

.ri-bus-wifi-line:before {
    content: "\eb15"
}

.ri-cactus-fill:before {
    content: "\eb16"
}

.ri-cactus-line:before {
    content: "\eb17"
}

.ri-cake-2-fill:before {
    content: "\eb18"
}

.ri-cake-2-line:before {
    content: "\eb19"
}

.ri-cake-3-fill:before {
    content: "\eb1a"
}

.ri-cake-3-line:before {
    content: "\eb1b"
}

.ri-cake-fill:before {
    content: "\eb1c"
}

.ri-cake-line:before {
    content: "\eb1d"
}

.ri-calculator-fill:before {
    content: "\eb1e"
}

.ri-calculator-line:before {
    content: "\eb1f"
}

.ri-calendar-2-fill:before {
    content: "\eb20"
}

.ri-calendar-2-line:before {
    content: "\eb21"
}

.ri-calendar-check-fill:before {
    content: "\eb22"
}

.ri-calendar-check-line:before {
    content: "\eb23"
}

.ri-calendar-event-fill:before {
    content: "\eb24"
}

.ri-calendar-event-line:before {
    content: "\eb25"
}

.ri-calendar-fill:before {
    content: "\eb26"
}

.ri-calendar-line:before {
    content: "\eb27"
}

.ri-calendar-todo-fill:before {
    content: "\eb28"
}

.ri-calendar-todo-line:before {
    content: "\eb29"
}

.ri-camera-2-fill:before {
    content: "\eb2a"
}

.ri-camera-2-line:before {
    content: "\eb2b"
}

.ri-camera-3-fill:before {
    content: "\eb2c"
}

.ri-camera-3-line:before {
    content: "\eb2d"
}

.ri-camera-fill:before {
    content: "\eb2e"
}

.ri-camera-lens-fill:before {
    content: "\eb2f"
}

.ri-camera-lens-line:before {
    content: "\eb30"
}

.ri-camera-line:before {
    content: "\eb31"
}

.ri-camera-off-fill:before {
    content: "\eb32"
}

.ri-camera-off-line:before {
    content: "\eb33"
}

.ri-camera-switch-fill:before {
    content: "\eb34"
}

.ri-camera-switch-line:before {
    content: "\eb35"
}

.ri-capsule-fill:before {
    content: "\eb36"
}

.ri-capsule-line:before {
    content: "\eb37"
}

.ri-car-fill:before {
    content: "\eb38"
}

.ri-car-line:before {
    content: "\eb39"
}

.ri-car-washing-fill:before {
    content: "\eb3a"
}

.ri-car-washing-line:before {
    content: "\eb3b"
}

.ri-caravan-fill:before {
    content: "\eb3c"
}

.ri-caravan-line:before {
    content: "\eb3d"
}

.ri-cast-fill:before {
    content: "\eb3e"
}

.ri-cast-line:before {
    content: "\eb3f"
}

.ri-cellphone-fill:before {
    content: "\eb40"
}

.ri-cellphone-line:before {
    content: "\eb41"
}

.ri-celsius-fill:before {
    content: "\eb42"
}

.ri-celsius-line:before {
    content: "\eb43"
}

.ri-centos-fill:before {
    content: "\eb44"
}

.ri-centos-line:before {
    content: "\eb45"
}

.ri-character-recognition-fill:before {
    content: "\eb46"
}

.ri-character-recognition-line:before {
    content: "\eb47"
}

.ri-charging-pile-2-fill:before {
    content: "\eb48"
}

.ri-charging-pile-2-line:before {
    content: "\eb49"
}

.ri-charging-pile-fill:before {
    content: "\eb4a"
}

.ri-charging-pile-line:before {
    content: "\eb4b"
}

.ri-chat-1-fill:before {
    content: "\eb4c"
}

.ri-chat-1-line:before {
    content: "\eb4d"
}

.ri-chat-2-fill:before {
    content: "\eb4e"
}

.ri-chat-2-line:before {
    content: "\eb4f"
}

.ri-chat-3-fill:before {
    content: "\eb50"
}

.ri-chat-3-line:before {
    content: "\eb51"
}

.ri-chat-4-fill:before {
    content: "\eb52"
}

.ri-chat-4-line:before {
    content: "\eb53"
}

.ri-chat-check-fill:before {
    content: "\eb54"
}

.ri-chat-check-line:before {
    content: "\eb55"
}

.ri-chat-delete-fill:before {
    content: "\eb56"
}

.ri-chat-delete-line:before {
    content: "\eb57"
}

.ri-chat-download-fill:before {
    content: "\eb58"
}

.ri-chat-download-line:before {
    content: "\eb59"
}

.ri-chat-follow-up-fill:before {
    content: "\eb5a"
}

.ri-chat-follow-up-line:before {
    content: "\eb5b"
}

.ri-chat-forward-fill:before {
    content: "\eb5c"
}

.ri-chat-forward-line:before {
    content: "\eb5d"
}

.ri-chat-heart-fill:before {
    content: "\eb5e"
}

.ri-chat-heart-line:before {
    content: "\eb5f"
}

.ri-chat-history-fill:before {
    content: "\eb60"
}

.ri-chat-history-line:before {
    content: "\eb61"
}

.ri-chat-new-fill:before {
    content: "\eb62"
}

.ri-chat-new-line:before {
    content: "\eb63"
}

.ri-chat-off-fill:before {
    content: "\eb64"
}

.ri-chat-off-line:before {
    content: "\eb65"
}

.ri-chat-poll-fill:before {
    content: "\eb66"
}

.ri-chat-poll-line:before {
    content: "\eb67"
}

.ri-chat-private-fill:before {
    content: "\eb68"
}

.ri-chat-private-line:before {
    content: "\eb69"
}

.ri-chat-quote-fill:before {
    content: "\eb6a"
}

.ri-chat-quote-line:before {
    content: "\eb6b"
}

.ri-chat-settings-fill:before {
    content: "\eb6c"
}

.ri-chat-settings-line:before {
    content: "\eb6d"
}

.ri-chat-smile-2-fill:before {
    content: "\eb6e"
}

.ri-chat-smile-2-line:before {
    content: "\eb6f"
}

.ri-chat-smile-3-fill:before {
    content: "\eb70"
}

.ri-chat-smile-3-line:before {
    content: "\eb71"
}

.ri-chat-smile-fill:before {
    content: "\eb72"
}

.ri-chat-smile-line:before {
    content: "\eb73"
}

.ri-chat-upload-fill:before {
    content: "\eb74"
}

.ri-chat-upload-line:before {
    content: "\eb75"
}

.ri-chat-voice-fill:before {
    content: "\eb76"
}

.ri-chat-voice-line:before {
    content: "\eb77"
}

.ri-check-double-fill:before {
    content: "\eb78"
}

.ri-check-double-line:before {
    content: "\eb79"
}

.ri-check-fill:before {
    content: "\eb7a"
}

.ri-check-line:before {
    content: "\eb7b"
}

.ri-checkbox-blank-circle-fill:before {
    content: "\eb7c"
}

.ri-checkbox-blank-circle-line:before {
    content: "\eb7d"
}

.ri-checkbox-blank-fill:before {
    content: "\eb7e"
}

.ri-checkbox-blank-line:before {
    content: "\eb7f"
}

.ri-checkbox-circle-fill:before {
    content: "\eb80"
}

.ri-checkbox-circle-line:before {
    content: "\eb81"
}

.ri-checkbox-fill:before {
    content: "\eb82"
}

.ri-checkbox-indeterminate-fill:before {
    content: "\eb83"
}

.ri-checkbox-indeterminate-line:before {
    content: "\eb84"
}

.ri-checkbox-line:before {
    content: "\eb85"
}

.ri-checkbox-multiple-blank-fill:before {
    content: "\eb86"
}

.ri-checkbox-multiple-blank-line:before {
    content: "\eb87"
}

.ri-checkbox-multiple-fill:before {
    content: "\eb88"
}

.ri-checkbox-multiple-line:before {
    content: "\eb89"
}

.ri-china-railway-fill:before {
    content: "\eb8a"
}

.ri-china-railway-line:before {
    content: "\eb8b"
}

.ri-chrome-fill:before {
    content: "\eb8c"
}

.ri-chrome-line:before {
    content: "\eb8d"
}

.ri-clapperboard-fill:before {
    content: "\eb8e"
}

.ri-clapperboard-line:before {
    content: "\eb8f"
}

.ri-clipboard-fill:before {
    content: "\eb90"
}

.ri-clipboard-line:before {
    content: "\eb91"
}

.ri-clockwise-2-fill:before {
    content: "\eb92"
}

.ri-clockwise-2-line:before {
    content: "\eb93"
}

.ri-clockwise-fill:before {
    content: "\eb94"
}

.ri-clockwise-line:before {
    content: "\eb95"
}

.ri-close-circle-fill:before {
    content: "\eb96"
}

.ri-close-circle-line:before {
    content: "\eb97"
}

.ri-close-fill:before {
    content: "\eb98"
}

.ri-close-line:before {
    content: "\eb99"
}

.ri-closed-captioning-fill:before {
    content: "\eb9a"
}

.ri-closed-captioning-line:before {
    content: "\eb9b"
}

.ri-cloud-fill:before {
    content: "\eb9c"
}

.ri-cloud-line:before {
    content: "\eb9d"
}

.ri-cloud-off-fill:before {
    content: "\eb9e"
}

.ri-cloud-off-line:before {
    content: "\eb9f"
}

.ri-cloud-windy-fill:before {
    content: "\eba0"
}

.ri-cloud-windy-line:before {
    content: "\eba1"
}

.ri-cloudy-2-fill:before {
    content: "\eba2"
}

.ri-cloudy-2-line:before {
    content: "\eba3"
}

.ri-cloudy-fill:before {
    content: "\eba4"
}

.ri-cloudy-line:before {
    content: "\eba5"
}

.ri-code-box-fill:before {
    content: "\eba6"
}

.ri-code-box-line:before {
    content: "\eba7"
}

.ri-code-fill:before {
    content: "\eba8"
}

.ri-code-line:before {
    content: "\eba9"
}

.ri-code-s-fill:before {
    content: "\ebaa"
}

.ri-code-s-line:before {
    content: "\ebab"
}

.ri-code-s-slash-fill:before {
    content: "\ebac"
}

.ri-code-s-slash-line:before {
    content: "\ebad"
}

.ri-code-view:before {
    content: "\ebae"
}

.ri-codepen-fill:before {
    content: "\ebaf"
}

.ri-codepen-line:before {
    content: "\ebb0"
}

.ri-coin-fill:before {
    content: "\ebb1"
}

.ri-coin-line:before {
    content: "\ebb2"
}

.ri-coins-fill:before {
    content: "\ebb3"
}

.ri-coins-line:before {
    content: "\ebb4"
}

.ri-collage-fill:before {
    content: "\ebb5"
}

.ri-collage-line:before {
    content: "\ebb6"
}

.ri-command-fill:before {
    content: "\ebb7"
}

.ri-command-line:before {
    content: "\ebb8"
}

.ri-community-fill:before {
    content: "\ebb9"
}

.ri-community-line:before {
    content: "\ebba"
}

.ri-compass-2-fill:before {
    content: "\ebbb"
}

.ri-compass-2-line:before {
    content: "\ebbc"
}

.ri-compass-3-fill:before {
    content: "\ebbd"
}

.ri-compass-3-line:before {
    content: "\ebbe"
}

.ri-compass-4-fill:before {
    content: "\ebbf"
}

.ri-compass-4-line:before {
    content: "\ebc0"
}

.ri-compass-discover-fill:before {
    content: "\ebc1"
}

.ri-compass-discover-line:before {
    content: "\ebc2"
}

.ri-compass-fill:before {
    content: "\ebc3"
}

.ri-compass-line:before {
    content: "\ebc4"
}

.ri-compasses-2-fill:before {
    content: "\ebc5"
}

.ri-compasses-2-line:before {
    content: "\ebc6"
}

.ri-compasses-fill:before {
    content: "\ebc7"
}

.ri-compasses-line:before {
    content: "\ebc8"
}

.ri-computer-fill:before {
    content: "\ebc9"
}

.ri-computer-line:before {
    content: "\ebca"
}

.ri-contacts-book-2-fill:before {
    content: "\ebcb"
}

.ri-contacts-book-2-line:before {
    content: "\ebcc"
}

.ri-contacts-book-fill:before {
    content: "\ebcd"
}

.ri-contacts-book-line:before {
    content: "\ebce"
}

.ri-contacts-book-upload-fill:before {
    content: "\ebcf"
}

.ri-contacts-book-upload-line:before {
    content: "\ebd0"
}

.ri-contacts-fill:before {
    content: "\ebd1"
}

.ri-contacts-line:before {
    content: "\ebd2"
}

.ri-contrast-2-fill:before {
    content: "\ebd3"
}

.ri-contrast-2-line:before {
    content: "\ebd4"
}

.ri-contrast-drop-2-fill:before {
    content: "\ebd5"
}

.ri-contrast-drop-2-line:before {
    content: "\ebd6"
}

.ri-contrast-drop-fill:before {
    content: "\ebd7"
}

.ri-contrast-drop-line:before {
    content: "\ebd8"
}

.ri-contrast-fill:before {
    content: "\ebd9"
}

.ri-contrast-line:before {
    content: "\ebda"
}

.ri-copper-coin-fill:before {
    content: "\ebdb"
}

.ri-copper-coin-line:before {
    content: "\ebdc"
}

.ri-copper-diamond-fill:before {
    content: "\ebdd"
}

.ri-copper-diamond-line:before {
    content: "\ebde"
}

.ri-copyleft-fill:before {
    content: "\ebdf"
}

.ri-copyleft-line:before {
    content: "\ebe0"
}

.ri-copyright-fill:before {
    content: "\ebe1"
}

.ri-copyright-line:before {
    content: "\ebe2"
}

.ri-coreos-fill:before {
    content: "\ebe3"
}

.ri-coreos-line:before {
    content: "\ebe4"
}

.ri-coupon-2-fill:before {
    content: "\ebe5"
}

.ri-coupon-2-line:before {
    content: "\ebe6"
}

.ri-coupon-3-fill:before {
    content: "\ebe7"
}

.ri-coupon-3-line:before {
    content: "\ebe8"
}

.ri-coupon-4-fill:before {
    content: "\ebe9"
}

.ri-coupon-4-line:before {
    content: "\ebea"
}

.ri-coupon-5-fill:before {
    content: "\ebeb"
}

.ri-coupon-5-line:before {
    content: "\ebec"
}

.ri-coupon-fill:before {
    content: "\ebed"
}

.ri-coupon-line:before {
    content: "\ebee"
}

.ri-cpu-fill:before {
    content: "\ebef"
}

.ri-cpu-line:before {
    content: "\ebf0"
}

.ri-creative-commons-by-fill:before {
    content: "\ebf1"
}

.ri-creative-commons-by-line:before {
    content: "\ebf2"
}

.ri-creative-commons-fill:before {
    content: "\ebf3"
}

.ri-creative-commons-line:before {
    content: "\ebf4"
}

.ri-creative-commons-nc-fill:before {
    content: "\ebf5"
}

.ri-creative-commons-nc-line:before {
    content: "\ebf6"
}

.ri-creative-commons-nd-fill:before {
    content: "\ebf7"
}

.ri-creative-commons-nd-line:before {
    content: "\ebf8"
}

.ri-creative-commons-sa-fill:before {
    content: "\ebf9"
}

.ri-creative-commons-sa-line:before {
    content: "\ebfa"
}

.ri-creative-commons-zero-fill:before {
    content: "\ebfb"
}

.ri-creative-commons-zero-line:before {
    content: "\ebfc"
}

.ri-criminal-fill:before {
    content: "\ebfd"
}

.ri-criminal-line:before {
    content: "\ebfe"
}

.ri-crop-2-fill:before {
    content: "\ebff"
}

.ri-crop-2-line:before {
    content: "\ec00"
}

.ri-crop-fill:before {
    content: "\ec01"
}

.ri-crop-line:before {
    content: "\ec02"
}

.ri-css3-fill:before {
    content: "\ec03"
}

.ri-css3-line:before {
    content: "\ec04"
}

.ri-cup-fill:before {
    content: "\ec05"
}

.ri-cup-line:before {
    content: "\ec06"
}

.ri-currency-fill:before {
    content: "\ec07"
}

.ri-currency-line:before {
    content: "\ec08"
}

.ri-cursor-fill:before {
    content: "\ec09"
}

.ri-cursor-line:before {
    content: "\ec0a"
}

.ri-customer-service-2-fill:before {
    content: "\ec0b"
}

.ri-customer-service-2-line:before {
    content: "\ec0c"
}

.ri-customer-service-fill:before {
    content: "\ec0d"
}

.ri-customer-service-line:before {
    content: "\ec0e"
}

.ri-dashboard-2-fill:before {
    content: "\ec0f"
}

.ri-dashboard-2-line:before {
    content: "\ec10"
}

.ri-dashboard-3-fill:before {
    content: "\ec11"
}

.ri-dashboard-3-line:before {
    content: "\ec12"
}

.ri-dashboard-fill:before {
    content: "\ec13"
}

.ri-dashboard-line:before {
    content: "\ec14"
}

.ri-database-2-fill:before {
    content: "\ec15"
}

.ri-database-2-line:before {
    content: "\ec16"
}

.ri-database-fill:before {
    content: "\ec17"
}

.ri-database-line:before {
    content: "\ec18"
}

.ri-delete-back-2-fill:before {
    content: "\ec19"
}

.ri-delete-back-2-line:before {
    content: "\ec1a"
}

.ri-delete-back-fill:before {
    content: "\ec1b"
}

.ri-delete-back-line:before {
    content: "\ec1c"
}

.ri-delete-bin-2-fill:before {
    content: "\ec1d"
}

.ri-delete-bin-2-line:before {
    content: "\ec1e"
}

.ri-delete-bin-3-fill:before {
    content: "\ec1f"
}

.ri-delete-bin-3-line:before {
    content: "\ec20"
}

.ri-delete-bin-4-fill:before {
    content: "\ec21"
}

.ri-delete-bin-4-line:before {
    content: "\ec22"
}

.ri-delete-bin-5-fill:before {
    content: "\ec23"
}

.ri-delete-bin-5-line:before {
    content: "\ec24"
}

.ri-delete-bin-6-fill:before {
    content: "\ec25"
}

.ri-delete-bin-6-line:before {
    content: "\ec26"
}

.ri-delete-bin-7-fill:before {
    content: "\ec27"
}

.ri-delete-bin-7-line:before {
    content: "\ec28"
}

.ri-delete-bin-fill:before {
    content: "\ec29"
}

.ri-delete-bin-line:before {
    content: "\ec2a"
}

.ri-delete-column:before {
    content: "\ec2b"
}

.ri-delete-row:before {
    content: "\ec2c"
}

.ri-device-fill:before {
    content: "\ec2d"
}

.ri-device-line:before {
    content: "\ec2e"
}

.ri-device-recover-fill:before {
    content: "\ec2f"
}

.ri-device-recover-line:before {
    content: "\ec30"
}

.ri-dingding-fill:before {
    content: "\ec31"
}

.ri-dingding-line:before {
    content: "\ec32"
}

.ri-direction-fill:before {
    content: "\ec33"
}

.ri-direction-line:before {
    content: "\ec34"
}

.ri-disc-fill:before {
    content: "\ec35"
}

.ri-disc-line:before {
    content: "\ec36"
}

.ri-discord-fill:before {
    content: "\ec37"
}

.ri-discord-line:before {
    content: "\ec38"
}

.ri-discuss-fill:before {
    content: "\ec39"
}

.ri-discuss-line:before {
    content: "\ec3a"
}

.ri-dislike-fill:before {
    content: "\ec3b"
}

.ri-dislike-line:before {
    content: "\ec3c"
}

.ri-disqus-fill:before {
    content: "\ec3d"
}

.ri-disqus-line:before {
    content: "\ec3e"
}

.ri-divide-fill:before {
    content: "\ec3f"
}

.ri-divide-line:before {
    content: "\ec40"
}

.ri-donut-chart-fill:before {
    content: "\ec41"
}

.ri-donut-chart-line:before {
    content: "\ec42"
}

.ri-door-closed-fill:before {
    content: "\ec43"
}

.ri-door-closed-line:before {
    content: "\ec44"
}

.ri-door-fill:before {
    content: "\ec45"
}

.ri-door-line:before {
    content: "\ec46"
}

.ri-door-lock-box-fill:before {
    content: "\ec47"
}

.ri-door-lock-box-line:before {
    content: "\ec48"
}

.ri-door-lock-fill:before {
    content: "\ec49"
}

.ri-door-lock-line:before {
    content: "\ec4a"
}

.ri-door-open-fill:before {
    content: "\ec4b"
}

.ri-door-open-line:before {
    content: "\ec4c"
}

.ri-dossier-fill:before {
    content: "\ec4d"
}

.ri-dossier-line:before {
    content: "\ec4e"
}

.ri-douban-fill:before {
    content: "\ec4f"
}

.ri-douban-line:before {
    content: "\ec50"
}

.ri-double-quotes-l:before {
    content: "\ec51"
}

.ri-double-quotes-r:before {
    content: "\ec52"
}

.ri-download-2-fill:before {
    content: "\ec53"
}

.ri-download-2-line:before {
    content: "\ec54"
}

.ri-download-cloud-2-fill:before {
    content: "\ec55"
}

.ri-download-cloud-2-line:before {
    content: "\ec56"
}

.ri-download-cloud-fill:before {
    content: "\ec57"
}

.ri-download-cloud-line:before {
    content: "\ec58"
}

.ri-download-fill:before {
    content: "\ec59"
}

.ri-download-line:before {
    content: "\ec5a"
}

.ri-draft-fill:before {
    content: "\ec5b"
}

.ri-draft-line:before {
    content: "\ec5c"
}

.ri-drag-drop-fill:before {
    content: "\ec5d"
}

.ri-drag-drop-line:before {
    content: "\ec5e"
}

.ri-drag-move-2-fill:before {
    content: "\ec5f"
}

.ri-drag-move-2-line:before {
    content: "\ec60"
}

.ri-drag-move-fill:before {
    content: "\ec61"
}

.ri-drag-move-line:before {
    content: "\ec62"
}

.ri-dribbble-fill:before {
    content: "\ec63"
}

.ri-dribbble-line:before {
    content: "\ec64"
}

.ri-drive-fill:before {
    content: "\ec65"
}

.ri-drive-line:before {
    content: "\ec66"
}

.ri-drizzle-fill:before {
    content: "\ec67"
}

.ri-drizzle-line:before {
    content: "\ec68"
}

.ri-drop-fill:before {
    content: "\ec69"
}

.ri-drop-line:before {
    content: "\ec6a"
}

.ri-dropbox-fill:before {
    content: "\ec6b"
}

.ri-dropbox-line:before {
    content: "\ec6c"
}

.ri-dual-sim-1-fill:before {
    content: "\ec6d"
}

.ri-dual-sim-1-line:before {
    content: "\ec6e"
}

.ri-dual-sim-2-fill:before {
    content: "\ec6f"
}

.ri-dual-sim-2-line:before {
    content: "\ec70"
}

.ri-dv-fill:before {
    content: "\ec71"
}

.ri-dv-line:before {
    content: "\ec72"
}

.ri-dvd-fill:before {
    content: "\ec73"
}

.ri-dvd-line:before {
    content: "\ec74"
}

.ri-e-bike-2-fill:before {
    content: "\ec75"
}

.ri-e-bike-2-line:before {
    content: "\ec76"
}

.ri-e-bike-fill:before {
    content: "\ec77"
}

.ri-e-bike-line:before {
    content: "\ec78"
}

.ri-earth-fill:before {
    content: "\ec79"
}

.ri-earth-line:before {
    content: "\ec7a"
}

.ri-earthquake-fill:before {
    content: "\ec7b"
}

.ri-earthquake-line:before {
    content: "\ec7c"
}

.ri-edge-fill:before {
    content: "\ec7d"
}

.ri-edge-line:before {
    content: "\ec7e"
}

.ri-edit-2-fill:before {
    content: "\ec7f"
}

.ri-edit-2-line:before {
    content: "\ec80"
}

.ri-edit-box-fill:before {
    content: "\ec81"
}

.ri-edit-box-line:before {
    content: "\ec82"
}

.ri-edit-circle-fill:before {
    content: "\ec83"
}

.ri-edit-circle-line:before {
    content: "\ec84"
}

.ri-edit-fill:before {
    content: "\ec85"
}

.ri-edit-line:before {
    content: "\ec86"
}

.ri-eject-fill:before {
    content: "\ec87"
}

.ri-eject-line:before {
    content: "\ec88"
}

.ri-emotion-2-fill:before {
    content: "\ec89"
}

.ri-emotion-2-line:before {
    content: "\ec8a"
}

.ri-emotion-fill:before {
    content: "\ec8b"
}

.ri-emotion-happy-fill:before {
    content: "\ec8c"
}

.ri-emotion-happy-line:before {
    content: "\ec8d"
}

.ri-emotion-laugh-fill:before {
    content: "\ec8e"
}

.ri-emotion-laugh-line:before {
    content: "\ec8f"
}

.ri-emotion-line:before {
    content: "\ec90"
}

.ri-emotion-normal-fill:before {
    content: "\ec91"
}

.ri-emotion-normal-line:before {
    content: "\ec92"
}

.ri-emotion-sad-fill:before {
    content: "\ec93"
}

.ri-emotion-sad-line:before {
    content: "\ec94"
}

.ri-emotion-unhappy-fill:before {
    content: "\ec95"
}

.ri-emotion-unhappy-line:before {
    content: "\ec96"
}

.ri-empathize-fill:before {
    content: "\ec97"
}

.ri-empathize-line:before {
    content: "\ec98"
}

.ri-emphasis-cn:before {
    content: "\ec99"
}

.ri-emphasis:before {
    content: "\ec9a"
}

.ri-english-input:before {
    content: "\ec9b"
}

.ri-equalizer-fill:before {
    content: "\ec9c"
}

.ri-equalizer-line:before {
    content: "\ec9d"
}

.ri-eraser-fill:before {
    content: "\ec9e"
}

.ri-eraser-line:before {
    content: "\ec9f"
}

.ri-error-warning-fill:before {
    content: "\eca0"
}

.ri-error-warning-line:before {
    content: "\eca1"
}

.ri-evernote-fill:before {
    content: "\eca2"
}

.ri-evernote-line:before {
    content: "\eca3"
}

.ri-exchange-box-fill:before {
    content: "\eca4"
}

.ri-exchange-box-line:before {
    content: "\eca5"
}

.ri-exchange-cny-fill:before {
    content: "\eca6"
}

.ri-exchange-cny-line:before {
    content: "\eca7"
}

.ri-exchange-dollar-fill:before {
    content: "\eca8"
}

.ri-exchange-dollar-line:before {
    content: "\eca9"
}

.ri-exchange-fill:before {
    content: "\ecaa"
}

.ri-exchange-funds-fill:before {
    content: "\ecab"
}

.ri-exchange-funds-line:before {
    content: "\ecac"
}

.ri-exchange-line:before {
    content: "\ecad"
}

.ri-external-link-fill:before {
    content: "\ecae"
}

.ri-external-link-line:before {
    content: "\ecaf"
}

.ri-eye-2-fill:before {
    content: "\ecb0"
}

.ri-eye-2-line:before {
    content: "\ecb1"
}

.ri-eye-close-fill:before {
    content: "\ecb2"
}

.ri-eye-close-line:before {
    content: "\ecb3"
}

.ri-eye-fill:before {
    content: "\ecb4"
}

.ri-eye-line:before {
    content: "\ecb5"
}

.ri-eye-off-fill:before {
    content: "\ecb6"
}

.ri-eye-off-line:before {
    content: "\ecb7"
}

.ri-facebook-box-fill:before {
    content: "\ecb8"
}

.ri-facebook-box-line:before {
    content: "\ecb9"
}

.ri-facebook-circle-fill:before {
    content: "\ecba"
}

.ri-facebook-circle-line:before {
    content: "\ecbb"
}

.ri-facebook-fill:before {
    content: "\ecbc"
}

.ri-facebook-line:before {
    content: "\ecbd"
}

.ri-fahrenheit-fill:before {
    content: "\ecbe"
}

.ri-fahrenheit-line:before {
    content: "\ecbf"
}

.ri-feedback-fill:before {
    content: "\ecc0"
}

.ri-feedback-line:before {
    content: "\ecc1"
}

.ri-file-2-fill:before {
    content: "\ecc2"
}

.ri-file-2-line:before {
    content: "\ecc3"
}

.ri-file-3-fill:before {
    content: "\ecc4"
}

.ri-file-3-line:before {
    content: "\ecc5"
}

.ri-file-4-fill:before {
    content: "\ecc6"
}

.ri-file-4-line:before {
    content: "\ecc7"
}

.ri-file-add-fill:before {
    content: "\ecc8"
}

.ri-file-add-line:before {
    content: "\ecc9"
}

.ri-file-chart-2-fill:before {
    content: "\ecca"
}

.ri-file-chart-2-line:before {
    content: "\eccb"
}

.ri-file-chart-fill:before {
    content: "\eccc"
}

.ri-file-chart-line:before {
    content: "\eccd"
}

.ri-file-cloud-fill:before {
    content: "\ecce"
}

.ri-file-cloud-line:before {
    content: "\eccf"
}

.ri-file-code-fill:before {
    content: "\ecd0"
}

.ri-file-code-line:before {
    content: "\ecd1"
}

.ri-file-copy-2-fill:before {
    content: "\ecd2"
}

.ri-file-copy-2-line:before {
    content: "\ecd3"
}

.ri-file-copy-fill:before {
    content: "\ecd4"
}

.ri-file-copy-line:before {
    content: "\ecd5"
}

.ri-file-damage-fill:before {
    content: "\ecd6"
}

.ri-file-damage-line:before {
    content: "\ecd7"
}

.ri-file-download-fill:before {
    content: "\ecd8"
}

.ri-file-download-line:before {
    content: "\ecd9"
}

.ri-file-edit-fill:before {
    content: "\ecda"
}

.ri-file-edit-line:before {
    content: "\ecdb"
}

.ri-file-excel-2-fill:before {
    content: "\ecdc"
}

.ri-file-excel-2-line:before {
    content: "\ecdd"
}

.ri-file-excel-fill:before {
    content: "\ecde"
}

.ri-file-excel-line:before {
    content: "\ecdf"
}

.ri-file-fill:before {
    content: "\ece0"
}

.ri-file-forbid-fill:before {
    content: "\ece1"
}

.ri-file-forbid-line:before {
    content: "\ece2"
}

.ri-file-gif-fill:before {
    content: "\ece3"
}

.ri-file-gif-line:before {
    content: "\ece4"
}

.ri-file-history-fill:before {
    content: "\ece5"
}

.ri-file-history-line:before {
    content: "\ece6"
}

.ri-file-hwp-fill:before {
    content: "\ece7"
}

.ri-file-hwp-line:before {
    content: "\ece8"
}

.ri-file-info-fill:before {
    content: "\ece9"
}

.ri-file-info-line:before {
    content: "\ecea"
}

.ri-file-line:before {
    content: "\eceb"
}

.ri-file-list-2-fill:before {
    content: "\ecec"
}

.ri-file-list-2-line:before {
    content: "\eced"
}

.ri-file-list-3-fill:before {
    content: "\ecee"
}

.ri-file-list-3-line:before {
    content: "\ecef"
}

.ri-file-list-fill:before {
    content: "\ecf0"
}

.ri-file-list-line:before {
    content: "\ecf1"
}

.ri-file-lock-fill:before {
    content: "\ecf2"
}

.ri-file-lock-line:before {
    content: "\ecf3"
}

.ri-file-mark-fill:before {
    content: "\ecf4"
}

.ri-file-mark-line:before {
    content: "\ecf5"
}

.ri-file-music-fill:before {
    content: "\ecf6"
}

.ri-file-music-line:before {
    content: "\ecf7"
}

.ri-file-paper-2-fill:before {
    content: "\ecf8"
}

.ri-file-paper-2-line:before {
    content: "\ecf9"
}

.ri-file-paper-fill:before {
    content: "\ecfa"
}

.ri-file-paper-line:before {
    content: "\ecfb"
}

.ri-file-pdf-fill:before {
    content: "\ecfc"
}

.ri-file-pdf-line:before {
    content: "\ecfd"
}

.ri-file-ppt-2-fill:before {
    content: "\ecfe"
}

.ri-file-ppt-2-line:before {
    content: "\ecff"
}

.ri-file-ppt-fill:before {
    content: "\ed00"
}

.ri-file-ppt-line:before {
    content: "\ed01"
}

.ri-file-reduce-fill:before {
    content: "\ed02"
}

.ri-file-reduce-line:before {
    content: "\ed03"
}

.ri-file-search-fill:before {
    content: "\ed04"
}

.ri-file-search-line:before {
    content: "\ed05"
}

.ri-file-settings-fill:before {
    content: "\ed06"
}

.ri-file-settings-line:before {
    content: "\ed07"
}

.ri-file-shield-2-fill:before {
    content: "\ed08"
}

.ri-file-shield-2-line:before {
    content: "\ed09"
}

.ri-file-shield-fill:before {
    content: "\ed0a"
}

.ri-file-shield-line:before {
    content: "\ed0b"
}

.ri-file-shred-fill:before {
    content: "\ed0c"
}

.ri-file-shred-line:before {
    content: "\ed0d"
}

.ri-file-text-fill:before {
    content: "\ed0e"
}

.ri-file-text-line:before {
    content: "\ed0f"
}

.ri-file-transfer-fill:before {
    content: "\ed10"
}

.ri-file-transfer-line:before {
    content: "\ed11"
}

.ri-file-unknow-fill:before {
    content: "\ed12"
}

.ri-file-unknow-line:before {
    content: "\ed13"
}

.ri-file-upload-fill:before {
    content: "\ed14"
}

.ri-file-upload-line:before {
    content: "\ed15"
}

.ri-file-user-fill:before {
    content: "\ed16"
}

.ri-file-user-line:before {
    content: "\ed17"
}

.ri-file-warning-fill:before {
    content: "\ed18"
}

.ri-file-warning-line:before {
    content: "\ed19"
}

.ri-file-word-2-fill:before {
    content: "\ed1a"
}

.ri-file-word-2-line:before {
    content: "\ed1b"
}

.ri-file-word-fill:before {
    content: "\ed1c"
}

.ri-file-word-line:before {
    content: "\ed1d"
}

.ri-file-zip-fill:before {
    content: "\ed1e"
}

.ri-file-zip-line:before {
    content: "\ed1f"
}

.ri-film-fill:before {
    content: "\ed20"
}

.ri-film-line:before {
    content: "\ed21"
}

.ri-filter-2-fill:before {
    content: "\ed22"
}

.ri-filter-2-line:before {
    content: "\ed23"
}

.ri-filter-3-fill:before {
    content: "\ed24"
}

.ri-filter-3-line:before {
    content: "\ed25"
}

.ri-filter-fill:before {
    content: "\ed26"
}

.ri-filter-line:before {
    content: "\ed27"
}

.ri-filter-off-fill:before {
    content: "\ed28"
}

.ri-filter-off-line:before {
    content: "\ed29"
}

.ri-find-replace-fill:before {
    content: "\ed2a"
}

.ri-find-replace-line:before {
    content: "\ed2b"
}

.ri-finder-fill:before {
    content: "\ed2c"
}

.ri-finder-line:before {
    content: "\ed2d"
}

.ri-fingerprint-2-fill:before {
    content: "\ed2e"
}

.ri-fingerprint-2-line:before {
    content: "\ed2f"
}

.ri-fingerprint-fill:before {
    content: "\ed30"
}

.ri-fingerprint-line:before {
    content: "\ed31"
}

.ri-fire-fill:before {
    content: "\ed32"
}

.ri-fire-line:before {
    content: "\ed33"
}

.ri-firefox-fill:before {
    content: "\ed34"
}

.ri-firefox-line:before {
    content: "\ed35"
}

.ri-first-aid-kit-fill:before {
    content: "\ed36"
}

.ri-first-aid-kit-line:before {
    content: "\ed37"
}

.ri-flag-2-fill:before {
    content: "\ed38"
}

.ri-flag-2-line:before {
    content: "\ed39"
}

.ri-flag-fill:before {
    content: "\ed3a"
}

.ri-flag-line:before {
    content: "\ed3b"
}

.ri-flashlight-fill:before {
    content: "\ed3c"
}

.ri-flashlight-line:before {
    content: "\ed3d"
}

.ri-flask-fill:before {
    content: "\ed3e"
}

.ri-flask-line:before {
    content: "\ed3f"
}

.ri-flight-land-fill:before {
    content: "\ed40"
}

.ri-flight-land-line:before {
    content: "\ed41"
}

.ri-flight-takeoff-fill:before {
    content: "\ed42"
}

.ri-flight-takeoff-line:before {
    content: "\ed43"
}

.ri-flood-fill:before {
    content: "\ed44"
}

.ri-flood-line:before {
    content: "\ed45"
}

.ri-flow-chart:before {
    content: "\ed46"
}

.ri-flutter-fill:before {
    content: "\ed47"
}

.ri-flutter-line:before {
    content: "\ed48"
}

.ri-focus-2-fill:before {
    content: "\ed49"
}

.ri-focus-2-line:before {
    content: "\ed4a"
}

.ri-focus-3-fill:before {
    content: "\ed4b"
}

.ri-focus-3-line:before {
    content: "\ed4c"
}

.ri-focus-fill:before {
    content: "\ed4d"
}

.ri-focus-line:before {
    content: "\ed4e"
}

.ri-foggy-fill:before {
    content: "\ed4f"
}

.ri-foggy-line:before {
    content: "\ed50"
}

.ri-folder-2-fill:before {
    content: "\ed51"
}

.ri-folder-2-line:before {
    content: "\ed52"
}

.ri-folder-3-fill:before {
    content: "\ed53"
}

.ri-folder-3-line:before {
    content: "\ed54"
}

.ri-folder-4-fill:before {
    content: "\ed55"
}

.ri-folder-4-line:before {
    content: "\ed56"
}

.ri-folder-5-fill:before {
    content: "\ed57"
}

.ri-folder-5-line:before {
    content: "\ed58"
}

.ri-folder-add-fill:before {
    content: "\ed59"
}

.ri-folder-add-line:before {
    content: "\ed5a"
}

.ri-folder-chart-2-fill:before {
    content: "\ed5b"
}

.ri-folder-chart-2-line:before {
    content: "\ed5c"
}

.ri-folder-chart-fill:before {
    content: "\ed5d"
}

.ri-folder-chart-line:before {
    content: "\ed5e"
}

.ri-folder-download-fill:before {
    content: "\ed5f"
}

.ri-folder-download-line:before {
    content: "\ed60"
}

.ri-folder-fill:before {
    content: "\ed61"
}

.ri-folder-forbid-fill:before {
    content: "\ed62"
}

.ri-folder-forbid-line:before {
    content: "\ed63"
}

.ri-folder-history-fill:before {
    content: "\ed64"
}

.ri-folder-history-line:before {
    content: "\ed65"
}

.ri-folder-info-fill:before {
    content: "\ed66"
}

.ri-folder-info-line:before {
    content: "\ed67"
}

.ri-folder-keyhole-fill:before {
    content: "\ed68"
}

.ri-folder-keyhole-line:before {
    content: "\ed69"
}

.ri-folder-line:before {
    content: "\ed6a"
}

.ri-folder-lock-fill:before {
    content: "\ed6b"
}

.ri-folder-lock-line:before {
    content: "\ed6c"
}

.ri-folder-music-fill:before {
    content: "\ed6d"
}

.ri-folder-music-line:before {
    content: "\ed6e"
}

.ri-folder-open-fill:before {
    content: "\ed6f"
}

.ri-folder-open-line:before {
    content: "\ed70"
}

.ri-folder-received-fill:before {
    content: "\ed71"
}

.ri-folder-received-line:before {
    content: "\ed72"
}

.ri-folder-reduce-fill:before {
    content: "\ed73"
}

.ri-folder-reduce-line:before {
    content: "\ed74"
}

.ri-folder-settings-fill:before {
    content: "\ed75"
}

.ri-folder-settings-line:before {
    content: "\ed76"
}

.ri-folder-shared-fill:before {
    content: "\ed77"
}

.ri-folder-shared-line:before {
    content: "\ed78"
}

.ri-folder-shield-2-fill:before {
    content: "\ed79"
}

.ri-folder-shield-2-line:before {
    content: "\ed7a"
}

.ri-folder-shield-fill:before {
    content: "\ed7b"
}

.ri-folder-shield-line:before {
    content: "\ed7c"
}

.ri-folder-transfer-fill:before {
    content: "\ed7d"
}

.ri-folder-transfer-line:before {
    content: "\ed7e"
}

.ri-folder-unknow-fill:before {
    content: "\ed7f"
}

.ri-folder-unknow-line:before {
    content: "\ed80"
}

.ri-folder-upload-fill:before {
    content: "\ed81"
}

.ri-folder-upload-line:before {
    content: "\ed82"
}

.ri-folder-user-fill:before {
    content: "\ed83"
}

.ri-folder-user-line:before {
    content: "\ed84"
}

.ri-folder-warning-fill:before {
    content: "\ed85"
}

.ri-folder-warning-line:before {
    content: "\ed86"
}

.ri-folder-zip-fill:before {
    content: "\ed87"
}

.ri-folder-zip-line:before {
    content: "\ed88"
}

.ri-folders-fill:before {
    content: "\ed89"
}

.ri-folders-line:before {
    content: "\ed8a"
}

.ri-font-color:before {
    content: "\ed8b"
}

.ri-font-size-2:before {
    content: "\ed8c"
}

.ri-font-size:before {
    content: "\ed8d"
}

.ri-football-fill:before {
    content: "\ed8e"
}

.ri-football-line:before {
    content: "\ed8f"
}

.ri-footprint-fill:before {
    content: "\ed90"
}

.ri-footprint-line:before {
    content: "\ed91"
}

.ri-forbid-2-fill:before {
    content: "\ed92"
}

.ri-forbid-2-line:before {
    content: "\ed93"
}

.ri-forbid-fill:before {
    content: "\ed94"
}

.ri-forbid-line:before {
    content: "\ed95"
}

.ri-format-clear:before {
    content: "\ed96"
}

.ri-fridge-fill:before {
    content: "\ed97"
}

.ri-fridge-line:before {
    content: "\ed98"
}

.ri-fullscreen-exit-fill:before {
    content: "\ed99"
}

.ri-fullscreen-exit-line:before {
    content: "\ed9a"
}

.ri-fullscreen-fill:before {
    content: "\ed9b"
}

.ri-fullscreen-line:before {
    content: "\ed9c"
}

.ri-function-fill:before {
    content: "\ed9d"
}

.ri-function-line:before {
    content: "\ed9e"
}

.ri-functions:before {
    content: "\ed9f"
}

.ri-funds-box-fill:before {
    content: "\eda0"
}

.ri-funds-box-line:before {
    content: "\eda1"
}

.ri-funds-fill:before {
    content: "\eda2"
}

.ri-funds-line:before {
    content: "\eda3"
}

.ri-gallery-fill:before {
    content: "\eda4"
}

.ri-gallery-line:before {
    content: "\eda5"
}

.ri-gallery-upload-fill:before {
    content: "\eda6"
}

.ri-gallery-upload-line:before {
    content: "\eda7"
}

.ri-game-fill:before {
    content: "\eda8"
}

.ri-game-line:before {
    content: "\eda9"
}

.ri-gamepad-fill:before {
    content: "\edaa"
}

.ri-gamepad-line:before {
    content: "\edab"
}

.ri-gas-station-fill:before {
    content: "\edac"
}

.ri-gas-station-line:before {
    content: "\edad"
}

.ri-gatsby-fill:before {
    content: "\edae"
}

.ri-gatsby-line:before {
    content: "\edaf"
}

.ri-genderless-fill:before {
    content: "\edb0"
}

.ri-genderless-line:before {
    content: "\edb1"
}

.ri-ghost-2-fill:before {
    content: "\edb2"
}

.ri-ghost-2-line:before {
    content: "\edb3"
}

.ri-ghost-fill:before {
    content: "\edb4"
}

.ri-ghost-line:before {
    content: "\edb5"
}

.ri-ghost-smile-fill:before {
    content: "\edb6"
}

.ri-ghost-smile-line:before {
    content: "\edb7"
}

.ri-gift-2-fill:before {
    content: "\edb8"
}

.ri-gift-2-line:before {
    content: "\edb9"
}

.ri-gift-fill:before {
    content: "\edba"
}

.ri-gift-line:before {
    content: "\edbb"
}

.ri-git-branch-fill:before {
    content: "\edbc"
}

.ri-git-branch-line:before {
    content: "\edbd"
}

.ri-git-commit-fill:before {
    content: "\edbe"
}

.ri-git-commit-line:before {
    content: "\edbf"
}

.ri-git-merge-fill:before {
    content: "\edc0"
}

.ri-git-merge-line:before {
    content: "\edc1"
}

.ri-git-pull-request-fill:before {
    content: "\edc2"
}

.ri-git-pull-request-line:before {
    content: "\edc3"
}

.ri-git-repository-commits-fill:before {
    content: "\edc4"
}

.ri-git-repository-commits-line:before {
    content: "\edc5"
}

.ri-git-repository-fill:before {
    content: "\edc6"
}

.ri-git-repository-line:before {
    content: "\edc7"
}

.ri-git-repository-private-fill:before {
    content: "\edc8"
}

.ri-git-repository-private-line:before {
    content: "\edc9"
}

.ri-github-fill:before {
    content: "\edca"
}

.ri-github-line:before {
    content: "\edcb"
}

.ri-gitlab-fill:before {
    content: "\edcc"
}

.ri-gitlab-line:before {
    content: "\edcd"
}

.ri-global-fill:before {
    content: "\edce"
}

.ri-global-line:before {
    content: "\edcf"
}

.ri-globe-fill:before {
    content: "\edd0"
}

.ri-globe-line:before {
    content: "\edd1"
}

.ri-goblet-fill:before {
    content: "\edd2"
}

.ri-goblet-line:before {
    content: "\edd3"
}

.ri-google-fill:before {
    content: "\edd4"
}

.ri-google-line:before {
    content: "\edd5"
}

.ri-google-play-fill:before {
    content: "\edd6"
}

.ri-google-play-line:before {
    content: "\edd7"
}

.ri-government-fill:before {
    content: "\edd8"
}

.ri-government-line:before {
    content: "\edd9"
}

.ri-gps-fill:before {
    content: "\edda"
}

.ri-gps-line:before {
    content: "\eddb"
}

.ri-gradienter-fill:before {
    content: "\eddc"
}

.ri-gradienter-line:before {
    content: "\eddd"
}

.ri-grid-fill:before {
    content: "\edde"
}

.ri-grid-line:before {
    content: "\eddf"
}

.ri-group-2-fill:before {
    content: "\ede0"
}

.ri-group-2-line:before {
    content: "\ede1"
}

.ri-group-fill:before {
    content: "\ede2"
}

.ri-group-line:before {
    content: "\ede3"
}

.ri-guide-fill:before {
    content: "\ede4"
}

.ri-guide-line:before {
    content: "\ede5"
}

.ri-h-1:before {
    content: "\ede6"
}

.ri-h-2:before {
    content: "\ede7"
}

.ri-h-3:before {
    content: "\ede8"
}

.ri-h-4:before {
    content: "\ede9"
}

.ri-h-5:before {
    content: "\edea"
}

.ri-h-6:before {
    content: "\edeb"
}

.ri-hail-fill:before {
    content: "\edec"
}

.ri-hail-line:before {
    content: "\eded"
}

.ri-hammer-fill:before {
    content: "\edee"
}

.ri-hammer-line:before {
    content: "\edef"
}

.ri-hand-coin-fill:before {
    content: "\edf0"
}

.ri-hand-coin-line:before {
    content: "\edf1"
}

.ri-hand-heart-fill:before {
    content: "\edf2"
}

.ri-hand-heart-line:before {
    content: "\edf3"
}

.ri-hand-sanitizer-fill:before {
    content: "\edf4"
}

.ri-hand-sanitizer-line:before {
    content: "\edf5"
}

.ri-handbag-fill:before {
    content: "\edf6"
}

.ri-handbag-line:before {
    content: "\edf7"
}

.ri-hard-drive-2-fill:before {
    content: "\edf8"
}

.ri-hard-drive-2-line:before {
    content: "\edf9"
}

.ri-hard-drive-fill:before {
    content: "\edfa"
}

.ri-hard-drive-line:before {
    content: "\edfb"
}

.ri-hashtag:before {
    content: "\edfc"
}

.ri-haze-2-fill:before {
    content: "\edfd"
}

.ri-haze-2-line:before {
    content: "\edfe"
}

.ri-haze-fill:before {
    content: "\edff"
}

.ri-haze-line:before {
    content: "\ee00"
}

.ri-hd-fill:before {
    content: "\ee01"
}

.ri-hd-line:before {
    content: "\ee02"
}

.ri-heading:before {
    content: "\ee03"
}

.ri-headphone-fill:before {
    content: "\ee04"
}

.ri-headphone-line:before {
    content: "\ee05"
}

.ri-health-book-fill:before {
    content: "\ee06"
}

.ri-health-book-line:before {
    content: "\ee07"
}

.ri-heart-2-fill:before {
    content: "\ee08"
}

.ri-heart-2-line:before {
    content: "\ee09"
}

.ri-heart-3-fill:before {
    content: "\ee0a"
}

.ri-heart-3-line:before {
    content: "\ee0b"
}

.ri-heart-add-fill:before {
    content: "\ee0c"
}

.ri-heart-add-line:before {
    content: "\ee0d"
}

.ri-heart-fill:before {
    content: "\ee0e"
}

.ri-heart-line:before {
    content: "\ee0f"
}

.ri-heart-pulse-fill:before {
    content: "\ee10"
}

.ri-heart-pulse-line:before {
    content: "\ee11"
}

.ri-hearts-fill:before {
    content: "\ee12"
}

.ri-hearts-line:before {
    content: "\ee13"
}

.ri-heavy-showers-fill:before {
    content: "\ee14"
}

.ri-heavy-showers-line:before {
    content: "\ee15"
}

.ri-history-fill:before {
    content: "\ee16"
}

.ri-history-line:before {
    content: "\ee17"
}

.ri-home-2-fill:before {
    content: "\ee18"
}

.ri-home-2-line:before {
    content: "\ee19"
}

.ri-home-3-fill:before {
    content: "\ee1a"
}

.ri-home-3-line:before {
    content: "\ee1b"
}

.ri-home-4-fill:before {
    content: "\ee1c"
}

.ri-home-4-line:before {
    content: "\ee1d"
}

.ri-home-5-fill:before {
    content: "\ee1e"
}

.ri-home-5-line:before {
    content: "\ee1f"
}

.ri-home-6-fill:before {
    content: "\ee20"
}

.ri-home-6-line:before {
    content: "\ee21"
}

.ri-home-7-fill:before {
    content: "\ee22"
}

.ri-home-7-line:before {
    content: "\ee23"
}

.ri-home-8-fill:before {
    content: "\ee24"
}

.ri-home-8-line:before {
    content: "\ee25"
}

.ri-home-fill:before {
    content: "\ee26"
}

.ri-home-gear-fill:before {
    content: "\ee27"
}

.ri-home-gear-line:before {
    content: "\ee28"
}

.ri-home-heart-fill:before {
    content: "\ee29"
}

.ri-home-heart-line:before {
    content: "\ee2a"
}

.ri-home-line:before {
    content: "\ee2b"
}

.ri-home-smile-2-fill:before {
    content: "\ee2c"
}

.ri-home-smile-2-line:before {
    content: "\ee2d"
}

.ri-home-smile-fill:before {
    content: "\ee2e"
}

.ri-home-smile-line:before {
    content: "\ee2f"
}

.ri-home-wifi-fill:before {
    content: "\ee30"
}

.ri-home-wifi-line:before {
    content: "\ee31"
}

.ri-honor-of-kings-fill:before {
    content: "\ee32"
}

.ri-honor-of-kings-line:before {
    content: "\ee33"
}

.ri-honour-fill:before {
    content: "\ee34"
}

.ri-honour-line:before {
    content: "\ee35"
}

.ri-hospital-fill:before {
    content: "\ee36"
}

.ri-hospital-line:before {
    content: "\ee37"
}

.ri-hotel-bed-fill:before {
    content: "\ee38"
}

.ri-hotel-bed-line:before {
    content: "\ee39"
}

.ri-hotel-fill:before {
    content: "\ee3a"
}

.ri-hotel-line:before {
    content: "\ee3b"
}

.ri-hotspot-fill:before {
    content: "\ee3c"
}

.ri-hotspot-line:before {
    content: "\ee3d"
}

.ri-hq-fill:before {
    content: "\ee3e"
}

.ri-hq-line:before {
    content: "\ee3f"
}

.ri-html5-fill:before {
    content: "\ee40"
}

.ri-html5-line:before {
    content: "\ee41"
}

.ri-ie-fill:before {
    content: "\ee42"
}

.ri-ie-line:before {
    content: "\ee43"
}

.ri-image-2-fill:before {
    content: "\ee44"
}

.ri-image-2-line:before {
    content: "\ee45"
}

.ri-image-add-fill:before {
    content: "\ee46"
}

.ri-image-add-line:before {
    content: "\ee47"
}

.ri-image-edit-fill:before {
    content: "\ee48"
}

.ri-image-edit-line:before {
    content: "\ee49"
}

.ri-image-fill:before {
    content: "\ee4a"
}

.ri-image-line:before {
    content: "\ee4b"
}

.ri-inbox-archive-fill:before {
    content: "\ee4c"
}

.ri-inbox-archive-line:before {
    content: "\ee4d"
}

.ri-inbox-fill:before {
    content: "\ee4e"
}

.ri-inbox-line:before {
    content: "\ee4f"
}

.ri-inbox-unarchive-fill:before {
    content: "\ee50"
}

.ri-inbox-unarchive-line:before {
    content: "\ee51"
}

.ri-increase-decrease-fill:before {
    content: "\ee52"
}

.ri-increase-decrease-line:before {
    content: "\ee53"
}

.ri-indent-decrease:before {
    content: "\ee54"
}

.ri-indent-increase:before {
    content: "\ee55"
}

.ri-indeterminate-circle-fill:before {
    content: "\ee56"
}

.ri-indeterminate-circle-line:before {
    content: "\ee57"
}

.ri-information-fill:before {
    content: "\ee58"
}

.ri-information-line:before {
    content: "\ee59"
}

.ri-infrared-thermometer-fill:before {
    content: "\ee5a"
}

.ri-infrared-thermometer-line:before {
    content: "\ee5b"
}

.ri-ink-bottle-fill:before {
    content: "\ee5c"
}

.ri-ink-bottle-line:before {
    content: "\ee5d"
}

.ri-input-cursor-move:before {
    content: "\ee5e"
}

.ri-input-method-fill:before {
    content: "\ee5f"
}

.ri-input-method-line:before {
    content: "\ee60"
}

.ri-insert-column-left:before {
    content: "\ee61"
}

.ri-insert-column-right:before {
    content: "\ee62"
}

.ri-insert-row-bottom:before {
    content: "\ee63"
}

.ri-insert-row-top:before {
    content: "\ee64"
}

.ri-instagram-fill:before {
    content: "\ee65"
}

.ri-instagram-line:before {
    content: "\ee66"
}

.ri-install-fill:before {
    content: "\ee67"
}

.ri-install-line:before {
    content: "\ee68"
}

.ri-invision-fill:before {
    content: "\ee69"
}

.ri-invision-line:before {
    content: "\ee6a"
}

.ri-italic:before {
    content: "\ee6b"
}

.ri-kakao-talk-fill:before {
    content: "\ee6c"
}

.ri-kakao-talk-line:before {
    content: "\ee6d"
}

.ri-key-2-fill:before {
    content: "\ee6e"
}

.ri-key-2-line:before {
    content: "\ee6f"
}

.ri-key-fill:before {
    content: "\ee70"
}

.ri-key-line:before {
    content: "\ee71"
}

.ri-keyboard-box-fill:before {
    content: "\ee72"
}

.ri-keyboard-box-line:before {
    content: "\ee73"
}

.ri-keyboard-fill:before {
    content: "\ee74"
}

.ri-keyboard-line:before {
    content: "\ee75"
}

.ri-keynote-fill:before {
    content: "\ee76"
}

.ri-keynote-line:before {
    content: "\ee77"
}

.ri-knife-blood-fill:before {
    content: "\ee78"
}

.ri-knife-blood-line:before {
    content: "\ee79"
}

.ri-knife-fill:before {
    content: "\ee7a"
}

.ri-knife-line:before {
    content: "\ee7b"
}

.ri-landscape-fill:before {
    content: "\ee7c"
}

.ri-landscape-line:before {
    content: "\ee7d"
}

.ri-layout-2-fill:before {
    content: "\ee7e"
}

.ri-layout-2-line:before {
    content: "\ee7f"
}

.ri-layout-3-fill:before {
    content: "\ee80"
}

.ri-layout-3-line:before {
    content: "\ee81"
}

.ri-layout-4-fill:before {
    content: "\ee82"
}

.ri-layout-4-line:before {
    content: "\ee83"
}

.ri-layout-5-fill:before {
    content: "\ee84"
}

.ri-layout-5-line:before {
    content: "\ee85"
}

.ri-layout-6-fill:before {
    content: "\ee86"
}

.ri-layout-6-line:before {
    content: "\ee87"
}

.ri-layout-bottom-2-fill:before {
    content: "\ee88"
}

.ri-layout-bottom-2-line:before {
    content: "\ee89"
}

.ri-layout-bottom-fill:before {
    content: "\ee8a"
}

.ri-layout-bottom-line:before {
    content: "\ee8b"
}

.ri-layout-column-fill:before {
    content: "\ee8c"
}

.ri-layout-column-line:before {
    content: "\ee8d"
}

.ri-layout-fill:before {
    content: "\ee8e"
}

.ri-layout-grid-fill:before {
    content: "\ee8f"
}

.ri-layout-grid-line:before {
    content: "\ee90"
}

.ri-layout-left-2-fill:before {
    content: "\ee91"
}

.ri-layout-left-2-line:before {
    content: "\ee92"
}

.ri-layout-left-fill:before {
    content: "\ee93"
}

.ri-layout-left-line:before {
    content: "\ee94"
}

.ri-layout-line:before {
    content: "\ee95"
}

.ri-layout-masonry-fill:before {
    content: "\ee96"
}

.ri-layout-masonry-line:before {
    content: "\ee97"
}

.ri-layout-right-2-fill:before {
    content: "\ee98"
}

.ri-layout-right-2-line:before {
    content: "\ee99"
}

.ri-layout-right-fill:before {
    content: "\ee9a"
}

.ri-layout-right-line:before {
    content: "\ee9b"
}

.ri-layout-row-fill:before {
    content: "\ee9c"
}

.ri-layout-row-line:before {
    content: "\ee9d"
}

.ri-layout-top-2-fill:before {
    content: "\ee9e"
}

.ri-layout-top-2-line:before {
    content: "\ee9f"
}

.ri-layout-top-fill:before {
    content: "\eea0"
}

.ri-layout-top-line:before {
    content: "\eea1"
}

.ri-leaf-fill:before {
    content: "\eea2"
}

.ri-leaf-line:before {
    content: "\eea3"
}

.ri-lifebuoy-fill:before {
    content: "\eea4"
}

.ri-lifebuoy-line:before {
    content: "\eea5"
}

.ri-lightbulb-fill:before {
    content: "\eea6"
}

.ri-lightbulb-flash-fill:before {
    content: "\eea7"
}

.ri-lightbulb-flash-line:before {
    content: "\eea8"
}

.ri-lightbulb-line:before {
    content: "\eea9"
}

.ri-line-chart-fill:before {
    content: "\eeaa"
}

.ri-line-chart-line:before {
    content: "\eeab"
}

.ri-line-fill:before {
    content: "\eeac"
}

.ri-line-height:before {
    content: "\eead"
}

.ri-line-line:before {
    content: "\eeae"
}

.ri-link-m:before {
    content: "\eeaf"
}

.ri-link-unlink-m:before {
    content: "\eeb0"
}

.ri-link-unlink:before {
    content: "\eeb1"
}

.ri-link:before {
    content: "\eeb2"
}

.ri-linkedin-box-fill:before {
    content: "\eeb3"
}

.ri-linkedin-box-line:before {
    content: "\eeb4"
}

.ri-linkedin-fill:before {
    content: "\eeb5"
}

.ri-linkedin-line:before {
    content: "\eeb6"
}

.ri-links-fill:before {
    content: "\eeb7"
}

.ri-links-line:before {
    content: "\eeb8"
}

.ri-list-check-2:before {
    content: "\eeb9"
}

.ri-list-check:before {
    content: "\eeba"
}

.ri-list-ordered:before {
    content: "\eebb"
}

.ri-list-settings-fill:before {
    content: "\eebc"
}

.ri-list-settings-line:before {
    content: "\eebd"
}

.ri-list-unordered:before {
    content: "\eebe"
}

.ri-live-fill:before {
    content: "\eebf"
}

.ri-live-line:before {
    content: "\eec0"
}

.ri-loader-2-fill:before {
    content: "\eec1"
}

.ri-loader-2-line:before {
    content: "\eec2"
}

.ri-loader-3-fill:before {
    content: "\eec3"
}

.ri-loader-3-line:before {
    content: "\eec4"
}

.ri-loader-4-fill:before {
    content: "\eec5"
}

.ri-loader-4-line:before {
    content: "\eec6"
}

.ri-loader-5-fill:before {
    content: "\eec7"
}

.ri-loader-5-line:before {
    content: "\eec8"
}

.ri-loader-fill:before {
    content: "\eec9"
}

.ri-loader-line:before {
    content: "\eeca"
}

.ri-lock-2-fill:before {
    content: "\eecb"
}

.ri-lock-2-line:before {
    content: "\eecc"
}

.ri-lock-fill:before {
    content: "\eecd"
}

.ri-lock-line:before {
    content: "\eece"
}

.ri-lock-password-fill:before {
    content: "\eecf"
}

.ri-lock-password-line:before {
    content: "\eed0"
}

.ri-lock-unlock-fill:before {
    content: "\eed1"
}

.ri-lock-unlock-line:before {
    content: "\eed2"
}

.ri-login-box-fill:before {
    content: "\eed3"
}

.ri-login-box-line:before {
    content: "\eed4"
}

.ri-login-circle-fill:before {
    content: "\eed5"
}

.ri-login-circle-line:before {
    content: "\eed6"
}

.ri-logout-box-fill:before {
    content: "\eed7"
}

.ri-logout-box-line:before {
    content: "\eed8"
}

.ri-logout-box-r-fill:before {
    content: "\eed9"
}

.ri-logout-box-r-line:before {
    content: "\eeda"
}

.ri-logout-circle-fill:before {
    content: "\eedb"
}

.ri-logout-circle-line:before {
    content: "\eedc"
}

.ri-logout-circle-r-fill:before {
    content: "\eedd"
}

.ri-logout-circle-r-line:before {
    content: "\eede"
}

.ri-luggage-cart-fill:before {
    content: "\eedf"
}

.ri-luggage-cart-line:before {
    content: "\eee0"
}

.ri-luggage-deposit-fill:before {
    content: "\eee1"
}

.ri-luggage-deposit-line:before {
    content: "\eee2"
}

.ri-lungs-fill:before {
    content: "\eee3"
}

.ri-lungs-line:before {
    content: "\eee4"
}

.ri-mac-fill:before {
    content: "\eee5"
}

.ri-mac-line:before {
    content: "\eee6"
}

.ri-macbook-fill:before {
    content: "\eee7"
}

.ri-macbook-line:before {
    content: "\eee8"
}

.ri-magic-fill:before {
    content: "\eee9"
}

.ri-magic-line:before {
    content: "\eeea"
}

.ri-mail-add-fill:before {
    content: "\eeeb"
}

.ri-mail-add-line:before {
    content: "\eeec"
}

.ri-mail-check-fill:before {
    content: "\eeed"
}

.ri-mail-check-line:before {
    content: "\eeee"
}

.ri-mail-close-fill:before {
    content: "\eeef"
}

.ri-mail-close-line:before {
    content: "\eef0"
}

.ri-mail-download-fill:before {
    content: "\eef1"
}

.ri-mail-download-line:before {
    content: "\eef2"
}

.ri-mail-fill:before {
    content: "\eef3"
}

.ri-mail-forbid-fill:before {
    content: "\eef4"
}

.ri-mail-forbid-line:before {
    content: "\eef5"
}

.ri-mail-line:before {
    content: "\eef6"
}

.ri-mail-lock-fill:before {
    content: "\eef7"
}

.ri-mail-lock-line:before {
    content: "\eef8"
}

.ri-mail-open-fill:before {
    content: "\eef9"
}

.ri-mail-open-line:before {
    content: "\eefa"
}

.ri-mail-send-fill:before {
    content: "\eefb"
}

.ri-mail-send-line:before {
    content: "\eefc"
}

.ri-mail-settings-fill:before {
    content: "\eefd"
}

.ri-mail-settings-line:before {
    content: "\eefe"
}

.ri-mail-star-fill:before {
    content: "\eeff"
}

.ri-mail-star-line:before {
    content: "\ef00"
}

.ri-mail-unread-fill:before {
    content: "\ef01"
}

.ri-mail-unread-line:before {
    content: "\ef02"
}

.ri-mail-volume-fill:before {
    content: "\ef03"
}

.ri-mail-volume-line:before {
    content: "\ef04"
}

.ri-map-2-fill:before {
    content: "\ef05"
}

.ri-map-2-line:before {
    content: "\ef06"
}

.ri-map-fill:before {
    content: "\ef07"
}

.ri-map-line:before {
    content: "\ef08"
}

.ri-map-pin-2-fill:before {
    content: "\ef09"
}

.ri-map-pin-2-line:before {
    content: "\ef0a"
}

.ri-map-pin-3-fill:before {
    content: "\ef0b"
}

.ri-map-pin-3-line:before {
    content: "\ef0c"
}

.ri-map-pin-4-fill:before {
    content: "\ef0d"
}

.ri-map-pin-4-line:before {
    content: "\ef0e"
}

.ri-map-pin-5-fill:before {
    content: "\ef0f"
}

.ri-map-pin-5-line:before {
    content: "\ef10"
}

.ri-map-pin-add-fill:before {
    content: "\ef11"
}

.ri-map-pin-add-line:before {
    content: "\ef12"
}

.ri-map-pin-fill:before {
    content: "\ef13"
}

.ri-map-pin-line:before {
    content: "\ef14"
}

.ri-map-pin-range-fill:before {
    content: "\ef15"
}

.ri-map-pin-range-line:before {
    content: "\ef16"
}

.ri-map-pin-time-fill:before {
    content: "\ef17"
}

.ri-map-pin-time-line:before {
    content: "\ef18"
}

.ri-map-pin-user-fill:before {
    content: "\ef19"
}

.ri-map-pin-user-line:before {
    content: "\ef1a"
}

.ri-mark-pen-fill:before {
    content: "\ef1b"
}

.ri-mark-pen-line:before {
    content: "\ef1c"
}

.ri-markdown-fill:before {
    content: "\ef1d"
}

.ri-markdown-line:before {
    content: "\ef1e"
}

.ri-markup-fill:before {
    content: "\ef1f"
}

.ri-markup-line:before {
    content: "\ef20"
}

.ri-mastercard-fill:before {
    content: "\ef21"
}

.ri-mastercard-line:before {
    content: "\ef22"
}

.ri-mastodon-fill:before {
    content: "\ef23"
}

.ri-mastodon-line:before {
    content: "\ef24"
}

.ri-medal-2-fill:before {
    content: "\ef25"
}

.ri-medal-2-line:before {
    content: "\ef26"
}

.ri-medal-fill:before {
    content: "\ef27"
}

.ri-medal-line:before {
    content: "\ef28"
}

.ri-medicine-bottle-fill:before {
    content: "\ef29"
}

.ri-medicine-bottle-line:before {
    content: "\ef2a"
}

.ri-medium-fill:before {
    content: "\ef2b"
}

.ri-medium-line:before {
    content: "\ef2c"
}

.ri-men-fill:before {
    content: "\ef2d"
}

.ri-men-line:before {
    content: "\ef2e"
}

.ri-mental-health-fill:before {
    content: "\ef2f"
}

.ri-mental-health-line:before {
    content: "\ef30"
}

.ri-menu-2-fill:before {
    content: "\ef31"
}

.ri-menu-2-line:before {
    content: "\ef32"
}

.ri-menu-3-fill:before {
    content: "\ef33"
}

.ri-menu-3-line:before {
    content: "\ef34"
}

.ri-menu-4-fill:before {
    content: "\ef35"
}

.ri-menu-4-line:before {
    content: "\ef36"
}

.ri-menu-5-fill:before {
    content: "\ef37"
}

.ri-menu-5-line:before {
    content: "\ef38"
}

.ri-menu-add-fill:before {
    content: "\ef39"
}

.ri-menu-add-line:before {
    content: "\ef3a"
}

.ri-menu-fill:before {
    content: "\ef3b"
}

.ri-menu-fold-fill:before {
    content: "\ef3c"
}

.ri-menu-fold-line:before {
    content: "\ef3d"
}

.ri-menu-line:before {
    content: "\ef3e"
}

.ri-menu-unfold-fill:before {
    content: "\ef3f"
}

.ri-menu-unfold-line:before {
    content: "\ef40"
}

.ri-merge-cells-horizontal:before {
    content: "\ef41"
}

.ri-merge-cells-vertical:before {
    content: "\ef42"
}

.ri-message-2-fill:before {
    content: "\ef43"
}

.ri-message-2-line:before {
    content: "\ef44"
}

.ri-message-3-fill:before {
    content: "\ef45"
}

.ri-message-3-line:before {
    content: "\ef46"
}

.ri-message-fill:before {
    content: "\ef47"
}

.ri-message-line:before {
    content: "\ef48"
}

.ri-messenger-fill:before {
    content: "\ef49"
}

.ri-messenger-line:before {
    content: "\ef4a"
}

.ri-meteor-fill:before {
    content: "\ef4b"
}

.ri-meteor-line:before {
    content: "\ef4c"
}

.ri-mic-2-fill:before {
    content: "\ef4d"
}

.ri-mic-2-line:before {
    content: "\ef4e"
}

.ri-mic-fill:before {
    content: "\ef4f"
}

.ri-mic-line:before {
    content: "\ef50"
}

.ri-mic-off-fill:before {
    content: "\ef51"
}

.ri-mic-off-line:before {
    content: "\ef52"
}

.ri-mickey-fill:before {
    content: "\ef53"
}

.ri-mickey-line:before {
    content: "\ef54"
}

.ri-microscope-fill:before {
    content: "\ef55"
}

.ri-microscope-line:before {
    content: "\ef56"
}

.ri-microsoft-fill:before {
    content: "\ef57"
}

.ri-microsoft-line:before {
    content: "\ef58"
}

.ri-mind-map:before {
    content: "\ef59"
}

.ri-mini-program-fill:before {
    content: "\ef5a"
}

.ri-mini-program-line:before {
    content: "\ef5b"
}

.ri-mist-fill:before {
    content: "\ef5c"
}

.ri-mist-line:before {
    content: "\ef5d"
}

.ri-money-cny-box-fill:before {
    content: "\ef5e"
}

.ri-money-cny-box-line:before {
    content: "\ef5f"
}

.ri-money-cny-circle-fill:before {
    content: "\ef60"
}

.ri-money-cny-circle-line:before {
    content: "\ef61"
}

.ri-money-dollar-box-fill:before {
    content: "\ef62"
}

.ri-money-dollar-box-line:before {
    content: "\ef63"
}

.ri-money-dollar-circle-fill:before {
    content: "\ef64"
}

.ri-money-dollar-circle-line:before {
    content: "\ef65"
}

.ri-money-euro-box-fill:before {
    content: "\ef66"
}

.ri-money-euro-box-line:before {
    content: "\ef67"
}

.ri-money-euro-circle-fill:before {
    content: "\ef68"
}

.ri-money-euro-circle-line:before {
    content: "\ef69"
}

.ri-money-pound-box-fill:before {
    content: "\ef6a"
}

.ri-money-pound-box-line:before {
    content: "\ef6b"
}

.ri-money-pound-circle-fill:before {
    content: "\ef6c"
}

.ri-money-pound-circle-line:before {
    content: "\ef6d"
}

.ri-moon-clear-fill:before {
    content: "\ef6e"
}

.ri-moon-clear-line:before {
    content: "\ef6f"
}

.ri-moon-cloudy-fill:before {
    content: "\ef70"
}

.ri-moon-cloudy-line:before {
    content: "\ef71"
}

.ri-moon-fill:before {
    content: "\ef72"
}

.ri-moon-foggy-fill:before {
    content: "\ef73"
}

.ri-moon-foggy-line:before {
    content: "\ef74"
}

.ri-moon-line:before {
    content: "\ef75"
}

.ri-more-2-fill:before {
    content: "\ef76"
}

.ri-more-2-line:before {
    content: "\ef77"
}

.ri-more-fill:before {
    content: "\ef78"
}

.ri-more-line:before {
    content: "\ef79"
}

.ri-motorbike-fill:before {
    content: "\ef7a"
}

.ri-motorbike-line:before {
    content: "\ef7b"
}

.ri-mouse-fill:before {
    content: "\ef7c"
}

.ri-mouse-line:before {
    content: "\ef7d"
}

.ri-movie-2-fill:before {
    content: "\ef7e"
}

.ri-movie-2-line:before {
    content: "\ef7f"
}

.ri-movie-fill:before {
    content: "\ef80"
}

.ri-movie-line:before {
    content: "\ef81"
}

.ri-music-2-fill:before {
    content: "\ef82"
}

.ri-music-2-line:before {
    content: "\ef83"
}

.ri-music-fill:before {
    content: "\ef84"
}

.ri-music-line:before {
    content: "\ef85"
}

.ri-mv-fill:before {
    content: "\ef86"
}

.ri-mv-line:before {
    content: "\ef87"
}

.ri-navigation-fill:before {
    content: "\ef88"
}

.ri-navigation-line:before {
    content: "\ef89"
}

.ri-netease-cloud-music-fill:before {
    content: "\ef8a"
}

.ri-netease-cloud-music-line:before {
    content: "\ef8b"
}

.ri-netflix-fill:before {
    content: "\ef8c"
}

.ri-netflix-line:before {
    content: "\ef8d"
}

.ri-newspaper-fill:before {
    content: "\ef8e"
}

.ri-newspaper-line:before {
    content: "\ef8f"
}

.ri-node-tree:before {
    content: "\ef90"
}

.ri-notification-2-fill:before {
    content: "\ef91"
}

.ri-notification-2-line:before {
    content: "\ef92"
}

.ri-notification-3-fill:before {
    content: "\ef93"
}

.ri-notification-3-line:before {
    content: "\ef94"
}

.ri-notification-4-fill:before {
    content: "\ef95"
}

.ri-notification-4-line:before {
    content: "\ef96"
}

.ri-notification-badge-fill:before {
    content: "\ef97"
}

.ri-notification-badge-line:before {
    content: "\ef98"
}

.ri-notification-fill:before {
    content: "\ef99"
}

.ri-notification-line:before {
    content: "\ef9a"
}

.ri-notification-off-fill:before {
    content: "\ef9b"
}

.ri-notification-off-line:before {
    content: "\ef9c"
}

.ri-npmjs-fill:before {
    content: "\ef9d"
}

.ri-npmjs-line:before {
    content: "\ef9e"
}

.ri-number-0:before {
    content: "\ef9f"
}

.ri-number-1:before {
    content: "\efa0"
}

.ri-number-2:before {
    content: "\efa1"
}

.ri-number-3:before {
    content: "\efa2"
}

.ri-number-4:before {
    content: "\efa3"
}

.ri-number-5:before {
    content: "\efa4"
}

.ri-number-6:before {
    content: "\efa5"
}

.ri-number-7:before {
    content: "\efa6"
}

.ri-number-8:before {
    content: "\efa7"
}

.ri-number-9:before {
    content: "\efa8"
}

.ri-numbers-fill:before {
    content: "\efa9"
}

.ri-numbers-line:before {
    content: "\efaa"
}

.ri-nurse-fill:before {
    content: "\efab"
}

.ri-nurse-line:before {
    content: "\efac"
}

.ri-oil-fill:before {
    content: "\efad"
}

.ri-oil-line:before {
    content: "\efae"
}

.ri-omega:before {
    content: "\efaf"
}

.ri-open-arm-fill:before {
    content: "\efb0"
}

.ri-open-arm-line:before {
    content: "\efb1"
}

.ri-open-source-fill:before {
    content: "\efb2"
}

.ri-open-source-line:before {
    content: "\efb3"
}

.ri-opera-fill:before {
    content: "\efb4"
}

.ri-opera-line:before {
    content: "\efb5"
}

.ri-order-play-fill:before {
    content: "\efb6"
}

.ri-order-play-line:before {
    content: "\efb7"
}

.ri-organization-chart:before {
    content: "\efb8"
}

.ri-outlet-2-fill:before {
    content: "\efb9"
}

.ri-outlet-2-line:before {
    content: "\efba"
}

.ri-outlet-fill:before {
    content: "\efbb"
}

.ri-outlet-line:before {
    content: "\efbc"
}

.ri-page-separator:before {
    content: "\efbd"
}

.ri-pages-fill:before {
    content: "\efbe"
}

.ri-pages-line:before {
    content: "\efbf"
}

.ri-paint-brush-fill:before {
    content: "\efc0"
}

.ri-paint-brush-line:before {
    content: "\efc1"
}

.ri-paint-fill:before {
    content: "\efc2"
}

.ri-paint-line:before {
    content: "\efc3"
}

.ri-palette-fill:before {
    content: "\efc4"
}

.ri-palette-line:before {
    content: "\efc5"
}

.ri-pantone-fill:before {
    content: "\efc6"
}

.ri-pantone-line:before {
    content: "\efc7"
}

.ri-paragraph:before {
    content: "\efc8"
}

.ri-parent-fill:before {
    content: "\efc9"
}

.ri-parent-line:before {
    content: "\efca"
}

.ri-parentheses-fill:before {
    content: "\efcb"
}

.ri-parentheses-line:before {
    content: "\efcc"
}

.ri-parking-box-fill:before {
    content: "\efcd"
}

.ri-parking-box-line:before {
    content: "\efce"
}

.ri-parking-fill:before {
    content: "\efcf"
}

.ri-parking-line:before {
    content: "\efd0"
}

.ri-passport-fill:before {
    content: "\efd1"
}

.ri-passport-line:before {
    content: "\efd2"
}

.ri-patreon-fill:before {
    content: "\efd3"
}

.ri-patreon-line:before {
    content: "\efd4"
}

.ri-pause-circle-fill:before {
    content: "\efd5"
}

.ri-pause-circle-line:before {
    content: "\efd6"
}

.ri-pause-fill:before {
    content: "\efd7"
}

.ri-pause-line:before {
    content: "\efd8"
}

.ri-pause-mini-fill:before {
    content: "\efd9"
}

.ri-pause-mini-line:before {
    content: "\efda"
}

.ri-paypal-fill:before {
    content: "\efdb"
}

.ri-paypal-line:before {
    content: "\efdc"
}

.ri-pen-nib-fill:before {
    content: "\efdd"
}

.ri-pen-nib-line:before {
    content: "\efde"
}

.ri-pencil-fill:before {
    content: "\efdf"
}

.ri-pencil-line:before {
    content: "\efe0"
}

.ri-pencil-ruler-2-fill:before {
    content: "\efe1"
}

.ri-pencil-ruler-2-line:before {
    content: "\efe2"
}

.ri-pencil-ruler-fill:before {
    content: "\efe3"
}

.ri-pencil-ruler-line:before {
    content: "\efe4"
}

.ri-percent-fill:before {
    content: "\efe5"
}

.ri-percent-line:before {
    content: "\efe6"
}

.ri-phone-camera-fill:before {
    content: "\efe7"
}

.ri-phone-camera-line:before {
    content: "\efe8"
}

.ri-phone-fill:before {
    content: "\efe9"
}

.ri-phone-find-fill:before {
    content: "\efea"
}

.ri-phone-find-line:before {
    content: "\efeb"
}

.ri-phone-line:before {
    content: "\efec"
}

.ri-phone-lock-fill:before {
    content: "\efed"
}

.ri-phone-lock-line:before {
    content: "\efee"
}

.ri-picture-in-picture-2-fill:before {
    content: "\efef"
}

.ri-picture-in-picture-2-line:before {
    content: "\eff0"
}

.ri-picture-in-picture-exit-fill:before {
    content: "\eff1"
}

.ri-picture-in-picture-exit-line:before {
    content: "\eff2"
}

.ri-picture-in-picture-fill:before {
    content: "\eff3"
}

.ri-picture-in-picture-line:before {
    content: "\eff4"
}

.ri-pie-chart-2-fill:before {
    content: "\eff5"
}

.ri-pie-chart-2-line:before {
    content: "\eff6"
}

.ri-pie-chart-box-fill:before {
    content: "\eff7"
}

.ri-pie-chart-box-line:before {
    content: "\eff8"
}

.ri-pie-chart-fill:before {
    content: "\eff9"
}

.ri-pie-chart-line:before {
    content: "\effa"
}

.ri-pin-distance-fill:before {
    content: "\effb"
}

.ri-pin-distance-line:before {
    content: "\effc"
}

.ri-ping-pong-fill:before {
    content: "\effd"
}

.ri-ping-pong-line:before {
    content: "\effe"
}

.ri-pinterest-fill:before {
    content: "\efff"
}

.ri-pinterest-line:before {
    content: "\f000"
}

.ri-pinyin-input:before {
    content: "\f001"
}

.ri-pixelfed-fill:before {
    content: "\f002"
}

.ri-pixelfed-line:before {
    content: "\f003"
}

.ri-plane-fill:before {
    content: "\f004"
}

.ri-plane-line:before {
    content: "\f005"
}

.ri-plant-fill:before {
    content: "\f006"
}

.ri-plant-line:before {
    content: "\f007"
}

.ri-play-circle-fill:before {
    content: "\f008"
}

.ri-play-circle-line:before {
    content: "\f009"
}

.ri-play-fill:before {
    content: "\f00a"
}

.ri-play-line:before {
    content: "\f00b"
}

.ri-play-list-2-fill:before {
    content: "\f00c"
}

.ri-play-list-2-line:before {
    content: "\f00d"
}

.ri-play-list-add-fill:before {
    content: "\f00e"
}

.ri-play-list-add-line:before {
    content: "\f00f"
}

.ri-play-list-fill:before {
    content: "\f010"
}

.ri-play-list-line:before {
    content: "\f011"
}

.ri-play-mini-fill:before {
    content: "\f012"
}

.ri-play-mini-line:before {
    content: "\f013"
}

.ri-playstation-fill:before {
    content: "\f014"
}

.ri-playstation-line:before {
    content: "\f015"
}

.ri-plug-2-fill:before {
    content: "\f016"
}

.ri-plug-2-line:before {
    content: "\f017"
}

.ri-plug-fill:before {
    content: "\f018"
}

.ri-plug-line:before {
    content: "\f019"
}

.ri-polaroid-2-fill:before {
    content: "\f01a"
}

.ri-polaroid-2-line:before {
    content: "\f01b"
}

.ri-polaroid-fill:before {
    content: "\f01c"
}

.ri-polaroid-line:before {
    content: "\f01d"
}

.ri-police-car-fill:before {
    content: "\f01e"
}

.ri-police-car-line:before {
    content: "\f01f"
}

.ri-price-tag-2-fill:before {
    content: "\f020"
}

.ri-price-tag-2-line:before {
    content: "\f021"
}

.ri-price-tag-3-fill:before {
    content: "\f022"
}

.ri-price-tag-3-line:before {
    content: "\f023"
}

.ri-price-tag-fill:before {
    content: "\f024"
}

.ri-price-tag-line:before {
    content: "\f025"
}

.ri-printer-cloud-fill:before {
    content: "\f026"
}

.ri-printer-cloud-line:before {
    content: "\f027"
}

.ri-printer-fill:before {
    content: "\f028"
}

.ri-printer-line:before {
    content: "\f029"
}

.ri-product-hunt-fill:before {
    content: "\f02a"
}

.ri-product-hunt-line:before {
    content: "\f02b"
}

.ri-profile-fill:before {
    content: "\f02c"
}

.ri-profile-line:before {
    content: "\f02d"
}

.ri-projector-2-fill:before {
    content: "\f02e"
}

.ri-projector-2-line:before {
    content: "\f02f"
}

.ri-projector-fill:before {
    content: "\f030"
}

.ri-projector-line:before {
    content: "\f031"
}

.ri-psychotherapy-fill:before {
    content: "\f032"
}

.ri-psychotherapy-line:before {
    content: "\f033"
}

.ri-pulse-fill:before {
    content: "\f034"
}

.ri-pulse-line:before {
    content: "\f035"
}

.ri-pushpin-2-fill:before {
    content: "\f036"
}

.ri-pushpin-2-line:before {
    content: "\f037"
}

.ri-pushpin-fill:before {
    content: "\f038"
}

.ri-pushpin-line:before {
    content: "\f039"
}

.ri-qq-fill:before {
    content: "\f03a"
}

.ri-qq-line:before {
    content: "\f03b"
}

.ri-qr-code-fill:before {
    content: "\f03c"
}

.ri-qr-code-line:before {
    content: "\f03d"
}

.ri-qr-scan-2-fill:before {
    content: "\f03e"
}

.ri-qr-scan-2-line:before {
    content: "\f03f"
}

.ri-qr-scan-fill:before {
    content: "\f040"
}

.ri-qr-scan-line:before {
    content: "\f041"
}

.ri-question-answer-fill:before {
    content: "\f042"
}

.ri-question-answer-line:before {
    content: "\f043"
}

.ri-question-fill:before {
    content: "\f044"
}

.ri-question-line:before {
    content: "\f045"
}

.ri-question-mark:before {
    content: "\f046"
}

.ri-questionnaire-fill:before {
    content: "\f047"
}

.ri-questionnaire-line:before {
    content: "\f048"
}

.ri-quill-pen-fill:before {
    content: "\f049"
}

.ri-quill-pen-line:before {
    content: "\f04a"
}

.ri-radar-fill:before {
    content: "\f04b"
}

.ri-radar-line:before {
    content: "\f04c"
}

.ri-radio-2-fill:before {
    content: "\f04d"
}

.ri-radio-2-line:before {
    content: "\f04e"
}

.ri-radio-button-fill:before {
    content: "\f04f"
}

.ri-radio-button-line:before {
    content: "\f050"
}

.ri-radio-fill:before {
    content: "\f051"
}

.ri-radio-line:before {
    content: "\f052"
}

.ri-rainbow-fill:before {
    content: "\f053"
}

.ri-rainbow-line:before {
    content: "\f054"
}

.ri-rainy-fill:before {
    content: "\f055"
}

.ri-rainy-line:before {
    content: "\f056"
}

.ri-reactjs-fill:before {
    content: "\f057"
}

.ri-reactjs-line:before {
    content: "\f058"
}

.ri-record-circle-fill:before {
    content: "\f059"
}

.ri-record-circle-line:before {
    content: "\f05a"
}

.ri-record-mail-fill:before {
    content: "\f05b"
}

.ri-record-mail-line:before {
    content: "\f05c"
}

.ri-recycle-fill:before {
    content: "\f05d"
}

.ri-recycle-line:before {
    content: "\f05e"
}

.ri-red-packet-fill:before {
    content: "\f05f"
}

.ri-red-packet-line:before {
    content: "\f060"
}

.ri-reddit-fill:before {
    content: "\f061"
}

.ri-reddit-line:before {
    content: "\f062"
}

.ri-refresh-fill:before {
    content: "\f063"
}

.ri-refresh-line:before {
    content: "\f064"
}

.ri-refund-2-fill:before {
    content: "\f065"
}

.ri-refund-2-line:before {
    content: "\f066"
}

.ri-refund-fill:before {
    content: "\f067"
}

.ri-refund-line:before {
    content: "\f068"
}

.ri-registered-fill:before {
    content: "\f069"
}

.ri-registered-line:before {
    content: "\f06a"
}

.ri-remixicon-fill:before {
    content: "\f06b"
}

.ri-remixicon-line:before {
    content: "\f06c"
}

.ri-remote-control-2-fill:before {
    content: "\f06d"
}

.ri-remote-control-2-line:before {
    content: "\f06e"
}

.ri-remote-control-fill:before {
    content: "\f06f"
}

.ri-remote-control-line:before {
    content: "\f070"
}

.ri-repeat-2-fill:before {
    content: "\f071"
}

.ri-repeat-2-line:before {
    content: "\f072"
}

.ri-repeat-fill:before {
    content: "\f073"
}

.ri-repeat-line:before {
    content: "\f074"
}

.ri-repeat-one-fill:before {
    content: "\f075"
}

.ri-repeat-one-line:before {
    content: "\f076"
}

.ri-reply-all-fill:before {
    content: "\f077"
}

.ri-reply-all-line:before {
    content: "\f078"
}

.ri-reply-fill:before {
    content: "\f079"
}

.ri-reply-line:before {
    content: "\f07a"
}

.ri-reserved-fill:before {
    content: "\f07b"
}

.ri-reserved-line:before {
    content: "\f07c"
}

.ri-rest-time-fill:before {
    content: "\f07d"
}

.ri-rest-time-line:before {
    content: "\f07e"
}

.ri-restart-fill:before {
    content: "\f07f"
}

.ri-restart-line:before {
    content: "\f080"
}

.ri-restaurant-2-fill:before {
    content: "\f081"
}

.ri-restaurant-2-line:before {
    content: "\f082"
}

.ri-restaurant-fill:before {
    content: "\f083"
}

.ri-restaurant-line:before {
    content: "\f084"
}

.ri-rewind-fill:before {
    content: "\f085"
}

.ri-rewind-line:before {
    content: "\f086"
}

.ri-rewind-mini-fill:before {
    content: "\f087"
}

.ri-rewind-mini-line:before {
    content: "\f088"
}

.ri-rhythm-fill:before {
    content: "\f089"
}

.ri-rhythm-line:before {
    content: "\f08a"
}

.ri-riding-fill:before {
    content: "\f08b"
}

.ri-riding-line:before {
    content: "\f08c"
}

.ri-road-map-fill:before {
    content: "\f08d"
}

.ri-road-map-line:before {
    content: "\f08e"
}

.ri-roadster-fill:before {
    content: "\f08f"
}

.ri-roadster-line:before {
    content: "\f090"
}

.ri-robot-fill:before {
    content: "\f091"
}

.ri-robot-line:before {
    content: "\f092"
}

.ri-rocket-2-fill:before {
    content: "\f093"
}

.ri-rocket-2-line:before {
    content: "\f094"
}

.ri-rocket-fill:before {
    content: "\f095"
}

.ri-rocket-line:before {
    content: "\f096"
}

.ri-rotate-lock-fill:before {
    content: "\f097"
}

.ri-rotate-lock-line:before {
    content: "\f098"
}

.ri-rounded-corner:before {
    content: "\f099"
}

.ri-route-fill:before {
    content: "\f09a"
}

.ri-route-line:before {
    content: "\f09b"
}

.ri-router-fill:before {
    content: "\f09c"
}

.ri-router-line:before {
    content: "\f09d"
}

.ri-rss-fill:before {
    content: "\f09e"
}

.ri-rss-line:before {
    content: "\f09f"
}

.ri-ruler-2-fill:before {
    content: "\f0a0"
}

.ri-ruler-2-line:before {
    content: "\f0a1"
}

.ri-ruler-fill:before {
    content: "\f0a2"
}

.ri-ruler-line:before {
    content: "\f0a3"
}

.ri-run-fill:before {
    content: "\f0a4"
}

.ri-run-line:before {
    content: "\f0a5"
}

.ri-safari-fill:before {
    content: "\f0a6"
}

.ri-safari-line:before {
    content: "\f0a7"
}

.ri-safe-2-fill:before {
    content: "\f0a8"
}

.ri-safe-2-line:before {
    content: "\f0a9"
}

.ri-safe-fill:before {
    content: "\f0aa"
}

.ri-safe-line:before {
    content: "\f0ab"
}

.ri-sailboat-fill:before {
    content: "\f0ac"
}

.ri-sailboat-line:before {
    content: "\f0ad"
}

.ri-save-2-fill:before {
    content: "\f0ae"
}

.ri-save-2-line:before {
    content: "\f0af"
}

.ri-save-3-fill:before {
    content: "\f0b0"
}

.ri-save-3-line:before {
    content: "\f0b1"
}

.ri-save-fill:before {
    content: "\f0b2"
}

.ri-save-line:before {
    content: "\f0b3"
}

.ri-scales-2-fill:before {
    content: "\f0b4"
}

.ri-scales-2-line:before {
    content: "\f0b5"
}

.ri-scales-3-fill:before {
    content: "\f0b6"
}

.ri-scales-3-line:before {
    content: "\f0b7"
}

.ri-scales-fill:before {
    content: "\f0b8"
}

.ri-scales-line:before {
    content: "\f0b9"
}

.ri-scan-2-fill:before {
    content: "\f0ba"
}

.ri-scan-2-line:before {
    content: "\f0bb"
}

.ri-scan-fill:before {
    content: "\f0bc"
}

.ri-scan-line:before {
    content: "\f0bd"
}

.ri-scissors-2-fill:before {
    content: "\f0be"
}

.ri-scissors-2-line:before {
    content: "\f0bf"
}

.ri-scissors-cut-fill:before {
    content: "\f0c0"
}

.ri-scissors-cut-line:before {
    content: "\f0c1"
}

.ri-scissors-fill:before {
    content: "\f0c2"
}

.ri-scissors-line:before {
    content: "\f0c3"
}

.ri-screenshot-2-fill:before {
    content: "\f0c4"
}

.ri-screenshot-2-line:before {
    content: "\f0c5"
}

.ri-screenshot-fill:before {
    content: "\f0c6"
}

.ri-screenshot-line:before {
    content: "\f0c7"
}

.ri-sd-card-fill:before {
    content: "\f0c8"
}

.ri-sd-card-line:before {
    content: "\f0c9"
}

.ri-sd-card-mini-fill:before {
    content: "\f0ca"
}

.ri-sd-card-mini-line:before {
    content: "\f0cb"
}

.ri-search-2-fill:before {
    content: "\f0cc"
}

.ri-search-2-line:before {
    content: "\f0cd"
}

.ri-search-eye-fill:before {
    content: "\f0ce"
}

.ri-search-eye-line:before {
    content: "\f0cf"
}

.ri-search-fill:before {
    content: "\f0d0"
}

.ri-search-line:before {
    content: "\f0d1"
}

.ri-secure-payment-fill:before {
    content: "\f0d2"
}

.ri-secure-payment-line:before {
    content: "\f0d3"
}

.ri-seedling-fill:before {
    content: "\f0d4"
}

.ri-seedling-line:before {
    content: "\f0d5"
}

.ri-send-backward:before {
    content: "\f0d6"
}

.ri-send-plane-2-fill:before {
    content: "\f0d7"
}

.ri-send-plane-2-line:before {
    content: "\f0d8"
}

.ri-send-plane-fill:before {
    content: "\f0d9"
}

.ri-send-plane-line:before {
    content: "\f0da"
}

.ri-send-to-back:before {
    content: "\f0db"
}

.ri-sensor-fill:before {
    content: "\f0dc"
}

.ri-sensor-line:before {
    content: "\f0dd"
}

.ri-separator:before {
    content: "\f0de"
}

.ri-server-fill:before {
    content: "\f0df"
}

.ri-server-line:before {
    content: "\f0e0"
}

.ri-service-fill:before {
    content: "\f0e1"
}

.ri-service-line:before {
    content: "\f0e2"
}

.ri-settings-2-fill:before {
    content: "\f0e3"
}

.ri-settings-2-line:before {
    content: "\f0e4"
}

.ri-settings-3-fill:before {
    content: "\f0e5"
}

.ri-settings-3-line:before {
    content: "\f0e6"
}

.ri-settings-4-fill:before {
    content: "\f0e7"
}

.ri-settings-4-line:before {
    content: "\f0e8"
}

.ri-settings-5-fill:before {
    content: "\f0e9"
}

.ri-settings-5-line:before {
    content: "\f0ea"
}

.ri-settings-6-fill:before {
    content: "\f0eb"
}

.ri-settings-6-line:before {
    content: "\f0ec"
}

.ri-settings-fill:before {
    content: "\f0ed"
}

.ri-settings-line:before {
    content: "\f0ee"
}

.ri-shape-2-fill:before {
    content: "\f0ef"
}

.ri-shape-2-line:before {
    content: "\f0f0"
}

.ri-shape-fill:before {
    content: "\f0f1"
}

.ri-shape-line:before {
    content: "\f0f2"
}

.ri-share-box-fill:before {
    content: "\f0f3"
}

.ri-share-box-line:before {
    content: "\f0f4"
}

.ri-share-circle-fill:before {
    content: "\f0f5"
}

.ri-share-circle-line:before {
    content: "\f0f6"
}

.ri-share-fill:before {
    content: "\f0f7"
}

.ri-share-forward-2-fill:before {
    content: "\f0f8"
}

.ri-share-forward-2-line:before {
    content: "\f0f9"
}

.ri-share-forward-box-fill:before {
    content: "\f0fa"
}

.ri-share-forward-box-line:before {
    content: "\f0fb"
}

.ri-share-forward-fill:before {
    content: "\f0fc"
}

.ri-share-forward-line:before {
    content: "\f0fd"
}

.ri-share-line:before {
    content: "\f0fe"
}

.ri-shield-check-fill:before {
    content: "\f0ff"
}

.ri-shield-check-line:before {
    content: "\f100"
}

.ri-shield-cross-fill:before {
    content: "\f101"
}

.ri-shield-cross-line:before {
    content: "\f102"
}

.ri-shield-fill:before {
    content: "\f103"
}

.ri-shield-flash-fill:before {
    content: "\f104"
}

.ri-shield-flash-line:before {
    content: "\f105"
}

.ri-shield-keyhole-fill:before {
    content: "\f106"
}

.ri-shield-keyhole-line:before {
    content: "\f107"
}

.ri-shield-line:before {
    content: "\f108"
}

.ri-shield-star-fill:before {
    content: "\f109"
}

.ri-shield-star-line:before {
    content: "\f10a"
}

.ri-shield-user-fill:before {
    content: "\f10b"
}

.ri-shield-user-line:before {
    content: "\f10c"
}

.ri-ship-2-fill:before {
    content: "\f10d"
}

.ri-ship-2-line:before {
    content: "\f10e"
}

.ri-ship-fill:before {
    content: "\f10f"
}

.ri-ship-line:before {
    content: "\f110"
}

.ri-shirt-fill:before {
    content: "\f111"
}

.ri-shirt-line:before {
    content: "\f112"
}

.ri-shopping-bag-2-fill:before {
    content: "\f113"
}

.ri-shopping-bag-2-line:before {
    content: "\f114"
}

.ri-shopping-bag-3-fill:before {
    content: "\f115"
}

.ri-shopping-bag-3-line:before {
    content: "\f116"
}

.ri-shopping-bag-fill:before {
    content: "\f117"
}

.ri-shopping-bag-line:before {
    content: "\f118"
}

.ri-shopping-basket-2-fill:before {
    content: "\f119"
}

.ri-shopping-basket-2-line:before {
    content: "\f11a"
}

.ri-shopping-basket-fill:before {
    content: "\f11b"
}

.ri-shopping-basket-line:before {
    content: "\f11c"
}

.ri-shopping-cart-2-fill:before {
    content: "\f11d"
}

.ri-shopping-cart-2-line:before {
    content: "\f11e"
}

.ri-shopping-cart-fill:before {
    content: "\f11f"
}

.ri-shopping-cart-line:before {
    content: "\f120"
}

.ri-showers-fill:before {
    content: "\f121"
}

.ri-showers-line:before {
    content: "\f122"
}

.ri-shuffle-fill:before {
    content: "\f123"
}

.ri-shuffle-line:before {
    content: "\f124"
}

.ri-shut-down-fill:before {
    content: "\f125"
}

.ri-shut-down-line:before {
    content: "\f126"
}

.ri-side-bar-fill:before {
    content: "\f127"
}

.ri-side-bar-line:before {
    content: "\f128"
}

.ri-signal-tower-fill:before {
    content: "\f129"
}

.ri-signal-tower-line:before {
    content: "\f12a"
}

.ri-signal-wifi-1-fill:before {
    content: "\f12b"
}

.ri-signal-wifi-1-line:before {
    content: "\f12c"
}

.ri-signal-wifi-2-fill:before {
    content: "\f12d"
}

.ri-signal-wifi-2-line:before {
    content: "\f12e"
}

.ri-signal-wifi-3-fill:before {
    content: "\f12f"
}

.ri-signal-wifi-3-line:before {
    content: "\f130"
}

.ri-signal-wifi-error-fill:before {
    content: "\f131"
}

.ri-signal-wifi-error-line:before {
    content: "\f132"
}

.ri-signal-wifi-fill:before {
    content: "\f133"
}

.ri-signal-wifi-line:before {
    content: "\f134"
}

.ri-signal-wifi-off-fill:before {
    content: "\f135"
}

.ri-signal-wifi-off-line:before {
    content: "\f136"
}

.ri-sim-card-2-fill:before {
    content: "\f137"
}

.ri-sim-card-2-line:before {
    content: "\f138"
}

.ri-sim-card-fill:before {
    content: "\f139"
}

.ri-sim-card-line:before {
    content: "\f13a"
}

.ri-single-quotes-l:before {
    content: "\f13b"
}

.ri-single-quotes-r:before {
    content: "\f13c"
}

.ri-sip-fill:before {
    content: "\f13d"
}

.ri-sip-line:before {
    content: "\f13e"
}

.ri-skip-back-fill:before {
    content: "\f13f"
}

.ri-skip-back-line:before {
    content: "\f140"
}

.ri-skip-back-mini-fill:before {
    content: "\f141"
}

.ri-skip-back-mini-line:before {
    content: "\f142"
}

.ri-skip-forward-fill:before {
    content: "\f143"
}

.ri-skip-forward-line:before {
    content: "\f144"
}

.ri-skip-forward-mini-fill:before {
    content: "\f145"
}

.ri-skip-forward-mini-line:before {
    content: "\f146"
}

.ri-skull-2-fill:before {
    content: "\f147"
}

.ri-skull-2-line:before {
    content: "\f148"
}

.ri-skull-fill:before {
    content: "\f149"
}

.ri-skull-line:before {
    content: "\f14a"
}

.ri-skype-fill:before {
    content: "\f14b"
}

.ri-skype-line:before {
    content: "\f14c"
}

.ri-slack-fill:before {
    content: "\f14d"
}

.ri-slack-line:before {
    content: "\f14e"
}

.ri-slice-fill:before {
    content: "\f14f"
}

.ri-slice-line:before {
    content: "\f150"
}

.ri-slideshow-2-fill:before {
    content: "\f151"
}

.ri-slideshow-2-line:before {
    content: "\f152"
}

.ri-slideshow-3-fill:before {
    content: "\f153"
}

.ri-slideshow-3-line:before {
    content: "\f154"
}

.ri-slideshow-4-fill:before {
    content: "\f155"
}

.ri-slideshow-4-line:before {
    content: "\f156"
}

.ri-slideshow-fill:before {
    content: "\f157"
}

.ri-slideshow-line:before {
    content: "\f158"
}

.ri-smartphone-fill:before {
    content: "\f159"
}

.ri-smartphone-line:before {
    content: "\f15a"
}

.ri-snapchat-fill:before {
    content: "\f15b"
}

.ri-snapchat-line:before {
    content: "\f15c"
}

.ri-snowy-fill:before {
    content: "\f15d"
}

.ri-snowy-line:before {
    content: "\f15e"
}

.ri-sort-asc:before {
    content: "\f15f"
}

.ri-sort-desc:before {
    content: "\f160"
}

.ri-sound-module-fill:before {
    content: "\f161"
}

.ri-sound-module-line:before {
    content: "\f162"
}

.ri-soundcloud-fill:before {
    content: "\f163"
}

.ri-soundcloud-line:before {
    content: "\f164"
}

.ri-space-ship-fill:before {
    content: "\f165"
}

.ri-space-ship-line:before {
    content: "\f166"
}

.ri-space:before {
    content: "\f167"
}

.ri-spam-2-fill:before {
    content: "\f168"
}

.ri-spam-2-line:before {
    content: "\f169"
}

.ri-spam-3-fill:before {
    content: "\f16a"
}

.ri-spam-3-line:before {
    content: "\f16b"
}

.ri-spam-fill:before {
    content: "\f16c"
}

.ri-spam-line:before {
    content: "\f16d"
}

.ri-speaker-2-fill:before {
    content: "\f16e"
}

.ri-speaker-2-line:before {
    content: "\f16f"
}

.ri-speaker-3-fill:before {
    content: "\f170"
}

.ri-speaker-3-line:before {
    content: "\f171"
}

.ri-speaker-fill:before {
    content: "\f172"
}

.ri-speaker-line:before {
    content: "\f173"
}

.ri-spectrum-fill:before {
    content: "\f174"
}

.ri-spectrum-line:before {
    content: "\f175"
}

.ri-speed-fill:before {
    content: "\f176"
}

.ri-speed-line:before {
    content: "\f177"
}

.ri-speed-mini-fill:before {
    content: "\f178"
}

.ri-speed-mini-line:before {
    content: "\f179"
}

.ri-split-cells-horizontal:before {
    content: "\f17a"
}

.ri-split-cells-vertical:before {
    content: "\f17b"
}

.ri-spotify-fill:before {
    content: "\f17c"
}

.ri-spotify-line:before {
    content: "\f17d"
}

.ri-spy-fill:before {
    content: "\f17e"
}

.ri-spy-line:before {
    content: "\f17f"
}

.ri-stack-fill:before {
    content: "\f180"
}

.ri-stack-line:before {
    content: "\f181"
}

.ri-stack-overflow-fill:before {
    content: "\f182"
}

.ri-stack-overflow-line:before {
    content: "\f183"
}

.ri-stackshare-fill:before {
    content: "\f184"
}

.ri-stackshare-line:before {
    content: "\f185"
}

.ri-star-fill:before {
    content: "\f186"
}

.ri-star-half-fill:before {
    content: "\f187"
}

.ri-star-half-line:before {
    content: "\f188"
}

.ri-star-half-s-fill:before {
    content: "\f189"
}

.ri-star-half-s-line:before {
    content: "\f18a"
}

.ri-star-line:before {
    content: "\f18b"
}

.ri-star-s-fill:before {
    content: "\f18c"
}

.ri-star-s-line:before {
    content: "\f18d"
}

.ri-star-smile-fill:before {
    content: "\f18e"
}

.ri-star-smile-line:before {
    content: "\f18f"
}

.ri-steam-fill:before {
    content: "\f190"
}

.ri-steam-line:before {
    content: "\f191"
}

.ri-steering-2-fill:before {
    content: "\f192"
}

.ri-steering-2-line:before {
    content: "\f193"
}

.ri-steering-fill:before {
    content: "\f194"
}

.ri-steering-line:before {
    content: "\f195"
}

.ri-stethoscope-fill:before {
    content: "\f196"
}

.ri-stethoscope-line:before {
    content: "\f197"
}

.ri-sticky-note-2-fill:before {
    content: "\f198"
}

.ri-sticky-note-2-line:before {
    content: "\f199"
}

.ri-sticky-note-fill:before {
    content: "\f19a"
}

.ri-sticky-note-line:before {
    content: "\f19b"
}

.ri-stock-fill:before {
    content: "\f19c"
}

.ri-stock-line:before {
    content: "\f19d"
}

.ri-stop-circle-fill:before {
    content: "\f19e"
}

.ri-stop-circle-line:before {
    content: "\f19f"
}

.ri-stop-fill:before {
    content: "\f1a0"
}

.ri-stop-line:before {
    content: "\f1a1"
}

.ri-stop-mini-fill:before {
    content: "\f1a2"
}

.ri-stop-mini-line:before {
    content: "\f1a3"
}

.ri-store-2-fill:before {
    content: "\f1a4"
}

.ri-store-2-line:before {
    content: "\f1a5"
}

.ri-store-3-fill:before {
    content: "\f1a6"
}

.ri-store-3-line:before {
    content: "\f1a7"
}

.ri-store-fill:before {
    content: "\f1a8"
}

.ri-store-line:before {
    content: "\f1a9"
}

.ri-strikethrough-2:before {
    content: "\f1aa"
}

.ri-strikethrough:before {
    content: "\f1ab"
}

.ri-subscript-2:before {
    content: "\f1ac"
}

.ri-subscript:before {
    content: "\f1ad"
}

.ri-subtract-fill:before {
    content: "\f1ae"
}

.ri-subtract-line:before {
    content: "\f1af"
}

.ri-subway-fill:before {
    content: "\f1b0"
}

.ri-subway-line:before {
    content: "\f1b1"
}

.ri-subway-wifi-fill:before {
    content: "\f1b2"
}

.ri-subway-wifi-line:before {
    content: "\f1b3"
}

.ri-suitcase-2-fill:before {
    content: "\f1b4"
}

.ri-suitcase-2-line:before {
    content: "\f1b5"
}

.ri-suitcase-3-fill:before {
    content: "\f1b6"
}

.ri-suitcase-3-line:before {
    content: "\f1b7"
}

.ri-suitcase-fill:before {
    content: "\f1b8"
}

.ri-suitcase-line:before {
    content: "\f1b9"
}

.ri-sun-cloudy-fill:before {
    content: "\f1ba"
}

.ri-sun-cloudy-line:before {
    content: "\f1bb"
}

.ri-sun-fill:before {
    content: "\f1bc"
}

.ri-sun-foggy-fill:before {
    content: "\f1bd"
}

.ri-sun-foggy-line:before {
    content: "\f1be"
}

.ri-sun-line:before {
    content: "\f1bf"
}

.ri-superscript-2:before {
    content: "\f1c0"
}

.ri-superscript:before {
    content: "\f1c1"
}

.ri-surgical-mask-fill:before {
    content: "\f1c2"
}

.ri-surgical-mask-line:before {
    content: "\f1c3"
}

.ri-surround-sound-fill:before {
    content: "\f1c4"
}

.ri-surround-sound-line:before {
    content: "\f1c5"
}

.ri-survey-fill:before {
    content: "\f1c6"
}

.ri-survey-line:before {
    content: "\f1c7"
}

.ri-swap-box-fill:before {
    content: "\f1c8"
}

.ri-swap-box-line:before {
    content: "\f1c9"
}

.ri-swap-fill:before {
    content: "\f1ca"
}

.ri-swap-line:before {
    content: "\f1cb"
}

.ri-switch-fill:before {
    content: "\f1cc"
}

.ri-switch-line:before {
    content: "\f1cd"
}

.ri-sword-fill:before {
    content: "\f1ce"
}

.ri-sword-line:before {
    content: "\f1cf"
}

.ri-syringe-fill:before {
    content: "\f1d0"
}

.ri-syringe-line:before {
    content: "\f1d1"
}

.ri-t-box-fill:before {
    content: "\f1d2"
}

.ri-t-box-line:before {
    content: "\f1d3"
}

.ri-t-shirt-2-fill:before {
    content: "\f1d4"
}

.ri-t-shirt-2-line:before {
    content: "\f1d5"
}

.ri-t-shirt-air-fill:before {
    content: "\f1d6"
}

.ri-t-shirt-air-line:before {
    content: "\f1d7"
}

.ri-t-shirt-fill:before {
    content: "\f1d8"
}

.ri-t-shirt-line:before {
    content: "\f1d9"
}

.ri-table-2:before {
    content: "\f1da"
}

.ri-table-alt-fill:before {
    content: "\f1db"
}

.ri-table-alt-line:before {
    content: "\f1dc"
}

.ri-table-fill:before {
    content: "\f1dd"
}

.ri-table-line:before {
    content: "\f1de"
}

.ri-tablet-fill:before {
    content: "\f1df"
}

.ri-tablet-line:before {
    content: "\f1e0"
}

.ri-takeaway-fill:before {
    content: "\f1e1"
}

.ri-takeaway-line:before {
    content: "\f1e2"
}

.ri-taobao-fill:before {
    content: "\f1e3"
}

.ri-taobao-line:before {
    content: "\f1e4"
}

.ri-tape-fill:before {
    content: "\f1e5"
}

.ri-tape-line:before {
    content: "\f1e6"
}

.ri-task-fill:before {
    content: "\f1e7"
}

.ri-task-line:before {
    content: "\f1e8"
}

.ri-taxi-fill:before {
    content: "\f1e9"
}

.ri-taxi-line:before {
    content: "\f1ea"
}

.ri-taxi-wifi-fill:before {
    content: "\f1eb"
}

.ri-taxi-wifi-line:before {
    content: "\f1ec"
}

.ri-team-fill:before {
    content: "\f1ed"
}

.ri-team-line:before {
    content: "\f1ee"
}

.ri-telegram-fill:before {
    content: "\f1ef"
}

.ri-telegram-line:before {
    content: "\f1f0"
}

.ri-temp-cold-fill:before {
    content: "\f1f1"
}

.ri-temp-cold-line:before {
    content: "\f1f2"
}

.ri-temp-hot-fill:before {
    content: "\f1f3"
}

.ri-temp-hot-line:before {
    content: "\f1f4"
}

.ri-terminal-box-fill:before {
    content: "\f1f5"
}

.ri-terminal-box-line:before {
    content: "\f1f6"
}

.ri-terminal-fill:before {
    content: "\f1f7"
}

.ri-terminal-line:before {
    content: "\f1f8"
}

.ri-terminal-window-fill:before {
    content: "\f1f9"
}

.ri-terminal-window-line:before {
    content: "\f1fa"
}

.ri-test-tube-fill:before {
    content: "\f1fb"
}

.ri-test-tube-line:before {
    content: "\f1fc"
}

.ri-text-direction-l:before {
    content: "\f1fd"
}

.ri-text-direction-r:before {
    content: "\f1fe"
}

.ri-text-spacing:before {
    content: "\f1ff"
}

.ri-text-wrap:before {
    content: "\f200"
}

.ri-text:before {
    content: "\f201"
}

.ri-thermometer-fill:before {
    content: "\f202"
}

.ri-thermometer-line:before {
    content: "\f203"
}

.ri-thumb-down-fill:before {
    content: "\f204"
}

.ri-thumb-down-line:before {
    content: "\f205"
}

.ri-thumb-up-fill:before {
    content: "\f206"
}

.ri-thumb-up-line:before {
    content: "\f207"
}

.ri-thunderstorms-fill:before {
    content: "\f208"
}

.ri-thunderstorms-line:before {
    content: "\f209"
}

.ri-ticket-2-fill:before {
    content: "\f20a"
}

.ri-ticket-2-line:before {
    content: "\f20b"
}

.ri-ticket-fill:before {
    content: "\f20c"
}

.ri-ticket-line:before {
    content: "\f20d"
}

.ri-time-fill:before {
    content: "\f20e"
}

.ri-time-line:before {
    content: "\f20f"
}

.ri-timer-2-fill:before {
    content: "\f210"
}

.ri-timer-2-line:before {
    content: "\f211"
}

.ri-timer-fill:before {
    content: "\f212"
}

.ri-timer-flash-fill:before {
    content: "\f213"
}

.ri-timer-flash-line:before {
    content: "\f214"
}

.ri-timer-line:before {
    content: "\f215"
}

.ri-todo-fill:before {
    content: "\f216"
}

.ri-todo-line:before {
    content: "\f217"
}

.ri-toggle-fill:before {
    content: "\f218"
}

.ri-toggle-line:before {
    content: "\f219"
}

.ri-tools-fill:before {
    content: "\f21a"
}

.ri-tools-line:before {
    content: "\f21b"
}

.ri-tornado-fill:before {
    content: "\f21c"
}

.ri-tornado-line:before {
    content: "\f21d"
}

.ri-trademark-fill:before {
    content: "\f21e"
}

.ri-trademark-line:before {
    content: "\f21f"
}

.ri-traffic-light-fill:before {
    content: "\f220"
}

.ri-traffic-light-line:before {
    content: "\f221"
}

.ri-train-fill:before {
    content: "\f222"
}

.ri-train-line:before {
    content: "\f223"
}

.ri-train-wifi-fill:before {
    content: "\f224"
}

.ri-train-wifi-line:before {
    content: "\f225"
}

.ri-translate-2:before {
    content: "\f226"
}

.ri-translate:before {
    content: "\f227"
}

.ri-travesti-fill:before {
    content: "\f228"
}

.ri-travesti-line:before {
    content: "\f229"
}

.ri-treasure-map-fill:before {
    content: "\f22a"
}

.ri-treasure-map-line:before {
    content: "\f22b"
}

.ri-trello-fill:before {
    content: "\f22c"
}

.ri-trello-line:before {
    content: "\f22d"
}

.ri-trophy-fill:before {
    content: "\f22e"
}

.ri-trophy-line:before {
    content: "\f22f"
}

.ri-truck-fill:before {
    content: "\f230"
}

.ri-truck-line:before {
    content: "\f231"
}

.ri-tumblr-fill:before {
    content: "\f232"
}

.ri-tumblr-line:before {
    content: "\f233"
}

.ri-tv-2-fill:before {
    content: "\f234"
}

.ri-tv-2-line:before {
    content: "\f235"
}

.ri-tv-fill:before {
    content: "\f236"
}

.ri-tv-line:before {
    content: "\f237"
}

.ri-twitch-fill:before {
    content: "\f238"
}

.ri-twitch-line:before {
    content: "\f239"
}

.ri-twitter-fill:before {
    content: "\f23a"
}

.ri-twitter-line:before {
    content: "\f23b"
}

.ri-typhoon-fill:before {
    content: "\f23c"
}

.ri-typhoon-line:before {
    content: "\f23d"
}

.ri-u-disk-fill:before {
    content: "\f23e"
}

.ri-u-disk-line:before {
    content: "\f23f"
}

.ri-ubuntu-fill:before {
    content: "\f240"
}

.ri-ubuntu-line:before {
    content: "\f241"
}

.ri-umbrella-fill:before {
    content: "\f242"
}

.ri-umbrella-line:before {
    content: "\f243"
}

.ri-underline:before {
    content: "\f244"
}

.ri-uninstall-fill:before {
    content: "\f245"
}

.ri-uninstall-line:before {
    content: "\f246"
}

.ri-unsplash-fill:before {
    content: "\f247"
}

.ri-unsplash-line:before {
    content: "\f248"
}

.ri-upload-2-fill:before {
    content: "\f249"
}

.ri-upload-2-line:before {
    content: "\f24a"
}

.ri-upload-cloud-2-fill:before {
    content: "\f24b"
}

.ri-upload-cloud-2-line:before {
    content: "\f24c"
}

.ri-upload-cloud-fill:before {
    content: "\f24d"
}

.ri-upload-cloud-line:before {
    content: "\f24e"
}

.ri-upload-fill:before {
    content: "\f24f"
}

.ri-upload-line:before {
    content: "\f250"
}

.ri-usb-fill:before {
    content: "\f251"
}

.ri-usb-line:before {
    content: "\f252"
}

.ri-user-2-fill:before {
    content: "\f253"
}

.ri-user-2-line:before {
    content: "\f254"
}

.ri-user-3-fill:before {
    content: "\f255"
}

.ri-user-3-line:before {
    content: "\f256"
}

.ri-user-4-fill:before {
    content: "\f257"
}

.ri-user-4-line:before {
    content: "\f258"
}

.ri-user-5-fill:before {
    content: "\f259"
}

.ri-user-5-line:before {
    content: "\f25a"
}

.ri-user-6-fill:before {
    content: "\f25b"
}

.ri-user-6-line:before {
    content: "\f25c"
}

.ri-user-add-fill:before {
    content: "\f25d"
}

.ri-user-add-line:before {
    content: "\f25e"
}

.ri-user-fill:before {
    content: "\f25f"
}

.ri-user-follow-fill:before {
    content: "\f260"
}

.ri-user-follow-line:before {
    content: "\f261"
}

.ri-user-heart-fill:before {
    content: "\f262"
}

.ri-user-heart-line:before {
    content: "\f263"
}

.ri-user-line:before {
    content: "\f264"
}

.ri-user-location-fill:before {
    content: "\f265"
}

.ri-user-location-line:before {
    content: "\f266"
}

.ri-user-received-2-fill:before {
    content: "\f267"
}

.ri-user-received-2-line:before {
    content: "\f268"
}

.ri-user-received-fill:before {
    content: "\f269"
}

.ri-user-received-line:before {
    content: "\f26a"
}

.ri-user-search-fill:before {
    content: "\f26b"
}

.ri-user-search-line:before {
    content: "\f26c"
}

.ri-user-settings-fill:before {
    content: "\f26d"
}

.ri-user-settings-line:before {
    content: "\f26e"
}

.ri-user-shared-2-fill:before {
    content: "\f26f"
}

.ri-user-shared-2-line:before {
    content: "\f270"
}

.ri-user-shared-fill:before {
    content: "\f271"
}

.ri-user-shared-line:before {
    content: "\f272"
}

.ri-user-smile-fill:before {
    content: "\f273"
}

.ri-user-smile-line:before {
    content: "\f274"
}

.ri-user-star-fill:before {
    content: "\f275"
}

.ri-user-star-line:before {
    content: "\f276"
}

.ri-user-unfollow-fill:before {
    content: "\f277"
}

.ri-user-unfollow-line:before {
    content: "\f278"
}

.ri-user-voice-fill:before {
    content: "\f279"
}

.ri-user-voice-line:before {
    content: "\f27a"
}

.ri-video-add-fill:before {
    content: "\f27b"
}

.ri-video-add-line:before {
    content: "\f27c"
}

.ri-video-chat-fill:before {
    content: "\f27d"
}

.ri-video-chat-line:before {
    content: "\f27e"
}

.ri-video-download-fill:before {
    content: "\f27f"
}

.ri-video-download-line:before {
    content: "\f280"
}

.ri-video-fill:before {
    content: "\f281"
}

.ri-video-line:before {
    content: "\f282"
}

.ri-video-upload-fill:before {
    content: "\f283"
}

.ri-video-upload-line:before {
    content: "\f284"
}

.ri-vidicon-2-fill:before {
    content: "\f285"
}

.ri-vidicon-2-line:before {
    content: "\f286"
}

.ri-vidicon-fill:before {
    content: "\f287"
}

.ri-vidicon-line:before {
    content: "\f288"
}

.ri-vimeo-fill:before {
    content: "\f289"
}

.ri-vimeo-line:before {
    content: "\f28a"
}

.ri-vip-crown-2-fill:before {
    content: "\f28b"
}

.ri-vip-crown-2-line:before {
    content: "\f28c"
}

.ri-vip-crown-fill:before {
    content: "\f28d"
}

.ri-vip-crown-line:before {
    content: "\f28e"
}

.ri-vip-diamond-fill:before {
    content: "\f28f"
}

.ri-vip-diamond-line:before {
    content: "\f290"
}

.ri-vip-fill:before {
    content: "\f291"
}

.ri-vip-line:before {
    content: "\f292"
}

.ri-virus-fill:before {
    content: "\f293"
}

.ri-virus-line:before {
    content: "\f294"
}

.ri-visa-fill:before {
    content: "\f295"
}

.ri-visa-line:before {
    content: "\f296"
}

.ri-voice-recognition-fill:before {
    content: "\f297"
}

.ri-voice-recognition-line:before {
    content: "\f298"
}

.ri-voiceprint-fill:before {
    content: "\f299"
}

.ri-voiceprint-line:before {
    content: "\f29a"
}

.ri-volume-down-fill:before {
    content: "\f29b"
}

.ri-volume-down-line:before {
    content: "\f29c"
}

.ri-volume-mute-fill:before {
    content: "\f29d"
}

.ri-volume-mute-line:before {
    content: "\f29e"
}

.ri-volume-off-vibrate-fill:before {
    content: "\f29f"
}

.ri-volume-off-vibrate-line:before {
    content: "\f2a0"
}

.ri-volume-up-fill:before {
    content: "\f2a1"
}

.ri-volume-up-line:before {
    content: "\f2a2"
}

.ri-volume-vibrate-fill:before {
    content: "\f2a3"
}

.ri-volume-vibrate-line:before {
    content: "\f2a4"
}

.ri-vuejs-fill:before {
    content: "\f2a5"
}

.ri-vuejs-line:before {
    content: "\f2a6"
}

.ri-walk-fill:before {
    content: "\f2a7"
}

.ri-walk-line:before {
    content: "\f2a8"
}

.ri-wallet-2-fill:before {
    content: "\f2a9"
}

.ri-wallet-2-line:before {
    content: "\f2aa"
}

.ri-wallet-3-fill:before {
    content: "\f2ab"
}

.ri-wallet-3-line:before {
    content: "\f2ac"
}

.ri-wallet-fill:before {
    content: "\f2ad"
}

.ri-wallet-line:before {
    content: "\f2ae"
}

.ri-water-flash-fill:before {
    content: "\f2af"
}

.ri-water-flash-line:before {
    content: "\f2b0"
}

.ri-webcam-fill:before {
    content: "\f2b1"
}

.ri-webcam-line:before {
    content: "\f2b2"
}

.ri-wechat-2-fill:before {
    content: "\f2b3"
}

.ri-wechat-2-line:before {
    content: "\f2b4"
}

.ri-wechat-fill:before {
    content: "\f2b5"
}

.ri-wechat-line:before {
    content: "\f2b6"
}

.ri-wechat-pay-fill:before {
    content: "\f2b7"
}

.ri-wechat-pay-line:before {
    content: "\f2b8"
}

.ri-weibo-fill:before {
    content: "\f2b9"
}

.ri-weibo-line:before {
    content: "\f2ba"
}

.ri-whatsapp-fill:before {
    content: "\f2bb"
}

.ri-whatsapp-line:before {
    content: "\f2bc"
}

.ri-wheelchair-fill:before {
    content: "\f2bd"
}

.ri-wheelchair-line:before {
    content: "\f2be"
}

.ri-wifi-fill:before {
    content: "\f2bf"
}

.ri-wifi-line:before {
    content: "\f2c0"
}

.ri-wifi-off-fill:before {
    content: "\f2c1"
}

.ri-wifi-off-line:before {
    content: "\f2c2"
}

.ri-window-2-fill:before {
    content: "\f2c3"
}

.ri-window-2-line:before {
    content: "\f2c4"
}

.ri-window-fill:before {
    content: "\f2c5"
}

.ri-window-line:before {
    content: "\f2c6"
}

.ri-windows-fill:before {
    content: "\f2c7"
}

.ri-windows-line:before {
    content: "\f2c8"
}

.ri-windy-fill:before {
    content: "\f2c9"
}

.ri-windy-line:before {
    content: "\f2ca"
}

.ri-wireless-charging-fill:before {
    content: "\f2cb"
}

.ri-wireless-charging-line:before {
    content: "\f2cc"
}

.ri-women-fill:before {
    content: "\f2cd"
}

.ri-women-line:before {
    content: "\f2ce"
}

.ri-wubi-input:before {
    content: "\f2cf"
}

.ri-xbox-fill:before {
    content: "\f2d0"
}

.ri-xbox-line:before {
    content: "\f2d1"
}

.ri-xing-fill:before {
    content: "\f2d2"
}

.ri-xing-line:before {
    content: "\f2d3"
}

.ri-youtube-fill:before {
    content: "\f2d4"
}

.ri-youtube-line:before {
    content: "\f2d5"
}

.ri-zcool-fill:before {
    content: "\f2d6"
}

.ri-zcool-line:before {
    content: "\f2d7"
}

.ri-zhihu-fill:before {
    content: "\f2d8"
}

.ri-zhihu-line:before {
    content: "\f2d9"
}

.ri-zoom-in-fill:before {
    content: "\f2da"
}

.ri-zoom-in-line:before {
    content: "\f2db"
}

.ri-zoom-out-fill:before {
    content: "\f2dc"
}

.ri-zoom-out-line:before {
    content: "\f2dd"
}

.ri-zzz-fill:before {
    content: "\f2de"
}

.ri-zzz-line:before {
    content: "\f2df"
}