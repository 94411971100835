@media only screen and (max-width: 600px) {
  .service-heading {
    font-size: 38px !important;
  }
  .service-heading {
    padding: 0px 16px;
  }
}

.swiper-pagination-bullet.swiper-pagination-bullet-active.swiper-pagination-bullet-active-main {
  height: 10px;
  width: 10px;
}
