 .clients {
     padding: 72px 0 60px 0;
     background-color: #ffffff;

     .framework-text {
         font-size: 48px;
         color: #344054;
         font-weight: 700;
     }

     @media only screen and (max-width: 480px) {
         .framework-text {
             font-size: 38px;
         }
     }

     h1 {
         margin-bottom: calc(4rem - 4px);
     }

     .clients-box {
         margin-bottom: 2rem;

         .scroll-container {
             width: 100%;
             overflow: hidden;
             position: relative;
         }

         .scroll-track {
             display: flex;
             width: calc(13 * 160px * 2);
             animation: scroll 20s linear infinite;
         }

         .client-box {
             flex: 0 0 auto;
             margin-right: 5rem;
         }

         .client-box img {
             max-height: 100px;
             object-fit: contain;
         }
     }

 }