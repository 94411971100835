/* Fade-in on load animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
    /* Slide in from below */
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.solution {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.7s ease-out, transform 0.7s ease-out;

}

.solution.animate {
  opacity: 1;
  transform: translateY(0);
}

/* Hover effects for service boxes */
.box {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.box .eachslide {
  width: auto;
  text-align: center;
}

.box .eachslide .service-box-img:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

/* Scroll track for the tools section */
.scroll-track {
  display: flex;
  gap: 15px;
  transition: transform 0.5s ease;
}

.client-box {
  transition: transform 0.3s ease;
}

.client-box:hover {
  transform: scale(1.1);
}

/* Parallax effect on mouse move for images (optional) */
.eachslide img {
  transition: transform 0.5s ease;
}

.eachslide img:hover {
  transform: scale(1.1);
}