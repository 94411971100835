.feature-heading {
  font-size: 48px;
  width: 70%;
  line-height: 120%;
  margin-bottom: 32px;
}

.feature-sub-heading {
  font-size: 20px;
  width: 50%;
  margin-bottom: 60px;
}

.feature-box {
  border: 1px solid #eee;
  background: #fafafa;
  border-radius: 24px;
  margin-bottom: 32px;
}

.feature-box .feature-box-content {
  padding: 40px;
}

.feature-box .feature-box-content h4 {
  font-size: 24px;
  margin-bottom: 13px;
}

.feature-box .feature-box-content p {
  font-size: 20px;
}

@media only screen and (max-width: 768px) {
  .product-feature-section {
    padding: 60px 16px !important;
  }
  .feature-heading {
    text-align: left;
    width: 100% !important;
  }
  .feature-sub-heading {
    text-align: left;
    width: 100% !important;
  }
}
