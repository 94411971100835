.hero-section-2 {
  padding: 72px 80px;
  display: flex;
  justify-content: center;
  p {
    font-weight: 400;
    color: #667085;
    font-size: 20px;
    width: 933px;

    b {
      color: #667085;
    }
  }
}
/* @media only screen and (max-width: 780px) {
  .hero-section-2 {
    padding: 32px 40px;
  }
} */

@media only screen and (max-width: 425px) {
  .hero-section-2 {
    padding: 32px 16px;
  }
}
