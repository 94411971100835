.tools-component-box {
    /* margin: auto; */
    margin-bottom: 72px;
    display: flex;
    gap: 120px;
    justify-content: center;
    flex-wrap: wrap;

    .tools-component img {
        width: 114px;
        height: 112px;
    }
}

.main-tool-container {
    padding: 80px 0px 72px 0px;
}

.main-tool-container .main-tool-title {
    font-size: 48px;
    font-weight: 700;
    color: #344054;
}

@media only screen and (max-width: 480px) {
    .main-tool-container .main-tool-title {
        font-size: 38px;
        font-weight: 700;
    }
}

@media (min-width: 1025px) {

    .img-section img {
        width: 100%;
        height: auto;
    }

    .tools {
        display: none;
    }
}

@media (max-width: 1024px) {
    .tools-component-box {
        display: none;
    }

    .tools {
        margin-top: -3rem;
        padding: 70px 0 70px 0;
        background-color: #ffffff;

        h1 {
            margin-bottom: calc(4rem - 4px);
        }

        .tools-box {
            margin-bottom: 2rem;

            .scroll-container {
                width: 100%;
                overflow: hidden;
                position: relative;
            }

            .scroll-track {
                display: flex;
                width: calc(4 * 120px * 4);
                animation: scroll 10s linear infinite;
            }

            .client-box {
                flex: 0 0 auto;
                margin-right: 1rem;
            }

            .client-box img {
                max-height: 120px;
                object-fit: contain;
            }
        }
    }
}