* {
  font-family: Albert Sans !important;
  color: #2a2a2a;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f3faf8;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #2a2a2a;
  margin: 0;
  padding: 0;
  line-height: 150% !important;
}

.fw-secondary-custom {
  color: #333333;
}

#hero {
  height: 565px;
  margin-top: 80px;
}

.bg-overlay-custom {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  opacity: 0.7;
  background-color: #000;
}

.bg-overlay-text-custom {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bg-overlay-pattern-custom {
  /* background-image: url(../SVG/heroBG.svg); */
  background-color: transparent;
  background-position: center;
  background-size: cover;
}

.client-images-custom img {
  max-height: 60px;
  width: auto;
  margin: 0.1rem auto;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

footer {
  background-color: #fbfbfb;
}

.bg-light-custom {
  background-color: #fbfbfb !important;
}

.book-demo-btn {
  height: 45px;
  width: 110px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  border: 1px solid #343a3f;
}

.book-demo-btn:hover {
  border: 1px solid #343a3f;
  transition: all 0.1s;
}

.Contact-14Day-trial-demo-btn {
  height: 45px;
  width: 216px;
  color: #f5f5f5;
}

.Contact-14Day-trial-demo-btn>span {
  width: 100%;
  font-weight: 400;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn {
  font-size: 14px;
}

.navbar-nav.nav-item.nav-link {
  font-size: 16px;
}

h1 {
  font-size: 48px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
  text-align: justify;
}

p {
  font-size: 16px;
}

.section {
  padding: 80px 0;
}

.footer-link li a {
  font-size: 14px;
  font-weight: 400;
}

.section-heading {
  margin-bottom: 24px;
}

.feature-card-custom {
  height: 240px;
  width: 360px;
  padding: 48px 24px;
  border-radius: 16px;
  border: 1px solid #e5eaf4;
  overflow: hidden;
}

.feature-card-custom>.fw-secondary-custom {
  margin-top: 2rem;
}

.process-card-custom {
  width: 360px;
  height: 220px;
  box-shadow: none;
  background-color: #fbfbfb;
  padding: 24px 0;
  border-radius: 8px;
  word-break: normal;
}

.regular-text {
  font-weight: 400;
}

.medium-text {
  font-weight: 500;
}

.semibold-text {
  font-weight: 600;
}

.bold-text {
  font-weight: 700;
}

#contact>.container {
  padding: 40px 0px;
}

/* Styles for screens with width <= 375px (phones) */
@media screen and (max-width: 375px) {
  h1 {
    font-size: 24px;
  }

  h5 {
    font-size: 16px;
  }

  p {
    font-size: 14px;
  }

  .hero-heading {
    padding: 0rem 1rem;
    word-break: keep-all;
    width: 100% !important;
  }

  .hero-subheading {
    font-size: 16px;
    padding: 0rem 1rem;
  }

  #hero {
    height: 450px;
  }

  .section {
    padding: 30px 20px;
  }

  #contact>.container {
    padding: 30px 20px;
  }

  #contact>.container>.row>.col-lg-4 {
    padding: 0;
  }

  .feature-card-custom {
    padding: 30px 24px;
    width: 330px;
  }

  .feature-card-custom>.fw-secondary-custom {
    margin-top: 1rem;
  }

  .container {
    padding: 0;
  }

  .process-card-custom {
    width: 330px;
  }

  .navbar.navbar-landing {
    padding: .8rem !important;
  }
}

/* Styles for screens with width > 375px and <= 576px (phones) */
@media screen and (min-width: 375px) and (max-width: 425px) {
  h1 {
    font-size: 32px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  p {
    font-size: 16px;
  }

  .section {
    padding: 30px 40px;
  }

  #contact>.container {
    padding: 30px 40px;
  }

  .navbar.navbar-landing {
    padding: .8rem !important;
  }
}

/* Styles for screens with width > 576px and <= 768px (tablets) */
@media screen and (min-width: 426px) and (max-width: 768px) {
  h1 {
    font-size: 32px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }

  p {
    font-size: 16px;
  }

  .feature-card-custom,
  .process-card-custom {
    width: 340px;
  }
}

/* Styles for screens with width > 768px and <= 992px (tablets) */
@media screen and (min-width: 769px) and (max-width: 992px) {
  h5 {
    font-size: 18px;
  }

  /* Additional styles for tablets */
  .feature-card-custom,
  .process-card-custom {
    width: 340px;
  }
}

/* Styles for screens with width > 992px and <= 1200px (small laptops) */
@media screen and (min-width: 993px) and (max-width: 1200px) {
  h5 {
    font-size: 18px;
  }

  /* Additional styles for small laptops */
  .feature-card-custom,
  .process-card-custom {
    width: 300px;
  }
}

/* Styles for screens with width > 1200px (big laptops and larger screens) */
@media screen and (min-width: 1201px) {
  /* Additional styles for big laptops and larger screens */
}