.opening-component-box {
    display: flex;
    flex-direction: column;

    .opening-component {
        display: flex;
        flex-direction: row;
        padding: 23px 0;
        align-items: center;

        &:nth-child(odd) {
            background-color: #F4FBFF;
        }

        .first-section {
            .header-content {
                font-size: 20px;
                font-weight: 600;
                color: #182230;
            }
        }

        .second-section {
            margin-left: auto;
            text-align: right;

            .section-detail {
                color: #344054;
                font-size: 18px;
                font-weight: 400;
            }

            .section-apply-now {
                color: #3563A8;
                font-size: 18px;
                font-weight: 600;
                text-decoration: underline;
                margin-left: 40px;
                cursor: pointer;
            }
        }
    }

    @media (max-width: 768px) {
        .opening-component {
            flex-direction: column;
            text-align: center;
            padding: 15px 0;

            .first-section {
                .header-content {
                    font-size: 18px;
                }
            }

            .second-section {
                margin-left: 0;
                margin-top: 10px;

                .section-detail,
                .section-apply-now {
                    font-size: 16px;
                }

                .section-apply-now {
                    margin-left: 0;
                    margin-top: 5px;
                }
            }
        }
    }

    @media (max-width: 480px) {
        .opening-component {
            padding: 10px 0;
            flex-direction: column;

            .first-section {
                .header-content {
                    font-size: 16px;
                }
            }

            .second-section {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: left;

                .section-detail,
                .section-apply-now {
                    font-size: 14px;
                    margin: 5px 0;
                }

                .section-apply-now {
                    margin-top: 10px;
                }
            }
        }
    }
}