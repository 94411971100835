.feature-component-box {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
}

.main-outer-container {
  margin-bottom: 60px;
  margin-top: 72px;
}

.main-outer-container .main-title {
  font-size: 48px;
  font-weight: 700;
}

.feature-component {
  height: 220px;
  width: 400px;
  background-color: #fbfbfb;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.feature-component .first-section {
  flex: 2;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.feature-component .first-section .header-line {
  border: 2px solid #3563a8;
  height: 44px;
}

.feature-component .first-section span {
  font-weight: 600;
  font-size: 20px;
  color: #182230;
}

.feature-component .second-section {
  flex: 3;
  display: flex;
  height: 84px;
  padding: 9px 24px 9px 24px;
}

.feature-component .second-section .section-detail {
  width: 100%;
  font-size: 18px;
  color: #344054;
  font-weight: 400;
  text-align: start;
}

.main-outer-container {
  display: flex;
  justify-content: center;
}